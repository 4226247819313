/**
 * Component for game box detail avatar svg
 */

import { log } from '../../config/log';
import React from 'react';
import { connect } from 'react-redux';

import './GameBoxDetailedAvatar.css';

class GameBoxDetailedAvatarBB extends React.PureComponent {

  renderShirt() {
    const { pattern, color1, color2 } = this.props;

    switch (pattern) {

      case "1":
        return (
          <div>
            <div className="competitor-avatar-bg-baseball"></div>
            <svg className="competitor-avatar-img" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="3em" height="3em"
              viewBox="0 0 520.7 565.9" style={{ enableBackground: "0 0 520.7 565.9" }} xmlSpace="preserve">
              <path fill={color2} d="M205.1,16.9l4.2-0.9c57.1,14.5,86.5,3.3,98.5,0.6c1-0.2,11.8,0.9,11.9,1c3.5,2.4,8.2,4.1,11.9,6.4
                c6.4,3.9,62,24.1,62.7,24.4c8.8,3,17.4,6.6,24.9,12.2c6.8,5.1,15.5,12.2,19.6,19.8c3.9,7.2,9.3,20.4,11.9,28.2
                s21.7,64.1,23.3,65.6s10.1,26.9,10.1,26.9l3.9,12.9v8.3l-16,6.2c-10.2,4.5-20.1,8.9-30.4,13.3c-8.7,3.7-17.4,8.4-26.6,10.9
                c-5.7,1.6-18.5,0-18.5,0l-3.1,0.7c0,0,0,33.9-0.3,35.7c-0.3,1.7,2.8,37.9,2.8,37.9c1.7,33.9,0.6,67.8,2.8,101.6l3.8,26.2
                c0,0,4.1,23.4,4.1,24.8s0.3,10.7,0.3,10.7l-5.5,3.4c0,0-4.5,7.9-4.5,7.9c-3.3,5.8-4.3,12.5-7.8,18.1c-3.4,5.5-9.4,9.8-14.3,14
                c-10.6,9.3-22.2,18.7-36.5,20.6c-17,2.2-33.9,3.1-51,3.6c-8,0.2-16.1,0.2-24.1,0.4c-2.9,0.1-37.6-1.9-37.6-1.9l-37.8-1.4
                c0,0-17.3-3.4-26.2-9.3c-9.6-6.3-18.9-14.8-25.7-24.2c-3.4-4.7-5.6-9.6-7.5-15c-0.8-2.3-2.4-10.9-4.4-12.2l-7.2-4.5
                c0,0,3.1-31.7,5.2-39.3c4.9-18.1,5-36.2,5.8-54.8c0.5-13.2,2.1-27.1,0.8-40.3c-1-10-0.7-28.3,0-35.8c0.7-7.6,2.8-51.3,2.8-56.8
                c0-5.5-0.7-7.9-0.7-7.9l-13.8-1c0,0-17.9-5.2-23.8-7.2c-5.9-2.1-21.4-10-24.8-11c-4.8-1.4-9.4-4-14-5.9c-7-2.8-17.6-3.9-22.1-10.3
                c0,0,26.5-70.6,26.9-71.7c4.7-14.2,8.8-28.5,14.5-42.4C77.4,95,82.4,81.3,90,73c11.2-12.1,23.1-19,38.5-24.7
                c10.5-3.9,22-7.1,32.1-12.2c10.8-5.4,22.4-7.9,33.1-13.6L205.1,16.9z"/>
              <path fill={color1} d="M486.9,210c-1-2.9-11.7-33.8-13.1-35.2c-1.6-1.6-20.4-57.6-23-65.3c-2.6-7.8-8.4-21.3-12.3-28.5
                c-4.1-7.6-11.8-14.1-18.6-19.2c-7.4-5.6-15.9-10-24.7-13c-0.8-0.3-43-15.8-44.3-16c-4.9-1-29.7-15-29.5-14.7l-1.2-0.7l-3.1-1.3
                l-2,0.3l1.8,1.4l0.7,5.2l-1,4.5l-4.7,8.4l-7.9,8.6c0,0-7.9,6.9-8.8,7.3c-0.9,0.3-10.3,6.8-10.3,6.8l-8.5,5.8l-12.4,8.2l-6.8-3.7
                l-3.4-2.3L239.7,59l-9.2-5.2l-13.2-10.1l-7.6-9l-0.6-1l0.4-0.2c0,0-3.7-5.3-4.8-8.5c-0.4-1.3-0.7-2.4-0.8-3.4c0-0.8,0-1.7,0.1-1.9
                c0,0,0,0,0-0.1l1.3-2l-0.1,0c1.2-0.9-1.4,0-1.5,0.1c-0.4,0.3-0.8,0.5-1.2,0.7l-0.1,0c0,0-2,1.1-3.7,2c-2.4,1.1-4.8,2.2-6.9,3.5
                c-6.4,3.9-13.1,6.7-20.1,9.1c-4.8,1.6-9.6,4.3-14.5,5.3c-1.3,0.3-27.4,9.8-28.2,10.1c-8.8,3-17.3,7.1-24.7,12.7
                C97.4,66.4,89.1,73.8,85,81.4c-3.9,7.2-9.5,19.9-12.6,27.5c-3.2,7.9-21.8,62.3-23.3,66.3c-0.8,2.1-9.4,26.9-9.4,26.9l-5.2,12.7
                l-2.7,6.4c0,0,12.7,8.4,20,8.4c10.2,4.5,20.1,8.9,30.4,13.3c8.7,3.7,17.4,8.4,26.6,10.9c5.7,1.6,18.2,2.3,18.2,2.3l4.2,0.6
                c-2.3-18.2-3.9-39-6.4-57.2c-1-7.7-2.7-15.5-3.1-23.3c-0.4-7.7-0.9-15.3-1-23c-0.1-6.5-5.1-46.6-5.2-50.2
                c-0.5-11.6,6.5-20.8,13.8-29c7.9-8.9,17.7-12.3,28.1-17.6l19.6-8.2l3.2,3.6l5.2,4.7l4.7,4.4l8.9,7.8c0,0,43.6,37.5,65,37.3
                c21.5-0.2,67.1-38.7,67.1-38.7l6.4-7.1l9.2-9.2l2.5-3.4l18.9,7.9c10.4,5.3,20.2,8.7,28.1,17.6c7.3,8.2,14.3,17.5,13.8,29
                c-0.1,3.6-5.9,65.6-6.3,73.2c-0.4,7.8-2.1,15.5-3.1,23.3c-2.4,18.2-5.7,38.1-7.9,56.3l6.1-0.7c0,0,12.1,0.2,17.8-1.4
                c9.2-2.5,17.9-7.2,26.6-10.9c10.2-4.4,20.2-8.8,30.4-13.3c4.6,0,11-3.2,15.1-5.5C487.6,218.7,487.8,214.2,486.9,210z"/>
              <path fill={color2} d="M477.7,218.2c4.1-2.1,5.3-2.3,9.7-3.1c0.4-0.3-1.4-1.1,0.4-0.9l-0.6-1.5l-0.6-2.3c-0.4-1-18,6.5-18.4,6.6
                c-3.9,1.6-7.9,3.1-11.7,4.9c-6.7,3.1-13,6.8-19.9,9.5c-8.4,3.3-17.1,6.4-26.1,7.6c-1.2,0.2-16.1,1.6-16.1,1.7
                c-0.5,1.7-0.9,3.9-0.8,5.6c14.2,0.4,26.6-3.6,37.7-8.1c10.8-4.4,21.1-10.1,31.8-14.4c3.1-1.3,6.2-2.5,9.4-3.7
                C473.1,219.6,476.9,218.6,477.7,218.2z"/>
              <path fill={color2} d="M395.6,232.4c2.7-0.4,13.4-1.4,14.4-1.5c8.7-1.1,17.1-4.1,25.2-7.3c6.6-2.6,12.8-6.3,19.3-9.3
                c3.7-1.7,7.5-3.2,11.3-4.8c0.4-0.1,18.1-7.6,18.5-6.6l1,2.2l0.5,1.9c-0.5,0.2-1,0.5-1.5,0.7c-4.2,0.7-8.4,1.6-12.4,3.6
                c-0.7,0.4-1.3,0.8-1.9,1.3c-3,1.2-6.1,2.3-9.1,3.6c-10.4,4.3-20.4,9.9-30.9,14.2c-10.8,4.4-23.8,7.2-35.4,7.8
                C394.7,237.3,395.4,233.4,395.6,232.4z"/>
              <path fill={color2} d="M130.2,240.9c-2.9-0.4-13.3-1.7-14.3-1.8c-9.3-1.2-18.2-4.3-26.9-7.6c-7.1-2.7-13.6-6.4-20.5-9.5
                c-4-1.8-8-3.3-12.1-4.9c-0.4-0.1-19.3-7.8-19.7-6.8l-1.1,2.2l-0.5,1.9c0.5,0.2,1,0.5,1.6,0.7c4.5,0.8,9,1.7,13.2,3.7
                c0.8,0.4,1.4,0.8,2,1.3c3.2,1.2,6.5,2.4,9.7,3.7c11,4.4,21.7,10.1,32.9,14.4c11.5,4.5,23.7,7.3,36.1,7.9c0.1-0.8,0.2-1.6,0.2-2.4
                C130.5,242.8,130.3,241.8,130.2,240.9z"/>
              <path fill={color2} d="M128.9,231.9c-2.8-0.4-12.7-1.7-13.7-1.8c-8.9-1.2-17.5-4.3-25.8-7.6c-6.8-2.7-13.1-6.4-19.7-9.5
                c-3.8-1.8-7.7-3.3-11.6-4.9c-0.4-0.1-18.4-7.8-18.9-6.8l-1,2.2l-0.5,1.9c0.5,0.2,1,0.5,1.5,0.7c4.3,0.8,8.6,1.7,12.7,3.7
                c0.7,0.4,1.4,0.8,1.9,1.3c3.1,1.2,6.2,2.4,9.3,3.7c10.6,4.4,20.8,10.1,31.4,14.4c11,4.5,23.1,7.3,35,8
                C129.6,236.5,129,232.9,128.9,231.9z"/>
            </svg>
          </div>
        );

      default:
        return (
          <div>no pattern</div>
        );
    }


  }

  render() {
    log(this.constructor.name, 'render');
    return <div>{this.renderShirt()}</div>
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    pattern: ownProps.pattern,
    color1: ownProps.color1,
    color2: ownProps.color2
  };
};

export default connect(mapStateToProps)(GameBoxDetailedAvatarBB);
