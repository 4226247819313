/**
 * SVG component for own icon 
 */

import React from 'react';
import './IconOwn.css';

const IconPLAYERPROPS = (props) => {

	return (
		<div style={{ display: "flex" }}>
			<svg className="icon-own" viewBox="0 0 2000 2000">


				<path fill={'var(--color-icon-1)'} d="M1136.6,583.7c93.5,0,169.4-75.8,169.4-169.4c0-93.6-75.9-169.4-169.4-169.4c-93.6,0-169.4,75.8-169.4,169.4
	C967.2,507.9,1043,583.7,1136.6,583.7z"/>
				<path fill={'var(--color-icon-1)'} d="M1265.3,1095.6c15.2-29.2,3.9-65.1-25.2-80.3l-111.1-58L1073.4,719c-2.8-36.7-22.3-71.6-56-92.5
	c-21.2-13.1-45-18.7-68.2-17.7c-4.3-0.3-8.8-0.2-13.3,0.5l-455.9,71.1c-32.5,5.1-54.7,35.5-49.7,68c4.6,29.4,29.9,50.4,58.8,50.4
	c3.1,0,6.1-0.2,9.3-0.7L800.3,751l-181,292.7c-2.6,4.2-4.8,8.5-6.8,12.8L426.2,1361H172.8c-32.9,0-59.5,26.7-59.5,59.5
	c0,32.9,26.7,59.5,59.5,59.5h286.8c20.7,0,40-10.8,50.8-28.5l148.9-243.3c1.6,1,3.3,1.7,5,2.6L863,1471.1v218.4
	c0,32.9,26.7,59.5,59.5,59.5c32.9,0,59.5-26.7,59.5-59.5v-238.6c0-13.1-4.3-25.8-12.2-36.2L801.1,1194c7.8-7.2,14.9-15.5,20.7-25
	L991.9,894l27.3,116.9c3.9,16.9,15.1,31.2,30.4,39.2l135.3,70.6c8.8,4.6,18.2,6.8,27.5,6.8C1233.9,1127.6,1254.7,1116,1265.3,1095.6
	z"/>
				<path fill={'var(--color-icon-1)'} d="M1352.9,1442.9c-81.2,0-147,65.8-147,147s65.8,147,147,147c81.2,0,147-65.8,147-147S1434.1,1442.9,1352.9,1442.9z" />



			</svg>
		</div>
	);
};

export default IconPLAYERPROPS;
