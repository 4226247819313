/**
 * Menu Tabs component ( Result, standing , conference and others)
 */

import { log } from "../../config/log";
import React from "react";
import { connect } from "react-redux";
import { Translate } from "react-redux-i18n";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { AppBar } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import Badge from "@mui/material/Badge";
import { setLeagueMenuValue } from "../../actions";
import { menuConfig } from "../../config/menuConfig";
import CheckedIcon from "@mui/icons-material/CheckBox";
import StandingsIcon from "@mui/icons-material/FormatListNumbered";
import ResultsIcon from "@mui/icons-material/DoneAll";
import ConferenceIcon from "@mui/icons-material/LiveTv";
import MarketIcon from "@mui/icons-material/ViewList";
import "./MenuTabs.css";
import withRouter from "../../helpers/withRouter";

function a11yProps(index, key) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
    disablehorizontalview: `${key === "favorites"}`,
  };
}

const styles = (theme) => ({
  rootTop: {
    "& .MuiTab-wrapper": {
      display: "inline-flex !important",
    },
    "& .MuiSvgIcon-root": {
      fontSize: "1.3em !important",
      "@media (hover: none) and (min-width: 768px)": {
        fontSize: "1.5em !important",
      },
    },
    "& .MuiTab-root": {
      minHeight: "3.5em !important",
      padding: "0.2em",
      minWidth: 0,
      textTransform: "uppercase !important",
      "@media (hover:hover)": {
        minHeight: "2.6em",
      },
    },
    "& .MuiTab-textColorInherit.Mui-selected": {
      color: "var(--color-contrast)",
    },
    "& .MuiAppBar-root": {
      position: "sticky",
      zIndex: "998",
    },
    "& .MuiBadge-anchorOriginTopRightRectangle": {
      top: "0.7em !important",
      left: "0.4em",
      "@media (hover: none) and (min-width: 768px)": {
        top: "0.6em !important",
        left: "0.8em",
      },
    },
    "& .MuiBadge-badge": {
      height: "1em",
      minWidth: "1em",
      fontSize: "0.8em",
    },
    width: "100%",
    background: "var(--bg-primary)",
  },
  tabLabel: {
    padding: "0.3em",
    "@media (hover:hover)": {
      padding: "0.3em 0.3em 0.3em 0.7em",
    },
  },
});

class MenuTabs extends React.PureComponent {
  constructor(props) {
    super(props);

    this.icons = {};
    this.labels = {};

    this.state = {
      menuValue: "mainbets",
    };
  }

  setIcons() {
    const { sportCode, marketSetConfig } = this.props;

    this.icons = {
      results: <ResultsIcon />,
      standings: <StandingsIcon />,
      conference: <ConferenceIcon />,
      market: <MarketIcon />,
      favorites: (
        <Badge
          badgeContent={
            marketSetConfig[sportCode] &&
            marketSetConfig[sportCode].prematch &&
            marketSetConfig[sportCode].prematch["favorites"]?.length
          }
          color="error"
        >
          <CheckedIcon />
        </Badge>
      ),
    };
  }

  setLabels() {
    this.labels = {
      results: <Translate value={`menu.results`} />,
      standings: <Translate value={`menu.standings`} />,
      conference: <Translate value={`menu.conference`} />,
    };
  }

  menuChange = (event, newLeagueMenuValue) => {
    const { sportCode, leagueId } = this.props;
    this.props.setLocalMenuValue(newLeagueMenuValue);
    this.props.setLeagueMenuValue(
      sportCode ? sportCode : "FB",
      leagueId,
      newLeagueMenuValue,
      this.constructor.name
    );
  };

  componentDidUpdate() {
    const { sportCode, leagueGrouping } = this.props;
    if (
      leagueGrouping &&
      menuConfig[sportCode] &&
      typeof this.props.parentCallBack === "function"
    )
      this.props.parentCallBack(menuConfig[sportCode].prematch);
  }

  render() {
    log(this.constructor.name, "render");
    const {
      sportCode,
      leagueId,
      leagueFilter,
      hasLeagueOutrights,
      selectedleagues,
      leagueMenuValue,
      marketSetConfig,
      marketViewerLabels,
      marketsLayout,
      classes,
      url,
    } = this.props;
    this.setIcons();
    this.setLabels();
    if (sportCode === "TR" || url === "/outrights") return <div></div>;

    let menuItems = [];
    if (
      !marketSetConfig[sportCode][
        leagueFilter === "live" ? "live" : "prematch"
      ]["favorites"] ||
      !marketSetConfig[sportCode][
        leagueFilter === "live" ? "live" : "prematch"
      ]["favorites"]?.length
    ) {
      menuItems = menuConfig[sportCode]
        ? menuConfig[sportCode][
            leagueFilter === "live" ? "live" : "prematch"
          ].filter((value) => {
            return value !== "favorites";
          })
        : [];
    } else {
      menuItems = menuConfig[sportCode]
        ? [
            ...menuConfig[sportCode][
              leagueFilter === "live" ? "live" : "prematch"
            ],
          ]
        : [];
    }
    if (!hasLeagueOutrights && !menuItems?.includes("favorites")) {
      menuItems = [menuItems[0], ...menuItems.slice(2, menuItems?.length)];
      // menuItems = [menuItems[0], "outrights", ...menuItems.slice(1, menuItems?.length)]
    } else if (
      !hasLeagueOutrights &&
      menuConfig[sportCode][
        leagueFilter === "live" ? "live" : "prematch"
      ]?.includes("favorites")
    ) {
      menuItems = [
        menuItems[0],
        menuItems[1],
        ...menuItems.slice(3, menuItems?.length),
      ];
    }

    if (
      !selectedleagues[leagueId]?.outrightGames ||
      (selectedleagues[leagueId]?.outrightGames &&
        !Object.values(selectedleagues[leagueId]?.outrightGames)?.length)
    ) {
      menuItems = menuItems.filter((item) => item !== "outrights");
    }

    if (
      !selectedleagues[leagueId]?.games ||
      (selectedleagues[leagueId]?.games &&
        !Object.values(selectedleagues[leagueId]?.games)?.length)
    ) {
      if (
        !selectedleagues[leagueId]?.outrightGames ||
        (selectedleagues[leagueId]?.outrightGames &&
          !Object.values(selectedleagues[leagueId]?.outrightGames)?.length)
      ) {
        menuItems = ["standings", "results"];
      } else {
        menuItems = ["outrights",  "standings", "results"];
      }
    }

    return (
      <div className={`${classes.rootTop}`}>
        {
          // !url?.includes('/tv1') ?
          <AppBar elevation={0} color="transparent">
            {/* <Tabs className={`${url?.includes('/tv1') ? 'hidden' : ''}`} */}
            <Tabs
              value={this.props.menuValue}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
              onChange={this.menuChange}
              orientation="horizontal"
            >
              {menuItems.map((key, index) => {
                if (
                  this.props.menuValue === "favorites" &&
                  !marketSetConfig[sportCode][
                    leagueFilter === "live" ? "live" : "prematch"
                  ]["favorites"]?.length
                ) {
                  this.menuChange(null, "mainbets");
                }
                return (
                  // key === 'favorites' && !marketSetConfig[sportCode].prematch['favorites']?.length ?
                  //   null
                  //   :
                  <Tab
                    key={index}
                    value={key}
                    label={
                      <span className={classes.tabLabel}>
                        {this.labels[key] ? (
                          this.labels[key]
                        ) : (
                          // key === 'favorites' && mode=='compact' ? <Badge badgeContent={marketSetConfig[sportCode].prematch['favorites']?.length} color="error"><Translate value={`marketSets.${key}`} /></Badge> : <Translate value={`marketSets.${key}`} />
                          <Translate value={`marketSets.${key}`} />
                        )}
                      </span>
                    }
                    // icon={mode === 'standard' ? (this.icons[key] ? this.icons[key] : this.icons.market) : null}

                    {...a11yProps(index, key)}
                  />
                );
              })}
            </Tabs>
          </AppBar>
          // :
          // <div></div>
        }

        {marketsLayout === "horizontal" &&
        this.props.menuValue !== "favorites" &&
        marketSetConfig[sportCode][
          leagueFilter === "live" ? "live" : "prematch"
        ][this.props.menuValue] ? (
          // <div className={`${url?.includes('/tv1') ? 'menu-tabs-grid-tv' : 'menu-tabs-grid'}`}>
          <div className={"menu-tabs-grid"}>
            <div></div>
            {/* <div className={`${url?.includes('/tv1') ? 'market-tv' : ''} color-contrast menu-tabs-market-box-h-${marketSetConfig[sportCode][leagueFilter === 'live' ? 'live' : 'prematch'][this.props.menuValue]?.length}`}> */}
            <div
              className={`color-market-tabs menu-tabs-market-box-h-${
                marketSetConfig[sportCode][
                  leagueFilter === "live" ? "live" : "prematch"
                ][this.props.menuValue]?.length
              }`}
            >
              {marketSetConfig[sportCode][
                leagueFilter === "live" ? "live" : "prematch"
              ][this.props.menuValue].map((key, index) => {
                let slgLabel = "";
                if (this.props.menuValue.includes("period")) {
                  const slg = selectedleagues
                    ? Object.values(selectedleagues)
                        ?.filter(
                          (item) =>
                            item?.games && Object.keys(item?.games)?.length > 1
                        )
                        .find((lg) => {
                          const lggame = lg.games
                            ? Object.values(lg.games).find((item) => {
                                const lgOdd = item?.odds?.find(
                                  (item) =>
                                    item?.hc?.includes("period") &&
                                    item.m == key
                                );
                                if (lgOdd) {
                                  return true;
                                } else {
                                  return false;
                                }
                              })
                            : {};
                          if (lggame) {
                            return true;
                          } else {
                            return false;
                          }
                        })
                    : {};
                  const slgGame = slg?.games ? Object.values(slg.games)[0] : {};
                  const slgGameOdd = slgGame?.odds
                    ? slgGame.odds.find((item) => item.m == key)
                    : {};
                  const mLabel = marketViewerLabels
                    ? marketViewerLabels[key]
                    : "";

                  const hcLabel = slgGameOdd?.hc
                    ? slgGameOdd.hc.split("|")[0][
                        slgGameOdd.hc.split("|")[0]?.length - 1
                      ]
                    : "";
                  slgLabel =
                    hcLabel && hcLabel?.trim() !== ""
                      ? mLabel.replace("{!periodnr}", `${hcLabel}.`)
                      : mLabel;
                } else if (this.props.menuValue == "firstset") {
                  const slg = selectedleagues
                    ? Object.values(selectedleagues)
                        ?.filter(
                          (item) =>
                            item?.games && Object.keys(item?.games)?.length > 1
                        )
                        .find((lg) => {
                          const lggame = lg.games
                            ? Object.values(lg.games).find((item) => {
                                const lgOdd = item?.odds?.find(
                                  (item) =>
                                    item?.hc?.includes("set") && item.m == key
                                );
                                if (lgOdd) {
                                  return true;
                                } else {
                                  return false;
                                }
                              })
                            : {};
                          if (lggame) {
                            return true;
                          } else {
                            return false;
                          }
                        })
                    : {};
                  const slgGame = slg?.games ? Object.values(slg.games)[0] : {};
                  const slgGameOdd = slgGame?.odds
                    ? slgGame.odds.find((item) => item.m == key)
                    : {};
                  const mLabel = marketViewerLabels
                    ? marketViewerLabels[key]
                    : "";

                  const hcLabel = slgGameOdd?.hc
                    ? slgGameOdd.hc.split("|")[0][
                        slgGameOdd.hc.split("|")[0]?.length - 1
                      ]
                    : "";
                  // console.log(mLabel, slg);
                  slgLabel =
                    hcLabel && hcLabel?.trim() !== ""
                      ? mLabel.replace("{!setnr}", `${hcLabel}.`)
                      : mLabel;
                } else {
                  slgLabel = marketViewerLabels ? marketViewerLabels[key] : "";
                }
                return (
                  <div className="content-center vertical-center" key={index}>
                    {slgLabel}
                  </div>
                );
              })}
            </div>
            <div></div>
          </div>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let confLength =
    state.settings.marketSets[ownProps.sportCode][
      state.leagues._leagueFilter === "live" ? "live" : "prematch"
    ]["favorites"]?.length;

  let showResultsFirst = state.settings.showResultsFirst;
  let leagueId = null,
    leagueMenuValue = showResultsFirst
      ? "results"
      : confLength > 0
      ? "favorites"
      : "mainbets",
    content = ownProps.content;

  switch (content) {
    case "league":
      leagueId =
        ownProps.params && ownProps.params.leagueId
          ? ownProps.params.leagueId
          : ownProps.leagueId;
      leagueMenuValue =
        state.selected.leagues[leagueId] &&
        state.selected.leagues[leagueId].menuValue
          ? state.selected.leagues[leagueId].menuValue
          : leagueMenuValue;
      break;

    case "leagues":
      leagueMenuValue =
        state.selected.leagues &&
        Object.values(state.selected.leagues)[0]?.menuValue
          ? Object.values(state.selected.leagues)[0].menuValue
          : leagueMenuValue;
      break;

    default:
      break;
  }

  return {
    selectedleagues: state.selected.leagues,
    content: content,
    leagueId: leagueId,
    leagueMenuValue: leagueMenuValue,
    leagueFilter: ownProps?.leagueFilter
      ? ownProps?.leagueFilter
      : state.leagues._leagueFilter,
    sportCode: ownProps.sportCode,
    leagueGrouping: state.settings.leagueGrouping,
    marketSetConfig: state.settings.marketSets,
    marketViewerLabels: state.labels.market ? state.labels.market.viewer : null,
    marketsLayout: state.settings.marketsLayout,
    mode: state.settings.mode,
    url: state.settings.url,
    hasLeagueOutrights: state.selected.leagues[leagueId]?.hasOutright,
  };
};

export default withRouter(
  connect(mapStateToProps, { setLeagueMenuValue })(withStyles(styles)(MenuTabs))
);
