import React from "react";
import { connect } from "react-redux";
import { getSingleGame,  } from "../../actions";
import withStyles from "@mui/styles/withStyles";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Close from "@mui/icons-material/Close";
import SportsTabs from "./betbuilder-screen/SportsTabs";
import LeagueRow from "./betbuilder-screen/LeagueRow";
import { Translate } from "react-redux-i18n";
import { Link } from "react-router-dom";
import withRouter from "../../helpers/withRouter";
import { Stack } from "@mui/material";
import PrecannedBetsCarousel from "../dynamic-banners/precanned-bets";
import { fontSize } from "@mui/system";

const styles = (theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "@media (hover:hover)": {
      justifyContent: "left",
      margin: "5em 0 0 0",
    },
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: "4px",
    boxShadow: theme.shadows[5],
    width: "100%",
    fontSize: "0.8em",
    "@media (hover:hover)": {
      width: "74.5%",
    },
    height: "100%",
    outline: "none",
    overflowY: "auto",
    overflowX: "hidden",
  },
  close_btn_container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "0.4em 0.2em",
  },
  headline: {
    color: "var(--yellow-1)",
    fontWeight: "800",
    fontSize: "1.2em",
  },
  builder_modal_header: {
    position: "sticky",
    top: 0,
    zIndex: 1000,
    backgroundColor: "var(--bg-app)",
  },
});

class BetBuilderModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  handleClose = () => {
    const { url } = this.props;
    this.props.navigate(url);
  };

  handleOpen = () => {
    const { lang } = this.props;
    this.props.navigate(`/${lang}?dialogue=betBuilder&selectedBetBuilderSportCode=FB`);
  };

  render() {
    const { classes, modalType, selectedLeagues, lang, disableText, topLeagueIds } = this.props;
    const isModalOpen = modalType && modalType === "betBuilder";
    return (
      <div>
        {!disableText && (
          <Link key="link-betbuilder" to={`/${lang}?dialogue=betBuilder&selectedBetBuilderSportCode=FB`}>
            <Typography
              style={{
                marginRight: "1em",
                color: modalType === "betBuilder" ? "var(--color-contrast)" : "var(--grey-15)",
              }}
              variant="body1"
            >
              <Translate value={`marketSets.betbuilder`} />
            </Typography>
          </Link>
        )}
        <Modal aria-labelledby="transition-modal-title" aria-describedby="transition-modal-description" className={classes.modal} open={Boolean(isModalOpen)} onClose={this.handleClose} closeAfterTransition>
          <Fade in={isModalOpen}>
            <div className={classes.paper}>
              <div className={classes.builder_modal_header}>
                <div className={classes.close_btn_container}>
                  <IconButton onClick={this.handleClose}>
                    <Close />
                  </IconButton>
                  <Translate className={classes.headline} value={`marketSets.betbuilder`} />
                </div>
                {/* <Divider /> */}
              </div>
              <Stack pb={2} width={{ xs: "90%", md: "92%" }} margin="auto">
                <Typography variant="subtitle2" color="var(--grey)">
                  <Translate value="labels.quick_pick" />
                </Typography>
                <PrecannedBetsCarousel topLeagueIds={topLeagueIds} isInBBModal />
                <Typography mt={{ xs: 2, md: 3 }} variant="subtitle2" color="var(--grey)">
                  <Translate value="labels.build_your_own" />
                </Typography>
              </Stack>
              <SportsTabs />
              <div>
                {selectedLeagues?.map((league, indx) => (
                  <LeagueRow key={league?.leagueId} indx={indx} selectedLeague={league} />
                ))}
              </div>
            </div>
          </Fade>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const modalType = params.get("dialogue");
  const sportCode = params.get("selectedBetBuilderSportCode");
  const topLeagueIds = [];

  const selectedLeagues = state?.leagues && state?.leagues?.betbuilder && state?.leagues?.betbuilder[sportCode] ? state?.leagues?.betbuilder[sportCode] : [];
  if (state?.leagues && state?.leagues?.betbuilder) {
    Object.keys(state?.leagues?.betbuilder).forEach((sportCode) => {
      const sportLeagues = state?.leagues?.betbuilder[sportCode];
      sportLeagues.forEach((league) => {
        if (league?.isTopLeague) {
          topLeagueIds.push(league.leagueId);
        }
      });
    });
  }

  return {
    modalType: modalType,
    url: state.settings.url,
    selectedLeagues: selectedLeagues,
    lang: state.i18n.locale,
    topLeagueIds: topLeagueIds,
  };
};

export default withRouter(connect(mapStateToProps, { getSingleGame })(withStyles(styles)(BetBuilderModal)));
