/**
 * Odds Chart component
 * Show price history changing in line chart
 */

import { log } from "../../config/log";
import React from "react";
import { connect } from "react-redux";
import { getOddsHistory } from "../../actions";
import { AppBar } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
} from "recharts";
import Momentum from "./widgets/betradar/Momentum";
import withStyles from "@mui/styles/withStyles";
import "./OddsChart.css";

const styles = (theme) => ({
  root: {
    "& .MuiTabs-root": {
      minHeight: "1em",
    },
    "& .MuiTab-root": {
      minHeight: "1em",
    },
    "& .MuiTab-wrapper": {
      color: "var(--grey)",
    },
    "& .MuiListItem-button": {
      color: "#000",
    },
    backgroundColor: theme.palette.background.default,
  },
});

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

class OddsChart extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      menuItem: 0,
      // opacity: {
      //   1: 1,
      //   2: 1,
      //   3: 1
      // },
    };
  }

  componentDidMount() {
    const { oddsHistory, leagueId, gameId, marketId, hc, selectionIds } =
      this.props;

    if (
      !oddsHistory ||
      !oddsHistory[marketId] ||
      !Object.keys(oddsHistory[marketId])?.length
    ) {
      this.props.getOddsHistory(
        leagueId,
        gameId,
        marketId,
        hc,
        selectionIds,
        this.constructor.name
      );
    }
  }

  handleChange = (event, newValue) => {
    this.setState({ menuItem: newValue });
    // this.setState({ [this.direction]: true, value: newValue });
    // setTimeout(() => {
    //   this.setState({ [this.direction]: true, value: newValue });
    // }, 10)
  };

  getSelectionLabel = (id) => {
    const { selectionPrintLabels } = this.props;
    if (selectionPrintLabels && selectionPrintLabels[id]) {
      if (selectionPrintLabels[id].indexOf("{$competitor1}") > -1) {
        return selectionPrintLabels[id].replace("{$competitor1}", "1");
      } else if (selectionPrintLabels[id].indexOf("{$competitor2}") > -1) {
        return selectionPrintLabels[id].replace("{$competitor2}", "2");
      } else {
        return selectionPrintLabels[id];
      }
    }
    return "";
  };

  /**
   * Handle mouse event
   * Set opacity to 0.2 when mouse hover
   */

  handleMouseEnter = (o) => {
    const { dataKey } = o;
    const { opacity } = this.state;

    this.setState({
      opacity: { ...opacity, [dataKey]: 0.2 },
    });
  };

  /**
   * Handle mouse event
   * Set opacity to 1 when mouse leave
   */

  handleMouseLeave = (o) => {
    const { dataKey } = o;
    const { opacity } = this.state;

    this.setState({
      opacity: { ...opacity, [dataKey]: 1 },
    });
  };

  /**
   * Return chart legends
   */

  chartLegendContent = (props) => {
    if (!props.payload[0]) return null;

    const { payload } = props;

    return (
      <ul style={{ textAlign: "center" }}>
        {payload.map((item, key) => (
          <span
            style={{ color: item.color, padding: "10px" }}
            key={`item-${key}`}
            onClick={this.handleMouseEnter}
            onMouseLeave={this.handleMouseLeave}
          >
            {this.getSelectionLabel(item.value)}
          </span>
        ))}
      </ul>
    );
  };

  /**
   * Return chart tooltip content
   */

  chartToolTipContent = (toolTipprops) => {
    if (!toolTipprops.payload[0]) return null;
    const { selectionIds } = this.props;

    return (
      <div
        style={{
          background: "var(--color-contrast)",
          color: "var(--color-main)",
          padding: "5px",
        }}
      >
        <div>
          <b>
            Quote:{" "}
            {toolTipprops.payload[0].payload[selectionIds[this.state.menuItem]]}
          </b>
        </div>
        <div>Zeitpunkt: {toolTipprops.payload[0].payload.gM}</div>
        <div>Spielstand: {toolTipprops.payload[0].payload.sc}</div>
      </div>
    );
  };

  /**
   * Return comparison chart legends
   */

  comparisonChartToolTipContent = (props) => {
    if (!props.payload[0]) return null;

    return (
      <div
        style={{
          background: "var(--color-contrast)",
          color: "var(--color-main)",
          padding: "5px",
        }}
      >
        {props.payload.map((item) => (
          <div key={item.name}>
            <b>
              {this.getSelectionLabel(item.name)}: {item.value}
            </b>
          </div>
        ))}
        <div>Zeitpunkt: {props.payload[0].payload.gM}</div>
        <div>Spielstand: {props.payload[0].payload.sc}</div>
      </div>
    );
  };

  /**
   * Show comparison chart
   */

  renderComparisonChart() {
    const { oddsHistory, marketId, selectionIds } = this.props;
    // const { opacity } = this.state;

    const colors = [
      "var(--color-contrast)",
      "var(--color-contrast-2)",
      "#cae6fb",
      "#477598",
    ];

    return (
      <div className="chart-box">
        <ResponsiveContainer width="99%" aspect={3}>
          <LineChart
            data={oddsHistory[marketId]}
            margin={{
              left: -38,
              top: 5,
            }}
          >
            <XAxis dataKey="gM" />
            <YAxis />
            <Tooltip content={this.comparisonChartToolTipContent} />
            <Legend content={this.chartLegendContent} verticalAlign="top" />
            {Object.values(selectionIds).map((value, key) => {
              // return <Area type="monotone" key={selectionIds[key - 1]} strokeOpacity={opacity[selectionIds[key - 1]]} dataKey={selectionIds[key - 1]} stackId="1" stroke={colors[key - 1]} fill={colors[key - 1]} />
              return (
                <Line
                  type="monotone"
                  key={value}
                  dataKey={value}
                  stackId="1"
                  stroke={colors[key]}
                  fill={colors[key]}
                  dot={false}
                />
              );
            })}

            {/* <Area type="monotone" dataKey="pv" stackId="1" stroke="#82ca9d" fill="#82ca9d" />
            <Area type="monotone" dataKey="amt" stackId="1" stroke="#ffc658" fill="#ffc658" /> */}
          </LineChart>
        </ResponsiveContainer>
      </div>
    );
  }

  /**
   * Show selected odd's chart
   */

  renderSelectionChart() {
    const { oddsHistory, marketId, selectionIds } = this.props;
    return (
      <div className="chart-box">
        <ResponsiveContainer width="99%" aspect={3}>
          <LineChart
            data={oddsHistory[marketId]}
            margin={{
              left: -38,
              top: 5,
            }}
          >
            {/* <defs>
              <linearGradient id="colorPrice1" x1="0" y1="0" x2="0" y2="1">
                <stop offset="33%" stopColor="var(--green)" stopOpacity={1} />
                <stop offset="75%" stopColor="var(--grey-5)" stopOpacity={0.5} />
                <stop offset="99%" stopColor="var(--red)" stopOpacity={0.2} />
              </linearGradient>
              <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
              </linearGradient>
            </defs> */}
            {/* <CartesianGrid strokeDasharray="3 3" /> */}
            <XAxis dataKey="gM" />
            <YAxis />
            <Tooltip active={true} content={this.chartToolTipContent} />
            <Line
              type="monotone"
              dataKey={selectionIds[this.state.menuItem]}
              stackId="1"
              stroke="var(--color-contrast)"
              dot={false}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    );
  }

  getSelectionTabs() {
    const { selectionIds } = this.props;

    const tabs = [];

    selectionIds?.forEach((key, index) => {
      if (key) tabs.push(this.getSelectionLabel(key));
    });

    tabs.push("Vergleichen");

    return tabs;
  }

  renderSelectionTabs() {
    const { classes } = this.props;
    const selectionTabsItems = this.getSelectionTabs();

    return (
      <div className={classes.root}>
        <AppBar
          position="sticky"
          style={{ backgroundColor: "var(--grey-7)", fontSize: "0.87em" }}
        >
          <Tabs
            value={this.state.menuItem}
            onChange={this.handleChange.bind(this)}
            variant="scrollable"
            indicatorColor="primary"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            {selectionTabsItems.map((item, key) => (
              <Tab key={key} value={key} label={item} {...a11yProps(key)} />
            ))}
          </Tabs>
        </AppBar>
      </div>
    );
  }

  renderMomentum() {
    return (
      <div>
        <div className="font-s content-center font-bold chart-box-title vertical-center">
          <h6>
            <span>Ereignisse & Spielverteilung</span>
          </h6>
        </div>
        <div className="font-s content-center chart-box-info vertical-center">
          (Für weitere Details auf Symbole und Quoten klicken)
        </div>
        <div>
          <Momentum gameId="23831649" />
        </div>
      </div>
    );
  }

  render() {
    const { oddsHistory, marketId } = this.props;
    log(this.constructor.name, "render");
    if (
      !oddsHistory ||
      !oddsHistory[marketId] ||
      !Object.keys(oddsHistory[marketId])?.length
    )
      return (
        <div className="grey-3 font-m content-center vertical-center">
          loading
        </div>
      );

    const selectionTabsItems = this.getSelectionTabs();
    return (
      <div>
        {this.renderSelectionTabs()}
        {this.renderMomentum()}
        <div className="font-s content-center font-bold chart-box-title vertical-center">
          <h6>
            <span>Quotenentwicklung</span>
          </h6>
        </div>
        {this.state.menuItem === selectionTabsItems?.length - 1
          ? this.renderComparisonChart()
          : this.renderSelectionChart()}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let leagueId = ownProps.leagueId;
  let gameId = ownProps.gameId;
  let marketId = ownProps.marketId;
  let selectionIds = ownProps.selectionIds;

  const validSelectionIds = [];

  selectionIds?.forEach((key, index) => {
    if (key) validSelectionIds.push(key);
  });

  return {
    leagueId: leagueId,
    gameId: gameId,
    marketId: marketId,
    hc: ownProps.hc,
    selectionIds: validSelectionIds,
    oddsHistory:
      state.selected.leagues[leagueId] &&
      state.selected.leagues[leagueId]["games"][gameId]?.oddsHistory
        ? state.selected.leagues[leagueId]["games"][gameId].oddsHistory
        : null,
    selectionPrintLabels: state.labels.selection
      ? state.labels.selection.print
      : null,
  };
};

export default connect(mapStateToProps, { getOddsHistory })(
  withStyles(styles)(OddsChart)
);
