/**
 * Settings page
 */
import { log } from "../../config/log";
import React from "react";
import { connect } from "react-redux";
import { Translate } from "react-redux-i18n";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import AppBar from "@mui/material/AppBar";
import withStyles from "@mui/styles/withStyles";
import PasswordUpdate from "./PasswordUpdate";
import Limits from "./Limits";
import UserBlock from "./UserBlock";
import Subscriptions from "./Subscriptions";
import UserUpdate from "./UserUpdate";
import withRouter from "../../helpers/withRouter";


const styles = (theme) => ({
  root: {
    "& .MuiTabs-root": {
      minHeight: "3.2em",
    },
    "& .MuiTab-root": {
      fontSize: "1.2em",
      minHeight: "1em",
      textTransform: "uppercase !important",
      color: "var(--grey)",
    },
    "@media (hover:hover)": {
      backgroundColor: "var(--color-main)",
    },
    "& .MuiTab-textColorInherit.Mui-selected": {
      fontWeight: "bold",
      color: "var(--color-contrast)",
    },
    "& .MuiTabs-indicator": {
      display: "none",
    },
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    position: "sticky",
    zIndex: 999,
    top: 0,
  },
  menuItems: {
    flexGrow: 1,
    width: "100%",
    position: "fixed",
    bottom: "66px",
    zIndex: 1,
    backgroundColor: theme.palette.background.paper,
  },
  indicatorRemove: {
    display: "none",
  },
});

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

class Settings extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      value: 0,
    };
  }

  componentDidMount() {
    const { auth, locale, navigate } = this.props;
    if (!auth.isSignedIn) navigate(`/${locale}/sign-in`);
  }

  componentDidUpdate() {
    const { auth, locale, navigate } = this.props;
    if (!auth.isSignedIn) navigate(`/${locale}/sign-in`);
  }

  renderTabContent = () => {
    /**
     * Render correct page from select tab menu
     */

    switch (this.state.value) {
      case 0:
        return <PasswordUpdate />;
      case 1:
        return <Limits />;
      case 2:
        return <UserBlock />;
      case 3:
          return <UserUpdate />;
      case 4:
        return <Subscriptions />;
      default:
        break;
    }
  };

  menuItemChange = (event, newValue) => {
    this.setState({ value: newValue });
  };

  menuItemChangeIndex = (newValue) => {
    this.setState({ value: newValue });
  };

  render() {
    log(this.constructor.name, "render");
    const { classes } = this.props;
    const menuItems = [
      <Translate value="labels.settings_change_pw" />,
      <Translate value="labels.settings_limits" />,
      <Translate value="labels.settings_blocks" />,
      <Translate value="labels.my_data" />,
      <Translate value="labels.settings_notes" />,
    ];

    return (
      <div key="app-bar">
        <div className={classes.root}>
          <AppBar
            position="static"
            sx={{ backgroundColor: "var(--bg-tabs-1)" }}
            elevation={0}
          >
            <Tabs
              value={this.state.value}
              onChange={this.menuItemChange.bind(this)}
              variant="scrollable"
              indicatorColor="primary"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
              classes={{
                indicator: classes.indicatorRemove,
              }}
            >
              {menuItems.map((item, key) => (
                <Tab key={key} value={key} label={item} {...a11yProps(key)} />
              ))}
            </Tabs>
          </AppBar>
          {this.renderTabContent()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.user.auth,
    locale: state.i18n.locale,
  };
};

export default withRouter(connect(mapStateToProps)(withStyles(styles)(Settings)));
