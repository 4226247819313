/**
 * Promotions Page - Estonian version
 */

import { log } from "../../../config/log";
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Grid, Paper, Typography } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";

const styles = (theme) => ({
  root: {
    padding: "1.8em",
    fontSize: "var(--content)",
  },
  listItems: {
    color: "var(--color-contrast)",
    fontSize: "1.2em",
    fontWeight: "bold",
  },
  promo: {
    height: "auto",
    width: "100%",
    borderRadius: "0.7em 0.7em 0 0",
  },
  promoGrid: {
    "& .MuiGrid-item": {
      padding: "0 0.5em 0.5em 0.5em !important",
      "@media (hover:hover)": {
        padding: "0 0.5em !important",
      },
    },
  },
});

class Promotions extends React.PureComponent {
  goTop = () => { };

  render() {
    log(this.constructor.name, "render");
    const { host, classes, locale, auth, url, siteTheme } = this.props;

    if (!host) return null;

    return (
      <Container style={{ backgroundColor: "var(--color-main)", height: "100%" }} maxWidth={false}>
        <Grid className={classes.promoGrid} container spacing={2} style={{ display: "flex", alignItems: "stretch" }}>
          {/* <Grid item xs={12} sm={4} style={{ display: "flex", alignItems: "stretch" }}>
            <Paper style={{ margin: "0.5em", backgroundColor: "var(--bg-promo)" }}>
              <img className={classes.promo} src={`https://storage.googleapis.com/nubet-prod-content-news/banner_casino_sm_gemix.jpg`} alt="banner-casino-promo" />
              <Typography component="span">
                <div id="legalcontent" className={classes.root}>
                  <h1>Kas sina oled Gemixi keiser? Jagamisel 20-eurosed megakeerutused!</h1>
                  <br></br>
                  Nüüd selgub, kes on Gemixi keiser!<br></br><br></br>
                  Sinu eesmärk on saada võimalikult suur kordaja algses Gemixis, Gemix 2-s või uues Gemix 100-s.<br></br><br></br>
                  Võitja saab endale 150 tükki 20-euroseid megakeerutusi!
                  <br></br>
                  <div style={{ textAlignLast: "center" }}>
                    <Link to={`/${locale}/casino/featured-games?filter=tournament`}>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{
                          margin: "2em 0",
                          color: "var(--btn-text)",
                          padding: "1em 1em",
                          fontSize: "1em",
                          background: "linear-gradient(0deg, rgba(29,88,154,1) 0%, rgba(61,163,253,1) 50%, rgba(29,88,154,1) 100%)",
                        }}
                      >
                        Mängi nüüd
                      </Button>
                    </Link>
                  </div>
                </div>
              </Typography>
            </Paper>
          </Grid> */}
          <Grid item xs={12} sm={4}>
            <Paper style={{ margin: "0.5em", backgroundColor: "var(--bg-promo)" }}>
              <img className={classes.promo} src={`https://storage.googleapis.com/nubet-prod-content-news/nbt_banner_cta_25_gif_478_230_et_${siteTheme}.gif`} alt="banner-casino-promo" />
              <Typography component="span">
                <div id="legalcontent" className={classes.root}>
                  <h1>Alustage panustamist Nubetiga ilma vähimagi riskita kaotada!</h1>
                  <br></br>
                  Olgu teie esimene panus Nubetis võidukas - ja isegi kui see ei ole, ei ole see oluline. Aga kui teie esimene panus läheb katki, siis krediteerime selle otse teie kontole tagasi, ilma küsimata ja ilma
                  väikse kirjapanduta.<br></br>
                  <br></br>
                  <h3>Kuidas panustada ilma riskita</h3>
                  <span className={classes.listItems}>1. </span>Tehke deposiit ja avage samal ajal Nubeti konto.<br></br>
                  <br></br>
                  <span className={classes.listItems}>2. </span>Tee oma soovitud panus 25 euro suuruse võrdse panusega. Krediteeritav panus peab olema arveldatud 48 tunni jooksul pärast konto avamist (Betmikseri ja
                  pikaajalised panused ei ole lubatud).<br></br>
                  <br></br>
                  <span className={classes.listItems}>3. </span>Kui teie panus on vale, krediteerime teie kontole 25 € (kui teie panus võidab, siis loomulikult säilitate oma võidud nagu tavaliselt). Hüvitis makstakse
                  välja hiljemalt kell 12 päeval pärast panuse arveldamist.<br></br>
                  <div style={{ textAlignLast: "center" }}>
                    {!auth.isSignedIn ? (
                      <Link to={`/${locale}/register-switch`}>
                        <Button
                          variant="contained"
                          color="primary"
                          style={{
                            margin: "2em 0",
                            color: "var(--btn-text)",
                            padding: "1em 1em",
                            fontSize: "1em",
                            background: "linear-gradient(0deg, rgba(29,88,154,1) 0%, rgba(61,163,253,1) 50%, rgba(29,88,154,1) 100%)",
                          }}
                        >
                          HOIUSTAGE JA TEHKE RISKIVABA PANUS
                        </Button>
                      </Link>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        disabled
                        style={{
                          margin: "2em 0",
                          color: "var(--btn-text)",
                          padding: "1em 1em",
                          fontSize: "1em",
                        }}
                      >
                        UUTELE KLIENTIDELE
                      </Button>
                    )}
                  </div>
                </div>
              </Typography>
            </Paper>
          </Grid>

          {/* <Grid item xs={12} sm={4} style={{ display: 'flex', alignItems: 'stretch' }}>
            <Paper style={{ margin: "0.5em", backgroundColor: "var(--bg-promo)" }}>
              <img className={classes.promo} src={`https://storage.googleapis.com/nubet-prod-content-news/nbt_banner_euro2024_promo_welcome_${locale}_${siteTheme}.jpg`} alt="banner-euro-promo" />
              <Typography component="span">
                <div id="legalcontent" className={classes.root}>

                  <h1>
                    Euro 2024 tervitusboonus<br></br>
                    (AINULT UUED KLIENDID) 
                  </h1>
                  <br></br>
                  Aeg luua oma pangakontot Euro 2024 jaoks!<br></br><br></br>
                  Tehke oma ESIMENE SISSEMAKSED ja saate 100% boonuse kuni 100 eur.<br></br><br></br>
                  Lisaks saad 100 tasuta keerutust Book of Dead mängule.<br></br>
                  <br></br>
                  <h3>Järgige neid lihtsaid samme, et nõuda tervitusboonust:</h3>
                  <span className={classes.listItems}>1. </span>Tehke deposiit ja registreerige end protsessi käigus kliendiks<br></br>
                  <br></br>
                  <span className={classes.listItems}>2. </span>Boonus ja tasuta keerutused lisatakse teie kontole<br></br>
                  <br></br>
                  <span className={classes.listItems}>3. </span>Boonusraha ja tasuta keerutuste võitmise suhtes kehtib 4x ümberlülitamisnõue.<br></br>
                  <br></br>
                  <span className={classes.listItems}>4. </span>Ümberlaadimisboonust ei saa kombineerida ühegi teise boonusega.<br></br>
                  <br></br>
                  <span className={classes.listItems}>5. </span>Kehtivad üldised boonustingimused.<br></br>
                  <div style={{ textAlignLast: "center" }}>
                    {!auth.isSignedIn ? (
                      <Link to={`/${locale}/register-switch`}>
                        <Button
                          variant="contained"
                          color="primary"
                          style={{
                            margin: "2em 0",
                            color: "var(--btn-text)",
                            padding: "1em 1em",
                            fontSize: "1em",
                            background: "linear-gradient(0deg, rgba(29,88,154,1) 0%, rgba(61,163,253,1) 50%, rgba(29,88,154,1) 100%)"
                          }}
                        >
                          REGISTREERUDA JA SAADA BOONUST
                        </Button>
                      </Link>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        disabled
                        style={{
                          margin: "2em 0",
                          color: "var(--btn-text)",
                          padding: "1em 1em",
                          fontSize: "1em",
                        }}
                      >
                        UUTE KLIENTIDE EEST
                      </Button>
                    )}
                  </div>
                </div>
              </Typography>
            </Paper>
          </Grid> */}

          <Grid item xs={12} sm={4} style={{ display: "flex", alignItems: "stretch" }}>
            <Paper style={{ margin: "0.5em", backgroundColor: "var(--bg-promo)" }}>
              <img className={classes.promo} src={`https://storage.googleapis.com/nubet-prod-content-news/nbt_banner_cta_bookofpower_gif_478_230_1_${siteTheme}.gif`} alt="banner-casino-promo" />
              <Typography component="span">
                <div id="legalcontent" className={classes.root}>
                  <h1>Alusta mängimist Nubet kasiinos 100 tasuta keerutustega!</h1>
                  <br></br>
                  Kui teete oma ESIMESE sissemakse Nubetis, saate kohe 100 tasuta keerutust Book of Power’is.<br></br>
                  <br></br>
                  Mäng pakub lausa 15.000x maksimaalset väljamakseid!
                  <br></br>
                  <br></br>
                  <h3>Kuidas saada tasuta keerutusi</h3>
                  <span className={classes.listItems}>1. </span>Tehke sissemakse ja avage samal ajal Nubeti konto.<br></br>
                  <br></br>
                  <span className={classes.listItems}>2. </span>Avage Book of Power mäng ja tasuta keerutused ootavad teid mängus<br></br>
                  <br></br>
                  <span className={classes.listItems}>3. </span>Tasuta keerutustest saadud võitudele kehtib 1x panustamisnõue.<br></br>
                  <br></br>
                  <div style={{ textAlignLast: "center" }}>
                    {!auth.isSignedIn ? (
                      <Link to={`/${locale}/register-switch`}>
                        <Button
                          variant="contained"
                          color="primary"
                          style={{
                            margin: "2em 0",
                            color: "var(--btn-text)",
                            padding: "1em 1em",
                            fontSize: "1em",
                            background: "linear-gradient(0deg, rgba(29,88,154,1) 0%, rgba(61,163,253,1) 50%, rgba(29,88,154,1) 100%)",
                          }}
                        >
                          DEPOSIIT JA MÄNGI TASUTA KEERUTUSI
                        </Button>
                      </Link>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        disabled
                        style={{
                          margin: "2em 0",
                          color: "var(--btn-text)",
                          padding: "1em 1em",
                          fontSize: "1em",
                        }}
                      >
                        UUTELE KLIENTIDELE
                      </Button>
                    )}
                  </div>
                </div>
              </Typography>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={4} style={{ display: "flex", alignItems: "stretch" }}>
            <Paper style={{ margin: "0.5em", backgroundColor: "var(--bg-promo)" }}>
              <img className={classes.promo} src={`https://storage.googleapis.com/nubet-prod-content-news/nbt_banner_comboboost_${locale}_${siteTheme}.gif`} alt="banner-casino-promo" />
              <Typography component="span">
                <div id="legalcontent" className={classes.root}>
                  <h1>Combo Boost</h1>
                  Miks peaksite leppima madalamate koefitsientidega kombodes? Combo Boostiga saate märkimisväärse panuse suurendamise. Mida pikem on kombinatsioon, seda suurem on boonus. Boonus lisatakse netovõidule
                  vastavalt alljärgnevale tabelile.<br></br>
                  <br></br>
                  <Grid container style={{ fontSize: "0.8em", textAlignLast: "center" }}>
                    <Grid item xs={3} sm={3} style={{ color: "var(--color-contrast)", fontWeight: "bold" }}>
                      Valikute
                    </Grid>
                    <Grid item xs={3} sm={3} style={{ color: "var(--color-contrast)", fontWeight: "bold" }}>
                      Boonus
                    </Grid>
                    <Grid item xs={3} sm={3} style={{ color: "var(--color-contrast)", fontWeight: "bold" }}>
                      Valikute
                    </Grid>
                    <Grid item xs={3} sm={3} style={{ color: "var(--color-contrast)", fontWeight: "bold" }}>
                      Boonus
                    </Grid>
                    <Grid item xs={3} sm={3}>
                      3
                    </Grid>
                    <Grid item xs={3} sm={3}>
                      5%
                    </Grid>
                    <Grid item xs={3} sm={3}>
                      10
                    </Grid>
                    <Grid item xs={3} sm={3}>
                      27%
                    </Grid>
                    <Grid item xs={3} sm={3}>
                      4
                    </Grid>
                    <Grid item xs={3} sm={3}>
                      7%
                    </Grid>
                    <Grid item xs={3} sm={3}>
                      11
                    </Grid>
                    <Grid item xs={3} sm={3}>
                      30%
                    </Grid>
                    <Grid item xs={3} sm={3}>
                      5
                    </Grid>
                    <Grid item xs={3} sm={3}>
                      10%
                    </Grid>
                    <Grid item xs={3} sm={3}>
                      12
                    </Grid>
                    <Grid item xs={3} sm={3}>
                      35%
                    </Grid>
                    <Grid item xs={3} sm={3}>
                      6
                    </Grid>
                    <Grid item xs={3} sm={3}>
                      12%
                    </Grid>
                    <Grid item xs={3} sm={3}>
                      13
                    </Grid>
                    <Grid item xs={3} sm={3}>
                      40%
                    </Grid>
                    <Grid item xs={3} sm={3}>
                      7
                    </Grid>
                    <Grid item xs={3} sm={3}>
                      15%
                    </Grid>
                    <Grid item xs={3} sm={3}>
                      14
                    </Grid>
                    <Grid item xs={3} sm={3}>
                      45%
                    </Grid>
                    <Grid item xs={3} sm={3}>
                      8
                    </Grid>
                    <Grid item xs={3} sm={3}>
                      20%
                    </Grid>
                    <Grid item xs={3} sm={3}>
                      15
                    </Grid>
                    <Grid item xs={3} sm={3}>
                      50%
                    </Grid>
                    <Grid item xs={3} sm={3}>
                      9
                    </Grid>
                    <Grid item xs={3} sm={3}>
                      25%
                    </Grid>
                    <Grid item xs={3} sm={3}></Grid>
                    <Grid item xs={3} sm={3}></Grid>
                  </Grid>
                  <h3>Kampaania reeglid</h3>
                  <span className={classes.listItems}>1. </span> Kampaania kehtib kuni edasise teatamiseni<br></br>
                  <span className={classes.listItems}>2. </span>Kehtib ainult 1X2 ja võitja (moneyline) turgudel.<br></br>
                  <span className={classes.listItems}>3. </span>Minimaalne valiku koefitsient on vähemalt 1.2<br></br>
                  <span className={classes.listItems}>4. </span>Boonusraha ei saa kasutada pakkumise puhul<br></br>
                  <span className={classes.listItems}>5. </span>Kui üks või mitu valikut kombinatsioonis on kehtetu, määratakse boonus ümber ülejäänud valikute alusel.<br></br>
                  <span className={classes.listItems}>6. </span>Kui panus tühistatakse, boonust ei maksta.<br></br>
                </div>
              </Typography>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={4} style={{ display: "flex", alignItems: "stretch" }}>
            <Paper style={{ margin: "0.5em", backgroundColor: "var(--bg-promo)" }}>
              <img className={classes.promo} src={`https://storage.googleapis.com/nubet-prod-content-news/nbt_banner_comboboost_trotting_anim_${locale}_${siteTheme}.gif`} alt="banner-casino-promo" />
              <Typography component="span">
                <div id="legalcontent" className={classes.root}>
                  <h1>Racing Boost</h1>
                  Racing Boostiga saate luua mitte ainult kombinatsioone, vaid ka süsteeme, mille võitu suurendatakse. Võite valida mitu hobust mitmele võistlusele oma panuse raames. Võite isegi segada rootsi ja soome võistlusi. Ja täpselt nagu Combo Boostis, mida pikem on kombinatsioon, seda suurem on boonus. Boonus lisatakse netovõidule vastavalt allpool esitatud tabelile.<br></br>
                  <br></br>
                  <Grid container style={{ fontSize: "0.8em", textAlignLast: "center" }}>
                    <Grid item xs={6} sm={6} style={{ color: "var(--color-contrast)", fontWeight: "bold" }}>
                      Valikud
                    </Grid>
                    <Grid item xs={6} sm={6} style={{ color: "var(--color-contrast)", fontWeight: "bold" }}>
                      Boonus
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      3
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      10%
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      4
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      17%
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      5
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      25%
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      6
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      34%
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      7
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      42%
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      8
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      50%
                    </Grid>
                  </Grid>
                  <br></br>
                  <h3>Kampaania reeglid</h3>
                  <span className={classes.listItems}>1. </span>Kampaania kehtib kuni edasise etteteatamiseni<br></br>
                  <span className={classes.listItems}>2. </span>Kehtib ainult võidukale otseturule traaversioonis<br></br>
                  <span className={classes.listItems}>3. </span>Minimaalne valiku koefitsient on vähemalt 1.2<br></br>
                  <span className={classes.listItems}>4. </span>Boonusraha ei saa kasutada pakkumise puhul<br></br>
                  <span className={classes.listItems}>5. </span>Kui üks või mitu valikut kombinatsioonis on kehtetu, määratakse boonus ümber ülejäänud valikute alusel.<br></br>
                  <span className={classes.listItems}>6. </span>Kui panus tühistatakse, siis boonust ei maksta.<br></br>
                  <br></br>
                </div>
              </Typography>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={4} style={{ display: "flex", alignItems: "stretch" }}>
            <Paper style={{ margin: "0.5em", backgroundColor: "var(--bg-promo)" }}>
              <img className={classes.promo} src={`https://storage.googleapis.com/nubet-prod-content-news/banner_casino_sm_dropswins_${siteTheme}.jpg`} alt="banner-casino-promo" />
              <Typography component="span">
                <div id="legalcontent" className={classes.root}>
                  <h1>Drops & Wins mängudes kahe miljoni euro suurune kampaania!</h1>
                  <br></br>
                  Osale Pragmatic Play väga populaarses Drops & Wins kampaanias, kus jagatakse iga kuu kaks miljonit eurot.<br></br>
                  <br></br>

                  <h3>Igapäevased dropid:</h3>
                  <span className={classes.listItems}>– </span>Iga päev jagatakse 30 000 € sularaha<br></br>
                  <span className={classes.listItems}>– </span>6500 sularahaauhinda päevas<br></br>
                  <span className={classes.listItems}>– </span>Võida kuni 2500x oma panus<br></br>
                  <br></br>

                  <h3>Iganädalased turniirid:</h3>
                  <span className={classes.listItems}>– </span>40 000 € päevane auhinnafond<br></br>
                  <span className={classes.listItems}>– </span>5000 € päevane peaauhind<br></br>
                  <span className={classes.listItems}>– </span>3500 auhinda jagamisel<br></br>
                  <span className={classes.listItems}>– </span>Iga nädal kolmapäevast kolmapäevani<br></br>
                  <br></br>

                  <div style={{ textAlignLast: "center" }}>

                    <Link to={`/${locale}/casino/featured-games?filter=dropsandwins`}>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{
                          margin: "2em 0",
                          color: "var(--btn-text)",
                          padding: "1em 1em",
                          fontSize: "1em",
                          background: "linear-gradient(0deg, rgba(29,88,154,1) 0%, rgba(61,163,253,1) 50%, rgba(29,88,154,1) 100%)",
                        }}
                      >
                        Mängi nüüd
                      </Button>
                    </Link>
                  </div>
                </div>
              </Typography>
            </Paper>
          </Grid>


        </Grid>
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    host: state.settings.host,
    locale: state.i18n.locale,
    auth: state.user.auth,
    url: state.settings.url,
    siteTheme: state.settings.theme,
  };
};

export default connect(mapStateToProps, {})(withStyles(styles)(Promotions));
