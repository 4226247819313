/**
 * React app wrapper
 */
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-lazy-load-image-component/src/effects/blur.css";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import reduxThunk from "redux-thunk";
import {
  setLocale,
  loadTranslations,
  syncTranslationWithStore,
} from "react-redux-i18n";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/es/integration/react";
// import { CookiesProvider } from 'react-cookie';
// import Cookies from 'js-cookie';
// import { createCookieMiddleware } from 'redux-cookie';

import App from "./components/App";
import reducers from "./reducers";
import translations from "./l10n/translations";

// import LogRocket from 'logrocket';
// LogRocket.init('vqaxbd/betv3dev');

import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import BugsnagPerformance from "@bugsnag/browser-performance";
import history from "./history";
import ThemeProvider from "./theme";

Bugsnag.start({
  apiKey: "02ebe461780cfb52fce01a85e57325d0",
  plugins: [new BugsnagPluginReact()],
  // enabledReleaseStages: ['production'],
});
BugsnagPerformance.start({ apiKey: "02ebe461780cfb52fce01a85e57325d0" });

const logReactRedux = (store) => (next) => (action) => {
  let result = next(action);
  if ("log-react-redux" in localStorage) {
    console.groupCollapsed(
      "%c" + action.component + action.type,
      "color:#D3D497"
    );
    console.info("dispatching", action);
    console.log("next state", store.getState());
    console.groupEnd();
    // Bugsnag.notify(new Error('Test error'))
  }

  return result;
};

//const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(reduxThunk, logReactRedux))
);
const getLanguage = () => {
  const activeRoute = window.location.pathname;
  const searchParams = new URL(window.location.href)?.search;
  const queryString = searchParams ? searchParams : "";
  const savedLang = localStorage.getItem("lang");
  const routeLang = activeRoute
    .split("/")
    ?.filter((bl) => bl === "en" || bl === "fi" || bl === "et")[0];
  const browserLangAgent = navigator.language || navigator.userLanguage;
  let browserLang = undefined;
  if (browserLangAgent?.includes("fi")) {
    browserLang = "fi";
  }
  if (browserLangAgent?.includes("et")) {
    browserLang = "et";
  }
  if (browserLangAgent?.includes("en")) {
    browserLang = "en";
  }

  const list = ["en", "fi", "et"];
  if (list.includes(routeLang)) {
    localStorage.setItem("lang", savedLang);
    if (activeRoute?.includes("sitemap.xml")) return;
    return routeLang;
  } else if (savedLang && list.includes(savedLang)) {
    localStorage.setItem("lang", savedLang);
    if (activeRoute?.includes("sitemap.xml")) return;
    history.push(`/${savedLang}${activeRoute}${queryString}`);
    return savedLang;
  } else if (browserLang) {
    localStorage.setItem("lang", browserLang);
    if (activeRoute?.includes("sitemap.xml")) return;
    history.push(`/${browserLang}${activeRoute}${queryString}`);
    return browserLang;
  } else {
    localStorage.setItem("lang", list[1]);
    if (activeRoute?.includes("sitemap.xml")) return;
    history.push(`/${list[1]}${activeRoute}${queryString}`);
    return list[1];
  }
};
syncTranslationWithStore(store);
store.dispatch(loadTranslations(translations));
store.dispatch(setLocale(getLanguage()));
let persistor = persistStore(store);
const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ThemeProvider>
        <ErrorBoundary>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </ErrorBoundary>
      </ThemeProvider>
    </PersistGate>
  </Provider>
);

//Bugsnag.notify(new Error('Test error'))
