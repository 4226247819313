/**
 * TreeViewerLeaguesFilterTabs - Currently not being used ( part of LeaguesTreeViewer )
 */

import { log } from '../../config/log';
import React from 'react';
import { connect } from 'react-redux';
import AppBar from '@mui/material/AppBar';
import withStyles from '@mui/styles/withStyles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Slider from '@mui/material/Slider';
import Button from '@mui/material/Button';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';


function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const styles = (theme) => ({
  root: {
    '& .MuiTabs-root': {
      minHeight: "1em",
    },
    '& .MuiTab-root': {
      minHeight: "1em",
      fontSize: '1.3em',
      minWidth: '1em',
      padding: '0.5em 0',
      "@media (hover:hover)": {
        fontSize: '1.2em !important'
      },
    },
    '& .MuiSvgIcon-fontSizeSmall': {
      fontSize: '1.3em',
      padding: '0.2em',
    },
    '& .MuiTab-fullWidth': {
      flexBasis: 'unset'
    },
    flexGrow: 1,
    width: '100%',
    color: 'var(--white)',
    background: 'var(--bg-secondary)'
  },
  slider: {
    '& .MuiSlider-markLabel': {
      fontSize: "0.65rem",
    },
  },
  title: {
    flexGrow: 1,
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    borderRadius: 0,
    marginLeft: 0,
    width: '100%',
  },
  searchIcon: {
    padding: '1em 1em',
  },
  inputRoot: {
    width: '82%',
  },
  inputInput: {
    padding: theme.spacing(1.5, 0, 1.5, 2),
  },
});

class TreeViewerLeaguesFilterTabs extends React.PureComponent {
  constructor(props) {
    super(props);

    const { leagueFilter } = this.props;

    this.menuItems = [];
    this.state = {
      value: leagueFilter ? leagueFilter : 'live',
      searchTerm: ''
    }
  }

  handleChange = (event, newValue) => {
    this.setState({ value: newValue }, () => {
      this.props.updateLeagueFilter(newValue);
    });

  };

  handleCalendarChange = (event, newValue) => {
    // console.log('change' + newValue);
  };

  calcCalendarLabel(value) {
    switch (value) {
      case 0:
        return "Bewerbe in der nächsten Stunde";
      case 25:
        return "Bewerbe in den nächsten 3 Stunden";
      case 50:
        return "Heutige Bewerbe";
      default:
        break;
    }
  }

  onSearchSubmit = event => {
    event.preventDefault();
  }

  renderSearch() {
    const { classes } = this.props;
    return (

      <div className={classes.search}>
        <InputBase
          placeholder="Suchbegriff eingeben"
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
          }}
          inputProps={{ 'aria-label': 'search' }}
        />
        <Button style={{ padding: "0 0 0 0" }}>
          <SearchIcon color="primary" />
        </Button>
      </div>
    );
  }

  renderCalendar() {
    const { classes } = this.props;

    const CalendarSlider = withStyles({
      valueLabel: {
        display: 'block !important',
        transform: 'rotate(0)',
        width: '200px'
      },
      PrivateValueLabel: {
        display: 'block !important',
        transform: 'rotate(0)',
        width: '200px'
      },
    })(Slider);

    const marks = [
      {
        value: 0,
        label: '< 3h',
      },
      {
        value: 50,
        label: 'Heute',
      },
      {
        value: 100,
        label: 'Morgen',
      },
    ];

    return (
      <div>
        <div className="color-contrast font-bold-2 font-l" style={{ margin: "1em 0 -0.5em 1em" }}>Live in</div>
        <div className="text-last-center">
          <div className={classes.slider}>
            <CalendarSlider
              defaultValue={50}
              getAriaValueText={this.calcCalendarLabel}
              aria-labelledby="discrete-slider-custom"
              step={25}
              valueLabelDisplay="off"
              valueLabelFormat={this.calcCalendarLabel}
              marks={marks}
              onChangeCommitted={this.handleCalendarChange}
              style={{
                margin: '0.3em 0 2.2em 0',
                width: "85%",
              }}
            />
          </div>
        </div>
      </div>
    );
  }

  render() {
    log(this.constructor.name, 'render');
    const { classes } = this.props;

    return (
      <div>
        <div key="app-bar" className={classes.root}>
          <AppBar position="static" color="transparent">
            <Tabs
              value={this.state.value}
              onChange={this.handleChange.bind(this)}
              variant="fullWidth"
              scrollButtons="auto"
              indicatorColor="primary"
            >
              <Tab value="live" label="Live"  {...a11yProps(0)} key={0} />
              <Tab value="all" label="Alle" {...a11yProps(1)} key={1} />
              <Tab value="lastMinute" label="< 3h" {...a11yProps(2)} key={2} />
              <Tab value="today" label="Heute" {...a11yProps(3)} key={3} />
              <Tab value="tomorrow" label="Morgen" {...a11yProps(4)} key={4} />
            </Tabs>
          </AppBar>
        </div>
        {this.state.value === 'calendar' ? this.renderCalendar() : null}
        {this.state.value === 'search' ? this.renderSearch() : null}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    leagues: state.leagues,
    leagueFilter: state.leagues._leagueFilter,
    leagueGrouping: state.settings.leagueGrouping,
    sportCode: state.leagues._sportCode
  };
};

export default connect(mapStateToProps, { })(withStyles(styles)(TreeViewerLeaguesFilterTabs));