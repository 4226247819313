import { useEffect } from "react";
import PropTypes from "prop-types";

const FirstLeagueLoader = ({ callBack, league, availableLeagues }) => {
  useEffect(() => {
    callBack();
  }, [league?.leagueId, availableLeagues?.length]);

  return null;
};

FirstLeagueLoader.propTypes = {
  callBack: PropTypes.func,
  league: PropTypes.object,
  availableLeagues: PropTypes.array,
};

FirstLeagueLoader.defaultProps = {
  callBack: () => {},
  league: {},
  availableLeagues: [],
};

export default FirstLeagueLoader;
