/**
 *  Component for game conference
 *  Show game status and game information with conference type
 */

import { log } from '../../config/log';
import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from "@mui/styles";
import ConferenceBox from './ConferenceBox';
import GameBoxColumn from './GameBoxColumn';
import GameTabs from './GameTabs';

const styles = (theme) => ({
  root: {
    '& .MuiTabs-root': {
      minHeight: "1em",
    },
    '& .MuiTab-root': {
      minHeight: "1em",
    },
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
});

class Conference extends React.PureComponent {
  constructor(props) {
    super(props);
    const { selectedGames } = this.props;

    this.state = {
      selectedGameId: Object.values(selectedGames)[0]['gameid']
    }
  }


  updateGameId(gameId) {
    this.setState({ selectedGameId: gameId });
  }

  render() {
    log(this.constructor.name, 'render');
    const { selectedGames, content, classes } = this.props;
    let selectedGameId = this.state.selectedGameId;
    let game = Object.values(selectedGames).filter(function (game) {
      return game.gameid === selectedGameId;
    });

    return (
      <div className={classes.root}>
        <ConferenceBox games={selectedGames} content={content} updateGameId={this.updateGameId.bind(this)} isInViewport={true} />
        <GameBoxColumn game={game} content="selectedGames" isInViewport={true} />
        <GameTabs game={game} />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {

  let selectedGames = [], selectedGamesWithDuplicates = [], uniqueSet, content = ownProps.content, leagueId = ownProps.leagueId;

  switch (content) {
    default:
      break;

    case 'leagues':
      for (let league in state.selected.leagues) {
        if (state.selected.leagues[league].games) {
          for (let game in state.selected.leagues[league].games) {
            selectedGames.push(state.selected.leagues[league].games[game]);
          }
        }
      }
      break;

    case 'league':
      selectedGames = state.selected.leagues[leagueId].games
      break;

    case 'slip':
      for (let bet in state.slips[state.selected.slipTemplate].settings.bets) {
        let betGame = state.slips[state.selected.slipTemplate].settings.bets[bet].game;
        selectedGamesWithDuplicates.push(betGame);
      }

      uniqueSet = new Set(selectedGamesWithDuplicates);
      selectedGames = [...uniqueSet];
      break;

    case 'selectedTicket':
      for (let bet in state.tickets.selected[0].bets) {
        let betGame = state.tickets.selected[0].bets[bet].game;
        selectedGamesWithDuplicates.push(betGame);
      }

      uniqueSet = new Set(selectedGamesWithDuplicates);
      selectedGames = [...uniqueSet];
      break;

  }



  return {
    content: content,
    selectedGames: Object.values(selectedGames),
    selectedGame: Object.values(selectedGames)[0],
    selectedGameId: Object.values(selectedGames)[0]['gameid'],
  };

}

export default connect(mapStateToProps)(withStyles(styles)(Conference));