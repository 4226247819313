/**
 * SVG component for own icon 
 */

import React from 'react';
import './IconOwn.css';

const IconDT = (props) => {

	return (
		<div style={{ display: "flex" }}>
			<svg className="icon-own" viewBox="0 0 2000 2000">
				<linearGradient gradientTransform="rotate(90)" id="icongradient">
					<stop className="icon-color-1" offset="0%" />
					<stop className="icon-color-2" offset="50%" />
					<stop className="icon-color-1" offset="100%" />
				</linearGradient>
				<g>
				<polygon fill="url(#icongradient)" points="1674.8,383.8 1529.2,246.1 1361,258.7 1315.5,287.2 1237.5,259.3 1090.7,285.1 1656.6,775.9 1685.4,664.1 
		1666.3,618.5 1681.8,569.8 	"/>
	<polygon fill="url(#icongradient)" points="328.8,1532 454.2,1645.8 497,1657.9 500,1687.3 551.1,1733.7 860.1,1681.8 712.1,1475.3 675.6,1466.2 
		650.2,1410.5 330.6,1210.8 329.4,1310.3 370.3,1352.6 329.1,1420.8 	"/>
	<polygon fill="url(#icongradient)" points="498.9,310.8 418.8,390.9 419,433.3 376.5,433.1 307.7,501.9 768.3,1009.6 818.5,1031 820.9,1067.6 1120.6,1398 
		1362.9,1741.9 1667,1690.8 1682.6,1207 1583.9,1132.3 1533.1,1121.9 1524.8,1087.6 	"/>
				</g>
			</svg>
		</div>
	);
};

export default IconDT;
