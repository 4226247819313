/**
 * SportsTabs component
 * Show all available sports including Football, basketball, Ice Hockey
 */

import { log } from "../../config/log";
import React from "react";
import { connect } from "react-redux";
import { Translate } from "react-redux-i18n";
import withStyles from "@mui/styles/withStyles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Badge from "@mui/material/Badge";
import CheckedIcon from "@mui/icons-material/CheckBox";
import IconFL from "./icons/IconFL";
import IconBD from "./icons/IconBD";
import IconTR from "./icons/IconTR";
import IconTT from "./icons/IconTT";
import IconSN from "./icons/IconSN";
import IconDA from "./icons/IconDA";
import IconIH from "./icons/IconIH";
import IconTE from "./icons/IconTE";
import IconCR from "./icons/IconCR";
import IconFB from "./icons/IconFB";
import IconBA from "./icons/IconBA";
import IconBX from "./icons/IconBX";
import IconVB from "./icons/IconVB";
import IconRG from "./icons/IconRG";
import IconAF from "./icons/IconAF";
import IconAR from "./icons/IconAR";
import IconHB from "./icons/IconHB";
import IconMM from "./icons/IconMM";
import IconBB from "./icons/IconBB";
import IconBM from "./icons/IconBM";
import IconCS from "./icons/IconCS";
import IconCU from "./icons/IconCU";
import IconDT from "./icons/IconDT";
import IconHR from "./icons/IconHR";
import IconLL from "./icons/IconLL";
import IconMO from "./icons/IconMO";
import IconSQ from "./icons/IconSQ";
import IconPS from "./icons/IconPS";
import IconWP from "./icons/IconWP";
import IconGO from "./icons/IconGO";
import IconF1 from "./icons/IconF1";
import IconAS from "./icons/IconAS";
import IconBI from "./icons/IconBI";
import IconSJ from "./icons/IconSJ";
import IconCC from "./icons/IconCC";
import IconCH from "./icons/IconCH";
import IconCY from "./icons/IconCY";
import IconFS from "./icons/IconFS";
import IconRA from "./icons/IconRA";
import IconNC from "./icons/IconNC";
import IconGH from "./icons/IconGH";
import IconGF from "./icons/IconGF";
import IconSP from "./icons/IconSP";
import IconChecked from "./icons/IconChecked";
import IconAT from "./icons/IconAT";
import IconWR from "./icons/IconWR";
import IconOL from "./icons/IconOL";

import { setOutrightLeagues, setLeagues } from "../../actions";
import withRouter from "../../helpers/withRouter";

export let icons = {
  // AY: <IconOL />,
  // SB: <IconOL />,
  // CA: <IconOL />,
  // SW: <IconOL />,
  // WL: <IconOL />,
  // SL: <IconOL />,
  // JU: <IconOL />,
  // TK: <IconOL />,
  // DV: <IconOL />,
  // SF: <IconOL />,
  // CL: <IconOL />,
  // TA: <IconOL />,
  // RO: <IconOL />,
  // BR: <IconOL />,
  // FE: <IconOL />,
  // GY: <IconOL />,
  // SH: <IconOL />,
  // OL: <IconOL />,
  // AT: <IconOL />,
  // WG: <IconOL />,
  AT: <IconAT />,
  WR: <IconWR />,
  FH: <IconBD />,
  AF: <IconAF />,
  // AR: <IconAR />,
  BA: <IconBA />,
  BB: <IconBB />,
  BD: <IconBD />,
  BM: <IconBM />,
  BV: <IconVB />,
  BX: <IconBX />,
  CR: <IconCR />,
  CS: <IconCS />,
  CU: <IconCU />,
  DA: <IconDA />,
  DT: <IconDT />,
  FB: <IconFB />,
  FL: <IconFL />,
  FS: <IconFS />,
  HB: <IconHB />,
  HR: <IconHR />,
  IH: <IconIH />,
  LL: <IconLL />,
  MM: <IconMM />,
  MO: <IconMO />,
  PS: <IconPS />,
  RG: <IconRG />,
  SN: <IconSN />,
  SQ: <IconSQ />,
  TE: <IconTE />,
  TR: <IconTR />,
  TT: <IconTT />,
  VB: <IconVB />,
  WP: <IconWP />,
  GO: <IconGO />,
  F1: <IconF1 />,
  AS: <IconAS />,
  BI: <IconBI />,
  SJ: <IconSJ />,
  CC: <IconCC />,
  CH: <IconCH />,
  CY: <IconCY />,
  FS: <IconFS />,
  RA: <IconRA />,
  NC: <IconNC />,
  GH: <IconGH />,
  GF: <IconGF />,
  SP: <IconSP />,
  ES: <IconCS />,
  B3: <IconBA />,
  BV: <IconVB />,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const styles = (theme) => ({
  aloneBadge: {
    "& .MuiBadge-anchorOriginTopRightRectangle": {
      position: "relative",
      top: "-0.3em!important",
      left: "0!important",
      transform: "none",
      marginLeft: "5px",
    },
  },
  root: {
    "& .MuiTab-wrapper": {
      display: "inline-flex !important",
      // color: 'var(--color-contrast)',
    },
    "& .MuiSvgIcon-root": {
      color: "var(--color-sportstabs)",
      fontSize: "1.3em !important",
      "@media (hover: hover)": {
        fontSize: "2.7em !important",
      },
    },
    "& .MuiTab-wrapper > *:first-child": {
      marginBottom: 0,
    },
    "& .MuiBadge-anchorOriginTopRightRectangle": {
      top: "0.6em",
      left: "0.2em",
      "@media (hover: none) and (min-width: 768px)": {
        top: "1.05em",
        left: "0.55em",
      },
    },
    "& .MuiBadge-badge": {
      height: "1.2em",
      minWidth: "1.2em",
      "@media (hover: none) and (min-width: 768px)": {
        height: "1.6em",
        minWidth: "1.6em",
      },
    },
    "& .MuiTabs-root": {
      minHeight: "2.6em",
    },
    "& .MuiTab-root": {
      // minHeight: "1em",
      minHeight: "3em",
      padding: "0.3em 0.4em",
      minWidth: 0,
      fontSize: "1.3em",
      "@media (hover:hover)": {
        fontSize: "1.2em",
        padding: "0.5em 1.2em 0.5em 1em",
      },
    },
    "& .MuiTab-labelIcon": {
      minHeight: 0,
    },
    "& .MuiTabScrollButton-root": {
      color: "var(--grey-11)",
    },
    "& .MuiTabScrollButton-root.Mui-disabled": {
      display: "none",
    },
    "& .Mui-selected": {
      color: "var(--color-sports-tabs-selected)",
      background: "var(--bg-sports-tabs-selected)",
    },
    flexGrow: 1,
    background: "var(--bg-tabs-2)",
  },
});

class SportsTabs extends React.PureComponent {
  constructor(props) {
    super(props);

    this.tabs = {};

    this.state = {
      showTabs: true,
    };
  }

  componentDidMount() {
    const { url } = this.props;
    this.setState({ showTabs: url && url.includes("/casino") ? false : true });
  }

  componentDidUpdate() {
    const { url } = this.props;
    this.setState({ showTabs: url && url.includes("/casino") ? false : true });
  }

  /**
   * Change sport type
   */

  handleChange = (e, sportCode) => {
    const { leagues, leagueFilter, url, locale } = this.props;
    if (url === `/${locale}/outrights`) {
      this.props.setOutrightLeagues(
        leagues,
        leagueFilter,
        sportCode,
        locale,
        this.constructor.name
      );
    } else {
      this.props.setLeagues(
        leagues,
        leagueFilter,
        sportCode,
        locale,
        this.constructor.name
      );
    }
    this.props.navigate(
      `/${locale}/leagues/${
        leagueFilter && leagueFilter !== "favorites" ? leagueFilter : "all"
      }/${sportCode}`
    );
  };

  /**
   * Get Sport Name from sport code
   */

  getSportName = (sportCode) => {
    const { sportLabels } = this.props;

    if (sportCode === "favorites") return <Translate value={`menu.mySports`} />;
    if (sportLabels) return sportLabels.default[sportCode];
  };

  /**
   * Calculate Favorite Leagues count
   */

  getFavoriteLeaguesTotal() {
    const { favoriteLeagues } = this.props;

    let leagues = 0;
    for (let sportCode in favoriteLeagues) {
      leagues += favoriteLeagues[sportCode]
        ? Object.keys(favoriteLeagues[sportCode])?.length
        : 0;
    }
    return leagues;
  }

  render() {
    log(this.constructor.name, "render");
    const {
      leagues,
      leagueFilter,
      sportCode,
      favoriteLeagues,
      mode,
      classes,
      navMode,
      layout,
      sportLabels,
    } = this.props;

    if (!this.state.showTabs) return null;
    if (!leagues || !leagues[leagueFilter]) return <div></div>;

    this.tabs = [];

    let concatedLeagues = [];
    if (favoriteLeagues && Object.values(favoriteLeagues)) {
      Object.values(favoriteLeagues)?.forEach((league) => {
        concatedLeagues = concatedLeagues.concat(Object.values(league));
      });
    }

    if (concatedLeagues?.length) {
      if (layout === "touch" || (layout === "mouse" && navMode === "list")) {
        this.tabs.push({
          sportCode: "favorites",
          icon: (
            <Badge badgeContent={this.getFavoriteLeaguesTotal()} color="error">
              <IconChecked />
            </Badge>
          ),
          active: false,
        });
      }
    }

    if (sportLabels?.default) {
      Object.keys(sportLabels?.default)?.forEach((lgKey) => {
        if (
          leagues[leagueFilter === "favorites" ? "all" : leagueFilter] &&
          leagues[leagueFilter === "favorites" ? "all" : leagueFilter][lgKey]
        ) {
          this.tabs.push({
            sportCode: lgKey,
            icon: icons[lgKey] ? icons[lgKey] : <CheckedIcon />,
            active: lgKey === sportCode ? true : false,
          });
        }
      });
    }

    return (
      <div className={classes.root}>
        <AppBar position="static" color="transparent" elevation={0}>
          <Tabs
            value={sportCode}
            onChange={this.handleChange}
            variant="scrollable"
            scrollButtons={layout === "mouse" ? "auto" : false}
            TabIndicatorProps={{
              style: {
                display: "none",
              },
            }}
          >
            {this.tabs.map((tab, key) => (
              <Tab
                key={tab.sportCode}
                value={tab.sportCode}
                icon={mode === "standard" ? tab.icon : null}
                label={
                  <span className={classes.tabLabel}>
                    {tab.sportCode === "ES"
                      ? "Esports"
                      : this.getSportName(tab.sportCode)}

                    {mode !== "standard" && tab.sportCode === "favorites" ? (
                      <Badge
                        badgeContent={this.getFavoriteLeaguesTotal()}
                        color="error"
                        className={classes.aloneBadge}
                      />
                    ) : null}
                  </span>
                }
                content={tab.sportCode}
                aria-label={tab.sportCode}
                {...a11yProps(key)}
                iconPosition="top"
              />
            ))}
          </Tabs>
        </AppBar>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    leagues: state.leagues,
    leagueFilter: ownProps.leagueFilter,
    sportCode: ownProps.sportCode,
    favoriteLeagues: state.leagues.favorites,
    sportLabels: state.labels.sports,
    url: state.settings.url,
    mode: state.settings.mode,
    layout: state.settings.layout,
    navMode: state.settings.navMode,
    locale: state.i18n.locale,
  };
};

export default withRouter(
  connect(mapStateToProps, { setLeagues, setOutrightLeagues })(
    withStyles(styles)(SportsTabs)
  )
);
