/**
 * League menu component ( Result, standing , conference and others)
 */

import { log } from "../../config/log";
import React from "react";
import { connect } from "react-redux";
import { Translate } from "react-redux-i18n";
import withStyles from "@mui/styles/withStyles";
import { setLeagueMenuValue, setLeagues } from "../../actions";
import OutrightGamesList from "./OutrightGamesList";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { AppBar } from "@mui/material";
import LeagueResults from "./LeagueResults";
import TrottingSystemLayout from "./trotting-system";

const styles = (theme) => ({
  root: {
    "& .MuiTabs-root": {
      minHeight: "1em",
    },
    "& .MuiTab-root": {
      minHeight: "1em",
    },
    "& .MuiSvgIcon-root": {
      fontSize: "1.2em",
    },
    "& .MuiTabScrollButton-root": {
      fontSize: "1.3em",
      width: "1.3em",
      opacity: "0.5",
    },
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  rootTop: {
    "& .MuiTab-wrapper": {
      display: "inline-flex !important",
    },
    "& .MuiSvgIcon-root": {
      fontSize: "1.3em !important",
      "@media (hover: none) and (min-width: 768px)": {
        fontSize: "1.5em !important",
      },
    },
    "& .MuiTabs-root": {
      minHeight: "1em",
    },
    "& .MuiTabScrollButton-root.Mui-disabled": {
      display: "none",
    },
    "& .MuiTab-root": {
      minHeight: "3.5em !important",
      padding: "0.2em",
      minWidth: 0,
      textTransform: "uppercase !important",
      "@media (hover:hover)": {
        minHeight: "2.6em",
      },
    },
    "& .MuiTab-textColorInherit.Mui-selected": {
      color: "var(--color-contrast)",
    },
    "& .MuiAppBar-root": {
      position: "sticky",
      zIndex: "998",
    },
    "& .MuiBadge-anchorOriginTopRightRectangle": {
      top: "0.7em !important",
      left: "0.4em",
      "@media (hover: none) and (min-width: 768px)": {
        top: "0.6em !important",
        left: "0.8em",
      },
    },
    "& .MuiBadge-badge": {
      height: "1em",
      minWidth: "1em",
      fontSize: "0.8em",
    },
    width: "100%",
    background: "var(--bg-primary)",
  },
  tabLabel: {
    padding: "0.3em",
    "@media (hover:hover)": {
      padding: "0.3em 0.3em 0.3em 0.7em",
    },
  },
  tabsticky: {
    position: "sticky",
    top: "3em",
    zIndex: "997",
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

class OutrightLeague extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "outrights",
    };
  }
  handleChange = (event, newValue) => {
    this.setState({ value: newValue });
  };

  render() {
    log(this.constructor.name, "render");
    const {
      sportCode,
      leagueId,
      leagues,
      isInsportsBetting,
      classes,
      outrightOnly,
      singleOnlyOutright,
      searchQuery,
    } = this.props;

    if (!leagues[leagueId] || !leagues[leagueId]["outrightGames"])
      return <div>no games</div>;

    return (
      <div className={`${classes.rootTop}`}>
        {outrightOnly || sportCode === "TR" || singleOnlyOutright ? (
          <AppBar elevation={0} color="transparent">
            <Tabs
              variant="scrollable"
              indicatorColor="primary"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
              onChange={this.handleChange}
              value={this.state.value}
            >
              <Tab
                value="outrights"
                label={
                  <span className={classes.tabLabel}>
                    <Translate value={`marketSets.outrights`} />
                  </span>
                }
                {...a11yProps("outrights")}
              />
              {sportCode === "TR" && <Tab
                value="tr_system"
                label={
                  <span className={classes.tabLabel}>
                    <Translate value={`slip.system`} />
                  </span>
                }
                {...a11yProps("tr_system")}
              />}
              <Tab
                value="results"
                label={
                  <span className={classes.tabLabel}>
                    <Translate value={`menu.results`} />
                  </span>
                }
                {...a11yProps("results")}
              />
            </Tabs>
            <TabPanel value={this.state.value} index="outrights">
              <OutrightGamesList
                isInsportsBetting={isInsportsBetting}
                leagueId={leagueId}
                sportCode={sportCode}
                searchQuery={searchQuery}
              />
            </TabPanel>
            <TabPanel value={this.state.value} index="tr_system">
              <TrottingSystemLayout leagueId={leagueId} />
            </TabPanel>
            <TabPanel value={this.state.value} index="results">
              <LeagueResults leagueId={leagueId} />
            </TabPanel>
          </AppBar>
        ) : (
          <OutrightGamesList
            isInsportsBetting={isInsportsBetting}
            leagueId={leagueId}
            sportCode={sportCode}
            searchQuery={searchQuery}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let showResultsFirst = state.settings.showResultsFirst;
  const leagueId = ownProps.leagueId;
  const leagueFilter = state.leagues._leagueFilter;

  const sportCode = state.selected.leagues[leagueId]
    ? state.selected.leagues[leagueId].sportCode
    : state.leagues._sportCode;

  /**** For Tree View Mode Comment *******/
  // let leagues;

  // if (ownProps.from === 'nav-tabs') {
  //   let treeViewLeagues = state.leagues.treeViewLeagues;

  //   for (let sportCode in treeViewLeagues) {
  //     for (let leagueId in treeViewLeagues[sportCode]) {
  //       leagues = { ...leagues, [leagueId]: treeViewLeagues[sportCode][leagueId] };
  //     }
  //   }
  // } else {
  //   leagues = state.selected.leagues;
  // }

  // console.log(leagueId + ' -> ' + state.selected.leagues[leagueId].gameLiveCount);

  return {
    leagueId: leagueId,
    singleOnlyOutright: ownProps?.singleOnlyOutright,
    // leagues,
    leagues: state.selected.leagues,
    outrightOnly: ownProps?.outrightOnly,
    leagueMenuValue:
      state.selected.leagues[leagueId] &&
      state.selected.leagues[leagueId].menuValue
        ? state.selected.leagues[leagueId].menuValue
        : showResultsFirst
        ? "results"
        : "mainbets",
    leagueGrouping: state.settings.leagueGrouping,
    leagueFilter: leagueFilter,
    sportCode: sportCode,
    marketSetConfig: state.settings.marketSets,
    gameStaticId: ownProps.gameStaticId,
    isInsportsBetting: ownProps?.isInsportsBetting,
    searchQuery: ownProps?.searchQuery,
  };
};

export default connect(mapStateToProps, { setLeagues, setLeagueMenuValue })(
  withStyles(styles)(OutrightLeague)
);
