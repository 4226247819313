/**
 * slipCalculatorService library multis winMax 252 tr
 */

import allPossibleCombis from "../config/allPossibleCombis.json";

var settings = null;
var stake = null;
var betType = null;
var slipTemplate = null;
var bets = null;
const system_bets_limit = 1024;

/**
 * Calculate single stake
 ** calculate winMin and winMax for slip
 */

function calcSingleStake() {
  settings.odds = 0;
  settings.maxOdds = 0;
  settings.combiType = 1;

  settings.stakePerBet = Object.keys(bets)?.length === 0 ? 0 : stake / Object.keys(bets)?.length;

  bets?.forEach((bet) => {
    if (settings.maxOdds < Number(bet.price)) {
      settings.maxOdds = Number(bet.price);
    }

    settings.odds += Number(bet.price);
  });

  settings.winMin = null;
  // if (settings.winMin > 100000) settings.winMin = nf(100000);
  settings.winMax = settings.odds * settings.stakePerBet;
}

/**
 * Calculate combi stake
 ** calculate winMin and winMax for slip
 */

function calcCombiStake(ignoreNonBanks) {
  settings.combiType = Object.keys(bets)?.length;

  settings.odds = 1;
  let oddsAfterBonus = 1;

  if (settings.cntMultiBets === 0) {
    if (ignoreNonBanks === true) {
      bets?.forEach((bet) => {
        if (bet.isBank === 1) {
          settings.odds *= Number(bet.price);
        }
      });
    } else {
      bets?.forEach((bet) => {
        settings.odds *= Number(bet.price);
      });
    }

    settings.maxOdds = settings.odds;
    settings.winMin = null;
    settings.winMax = settings.odds * stake;
  } else {
    let combiMultiType = calcCombiMultiTypes();
    let maxCombiType = combiMultiType[0];
    let cntMaxCombiTypes = combiMultiType[2];

    if (settings.eventsInSlip?.length !== parseInt(maxCombiType)) {
      betType = "system";
      calcBetTypes();
      return;
    }

    settings.combiTypes.length = 0;
    settings.combiStakes.length = 0;
    settings.combiTypes.push(Number(maxCombiType));
    settings.combiStakes.push(Number(stake / cntMaxCombiTypes));
    calcCombiMultiTypeWins(cntMaxCombiTypes);
    calcMaxSystemOdds();
  }

  let userBonuses = {
    minOdds: 1.01,
    excludedMarkets: [],
  };

  let combiTypeAfterBonus = 0;
  bets?.forEach((bet, k) => {
    if (Number(bet.price) >= userBonuses.minOdds && userBonuses.excludedMarkets.includes(bet.marketId) === false) {
      combiTypeAfterBonus++;
      oddsAfterBonus *= Number(bet.price);
    }
  });
  let winMaxAfterBonus = oddsAfterBonus * stake;
  let bonus = 0;

  if (combiTypeAfterBonus >= userBonuses.minCombi && combiTypeAfterBonus <= userBonuses.maxCombi) {
    let bonusPercent = userBonuses.bonuses[combiTypeAfterBonus];
    bonus = (winMaxAfterBonus * bonusPercent) / 100;
  }

  if (settings.winMax > 100000) settings.winMax = nf(100000);
  if (settings.winMin > 100000) settings.winMin = nf(100000);
}

/**
 * Calculate combiMulti types
 */

function calcCombiMultiTypes() {
  let betsIndexes = [];

  bets?.forEach((bet, index) => {
    betsIndexes.push(index + 1);
  });

  settings.activeCombinations = [];

  let maxCombiType = 0;
  let cntMaxCombiTypes = 0;

  for (let combiType = 1; combiType <= Object.keys(bets)?.length; combiType++) {
    settings.activeCombinations[combiType] = [];

    let allCombinations = getAllPossibleCombinations(betsIndexes, combiType);

    if (combiType === settings.eventsInSlip?.length && allCombinations?.length === 0) {
      let numberOfCombis = computeNumberOfCombis(Object.keys(bets)?.length, settings.eventsInSlip?.length);

      if (numberOfCombis <= 10000) {
        allCombinations = getAllPossibleCombinations(betsIndexes, combiType, false);
      }
    }

    // remove the multis from all possible combinations
    let tmp = allCombinations;
    allCombinations = [];

    for (let i = 0; i < tmp?.length; i++) {
      let isValid = true;
      for (let key in settings.multis) {
        // allCombinations [1,2,3], multi.betsIndex [3,4] = merged
        // [1,2,3,3,4]: 3 = 1x multi of game, valid
        // allCombinations [1,2,3], multi.betsIndex [2,3,4] = merged
        // [1,2,2,3,3,4]: 2x multi of game, invalid

        let mergedIndexes = tmp[i].concat(settings.multis[key].betsIndexes);
        let uniqueIndexes = [];

        mergedIndexes?.forEach((item, index) => {
          // $.each(mergedIndexes, function (index, item) {

          if (uniqueIndexes.includes(item) === false) {
            uniqueIndexes.push(item);
          }
          // if ($.inArray(item, uniqueIndexes) === -1)
          //     uniqueIndexes.push(item);
        });

        // remove combi
        if (mergedIndexes?.length - 2 >= uniqueIndexes?.length) isValid = false;
      }

      if (isValid === true) allCombinations.push(tmp[i]);
    }

    if (allCombinations?.length === 0 || allCombinations?.length > 252) continue;

    settings.activeCombinations[combiType] = allCombinations;
    maxCombiType = combiType;
    cntMaxCombiTypes = allCombinations?.length;
  }

  //returns highest systemType in format 5 (=maxCombiType) of 9 (=bets?.length), 16 rows (=cntMaxCombiTypes)
  return [maxCombiType, Object.keys(bets)?.length, cntMaxCombiTypes];
}

/**
 * Calculate combiMulti types
 */

function calcCombiMultiTypeWins(cntMaxCombiTypes) {
  // const { bets } = props;

  let stakePerBet = stake / Number(cntMaxCombiTypes);

  let totalOddMax = 1;
  let totalOddMin = 1;
  let calculatedGames = [];

  bets?.forEach((bet, index) => {
    //add accumulated odd for the game if it has multis
    for (let gameid in settings.multiOdds) {
      //since odds are accumulated, game can just be calculated once
      if (calculatedGames.includes(Number(gameid))) continue;

      if (Number(bet.gameId) === Number(gameid)) {
        totalOddMin *= Number(settings.multiOdds[gameid][0]);
        calculatedGames.push(Number(gameid));
      }
    }

    //add all non-multi games to the odd calculation
    //if ($.inArray(Number(bets[i]['eventID']), calculatedGames) == -1) {
    if (calculatedGames.includes(Number(bet.gameId)) === false) {
      totalOddMin *= Number(bet.price);
      calculatedGames.push(Number(bet.gameId));
    }
  });

  // This overrides the previous calculation which only obtains the highest combi odds for this combitype
  // The new calculation in calcMaxSystemOddsPerCombiType also factors other markets from within the same event
  // The new calculation was created by Christian and outlined in a .doc
  let combiType = settings.combiTypes[settings.combiTypes.length - 1];
  totalOddMax = calcMaxSystemOddsPerCombiType(combiType);

  let winMin = null;
  let winMax = null;

  if (parseInt(totalOddMin) === parseInt(totalOddMax)) {
    winMax = nf(totalOddMax * stakePerBet);
  } else {
    winMin = nf(totalOddMin * stakePerBet);
    winMax = nf(totalOddMax * stakePerBet);
  }

  settings.winMin = winMin;
  settings.winMax = winMax;
}

/**
 * Calculate SystemCombi types
 */

function calcSystemCombiTypeWins(combiType) {
  // const { bets } = props;

  //let stakePerBet = typeof (realCombiStakes[combiType]) === 'undefined' ? 0 : realCombiStakes[combiType];
  //stakePerBet = isNaN(stakePerBet) ? 0 : stakePerBet;

  let stakePerBet = settings.combiStakes[getIndexFromCombiTypes(combiType)];

  let totalOddMax = 0;
  let totalOddMin = 1;
  let oddsPerBet = [];

  if (settings.cntMultiBets > 0) {
    for (let i = 0; i < settings.activeCombinations[combiType]?.length; i++) {
      let oddsPerCombinationMax = 1;
      let oddsPerCombinationMin = 1;
      let calculatedGames = [];

      for (let j = 0; j < settings.activeCombinations[combiType][i]?.length; j++) {
        let betsIndex = settings.activeCombinations[combiType][i][j] - 1;

        //check if the games of this combitype have multibets
        for (let gameid in settings.multiOdds) {
          if (Number(bets[betsIndex]["gameId"]) === Number(gameid)) {
            oddsPerCombinationMax *= Number(settings.multiOdds[gameid][settings.multiOdds[gameid]?.length - 1]);
            oddsPerCombinationMin *= Number(settings.multiOdds[gameid][0]);
            //totalOddMin *= Number(multiOdds[gameid][0]);
            calculatedGames.push(Number(gameid));
          }
        }

        //add all non-multi games to the odd calculation
        if (calculatedGames.includes(Number(bets[betsIndex]["gameId"])) === false) {
          oddsPerCombinationMax *= Number(bets[betsIndex]["price"]);
          oddsPerCombinationMin *= Number(bets[betsIndex]["price"]);
          calculatedGames.push(Number(bets[betsIndex]["gameId"]));
        }
      }

      oddsPerBet.push(oddsPerCombinationMax);
      oddsPerBet.push(oddsPerCombinationMin);
    }
  } else {
    for (let i = 0; i < settings.activeCombinations[combiType]?.length; i++) {
      let oddsPerCombination = 1;

      for (let j = 0; j < settings.activeCombinations[combiType][i]?.length; j++) {
        let betsIndex = settings.activeCombinations[combiType][i][j] - 1;
        oddsPerCombination *= bets[betsIndex].price;
      }

      oddsPerBet.push(oddsPerCombination);
    }
  }

  oddsPerBet.sort(function (a, b) {
    return a - b;
  });

  totalOddMin = Number(oddsPerBet[0]);

  // This overrides the previous calculation which only obtains the highest combi odds for this combitype
  // The new calculation in calcMaxSystemOddsPerCombiType also factors other markets from within the same event
  // The new calculation was created by Christian and outlined in a .doc
  totalOddMax = calcMaxSystemOddsPerCombiType(combiType);

  // settings.availableCombiWinMin[getIndexFromAvailableCombiTypes(combiType)] = "";
  settings.availableCombiWinMin[getIndexFromAvailableCombiTypes(combiType)] = totalOddMin * stakePerBet > 100000 ? 100000 : totalOddMin * stakePerBet;
  settings.availableCombiWinMax[getIndexFromAvailableCombiTypes(combiType)] = totalOddMax * stakePerBet > 100000 ? 100000 : totalOddMax * stakePerBet;

  if (settings.totalOddMin !== settings.totalOddMax && stakePerBet > 0) {
    settings.availableCombiWinMin[getIndexFromAvailableCombiTypes(combiType)] = totalOddMin * stakePerBet > 100000 ? 100000 : totalOddMin * stakePerBet;
  }

  //maks
  // $('#combiStakes_' + combiType + '_win_sep_min').html('');
  // $('#combiStakes_' + combiType + '_win_min').html('');
  // $('#combiStakes_' + combiType + '_win_sep_max').html('');
  // $('#combiStakes_' + combiType + '_win_max').html(nf(totalOddMax * stakePerBet > 100000 ? 100000 : totalOddMax * stakePerBet)).attr('realwinmax', totalOddMax * stakePerBet);

  // if (dec(totalOddMin) != dec(totalOddMax) && stakePerBet > 0) {
  //   $('#combiStakes_' + combiType + '_win_min').html(nf(totalOddMin * stakePerBet > 100000 ? 100000 : totalOddMin * stakePerBet)).attr('realwinmin', totalOddMin * stakePerBet);
  //   $('#combiStakes_' + combiType + '_win_sep_min').html(' ' + APP_LANG_NEW_WINNING_CHANCE_MIN + ' ');
  //   $('#combiStakes_' + combiType + '_win_sep_max').html(' <> ' + APP_LANG_NEW_WINNING_CHANCE_MAX + ' ');
  // }

  calcMaxSystemOdds();
}

/**
 * Calculate System types
 */

function calcSystemTypes() {
  let betsIndexes = [];

  for (let i = 1; i <= Object.keys(bets)?.length; i++) {
    betsIndexes.push(i);
    //cntBanks = props.bets[i - 1].isBank === 1 ? cntBanks + 1 : cntBanks;
  }

  let multiTextIsSet = false;

  // console.log('combiTypes:');
  // console.log(settings.combiTypes);
  // console.log('----');

  if (settings.keepSystemTypeSettings === false) {
    // console.log('oasdf');
    settings.combiTypes = [];
    settings.combiStakes = [];
  }

  settings.activeCombinations = [];
  settings.availableCombiTypes = [];
  settings.availableCombiTexts = [];
  settings.availableCombiCounts = [];
  settings.availableCombiWinMin = [];
  settings.availableCombiWinMax = [];
  const gameIds = {};
  const leagueIds = {};
  let hasCombinedOutrights = false;
  let outrightsCount = 0;

  Object.values(bets)?.forEach((betGame) => {
    if (!gameIds[betGame.gameId]) {
      gameIds[betGame.gameId] = bets.filter((each) => each.gameId === betGame.gameId);
    }
    if (!leagueIds[betGame.game.leagueID] && betGame?.isOutright) {
      leagueIds[betGame.game.leagueID] = bets.filter((eachBet) => eachBet.game.leagueID === betGame.game.leagueID && eachBet?.isOutright);
    }
  });

  Object.values(leagueIds)?.forEach((leagueArr) => {
    outrightsCount += leagueArr?.length;
    if (leagueArr?.length > 1) {
      hasCombinedOutrights = true;
    }
  });

  let marketKeys = Object.keys(gameIds);
  let totalCombiCounts = 0;

  for (let combiType = 1; combiType <= marketKeys?.length; combiType++) {
    let allCombinations = getAllPossibleCombinations(betsIndexes, combiType, false);
    let tmp = allCombinations;
    allCombinations = [];

    for (let i = 0; i < tmp?.length; i++) {
      let isValid = true;

      for (let key in settings.multis) {
        // allCombinations [1,2,3], multi.betsIndex [3,4] = merged
        // [1,2,3,3,4]: 3 = 1x multi of game, valid
        // allCombinations [1,2,3], multi.betsIndex [2,3,4] = merged
        // [1,2,2,3,3,4]: 2x multi of game, invalid

        let mergedIndexes = tmp[i].concat(settings.multis[key].betsIndexes);
        let uniqueIndexes = [];
        mergedIndexes?.forEach((item, index) => {
          if (uniqueIndexes.includes(item) === false) uniqueIndexes.push(item);
        });

        // remove combi
        if (mergedIndexes?.length - 2 >= uniqueIndexes?.length) isValid = false;
      }

      if (isValid === true) allCombinations.push(tmp[i]);
    }

    if (allCombinations?.length === 0 || settings.cntBanks === combiType) continue;

    // if a bet is a bank, it must be in this combination in order to count
    // it to this to cntCombiTypes
    let cntCombiTypes = allCombinations?.length;
    let bankIndexes = [];
    let bankCombinations = [];

    for (let betsIndex = 1; betsIndex <= Object.keys(bets)?.length; betsIndex++) {
      //maksym: better way to update store
      if (bets[betsIndex - 1].isBank === 1) bankIndexes.push(betsIndex);
    }

    if (bankIndexes?.length > 0) {
      if (combiType < bankIndexes?.length) continue;

      // check the combiType's combinations if it has the betsindex,
      // if not, remove it from the combiType's combinations
      // [1,2], [2,4],...
      for (let i = 0; i < allCombinations?.length; i++) {
        let cntMatches = 0;

        for (let j = 0; j < allCombinations[i]?.length; j++) {
          for (let l = 0; l < bankIndexes?.length; l++) {
            if (bankIndexes[l] === allCombinations[i][j]) {
              cntMatches++;
            }
          }
        }

        if (cntMatches === bankIndexes?.length) bankCombinations.push(allCombinations[i]);
      }
    }
    if (bankCombinations?.length > 0) {
      totalCombiCounts += bankCombinations.length;
    } else {
      totalCombiCounts += allCombinations.length;
    }
  }

  settings.combiCounts = totalCombiCounts;
  // const leagueKeys = Object.keys(leagueIds);

  for (let combiType = 1; combiType <= marketKeys?.length; combiType++) {
    settings.activeCombinations[combiType] = [];
    let allCombinations = getAllPossibleCombinations(betsIndexes, combiType, false);
    if (totalCombiCounts > system_bets_limit) {
      totalCombiCounts -= allCombinations.length;
      allCombinations = [];
    }
    // if (
    //   // combiType === settings.eventsInSlip?.length &&
    //   allCombinations?.length === 0
    // ) {
    //   let numberOfCombis = computeNumberOfCombis(Object.keys(bets)?.length, settings.eventsInSlip?.length);

    //   if (numberOfCombis <= 10000) {
    //     allCombinations = getAllPossibleCombinations(betsIndexes, combiType, false);
    //   }
    // }
    // remove the multis from all possible combinations
    let tmp = allCombinations;
    allCombinations = [];

    for (let i = 0; i < tmp?.length; i++) {
      let isValid = true;

      for (let key in settings.multis) {
        // allCombinations [1,2,3], multi.betsIndex [3,4] = merged
        // [1,2,3,3,4]: 3 = 1x multi of game, valid
        // allCombinations [1,2,3], multi.betsIndex [2,3,4] = merged
        // [1,2,2,3,3,4]: 2x multi of game, invalid

        let mergedIndexes = tmp[i].concat(settings.multis[key].betsIndexes);
        let uniqueIndexes = [];
        mergedIndexes?.forEach((item, index) => {
          if (uniqueIndexes.includes(item) === false) uniqueIndexes.push(item);
        });

        // remove combi
        if (mergedIndexes?.length - 2 >= uniqueIndexes?.length) isValid = false;
      }

      if (isValid === true) allCombinations.push(tmp[i]);
    }

    if (allCombinations?.length === 0 || settings.cntBanks === combiType) continue;

    // if a bet is a bank, it must be in this combination in order to count
    // it to this to cntCombiTypes
    let cntCombiTypes = allCombinations?.length;
    let bankIndexes = [];
    let bankCombinations = [];

    for (let betsIndex = 1; betsIndex <= Object.keys(bets)?.length; betsIndex++) {
      //maksym: better way to update store
      if (bets[betsIndex - 1].isBank === 1) bankIndexes.push(betsIndex);
    }

    if (bankIndexes?.length > 0) {
      if (combiType < bankIndexes?.length) continue;

      // check the combiType's combinations if it has the betsindex,
      // if not, remove it from the combiType's combinations
      // [1,2], [2,4],...
      for (let i = 0; i < allCombinations?.length; i++) {
        let cntMatches = 0;

        for (let j = 0; j < allCombinations[i]?.length; j++) {
          for (let l = 0; l < bankIndexes?.length; l++) {
            if (bankIndexes[l] === allCombinations[i][j]) {
              cntMatches++;
            }
          }
        }

        if (cntMatches === bankIndexes?.length) bankCombinations.push(allCombinations[i]);
      }
    }
    // console.log(allCombinations, "DEBUG activeCombinations>>>>>>>>>>>>>>>>>>>>111111111", combiType);
    if (bankCombinations?.length > 0) {
      cntCombiTypes = bankCombinations?.length;
      settings.activeCombinations[combiType] = bankCombinations;
    } else {
      settings.activeCombinations[combiType] = allCombinations;
    }

    if (settings.activeCombinations[combiType]?.length > system_bets_limit) {
      settings.activeCombinations[combiType] = [];
      continue;
    }

    if (settings.cntMultiBets > 0 && combiType > 1 && multiTextIsSet === false) multiTextIsSet = true;
    
    var strCombiText = "";

    let systemLabel = "";

    switch (combiType - settings.cntBanks) {
      case 1:
        systemLabel = "Single";
        break;

      case 2:
        systemLabel = "Double";
        break;

      case 3:
        systemLabel = "Trebble";
        break;

      default:
        // systemLabel = " x " + (combiType - settings.cntBanks) + " fold";
        systemLabel = combiType - settings.cntBanks + " -fold";
        break;
    }

    if (settings.cntBanks > 0) {
      strCombiText = settings.cntBanks + "B";
      if (combiType - settings.cntBanks > 0) {
        // strCombiText += ' + ' + cntCombiTypes + ' ' + systemLabel;
        strCombiText += " + " + " " + systemLabel;
      }
    } else {
      strCombiText = systemLabel;
      // strCombiText = cntCombiTypes + ' ' + systemLabel;
    }

    settings.availableCombiTypes.push(combiType);
    settings.availableCombiTexts.push(strCombiText);
    settings.availableCombiCounts.push(cntCombiTypes);
    settings.availableCombiWinMin.push(0);
    settings.availableCombiWinMax.push(0);
  }

  //return calcSystemStake();
  //return renderSystemTypesInfo();
}

/**
 * Calculate System stake
 */

function calcSystemStake() {
  settings.stakePerBet = 0;
  settings.stakePerBetReal = 0;
  let stakePerBet;
  let stakePerBetReal;
  settings.winMin = 0;
  settings.winMax = 0;
  // let betRows = 0;
  // availableCombiWinMin = [];
  // availableCombiWinMax = [];
  let totalBets = calcSystemTotalBets();

  switch (settings.stakeCalculationType) {
    case "total":
      stakePerBet = totalBets ? stake / totalBets : "";
      stakePerBetReal = totalBets ? stake / totalBets : "";
      settings.combiStakes = [];
      settings.realCombiStakes = [];

      settings.combiTypes?.forEach((combiType, key) => {
        settings.combiStakes.push(stakePerBet);
        // settings.combiStakes[combiType] = settings.combiStakes[combiType] ? settings.combiStakes[combiType] : stakePerBet;
        settings.realCombiStakes.push(stakePerBetReal);
        calcSystemCombiTypeWins(combiType);
        settings.winMin += settings.availableCombiWinMin[getIndexFromAvailableCombiTypes(combiType)];
        settings.winMax += settings.availableCombiWinMax[getIndexFromAvailableCombiTypes(combiType)];
      });
      break;

    case "perBet":
      stakePerBet = settings.stakePerBet;
      stakePerBetReal = settings.stakePerBetReal;
      settings.combiStakes = [];

      settings.combiTypes?.forEach((combiType, key) => {
        settings.combiStakes.push(stakePerBet);
        // settings.combiStakes[combiType] = settings.combiStakes[combiType] ? settings.combiStakes[combiType] : stakePerBet;
        settings.realCombiStakes.push(stakePerBetReal);
        calcSystemCombiTypeWins(combiType);
        settings.winMin += settings.availableCombiWinMin[getIndexFromAvailableCombiTypes(combiType)];
        settings.winMax += settings.availableCombiWinMax[getIndexFromAvailableCombiTypes(combiType)];
      });
      break;

    case "thisBet":
      // console.log('calculating thisBet')
      stake = 0;

      settings.combiTypes?.forEach((combiType, key) => {
        stakePerBet = settings.combiStakes[getIndexFromCombiTypes(combiType)];
        stakePerBetReal = settings.realCombiStakes[getIndexFromCombiTypes(combiType)];
        stake += stakePerBet * Number(settings.activeCombinations[combiType]?.length);
        calcSystemCombiTypeWins(combiType);
        settings.winMin += settings.availableCombiWinMin[getIndexFromAvailableCombiTypes(combiType)];
        settings.winMax += settings.availableCombiWinMax[getIndexFromAvailableCombiTypes(combiType)];
      });
      //ersetzen mit state
      //stake = stakePerBet * totalBets;

      // settings.stakeCalculationType = 'total';
      break;

    case "thisBetFromTotal":
      stakePerBet = totalBets ? stake / totalBets : "";
      stakePerBetReal = totalBets ? stake / totalBets : "";
      settings.combiStakes = [];
      settings.realCombiStakes = [];

      settings.combiTypes?.forEach((combiType, key) => {
        settings.combiStakes.push(stakePerBet);
        // settings.combiStakes[combiType] = settings.combiStakes[combiType] ? settings.combiStakes[combiType] : stakePerBet;
        settings.realCombiStakes.push(stakePerBetReal);
        calcSystemCombiTypeWins(combiType);
        settings.winMin += settings.availableCombiWinMin[getIndexFromAvailableCombiTypes(combiType)];
        settings.winMax += settings.availableCombiWinMax[getIndexFromAvailableCombiTypes(combiType)];
      });

      settings.stakeCalculationType = "total";
      break;
    default:
      break;
  }
  settings.winMin = settings.availableCombiWinMin[0];

  if (settings.winMin !== settings.winMax && totalBets > 1) {
    //maks
    // $('#win_sep_min').html(' ' + APP_LANG_NEW_WINNING_CHANCE_MIN + ' ');
    // $('#win_min').html(nf(winMin));
    // $('#win_sep_max').html(' ' + APP_LANG_NEW_WINNING_CHANCE_MAX + ' ');
  }

  calcMaxSystemOdds();
  settings.winMax = settings.winMax > 100000 ? 100000 : settings.winMax;
  settings.winMin = settings.winMin > 100000 ? 100000 : settings.winMin;
}

/**
 * Calculate System Total bets
 */

function calcSystemTotalBets() {
  let totalBets = 0;

  settings.combiTypes?.forEach((combiType, index) => {
    totalBets += Number(settings.activeCombinations[combiType]?.length || 0);
  });

  return totalBets;
}

/**
 * Calculate system max odds
 */

function calcMaxSystemOdds() {
  if (betType === "system" || (betType === "combi" && settings.cntMultiBets > 0)) {
    settings.maxOdds = 0;
    settings.combiTypes?.forEach((combiType, index) => {
      settings.maxOdds += calcMaxSystemOddsPerCombiType(combiType);
    });
  }
}

function calcMaxSystemOddsPerCombiType(combiType) {
  // const { bets } = props;
  let odds = {};
  let sumsPerGame = {};

  bets?.forEach((bet, index) => {
    let currentBetPrice = Number(bet.price);

    if (typeof odds[bet.gameId] === "undefined") odds[bet.gameId] = {};
    if (typeof odds[bet.gameId][bet.marketId] === "undefined") odds[bet.gameId][bet.marketId] = {};
    if (typeof odds[bet.gameId][bet.marketId][bet.hc] === "undefined") odds[bet.gameId][bet.marketId][bet.hc] = currentBetPrice;
    if (currentBetPrice > odds[bet.gameId][bet.marketId][bet.hc]) odds[bet.gameId][bet.marketId][bet.hc] = currentBetPrice;
  });

  for (let gameId in odds) {
    for (let marketId in odds[gameId]) {
      for (let price in odds[gameId][marketId]) {
        if (typeof sumsPerGame[gameId] === "undefined") sumsPerGame[gameId] = 0;
        sumsPerGame[gameId] += odds[gameId][marketId][price];
      }
    }
  }

  let indices = [];
  let zeroIndexedSumsPerGame = [];
  let zeroIndexedToGameID = [];
  let i = 0;
  for (let key in sumsPerGame) {
    i++;
    indices.push(i);
    zeroIndexedSumsPerGame[i] = sumsPerGame[key];
    zeroIndexedToGameID[i] = key;
  }
  let oddsCombis = getAllPossibleCombinations(indices, combiType);
  let maxOdds = 0;

  oddsCombis?.forEach((indices, k1) => {
    let oddPerCombi = 1;
    let gameIDsInCombi = [];

    for (let key2 in indices) {
      oddPerCombi *= zeroIndexedSumsPerGame[indices[key2]];
      gameIDsInCombi[indices[key2]] = Number(zeroIndexedToGameID[indices[key2]]);
    }

    let allBanksInCombi = true;

    settings.bankIds?.forEach((bankId, k3) => {
      if (typeof bankId === "undefined") return true;

      if (gameIDsInCombi.includes(bankId) === false) {
        allBanksInCombi = false;
        return false;
      }
    });

    if (!allBanksInCombi) return true;

    maxOdds += oddPerCombi;
  });

  return maxOdds;
}

/**
 * Calculate all possible combinations
 */

function getAllPossibleCombinations(betsIndexes, combiType, restricted) {
  if (typeof restricted === "undefined") restricted = true;

  let combis = restricted ? getAllPossibleCombinations_Cached(betsIndexes, combiType) : getAllPossibleCombinations_General_v3(betsIndexes, combiType);
  return combis;
}

function getAllPossibleCombinations_Cached(betsIndexes, combiType) {
  let maxCombiSize = betsIndexes?.length;
  if (typeof allPossibleCombis[maxCombiSize] === "undefined" || typeof allPossibleCombis[maxCombiSize][combiType] === "undefined") return [];
  return allPossibleCombis[maxCombiSize][combiType];
}

function getAllPossibleCombinations_General_v3(betsIndexes, combiType) {
  // Version 3, highest speed
  let results = [],
    result,
    mask,
    i,
    total = Math.pow(2, betsIndexes?.length);

  for (mask = combiType; mask < total; mask++) {
    result = [];
    i = betsIndexes?.length - 1;

    do {
      if ((mask & (1 << i)) !== 0) result.push(betsIndexes[i]);
    } while (i--);

    if (result?.length === combiType) results.push(result);
  }

  return results;
}

function computeNumberOfCombis(n, k) {
  return factorial(n) / (factorial(k) * factorial(n - k));
}

function factorial(n) {
  return n <= 1 ? 1 : n * factorial(n - 1);
}

// function dec(n) {
//   return n.toFixed(2);
// }

function nf(number, options, locale) {
  // let defaultOptions = { minimumFractionDigits: 2, maximumFractionDigits: 2 };
  // if (typeof (options) !== 'object') options = {};
  // if (typeof (locale) === 'undefined') locale = "de-DE"; //replace later with APP_LOCALE

  // Object.keys(options)?.forEach((key, value) => {
  //   defaultOptions[key] = value;
  // });
  // return Intl.NumberFormat(locale, defaultOptions).format(number);

  return number;
}

function eventHasMultipleSelections(gameId) {
  if (typeof settings.multis[gameId] === "undefined") return false;

  return settings.multis[gameId].betsIndexes?.length > 0;
}

// function jsonCopy(src) {
//   return JSON.parse(JSON.stringify(src));
// }

function analyzeBanks() {
  // const { bets } = props;
  // let activeCombis = 0;
  if (settings.activeCombinations) {
    settings.activeCombinations?.forEach((combis, key) => {
      if (typeof combis === "undefined") return true;

      if (combis && combis?.length > 0) {
        // activeCombis++;
      }
    });
  }

  // Decrement because the active combis get updated AFTER the bank is clicked
  // activeCombis--;
  settings.cntBanks = 0;
  settings.bankIds = [];
  bets?.forEach((bet, index) => {
    if (bet.isBank === 1) {
      settings.cntBanks++;
      settings.bankIds[index] = bet.gameId;
    }
  });

  bets?.forEach((bet, index) => {
    if (bet.isBank !== 1) {
      bet.isBank = Object.keys(bets)?.length - settings.cntBanks === 1 || eventHasMultipleSelections(bet.gameId) ? -1 : 0;
    }
  });
}

function analyzeMultis() {
  // const { bets } = props;

  // multis[gameID1][betsIndex1,betsIndex3, betsIndex5, betsIndex2]
  // multis[gameID2][betsIndex3,betsIndex4]
  settings.multis = {};
  settings.eventsInSlip = [];

  bets?.forEach((bet, index) => {
    bets?.forEach((bet2, index2) => {
      if (bet.gameId === bet2.gameId) {
        // same bet
        if (bet.marketId === bet2.marketId && bet.selectionId === bet2.selectionId && bet.hc === bet2.hc) return;

        // groups multis according to gameid and betsIndex e.g.
        // multis[312321][1,4,2]
        if (typeof settings.multis[bet.gameId] == "undefined") {
          settings.multis[bet.gameId] = {};
          settings.multis[bet.gameId]["betsIndexes"] = [];
        }

        let keyExists = false;

        for (let m = 0; m < settings.multis[bet.gameId]["betsIndexes"]?.length; m++) {
          if (settings.multis[bet.gameId]["betsIndexes"][m] === index + 1) keyExists = true;
        }

        if (keyExists === false) settings.multis[bet.gameId]["betsIndexes"].push(index + 1);
      }
    });

    if (settings.eventsInSlip.includes(Number(bet.gameId)) === false) settings.eventsInSlip.push(Number(bet.gameId));
  });

  //cntMultiBets = Object.keys(multis)?.length;
  settings.cntMultiBets = 0;
  let allMultis = [];

  for (let key in settings.multis) {
    settings.cntMultiBets += settings.multis[key].betsIndexes?.length - 1;
    allMultis.push(Number(key));
  }

  bets?.forEach((bet) => {
    bet.isMultiBet = allMultis.includes(Number(bet.gameId)) === true ? 1 : 0;
  });

  settings.hasMulti = settings.cntMultiBets > 0 ? true : false;

  if (settings.cntMultiBets > 0) getMultiOdds();
}

function getMultiOdds() {
  // const { bets } = props;
  settings.multiOdds = {};
  for (let key in settings.multis) {
    let betIndexes = settings.multis[key].betsIndexes;
    settings.multiOdds[key] = [];

    for (let i = 0; i < betIndexes?.length; i++) {
      //take it as a base and start searching for accumulated odds
      let combinedOdd = Number(bets[betIndexes[i] - 1]["price"]);
      let marketsCombined = [];

      for (let j = 0; j < betIndexes?.length; j++) {
        if (i === j) continue;

        if (checkIfWinIsAccumulated(betIndexes[i], betIndexes[j]) === true) {
          if (marketsCombined.includes(bets[betIndexes[j] - 1]["marketId"]) === true) {
            // multiOdds[key].push(combinedOdd);
            combinedOdd = Number(bets[betIndexes[i] - 1]["price"]);
          }

          combinedOdd += Number(bets[betIndexes[j] - 1]["price"]);
          marketsCombined.push(bets[betIndexes[j] - 1]["marketId"]);
        }
      }

      /*
       //even if a single odd/price is not accumulated it is added to the multiOdds array
       multiOdds[key].push(betPrice);
       */

      settings.multiOdds[key].push(combinedOdd);
    }

    settings.multiOdds[key] = settings.multiOdds[key].reduce(function (a, b) {
      if (a.indexOf(b) < 0) a.push(b);
      return a;
    }, []);

    settings.multiOdds[key].sort(function (a, b) {
      return a - b;
    });
  }
}

function checkIfWinIsAccumulated(betsIndex1, betsIndex2) {
  return false;
}

/**
 * Calculate bet types
 */

function calcBetTypes() {
  analyzeMultis();
  analyzeBanks();

  switch (betType) {
    case "single":
      calcSingleStake();
      settings.totalBetsPerBetType = Object.keys(bets)?.length;
      break;

    case "combi":
      calcCombiStake();
      settings.totalBetsPerBetType = 1;
      break;

    case "system":
      calcSystemTypes();
      calcSystemStake();
      settings.totalBetsPerBetType = calcSystemTotalBets();
      break;

    default:
      break;
  }
}

function getIndexFromCombiTypes(combiType) {
  return settings.combiTypes.findIndex((value) => value === Number(combiType));
}

function getIndexFromAvailableCombiTypes(combiType) {
  return settings.availableCombiTypes.findIndex((value) => value === Number(combiType));
}

/**
 * Calculate slip price and return
 */

export const calculateSlip = (slipTemplates) => {
  if (!Object.keys(slipTemplates)?.length) return slipTemplates;

  Object.values(slipTemplates)?.forEach((sTemplate) => {
    settings = sTemplate.settings;
    stake = sTemplate.stake;
    betType = sTemplate.betType;
    slipTemplate = sTemplate.slipTemplate;
    // console.log(slipTemplate)
    bets = sTemplate.settings?.bets ? Object.values(sTemplate.settings.bets) : {};

    if (Object.keys(bets)?.length) {
      calcBetTypes();

      sTemplate.stake = stake;
    }

    // settings.multis = settings.multis.filter((item)=>item!=undefined)
  });

  return slipTemplates;
};
