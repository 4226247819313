/**
 * SVG component for own icon 
 */

import React from 'react';
import './IconOwn.css';

const IconIH = (props) => {

	return (
		<div style={{ display: "flex" }}>
			<svg className="icon-own" viewBox="0 0 2000 2000">
				<linearGradient gradientTransform="rotate(90)" id="icongradient">
					<stop className="icon-color-1" offset="0%" />
					<stop className="icon-color-2" offset="50%" />
					<stop className="icon-color-1" offset="100%" />
				</linearGradient>
				<g><path fill="url(#icongradient)" d="M1813,1409.3c-24.2-34.1-64-53.1-106.4-50.4l-404.8,28.2c-26.8,2.4-51.9-11.4-65.7-33.8l-152.8-248.9
		l526.2-869.5c19.3-31.9,9.1-73.6-22.9-93c-31-18.8-73.9-8.2-92.8,22.5L1000,968.7L506.2,164.4c-18.9-30.8-61.7-41.3-92.8-22.5
		c-31.9,19.3-42.2,61-22.9,93l526.2,869.5l-152.8,248.9c-13.7,22.4-38.9,36.2-65.7,33.8l-404.8-28.2c-42.4-2.7-82.3,16.3-106.4,50.4
		c-22.8,32.2-28.5,71.9-15.5,109.2c16.8,48.2,62.5,80.6,113.5,80.6h387c69,0,134.1-36.7,169.7-95.7L1000,1242l158.2,261.4
		c35.7,59,100.7,95.7,169.7,95.7h387c51.1,0,96.7-32.4,113.5-80.6C1841.5,1481.2,1835.8,1441.4,1813,1409.3z"/>
					<path fill="url(#icongradient)" d="M815.8,1684.7C815.8,1684.7,815.8,1684.7,815.8,1684.7v95.8c0,5.3,0.9,10.7,2.6,15.4
		c15.9,49.5,102.6,71.6,181.6,71.6c91.5,0,184.1-29.9,184.1-87v-95.7c0,0,0,0,0-0.1C1183.8,1570.5,816.2,1570.6,815.8,1684.7z
		 M1135,1780.5c0,9.8-46.4,37.9-135,37.9c-80.5,0-130.4-24.3-135.1-38.2v-34.5c35.4,17.3,85.3,26.2,135.1,26.2
		c49.7,0,99.7-8.9,135-26.2V1780.5z"/></g>
			</svg>
		</div>
	);
};

export default IconIH;
