/**
 * Component for game box detail avatar svg
 */

import { log } from '../../config/log';
import React from 'react';
import { connect } from 'react-redux';

class GameBoxDetailedAvatarFBBB extends React.PureComponent {

  renderShirt() {
    const { pattern, color1, color2, color3, color4, color5, color6, color7 } = this.props;

    switch (pattern) {

      case "1":
        return (
          <div>
            {/* <div className="competitor-avatar-bg-short"></div> */}
            <svg className="team-avatar" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="3em" height="3em"
              viewBox="0 0 520.7 565.9" style={{ enableBackground: "0 0 520.7 565.9" }} xmlSpace="preserve">
              <path fill={color1} d="M488.6,263.4c-16.5-119-39.9-176.8-45.2-184.5C436,68,430.4,65.2,429.1,64.6c0-0.1,0-0.1,0-0.1
                L317.8,18.8c0,0-18.8,14.8-55.8,13.5C225.1,33.6,206,18.8,206,18.8L94.7,64.5c0,0-6.1,1.9-14.7,14.3c-4.9,7.1-25.5,57.6-41.6,160.5
                c-1.3,8.1-2.5,16.5-3.7,25.2c-3.5,25.1,75.2,3.9,75.2,3.9l2.9-20.1c0.9,15.4,1.4,29.9,1.4,42.3c0,76.3-19.4,244.4-19.4,244.4
                c79.4,20.1,167.2,20.5,167.2,20.5s87.8-0.3,167.2-20.5c0,0-19.4-168.1-19.4-244.4c0-12.1,0.5-26,1.3-40.8l3.4,23.5
                C414.3,273.3,492.2,288.8,488.6,263.4z"/>
              <path fill={color6} d="M485.8,243.9c-2.8-18.1-5.7-34.7-8.7-49.7l-20.3,4.8l-11.2-47.1l21-5c-5.3-21-10.4-37-14.5-48.3
                l-18.2,4.3l-7.9-33.1c-0.2,0.9-0.5,1.8-0.8,2.9c0,0,0-0.1,0-0.1h-8.8V59.3l-50.3-20.7v34h-49l-29.9,25.7l3.2-2.1l26.1-22.7v48.2
                h-48.5v-10.6l12.7-8.4l-42.7-4.2l-20.4-22.5v45.8h-49V73.4h46.8l-16-17.7L187,26.6l-19,7.8v38.2h-49v-18l-24.3,10l2-0.8
                c0,0,1,3.6,2.3,9.7h19.4v48.2h-12c1.6,14.9,3.7,30.5,5,42.2l-26-6.9l12.3-46.9l7.7,2c-0.9-8.1-2-15.6-3.2-22.3l-5.1,19.5l-27-7.1
                c-4.1,11.7-9,27.7-14.1,48.3l28.3,7.4L72,204.9L45.7,198c-2.5,12.7-4.9,26.5-7.2,41.4c-0.4,2.8-0.9,5.6-1.3,8.5l22.1,5.8l-6.1,23.1
                c18.5,0.8,45.5-5.5,54.1-7.6l0.9-3.4L60.2,253l12.5-47.4l42.4,11.2c0-0.6,0-1.3,0-1.9c-0.3-14.5-1.5-28.3-2.4-40.1
                c-0.1-0.9-0.2-1.9-0.3-3c0,0,0,0,0,0h6.1v47.6h-3.4c-0.2,9.6-1.6,22.2-2.1,26.8l0.3,2.8l0,0c0,0,0,0.1,0,0.1l0,0.5l0,0
                c0.4,6.6,0.7,13.5,0.9,20.1h4.3v48.2H114c-0.6,15.4-1.6,32.5-2.9,50.3h7.3v47.6h-11.2c-1.6,17.6-3.2,34.7-4.8,50.3h16v48.2H97.3
                c-1.1,10.2-2,17.4-2.3,20.5c8,2,16,3.8,24,5.5v-24.6h49v32.6c18.6,2.4,35.8,4,50.3,5v-37.6h48.5v39.4c8.3-0.2,26.7-0.8,50.3-2.7
                v-36.7h49v31.2c16.3-2.3,33.4-5.3,50.3-9.1v-22.1H427c-1.4-12.4-3.2-30-5.2-50.3h-5.3v-47.6h0.8c-0.5-5-0.9-10-1.3-15.1v13.2h-49
                v-47.6h46.2c-1.3-17.7-2.4-34.9-3-50.3h-43.2v-48.2h42.9c0.2-6.9,0.5-14.1,0.9-21c0-0.2,0-0.3,0-0.5c0.1,1.6,0.3,2.2,0.3,1.4
                l1.7,11.6l6.9-1.6l3.6,15.1c12.9,2,37,5,52.2,1.7l-6.8-28.5L485.8,243.9z M168,465.6h-49v-47.6h49V465.6z M168,367.6h-49v-48.2h49
                V367.6z M168,269.1h-49v-47.6h49V269.1z M168,171.1h-49v-48.2h49V171.1z M217.8,514.6h-49v-48.2h49V514.6z M217.8,416.1h-49v-47.6
                h49V416.1z M217.8,318.1h-49v-48.2h49V318.1z M217.8,219.5h-49v-47.6h49V219.5z M266.8,465.6h-48.5v-47.6h48.5V465.6z M266.8,367.6
                h-48.5v-48.2h48.5V367.6z M266.8,269.1h-48.5v-47.6h48.5V269.1z M266.8,171.1h-48.5v-48.2h48.5V171.1z M316.6,514.6h-48.5v-48.2
                h48.5V514.6z M316.6,416.1h-48.5v-47.6h48.5V416.1z M316.6,318.1h-48.5v-48.2h48.5V318.1z M316.6,219.5h-48.5v-47.6h48.5V219.5z
                M366.1,465.6h-49v-47.6h49V465.6z M366.1,367.6h-49v-48.2h49V367.6z M366.1,269.1h-49v-47.6h49V269.1z M366.1,171.1h-49v-48.2h49
                V171.1z M415.9,466.4v48.2h-49v-48.2H415.9z M424.9,73.7c-0.1,0.3-0.1,0.5-0.2,0.8C424.8,74.2,424.9,73.9,424.9,73.7z M424.5,75.4
                c-0.5,2.1-1.1,4.5-1.6,7C423.5,79.8,424,77.5,424.5,75.4z M422.8,82.7c-0.1,0.7-0.3,1.3-0.4,2C422.5,84,422.7,83.3,422.8,82.7z
                M422.3,85c-0.1,0.7-0.3,1.4-0.4,2.1C422,86.4,422.2,85.7,422.3,85z M421.8,87.5c-0.1,0.7-0.3,1.4-0.4,2.1
                C421.5,88.9,421.7,88.2,421.8,87.5z M421.3,90.1c-0.1,0.7-0.3,1.4-0.4,2.2C421,91.5,421.2,90.8,421.3,90.1z M420.8,92.8
                c-0.1,0.7-0.3,1.5-0.4,2.2C420.5,94.3,420.7,93.5,420.8,92.8z M420.3,95.6c-0.1,0.7-0.3,1.5-0.4,2.3
                C420.1,97.1,420.2,96.3,420.3,95.6z M419.8,98.5c-0.1,0.8-0.2,1.5-0.4,2.3C419.6,100,419.7,99.2,419.8,98.5z M419.4,101.4
                c-0.1,0.8-0.2,1.5-0.3,2.3C419.1,103,419.2,102.2,419.4,101.4z M418.9,104.5c-0.1,0.8-0.2,1.6-0.3,2.4
                C418.7,106,418.8,105.3,418.9,104.5z M416.4,122.9h0.4c0.3-2.4,0.5-4.7,0.7-7c0.3-3,0.6-5.9,1-8.7l14.1-3.4l11.2,47.1l-30.9,7.4
                c0.9-9.5,2.2-20.9,3.4-32.1V122.9z M366.9,73.4h49v48.2h-49V73.4z M410.6,247.7c0,0.1,0,0.3,0,0.4
                C410.6,247.9,410.6,247.8,410.6,247.7z M410,237.2c0,0.1,0,0.2,0,0.4C410,237.5,410,237.3,410,237.2z M410,238.4c0,0.1,0,0.1,0,0.2
                C410,238.5,410,238.5,410,238.4z M410.1,240c0,0.2,0,0.3,0,0.5C410.1,240.4,410.1,240.2,410.1,240z M410.2,241.2c0,0.2,0,0.3,0,0.5
                C410.2,241.5,410.2,241.3,410.2,241.2z M410.3,242.6c0,0.2,0,0.4,0,0.5C410.3,243,410.3,242.8,410.3,242.6z M410.3,243.7
                c0,0.2,0,0.4,0,0.5C410.4,244,410.3,243.9,410.3,243.7z M410.4,244.9c0,0.2,0,0.4,0,0.6C410.4,245.3,410.4,245.1,410.4,244.9z
                M410.5,245.9c0,0.2,0,0.3,0,0.5C410.5,246.2,410.5,246.1,410.5,245.9z M410.5,246.7c0,0.2,0,0.5,0.1,0.7
                C410.6,247.2,410.6,246.9,410.5,246.7z M420.1,258.7L409.4,214c0,0.3,0,0.6,0,0.9c0,1.4,0,2.9,0,4.6h-42.5v-47.6h45
                c0,0,0-0.1,0-0.1c-0.1,1.1-0.1,2.1-0.1,3.1c-0.2,10.6-1.7,22.9-2.3,36l46-11l11.4,47.7L420.1,258.7z M409.8,234.4c0,0,0-0.1,0-0.1
                C409.8,234.3,409.8,234.3,409.8,234.4z M409.4,223.5C409.4,223.5,409.4,223.5,409.4,223.5C409.4,223.5,409.4,223.5,409.4,223.5z
                M412.9,159.7c0,0.2,0,0.3,0,0.5C412.8,160,412.9,159.8,412.9,159.7z M412.6,162.5c0,0.1,0,0.1,0,0.2
                C412.6,162.6,412.6,162.5,412.6,162.5z M412.1,169.8C412.1,169.8,412.1,169.8,412.1,169.8C412.1,169.8,412.1,169.8,412.1,169.8z"/>
              <path fill={color7} d="M395.4,542.2l33.7-7.2c0,0-12.3-125-17.3-185.5c-3-36.5-1.4-66.1-1.4-98.3
                c0-12.8-1.4-24.5-1.1-36.3c0.3-14.5,2.2-28.3,2.4-40.1c0.2-12.2,3.5-37.1,5.7-59c2.9-28.9,11-51.6,11-51.6L392.1,49
                c0,0-6,67.3-6,139.7c-0.1,57.7-1.6,119.5,0,151C390.8,432.9,395.2,538.8,395.4,542.2c-1.1,0.1-16.3,2.9-37.9,5.8
                c-0.3-9.2-6.9-211.1-8.6-319.9c-0.7-44,8.6-193.1,8.6-193.1l-32.4-13.6l11.2,17.3l-23.3,39.1c0,0,1.8,90.6,1.8,123.9
                c0,88,1.6,350.8,1.6,351c-22.1,2-39.1,2.6-51.2,2.7c5.3-0.1,12.7-0.4,12.7-0.4s3-271.2,3.2-362c0-23.4-2.4-94.6-2.4-94.6l-32.5,0.6
                c0,0-3.5,68-3.5,91.2c-0.2,90.8,2.6,364.7,2.6,364.7s5.5,0.2,10.3,0.3c-15.7-0.3-32.6-1.4-48.1-2.8c0.1-6.8,2.6-264,2.6-350.8
                c0-33.3,3.5-127.4,3.5-127.4l-20.8-38.5l7-14.3l-31.6,12.8c0,0,5.4,123.4,6.7,167.4c3.2,108.7-6.2,335.5-6.6,346.5
                c-19.5-2.5-33.3-4.7-34.5-4.9c0.1-3,3.9-149.2,7.9-271.2c1-32.2-9.1-223.2-9.1-223.2L94.7,64.5c0,0,4.2,15.6,8.3,40
                c3.5,20.4,4.7,38.9,6.6,58.7c5,49.4,4.8,91.7,4.7,121.3C114.1,351.1,94.7,535,94.7,535l39,8.3l0,0c0.2,0,14.4,2.3,34.6,4.9
                c0,0.2,0,0.3,0,0.3l20.7,2.1c4.7,0.5,9.2,1,13.5,1.4l4.9,0.5c0,0,0,0,0,0c25.4,2.3,43.3,2.9,54.8,2.9c30.3,0,63-3.3,86.2-6.2l9-1
                c0,0,0-0.1,0-0.2C379.8,545.1,395.2,542.2,395.4,542.2L395.4,542.2z M316.6,552.7l26.1-2.8C333.4,551,324.7,551.9,316.6,552.7z"/>
              <path fill={color3} d="M428.7,530.9c0,0-125.6,8.3-167.6,8.3c-40.6,0-161.7-8-165.5-8.2l4-36.9
                c2.5,0.1,120.5,4.9,160.2,4.9c41.1,0,165-4.9,165-4.9c-1.1-10.6-2.4-22.7-3.7-35.7H103.3l3.2-34.6h311.2
                c-1.1-11.8-2.1-23.8-3.1-35.7H109.8l2.9-40.6h299.1c-0.8-12.7-1.4-24.8-1.7-35.7H114.3l0.1-36.9H410c0.2-8.1,0.6-16.7,1-24.9l0-0.1
                c-0.1,1.7-0.5-3.5-0.9-10.7H113.9l0.6-39.5h295.7c0.7-8.8,1.5-17.2,1.6-24.7c0.1-3,0.3-6.8,0.7-11H111.7l-4.8-40.6h309.9
                c0.3-2.5,0.5-4.9,0.8-7.4c1.1-10.5,2.7-20.3,4.3-28.4H301.7l-39,17.6l-41.1-17.6h-0.4L187,50.1l3.5-20.4l142.6,0.7l6.5,20.2h55.8
                L342.2,29l-156.9-1.6l-56.8,23.3h57.7l33.6,36.9H101.9c1.5,8.2,3,17.9,4,28.5c0.2,2.4,0.5,4.8,0.8,7.3h0.1l4.8,40.6h0
                c0.5,4.3,0.9,8.1,1.1,11c0.6,7.5,1.3,15.7,1.8,24.5l-0.6,39.6h0c-0.3,3.1-0.6,5.7-0.8,7.3l0.3,2.8l0,0c0,0,0,0.1,0,0.1l0,0.5l0,0
                c0.4,8.2,0.8,16.8,1,24.8l-0.1,37h0c-0.3,11-1,23.1-1.7,35.7h0l-2.9,40.6h-0.1c-1,11.9-2,23.8-3.1,35.5l-0.1,0l-3.2,34.8h0
                c-1.3,13-2.6,25.1-3.7,35.7l0,0l-4.1,37l0,0c-0.4,3.7-0.6,3.8-0.6,3.8c88.5,21.2,167.1,20.7,167.1,20.7s91.4-1.7,167.2-20.5
                C429.1,535,429.1,534.6,428.7,530.9z"/>
              <path fill={color4} d="M112.7,248.2c0,0,2.6-21.5,2.3-33.3c-0.3-14.5-1.5-28.3-2.4-40.1c-0.9-12.2-4.6-37-6.7-58.9
                C103,87,96.7,63.7,96.7,63.7l-2,0.8c0,0-6.1,1.9-14.7,14.3c-4.9,7.1-25.5,57.6-41.6,160.5c-1.3,8.1-2.5,16.5-3.7,25.2
                c-3.5,25.1,75.2,3.9,75.2,3.9L112.7,248.2z"/>
              <path fill={color4} d="M488.6,263.4
                c-16.5-119-39.9-176.8-45.2-184.5C436,68,430.4,65.2,429.1,64.6c0-0.1,0-0.1,0-0.1l-3.2-1.3l1.6,0.9c0,0-7.1,22.8-10,51.7
                c-2.2,21.9-5.5,46.8-5.7,59c-0.2,11.8-2.1,25.5-2.4,40.1c-0.3,11.8,1.4,38.4,1.6,34.8l3.4,23.5
                C414.3,273.3,492.2,288.8,488.6,263.4z"/>
              <path fill={color5} d="M488.6,263.4c-16.5-119-39.9-176.8-45.2-184.5C436,68,430.4,65.2,429.1,64.6c0-0.1,0-0.1,0-0.1
                l-0.6-0.3L338.7,27l-19.6,47c0,0-55.5,27.2-55.5,32.9c0,145.9-5.3,448.4-2.2,448.4c4.1,0,20.6-0.2,24.8-0.4c93.8-4.6,143-20,143-20
                s-6.4-58.3-11.6-114.7c-2.4-26.3-4.7-58.7-5.9-77.9c-3.1-48.7-0.8-91.2-0.8-91.2s-0.1-0.8-0.2-2.3c0.1,1.1,0.2,1.5,0.2,0.8
                l3.4,23.5C414.3,273.3,492.2,288.8,488.6,263.4z"/>
              <path fill={'#00000060'} d="M429.1,64.5l-93-38.3
                c-5.8,17.6-16.5,34.1-30.9,44.9c0.2-0.2,0.5-0.3,0.7-0.4c7.3-4.8,12.9-11,19.3-17c8.9-8.4,18-12.8,30.3-10.5
                c4.7,0.9,9.8,2.5,14.7,4.6c7.8,3,15.3,6.9,22.6,11c7.5,4.2,16,9.2,20.1,17.2c5.6,11,5.1,24.9,4.6,36.8c-0.6,13.1-2.4,25.9-4.2,38.9
                c-0.8,5.6-2.1,17.3-10.6,16.7c-6.9-0.5-10.5-7.7-13-13.3c-8.3-18.5-9.1-21.1-16-40.1c6.4,23.6,8.6,30.2,14.9,53.8
                c2.4,9.1,5.1,18.3,6,27.8c0.5,5.1,1.3,12.1-2.8,16c-3.8,3.6-10.6-0.6-13.5-3.4c-7.4-7-12-16.5-16.4-25.5
                c-8.2-16.8-18.4-32.5-27.6-48.7c16.9,29.9,29.9,66.2,42.2,98.4c14.7,38.6,21.5,79.3,26.6,120.1c5.4,42.7,21,113.2,0.2,153.9
                c-10.3,20.2-11.4,17.7-39.5,32.2c-12.7,6.6-50.2,12.3-68,14.5c0.2,0.2,18.7-1.6,18.8-1.3c32-2.6,74.3-7.7,114.4-17.8
                c0,0-19.4-168.1-19.4-244.4C409.7,214.3,429.1,64.5,429.1,64.5z"/>
              <path fill={'#00000060'} d="M143.7,527.8c-10.7-8.6-18.1-20.6-20.7-34.1c-6.6-35.2,7.6-69.9,16-103.6c5.2-20.7,9.8-41.6,16.3-62
                c6.1-19.2,24.1-62.8,26.4-82.9c-1.4,11.8-21.5,49.4-27.7,59.7c-4.5,7.3-14.8,26.1-26,21.1c-7.6-3.3-2.6-20.7-1.8-26.3
                c4.7-34.2,15.6-65.9,27.3-98.4c13.7-38.3,24.3-53.9,33.4-82c-10.4,14.8-19.6,30.7-28.2,46.6c-7.1,13-13.9,28.7-27.8,35.7
                c-0.9,0.5-2,0.1-2.3-0.9c-4.3-15.4,3.2-32.5,6.9-47.4c4.7-18.6,10.2-37,13.6-55.9c-2.7,15.1-12.2,29.3-17.9,43.4
                c-4.1,10.1-12.7,10.7-14.7,0.4c-14.9-72.8-11.4-74.5,15-85.9c16.6-7.2,40.9-23.8,58.6-12c8.3,5.5,14.7,13.6,22,20.5
                c-10.5-10.4-17.9-24.1-21.9-38.6L94.7,64.5c0,0,19.4,149.8,19.4,226c0,76.3-19.4,244.4-19.4,244.4c45.3,11.5,93.4,16.5,126.3,18.7
                c-9.7-1.7-19.3-3.9-28.8-6.5C175.9,542.9,157.1,538.6,143.7,527.8z"/>
              <path fill={'#00000050'} d="M330.9,30.6l-135.6-0.5c-3,2.3-7,13.4,12.2,37.4c12.3,15.4,34.8,30.8,54.9,34.9
                c20.1-4.2,45.7-20.7,57.7-36.3C335,46.7,333.9,32.9,330.9,30.6z"/>
              <path fill={'#b3b3b3'} d="M338.7,24.7c-9-4.3-17.2-7.1-26.1-6.1c-0.5,0.1-34,4.4-49.5,4.4c-15.7,0-49-4.3-49.5-4.4
                c-8.9-1-17,1.7-26.1,6.1c-0.3,0.2-15.6,8.6,5.8,42.9c8.7,14,31.9,39.2,69.7,47.5c37.9-8.3,61-33.5,69.7-47.5
                C354.3,33.3,339,24.9,338.7,24.7z M315.6,63.2c-12,15.6-32.3,30.2-52.5,34.3c-20.1-4.2-40.5-18.8-52.5-34.3
                c-9.2-12-13.4-23.1-14.6-30.3c10.8,2.3,32.2,7.1,67.1,7.1c34.4,0,56.1-4.6,67-7C329,40.3,324.8,51.3,315.6,63.2z"/>
            </svg>
          </div>
        );

      default:
        return (
          <div>no pattern</div>
        );
    }


  }

  render() {
    log(this.constructor.name, 'render');
    return <div>{this.renderShirt()}</div>
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    pattern: ownProps.pattern,
    color1: ownProps.color1,
    color2: ownProps.color2,
    color3: ownProps.color3,
    color4: ownProps.color4,
    color5: ownProps.color5,
    color6: ownProps.color6,
    color7: ownProps.color7,
  };
};

export default connect(mapStateToProps)(GameBoxDetailedAvatarFBBB);
