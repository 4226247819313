/**
 * SVG component for own icon 
 */

import React from 'react';
import './IconOwn.css';

const IconCC = (props) => {

	return (
		<div style={{ display: "flex" }}>
			<svg className="icon-own" viewBox="0 0 2000 2000">
				<linearGradient gradientTransform="rotate(90)" id="icongradient">
					<stop className="icon-color-1" offset="0%" />
					<stop className="icon-color-2" offset="50%" />
					<stop className="icon-color-1" offset="100%" />
				</linearGradient>
				<g>
					<ellipse transform="matrix(0.7071 -0.7071 0.7071 0.7071 150.467 1067.1417)" cx="1363.4" cy="351.9" rx="156.7" ry="156.7" fill="url(#icongradient)" />
					<path fill="url(#icongradient)" d="M1851.7,1738.5L1851.7,1738.5c-9.4-9.4-24.7-9.4-34.1,0c-44.1,44.1-102.8,68.4-165.1,68.4h-300.3
		c3.7-9.1,5.7-19,5.6-29.2L1357,1417c0-17.6-6.2-34.8-17.2-48.4l-191.4-235.8l180.1-407.5l137.9,129.7c12.8,12,29.4,19.4,46.9,20.7
		l185.7,16.7c-0.7,1.7,12.9-0.2,12.6,1.7L1582,1649.4c-2.2,13.1,16.1,28.7,29.2,30.9c1.3,0.2-1.3-0.2,0,0
		c21.1,4.1,37.4-11.8,39.3-23.6l123.4-750.9c0.6-3.4,0.4-6.7-0.4-9.8c42.4,3.1,79.3-28.7,82.6-71c3.3-42.4-28.5-79.6-71-82.9
		c0,0,0,0,0,0l-232.7-18.1l-197.5-185.8c-17.1-16.1-40.7-23.5-63.8-20.2c-1.2-0.3-2.4-0.6-3.6-0.8l-272.9-46.6
		c-23.8-3.7-47.5,3.6-65,20L760.3,668.9c-6.4,6,3.9-7.4,0,0L178,504.9c-12.8-3.7-33.1,11.1-36.8,23.8c-3.7,12.8,4.6,37.5,17.4,41.2
		L737,737.1c2.3,14.9,8.9,29.2,20,40.9c14.5,15.4,35,24.3,56.2,24.3c19.7,0,38.5-7.5,52.9-21l161.7-152.3l162.1,28l-201.8,456.6
		c-1.1,2.4-1.9,4.8-2.7,7.2c-4.1,2.5-8.1,5.4-11.7,8.7l-181.8,152.2l-307.2-140.8c-29.5-13.5-63.2-6.7-85.5,14.4L278.7,775.6
		c-3.8-12.8-30.2-20-43-16.2c-12.8,3.8-39,17.2-35.3,30l207.3,671.8c27,91,133.3,160.8,225.9,181.9c1.8,0.4,3.6,0.6,5.4,0.6
		c11,0,20.9-7.6,23.5-18.7c3-13-5.1-25.9-18.1-28.9c-75.6-17.3-136.4-74.3-158.4-148.6l-46.8-157.7l334.6,153.3
		c10.2,4.7,21,7,32.2,7c19.1,0,37.5-7.1,51.7-19.9l197.5-167.5l147.6,181.9l0.8,333.4c0,10.2,2.1,19.9,5.7,28.8H340.1
		c-13.3,0-24.1,25.8-24.1,39.1c0,13.3,10.8,36.1,24.1,36.1h1312.4c75.3,0,146-56.3,199.2-109.5
		C1861.2,1763.2,1861.2,1747.9,1851.7,1738.5z"/>
				</g>
			</svg>
		</div>
	);
};

export default IconCC;
