/**
 * Reducer for WP content
 */

import {
  SET_EVENTS_SEARCH_RESULT,
  SET_EVENT_SEARCH_LOADING,
  DELETE_SEARCH_RESULTS,
  GET_EXAMPLE_SEARCHES,
} from "../actions/types";

const INTIAL_STATE = {
  result: {},
  sports: [],
  exampleSearches: [],
  isLoading: false,
};
let stateCopy = {};
const fn = (state = INTIAL_STATE, action) => {
  switch (action.type) {
    case SET_EVENTS_SEARCH_RESULT:
      stateCopy = { ...state };
      let payload = action.payload;
      let leagues = {};
      let payloadValues = payload && typeof payload ==='object' ? Object.values(payload) : [];
      payloadValues.forEach((league) => {
        if (!leagues[league.sportCode]) {
          leagues[league.sportCode] = payloadValues.filter(
            (itm) => itm.sportCode === league.sportCode
          );
        }
      });
      stateCopy.result = leagues;
      stateCopy.sports = Object.keys(leagues);
      return stateCopy;
    case SET_EVENT_SEARCH_LOADING:
      stateCopy = { ...state };
      stateCopy.isLoading = action.payload;

      return stateCopy;
    case DELETE_SEARCH_RESULTS:
      stateCopy = { ...state };
      stateCopy.isLoading = false;
      stateCopy.result = {};
      stateCopy.sports = [];

      return stateCopy;
    case GET_EXAMPLE_SEARCHES:
      stateCopy = { ...state };
      stateCopy.exampleSearches = action.payload;
      return stateCopy;
    default:
      return state;
  }
};

export default fn;
