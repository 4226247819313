/**
 * SVG component for own icon 
 */

import React from 'react';
import './IconOwn.css';

const IconAS = (props) => {

	return (
		<div style={{ display: "flex" }}>
			<svg className="icon-own" viewBox="0 0 2000 2000">
				<linearGradient gradientTransform="rotate(90)" id="icongradient">
					<stop className="icon-color-1" offset="0%" />
					<stop className="icon-color-2" offset="50%" />
					<stop className="icon-color-1" offset="100%" />
				</linearGradient>
				<g><path fill="url(#icongradient)" d="M1731.5,631.2c79.8-39.1,112.8-135.5,73.6-215.3c-39.1-79.8-135.4-112.8-215.2-73.6c-79.7,39.1-112.7,135.5-73.6,215.2
		C1555.3,637.3,1651.7,670.3,1731.5,631.2z"/>
					<path fill="url(#icongradient)" d="M574.8,357.8L1295,838.6c-12.8,62.6-7.2,118.6,55.1,135.7c0,0,286.8,78.8,286.9,78.8l0.2,0c46.6,12.8,94.3-14.7,106.9-60.9
		c12.7-46.3-14.4-94.1-60.7-106.9c-0.1,0-0.1,0-0.2-0.1L1537,845.2l-57.8-38.6c17.5-85.1,11.3-54.9,26.8-130.6
		c8.2-39.9-22.3-90.9-40.3-127.4c-33.7-68.7-116.7-97.1-185.3-63.4l-96,47.1l153.2,102.3c-3.5,12.1-11.8,54.2-14.6,67.7L646.4,250.6
		c-29.5-19.8-69.6-11.8-89.4,17.8C537.3,298,545.2,338,574.8,357.8z"/>
					<path fill="url(#icongradient)" d="M1826.1,1594.2c-18.2-30.5-57.7-40.4-88.4-22.2l-88.7,53.1l-587.1-202l276.8-233.2c52.6-44.3,49.9-126.6-5.9-167.3
		l-154.9-112.9l55.6-27.3l6.4-3.2L916.6,663.5l-0.1,0c-54,26.5-88.3,76.6-97.3,131.8c-11.2,68,17.3,127.2,81.6,175.7l197,143.6
		l-268.3,228.5l-579.4-199.4c-33.7-11.7-70.4,6.3-81.9,39.9c-11.6,33.7,6.3,70.3,39.9,81.9l1427.1,491.2c6.8,2.4,13.9,3.5,21,3.5
		c11.5,0,23-3.1,33.1-9.2l114.5-68.5C1834.4,1664.4,1844.4,1624.8,1826.1,1594.2z"/></g>
			</svg>
		</div>
	);
};

export default IconAS;
