/**
 * SVG component for own icon 
 */

import React from 'react';
import './IconOwn.css';

const IconHR = (props) => {

	return (
		<div style={{ display: "flex" }}>
			<svg className="icon-own" viewBox="0 0 2000 2000">
				<linearGradient gradientTransform="rotate(90)" id="icongradient">
					<stop className="icon-color-1" offset="0%" />
					<stop className="icon-color-2" offset="50%" />
					<stop className="icon-color-1" offset="100%" />
				</linearGradient>
				<g>
					<path fill="url(#icongradient)" d="M1876.7,1377.6L1876.7,1377.6l-236.1-236.1c-3.2-3.2-7.2-5.7-11.5-7.1l-156.9-52.3V938.4l119.7-119.7L1763,963.5
	c11.7,9.9,29.1,9.2,39.9-1.7l73.8-73.8c9.7-9.7,11.4-24.9,4.2-36.5l-147.6-236.1c-2.9-4.6-7-8.3-11.8-10.8l-118.1-59
	c-14.6-7.3-32.3-1.4-39.6,13.2c-3.2,6.3-3.9,13.5-2.2,20.4l5.6,22.4h-6.5c-3.6,0-7.2,0.7-10.5,1.9l-128.9,49.1
	c-15.2-31.8-50.6-48.5-84.8-40.2l-51.7,12.9c36.4-12.7,62.7-44.7,68.3-82.8h1.1c16.3,0,29.5-13.2,29.5-29.5
	c0-16.3-13.2-29.5-29.5-29.5h-1.1c-8.1-56.5-60.5-95.6-116.9-87.5c-49.8,7.2-87.2,49.2-88.5,99.5c-17-5.9-35.1-7.8-52.9-5.6
	l-94.7,11.8c-66.8,8.2-127.7,42.3-169.6,94.9c-36,45-28.7,110.6,16.3,146.6c6.7,5.4,14.1,9.9,22,13.5l101.5,46.1l-5,11.7
	c-40.7,2.8-81.5-4.1-119.1-20.1c-71.1-30.5-150.9-33.8-224.3-9.4l-69.2,23.1h-38.4c-92.7-0.1-174.9,59.2-204.2,147.2
	c-9.2,27.6-25.9,52-48.2,70.6l-136.5,113.7c-12.5,10.4-14.2,29-3.8,41.6c6.4,7.7,16.4,11.7,26.4,10.4l151.6-19
	c62.8-7.8,116.4-49.2,139.9-107.9c6.6-16.5,17-31.2,30.2-43c-0.4,5.8-0.7,11.6-0.7,17.5v10.1c0,25.1,4,50.1,12.1,74L337.5,1195
	c-3.5,1.8-6.6,4.2-9.2,7.2l-177.1,206.6c-10,11.7-9.4,29.2,1.5,40.1l59,59c10.8,10.8,28,11.6,39.8,1.8L426,1364.3l32.3-16.1
	L529,1584c3.7,12.5,15.2,21,28.3,21h59c16.3,0,29.5-13.2,29.5-29.5c0-1.4-0.1-2.8-0.3-4.2l-27.4-192l116.8-116.8
	c34.5-34.5,58.7-77.9,69.9-125.4c19.2,8.3,37.1,19.1,53.4,32.1c66.3,53.1,152.3,74.8,235.9,59.6l143.3-26.1
	c12.3,12.6,25.8,23.9,40.4,33.8l79.1,174c1.1,2.4,2.5,4.7,4.2,6.7l147.6,177.1c5.6,6.7,13.9,10.6,22.7,10.6h59
	c16.3,0,29.5-13.2,29.5-29.5c0-4.2-0.9-8.4-2.6-12.2l-120.2-264.5l50.9,9.3l171.1,142.6c7.2,6,16.9,8.2,26.1,6l118.1-29.5
	c15.8-4,25.4-20,21.5-35.8C1883.2,1386.1,1880.5,1381.3,1876.7,1377.6z M447.9,1259.5c-7.7,7.7-10.5,18.9-7.4,29.3l0.6,1.9
	l-44.5,22.3c-2,1-3.9,2.3-5.7,3.7L234.5,1447l-20.6-20.6l155.4-181.3l137.5-68.8c3.2,4.8,6.6,9.6,10.1,14.2L447.9,1259.5z
	 M793.4,980.6c-2.8-7.1-5.9-14.2-9.4-21.1L760.8,913L708,939.4l23.3,46.6c39.2,78.3,23.8,172.9-38.1,234.9L566,1348
	c-6.6,6.6-9.7,15.8-8.3,25l24.7,172.9h-3l-77.2-257.2l76.1-76.1c11.5-11.5,11.5-30.2,0-41.7c-32.4-32.2-50.5-76-50.4-121.6v-10.1
	c-0.1-45.6,18-89.4,50.4-121.6l-41.7-41.7C517,895.3,501.2,918,489.7,943c-48,16.3-86.4,52.8-105.2,99.8
	c-15.5,38.8-50.9,66.1-92.4,71.3l-51.7,6.5l59.1-49.3c30.7-25.6,53.7-59.3,66.4-97.3c21.2-63.8,80.9-106.9,148.2-106.8h43.2
	c3.2,0,6.3-0.5,9.3-1.5l73.8-24.6c49.5-16.5,102.9-17.5,153-2.9V980.6z M1818.4,862.9l-38,38l-18.7-15.8l43.4-43.4L1818.4,862.9z
	 M1725.6,714.5l47.4,75.8l-56.5,56.5l-48-40.6l34.5-69.1L1725.6,714.5z M1250.9,454c18.7,0,35.4,11.8,41.7,29.5h-83.4
	C1215.4,465.9,1232.1,454.1,1250.9,454z M1292.6,542.6c-2.2,6.2-5.7,11.9-10.4,16.5c-14.7,14.7-37.7,17.2-55.2,6l-17.6-22
	c-0.1-0.2-0.1-0.3-0.2-0.5H1292.6z M1007.3,560.3l94.7-11.8c20.4-2.5,40.5,5.7,53.4,21.7l87.1,108.9c7.2,9,19,13,30.2,10.2
	l78.1-19.5c7.6-1.9,15.4,2.4,17.9,9.8c2.6,7.9-1.6,16.4-9.5,19c-0.7,0.2-1.5,0.4-2.3,0.6l-112.3,18.7l-166.7-138.9l-37.8,45.3
	l8.2,6.8H964l-13.7-54.9C968.4,568.1,987.6,562.8,1007.3,560.3z M1109.3,700.6l-24.5-10.5h34.4l63.9,53.3l-25.2,9.6
	C1149.7,729.5,1132.1,710.5,1109.3,700.6z M1118.1,886.9v98.4h-17.3l-32.3-32.3L1118.1,886.9z M893.1,703
	c-12.7-5.7-22.1-17.1-25.3-30.7c-3.3-13.6-0.1-27.9,8.7-38.8c6.6-8.2,13.8-15.9,21.6-23l14.3,57.2c2.2,8.9,8.5,16.3,17,20
	l156.7,67.1c14,6.1,21.1,21.9,16.4,36.5l-2,5.9L893.1,703z M1023.9,827.3l48.5,22l-66.5,88.7c-8.8,11.7-7.6,28.2,2.7,38.6l8.6,8.6
	h-61.1L1023.9,827.3z M852.4,859.6c28.2,8.9,57.6,13.7,87.2,14.4L892,985.3h-39.6V859.6z M1083.5,1170.8
	c-66.7,12.1-135.5-5.2-188.4-47.6c-25-20-53.2-35.8-83.4-46.7c0-11.9-0.9-23.8-2.5-35.7c4.2,2.2,9,3.4,13.7,3.4h324.6
	c4.5,0,9-1.1,13.1-3.1l11,44.2c5.7,22.6,14.4,44.4,26,64.7L1083.5,1170.8z M1543.7,1544.3l-134.8-161.7l-49.8-109.5
	c5.6,1.4,11.2,2.6,16.9,3.7l50.2,9.1L1543.7,1544.3z M1745.2,1395.7l-165.6-138c-3.9-3.3-8.6-5.5-13.6-6.4l-179.5-32.6
	c-76.9-14.1-138.6-71.8-157.6-147.7l-23.2-92.9l-28.6,7.2V808.8l93-35.4l96.6-16.1c24-4,44.5-19.5,54.8-41.6l144.7-55.1h15.8
	l9.2,36.7l57.3-14.3l-13.9-55.8l53.2,26.6l5.9,9.4l-35.6,35.6c-2.2,2.2-4.1,4.8-5.5,7.7l-30.3,60.5l-12.8-10.8
	c-11.7-9.9-29.1-9.2-39.9,1.7l-147.6,147.6c-5.5,5.5-8.6,13-8.6,20.9v2.5c-21-24.2-46.6-44.1-75.3-58.4l-26.4,52.8
	c62.5,31,102,94.8,101.7,164.6v15.6c0,12.7,8.1,24,20.2,28l170.5,56.8l194.3,194.3L1745.2,1395.7z"/>
				</g>
			</svg>
		</div>
	);
};

export default IconHR;
