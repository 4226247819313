// Betbuilder screen
import React from "react";
import { connect } from "react-redux";
import withStyles from "@mui/styles/withStyles";
import Typography from "@mui/material/Typography";
import SportsTabs from "./SportsTabs";
import LeagueRow from "./LeagueRow";
import { Translate } from "react-redux-i18n";
import withRouter from "../../../helpers/withRouter";
import { Box, Stack } from "@mui/material";
import PrecannedBetsCarousel from "../../dynamic-banners/precanned-bets";
import { setLeagues } from "../../../actions";

const styles = (theme) => ({
  paper: {},
});

class BetBuilderPage extends React.Component {
  componentDidMount() {
    const { lang,  } = this.props;
    this.props.setLeagues(null, "All", "FB", lang, this.constructor.name);
  }
  render() {
    const { classes, selectedLeagues, topLeagueIds } = this.props;
    return (
      <Box className={classes.paper}>
        <Stack pb={2} width={{ xs: "90%", md: "92%" }} margin="auto">
          <Typography variant="subtitle2" color="var(--grey)">
            <Translate value="labels.quick_pick" />
          </Typography>
          <PrecannedBetsCarousel topLeagueIds={topLeagueIds} isInBBModal />
          <Typography mt={{ xs: 2, md: 3 }} variant="subtitle2" color="var(--grey)">
            <Translate value="labels.build_your_own" />
          </Typography>
        </Stack>
        <SportsTabs />
        <div>
          {selectedLeagues?.map((league, indx) => (
            <LeagueRow key={league?.leagueId} indx={indx} selectedLeague={league} />
          ))}
        </div>
      </Box>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const sportCode = params.get("selectedBetBuilderSportCode");
  const topLeagueIds = [];

  const selectedLeagues = state?.leagues && state?.leagues?.betbuilder && state?.leagues?.betbuilder[sportCode] ? state?.leagues?.betbuilder[sportCode] : [];
  if (state?.leagues && state?.leagues?.betbuilder) {
    Object.keys(state?.leagues?.betbuilder).forEach((sportCode) => {
      const sportLeagues = state?.leagues?.betbuilder[sportCode];
      sportLeagues.forEach((league) => {
        if (league?.isTopLeague) {
          topLeagueIds.push(league.leagueId);
        }
      });
    });
  }

  return {
    url: state.settings.url,
    selectedLeagues: selectedLeagues,
    lang: state.i18n.locale,
    topLeagueIds: topLeagueIds,
    lang: state.i18n.locale,
  };
};

export default withRouter(connect(mapStateToProps, { setLeagues })(withStyles(styles)(BetBuilderPage)));
