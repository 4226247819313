/**
 * Reducer for Casino component
 */

import {
  SAVE_CASINO_GAMES,
  FILTER_CASINO_GAMES,
  GET_CASINO_GAME_GROUP_NAMES,
  SEARCH_CASINO_GAMES,
  CLEAR_CASINO_SEARCH_RESULTS,
  GET_CASINO_GAMES_BY_GROUP_NAME,
  GET_USER_FAVORITE_CASINO_GAMES,
  GET_CUSTOMER_FREE_SPINS,
  CASINO_GAMES_LIST_LOADING,
  RESET_CASINO_GAMES_CACHE,
  CASINO_FREE_SPINS_LOADING,
  SAVE_VENDORS,
  GET_CASINO_SLIDER_GAMES,
  GET_CASINO_GAMES_BY_FEATURE,
  RESET_FEATURE_GAMES,
  GET_CASINO_GAMES_BY_PROVIDER,
  RESET_CASINO_PROVIDER_GAMES,
  GET_USER_FAV_GAMES_IDS,
} from "../actions/types";

const INITIAL_STATE = {
  allGames: [],
  games: [],
  gameGroups: [],
  searchedGames: [],
  favGames: {
    ids: [],
    games: [],
  },
  bonuses: {},
  slider: {
    newGames: [],
    popularGames: [],
    liveGames: [],
    dropAndWinGames: [],
    winOftenGames: [],
    winAlotGames: [],
    megawayGames: [],
    jackpotGames: [],
    hotGames: [],
    classicGames: [],
    bonusBuyGames: [],
    tournamentGames: []
  },
  gamesByFeature: {
    list: [],
    isLoading: false,
    page: 1,
    pageCount: 10,
    pageSize: 20,
    groupId: null,
  },
  gamesByProvider: {
    list: [],
    isLoading: false,
    page: 1,
    pageCount: 10,
    pageSize: 20,
  },
  providers: [],
  isGamesLoading: false,
  isFreeSpinsLoading: false,
  freeSpinsLoaded: false,
};

let stateCopy, currentList;
const fn = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    /**
     * Save casino games
     */

    case SAVE_CASINO_GAMES:
      stateCopy = { ...state };
      let games = action.payload;

      let allGamesList = [];

      for (let menuValue in games) {
        const elements = games[menuValue];
        for (let index = 0; index < elements?.length; index++) {
          const element = elements[index];
          allGamesList.push(element);
        }
      }

      return {
        ...stateCopy,
        allGames: allGamesList,
        games: { ...action.payload, All: allGamesList },
      };
    // GET custmer free spins list
    case GET_CUSTOMER_FREE_SPINS:
      stateCopy = { ...state };
      let freeSpins = action.payload;
      let transactions = freeSpins?.transactions;
      const freeSpinGames = [];

      transactions?.forEach((transaction) => {
        transaction?.games?.forEach((game) => {
          freeSpinGames.push(game);
        });
      });

      return {
        ...stateCopy,
        allGames: freeSpinGames,
        games: freeSpinGames,
        bonuses: freeSpins,
      };
    /**
     * Filter casino games
     */
    case FILTER_CASINO_GAMES:
      stateCopy = { ...state };
      let filteredGames = [];
      if (action.payload === "all") {
        filteredGames = stateCopy.allGames;
      } else {
        filteredGames = stateCopy.allGames.filter((game) => game.provider === parseInt(action.payload));
      }
      return { ...stateCopy, games: filteredGames, publisher: action.payload };

    case GET_CASINO_GAME_GROUP_NAMES:
      return { ...state, gameGroups: action.payload.groups };

    case SEARCH_CASINO_GAMES:
      const loadedGames = { ...action.payload };
      let gamesList = loadedGames["All"] || [];
      gamesList = gamesList.reduce((accumulator, current) => {
        if (!accumulator.find((item) => item.gameId === current.gameId||item.translation===current.translation)) {
          accumulator.push(current);
        }
        return accumulator;
      }, []);
      return { ...state, searchedGames: gamesList };

    case CLEAR_CASINO_SEARCH_RESULTS:
      return { ...state, searchedGames: action.payload };

    case GET_CASINO_GAMES_BY_GROUP_NAME:
      stateCopy = { ...state };
      let groupGames = action.payload?.games?.games ? action.payload?.games?.games : [];
      return {
        ...stateCopy,
        games: groupGames,
        allGames: groupGames,
        casinoFilter: action.payload.filter,
        isGamesLoading: false,
      };

    case CASINO_GAMES_LIST_LOADING:
      return { ...state, isGamesLoading: action.payload };
    case CASINO_FREE_SPINS_LOADING:
      return { ...state, isFreeSpinsLoading: action.payload };

    // save casino vendors list
    case SAVE_VENDORS:
      const allProviders = action.payload && typeof action.payload === "object" ? action.payload[0] : [];
      const orderList = [3, 116, 83];
      let mainProviders = [];
      const subProviders = [];
      allProviders.forEach((provider) => {
        const scheme = {
          supplier_id: provider.provider_id,
          supplier_name: provider.provider_name,
          order: 100,
        };
        if (orderList.includes(scheme.supplier_id)) {
          scheme.order = orderList.findIndex((itm) => itm === scheme.supplier_id);
        }
        mainProviders.push(scheme);
        provider.suppliers.forEach((supplier) => {
          subProviders.push({ ...supplier, order: 100 });
        });
      });

      mainProviders = mainProviders.concat(subProviders);
      mainProviders = mainProviders.sort((a, b) => a.order - b.order);

      return { ...state, providers: mainProviders };

    // get casino slider games by group type
    case GET_CASINO_SLIDER_GAMES:
      stateCopy = { ...state };
      switch (action.groupType) {
        case "new":
          stateCopy.slider.newGames = action.payload;
          return stateCopy;
        case "livecasino":
          stateCopy.slider.liveGames = action.payload;
          return stateCopy;
        case "popular":
          stateCopy.slider.popularGames = action.payload;
          return stateCopy;
        case "dropsandwins":
          stateCopy.slider.dropAndWinGames = action.payload;
          return stateCopy;
        case "winoften":
          stateCopy.slider.winOftenGames = action.payload;
          return stateCopy;
        case "winalot":
          stateCopy.slider.winAlotGames = action.payload;
          return stateCopy;
        case "Megaways":
          stateCopy.slider.megawayGames = action.payload;
          return stateCopy;
        case "jackpots":
          stateCopy.slider.jackpotGames = action.payload;
          return stateCopy;
        case "classics":
          stateCopy.slider.classicGames = action.payload;
          return stateCopy;
        case "bonusbuy":
          stateCopy.slider.bonusBuyGames = action.payload;
          return stateCopy;
        case "hotgames":
          stateCopy.slider.hotGames = action.payload;
          return stateCopy;
        case "tournament":
          stateCopy.slider.tournamentGames = action.payload;
          return stateCopy;
        default:
          return stateCopy;
      }

    //get casino games by feature filter
    case GET_CASINO_GAMES_BY_FEATURE:
      stateCopy = { ...state };
      currentList = stateCopy.gamesByFeature.list;
      stateCopy.gamesByFeature.list = action.payload.data?.games  ? [...currentList, ...action.payload.data.games] : currentList;
      stateCopy.gamesByFeature.page = action.payload.data?.pagination?.page ? action.payload.data.pagination.page + 1 : 1;
      stateCopy.gamesByFeature.pageCount = action.payload.data?.pagination?.pageCount ? action.payload.data.pagination.pageCount : 10;
      stateCopy.gamesByFeature.pageSize = action.payload.data?.pagination?.size ? action.payload.data.pagination.size : 20;
      stateCopy.gamesByFeature.groupId = action.payload.groupId;
      return stateCopy;

    // reset casino games by feature params. This is needed to revoke the initial state when filter type changes
    case RESET_FEATURE_GAMES:
      stateCopy = { ...state };
      stateCopy.gamesByFeature.list = [];
      stateCopy.gamesByFeature.page = 1;
      stateCopy.gamesByFeature.pageCount = 10;
      stateCopy.gamesByFeature.pageSize = 20;
      if(action.payload){
        stateCopy.gamesByFeature.groupId = action.payload;
      }
      return stateCopy;
    // get casino games by provider ids
    case GET_CASINO_GAMES_BY_PROVIDER:
      stateCopy = { ...state };
      currentList = stateCopy.gamesByProvider.list;
      stateCopy.gamesByProvider.list = action.payload?.games ? [...currentList, ...action.payload.games] : currentList;
      stateCopy.gamesByProvider.page = action.payload?.pagination?.page ? action.payload.pagination.page + 1 : 1;
      stateCopy.gamesByProvider.pageCount = action.payload?.pagination?.pageCount ? action.payload.pagination.pageCount : 10;
      stateCopy.gamesByProvider.pageSize = action.payload?.pagination?.size ? action.payload.pagination.size : 20;
      return stateCopy;

    // reset casino games by provider params. This is needed to revoke the initial state when filter type changes
    case RESET_CASINO_PROVIDER_GAMES:
      stateCopy = { ...state };
      stateCopy.gamesByProvider.list = [];
      stateCopy.gamesByProvider.page = 1;
      stateCopy.gamesByProvider.pageCount = 10;
      stateCopy.gamesByProvider.pageSize = 20;
      return stateCopy;

    // get user favorite casino games ids
    case GET_USER_FAV_GAMES_IDS:
      stateCopy = { ...state };
      let favIdsPayload = action.payload ? action.payload : [];
      favIdsPayload = favIdsPayload.map((favObject) => favObject.gameId);
      stateCopy.favGames.ids = favIdsPayload;
      return stateCopy;
    // get user favorite casino games
    case GET_USER_FAVORITE_CASINO_GAMES:
      stateCopy = { ...state };
      let favGames = action.payload ? Object.values(action.payload) : [];
      favGames = favGames.reduce((accumulator, current) => {
        if (!accumulator.find((item) => item.gameId === current.gameId||item.translation===current.translation)) {
          accumulator.push(current);
        }
        return accumulator;
      }, []);
      stateCopy.favGames.games = favGames;
      return stateCopy;
    default:
      return state;
  }
};

export default fn;
