import  { useEffect } from "react";
import { useDispatch } from "react-redux";
import useWindowSize from "../helpers/useWindowSize";
import { setMarketsLayout } from "../actions/index";

const MarketLayoutLoader = () => {
  const dispatch = useDispatch();
  const winWidth = useWindowSize();

  useEffect(() => {
    if (winWidth > 600) {
      let marketsLayout = "horizontal";
      dispatch(setMarketsLayout(marketsLayout, "MarketLayoutLoader"));
    } else {
      let marketsLayout = "vertical";
      dispatch(setMarketsLayout(marketsLayout, "MarketLayoutLoader"));
    }
  }, [winWidth]);
  return null;
};

export default MarketLayoutLoader;
