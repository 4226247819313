import "./style.css";
import { log } from "../../../config/log";
import React from "react";
import { connect } from "react-redux";
import FeaturedOutrightCard from "../components/outright-card";
import { withStyles } from "@mui/styles";
import Slider from "react-slick";
import FeaturedGameCard from "../components/game-card-v1";
import { getFeaturedGames, getFeaturedOutrights } from "../../../actions";
import withRouter from "../../../helpers/withRouter";
import FeaturedSliptCard from "../components/slip-card";
import PrecannedCard from "../components/precanned-card";

const settings = {
  dots: false,
  infinite: true,
  speed: 4000,
  slidesToShow: 3,
  slidesToScroll: 3,
  arrows: true,
  autoplay: true,
  pauseOnHover: true,
  autoplaySpeed: 15000,
  // swipeToSlide: true,
  // fade: true,
  // vertical: true,
  lazyLoad: true,
  rtl: false,
};

const styles = (theme) => ({
  root: {
    "@media (hover:hover)": {
      backgroundColor: "var(--color-main)",
    },
    // "@media (hover:none)": {
    //   height: "16em",
    // },
    "& .MuiTypography-root": {
      fontSize: "1.1em !important",
    },
    "& p": {
      margin: "1em 0 1em",
    },
    "& li": {
      margin: "0.3em 0",
    },
    "& h3": {
      textAlign: "center",
      fontSize: "1.1em !important",
    },
    "& .MuiDivider-root": {
      backgroundColor: "var(--color-contrast)",
    },
    padding: "0 0 1.5em",
    // margin: "0 0 0.3em",
    flexGrow: 1,
    width: "97%",
    backgroundColor: "var(--color-main)",
  },
});

class FeaturedGameAndOutrightCombiMouse extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { games, outrights } = this.props;
    if (games.length < 1) {
      this.props.getFeaturedGames(this.constructor.name);
    }
    if (outrights.length < 1) {
      this.props.getFeaturedOutrights(this.constructor.name);
    }
  }

  goToRiskitonVeto = () => {
    this.props.navigate(`/${this.props.lang}/promotions`);
  };
  goToBetbuilder = () => {
    this.props.navigate(`/${this.props.lang}?dialogue=betBuilder&selectedBetBuilderSportCode=FB`);
  };
  goToKaeteiskierrosta = () => {
    this.props.navigate(`/${this.props.lang}/info/kaeteiskierrosta`);
  };
  goToAF = () => {
    this.props.navigate(`/${this.props.lang}/leagues/all/AF/31/46791617`);
  };

  render() {
    log(this.constructor.name, "render");
    const { classes, games, outrights, allSlips, isBannerLoading, precannedGames } = this.props;
    if (isBannerLoading) return <div className="text-align-center font-xs">Loading...</div>;
    return (
      <div className={classes.root}>
        <div className="featured-matches-container-mouse">
          <Slider {...settings}>
            {precannedGames?.map((precannedGame) => {
              const betsToRender = precannedGame?.bets ? precannedGame?.bets?.filter((bt) => bt.sideBet === "0") : [];
              return betsToRender.map((bet) => <PrecannedCard key={bet.betslipUid} game={precannedGame} bet={bet} />);
            })}
            {allSlips?.map((slipGroup) => {
              return slipGroup.map((slipData, indx) => <FeaturedSliptCard key={indx} data={slipData} order={indx + 1} />);
            })}
            {games?.map((game) => {
              return game?.odds?.map((gameOdd, index) => {
                const gameCopy = { ...game };
                gameCopy["odds"] = [gameOdd];
                return <FeaturedGameCard key={index} game={gameCopy} />;
              });
            })}
            {outrights?.map((game, indx) => (
              <FeaturedOutrightCard key={indx} game={game} />
            ))}
          </Slider>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  let gamesList = [];
  const gamesGroups = state.banner.games && Object.values(state.banner.games) ? Object.values(state.banner.games) : [];
  gamesGroups?.forEach((grItem) => {
    const grItemValues = Object.values(grItem);
    grItemValues?.forEach((item) => {
      gamesList.push(item);
    });
  });

  let outrightsList = [];
  const outrightsGroups = state.banner.outrights && Object.values(state.banner.outrights) ? Object.values(state.banner.outrights) : [];
  outrightsGroups?.forEach((grItem) => {
    const grItemValues = Object.values(grItem);
    grItemValues?.forEach((item) => {
      outrightsList.push(item);
    });
  });

  const slip = state.banner.slip;
  const slipKeysArray = Object.keys(slip);
  const allSlips = [];

  if (slip) {
    slipKeysArray?.forEach((arrKey) => {
      Object.values(slip[arrKey])?.forEach((slipData) => {
        if ((slipData?.games && Object.keys(slipData?.games)?.length) || (slipData?.outrights && Object.keys(slipData?.outrights)?.length)) {
          allSlips.push({ ...slipData, slipKey: arrKey });
        }
      });
    });
  }
  const banner = state.banner;
  const isLoading = banner.isGamesLoading || banner.isOutrightsLoading || banner.isSlipsLoading;

  const slipsGroup = {};

  allSlips?.forEach((slp) => {
    if (!slipsGroup[slp.slipKey]) {
      slipsGroup[slp.slipKey] = allSlips.filter((itm) => itm.slipKey === slp.slipKey);
    }
  });

  const precannedGames = [];
  const precannedList = Object.values(state.banner.precanned);

  precannedList?.forEach((prGroup) => {
    Object.values(prGroup)?.forEach((prGame) => {
      precannedGames.push(prGame);
    });
  });

  return {
    games: gamesList,
    outrights: outrightsList,
    allSlips: Object.values(slipsGroup),
    lang: state.i18n.locale,
    isMobile: state.settings.isMobile,
    host: state.settings.host,
    locale: state.i18n.locale,
    auth: state.user.auth,
    siteTheme: state.settings.theme,
    isBannerLoading: isLoading,
    precannedGames: precannedGames,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    getFeaturedGames,
    getFeaturedOutrights,
  })(withStyles(styles)(FeaturedGameAndOutrightCombiMouse))
);
