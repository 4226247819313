/**
 * Show all outright games list
 * For Odds and Columns view modes
 */

import { log } from "../../config/log";
import React from "react";
import { connect } from "react-redux";
import OutrightGameBox from "./OutrightGameBox";
import withStyles from "@mui/styles/withStyles";

import "./GamesList.css";
import { AppBar, Tab, Tabs } from "@mui/material";
import sortGamesByTime from "../../helpers/gameChronologicalsort";

const styles = (theme) => ({
  root: {},
  raceTabs: {
    "& .MuiTab-root": {
      minHeight: "3em !important",
      padding: "0.35em 0 !important",
      color: "var(--grey)",
      "@media (hover:hover)": {
        minHeight: "2.3em !important",
        padding: "0.44em 0 !important",
        fontSize: "1.2em !important",
      },
    },
    "& .MuiTabs-indicator": {
      display: "none",
    },
    "& .MuiAppBar-colorPrimary": {
      backgroundColor: "var(--bg-app)",
    },
    backgroundColor: "var(--color-main)",
  },
  raceSelection: {
    "&.MuiButtonBase-root.MuiTab-root": {
      background:
        "linear-gradient(0deg, rgba(29,88,154,1) 0%, rgba(61,163,253,1) 50%, rgba(29,88,154,1) 100%)",
      borderRadius: "0.4em",
      height: "1em",
      fontSize: "0.9em !important",
      margin: "1em 0.8em",
      minWidth: "6em",
      color: "var(--btn-text) !important",
    },
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {children}
    </div>
  );
}

function a11yProps(index, key) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
    disablehorizontalview: `${key === "favorites"}`,
  };
}

class OutrightGamesList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedGame: this.props.defaultGameId,
    };
  }

  UNSAFE_componentWillMount() {
    this.domReferences = {};
  }

  componentWillUnmount() {
    this.domReferences = {};
  }

  saveRefs(key, ref) {
    this.domReferences[key] = ref;
  }

  menuChange(event, newValue) {
    this.setState({ selectedGame: newValue });
  }

  renderTrGamesList() {
    const { leagues, leagueId, sportCode, searchQuery, classes } = this.props;
    const games = Object.values(leagues[leagueId]["outrightGames"]);
    if (games?.length === 0) return null;
    return (
      <div className={classes.raceTabs}>
        <AppBar elevation={0} position="static" color="transparent">
          <Tabs
            value={this.state.selectedGame}
            variant="scrollable"
            indicatorColor="primary"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
            onChange={this.menuChange.bind(this)}
          >
            {sortGamesByTime(games).map((game, index) => {
              let displayLabel = game?.game_title?.replace(
                / *\([^)]*\) */g,
                ""
              );
              return (
                <Tab
                  key={game.gameid}
                  value={game.gameid}
                  label={displayLabel}
                  className={classes.raceSelection}
                  {...a11yProps(index, game.gameid)}
                />
              );
            })}
          </Tabs>
        </AppBar>
        {sortGamesByTime(games).map((game, key) => {
          return (
            <TabPanel
              key={key}
              value={game.gameid}
              index={this.state.selectedGame}
            >
              <OutrightGameBox
                searchQuery={searchQuery}
                game={game}
                sportCode={sportCode}
              />
            </TabPanel>
          );
        })}
      </div>
    );
  }

  renderBrGamesList = (games, lable) => {
    const { sportCode, searchQuery } = this.props;
    if (games?.length === 0) return null;
    return (
      <div>
        {sortGamesByTime(games)
          .filter((gameData) => gameData.isActive === 1)
          .filter(
            (eachGame) =>
              eachGame.odds.filter((oddItem) => oddItem.iA == 1)?.length
          )
          .map((game) => {
            return (
              <OutrightGameBox
                searchQuery={searchQuery}
                key={game.gameid}
                game={game}
                lable={lable}
                sportCode={sportCode}
                odds={game.odd}
              />
            );
          })}
      </div>
    );
  };

  render() {
    log(this.constructor.name, "render");
    const { leagues, leagueId, sportCode } = this.props;
    const games = Object.values(leagues[leagueId]["outrightGames"]);
    // console.log(games);
    const lable = leagues[leagueId]["label"];
    if (games?.length === 0) return null;
    return (
      <div>
        {sportCode === "TR"
          ? this.renderTrGamesList()
          : this.renderBrGamesList(games, lable)}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let gameId =
    ownProps.leagueId &&
    state.selected.leagues[ownProps.leagueId]?.outrightGames &&
    Object.values(
      state.selected.leagues[ownProps.leagueId]?.outrightGames
    )[0] &&
    Object.values(
      state.selected.leagues[ownProps.leagueId]["outrightGames"]
    )[0]["gameid"]
      ? Object.values(
          state.selected.leagues[ownProps.leagueId]["outrightGames"]
        )[0].gameid
      : null;
  return {
    leagueId: ownProps.leagueId,
    leagues: state.selected.leagues ? state.selected.leagues : {},
    defaultGameId: gameId,
    selected: state.selected,
    leagueFilter: state.leagues._leagueFilter,
    currentMarketSetValue: ownProps.currentMarketSetValue,
    currentMarketSetKey: ownProps.currentMarketSetKey,
    marketsLayout: state.settings.marketsLayout,
    gameStaticId: ownProps.gameStaticId,
    sportCode: ownProps.sportCode,
    isInsportsBetting: ownProps.isInsportsBetting,
    searchQuery: ownProps?.searchQuery,
  };
};

export default connect(
  mapStateToProps,
  {}
)(withStyles(styles)(OutrightGamesList));
