/**
 * Promotions Page - Fin version
 */

import { log } from "../../../config/log";
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Grid, Paper, Typography } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";

const styles = (theme) => ({
  root: {
    padding: "1.8em",
    fontSize: "var(--content)",
  },
  listItems: {
    color: "var(--color-contrast)",
    fontSize: "1.2em",
    fontWeight: "bold",
  },
  promo: {
    height: "auto",
    width: "100%",
    borderRadius: "0.7em 0.7em 0 0",
  },
  promoGrid: {
    "& .MuiGrid-item": {
      padding: "0 0.5em 0.5em 0.5em !important",
      "@media (hover:hover)": {
        padding: "0 0.5em !important",
      },
    },
  },
});

class Promotions extends React.PureComponent {
  goTop = () => { };

  render() {
    log(this.constructor.name, "render");
    const { host, classes, locale, auth, url, siteTheme } = this.props;

    if (!host) return null;

    return (
      <Container style={{ backgroundColor: "var(--color-main)", height: "100%" }} maxWidth={false}>
        <Grid className={classes.promoGrid} container spacing={2} style={{ display: "flex", alignItems: "stretch" }}>

        {/* <Grid item xs={12} sm={4} style={{ display: "flex", alignItems: "stretch" }}>
            <Paper style={{ margin: "0.5em", backgroundColor: "var(--bg-promo)" }}>
              <img className={classes.promo} src={`https://storage.googleapis.com/nubet-prod-content-news/banner_casino_sm_gemix.jpg`} alt="banner-casino-promo" />
              <Typography component="span">
                <div id="legalcontent" className={classes.root}>
                  <h1>Oletko sinä Gemixin Keisari? Jaossa 20 euron jättikierroksia!</h1>
                  <br></br>
                  Nyt selvitetään Gemixin Keisari!<br></br><br></br>
                  Tavoitteenasi on saada mahdollisimman suuri voittokerroin alkuperäisessä Gemixissä, Gemix 2:ssa tai uudessa Gemix 100:ssa.<br></br><br></br>
                  Voittaja kuittaa 150 kappaletta 20 euron jättimoukkuja!
                  <br></br>
                  <div style={{ textAlignLast: "center" }}>
                    <Link to={`/${locale}/casino/featured-games?filter=tournament`}>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{
                          margin: "2em 0",
                          color: "var(--btn-text)",
                          padding: "1em 1em",
                          fontSize: "1em",
                          background: "linear-gradient(0deg, rgba(29,88,154,1) 0%, rgba(61,163,253,1) 50%, rgba(29,88,154,1) 100%)",
                        }}
                      >
                        Pelaa nyt
                      </Button>
                    </Link>
                  </div>
                </div>
              </Typography>
            </Paper>
          </Grid> */}

          <Grid item xs={12} sm={4}>
            <Paper style={{ margin: "0.5em", backgroundColor: "var(--bg-promo)" }}>
              <img className={classes.promo} src={`https://storage.googleapis.com/nubet-prod-content-news/nbt_banner_cta_25_gif_478_230_fi_${siteTheme}.gif`} alt="banner-casino-promo" />
              <Typography component="span">
                <div id="legalcontent" className={classes.root}>
                  <h1>Aloita vedonlyönti Nubetilla ilman pienintäkään riskiä tappiosta!</h1>
                  <br></br>
                  Ensimmäinen vetosi Nubetilla olkoon voittoisa - ja vaikka ei olisikaan, niin ei sillä väliä. Jos ensimmäinen vetosi kuitenkin menee myttyyn, hyvitämme sen saman tien tilillesi ilman kyselyitä tai
                  pikkupränttiä.<br></br>
                  <br></br>
                  <h3>Näin lyöt vetoa ilman riskiä</h3>
                  <span className={classes.listItems}>1. </span>Talleta ja avaat samalla Nubet-pelitilin.<br></br>
                  <br></br>
                  <span className={classes.listItems}>2. </span>Lyö haluamasi veto tasan 25€ panoksella. Hyvitettävän vedon tulee ratketa 48 tunnin sisällä tilin avaamisesta (ei Vetosekoitin - tai pitkäaikaisvetoja).
                  <br></br>
                  <br></br>
                  <span className={classes.listItems}>3. </span> Jos vetosi menee väärin hyvitämme pelitilillesi 25 euroa (jos vetosi voittaa, pidät luonnollisesti voittosi normaalisti). Hyvitys maksetaan vedon
                  ratkeamista seuraavana päivänä kello 12 mennessä.<br></br>
                  <div style={{ textAlignLast: "center" }}>
                    {!auth.isSignedIn ? (
                      <Link to={`/${locale}/register-switch`}>
                        <Button
                          variant="contained"
                          color="primary"
                          style={{
                            margin: "2em 0",
                            color: "var(--btn-text)",
                            padding: "1em 1em",
                            fontSize: "1em",
                            background: "linear-gradient(0deg, rgba(29,88,154,1) 0%, rgba(61,163,253,1) 50%, rgba(29,88,154,1) 100%)",
                          }}
                        >
                          TALLETA JA LYÖ RISKITÖN VETO
                        </Button>
                      </Link>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        disabled
                        style={{
                          margin: "2em 0",
                          color: "var(--btn-text)",
                          padding: "1em 1em",
                          fontSize: "1em",
                        }}
                      >
                        UUSILLE ASIAKKAILLE
                      </Button>
                    )}
                  </div>
                </div>
              </Typography>
            </Paper>
          </Grid>

          {/* <Grid item xs={12} sm={4} style={{ display: 'flex', alignItems: 'stretch' }}>
            <Paper style={{ margin: "0.5em", backgroundColor: "var(--bg-promo)" }}>
              <img className={classes.promo} src={`https://storage.googleapis.com/nubet-prod-content-news/nbt_banner_euro2024_promo_welcome_${locale}_${siteTheme}.jpg`} alt="banner-euro-promo" />
              <Typography component="span">
                <div id="legalcontent" className={classes.root}>

                  <h1>
                    Euro 2024 tervetuliaisbonus<br></br>
                    (VAIN UUSILLE ASIAKKAILLE)
                  </h1>
                  <br></br>
                  Pelikassa kerralla kuntoon EM-kisoja varten!<br></br><br></br>
                  Tee ENSIMMÄINEN talletuksesi ja saat 100% bonuksen aina 100 euroon asti.<br></br><br></br>
                  Lisäksi saat myös 100 ilmaiskierrosta Book of Dead -peliin.<br></br><br></br>
                  <h3>Seuraa näitä ohjeita lunastaaksesi tervetuliaisbonuksen:</h3>
                  <span className={classes.listItems}>1. </span>Tee talletus ja rekisteröidy asiakkaaksi prosessin aikana<br></br>
                  <br></br>
                  <span className={classes.listItems}>2. </span>Bonus ja ilmaiskierrokset lisätään pelitilillesi.<br></br>
                  <br></br>
                  <span className={classes.listItems}>3. </span>Bonusrahaan ja ilmaiskierrosten voittoihin sovelletaan 4x kierrätysvaatimusta.<br></br>
                  <br></br>
                  <span className={classes.listItems}>4. </span>Talletusbonusta ei voi yhdistää muiden bonuksien kanssa<br></br>
                  <br></br>
                  <span className={classes.listItems}>5. </span>Muilta osin yleiset Bonus käyttöehdot voimassa<br></br>
                  <div style={{ textAlignLast: "center" }}>
                    {!auth.isSignedIn ? (
                      <Link to={`/${locale}/register-switch`}>
                        <Button
                          variant="contained"
                          color="primary"
                          style={{
                            margin: "2em 0",
                            color: "var(--btn-text)",
                            padding: "1em 1em",
                            fontSize: "1em",
                            background: "linear-gradient(0deg, rgba(29,88,154,1) 0%, rgba(61,163,253,1) 50%, rgba(29,88,154,1) 100%)"
                          }}
                        >
                          REKISTERÖIDY JA HANKI BONUS
                        </Button>
                      </Link>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        disabled
                        style={{
                          margin: "2em 0",
                          color: "var(--btn-text)",
                          padding: "1em 1em",
                          fontSize: "1em",
                        }}
                      >
                        UUSILLE ASIAKKAILLE
                      </Button>
                    )}
                  </div>
                </div>
              </Typography>
            </Paper>
          </Grid> */}

          <Grid item xs={12} sm={4} style={{ display: "flex", alignItems: "stretch" }}>
            <Paper style={{ margin: "0.5em", backgroundColor: "var(--bg-promo)" }}>
              <img className={classes.promo} src={`https://storage.googleapis.com/nubet-prod-content-news/nbt_banner_cta_bookofpower_gif_478_230_1_${siteTheme}.gif`} alt="banner-casino-promo" />
              <Typography component="span">
                <div id="legalcontent" className={classes.root}>
                  <h1>Aloita pelit Nubet-kasinolla 100:lla ilmaiskierroksella!</h1>
                  <br></br>
                  Kun teet ENSIMMÄISEN talletuksesi Nubetille saat välittömästi 100 ilmaiskierrosta Book of Power -peliin.<br></br>
                  <br></br>
                  Pelissä on tarjolla huima 15.000x -maksimivoitto!
                  <br></br>
                  <br></br>
                  <h3>Näin saat ilmaiskierrokset</h3>
                  <span className={classes.listItems}>1. </span>Talleta ja avaat samalla Nubet-pelitilin.<br></br>
                  <br></br>
                  <span className={classes.listItems}>2. </span>Avaa Book of Power -peli ja ilmaiskierrokset odottavat pelissä.<br></br>
                  <br></br>
                  <span className={classes.listItems}>3. </span>Ilmaiskierrosten voittoja koskee 1x kierrätysvaatimus.<br></br>
                  <br></br>
                  <div style={{ textAlignLast: "center" }}>
                    {!auth.isSignedIn ? (
                      <Link to={`/${locale}/register-switch`}>
                        <Button
                          variant="contained"
                          color="primary"
                          style={{
                            margin: "2em 0",
                            color: "var(--btn-text)",
                            padding: "1em 1em",
                            fontSize: "1em",
                            background: "linear-gradient(0deg, rgba(29,88,154,1) 0%, rgba(61,163,253,1) 50%, rgba(29,88,154,1) 100%)",
                          }}
                        >
                          TALLETA JA PELAA ILMAISKIERROKSET
                        </Button>
                      </Link>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        disabled
                        style={{
                          margin: "2em 0",
                          color: "var(--btn-text)",
                          padding: "1em 1em",
                          fontSize: "1em",
                        }}
                      >
                        UUSILLE ASIAKKAILLE
                      </Button>
                    )}
                  </div>
                </div>
              </Typography>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={4} style={{ display: "flex", alignItems: "stretch" }}>
            <Paper style={{ margin: "0.5em", backgroundColor: "var(--bg-promo)" }}>
              <img className={classes.promo} src={`https://storage.googleapis.com/nubet-prod-content-news/nbt_banner_comboboost_${locale}_${siteTheme}.gif`} alt="banner-casino-promo" />
              <Typography component="span">
                <div id="legalcontent" className={classes.root}>
                  <h1>Turbo-rekka</h1>
                  Miksi tyytyisit pienempiin kertoimiin yhdistelmissä? Turbo-rekan avulla rivisi saa merkittävästi lisää vääntöä. Mitä pidempi rekka, sitä enemmän bonusta. Bonus lisätään netto-voittoon ao. taulukon
                  mukaisesti.<br></br>
                  <br></br>
                  <Grid container style={{ fontSize: "0.8em", textAlignLast: "center" }}>
                    <Grid item xs={3} sm={3} style={{ color: "var(--color-contrast)", fontWeight: "bold" }}>
                      Kohteita
                    </Grid>
                    <Grid item xs={3} sm={3} style={{ color: "var(--color-contrast)", fontWeight: "bold" }}>
                      Bonus
                    </Grid>
                    <Grid item xs={3} sm={3} style={{ color: "var(--color-contrast)", fontWeight: "bold" }}>
                      Kohteita
                    </Grid>
                    <Grid item xs={3} sm={3} style={{ color: "var(--color-contrast)", fontWeight: "bold" }}>
                      Bonus
                    </Grid>
                    <Grid item xs={3} sm={3}>
                      3
                    </Grid>
                    <Grid item xs={3} sm={3}>
                      5%
                    </Grid>
                    <Grid item xs={3} sm={3}>
                      10
                    </Grid>
                    <Grid item xs={3} sm={3}>
                      27%
                    </Grid>
                    <Grid item xs={3} sm={3}>
                      4
                    </Grid>
                    <Grid item xs={3} sm={3}>
                      7%
                    </Grid>
                    <Grid item xs={3} sm={3}>
                      11
                    </Grid>
                    <Grid item xs={3} sm={3}>
                      30%
                    </Grid>
                    <Grid item xs={3} sm={3}>
                      5
                    </Grid>
                    <Grid item xs={3} sm={3}>
                      10%
                    </Grid>
                    <Grid item xs={3} sm={3}>
                      12
                    </Grid>
                    <Grid item xs={3} sm={3}>
                      35%
                    </Grid>
                    <Grid item xs={3} sm={3}>
                      6
                    </Grid>
                    <Grid item xs={3} sm={3}>
                      12%
                    </Grid>
                    <Grid item xs={3} sm={3}>
                      13
                    </Grid>
                    <Grid item xs={3} sm={3}>
                      40%
                    </Grid>
                    <Grid item xs={3} sm={3}>
                      7
                    </Grid>
                    <Grid item xs={3} sm={3}>
                      15%
                    </Grid>
                    <Grid item xs={3} sm={3}>
                      14
                    </Grid>
                    <Grid item xs={3} sm={3}>
                      45%
                    </Grid>
                    <Grid item xs={3} sm={3}>
                      8
                    </Grid>
                    <Grid item xs={3} sm={3}>
                      20%
                    </Grid>
                    <Grid item xs={3} sm={3}>
                      15
                    </Grid>
                    <Grid item xs={3} sm={3}>
                      50%
                    </Grid>
                    <Grid item xs={3} sm={3}>
                      9
                    </Grid>
                    <Grid item xs={3} sm={3}>
                      25%
                    </Grid>
                    <Grid item xs={3} sm={3}></Grid>
                    <Grid item xs={3} sm={3}></Grid>
                  </Grid>
                  <h3>Kampanjasäännöt</h3>
                  <span className={classes.listItems}>1. </span>Kampanja on voimassa toistaiseksi<br></br>
                  <span className={classes.listItems}>2. </span>Voimassa vain 1X2 ja voittaja (moneyline) kohteille<br></br>
                  <span className={classes.listItems}>3. </span>Tulosvaihtoehdon kerroin vähintään 1.2<br></br>
                  <span className={classes.listItems}>4. </span>Bonusrahaa ei voi käyttää tarjoukseen<br></br>
                  <span className={classes.listItems}>5. </span>Jos yksi tai useampi yhdistelmän kohteista perutaan (void), määritellään bonus uudelleen jäljellä olevien kohteiden perusteella<br></br>
                  <span className={classes.listItems}>6. </span>Jos veto mitätöidään ei myöskään bonusta makseta
                </div>
              </Typography>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={4} style={{ display: "flex", alignItems: "stretch" }}>
            <Paper style={{ margin: "0.5em", backgroundColor: "var(--bg-promo)" }}>
              <img className={classes.promo} src={`https://storage.googleapis.com/nubet-prod-content-news/nbt_banner_comboboost_trotting_anim_${locale}_${siteTheme}.gif`} alt="banner-casino-promo" />
              <Typography component="span">
                <div id="legalcontent" className={classes.root}>
                  <h1>Ravi-rekka</h1>
                  Ravi-rekan avulla pääset luomaan yhdistelmien lisäksi järjestelmiä, joissa on korotetut voitot. Voit laatia useamman hevosen sisältävän pelin useammasta lähdöstä. Voit jopa sekoittaa Ruotsin ja Suomen raveja keskenään. Ja kuten Turbo-rekassa, mitä pidempi rekka sitä enemmän bonusta. Bonus lisätään netto-voittoon ao. taulukon mukaisesti.<br></br>
                  <br></br>
                  <Grid container style={{ fontSize: "0.8em", textAlignLast: "center" }}>
                    <Grid item xs={6} sm={6} style={{ color: "var(--color-contrast)", fontWeight: "bold" }}>
                      Kohteita
                    </Grid>
                    <Grid item xs={6} sm={6} style={{ color: "var(--color-contrast)", fontWeight: "bold" }}>
                      Bonus
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      3
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      10%
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      4
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      17%
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      5
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      25%
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      6
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      34%
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      7
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      42%
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      8
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      50%
                    </Grid>
                  </Grid>
                  <br></br>
                  <h3>Kampanjasäännöt</h3>
                  <span className={classes.listItems}>1. </span>Kampanja on voimassa toistaiseksi<br></br>
                  <span className={classes.listItems}>2. </span>Voimassa vain ravien voittaja kohteille<br></br>
                  <span className={classes.listItems}>3. </span>Tulosvaihtoehdon kerroin vähintään 1.2<br></br>
                  <span className={classes.listItems}>4. </span>Bonusrahaa ei voi käyttää tarjoukseen<br></br>
                  <span className={classes.listItems}>5. </span>Jos yksi tai useampi yhdistelmän kohteista perutaan (void), määritellään bonus uudelleen jäljellä olevien kohteiden perusteella<br></br>
                  <span className={classes.listItems}>6. </span>Jos veto mitätöidään ei myöskään bonusta makseta<br></br>
                  <br></br>
                </div>
              </Typography>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={4} style={{ display: "flex", alignItems: "stretch" }}>
            <Paper style={{ margin: "0.5em", backgroundColor: "var(--bg-promo)" }}>
              <img className={classes.promo} src={`https://storage.googleapis.com/nubet-prod-content-news/banner_casino_sm_dropswins_${siteTheme}.jpg`} alt="banner-casino-promo" />
              <Typography component="span">
                <div id="legalcontent" className={classes.root}>
                  <h1>Drops & Wins -peleissä kahden miljoonan kampanja!</h1>
                  <br></br>
                  Osallistu Pragmatic Playn erittäin suosittuun Drops & Wins -kampanjaan, jossa jaetaan joka kuukausi kaksi miljoonaa euroa.<br></br>
                  <br></br>

                  <h3>Päivittäiset tiputukset:</h3>
                  <span className={classes.listItems}>– </span>30 000 € käteistä tiputetaan joka päivä<br></br>
                  <span className={classes.listItems}>– </span>6500 käteispalkintoa päivässä<br></br>
                  <span className={classes.listItems}>– </span>Voita jopa 2 500x panoksesi<br></br>
                  <br></br>

                  <h3>Viikoittaiset turnaukset:</h3>
                  <span className={classes.listItems}>– </span>40 000 € päivittäinen palkintopotti<br></br>
                  <span className={classes.listItems}>– </span>5000 € päivittäinen pääpalkinto<br></br>
                  <span className={classes.listItems}>– </span>3500 palkintoa jaossa<br></br>
                  <span className={classes.listItems}>– </span>Joka viikko keskiviikosta keskiviikkoon<br></br>
                  <br></br>

                  <div style={{ textAlignLast: "center" }}>

                    <Link to={`/${locale}/casino/featured-games?filter=dropsandwins`}>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{
                          margin: "2em 0",
                          color: "var(--btn-text)",
                          padding: "1em 1em",
                          fontSize: "1em",
                          background: "linear-gradient(0deg, rgba(29,88,154,1) 0%, rgba(61,163,253,1) 50%, rgba(29,88,154,1) 100%)",
                        }}
                      >
                        Pelaa nyt
                      </Button>
                    </Link>
                  </div>
                </div>
              </Typography>
            </Paper>
          </Grid>


        </Grid>
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    host: state.settings.host,
    locale: state.i18n.locale,
    auth: state.user.auth,
    url: state.settings.url,
    siteTheme: state.settings.theme,
  };
};

export default connect(mapStateToProps, {})(withStyles(styles)(Promotions));
