// Trotting system layout
import { Stack } from "@mui/material";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import TrottingSystemRow from "./system-row";

const TrottingSystemLayout = ({ leagueId }) => {
  // get league filter and leagues
  const leagueFilter = useSelector((state) => state.leagues._leagueFilter);
  const leagues = useSelector(
    (state) => state.leagues[leagueFilter ? leagueFilter : "all"]["TR"]
  );
  const selectedLeague = leagues.find((leag) => leag.leagueId === leagueId);
  const outrightGames = selectedLeague?.outrightGames;
  const gamesList = outrightGames ? Object.values(outrightGames) : [];

  return (
    <Stack gap={1} px={2} py={2} overflowX="auto" maxWidth="100dvw">
      {gamesList.map((game) => (
        <TrottingSystemRow game={game} key={game.gameid} />
      ))}
    </Stack>
  );
};

TrottingSystemLayout.propTypes = {
  leagueId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
};

export default TrottingSystemLayout;
