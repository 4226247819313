/**
 * SVG component for own icon 
 */

import React from 'react';
import './IconOwn.css';

const IconMO = (props) => {

	return (
		<div style={{ display: "flex" }}>
			<svg className="icon-own" viewBox="0 0 2000 2000">
				<linearGradient gradientTransform="rotate(90)" id="icongradient">
					<stop className="icon-color-1" offset="0%" />
					<stop className="icon-color-2" offset="50%" />
					<stop className="icon-color-1" offset="100%" />
				</linearGradient>
				<g>
					<path fill="url(#icongradient)" d="M1000.2,218.5c-446.5,0-838.7,392.2-838.7,838.7v345.5c-0.2,110.1,82.4,202.8,191.8,215.2l1108.3,163.5
		c208.2,0,376.9-168.8,376.9-376.9v-347.3C1838,594.4,1463.1,219.3,1000.2,218.5z M1464.7,1691.6L364.5,1529
		c-64.4-7.1-113.1-61.5-113.2-126.2v-345.5c10.4-409.2,339.7-738.5,748.9-748.9c291.4,0.5,556,170,678.4,434.4l-874.7-69.6
		c-84.3-6.9-158.3,55.8-165.3,140.1c-0.3,4.2-0.5,8.4-0.5,12.6v166.7c0.1,134.4,80.1,255.9,203.5,309.1l783.5,338.7
		C1578.1,1673.4,1522.2,1691.2,1464.7,1691.6z M1748.7,1404.5c0,60.3-19.1,119.1-54.4,168L877.1,1219
		c-89.8-38.8-148.3-126.8-149.1-224.6V825.9c0.6-17.1,8-33.3,20.7-44.9c11.6-10.7,26.9-16.6,42.7-16.6l923.2,73.2
		c17.6,72.7,29,146.8,34.1,221.5V1404.5z"/>
					<path fill="url(#icongradient)" d="M480,1098.2c-99.2-1.2-180.7,78.2-181.9,177.4s78.2,180.7,177.4,181.9s180.7-78.2,181.9-177.4c0-0.7,0-1.5,0-2.2
		C657.5,1179.5,578.4,1099.4,480,1098.2z M480,1364.1c-49.6,1.2-90.8-38-92.1-87.6c-1.2-49.6,38-90.8,87.6-92.1
		c49.6-1.2,90.8,38,92.1,87.6c0,0.7,0,1.5,0,2.2C567.6,1323,528.7,1362.9,480,1364.1z"/>
				</g>
			</svg>
		</div>
	);
};

export default IconMO;
