/**
 * SVG component for own icon 
 */

import React from 'react';
import './IconOwn.css';

const IconBX = (props) => {

	return (
		<div style={{ display: "flex" }}>
			<svg className="icon-own" viewBox="0 0 2000 2000">
				<linearGradient gradientTransform="rotate(90)" id="icongradient">
					<stop className="icon-color-1" offset="0%" />
					<stop className="icon-color-2" offset="50%" />
					<stop className="icon-color-1" offset="100%" />
				</linearGradient>
				<g><path fill="url(#icongradient)" d="M1850.5,696.1l-577.9-542.6c-53.4-50.2-137.7-47.5-187.9,5.9l-69.2,73.7c-77.8-51.6-182.9-40.7-248.4,29.2l-79.8,85
	l-6.8,7.3l-99.2,105.6c-72.9,77.6-123.6,173.5-146.8,277.4l-65.6,294.2l-238.7,254.3c-32.9,35-31.1,90.2,3.9,123l477.5,448.3
	c35,32.9,90.2,31.1,123-3.9l238.2-253.7l104.6-23.9c107.9-24.7,205-80.5,280.8-161.2L1856.5,884
	C1906.6,830.6,1904,746.3,1850.5,696.1z M364.2,1557.2L168,1373c-15-14.1-15.8-37.8-1.7-52.9L392.7,1079l223.5,209.8
	c50.6,47.5,53.1,127.2,5.6,177.8l-79.8,85C494.5,1602.1,414.8,1604.7,364.2,1557.2z M630.7,1234.3l187.5-199.7
	c32.1-34.2,86-35.9,120.2-3.8c34.2,32.1,35.9,86,3.8,120.2l-187.5,199.7L630.7,1234.3z M698.4,1819.6c-14.1,15-37.8,15.8-52.9,1.7
	l-192.3-180.5c45.8-0.8,91.2-19.3,124.9-55.2l79.8-85c33.7-35.9,49.3-82.4,47.2-128.2l90.6,85l7.3,6.8l121.7,114.3L698.4,1819.6z
	 M1322,1380.8c-69,73.5-157.4,124.3-255.7,146.8l-99.1,22.7L837,1428l-7.3-6.8l-38.8-36.4L978.4,1185c50.8-54.2,48.1-139.6-6-190.4
	c-54.1-50.8-139.6-48.1-190.4,6l-187.5,199.7l-175.6-164.8l64-287c21.3-95.2,67.8-183.1,134.5-254.3l99.2-105.6l6.8-7.3l79.8-85
	c46.8-49.8,120.5-59.4,177.6-26.2L950,303C808.2,454,815.7,692.3,966.7,834.1l223.9,210.2c101.6,95.4,242.6,123.2,367.3,85.2
	L1322,1380.8z M1820.3,850l-134.7,143.5c-123.1,131.1-329.8,137.6-460.9,14.6l-223.9-210.2C869.6,674.8,863.1,468,986.2,337
	l134.7-143.5c31.4-33.5,84.3-35.2,117.7-3.7l577.9,542.6C1850,763.7,1851.7,816.5,1820.3,850z"/>
				</g>
			</svg>
		</div>
	);
};

export default IconBX;
