/**
 * MenuBottom component - for mobile view mode
 */

import React from "react";
import { connect } from "react-redux";
import { Localize } from "react-redux-i18n";
import withStyles from "@mui/styles/withStyles";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import TicketIcon from "@mui/icons-material/DynamicFeed";
import Badge from "@mui/material/Badge";
import { setLeagues, setOutrightLeagues } from "../actions";

import IconFB from "./events/icons/IconFB";
import IconCAS from "./events/icons/IconCAS";
import IconSLIP from "./events/icons/IconSLIP";
import withRouter from "../helpers/withRouter";
import BetBuilderModal from "./events/BetBuilderModal";
import IconBETBUILDERMENUBOTTOM from "./events/icons/IconBETBUILDERMENUBOTTOM";

const styles = (theme) => ({
  root: {
    "& .MuiBottomNavigationAction-root": {
      minWidth: "3em",
    },
    "& .MuiBottomNavigationAction-root.Mui-selected": {
      color: "var(--grey)",
    },
    "& .MuiBottomNavigationAction-root": {
      color: "var(--grey)",
    },
    flexGrow: 1,
    bottom: 0,
    width: "100%",
    position: "fixed",
    zIndex: 1299,
    background: "var(--bg-app)",
  },
  betbuilderBadge: {
    "& .MuiBadge-badge": {
      padding: "0.2em",
      color: "var(--color-promo-badge)",
      backgroundColor: "var(--yellow-1)",
      fontSize: "0.7em",
      fontWeight: "800",
      height: "1em",
    },
    "& .MuiBadge-anchorOriginTopRightCircular": {
      top: "0",
      right: "0",
    },
  },
});

class MenuBottom extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      value: "/",
    };
  }

  componentDidMount() {
    this.setState({ value: this.props.location.pathname });
  }

  handleChange = (event, newValue) => {
    this.setState({ value: newValue });
  };

  goToLeagues = () => {
    const { leagues, locale, navigate } = this.props;
    navigate(`/${locale}`);

    this.props.setLeagues(leagues, "All", "FB", locale, this.constructor.name);
  };

  goToOutrights = () => {
    const { leagues, locale, navigate } = this.props;
    navigate(`/${locale}/outrights`);

    this.props.setOutrightLeagues(leagues, "All", "CS", "", this.constructor.name);
  };

  getTrLeagues = (sportCode) => {
    const { leagues, leagueFilter, staticLeagueFilter, locale, navigate } = this.props;
    navigate(`/${locale}/horseracing`);

    this.props.setLeagues(leagues, sportCode === "TR" ? "all" : staticLeagueFilter ? staticLeagueFilter : leagueFilter ? leagueFilter : "live", sportCode, locale, this.constructor.name);
  };

  goToSlip = () => {
    const { locale, navigate } = this.props;
    navigate(`/${locale}/slip`);
  };

  goToTickets = () => {
    const { locale, navigate } = this.props;
    navigate(`/${locale}/account/tickets`);
  };

  goToCasino = () => {
    const { locale, navigate } = this.props;
    navigate(`/${locale}/casino/dashboard`);
  };

  openBetbuilder = () => {
    const { locale, navigate } = this.props;
    navigate(`/${locale}/betbuilder?selectedBetBuilderSportCode=FB`);
  };

  renderSelectedBets() {
    const { selectedBets, translate, slipTemplates, thisSlipTemplate, oddsFormat, locale } = this.props;
    this.settings = slipTemplates[thisSlipTemplate]?.settings;

    if (selectedBets > 0) {
      return (
        <BottomNavigationAction
          label={
            <span style={{ fontSize: "0.8em", whiteSpace: "nowrap" }}>
              {translate.menu.maxOdd}{" "}
              {oddsFormat === "decimal" ? (
                <Localize
                  value={this.settings.maxOdds}
                  options={{
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }}
                />
              ) : (
                <span>{this.settings.maxOdds}</span>
              )}
            </span>
          }
          value={`/${locale}/slip`}
          icon={
            <Badge badgeContent={`${selectedBets}`} color="error">
              <IconSLIP />
            </Badge>
          }
          onClick={this.goToSlip}
        />
      );
    } else {
      return <BottomNavigationAction label={translate.menu.slip} value={`/${locale}/slip`} icon={<IconSLIP />} onClick={this.goToSlip} />;
    }
  }

  renderTickets() {
    const { pendingTickets, translate, auth, locale } = this.props;

    if (auth?.isSignedIn) {
      return (
        <BottomNavigationAction
          label={translate.menu.myBets}
          value={`/${locale}/account/tickets`}
          icon={
            pendingTickets !== 0 ? (
              <Badge badgeContent={`${pendingTickets}`} color="error" overlap="rectangular">
                <TicketIcon />
              </Badge>
            ) : (
              <TicketIcon />
            )
          }
          onClick={this.goToTickets}
        />
      );
    } else {
      <BottomNavigationAction label={translate.menu.myBets} value={`/${locale}/account/tickets`} icon={<TicketIcon />} onClick={this.goToTickets} />;
    }
  }

  render() {
    const { host, translate, classes, slipTemplates, thisSlipTemplate, locale, url } = this.props;

    if (!host) return null;

    this.settings = slipTemplates[thisSlipTemplate]?.settings;

    const pathName = location.pathname ? location.pathname : "";

    if (
      pathName.indexOf(`/${locale}/casino/dashboard`) > -1 &&
      pathName != `/${locale}/casino/menu/live` &&
      pathName != `/${locale}/casino/menu/new` &&
      pathName != `/${locale}/casino/menu/popular` &&
      pathName != `/${locale}/casino/menu/my_games`
    )
      return null;

    return (
      <>
        <BetBuilderModal disableText />
        <BottomNavigation value={this.state.value} onChange={this.handleChange} className={classes.root} showLabels>
          {host.bet ? <BottomNavigationAction label={translate.menu.sportsBetting} value={`/${locale}`} icon={<IconFB state={this.state.value === "/" ? "active" : ""} />} onClick={this.goToLeagues} /> : null}
          {/* {host.bet ? (<BottomNavigationAction onClick={() => this.getTrLeagues("TR")} label={translate.menu.horseRacing_1} value="/horseracing" icon={<IconHRS state={this.state.value === '/horseracing' ? 'active' : ''} />} />) : null} */}
          {/* {host.bet ? (<BottomNavigationAction label={translate.menu.esports} value="/outrights" icon={<IconESP state={this.state.value === '/outrights' ? 'active' : ''} />} onClick={this.goToOutrights} />) : null} */}
          {host.bet ? (
            <BottomNavigationAction
              label={translate.marketSets.betbuilder}
              className={classes.betbuilderBadge}
              value={`/${locale}/betbuilder`}
              icon={<IconBETBUILDERMENUBOTTOM />}
              onClick={this.openBetbuilder}
            />
          ) : null}
          {host.gaming ? <BottomNavigationAction label={translate.menu.casino} value={`/${locale}/casino/dashboard`} icon={<IconCAS />} onClick={this.goToCasino} /> : null}
          {host.bet ? this.renderSelectedBets() : null}
          {host.bet ? this.renderTickets() : null}
        </BottomNavigation>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const selectedSlipTemplateData = Object.values(state.slips).filter(function (item) {
    return item.slipTemplate === state.selected.slipTemplate;
  });

  let pendingTickets = 0;
  if (state.tickets.pendingList) {
    pendingTickets = Object.values(state.tickets.pendingList)?.length;
  }

  return {
    auth: state.user.auth,
    host: state.settings.host,
    selectedLeague: Object.values(state.selected.leagues),
    favoriteLeagues: state.settings.favorites ? state.settings.favorites.leagues : null,
    leagueFilter: state.leagues._leagueFilter,
    sportCode: state.leagues._sportCode,
    selectedSlipTemplate: state.selected.slipTemplate,
    selectedBets: selectedSlipTemplateData[0] ? (selectedSlipTemplateData[0].settings ? (selectedSlipTemplateData[0].settings.bets ? Object.keys(selectedSlipTemplateData[0].settings.bets)?.length : 0) : 0) : 0,
    pendingTickets: pendingTickets,
    translate: state.i18n.translations[state.i18n.locale],
    thisSlipTemplate: state.selected.slipTemplate,
    slipTemplates: state.slips,
    oddsFormat: state.settings.oddsFormat,
    leagues: state.leagues,
    locale: state.i18n.locale,
    url: state.settings.url,
  };
};

export default withRouter(connect(mapStateToProps, { setLeagues, setOutrightLeagues })(withStyles(styles)(MenuBottom)));
