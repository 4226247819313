/**
 *  Component for showing game GeneralStatistics widget from widgets.sir.sportradar.com service
 */

import React from 'react';
import './theme.css';

const GeneralStatistics = (props) => {
  React.useEffect(() => {
    setTimeout(() => {
      window.SIR("addWidget", ".sr-widget-5", "match.GeneralStatistics", {
        matchId: props.gameId,
        disableMatchHeader: true,
        chartType: "bullet",
     });
    }, 500);
  }, [props.gameId]);

  return (
    <div className="widgets">
      <div><div className="sr-widget sr-widget-5"><div style={{padding: "10px 5px"}}>Loading...</div></div></div>
    </div>
  );
}

export default GeneralStatistics;