/**
 * TabSelectViewer - Currently not being used ( part of LeaguesTreeViewer )
 */

import { log } from "../../config/log";
import React from "react";
import { connect } from "react-redux";
import { Translate } from "react-redux-i18n";
import withStyles from "@mui/styles/withStyles";
import { setLeagues, getGames } from "../../actions";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Avatar from "@mui/material/Avatar";
import IconFB from "@mui/icons-material/SportsSoccer";
import IconTE from "@mui/icons-material/SportsTennis";
import IconBA from "@mui/icons-material/SportsBasketball";
import IconHB from "@mui/icons-material/SportsHandball";
import IconIH from "@mui/icons-material/SportsHockey";
import StarIcon from "@mui/icons-material/StarBorder";
import NowLiveIcon from "../../media/img/now_live_icon.svg";

import League from "./League";
import "./Leagues.css";

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const styles = (theme) => ({
  root: {
    "& .MuiSvgIcon-root": {
      fontSize: "1.7em",
    },
    "& .MuiTabs-root": {
      minHeight: "1em",
    },
    "& .MuiTab-root": {
      minHeight: "2.6em",
      minWidth: 0,
      fontSize: "1.2em",
    },
    "& .MuiTab-wrapper": {
      display: "inline-flex !important",
    },
    "& .MuiTabScrollButton-root.Mui-disabled": {
      display: "none",
    },
  },
  tabBars: {
    position: "sticky",
    top: "0",
    zIndex: 998,
    "@media (hover:hover)": {
      // borderRadius: "0.5em",
      position: "static",
    },
  },
  tabBarFavorites: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  avatarAll: {
    backgroundColor: "transparent",
    color: "var(--grey-3)",
    border: "0.17em solid var(--grey-3)",
    borderRadius: "1em",
    width: "1.6em",
    height: "1.6em",
    fontSize: "0.94em",
    lineHeight: "inherit",
    fontWeight: "500",
  },
  avatarLive: {
    backgroundColor: "transparent",
    color: "var(--color-contrast)",
    width: "2.1em",
    height: "2.1em",
    fontSize: "0.94em",
    lineHeight: "inherit",
    fontWeight: "500",
    backgroundImage: `url(${NowLiveIcon})`,
    backgroundRepeat: "no-repeat",
    justifySelf: "center",
    backgroundPosition: "center",
    backgroundSize: "contain",
  },
});

class TabSelectViewer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedLeagueIndex: 0,
      alreadyGotLeagueIds: [],
    };
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.leagues !== this.props.leagues) {
      let key1 = Object.keys(newProps.leagues ? newProps.leagues : {});
      let key2 = Object.keys(this.props.leagues ? this.props.leagues : {});

      if (key1.join(",") !== key2.join(",")) {
        this.loadSavedLeagues(newProps.leagues);
      }
    }
  }

  loadSavedLeagues(leagues) {
    const { alreadyGotLeagueIds } = this.state;

    let ids = Object.keys(leagues ? leagues : {});

    ids?.forEach((leagueId) => {
      if (alreadyGotLeagueIds.indexOf(leagueId) === -1) {
        // need to run api;
        let league = leagues[leagueId];
        let targetLeague = {};
        targetLeague[leagueId] = leagues[leagueId];

        this.props.getGames(
          this.props.leagueFilter,
          league.sportCode,
          targetLeague,
          false,
          this.constructor.name
        );
      }
    });

    this.setState({ alreadyGotLeagueIds: ids });
  }

  renderTotalGamesCounter() {
    // const { leagues, leagueFilter, sportCode, leagueGrouping } = this.props;
    const { leagues } = this.props;
    if (!leagues) return <div key="no-leagues"></div>;

    let totalAllCount = 0,
      totalLiveCount = 0,
      totalOfferedLiveCount = 0;

    leagues &&
      Object.values(leagues)?.forEach((league, key) => {
        if (league.leagueId) {
          totalAllCount += Number(league.gameCount);
          totalLiveCount += Number(league.gameLiveCount);
          totalOfferedLiveCount += league.gameOfferedLiveCount;
        }
      });

    totalAllCount = totalAllCount <= 99 ? totalAllCount : "99+";
    totalLiveCount = totalLiveCount <= 99 ? totalLiveCount : "99+";
    totalOfferedLiveCount =
      totalOfferedLiveCount <= 99 ? totalOfferedLiveCount : "99+";
    // console.log(totalOfferedLiveCount)

    return (
      <div
        className="league-selector-legend-touch"
        key="league-selector-legend-touch"
      >
        <div className="spacer-left vertical-center grey-3"></div>
        <div className="font-xs vertical-center content-right">
          <Translate value={`labels.avatar_now_live`} />
        </div>
        <div className="vertical-center spacer-left">
          <Avatar className={this.props.classes.avatarLive}>
            {totalLiveCount}
          </Avatar>
        </div>
        <div className="font-xs vertical-center content-right">
          <Translate value={`labels.avatar_all`} />
        </div>
        <div className="vertical-center spacer-left">
          <Avatar className={this.props.classes.avatarAll}>
            {totalAllCount}
          </Avatar>
        </div>
      </div>
    );
  }

  selectedLeagueChangeIndex = (event, newValue) => {
    this.setState({ selectedLeagueIndex: newValue });
  };

  renderSelectedLeagues() {
    const { leagues, classes } = this.props;

    let leagueId;
    let isSelected = false;
    let league = leagues
      ? Object.keys(leagues)[this.state.selectedLeagueIndex]
        ? Object.values(leagues)[this.state.selectedLeagueIndex]
        : null
      : null;

    if (league) {
      leagueId = league.leagueId;
      if (leagues[leagueId]) isSelected = true;
    }

    let icons = {
      FB: <IconFB style={{ fontSize: "1.15em" }} />,
      TE: <IconTE style={{ fontSize: "1.15em" }} />,
      BA: <IconBA style={{ fontSize: "1.15em" }} />,
      HB: <IconHB style={{ fontSize: "1.15em" }} />,
      IH: <IconIH style={{ fontSize: "1.15em" }} />,
      default: <StarIcon style={{ fontSize: "1.15em" }} />,
    };

    return (
      <div key={0}>
        <div className={classes.root}>
          <AppBar
            position="static"
            style={{ backgroundColor: "var(--bg-tabs-1)" }}
          >
            {leagues && (
              <Tabs
                value={this.state.selectedLeagueIndex}
                onChange={this.selectedLeagueChangeIndex.bind(this)}
                variant="scrollable"
                scrollButtons="auto"
                indicatorColor="primary"
              >
                {Object.values(leagues).map((league, key) => (
                  <Tab
                    key={key}
                    value={key}
                    label={
                      <div className="vertical-center text-wrap">
                        {icons[league.sportCode]
                          ? icons[league.sportCode]
                          : icons.default}
                        <img
                          src={`https://api.nubet.com/media/flags/${league.countryCode}.png`}
                          style={{ margin: "0 0.1em" }}
                          alt="flag"
                        />
                        <span className="text-wrap">{league.label}</span>
                      </div>
                    }
                    {...a11yProps(key)}
                  />
                ))}
              </Tabs>
            )}
          </AppBar>
          {this.renderTotalGamesCounter()}
        </div>
        <League leagueId={leagueId} />
      </div>
    );
  }

  render() {
    log(this.constructor.name, "render");
    const { leagueFilter, leagues } = this.props;
    if (!leagueFilter || !leagues) return <div></div>;

    return <div>{this.renderSelectedLeagues()}</div>;
  }
}

const mapStateToProps = (state, ownProps) => {
  let leagueFilter = state.leagues._leagueFilter;
  let sportCode = state.leagues._sportCode;
  let treeViewLeagues = state.leagues.treeViewLeagues;
  let leagues;

  for (let sportCode in treeViewLeagues) {
    for (let leagueId in treeViewLeagues[sportCode]) {
      leagues = {
        ...leagues,
        [leagueId]: treeViewLeagues[sportCode][leagueId],
      };
    }
  }

  return {
    leagues: leagues,
    treeViewLeagues: treeViewLeagues,
    selectedLeagues: state.selected.leagues,
    leagueFilter: leagueFilter,
    sportCode: sportCode,
    sportLabels: state.labels.sports,
    layout: state.settings.layout,
    leagueGrouping: state.settings.leagueGrouping,
  };
};

export default connect(mapStateToProps, { setLeagues, getGames })(
  withStyles(styles)(TabSelectViewer)
);
