/**
 *  Component for showing game HeadToHead widget from widgets.sir.sportradar.com service
 */

import React from 'react';
import './theme.css';

const HeadToHead = (props) => {
  React.useEffect(() => {
    setTimeout(() => {
      window.SIR("addWidget", ".sr-widget-4", "headToHead.standalone", {
        matchId: props.gameId,
        disableMatchHeader: true
      });
    }, 500);
  }, [props.gameId]);

  return (
    <div className="widgets">
      <div><div className="sr-widget sr-widget-4"><div style={{padding: "10px 5px"}}>Loading...</div></div></div>
    </div>
  );
}

export default HeadToHead;