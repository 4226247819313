/**
 * SVG component for own icon 
 */

import React from 'react';
import './IconOwn.css';

const IconFS = (props) => {

	return (
		<div style={{ display: "flex" }}>
			<svg className="icon-own" viewBox="0 0 2000 2000">
				<linearGradient gradientTransform="rotate(90)" id="icongradient">
					<stop className="icon-color-1" offset="0%" />
					<stop className="icon-color-2" offset="50%" />
					<stop className="icon-color-1" offset="100%" />
				</linearGradient>
				<g><path fill="url(#icongradient)" d="M1000,87.3C495.9,87.3,87.3,495.9,87.3,1000s408.6,912.7,912.7,912.7s912.7-408.6,912.7-912.7
	C1912.2,496.2,1503.8,87.8,1000,87.3z M1032.6,350.4l317.3-126.8c36,16.3,70.8,35.1,104.3,56.2l0.8,0.5
	c32.8,20.8,64.2,43.8,93.9,68.9l2.6,2.2c13.3,11.3,26.3,23.1,38.9,35.2c1.6,1.6,3.3,3,4.8,4.6c13.2,12.9,26,26.2,38.4,39.9
	c1,1.2,2,2.4,3,3.5c10.9,12.2,21.3,24.8,31.4,37.6c2.4,3,4.9,6.1,7.2,9.1c11,14.2,21.6,28.7,31.7,43.7l-63.3,268.9l-311.2,103.8
	l-299.8-239.9L1032.6,350.4z M324.7,481.5c2.4-3,4.8-6.1,7.2-9.1c10-12.7,20.4-25.1,31.1-37.2c1.2-1.3,2.2-2.6,3.4-3.9
	c12.3-13.7,25-27,38.2-39.9c1.5-1.5,3-3,4.6-4.4c12.5-12,25.3-23.7,38.5-35l3-2.6c29.4-24.9,60.5-47.7,93-68.5l1.2-0.8
	c33.2-21.1,67.8-39.8,103.6-56.1l318.8,126.2v307.3L667.6,897.5L356.4,794l-63.3-268.9C303.3,510.2,313.8,495.7,324.7,481.5z
	 M288.1,1466.9c-8.9-13.6-17.5-27.5-25.7-41.7l-2-3.4c-8.1-14.1-15.8-28.4-23-42.9l-0.3-0.5c-15.2-30.6-28.6-62.1-40.1-94.3v-0.3
	c-5.4-15.2-10.3-30.8-14.9-46.5l-1.6-5.8c-4.2-14.7-8-29.6-11.3-44.7c-0.3-1.5-0.7-2.9-1-4.3c-7.5-34-12.8-68.4-16.1-103.1
	l185.7-222.9l309.1,103l89.7,358.8l-147.4,196.1L288.1,1466.9z M1202.6,1827.3c-15.2,3.8-30.8,7-46.4,10c-2.2,0.4-4.4,0.8-6.6,1.2
	c-13.3,2.4-26.7,4.4-40.2,6.1c-3.6,0.5-7.2,1-10.7,1.4c-12.5,1.5-25.1,2.6-37.8,3.5c-4,0.3-7.9,0.7-11.9,0.9
	c-16.2,1-32.5,1.6-48.9,1.6c-15,0-30-0.4-44.9-1.2c-1.8,0-3.5-0.3-5.3-0.4c-13.2-0.8-26.4-1.8-39.6-3l-1.5-0.3
	c-28.8-3.1-57.4-7.7-85.8-13.7l-180-281.8L788,1358.1H1212l147.4,194.7L1202.6,1827.3z M1832,1182.5c-0.3,1.5-0.7,2.9-1,4.3
	c-3.4,15-7.2,29.9-11.3,44.7l-1.6,5.8c-4.6,15.7-9.5,31.2-14.9,46.5v0.3c-11.4,32.2-24.8,63.7-40.1,94.3l-0.3,0.5
	c-7.3,14.6-14.9,28.9-23,42.9l-2,3.4c-8.1,14.2-16.7,28.1-25.6,41.6l-300.2,47.3l-148.4-195.9l89.7-358.7l309.1-103l185.7,222.9
	C1844.8,1114.1,1839.5,1148.5,1832,1182.5z"/></g>
			</svg>
		</div>
	);
};

export default IconFS;
