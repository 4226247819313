import "./Odds.css";
import { log } from "../../config/log";
import React from "react";
import { connect } from "react-redux";
import {
  saveBet,
  deleteBet,
  saveMarketSetConfig,
  updateOddsInLeague,
  getOddsHistory,
  setLeagueMenuValue,
  toggleMyTicketsPanel,
} from "../../actions";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Divider, Grid } from "@mui/material";
import OddsFormat from "../OddsFormat";
import { sortAlphabetically } from "../../helpers/utilities";

class PlayerProps extends React.Component {
  constructor(props) {
    super(props);
    log(this.constructor.name + "Constructor", "render");

    this.state = {
      oddsHistoryMarketId: "",
      oddsChartsMarketId: "",
      betAdded: false,
      openDetail: false,
    };

    this.lastRenderedMarketId = null;
    this.showSpecificLine = false;
  }

  checkSelectedOdd(odd, index) {
    const { game, bets } = this.props;
    // console.log(odd);

    let payload = {
      gameId: game.gameid,
      // isLive: game.isLive
    };

    const marketId = odd.m;
    const selectionIds = odd.s?.split(",");
    const prices = odd.p?.split(",");
    const hc = odd.hc;
    const shc = odd.shc;
    const isActives = odd.iA?.split(",");

    if (selectionIds?.length < 3 && selectionIds?.length > 1) {
      selectionIds.unshift(null);
      prices.unshift(null);
      isActives.unshift(null);
    } else {
      selectionIds.push(null);
      prices.push(null);
      isActives.push(null);
      selectionIds.push(null);
      prices.push(null);
      isActives.push(null);
    }

    if (Number(prices[index]) < 1) return false;

    // payload.isMultiBet = 0;
    // payload.isBank = 0;
    payload.marketId = Number(marketId);
    payload.selectionId = Number(selectionIds[index]);
    payload.price = Number(prices[index]);
    payload.hc = hc;
    if (shc) {
      payload.shc = shc;
    }

    // payload.isActive = Number(isActives[index]);
    // console.log(payload);

    let flag = false;

    if (!bets) return flag;

    for (let i = 0; i < bets?.length; i++) {
      let bet = bets[i];
      // if ((bet.gameId === payload.gameId) && (bet.hc === payload.hc) && (bet.isActive === payload.isActive) && (bet.isBank === payload.isBank) && (bet.isLive === payload.isLive) && (bet.isMultiBet === payload.isMultiBet) && (bet.marketId === payload.marketId) && (bet.selectionId === payload.selectionId)) {
      if (
        bet.gameId === payload.gameId &&
        bet.marketId === payload.marketId &&
        bet.selectionId === payload.selectionId &&
        bet.hc === payload.hc &&
        bet.shc === payload.shc
      ) {
        flag = true;
        break;
      }
    }

    // for (let key in slipTemplates) {
    //   if (slipTemplates[key].slipTemplate === selectedSlipTemplate) {

    //     let bets = slipTemplates[key].settings.bets;

    //     for (let nextKey in bets) {
    //       if ((bets[nextKey].gameId === payload.gameId) && (bets[nextKey].hc === payload.hc) && (bets[nextKey].isActive === payload.isActive) && (bets[nextKey].isBank === payload.isBank) && (bets[nextKey].isLive === payload.isLive) && (bets[nextKey].isMultiBet === payload.isMultiBet) && (bets[nextKey].marketId === payload.marketId) && (bets[nextKey].selectionId === payload.selectionId)) {
    //         flag = true;
    //         break;
    //       }
    //     }
    //   }
    // }

    return flag;
  }

  /**
   * Select odd for bet
   */

  addBet(odd, index) {
    const { game, selectedSlipTemplate, maxSelections, bets } = this.props;
    this.setState({ betAdded: !this.state.betAdded });
    if (this.deleteBet(odd, index) === true) return false;
    // if (maxSelections === bets?.length) {
    //   return;
    // }
    let payload = {
      gameId: game.gameid,
      isLive: game.isLive,
    };

    const marketId = odd.m;
    const selectionIds = odd.s?.split(",");
    const prices = odd.p?.split(",");
    const hc = odd.hc;
    const shc = odd?.shc;
    const isActives = odd.iA?.split(",");

    if (selectionIds?.length < 3 && selectionIds?.length > 1) {
      selectionIds.unshift(null);
      prices.unshift(null);
      isActives.unshift(null);
    } else if (selectionIds?.length === 1) {
      selectionIds.push(null);
      prices.push(null);
      isActives.push(null);
      selectionIds.push(null);
      prices.push(null);
      isActives.push(null);
    }

    if (Number(prices[index]) < 1) return false;
    if (Number(prices[index]) === 1) return false;
    if (Number(isActives[index]) === 0) return false;

    payload.marketId = Number(marketId);
    payload.selectionId = Number(selectionIds[index]);
    payload.price = Number(prices[index]);
    payload.hc = hc;
    payload.sn = odd?.sn;
    payload.isPlayerProps = odd?.isPlayerProps;
    payload.isSinglePlayerProps = odd?.isSinglePlayerProps;
    payload.isActive = Number(isActives[index]);
    payload.game = { ...game, isOutright: true };
    if (shc) {
      payload.shc = shc;
    }
    this.props.toggleMyTicketsPanel(this.constructor.name, true);
    this.props.saveBet(payload, selectedSlipTemplate, this.constructor.name);
    return Object.keys(payload)?.length === 0 ? false : true;
  }

  /**
   * Deselect odd from bet
   */

  deleteBet(odd, index) {
    // console.log(odd);
    const self = this;
    const marketId = odd.m;
    const selectionIds = odd.s?.split(",");

    if (selectionIds?.length < 3) {
      selectionIds.unshift(null);
    }

    let payload = {};
    payload = self.props.bets.filter(function (bet) {
      return (
        bet.gameId === self.props.game.gameid &&
        bet.marketId === Number(marketId) &&
        bet.selectionId === Number(selectionIds[index]) &&
        bet.hc === odd.hc &&
        bet.shc === odd.shc
      );
    });
    // console.log(payload);

    if (payload?.length) {
      self.props.deleteBet(payload[0], self.props.selectedSlipTemplate);
      return Object.keys(payload[0])?.length === 0 ? false : true;
    } else {
      return false;
    }
  }

  /**
   * Replace selection label with correct value
   */

  getSelectionLabel = (id) => {
    const { selectionPrintLabels } = this.props;
    if (selectionPrintLabels && selectionPrintLabels[id]) {
      if (selectionPrintLabels[id].indexOf("{$competitor1}") > -1) {
        return selectionPrintLabels[id].replace("{$competitor1}", "1");
      } else if (selectionPrintLabels[id].indexOf("{$competitor2}") > -1) {
        return selectionPrintLabels[id].replace("{$competitor2}", "2");
      } else {
        return selectionPrintLabels[id];
      }
    }
    return "";
  };

  formatMarketViewerLabels(sportCode, gameState, marketLabel) {
    marketLabel = marketLabel?.replace("{%player}", "")?.trim();
    marketLabel = marketLabel?.replace(/^./, marketLabel[0].toUpperCase());
    return marketLabel;
  }

  renderGroupedMarketOdds = (odds) => {
    let sortedOdds = odds.sort((a, b) => {
      if (a.sn < b.sn) {
        return -1;
      }
      if (a.sn > b.sn) {
        return 1;
      }
      return 0;
    });
    return sortedOdds?.map((marketOdd, key) => {
      let shouldRenderName = true;
      if (this.lastRenderedMarketId === marketOdd?.sn) {
        shouldRenderName = false;
      }
      this.lastRenderedMarketId = marketOdd?.sn;

      const formattedOdds = [];
      formattedOdds.push({
        hc: marketOdd?.hc,
        iA: marketOdd?.iA?.split(",")[0],
        p: marketOdd?.p?.split(",")[0],
        s: marketOdd?.s?.split(",")[0],
        shc: marketOdd?.shc,
        sn: marketOdd?.sn?.split(",")[0],
        m: marketOdd?.m,
        iF: marketOdd?.iF,
        isPlayerProps: true,
      });
      if (marketOdd?.s?.split(",")?.length > 1) {
        formattedOdds.push({
          hc: marketOdd?.hc,
          iA: marketOdd?.iA?.split(",")[1],
          p: marketOdd?.p?.split(",")[1],
          s: marketOdd?.s?.split(",")[1],
          shc: marketOdd?.shc,
          sn: marketOdd?.sn?.split(",")[1],
          m: marketOdd?.m,
          iF: marketOdd?.iF,
          isPlayerProps: true,
        });
      }
      const hc = marketOdd.hc ? marketOdd.hc?.split("=") : [""];
      return (
        <div key={key} style={{ marginTop: "5px" }}>
          <div className="marketset-playerprops-grid-2-cols">
            <div className="playerprops-market">
              <p>{shouldRenderName ? marketOdd?.sn?.split(",")[0] : null}</p>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <span>
                {hc && marketOdd?.m !== 1234 ? hc[hc?.length - 1] : null}
              </span>
              <div className="marketset-grid-2-cols">
                {formattedOdds?.map((odd, indx) => (
                  <div
                    key={indx}
                    onClick={this.addBet.bind(this, odd, 0)}
                    className={`${
                      this.checkSelectedOdd(odd, 0) ? "selected-odds" : ""
                    }  content-center vertical-center cursor-pointer font-xl font-bold-2 odds-btn-outright border-odds-right border-odds-left `}
                    style={{
                      margin: "0.15em 0.3em",
                      padding: "10px",
                      color: odd.iA != "1" ? "var(--grey-5)" : "inherit",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        className="flex-end spacer-right font-s"
                        style={{ fontSize: "0.7em", fontWeight: 400 }}
                      >
                        {this.getSelectionLabel(odd?.s)}
                      </div>
                      <div
                        className="price-border spacer-right"
                        style={{
                          color: this.checkSelectedOdd(odd, 0)
                            ? "inherit"
                            : "var(--color-odds)",
                        }}
                      >
                        <OddsFormat price={odd.p} isActive={odd.iA == "1"} />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      );
    });
  };

  renderPlayerMarketOdds = (playerOdds) => {
    const sortedArray = sortAlphabetically(Object.values(playerOdds));
    const { hcDisabledMarkets } = this.props;
    return (
      <div>
        {sortedArray.map((selection, key) => {
          let selectedOdds = selection;
          if (selectedOdds?.length > 2) {
            selectedOdds = selectedOdds?.sort(
              (a, b) => parseFloat(a.p) - parseFloat(b.p)
            );
          }
          return (
            <div style={{ display: "flex", marginTop: "10px" }} key={key}>
              <Grid container>
                <Grid item xs={4} style={{ marginTop: "1em" }}>
                  <div className="vertical-center spacer-right playerprops-market">
                    {selectedOdds[0]?.sn}
                  </div>
                </Grid>
                <Grid item xs={8}>
                  <Grid container>
                    {selectedOdds?.map((selectedOdd, key) => {
                      const hc = selectedOdd.hc
                        ? selectedOdd.hc?.split(":")
                        : [""];
                      return (
                        <Grid item xs={6} md={4} key={key}>
                          <div
                            onClick={this.addBet.bind(
                              this,
                              { ...selectedOdd, isSinglePlayerProps: true },
                              0
                            )}
                            className={`${
                              this.checkSelectedOdd(selectedOdd, 0)
                                ? "selected-odds"
                                : ""
                            }  content-center vertical-center cursor-pointer font-xl font-bold-2 odds-btn-outright border-odds-right border-odds-left `}
                            style={{
                              margin: "0.2em 0.2em",
                              padding: "10px",
                              color:
                                selectedOdd.iA != "1" ? "var(--grey-5)" : "",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                width: "100%",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <div
                                className="flex-end spacer-right font-s"
                                style={{
                                  fontSize: "0.7em",
                                  fontWeight: 400,
                                  color: "var(--grey)",
                                }}
                              >
                                {hc &&
                                !hcDisabledMarkets.includes(selectedOdd?.m)
                                  ? hc[hc?.length - 1] + "+"
                                  : ""}
                              </div>
                              <div>{parseFloat(selectedOdd.p).toFixed(2)}</div>
                            </div>
                          </div>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Grid>
              </Grid>
            </div>
          );
        })}
      </div>
    );
  };

  renderPlayerMarkets = () => {
    const { odds, marketViewerLabels, game } = this.props;

    return (
      <div>
        {Object.keys(odds)?.map((marketKey, key) => {
          return (
            <Accordion key={marketKey}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ fontSize: "1.1em" }} />}
                aria-controls="panel1a-content"
              >
                <Typography style={{ fontSize: "1.1em" }}>
                  {[
                    marketViewerLabels &&
                      marketViewerLabels[marketKey] &&
                      this.formatMarketViewerLabels(
                        game.sport_code,
                        game.state,
                        marketViewerLabels[marketKey]
                      ),
                    // <OddsHistoryIcon key={'icon-' + key} style={{ width: '0.6em', padding: '0.15em', color: 'var(--grey-10)' }} onClick={() => { this.openOddsHistory(marketId) }} />
                  ]}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div
                  // className="games-list-box"
                  // style={{ padding: "20px 10px", width: "100%" }}
                  style={{ width: "100%", paddingBottom: "10px" }}
                >
                  {this.renderPlayerMarketOdds(odds[marketKey])}
                </div>
              </AccordionDetails>
            </Accordion>
          );
        })}
      </div>
    );
  };

  renderMultiPlayerMarkets = () => {
    const { multiOdds, marketViewerLabels, game } = this.props;
    return (
      <div>
        {Object.keys(multiOdds).map((marketKey, key) => {
          return (
            <Accordion key={marketKey}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
              >
                <Typography style={{ fontSize: "1.1em" }}>
                  {marketViewerLabels &&
                    marketViewerLabels[marketKey] &&
                    this.formatMarketViewerLabels(
                      game.sport_code,
                      game.state,
                      marketViewerLabels[marketKey]
                    )}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ width: "100%" }}>
                  {this.renderGroupedMarketOdds(multiOdds[marketKey])}
                </div>
              </AccordionDetails>
            </Accordion>
          );
        })}
      </div>
    );
  };

  render() {
    const { odds } = this.props;
    if (!odds) return;
    return (
      <div style={{ width: "94%", padding: "10px", margin: "auto" }}>
        {this.renderPlayerMarkets()}
        <Divider />
        {this.renderMultiPlayerMarkets()}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const marketSetConfig = state.settings.marketSets;
  let currentMarketSetValue =
    marketSetConfig[ownProps.game.sport_code]["sidebets"]["player"];
  const marketSetValue = currentMarketSetValue?.includes(",")
    ? currentMarketSetValue?.split(",")
    : currentMarketSetValue;
  const selectedOdds = ownProps.game.odds?.filter(function (odd) {
    return marketSetValue?.includes(odd.m);
  });

  const singlePlayerOdds = selectedOdds.filter(
    (sOdd) => sOdd.s?.split(",")?.length === 1
  );
  const multiPlayerOdds = selectedOdds.filter(
    (sOdd) => sOdd.s?.split(",")?.length > 1
  );

  const singlePlayersGroupedByMarket = singlePlayerOdds.reduce(
    (result, currentObject) => {
      const marketId = currentObject?.m;
      if (!result[marketId]) {
        result[marketId] = [];
      }
      result[marketId].push(currentObject);
      return result;
    },
    {}
  );

  const sortedSingles = {};

  Object.keys(singlePlayersGroupedByMarket)?.forEach((marketKey) => {
    const groupedBySelections = singlePlayersGroupedByMarket[marketKey]?.reduce(
      (result, currentObject) => {
        const selectionId = currentObject?.s;
        if (!result[selectionId]) {
          result[selectionId] = [];
        }
        result[selectionId].push(currentObject);
        return result;
      },
      {}
    );
    sortedSingles[marketKey] = groupedBySelections;
  });

  const groupedByMarket = multiPlayerOdds.reduce((result, currentObject) => {
    const marketId = currentObject?.m;
    if (!result[marketId]) {
      result[marketId] = [];
    }
    result[marketId].push(currentObject);
    return result;
  }, {});

  return {
    game: ownProps.game,
    odds: sortedSingles,
    multiOdds: groupedByMarket,
    marketSetConfig: marketSetConfig,
    selectedLeague: state.selected.leagues[ownProps.game["leagueID"]],
    mode: state.settings.mode,
    isSideBetOpen: true,
    selectedSlipTemplate: state.selected.slipTemplate,
    bets:
      state.slips && state.slips[state.selected.slipTemplate].settings?.bets ? Object.values(state.slips[state.selected.slipTemplate].settings.bets)
        : null,
    leagueFilter: state.leagues._leagueFilter,
    marketViewerLabels: state.labels.market ? state.labels.market.viewer : null,
    selectionPrintLabels: state.labels.selection
      ? state.labels.selection.print
      : null,
    from: ownProps.from ? ownProps.from : "default",
    marketSetKey: "player",
    showMyTicketsPanel: state.settings.showMyTicketsPanel,
    maxSelections:
      state.slips && state.slips[state.selected.slipTemplate].settings
        ? state.slips &&
          state.slips[state.selected.slipTemplate].settings?.maxSelections
        : 15,
    hcDisabledMarkets: state.settings.playerProps.hcDisabledList,
  };
};

export default connect(mapStateToProps, {
  saveBet,
  deleteBet,
  saveMarketSetConfig,
  toggleMyTicketsPanel,
  updateOddsInLeague,
  getOddsHistory,
  setLeagueMenuValue,
})(PlayerProps);
