// betbuilder loader
// REACT hooks
import { useEffect } from "react";
// @MUI components
import { Box, IconButton, Stack, Typography } from "@mui/material";
// REDUX utils
import { useDispatch, useSelector } from "react-redux";
import { emptySlipTemplate, getBetBuilderSlipBet, saveBet, setLeagues, toggleMyTicketsPanel } from "../../../actions";
// CONFIGs
import sportCastConfig from "../../../config/sportcast";
// router utils
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
// components
import Iconify from "../../iconify";
import { format } from "date-fns";

const BetBuilderLoaderPage = () => {
  // url params
  const params = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  // action dispatcher
  const dispatch = useDispatch();

  // state selectors
  const sportCasturl = useSelector((state) => state.settings.host.sportcast_url);
  const brand = useSelector((state) => state.settings.theme);
  const locale = useSelector((state) => state.i18n.locale);
  const slipTemplate = useSelector((state) => state.selected.slipTemplate);
  const bets = useSelector((state) => state.slips[slipTemplate]["settings"]["bets"]);
  const layout = useSelector((state) => state.settings.layout);
  const allLeagues = useSelector((state) => state.leagues);
  const selectedSlipTemplate = useSelector((state) => state.selected.slipTemplate);
  const leaguesGroup = allLeagues?.betbuilder ? allLeagues.betbuilder : {};
  // constants
  const gameId = params.gameId;
  const leagueId = searchParams.get("league");
  const sportId = searchParams.get("sport");
  const leagues = leaguesGroup && leaguesGroup[sportId] ? leaguesGroup[sportId] : [];
  const league = leagues.find((leg) => leg.leagueId === Number(leagueId));
  const games = league && league.games ? league.games : [];
  const game = games.find((gm) => gm.gameid === Number(gameId));

  function isJson(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  const handleAddBet = (res) => {
    const data = res?.payload ? res.payload : {};
    console.log(data);

    let consumerFixtureId = data?.ConsumerFixtureId ? data?.ConsumerFixtureId?.split(":") : [];
    let fixtureId = consumerFixtureId[2];
    if (Number(gameId) !== Number(fixtureId)) return;
    let payload = {
      game: game,
      gameId: game?.gameid,
      consumerFixtureId: data?.ConsumerFixtureId,
      isLive: false,
      isBetBuilder: true,
      marketId: undefined,
      betslipUid: data?.BetSlipUid,
      price: data?.PriceDetails?.DecimalPriceRounded,
      isActive: true,
      hc: "",
      selectionId: data?.BetSlipUid,
      description: data?.Description,
      translations: data?.Translations,
      identifier: data?.Identifier,
      marketDescription: data?.LegDetails,
      isMultiBet: 0,
      sn: data?.Description?.toString(),
    };
    dispatch(toggleMyTicketsPanel("BetBuilderLoaderPage", true));
    dispatch(emptySlipTemplate(0, "BetBuilderLoaderPage"));
    dispatch(saveBet(payload, selectedSlipTemplate, "BetBuilderLoaderPage"));
    if (layout === "touch") {
      navigate(`/${locale}/slip`);
    }
    return Object.keys(payload)?.length === 0 ? false : true;
  };

  const handleBuilderResponse = (e) => {
    if (!isJson(e?.data)) return;
    const betData = e?.data ? JSON.parse(e.data) : {};
    const betValues = bets ? Object.values(bets) : [];
    const isBetExist = betValues.find((bet) => bet.betslipUid === betData?.betslipUid);
    if (betData?.command === "addToBetslip" && !isBetExist) {
      dispatch(getBetBuilderSlipBet(betData?.betslipUid, handleAddBet, "BetBuilderLoaderPage"));
    }
  };

  const goBack = () => {
    navigate(`/${locale}/betbuilder?selectedBetBuilderSportCode=${game?.sport_code}`);
  };

  useEffect(() => {
    if (!allLeagues.betbuilder) {
      dispatch(setLeagues(null, "All", sportId, locale, gameId, leagueId, "BetbuilderLoaderPage"));
    }
  }, [allLeagues.betbuilder]);

  useEffect(() => {
    window.addEventListener("message", handleBuilderResponse);
    return () => {
      window.removeEventListener("message", handleBuilderResponse);
    };
  }, []);

  return (
    <Box>
      <Stack direction="row" alignItems="center" justifyContent="start" gap={2} px={1} py={1}>
        <IconButton onClick={goBack}>
          <Iconify icon="ic:round-arrow-back" />
        </IconButton>
        <Stack>
          <Stack>
            <Typography variant="string" align="left">
              {game?.team1}
            </Typography>
            <Typography variant="string" align="left">
              {game?.team2}
            </Typography>
          </Stack>
          <Typography variant="string" color="text.secondary">
            {game?.startTime ? format(new Date(game.startTime), "dd.MM. HH:mm") : null}
          </Typography>
        </Stack>
      </Stack>
      {sportCasturl && (
        <Box
          component="iframe"
          id="bb_modalframe"
          title="Betbuilder"
          src={`${sportCasturl}/markets?key=${sportCastConfig.WIDGET_KEY}&consumerfixtureid=sr:match:${params.gameId}&brand=nubet-${brand}${locale !== "en" ? `&culture=${locale}-${locale === "et" ? "EE" : "FI"}` : ""}`}
        />
      )}
    </Box>
  );
};

export default BetBuilderLoaderPage;
