import PropTypes from "prop-types";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@mui/styles";
import UncheckedIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckedIcon from "@mui/icons-material/CheckBox";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Avatar from "@mui/material/Avatar";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import League from "../League";
import OutrightLeague from "../OutrightLeague";
import { saveFavoriteLeagues } from "../../../actions";

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiSvgIcon-root": {
      fontSize: "1.7em",
    },
    "& .MuiTabs-root": {
      minHeight: "1em",
    },
    "& .MuiTab-root": {
      "@media (hover:hover)": {
        fontSize: "1.2em",
      },
    },
    "& .MuiTab-wrapper": {
      display: "inline-flex !important",
    },
    "& .MuiTabScrollButton-root.Mui-disabled": {
      display: "none",
    },
  },
  avatarAll: {
    backgroundColor: "transparent !important",
    color: "var(--color-contrast) !important",
    borderRadius: "1em !important",
    width: "1.6em !important",
    height: "1.6em !important",
    fontSize: "0.94em !important",
    lineHeight: "inherit !important",
    fontWeight: "500 !important",
  },
  avatarLive: {
    backgroundColor: "transparent",
    color: "var(--color-contrast)",
    width: "2.1em",
    height: "2.1em",
    fontSize: "0.94em",
    lineHeight: "inherit",
    fontWeight: "500",
    justifySelf: "center",
    backgroundPosition: "center",
    backgroundSize: "contain",
  },
}));

const SearchResultLeague = ({ league }) => {
  const [isSelected, setSelected] = useState(true);
  // create styles leagueFilter
  const classes = useStyles();
  const dispatch = useDispatch();
  // fav leagues list
  const favLeagues = useSelector((state) => state.leagues.favorites);
  const navMode = useSelector((state) => state.settings.navMode);
  const url = useSelector(state => state.settings.url);
  // get league total games
  let gameLiveCount = league?.gameLiveCount;
  let outrightOnly = false;

  let leagueText;
  if (league.labelCategory !== "") {
    leagueText = (
      <span>
        <span style={{ marginRight: "2px" }}>
          <b>{league.labelCategory} </b>
        </span>
        {league.label}
      </span>
    );
  } else {
    leagueText = <b>{league.label}</b>;
  }

  let className = isSelected ? `league-box-selected` : "league-box";

  const handleLeague = () => {
    setSelected(!isSelected);
  };

  const saveFavoriteLeaguesAll = () => {
    const sportCode = league?.sportCode;
    const leagueId = league?.leagueId;
    const outrightOnly = league && !league?.games || league && !Object.values(league?.games)?.length;
    let favoriteLeagues = { ...favLeagues };
    if (!favoriteLeagues[sportCode]) favoriteLeagues[sportCode] = {};

    if (!favoriteLeagues[sportCode][leagueId]) {
      if(url==="/outrights"||outrightOnly){
        favoriteLeagues[sportCode][leagueId] = {...league, isOutright: true };
      }else{
        favoriteLeagues[sportCode][leagueId] = {...league, isOutright: false };
      }
    } else {
      delete favoriteLeagues[sportCode][leagueId];
    }

    localStorage.setItem('favoriteLeagues', JSON.stringify(favoriteLeagues));
    dispatch(saveFavoriteLeagues("SearchResultLeague"));
  }

  return (
    <div className={classes.root}>
      <div></div>
      {/* <div className={className} data-ripple="var(--color-ripple)"> */}
      <div className={className} data-ripple="var(--color-ripple)">
        <div className="vertical-center grey-3 spacer-left-list" onClick={saveFavoriteLeaguesAll}>
          {favLeagues &&
          favLeagues[league.sportCode] &&
          favLeagues[league.sportCode][league.leagueId] ? (
            <CheckedIcon style={{ color: "var(--color-contrast)" }} />
          ) : (
            <UncheckedIcon />
          )}
        </div>
        <div
          id="league-country-flag"
          className="vertical-center content-center"
        >
          <img
            src={`https://api.nubet.com/media/flags/${(league.countryCode = ""
              ? "INT"
              : league.countryCode)}.png`}
            alt="flag"
          />
        </div>
        {/* <div className="text-wrap font-xl font-bold vertical-center spacer-left" onClick={() => this.selectLeague(league)}>{leagueText}</div> */}
        <div
          className="text-wrap font-xl font-bold vertical-center"
          onClick={handleLeague}
        >
          {leagueText}
        </div>
        <div className="vertical-center content-right" onClick={handleLeague}>
          {gameLiveCount > 0 ? (
            <span>
              <Avatar className={classes.avatarLive}>{gameLiveCount}</Avatar>
            </span>
          ) : null}
          <span>
            <Avatar className={classes.avatarAll}>{league.gameCount}</Avatar>
          </span>
        </div>

        {navMode === "tab" && layout === "mouse" ? null : (
          <div
            className="vertical-center content-center"
            onClick={handleLeague}
          >
            {isSelected ? (
              <ExpandLessIcon style={{ color: "var(--grey-3)" }} />
            ) : (
              <ExpandMoreIcon style={{ color: "var(--grey-3)" }} />
            )}
          </div>
        )}
      </div>
      {(isSelected && league.sportCode === "TR") ||
      (isSelected && league?.isOutright) ||
      (isSelected &&
        league.league_game_cnt === 0 &&
        parseInt(league.league_outright_cnt) > 0) ? (
        <OutrightLeague
          leagueId={league.leagueId}
          outrightOnly={outrightOnly}
          singleOnlyOutright={
            isSelected &&
            league.league_game_cnt === 0 &&
            parseInt(league.league_outright_cnt) > 0
          }
        />
      ) : (isSelected && league.sportCode !== "TR" && !outrightOnly) ||
        (isSelected && url !== "/outrights" && !outrightOnly) ||
        (isSelected && !league?.isOutright && !outrightOnly) ? (
        <League  leagueId={league.leagueId} />
      ) : null}
    </div>
  );
};

SearchResultLeague.propTypes = {
  league: PropTypes.object.isRequired,
};

export default SearchResultLeague;
