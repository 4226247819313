import "./style.css";
import React from "react";
import { Translate } from "react-redux-i18n";
import {
  getFeaturedOutrights,
  toggleMyTicketsPanel,
  saveBet,
  deleteBet,
} from "../../../actions";
import { connect } from "react-redux";
import { format } from "date-fns";
import locales from "../../../helpers/datefns-locales";
import { icons } from "../../events/SportsTabs";

class FeaturedOutrightCard extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      betAdded: false,
    };
  }

  getSelectionLabel = (id) => {
    const { selectionPrintLabels } = this.props;
    if (selectionPrintLabels && selectionPrintLabels[id]) {
      if (selectionPrintLabels[id].indexOf("{$competitor1}") > -1) {
        return selectionPrintLabels[id].replace("{$competitor1}", "1");
      } else if (selectionPrintLabels[id].indexOf("{$competitor2}") > -1) {
        return selectionPrintLabels[id].replace("{$competitor2}", "2");
      } else {
        return selectionPrintLabels[id];
      }
    }
    return "";
  };

  addBet(odd, index) {
    const { game, selectedSlipTemplate, maxSelections, bets } = this.props;
    this.setState({ betAdded: !this.state.betAdded });
    if (this.deleteBet(odd, index) === true) return false;
    // if (maxSelections === bets?.length) {
    //   return;
    // }

    let payload = {
      gameId: game.gameid,
      isLive: game.isLive,
    };

    const marketId = odd.m;
    const selectionId = odd.s;
    const price = odd.p;
    const hc = odd.hc;
    const isActive = odd.iA;

    payload.marketId = marketId;
    payload.selectionId = selectionId;
    payload.price = price;
    payload.hc = hc;
    payload.hn = odd.hn;
    payload.sn = odd.sn;
    payload.nr = odd?.nr;
    payload.isActive = isActive;
    payload.game = {
      ...game,
      score_set_calculated: {
        away: [],
        home: [],
      },
    };
    payload.isOutright = true;
    this.props.toggleMyTicketsPanel(this.constructor.name, true);
    this.props.saveBet(payload, selectedSlipTemplate, this.constructor.name);

    return Object.keys(payload)?.length === 0 ? false : true;
  }
  checkSelectedOdd(odd, index) {
    const { game, bets } = this.props;
    let payload = {
      gameId: game.gameid,
    };

    payload.marketId = odd.m;
    payload.selectionId = odd.s;
    payload.price = odd.p;
    payload.hc = odd.hc;

    let flag = false;

    if (!bets) return flag;

    for (let i = 0; i < bets?.length; i++) {
      let bet = bets[i];
      if (
        bet.gameId === payload.gameId &&
        bet.marketId === payload.marketId &&
        bet.selectionId === payload.selectionId &&
        bet.hc === payload.hc
      ) {
        flag = true;
        break;
      }
    }

    return flag;
  }

  /**
   * Deselect odd from bet
   */

  deleteBet(odd, index) {
    const self = this;
    const marketId = odd.m;
    const selectionIds = odd.s;

    if (selectionIds?.length < 3) {
      selectionIds.unshift(null);
    }

    let payload = {};
    payload = self.props.bets.filter(function (bet) {
      return (
        bet.gameId === self.props.game.gameid &&
        bet.marketId === Number(marketId) &&
        bet.selectionId === Number(selectionIds[index]) &&
        bet.hc === odd.hc
      );
    });

    if (payload?.length) {
      self.props.deleteBet(payload[0], self.props.selectedSlipTemplate);
      return Object.keys(payload[0])?.length === 0 ? false : true;
    } else {
      return false;
    }
  }

  render() {
    const { game, lang } = this.props;
    let odds = game?.odds
      ? game?.odds?.sort((a, b) => parseFloat(a?.p) - parseFloat(b?.p))
      : [];
    if (game && game && game?.odds?.length > 0 && game?.sport_code === "TR") {
      odds = game?.odds?.sort((a, b) => a?.ord - b?.ord);
    }
    return (
      // <div className="featured-outright-container">
      <div className="featured-outright-container">
        <div className="featured-outright-header">
          <div style={{ margin: "0 1em 0 0", justifySelf: "right" }}>
            {icons[game?.sport_code]}
          </div>
          <div className="featured-outright-logo">
            <span className="featured-banner-title">
              {game?.sport_code === "TR" ? game?.leagueName : null}{" "}
              {game?.game_title}
            </span>
            <p
              className="text-align-center font-bold font-xs"
              style={{ margin: "0" }}
            >
              {game?.sport_code === "TR"
                ? format(new Date(game?.nextBetStop), "dd.MM. HH:mm yyyy", {
                    locale: locales[lang],
                  })
                : !game.odds.find((item) => item.m === 534)
                ? format(new Date(game?.nextBetStop), "dd.MM. HH:mm yyyy", {
                    locale: locales[lang],
                  })
                : format(new Date(game?.endTime), "dd.MM. HH:mm yyyy", {
                    locale: locales[lang],
                  })}
            </p>
          </div>
          <div id="league-country-flag" style={{ margin: "0 0 0 1em" }}>
            <img
              src={`https://api.nubet.com/media/flags/${game?.countryCode}.png`}
              alt="flag"
            />
          </div>
        </div>

        {/* <div className="featured-outright-header">
          <div style={{ margin: "0 0 0 9em", justifySelf: "right" }}>
            {icons[game?.sport_code]}
          </div> */}

        {/* <div className="color-team font-team font-bold-2" style={{ margin: "0.5em 0 0", justifySelf: "center" }}>
            {game?.sport_code === "TR" ? game?.leagueName : null}
            {' '}
            {game?.game_title}
          </div> */}

        {/* <div className="featured-outright-logo">
            <img
              style={{ width: "9em", height: "auto" }}
              src={`https://api.nubet.com/media/logos/nubet_bluedark.svg`}
              alt="logo"
            />
          </div>
          <div id="league-country-flag" style={{ margin: "0 9em 0 0" }}>
            <img
              src={`https://api.nubet.com/media/flags/${game?.countryCode}.png`}
              alt="flag"
            />
          </div>
        </div> */}
        {/* <div className="featured-outright-info">
          <div
            className="color-team font-team font-bold-2"
            style={{ justifySelf: "center" }}
          >
            {game?.sport_code === "TR" ? game?.leagueName : null}{" "}
            {game?.game_title}
          </div>

          <p
            className="text-align-center color-time font-bold font-time-1"
            style={{ margin: "0" }}
          > */}
        {/* {game?.startTime */}

        {/* {game?.sport_code === "TR" 
              ? format(new Date(game?.nextBetStop), "dd.MM. HH:mm yyyy", {              
                  locale: locales[lang],
                })
              :
              !game.odds.find(item=>item.m===534)
              ? format(new Date(game?.nextBetStop), "dd.MM. HH:mm yyyy", {              
                  locale: locales[lang],
                })
              : 
              format(new Date(game?.endTime), "dd.MM. HH:mm yyyy", {              
                locale: locales[lang],
              })
              }
          </p>
        </div> */}
        <div className="featured-outright-game-details">
          <div className="featured-outright-game-logo">
            {/* <div className="featured-outright-logo">
              <img
                style={{ width: "9em", height: "auto" }}
                src={`https://api.nubet.com/media/logos/nubet_bluedark.svg`}
                alt="logo"
              />
            </div> */}
          </div>
          {/* <h3>{game?.leagueName}</h3> */}
          {/* <div className="featured-outright-game-state">
            <span>
              {marketViewerLabels &&
              odds &&
              odds[0] &&
              marketViewerLabels[odds[0]["m"]]
                ? marketViewerLabels[odds[0]["m"]]
                : null}
            </span>
          </div> */}
          <div className="featured-outright-game-odds-container">
            {odds?.map((odd, indx) => {
              return (
                <div key={indx} className="featured-outright-game-odds-row">
                  <p
                    style={{
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {odd?.sn}
                  </p>
                  {/* <a
                    href={`/${lang}/leagues/all/${game?.sport_code}?leagueId=${game?.leagueID}&gameId=${game?.gameid}&type=outrights&selectionId=${odd?.s}&marketId=${odd?.m}`}
                  > */}
                  <div
                    onClick={() => this.addBet(odd, indx)}
                    style={{}}
                    className={`${
                      odd?.priceChange === ">"
                        ? "odds-raise"
                        : odd?.priceChange === "<"
                        ? "odds-drop"
                        : odd?.priceChange === "="
                        ? ""
                        : "color-odds"
                    }   ${
                      this.checkSelectedOdd(odd, indx) && !odd.nr
                        ? "selected-odds"
                        : "odds-featured"
                    } font-xl font-bold-2 featured-outright-odd`}
                  >
                    {odd?.p}
                  </div>
                  {/* </a> */}
                </div>
              );
            })}
          </div>
        </div>
        <div className="featured-outright-footer">
          <a
            href={`/${lang}/leagues/all/${game?.sport_code}?leagueId=${game?.leagueID}&gameId=${game?.gameid}&type=outrights`}
          >
            <span>
              <Translate value={`labels.view_all`} />
            </span>
          </a>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const gamesList = [];
  const gamesGroups =
    state.banner.games && Object.values(state.banner.games)
      ? Object.values(state.banner.games)
      : [];
  gamesGroups?.forEach((grItem) => {
    const grItemValues = Object.values(grItem);
    grItemValues?.forEach((item) => {
      gamesList.push(item);
    });
  });
  return {
    games: gamesList,
    lang: state.i18n.locale,
    game: ownProps.game,
    selectionPrintLabels: state.labels.selection
      ? state.labels.selection.print
      : null,
    selectedSlipTemplate: state.selected.slipTemplate,
    maxSelections:
      state.slips && state.slips[state.selected.slipTemplate].settings
        ? state.slips &&
          state.slips[state.selected.slipTemplate].settings?.maxSelections
        : 15,
    marketViewerLabels: state.labels.market ? state.labels.market.viewer : null,
    bets:
      state.slips && state.slips[state.selected.slipTemplate].settings?.bets
        ? Object.values(state.slips[state.selected.slipTemplate].settings.bets)
        : null,
  };
};

export default connect(mapStateToProps, {
  getFeaturedOutrights,
  toggleMyTicketsPanel,
  saveBet,
  deleteBet,
})(FeaturedOutrightCard);
