/**
 * SVG component for own icon 
 */

import React from 'react';
import './IconOwn.css';

const IconAR = (props) => {

	return (
		<div style={{ display: "flex" }}>
			<svg className="icon-own" viewBox="0 0 2000 2000">
				<linearGradient gradientTransform="rotate(90)" id="icongradient">
					<stop className="icon-color-1" offset="0%" />
					<stop className="icon-color-2" offset="50%" />
					<stop className="icon-color-1" offset="100%" />
				</linearGradient>
				<g><path fill="url(#icongradient)" d="M833.6,1351.2L833.6,1351.2l517.6-517.6c51-51,51-133.9,0-184.8c-48.3-48.3-125.4-50.8-176.7-7.5c-2.7,1.3-5.3,3-7.5,5.2
		l-518.3,519.8c-51,51-51,133.9,0,184.8C699.8,1402.2,782.7,1402.2,833.6,1351.2z M926.1,1184.8l-37-37L1000,1037l37,37
		L926.1,1184.8z M1073.9,1037l-37-37l110.9-110.9l37,37L1073.9,1037z M1197.5,690.1c2.1-1.2,4.1-2.6,5.9-4.4
		c30.6-30.6,80.3-30.6,110.9,0c30.6,30.6,30.6,80.3,0,110.9l-92.4,92.4l-37-37l92.4-92.4c10.2-10.2,10.2-26.8,0-37
		c-10.2-10.2-26.8-10.2-37,0l-92.4,92.4l-37-37L1197.5,690.1z M1073.9,815.2l37,37L1000,963l-37-37L1073.9,815.2z M926.1,963l37,37
		l-110.9,110.9l-37-37L926.1,963z M685.8,1203.3L685.8,1203.3l92.4-92.4l37,37l-92.4,92.4c-10.2,10.2-10.2,26.8,0,37
		c10.2,10.2,26.8,10.2,37,0l92.4-92.4l37,37l-92.4,92.4c-30.6,30.6-80.3,30.6-110.9,0C655.2,1283.7,655.2,1233.9,685.8,1203.3z"/>
					<path fill="url(#icongradient)" d="M1736,264L1736,264c-124.3-124.3-332.2-173.5-570.6-134.9c-118.2,19.1-235.8,58.4-349.6,116.8
		C694.7,308,583.9,389,486.4,486.4C389,583.9,308,694.7,245.9,815.8c-58.4,113.8-97.7,231.4-116.8,349.6
		C90.6,1403.8,139.7,1611.7,264,1736s332.2,173.5,570.6,134.9c118.2-19.1,235.8-58.4,349.6-116.8
		c121.1-62.1,231.9-143.1,329.4-240.5c97.5-97.5,178.4-208.3,240.5-329.4c58.4-113.8,97.7-231.4,116.8-349.6
		C1909.4,596.2,1860.3,388.3,1736,264z M173.5,1408.2L173.5,1408.2c-8.4-72.1-6.2-151.1,7.2-234.4c38.3-236.7,160-467.6,342.7-650.4
		S937.1,219,1173.8,180.7c83.3-13.5,162.2-15.7,234.4-7.2c-53.3,4.5-110.3,15.1-170,31.9C1014.6,268.5,787,407.7,597.3,597.3
		C407.7,787,268.5,1014.6,205.4,1238.2C188.6,1297.9,178,1354.9,173.5,1408.2z M231,1551.5L231,1551.5
		c-14.9-83.2-7.1-186.3,24.7-299.1c60.7-215.1,195.1-434.6,378.6-618c183.5-183.5,403-317.9,618-378.6
		c112.8-31.8,215.9-39.7,299.1-24.7c-207.2,37.8-525,233-806.3,514.2C464,1026.5,268.8,1344.2,231,1551.5z M301,1699
		c-46.7-46.7-26-177.5,54-341.4c90.9-186.5,242.7-390.8,427.3-575.4s388.9-336.3,575.4-427.3c163.9-79.9,294.8-100.6,341.4-54
		s26,177.5-54,341.4c-90.9,186.5-242.7,390.8-427.3,575.4s-388.9,336.3-575.4,427.3C478.5,1725,347.6,1745.7,301,1699z M448.5,1769
		c207.2-37.8,525-233,806.3-514.2s476.5-599,514.2-806.3c14.9,83.2,7.1,186.3-24.7,299.1c-60.7,215.1-195.1,434.6-378.6,618
		s-403,317.9-618,378.6C634.8,1776.1,531.7,1784,448.5,1769z M1819.3,826.2L1819.3,826.2c-38.3,236.7-160,467.6-342.7,650.4
		s-413.7,304.4-650.4,342.7c-83.3,13.5-162.3,15.7-234.4,7.2c53.3-4.5,110.3-15.1,170-31.9c223.5-63.1,451.1-202.3,640.8-391.9
		c189.7-189.7,328.9-417.3,391.9-640.8c16.8-59.7,27.4-116.7,31.9-170C1835,664,1832.8,743,1819.3,826.2z"/>
				</g>
			</svg>
		</div>
	);
};

export default IconAR;
