/**
 * SVG component for own icon 
 */

import React from 'react';
import './IconOwn.css';

const IconF1 = (props) => {

	return (
		<div style={{ display: "flex" }}>
			<svg className="icon-own" viewBox="0 0 2000 2000">
				<linearGradient gradientTransform="rotate(90)" id="icongradient">
					<stop className="icon-color-1" offset="0%" />
					<stop className="icon-color-2" offset="50%" />
					<stop className="icon-color-1" offset="100%" />
				</linearGradient>
				<g><path fill="url(#icongradient)" d="M1618,378.7c-148.3-148.3-345.4-229.9-555.1-229.9c-311,0-617.5,82.8-886.1,239.6c-13.4,7.8-22.3,21.3-24.3,36.7
		c-2,15.3,3.3,30.7,14.2,41.7l106.6,106.6c-79.4,151.5-121.2,320.7-121.2,492v167.6c0,141.8,95.5,267,232.1,304.6l1104.1,303.6
		c24.6,6.8,49.9,10.2,75.4,10.2c156.7,0,284.3-127.5,284.3-284.3V933.7C1847.9,724.1,1766.3,526.9,1618,378.7z M1563.7,1751.5
		c-16.5,0-33-2.2-48.9-6.6L410.7,1441.3c-93.5-25.7-158.8-111.4-158.8-208.4v-167.6c0-166.7,43.7-331.4,126.4-476.1
		c11.2-19.5,7.9-44.1-8-60l-86.3-86.3c239.4-127.3,507.3-194.3,779-194.3c354.8,0,647.5,271,681.9,616.9l-608.7-152.2
		c-94.4-23.6-185.9,47.8-185.9,145.2v335.1c0,62.4,38.7,118.2,97.1,140.1l698.7,262C1732.1,1683.8,1655.7,1751.5,1563.7,1751.5
		L1563.7,1751.5z"/>
					<path fill="url(#icongradient)" d="M643.2,765.5c-100.8,0-182.9,82-182.9,182.9s82,182.9,182.9,182.9s182.9-82,182.9-182.9S744,765.5,643.2,765.5z
		 M643.2,1031.5c-45.8,0-83.1-37.3-83.1-83.1s37.3-83.1,83.1-83.1s83.1,37.3,83.1,83.1S689,1031.5,643.2,1031.5z"/></g>
			</svg>
		</div>
	);
};

export default IconF1;
