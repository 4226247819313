import "./style.css";
import React from "react";
import locales from "../../../helpers/datefns-locales";
import format from "date-fns/format";
import {
  getFeaturedGames,
  saveBet,
  deleteBet,
  toggleMyTicketsPanel,
} from "../../../actions";
import { connect } from "react-redux";
import OddsFormat from "../../OddsFormat";
import GameBoxDetailedAvatarFB from "../../events/GameBoxDetailedAvatarFBBB";
import GameBoxDetailedAvatarTE from "../../events/GameBoxDetailedAvatarTEBB";
import GameBoxDetailedAvatarNo from "../../events/GameBoxDetailedAvatarNoBB";
import { icons } from "../../events/SportsTabs";
import { calcGame, checkGameSession } from "../../../helpers/gameFunctions";
import GameBoxDetailedAvatarShort from "../../events/GameBoxDetailedAvatarShort";
import GameBoxDetailedAvatarLong from "../../events/GameBoxDetailedAvatarLongBB";
import GameBoxDetailedAvatarLongPS from "../../events/GameBoxDetailedAvatarLongPS";
import GameBoxDetailedAvatarBX from "../../events/GameBoxDetailedAvatarBX";
import GameBoxDetailedAvatarBB from "../../events/GameBoxDetailedAvatarBB";
import GameBoxDetailedAvatarSN from "../../events/GameBoxDetailedAvatarSN";

class FeaturedGameCardV1 extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      oddsHistoryMarketId: "",
      oddsChartsMarketId: "",
      betAdded: false,
      openDetail: false,
    };
  }

  addBet(odd, index) {
    const {
      game,
      selectedSlipTemplate,
      maxSelections,
      bets,
      marketViewerLabels,
      goalscorerMarkets,
    } = this.props;
    let gameCopy = { ...game, isOutright: goalscorerMarkets.includes(odd.m) };
    const returnedGame = checkGameSession(gameCopy, "all");
    if (returnedGame) {
      gameCopy = calcGame(returnedGame);
    }
    this.setState({ betAdded: !this.state.betAdded });
    if (this.deleteBet(odd, index) === true) return false;
    // if (maxSelections === bets?.length) {
    //   return;
    // }
    let payload = {
      gameId: game.gameid,
      isLive: game.isLive,
    };

    const marketId = odd.m;
    const selectionIds = odd.s?.split(",");
    const prices = odd.p?.split(",");
    const hc = odd.hc;
    const shc = odd?.shc;
    const isActives = odd.iA?.split(",");
    const selectionNames = odd?.sn?.split(",");

    if (selectionIds?.length < 3 && selectionIds?.length > 1) {
      selectionIds.push(null);
      prices.push(null);
      isActives.push(null);
    } else if (selectionIds?.length === 1) {
      selectionIds.push(null);
      prices.push(null);
      isActives.push(null);
      selectionIds.push(null);
      prices.push(null);
      isActives.push(null);
    }

    if (Number(prices[index]) < 1) return false;
    if (Number(prices[index]) === 1) return false;
    if (Number(isActives[index]) === 0) return false;

    const marketName =
      marketViewerLabels && marketViewerLabels[odd?.m]
        ? marketViewerLabels[odd?.m]
        : null;
    if (marketName && marketName?.includes("{%player}")) {
      payload.isPlayerProps = true;
    }

    payload.marketId = Number(marketId);
    payload.selectionId = Number(selectionIds[index]);
    payload.price = Number(prices[index]);
    payload.hc = hc;
    payload.sn =
      selectionNames?.length === 1
        ? selectionNames[0]
        : selectionNames[index]
        ? selectionNames[index]
        : "";
    // payload.isPlayerProps = odd?.isPlayerProps;
    // payload.isSinglePlayerProps = odd?.isSinglePlayerProps;
    payload.isActive = Number(isActives[index]);
    payload.game = gameCopy;
    if (shc) {
      payload.shc = shc;
    }

    this.props.toggleMyTicketsPanel(this.constructor.name, true);
    this.props.saveBet(payload, selectedSlipTemplate, this.constructor.name);
    return Object.keys(payload)?.length === 0 ? false : true;
  }
  checkSelectedOdd(odd, index) {
    const { game, bets } = this.props;

    let payload = {
      gameId: game.gameid,
      // isLive: game.isLive
    };

    const marketId = odd.m;
    const selectionIds = odd.s?.split(",");
    const prices = odd.p?.split(",");
    const hc = odd.hc;
    const shc = odd.shc;
    const isActives = odd.iA?.split(",");

    if (selectionIds?.length < 3 && selectionIds?.length > 1 && odd.m !== 910) {
      selectionIds.unshift(null);
      prices.unshift(null);
      isActives.unshift(null);
    } else {
      selectionIds.push(null);
      prices.push(null);
      isActives.push(null);
      selectionIds.push(null);
      prices.push(null);
      isActives.push(null);
    }

    if (Number(prices[index]) < 1) return false;

    payload.marketId = Number(marketId);
    payload.selectionId = Number(selectionIds[index]);
    payload.price = Number(prices[index]);
    payload.hc = hc;
    if (shc) {
      payload.shc = shc;
    }

    let flag = false;

    if (!bets) return flag;

    for (let i = 0; i < bets?.length; i++) {
      let bet = bets[i];
      if (
        bet.gameId === payload.gameId &&
        bet.marketId === payload.marketId &&
        bet.selectionId === payload.selectionId &&
        bet.hc === payload.hc &&
        bet.shc === payload.shc
      ) {
        flag = true;
        break;
      }
    }

    return flag;
  }

  /**
   * Deselect odd from bet
   */

  deleteBet(odd, index) {
    const self = this;
    const marketId = odd.m;
    const selectionIds = odd.s?.split(",");

    if (selectionIds?.length < 3) {
      selectionIds.push(null);
    }

    let payload = {};
    payload = self.props.bets.filter(function (bet) {
      return (
        bet.gameId === self.props.game.gameid &&
        bet.marketId === Number(marketId) &&
        bet.selectionId === Number(selectionIds[index]) &&
        bet.hc === odd.hc &&
        bet.shc === odd.shc
      );
    });
    // console.log(payload);

    if (payload?.length) {
      self.props.deleteBet(payload[0], self.props.selectedSlipTemplate);
      return Object.keys(payload[0])?.length === 0 ? false : true;
    } else {
      return false;
    }
  }

  getSelectionLabel = (id) => {
    const { selectionPrintLabels } = this.props;
    if (selectionPrintLabels && selectionPrintLabels[id]) {
      if (selectionPrintLabels[id].indexOf("{$competitor1}") > -1) {
        return selectionPrintLabels[id].replace("{$competitor1}", "1");
      } else if (selectionPrintLabels[id].indexOf("{$competitor2}") > -1) {
        return selectionPrintLabels[id].replace("{$competitor2}", "2");
      } else {
        return selectionPrintLabels[id];
      }
    }
    return "";
  };

  getHCLabel = (hc, odd) => {
    let hcstr = [];
    if (!hc) return "";
    if (hc.includes(":")) {
      hcstr = hc.split(":");
    }
    if (hc.includes("=") && hc.includes(":")) {
      hcstr = hc.split(":");
    }
    if (hc.includes("=") && hc.includes(":") && hc.includes("|")) {
      hcstr = hc.split("=");
    }

    if (odd.hc.includes("playerprops")) {
      return odd.sn + " " + hcstr[hcstr?.length - 1] + "+";
    }

    const { goalscorerMarkets } = this.props;
    if (goalscorerMarkets?.includes(odd?.m)) {
      return odd?.sn;
    }
    return hcstr[hcstr?.length - 1];
  };

  getMarketLabel = (m, sn, hc) => {
    const { marketViewerLabels } = this.props;
    let label = "";
    if (marketViewerLabels && marketViewerLabels[m]) {
      const marketFullName = marketViewerLabels[m];
      if (marketFullName?.includes("{%player}")) {
        label = marketFullName?.replace("{%player}", sn?.split(",")[0]);
      } else if (marketFullName?.includes("{!goalnr}")) {
        label = marketFullName?.replace("{!goalnr}", "1.");
      } else {
        label = marketFullName;
      }
    }

    return label;
  };

  render() {
    const { game, lang, marketViewerLabels } = this.props;
    if (!game) return;
    const odd = game && game?.odds ? game?.odds[0] : {};
    const hc = odd?.hc;
    const selectionIds = odd?.s?.split(",");
    const priceChanges =
      odd?.priceChanges && odd?.priceChanges?.length
        ? odd.priceChanges
        : ["=", "=", "="];

    const createJersey = (
      teamColor,
      teamHorStripes,
      teamNumber,
      teamSleeve,
      teamSplit,
      teamSquares,
      teamStripes,
      teamSleeveDetail,
      sportCode,
      isTeam1
    ) => {
      const defaultColor = isTeam1 ? "bdbdbd" : "636363";
      const defaultColorTe = isTeam1 ? 'afcff1' : '3da3fd';
      const defaultNumberColor = isTeam1 ? "f5f5f5" : "030303";
      const teamSleeveDetailColor =
        teamSleeveDetail != "" ? teamSleeveDetail : teamSleeve;
      const teamSplitColor =
        teamSplit != "false" ? teamSleeveDetailColor : "ffffff00";
      const teamHorStripesColor =
        teamHorStripes != "false" ? teamSleeveDetailColor : "ffffff00";
      const teamStripesColor =
        teamStripes != "false" ? teamSleeveDetailColor : "ffffff00";
      const teamSquaresColor =
        teamSquares != "false" ? teamSleeveDetailColor : "ffffff00";
      const teamSleeveColor = teamSleeve != "" ? teamSleeve : "ffffff00";

      return (
        <div className="featured-game-team">
          {game?.sport_code === "FB" && (
            <GameBoxDetailedAvatarFB
              pattern="1"
              color1={`#${teamColor || defaultColor}`}
              color2={`#${teamNumber || defaultNumberColor}`}
              color3={`#${teamHorStripesColor}`}
              color4={`#${teamSleeveColor}`}
              color5={`#${teamSplitColor}`}
              color6={`#${teamSquaresColor}`}
              color7={`#${teamStripesColor}`}
            />
          )}
          {game?.sport_code === "BA" && (
            <GameBoxDetailedAvatarNo
              pattern="1"
              color1={`#${teamColor || defaultColor}`}
              color2={`#${teamNumber || defaultNumberColor}`}
            />
          )}
          {(game?.sport_code === "AF" ||
            game?.sport_code === "FL" ||
            game?.sport_code === "VB" ||
            game?.sport_code === "HB" ||
            game?.sport_code === "FS") && (
            <GameBoxDetailedAvatarShort
              pattern="1"
              color1={`#${teamColor || defaultColor}`}
              color2={`#${teamNumber || defaultNumberColor}`}
            />
          )}
          {(game?.sport_code === "IH" || game?.sport_code === "BD") && (
            <GameBoxDetailedAvatarLong
              pattern="1"
              color1={`#${teamColor || defaultColor}`}
              color2={`#${teamNumber || defaultNumberColor}`}
            />
          )}
          {(game?.sport_code === "TE") && (
            <GameBoxDetailedAvatarTE
              pattern="1"
              color1={`#${teamColor || defaultColorTe}`}
              color2={`#${teamNumber || defaultNumberColor}`}
            />
          )}
          {(game?.sport_code === "DA") && (
            <GameBoxDetailedAvatarTE
              pattern="1"
              color1={`#${teamColor || defaultColor}`}
              color2={`#${teamNumber || defaultNumberColor}`}
            />
          )}
          {(game?.sport_code === "BX" || game?.sport_code === "MM") && (
            <GameBoxDetailedAvatarBX
              pattern="1"
              color1={`#${teamColor || defaultColor}`}
              color2={`#${teamNumber || defaultNumberColor}`}
            />
          )}
          {game?.sport_code === "PS" && (
            <GameBoxDetailedAvatarLongPS
              pattern="1"
              color1={`#${teamColor || defaultColor}`}
              color2={`#${teamNumber || defaultNumberColor}`}
            />
          )}
          {game?.sport_code === "SN" && (
            <GameBoxDetailedAvatarSN
              pattern="1"
              color1={`#${teamColor || defaultColor}`}
              color2={`#${teamNumber || defaultNumberColor}`}
            />
          )}
          {game?.sport_code === "BB" && (
            <GameBoxDetailedAvatarBB
              pattern="1"
              color1={`#${teamColor || defaultColor}`}
              color2={`#${teamNumber || defaultNumberColor}`}
            />
          )}
          {!game?.sport_code && (
            <GameBoxDetailedAvatarLong
              pattern="1"
              color1={`#${teamColor || defaultColor}`}
              color2={`#${teamNumber || defaultNumberColor}`}
            />
          )}
        </div>
      );
    };

    return (
      <div className="featured-game-container">
        <div className="featured-game-header">
          <div style={{ margin: "0 1em 0 0", justifySelf: "right" }}>
            {icons[game?.sport_code]}
          </div>
          <div className="featured-outright-logo">
            <span className="featured-banner-title">
              {game?.leagueName
                ? game?.leagueName
                : "League name not available"}
            </span>
            <p
              className="text-align-center font-bold font-xs"
              style={{ margin: "0" }}
            >
              {game?.startTime
                ? format(new Date(game?.startTime), "dd.MM. HH:mm", {
                    locale: locales[lang],
                  })
                : null}
            </p>
          </div>
          <div id="league-country-flag" style={{ margin: "0 0 0 1em" }}>
            <img
              src={`https://api.nubet.com/media/flags/${game?.countryCode}.png`}
              alt="flag"
            />
          </div>
        </div>
        <div className="game-teams-container">
          {createJersey(
            game?.team1_color,
            game?.team1_horizontal_stripes,
            game?.team1_number,
            game?.team1_sleeve,
            game?.team1_split,
            game?.team1_squares,
            game?.team1_stripes,
            game?.team1_sleeve_detail,
            game?.sport_code,
            true
          )}
          <div className="featured-game-team-separator">
            {/* <img
              style={{ width: "4em", height: "auto" }}
              src="https://api.nubet.com/media/logos/nubet_bluedark.svg"
              alt="logo"
            /> */}
            <div className="featured-game-team-vs">vs</div>
          </div>
          {createJersey(
            game?.team2_color,
            game?.team2_horizontal_stripes,
            game?.team2_number,
            game?.team2_sleeve,
            game?.team2_split,
            game?.team2_squares,
            game?.team2_stripes,
            game?.team2_sleeve_detail,
            game?.sport_code,
            false
          )}
          <div className="featured-game-team">
            {/* <a href={`/${lang}/leagues/all/${game?.sport_code}/${game?.leagueID}/${game?.gameid}`}> */}
            <span className="featured-game-team-name">{game?.team1}</span>
            {/* </a> */}
          </div>
          <div></div>
          <div className="featured-game-team">
            {/* <a href={`/${lang}/leagues/all/${game?.sport_code}/${game?.leagueID}/${game?.gameid}`}> */}
            <span className="featured-game-team-name">{game?.team2}</span>
            {/* </a> */}
          </div>
        </div>
        {/* <div style={{ margin: "0.5em 0 0.8em", textAlign: "center" }}> */}
        <div style={{ margin: "0.4em 0 1.8em", textAlign: "center" }}>
          {this.getMarketLabel(odd.m, odd?.sn, odd?.hc)}
        </div>
        <div
          className="featured-game-odds-container"
          style={{ padding: "0 3em 0", justifyContent: "center" }}
        >
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div style={{ padding: "0.4em 0" }}>{this.getHCLabel(hc, odd)}</div>
            <div
              // className="featured-game-odd"
              className={` ${
                this.checkSelectedOdd(
                  odd,
                  selectionIds?.length === 3
                    ? 0
                    : selectionIds?.length === 2
                    ? 1
                    : 0
                )
                  ? "selected-featured-game-odd"
                  : "featured-game-odd"
              } `}
              onClick={this.addBet.bind(this, odd, 0)}
            >
              <span className="featured-game-odd-spacer">
                {this.getSelectionLabel(
                  odd?.s && odd?.s?.split(",")[0] ? odd.s?.split(",")[0] : null
                )}
              </span>
              <span
                className={`featured-game-odd-selection  color-odds ${
                  priceChanges[0] === "="
                    ? ""
                    : priceChanges[0] === ">"
                    ? "odds-raise"
                    : priceChanges[0] === "<"
                    ? "odds-drop"
                    : ""
                } font-xl font-bold-2`}
              >
                <OddsFormat
                  price={
                    odd?.p && odd?.p?.split(",")[0]
                      ? odd.p?.split(",")[0]
                      : null
                  }
                  isActive={
                    odd?.iA && odd?.iA?.split(",")[0]
                      ? odd?.iA?.split(",")[0]
                      : null
                  }
                />
              </span>
            </div>
          </div>
          <div>
            {selectionIds[1] ? (
              <div
                // className="featured-game-odd"
                className={` ${
                  this.checkSelectedOdd(odd, selectionIds?.length === 3 ? 1 : 2)
                    ? "selected-featured-game-odd"
                    : "featured-game-odd"
                } `}
                onClick={this.addBet.bind(this, odd, 1)}
              >
                <span className="featured-game-odd-spacer">
                  {this.getSelectionLabel(
                    odd?.s && odd?.s?.split(",")[1]
                      ? odd.s?.split(",")[1]
                      : null
                  )}
                </span>
                <span
                  className={`featured-game-odd-selection  color-odds ${
                    priceChanges[1] === "="
                      ? ""
                      : priceChanges[1] === ">"
                      ? "odds-raise"
                      : priceChanges[1] === "<"
                      ? "odds-drop"
                      : ""
                  } font-xl font-bold-2`}
                >
                  <OddsFormat
                    price={
                      odd?.p && odd?.p?.split(",")[1]
                        ? odd.p?.split(",")[1]
                        : null
                    }
                    isActive={
                      odd?.iA && odd?.iA?.split(",")[1]
                        ? odd?.iA?.split(",")[1]
                        : null
                    }
                  />
                </span>
              </div>
            ) : null}
          </div>
          <div>
            {selectionIds[2] ? (
              <div
                // className="featured-game-odd"
                className={` ${
                  this.checkSelectedOdd(odd, 2)
                    ? "selected-featured-game-odd"
                    : "featured-game-odd"
                } `}
                onClick={this.addBet.bind(this, odd, 2)}
              >
                <span className="featured-game-odd-spacer">
                  {" "}
                  {this.getSelectionLabel(
                    odd?.s && odd?.s?.split(",")[2]
                      ? odd.s?.split(",")[2]
                      : null
                  )}
                </span>
                <span
                  className={`featured-game-odd-selection  color-odds ${
                    priceChanges[2] === "="
                      ? ""
                      : priceChanges[2] === ">"
                      ? "odds-raise"
                      : priceChanges[2] === "<"
                      ? "odds-drop"
                      : ""
                  } font-xl font-bold-2`}
                >
                  {" "}
                  <OddsFormat
                    price={
                      odd?.p && odd?.p?.split(",")[2]
                        ? odd.p?.split(",")[2]
                        : null
                    }
                    isActive={
                      odd?.iA && odd?.iA?.split(",")[2]
                        ? odd?.iA?.split(",")[2]
                        : null
                    }
                  />
                </span>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const gamesList = [];
  const gamesGroups =
    state.banner.games && Object.values(state.banner.games)
      ? Object.values(state.banner.games)
      : [];
  gamesGroups?.forEach((grItem) => {
    const grItemValues = Object.values(grItem);
    grItemValues?.forEach((item) => {
      gamesList.push(item);
    });
  });
  return {
    games: gamesList,
    lang: state.i18n.locale,
    game: ownProps.game,
    marketViewerLabels: state.labels.market ? state.labels.market.viewer : null,
    selectionPrintLabels: state.labels.selection
      ? state.labels.selection.print
      : null,
    bets:
      state.slips && state.slips[state.selected.slipTemplate].settings?.bets
        ? Object.values(state.slips[state.selected.slipTemplate].settings.bets)
        : null,
    selectedSlipTemplate: state.selected.slipTemplate,
    goalscorerMarkets:
      state.settings.marketSets["FB"]["sidebets"]["goalscorer"],
  };
};

export default connect(mapStateToProps, {
  getFeaturedGames,
  saveBet,
  deleteBet,
  toggleMyTicketsPanel,
})(FeaturedGameCardV1);
