/**
 * Main reducer for combining all sub reducers and link to the app
 */

import { combineReducers } from "redux";
import { i18nReducer } from "react-redux-i18n";
import { reducer as formReducer } from "redux-form";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import userReducer from "./userReducer";
import settingsReducer from "./settingsReducer";
import alertReducer from "./alertReducer";
import selectReducer from "./selectReducer";
import labelsReducer from "./labelsReducer";
import leaguesReducer from "./leaguesReducer";
import slipsReducer from "./slipsReducer";
import ticketsReducer from "./ticketsReducer";
import casinoReducer from "./casinoReducer";
import favoriteCasinoGamesReducer from "./favoriteCasinoGamesReducer";
import slipAutoUpdateStatusReducer from "./slipAutoUpdateStatusReducer";
import registeredUserReducer from "./registeredUserReducer";

import confirmReducer from "./confirmReducer";
import wpReducer from "./wpReducer";
import bannersReducer from "./bannersReducer";
import updateAlertReducer from "./oddsUpdateAlertReducer";
import searchReducer from "./searchReducer";
import comboboostReducer from "./comboboostReducer";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["slips"],
};

const rootReducer = combineReducers({
  i18n: i18nReducer,
  form: formReducer,
  user: userReducer,
  settings: settingsReducer,
  selected: selectReducer,
  alert: alertReducer,
  labels: labelsReducer,
  leagues: leaguesReducer,
  tickets: ticketsReducer,
  slips: slipsReducer,
  casino: casinoReducer,
  favoriteCasinoGames: favoriteCasinoGamesReducer,
  slipAutoUpdateStatus: slipAutoUpdateStatusReducer,
  registeredUser: registeredUserReducer,
  confirm: confirmReducer,
  wpContent: wpReducer,
  banner: bannersReducer,
  update: updateAlertReducer,
  search: searchReducer,
  comboboost: comboboostReducer,
});

export default persistReducer(persistConfig, rootReducer);
