/**
 * SVG component for own icon 
 */

import React from 'react';
import './IconOwn.css';

const IconBONUS = (props) => {

	return (
		<div style={{ display: "flex" }}>
			<svg className="icon-own-drawer" viewBox="0 0 2000 2000">
				<linearGradient gradientTransform="rotate(90)" id="icongradient">
					<stop className="icon-color-1" offset="0%" />
					<stop className="icon-color-2" offset="50%" />
					<stop className="icon-color-1" offset="100%" />
				</linearGradient>
				<g>
					<path fill="url(#icongradient)" d="M616.1,99.3c-291.9,0-528.4,131.8-528.4,294.4c0,162.6,236.6,294.4,528.4,294.4c51,0,100.3-4,146.9-11.5
		c-0.5-6.4-0.8-12.9-0.8-19.3c0-152.2,150.4-279.3,368.3-331.3C1075.5,196,866.1,99.3,616.1,99.3z"/>
					<path fill="url(#icongradient)" d="M102.4,626.3c-9.5,22.1-14.7,45.1-14.7,68.8c0,162.6,236.6,294.4,528.4,294.4c51,0,100.2-4,146.9-11.5
		c-0.5-6.4-0.8-12.8-0.8-19.3c0-31.5,6.6-62.9,19.7-93.4l13.2-30.8c-55.9,11.2-116.2,17.4-179,17.4
		C366.8,851.9,158,755.7,102.4,626.3z"/>
					<path fill="url(#icongradient)" d="M102.4,937.2c-9.5,22.1-14.7,45.1-14.7,68.8c0,162.6,236.6,294.4,528.4,294.4c51,0,100.2-4,146.9-11.5
		c-0.5-6.4-0.8-12.9-0.8-19.3c0-31.5,6.6-62.9,19.7-93.4l13.2-30.8c-55.9,11.2-116.2,17.4-179,17.4
		C366.8,1162.7,158,1066.5,102.4,937.2z"/>
					<path fill="url(#icongradient)" d="M102.4,1248.1c-9.5,22.1-14.7,45.1-14.7,68.8c0,162.6,236.6,294.4,528.4,294.4c51,0,100.3-4,146.9-11.5
		c-0.5-6.4-0.8-12.8-0.8-19.3c0-31.5,6.6-63,19.7-93.4l13.2-30.8c-55.9,11.2-116.2,17.3-179,17.3
		C366.8,1473.6,158,1377.4,102.4,1248.1z"/>
					<path fill="url(#icongradient)" d="M1383.9,977.5c291.9,0,528.4-131.8,528.4-294.4c0-162.6-236.6-294.4-528.4-294.4c-291.9,0-528.4,131.8-528.4,294.4
		C855.5,845.7,1092,977.5,1383.9,977.5z"/>
					<path fill="url(#icongradient)" d="M1383.9,1141.3c-249.3,0-458.1-96.2-513.8-225.6c-9.5,22.1-14.6,45.1-14.6,68.8c0,162.6,236.6,294.4,528.4,294.4
		c291.9,0,528.4-131.8,528.4-294.4c0-23.7-5.2-46.7-14.7-68.8C1842,1045.1,1633.2,1141.3,1383.9,1141.3z"/>
					<path fill="url(#icongradient)" d="M1383.9,1452.2c-249.3,0-458.1-96.2-513.8-225.6c-9.5,22.1-14.6,45.1-14.6,68.8c0,162.6,236.6,294.4,528.4,294.4
		c291.9,0,528.4-131.8,528.4-294.4c0-23.7-5.2-46.7-14.7-68.8C1842,1356,1633.2,1452.2,1383.9,1452.2z"/>
					<path fill="url(#icongradient)" d="M1383.9,1763c-249.3,0-458.1-96.2-513.8-225.5c-9.5,22.1-14.6,45.1-14.6,68.8c0,162.6,236.6,294.4,528.4,294.4
		c291.9,0,528.4-131.8,528.4-294.4c0-23.7-5.2-46.7-14.7-68.8C1842,1666.9,1633.2,1763,1383.9,1763z"/>
				</g>
			</svg>
		</div>
	);
};

export default IconBONUS;
