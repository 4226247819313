/**
 *  Component for showing game Lineups widget from widgets.sir.sportradar.com service
 */
 
import React from 'react';
import './theme.css';

const Lineups = (props) => {
  React.useEffect(() => {
    setTimeout(() => {
      window.SIR("addWidget", ".sr-widget-3", "match.Lineups", {
        matchId: props.gameId,
        disableMatchHeader: true,  
      });
    }, 500);
  }, [props.gameId]);

  return (
    <div className="widgets">
      <div><div className="sr-widget sr-widget-3"><div style={{padding: "10px 5px"}}>Loading...</div></div></div>
    </div>
  );
}

export default Lineups;