/**
 * Odds Price history component
 */

import { log } from "../../config/log";
import React from "react";
import { connect } from "react-redux";
import {
  ResponsiveContainer,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
} from "recharts";
import clsx from "clsx";
import withStyles from "@mui/styles/withStyles";
import { Localize } from "react-redux-i18n";
import Drawer from "@mui/material/Drawer";
import { Typography } from "@mui/material";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import PriceDropIcon from "@mui/icons-material/ArrowDropDown";
import PriceRaiseIcon from "@mui/icons-material/ArrowDropUp";
import "./OddsHistory.css";

const styles = (theme) => ({
  list: {
    width: "100vW",
  },
  fullList: {
    width: "auto",
  },
  drawerCustomStyle: {
    "& .MuiDrawer-paperAnchorRight": {
      left: "initial",
      right: "initial",
      height: "calc(100% - 36px)",
    },
  },
  backIcon: {
    zIndex: 1,
  },
  root: {
    "& .MuiTabs-root": {
      minHeight: "1em",
    },
    "& .MuiTab-root": {
      minHeight: "1em",
    },
    "& .MuiListItem-button": {
      color: "#000",
    },
  },
  chart: {
    // '& .MuiTabs-root': {
    //   minHeight: "1em",
    //   },
    //   '& .MuiTab-root': {
    //   minHeight: "1em",
    //   },
    // flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    position: "sticky",
    zIndex: 1001,
    top: 35,
    left: -20,
  },
  tooltip: {
    backgroundColor: theme.palette.background.paper,
  },
});

class OddsHistory extends React.PureComponent {
  constructor(props) {
    super(props);
    const { layout } = this.props;

    props.forwardRef(() => this.openDrawer());
    this.direction = layout === "touch" ? "right" : "left";

    this.state = {
      top: false,
      left: false,
      bottom: false,
      right: false,
      value: 0,
    };
  }

  openDrawer = () => {
    this.setState({ [this.direction]: true, value: 0 });
    setTimeout(() => {
      this.setState({ [this.direction]: true, value: 0 });
    }, 10);
  };

  toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    )
      return;
    this.setState({ ...this.state, [anchor]: open });
  };

  handleChange = (event, newValue) => {
    this.setState({ [this.direction]: true, value: newValue });
    setTimeout(() => {
      this.setState({ [this.direction]: true, value: newValue });
    }, 10);
  };

  /**
   * Return chart tooltip content
   */

  chartToolTipContent(toolTipProps) {
    if (!toolTipProps.payload[0]) return null;

    return (
      <div style={{ background: "var(--color-contrast)", padding: "5px" }}>
        <div>
          <b>Quote: {toolTipProps.payload[0].payload.price}</b>
        </div>
        <div>Zeitpunkt: {toolTipProps.payload[0].payload.gameMinute}</div>
        <div>Spielstand: {toolTipProps.payload[0].payload.gameScore}</div>
      </div>
    );
  }

  /**
   * Show area chart
   */

  renderChart() {
    const { oddsHistory, classes } = this.props;

    if (!oddsHistory || !Object.keys(oddsHistory)?.length)
      return (
        <div className="grey-3 font-m content-center vertical-center"></div>
      );

    return (
      <div className={classes.chart}>
        <ResponsiveContainer width="99%" aspect={3}>
          <AreaChart
            data={oddsHistory}
            margin={{
              left: -38,
              top: 5,
            }}
          >
            <defs>
              <linearGradient id="colorPrice1" x1="0" y1="0" x2="0" y2="1">
                <stop offset="33%" stopColor="var(--green)" stopOpacity={1} />
                <stop
                  offset="75%"
                  stopColor="var(--grey-5)"
                  stopOpacity={0.5}
                />
                <stop offset="99%" stopColor="var(--red)" stopOpacity={0.2} />
              </linearGradient>
              {/* <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
              </linearGradient> */}
            </defs>
            {/* <CartesianGrid strokeDasharray="3 3" /> */}
            <XAxis dataKey="gameMinute" />
            <YAxis />
            <Tooltip content={this.chartToolTipContent} />
            <Area
              type="monotone"
              dataKey="price"
              stackId="1"
              stroke="#676d73"
              fillOpacity={1}
              fill="url(#colorPrice1)"
            />
            {/* <Area type="monotone" dataKey="pv" stackId="1" stroke="#82ca9d" fill="#82ca9d" />
            <Area type="monotone" dataKey="amt" stackId="1" stroke="#ffc658" fill="#ffc658" /> */}
          </AreaChart>
        </ResponsiveContainer>
      </div>
    );
  }

  /**
   * Return odd changing state as icon
   */

  renderList() {
    const { oddsHistory } = this.props;

    if (!oddsHistory || !Object.keys(oddsHistory)?.length)
      return (
        <div className="grey-3 font-m content-center vertical-center"></div>
      );

    return Object.values(oddsHistory).map((odd, key) => {
      let changeIcon;
      switch (odd.change) {
        default:
        case "no_change":
          changeIcon = "";
          break;

        case "raise":
          changeIcon = (
            <PriceRaiseIcon
              style={{
                color: "var(--green)",
                margin: "0.05em 0.2em 0 0",
                fontSize: "2.5em",
              }}
            />
          );
          break;

        case "drop":
          changeIcon = (
            <PriceDropIcon
              style={{
                color: "var(--red)",
                margin: "0.05em 0.2em 0 0",
                fontSize: "2.5em",
              }}
            />
          );
          break;
      }

      return (
        <div className="odds-history-item" key={odd.gameMinute}>
          <div className="color-contrast font-bold-2 font-xl spacer-left vertical-center">
            {odd.gameMinute}
          </div>
          <div className="font-l vertical-center">{odd.gameScore}</div>
          <div
            className={`${
              odd.change === "raise" ? "green" : "red"
            } font-bold-2 font-xl flex-end vertical-center`}
          >
            <Localize
              value={odd.price}
              options={{ minimumFractionDigits: 2, maximumFractionDigits: 2 }}
            />
          </div>
          <div>{changeIcon}</div>
          <div className="vertical-center content-center">
            {odd.isLive === 1 ? "Live" : "vor Spielbeginn"}
          </div>
          <div className="vertical-center content-right spacer-right">
            <Localize value={odd.ts} dateFormat="date.extra" />
          </div>
        </div>
      );
    });
  }

  render() {
    log(this.constructor.name, "render");
    const { classes, layout, showMyTicketsPanel } = this.props;
    // const tabsMenuItems = ['Alle'];

    return (
      <div>
        <Drawer
          anchor={this.direction}
          open={this.state[this.direction]}
          onClose={this.toggleDrawer(this.direction, false)}
          className={classes.drawerCustomStyle}
        >
          <div
            className={clsx(classes.list, {
              [classes.fullList]:
                this.direction === "top" || this.direction === "bottom",
            })}
            style={{
              width:
                layout === "touch"
                  ? "100vW"
                  : showMyTicketsPanel
                  ? "50vW"
                  : "75vW",
            }}
            role="presentation"
          >
            <List
              onClick={this.toggleDrawer(this.direction, false)}
              className={classes.backIcon}
            >
              <ListItem button key="back">
                <ListItemIcon>
                  <ArrowBackIosIcon style={{ color: "var(--grey-1)" }} />
                </ListItemIcon>
                <Typography variant="body1">
                  <span className="color-contrast font-xxl font-bold">
                    {this.props.marketViewerLabels[this.props.marketId]}
                  </span>
                </Typography>
              </ListItem>
            </List>
            <Divider />

            <div className={classes.root}>
              {/* <AppBar position="sticky">
                <Tabs
                  value={this.state.value}
                  onChange={this.handleChange.bind(this)}
                  variant="scrollable"
                  indicatorColor="primary"
                  scrollButtons="auto"
                  aria-label="scrollable auto tabs example"
                >
                  {
                    tabsMenuItems.map((item, key) => (
                      <Tab key={key} value={key} label={item} {...a11yProps(key)} />
                    ))
                  }
                </Tabs>
              </AppBar> */}
              {this.renderChart()}
              {this.renderList()}
            </div>
          </div>
        </Drawer>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    leagueId: ownProps.leagueId,
    gameId: ownProps.gameId,
    oddsHistory:
      state.selected.leagues[ownProps.leagueId] &&
      state.selected.leagues[ownProps.leagueId].oddsHistory
        ? state.selected.leagues[ownProps.leagueId].oddsHistory
        : null,
    marketViewerLabels: state.labels.market.viewer,
    layout: state.settings.layout,
    showMyTicketsPanel: state.settings.showMyTicketsPanel,
    // marketId: state.selectedGameOddsHistory.marketId
  };
};

export default connect(mapStateToProps)(withStyles(styles)(OddsHistory));
