/**
 * Show all games list
 * For Odds and Columns view modes
 */

import { log } from "../../../config/log";
import React from "react";
import { connect } from "react-redux";
import { getBetBuilderGames } from "../../../actions";
import Grid from "@mui/material/Grid";
import BetBuilderGameBox from "./GameBox";

import "../GamesList.css";
import sortGamesByTime from "../../../helpers/gameChronologicalsort";

class GamesList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { league, leagueId } = this.props;
    if (league && leagueId) {
      this.props.getBetBuilderGames(
        league?.sportCode,
        true,
        this.constructor.name,
        league?.leagueId
      );
    }
  }

  render() {
    log(this.constructor.name, "render");
    const { league } = this.props;
    let games = league && league.games ? league.games : [];
    games = sortGamesByTime(games);

    return (
      <div style={{ width: "99%", padding: "1em" }}>
        <Grid container spacing={1}>
          {games.map((game, indx) => (
            // <Grid key={game.gameid} item xs={12} sm={3} md={3}>
            // <Grid key={game.gameid} item xs={12} sm={6} md={2}>
            <Grid key={game.gameid} item xs={12} sm={6} md={3}>
              <BetBuilderGameBox game={game} />
            </Grid>
          ))}
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    leagueId: ownProps.league?.leagueId,
    league: ownProps.league,
    locale: state.i18n.locale,
  };
};

export default connect(mapStateToProps, { getBetBuilderGames })(GamesList);
