// casino promo game card
// @mui compoenents
import { Box, Grid, Paper, Stack, styled, Typography, Button } from "@mui/material";
// @laxy load image
import Image from "../../image";
// router
import { useNavigate, useSearchParams } from "react-router-dom";
import LikeButton from "./LikeButton";
import { format } from "date-fns";
import { I18n, Translate } from "react-redux-i18n";

const StyledPaper = styled(Paper)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  // backgroundColor: theme.palette.background.default,
  padding: theme.spacing(1),
}));

const CasinoGameBonusCard = ({ game, bonus }) => {
  // router hooks
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  // generate game image
  let casinoGameImgLink = `https://storage.googleapis.com/nubet-casino-gfx/${game.name}.jpg`;
  // generate 404 image placeholder
  const handleImage404Error = (e) => {
    e.target.src = `https://storage.googleapis.com/nubet-casino-gfx/coming_soon.jpg`;
  };

  const handleOpen = () => {
    searchParams.set("dialog", "play-game");
    searchParams.set("game-id", game.gameId);
    searchParams.set("bonus", "free-spins");
    navigate(`${location.pathname}?${searchParams.toString()}`);
  };

  return (
    <Box>
      <Grid container>
        <Grid item xs={12} md={5}>
          <Image onError={handleImage404Error} src={casinoGameImgLink} ratio="4/3" />
        </Grid>
        <Grid item xs={12} md={7}>
          <StyledPaper>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="h2" sx={{ color: "var(--color-contrast)" }}>
                {game.translation} {I18n.t("menu.free_spins")}
              </Typography>
              <LikeButton gameId={game.gameId} />
            </Stack>
            <Stack direction="row" alignItems="flex-end" justifyContent="space-between" py={2}>
              <Stack>
                <Stack direction="row" alignItems="center" gap={1}>
                  <Typography variant="h5" component="p">
                    {I18n.t("labels.statement_header_game")}:
                  </Typography>
                  <Typography variant="subtitle2" component="h3">
                    {game.translation}
                  </Typography>
                </Stack>
                <Stack direction="row" alignItems="center" gap={1}>
                  <Typography variant="h5" component="p">
                  {I18n.t("labels.spins_left")}:
                  </Typography>
                  <Typography variant="subtitle2" component="h3">
                    {bonus.freeSpins}/{bonus.total}
                  </Typography>
                </Stack>
                <Stack direction="row" alignItems="center" gap={1}>
                  <Typography variant="h5" component="p">
                    {I18n.t("menu.bonus_wr")}:
                  </Typography>
                  <Typography variant="subtitle2" component="h3">
                    {bonus.turnoverFactor}x
                  </Typography>
                </Stack>
                <Stack direction="row" alignItems="center" gap={1}>
                  <Typography variant="h5" component="p">
                    {I18n.t("labels.expires")}:
                  </Typography>
                  <Typography variant="subtitle2" component="h3">
                    {format(new Date(bonus.expirationTime), "dd.MM.yyyy HH:mm")}
                  </Typography>
                </Stack>
                <br></br>
                <Stack>
                  <Button onClick={handleOpen} variant="contained" color="primary">
                    <Translate value="labels.slots_play" />
                  </Button>
                </Stack>
              </Stack>
              {/* <Stack>
                <Button
                  onClick={handleOpen}
                  variant="contained"
                  color="primary"
                >
                  <Translate value="labels.slots_play" />
                </Button>
              </Stack> */}
            </Stack>
          </StyledPaper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CasinoGameBonusCard;
