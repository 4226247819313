// getUserFavoriteCasinoGames

import { Box, Grid, Typography } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { I18n } from "react-redux-i18n";
import { getUserFavoriteCasinoGames } from "../../../actions";
import GameCard from "../components/CasinoGameCard";

const FavoriteGamesView = () => {
  // action dispatcher
  const dispatch = useDispatch();
  // state selectors
  const favGames = useSelector((state) => state.casino.favGames.games);
  const favGamesIds = useSelector((state) => state.casino.favGames.ids);

  useEffect(() => {
    dispatch(getUserFavoriteCasinoGames("FavoriteGamesView"));
  }, [favGamesIds.length]);

  return (
    <Box>
      <Typography variant="h2" m={2}>
      {I18n.t(`slots.mygames`)}
      </Typography>
      <Grid container>
        {favGames.map((gameData) => (
          <Grid item xs={6} sm={4} md={3} key={gameData.id}>
            <Box p={1}>
              <GameCard game={gameData} />
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default FavoriteGamesView;
