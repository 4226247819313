/**
 * Settings -> Notifications page
 * Get notifications with email / SMS
 */
import { log } from "../../config/log";
import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { withStyles } from "@mui/styles";
import { Translate } from "react-redux-i18n";
import FormControlLabel from "@mui/material/FormControlLabel";

import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";

import Button from "@mui/material/Button";

import { getSubscriptions, updateSubscriptions } from "../../actions";
import withRouter from "../../helpers/withRouter";

const styles = (theme) => ({
  root: {
    "& .MuiContainer-root": {
      padding: theme.spacing(2),
      width: "100%",
    },
    "& .MuiFormControl-root": {
      margin: "1em 0",
      width: "100%",
    },
    "& .MuiButton-root": {
      margin: "1em 1em 0 0",
      background:
        "linear-gradient(0deg, rgba(29,88,154,1) 0%, rgba(61,163,253,1) 50%, rgba(29,88,154,1) 100%)",
    },
  },
});

class Subscriptions extends React.PureComponent {
  componentDidMount() {
    const { auth, locale, navigate } = this.props;
    auth.isSignedIn
      ? this.props.getSubscriptions(this.constructor.name)
      : navigate(`/${locale}/sign-in`);
  }

  componentDidUpdate() {
    const { auth, locale, navigate } = this.props;
    if (!auth.isSignedIn) navigate(`/${locale}/sign-in`);
  }

  renderTextField = ({
    label,
    input,
    meta: { touched, invalid, error },
    ...custom
  }) => (
    <TextField
      label={label}
      placeholder={label}
      error={touched && invalid}
      helperText={touched && error}
      {...input}
      {...custom}
    />
  );

  renderCheckbox = ({ input, label }) => (
    <div>
      <FormControlLabel
        control={
          <Checkbox
            checked={input.value ? true : false}
            onChange={input.onChange}
            color="primary"
          />
        }
        label={label}
      />
    </div>
  );

  onSubmit = (formValues) => {
    this.props.updateSubscriptions(formValues, this.constructor.name);
  };

  render() {
    log(this.constructor.name, "render");
    const { initialValues, classes } = this.props;

    if (!initialValues) return <div>Loading...</div>;

    return (
      <form
        onSubmit={this.props.handleSubmit(this.onSubmit)}
        className={classes.root}
        autoComplete="off"
      >
        <div>
          <Container maxWidth="md">
            <Typography
              variant="body2"
              paragraph
              style={{ whiteSpace: "pre-line" }}
            >
              <Translate value="labels.notifications_text" />
            </Typography>
            <Field
              name="sub_email"
              component={this.renderCheckbox}
              label="Email"
            />
            <Field name="sub_sms" component={this.renderCheckbox} label="SMS" />
            <Button
              type="submit"
              variant="contained"
              size="small"
              color="primary"
              style={{ color: "var(--btn-text)" }}
            >
              <Translate value="labels.btn_save" />
            </Button>
          </Container>
        </div>
      </form>
    );
  }
}

const validate = (values, props) => {
  const errors = {};

  const requiredFields = [
    // 'sub_email',
    // 'sub_sms'
  ];

  //maks: some fields need to sent to server uppercase
  // const toUpper = [
  //   'address1',
  //   'address2',
  //   'zip',
  //   'city'
  // ];

  // toUpper?.forEach(field => {
  //   values[field] = values[field].toUpper;
  // })

  requiredFields?.forEach((field) => {
    if (!values[field]) {
      errors[field] = props.translate.labels.field_required;
    }
  });

  // if (!values.address1) {
  //   errors.address1 = props.translate.labels.field_required;
  // }

  return errors;
};

const mapStateToProps = (state) => ({
  auth: state.user.auth,
  initialValues: state.user.subscriptions
    ? state.user.subscriptions.types
    : null,
  translate: state.i18n.translations[state.i18n.locale],
  locale: state.i18n.locale,
});

export default withRouter(
  connect(mapStateToProps, {
    getSubscriptions,
    updateSubscriptions,
  })(
    reduxForm({
      form: "Subscriptions",
      validate,
      enableReinitialize: true,
    })(withStyles(styles)(Subscriptions))
  )
);
