/**
 * Reducer for Dynmaic banners
 */

import {
  GET_FEATURED_GAMES,
  GET_FEATURED_OUTRIGHTS,
  GET_FEATURED_SLIP,
  UPDATE_FEATURED_SLIP,
  UPDATE_FEATURED_OUTRIGHTS,
  UPDATE_FEATURED_GAMES,
  REMOVE_ENDED_FEATURED_GAME,
  REMOVE_ENDED_FEATURED_OUTRIGHT,
  REMOVE_ENDED_FEATURED_SLIP,
  GET_PRECANNED_BETS,
  FEATURED_GAMES_LOADING,
  FEATURED_OUTRIGHTS_LOADING,
  FEATURED_SLIPS_LOADING,
} from "../actions/types";
import {
  calcGame,
  updateOdd,
  updateOutrightOdd,
} from "../helpers/gameFunctions";

const INTIAL_STATE = {
  games: [],
  outrights: [],
  slip: {},
  precanned: {},
  isGamesLoading: false,
  isOutrightsLoading: false,
  isSlipsLoading: false,
};
let stateCopy;
let newOdds;
let newGames;
const fn = (state = INTIAL_STATE, action) => {
  switch (action.type) {
    case GET_FEATURED_GAMES:
      stateCopy = { ...state };
      let allGames = { ...action.payload };
      Object.keys(allGames)?.forEach((key) => {
        const leagueGroup = allGames[key];
        Object.keys(leagueGroup)?.forEach((gameKey) => {
          const game = leagueGroup[gameKey];
          allGames[key][gameKey] = calcGame(game);
        });
      });

      stateCopy.games = allGames;

      return stateCopy;
    case GET_FEATURED_OUTRIGHTS:
      stateCopy = { ...state };
      let allOutrights = { ...action.payload };

      Object.keys(allOutrights)?.forEach((key) => {
        const leagueGroup = allOutrights[key];
        Object.keys(leagueGroup)?.forEach((gameKey) => {
          const game = leagueGroup[gameKey];
          allOutrights[key][gameKey] = calcGame(game);
        });
      });

      stateCopy.outrights = allOutrights;
      return stateCopy;
    case GET_FEATURED_SLIP:
      stateCopy = { ...state };
      stateCopy.slip = action.payload;
      return stateCopy;
    case UPDATE_FEATURED_GAMES:
      stateCopy = { ...state };
      let leagues = Object.values(stateCopy.games);

      newOdds = action.payload;

      if (leagues?.length && newOdds?.length) {
        leagues?.forEach((games) => {
          Object.values(games)?.forEach((game) => {
            game?.odds?.forEach((odd) => {
              updateOdd(game, game.gameid, odd, newOdds);
            });
          });
        });
      }
      return stateCopy;
    case UPDATE_FEATURED_OUTRIGHTS:
      stateCopy = { ...state };
      let outrightLeagues = Object.values(stateCopy.outrights);
      newOdds = action.payload;

      if (outrightLeagues?.length && newOdds?.length) {
        outrightLeagues?.forEach((outrights) => {
          Object.values(outrights)?.forEach((outright) => {
            outright.odds?.forEach((odd) => {
              updateOutrightOdd(outright, outright.gameid, odd, newOdds);
            });
          });
        });
      }
      return stateCopy;
    case REMOVE_ENDED_FEATURED_GAME:
      stateCopy = { ...state };
      newGames = action.payload;
      const featuredGames = Object.values(stateCopy.games);

      if (featuredGames?.length && newGames?.length) {
        featuredGames?.forEach((games) => {
          Object.values(games)?.forEach((game) => {
            const isEnded = newGames.find(
              (item) => parseInt(item.gId) === game.gameid
            );
            if (isEnded) {
              delete stateCopy["games"][game.leagueID][game.gameid];
            }
          });
        });
      }

      return stateCopy;
    case REMOVE_ENDED_FEATURED_OUTRIGHT:
      stateCopy = { ...state };
      newGames =
        action.payload && typeof action.payload === "object"
          ? action.payload
          : [];
      const featuredOutrights = Object.values(stateCopy.outrights);
      if (featuredOutrights?.length && newGames?.length) {
        featuredOutrights?.forEach((games) => {
          Object.values(games)?.forEach((game) => {
            const isEnded = newGames.find(
              (item) => parseInt(item.gId) === game.gameid
            );
            if (isEnded) {
              delete stateCopy["outrights"][game.leagueID][game.gameid];
            }
          });
        });
      }
      return stateCopy;
    case UPDATE_FEATURED_SLIP:
      stateCopy = { ...state };
      newGames = action.payload;
      let slipData = Object.values(stateCopy.slip);

      newGames?.forEach((game) => {
        if (game.st === "FT") {
          stateCopy.slip[game.slipKey][game.collectionKey][action.updateType] =
            {};
        }
      });

      slipData?.forEach((slipdata) => {
        Object.values(slipdata)?.forEach((item) => {
          const itemGames =
            item?.games && typeof item?.games === "object" ? item.games : {};
          const itemOutrights =
            item?.outrights && typeof item?.outrights === "object"
              ? item.outrights
              : {};
          Object.values(itemGames)?.forEach((gameLeague) => {
            Object.values(gameLeague)?.forEach((game) => {
              game.odds?.forEach((odd, indx) => {
                updateOdd(game, game.gameid, odd, newGames);
              });
            });
          });
          Object.values(itemOutrights)?.forEach((outrightLeague) => {
            Object.values(outrightLeague)?.forEach((outright) => {
              outright.odds?.forEach((odd) => {
                updateOutrightOdd(outright, outright.gameid, odd, newGames);
              });
            });
          });
        });
      });

      return stateCopy;
    case REMOVE_ENDED_FEATURED_SLIP:
      stateCopy = { ...state };

      return stateCopy;
    case GET_PRECANNED_BETS:
      stateCopy = { ...state };
      stateCopy.precanned = action.payload;
      return stateCopy;
    case FEATURED_GAMES_LOADING:
      stateCopy = { ...state };
      stateCopy.isGamesLoading = action.payload;
      return stateCopy;
    case FEATURED_OUTRIGHTS_LOADING:
      stateCopy = { ...state };
      stateCopy.isOutrightsLoading = action.payload;
      return stateCopy;
    case FEATURED_SLIPS_LOADING:
      stateCopy = { ...state };
      stateCopy.isSlipsLoading = action.payload;
      return stateCopy;
    default:
      return state;
  }
};

export default fn;
