// Player props catalog
import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useLocation, useNavigate } from "react-router-dom";
import { withStyles } from "@mui/styles";
import { IconButton } from "@mui/material";
import Close from "@mui/icons-material/Close";
import { Translate } from "react-redux-i18n";
import SportsFilterTabs from "./SportsFilterTabs";
import { useDispatch, useSelector } from "react-redux";
import LeagueRow from "./LeagueRow";
import { setLeagues } from "../../../actions";

const styles = (theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "@media (hover:hover)": {
      justifyContent: "left",
      margin: "5em 0 0 0",
    },
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: "4px",
    boxShadow: theme.shadows[5],
    width: "100%",
    fontSize: "0.8em",
    "@media (hover:hover)": {
      width: "74.5%",
    },
    height: "100%",
    outline: "none",
    overflowY: "auto",
    overflowX: "hidden",
  },
  close_btn_container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "0.4em 0.2em",
  },
  headline: {
    color: "var(--yellow-1)",
    fontWeight: "800",
    fontSize: "1.2em",
  },
  builder_modal_header: {
    position: "sticky",
    top: 0,
    zIndex: 1000,
    backgroundColor: "var(--bg-app)",
  },
});

const PlayerPropsCatalog = ({ classes }) => {
  // redux action dispatcher
  const dispatch = useDispatch();
  // router hooks
  const location = useLocation();
  // selectors
  const leagues = useSelector((state) => state.leagues?.playerprops);
  const locale = useSelector((state) => state.i18n.locale);
  // seaerch params
  const searchParams = new URLSearchParams(location.search);
  const selectedSportCode = searchParams.get("selected-sport-code");
  // getSelected
  const selectedLeagues = leagues && leagues[selectedSportCode] ? leagues[selectedSportCode] : [];

  React.useEffect(() => {
    dispatch(setLeagues(null, "All", "FB", locale, "PlayerPropsCatalog"));
  }, []);

  return (
    <Box>
      <div className={classes.builder_modal_header}>
        <SportsFilterTabs selectedSportCode={selectedSportCode} />
        <div>
          {selectedLeagues?.map((league, indx) => (
            <LeagueRow key={league?.leagueId} indx={indx} league={league} />
          ))}
        </div>
      </div>
    </Box>
  );
};

export default withStyles(styles)(PlayerPropsCatalog);
