/**
 * Statement page (/statement)
 * Show Tickets, Slots, Deposits and withdraws statements with time period selection
 * Time period selection = 24h, 7 days, 30 days and other
 */
import { log } from "../../config/log";
import React from "react";
import { connect } from "react-redux";
import { Localize } from "react-redux-i18n";
import { Translate } from "react-redux-i18n";
import { withStyles } from "@mui/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import AppBar from "@mui/material/AppBar";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import format from "date-fns/format";
import subDays from "date-fns/subDays";

import {
  getWalletStatement,
  resetWalletStatement,
  loadMoreWalletStatement,
} from "../../actions";
import { formatCommasAndDots } from "../../helpers/utilities";

import "./Statement.css";
import { addDays } from "date-fns";
import withRouter from "../../helpers/withRouter";

const styles = (theme) => ({
  root: {
    "& .MuiTabs-root": {
      minHeight: "3.2em",
    },
    "& .MuiTab-root": {
      fontSize: "1.2em",
      minHeight: "1em",
      textTransform: "uppercase !important",
      color: "var(--grey)",
    },
    "@media (hover:hover)": {
      backgroundColor: "var(--color-main)",
    },
    "& .MuiTab-textColorInherit.Mui-selected": {
      fontWeight: "bold",
      color: "var(--color-contrast)",
    },
    "& .MuiAppBar-colorPrimary": {
      backgroundColor: "var(--bg-tabs-1)",
    },
    position: "sticky",
    zIndex: 999,
    top: 0,
  },
  customTable: {
    "& .MuiTableCell-root": {
      padding: "0.4em",
      borderBottom: "0em solid var(--grey-9)",
      fontSize: "1em",
      "@media (hover:hover)": {
        fontSize: "1.2em",
      },
    },
    "& .MuiTable-root": {
      width: "98.5%",
      "@media (hover:none)": {
        width: "96%",
      },
    },
    "& .MuiTableCell-head": {
      fontWeight: "bold",
      color: "var(--color-contrast)",
      margin: "3em",
      "&:nth-last-child(-n+2)": {
        textAlign: "right",
      },
    },
    "& .MuiTableCell-body": {
      "&:nth-last-child(-n+2)": {
        textAlign: "right",
      },
      // borderBottom: '1px solid var(--bg-menu-top)',
      borderBottom: "1px solid var(--bg-tabs-1)",
    },
    textAlign: "-webkit-center;",
  },
  menuBottom: {
    "& .MuiTabs-root": {
      minHeight: "1em",
    },
    "& .MuiTab-root": {
      minHeight: "1em",
      fontSize: "1.2em",
    },
    "& .MuiAppBar-root": {
      backgroundColor: "var(--color-main)",
      color: "var(--color)",
    },
    flexGrow: 1,
    width: "100%",
    zIndex: 1,
  },
  menuDatePicker: {
    "& .MuiInputBase-root": {
      fontSize: "6.5em",
      "@media (hover: hover)": {
        fontSize: "5em",
      },
    },
    "& .MuiButton-outlinedSizeSmall": {
      padding: "0.2em 0.7em",
      fontSize: "4.5em",
      "@media (hover: none)": {
        margin: "1.5em 0 0 0",
      },
    },
    "& .MuiFormLabel-root": {
      color: "var(--color-contrast)",
      fontSize: "5.5em",
      "@media (hover: none)": {
        fontSize: "7em",
      },
    },
    flexGrow: 1,
    width: "100%",
    backgroundColor: "var(--color-main)",
    zIndex: 1,
    fontSize: "0.2em",
    margin: "0 0 5em 0",
  },
  infoNotification: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "10em 0",
    color: "var(--color-contrast)",
    fontSize: "1.5em",
    "& i": {
      marginRight: 10,
    },
  },
  loadMore: {
    "& .MuiButton-outlinedSizeSmall": {
      padding: "0.2em 0.7em",
      fontSize: "1em",
    },
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "3em 0",
  },
  subTabs: {
    "& .MuiTab-root": {
      minHeight: "3em !important",
      padding: "0.35em 0 !important",
      color: "var(--grey)",
      "@media (hover:hover)": {
        minHeight: "2.3em !important",
        padding: "0.44em 0 !important",
        fontSize: "1.2em !important",
      },
      background:
        "linear-gradient(0deg, rgba(29,88,154,1) 0%, rgba(61,163,253,1) 50%, rgba(29,88,154,1) 100%)",
    },
    "& .MuiTabs-indicator": {
      display: "none",
    },
    "& .MuiAppBar-colorPrimary": {
      backgroundColor: "var(--bg-app)",
    },
    backgroundColor: "var(--color-main)",
  },
  tabSelection: {
    "&.MuiButtonBase-root.MuiTab-root": {
      backgroundColor: "var(--color-slip-tabs)",
      borderRadius: "0.4em",
      height: "1em",
      color: "var(--btn-text)",
      fontSize: "0.9em !important",
      margin: "1em 0.8em",
      width: "120px",
    },
  },
  indicatorRemove: {
    display: "none",
  },
});
function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

class Statement extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      type: "games",
      period: 1,
      startDate: format(subDays(new Date(), 1), "yyyy-MM-dd"),
      endDate: format(addDays(new Date(), 1), "yyyy-MM-dd"),

      currentPage: 1,
    };
  }

  componentDidMount() {
    /**
     * Get Ticket statements when user is already signed in
     * Otherwise, redirect to login page
     */
    const { auth, locale } = this.props;
    if (auth.isSignedIn) {
      this.props.getWalletStatement(
        "games",
        this.state.startDate,
        this.state.endDate,
        1,
        this.constructor.name
      );
    } else {
      this.props.navigate(`/${locale}/sign-in`);
    }
  }

  componentDidUpdate() {
    const { auth, locale } = this.props;
    if (!auth.isSignedIn) this.props.navigate(`/${locale}/sign-in`);
  }

  startDateChange(e) {
    this.setState({ startDate: e.target.value });
  }

  endDateChange(e) {
    this.setState({ endDate: e.target.value });
  }

  renderDatePicker() {
    if (this.state.period !== "custom") return;

    const { classes, translate } = this.props;

    return (
      <div className={classes.menuDatePicker}>
        <form className={classes.container} noValidate>
          <Grid container style={{ padding: "5em" }}>
            <Grid item xs={6} sm={2}>
              <TextField
                id="startDate"
                label={translate.labels.from_title}
                type="date"
                value={this.state.startDate}
                onChange={this.startDateChange.bind(this)}
                className={classes.textField}
                style={{ width: "90%" }}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={6} sm={2}>
              <TextField
                id="endDate"
                label={translate.labels.to_title}
                type="date"
                value={this.state.endDate}
                onChange={this.endDateChange.bind(this)}
                className={classes.textField}
                style={{ width: "90%" }}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12} sm={7} className={"vertical-center"}>
              <Button
                variant="outlined"
                size="small"
                color="primary"
                onClick={(e) => this.reloadStatements()}
              >
                <Translate value="labels.stmt_btn" />
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    );
  }

  formatStmtHeader = (key) => {
    /**
     * Render appropriate header label with key value
     */
    let translateKey = `labels.statement_header_${key}`;

    if (key === "transactionType") {
      translateKey = `labels.statement_header_trtype`;
    }

    if (key === "ticketId") {
      translateKey = `labels.statement_header_ticket_id`;
    }

    if (key === "status") {
      translateKey = `labels.statement_header_${this.state.type}_${key}`;
    }

    return <Translate value={translateKey} />;
  };

  formatStmtItem = (key, value) => {
    /**
     * Show correct statement items / records
     */
    if (key === "transactionType" || key === "status") {
      const translateKey = `labels.transaction_status_${value}`;
      return <Translate value={translateKey} />;
    }

    if (key === "ticketId") {
      return value;
    }

    if (key === "game") {
      return value;
    }

    if (key === "balance") {
      // return `EUR ${formatCommasAndDots(value, this.props.locale)}`;
      return `${formatCommasAndDots(value, this.props.locale)}`;
    }

    if (key === "amount") {
      return `${formatCommasAndDots(value, this.props.locale)}`;
    }

    if (key === "provider") {
      return value;
    }
  };

  renderTicketsAndSlotsTable() {
    const { statement, classes } = this.props;
    return (
      <Table className={classes.withTheme} aria-label="customized table">
        <TableHead>
          <TableRow>
            <TableCell>
              <Translate value="labels.statement_header_ts" />
            </TableCell>
            <TableCell>
              <Translate value="labels.statement_header_ticket_id" />
            </TableCell>
            <TableCell>
              <Translate value="labels.statement_header_trtype" />
            </TableCell>
            <TableCell>
              <Translate value="slip.stake" />
            </TableCell>
            <TableCell>
              <Translate value="labels.auto_logout_win" />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {statement.transactions.map((item, indx) => (
            <TableRow key={indx}>
              <TableCell align="left" component="th" scope="row">
                <Localize value={item.createdTime} dateFormat="date.extra" />
              </TableCell>
              {item.productName === "VLSportsbetE"
                ? [
                    <TableCell
                      key={1000}
                      align="left"
                      component="th"
                      scope="row"
                    >
                      {item.roundId}
                    </TableCell>,
                  ]
                : [
                    <TableCell
                      key={1001}
                      align="left"
                      component="th"
                      scope="row"
                    >
                      {item.productTranslation}
                    </TableCell>,
                  ]}
              <TableCell
                align="left"
                style={{
                  color:
                    item.status === "WIN"
                      ? "var(--green)"
                      : item.status === "LOSS"
                      ? "var(--red)"
                      : "var(--grey)",
                }}
              >
                {item.status}
              </TableCell>

              <TableCell align="left" component="th" scope="row">
                {item.cashBetAmount?.toFixed(2)}
              </TableCell>
              <TableCell
                align="left"
                component="th"
                scope="row"
                style={{
                  color: item.status === "WIN" ? "var(--green)" : "var(--grey)",
                }}
              >
                {item.winAmount?.toFixed(2)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  }

  renderDepositsAndWithdrawlsTable() {
    const { statement, classes } = this.props;
    return (
      <Table className={classes.withTheme} aria-label="customized table">
        <TableHead>
          <TableRow>
            <TableCell>
              <Translate value="labels.statement_header_ts" />
            </TableCell>
            <TableCell>
              <Translate value="labels.statement_header_amount" />
            </TableCell>
            <TableCell>
              <Translate value="labels.statement_header_trtype" />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {statement.transactions.map((item, indx) => (
            <TableRow key={indx}>
              <TableCell align="left" component="th" scope="row">
                <Localize value={item.createdTime} dateFormat="date.extra" />
              </TableCell>
              <TableCell align="left" component="th" scope="row">
                {item.amount?.toFixed(2)}
              </TableCell>

              <TableCell
                align="left"
                style={{
                  color:
                    item.status === "ok"
                      ? "var(--green)"
                      : item.status === "cancelled"
                      ? "var(--red)"
                      : "var(--grey)",
                }}
              >
                {item.status}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  }

  renderList() {
    const { statement, classes } = this.props;
    // console.log(statement)

    if (!statement) return <div>Loading...</div>;

    return (
      <>
        {statement.transactions?.length ? (
          <>
            <TableContainer component={Paper} className={classes.customTable}>
              {this.state.type === "slots" || this.state.type === "games"
                ? this.renderTicketsAndSlotsTable()
                : this.renderDepositsAndWithdrawlsTable()}
            </TableContainer>
            {statement.loadMore ? (
              <div className={classes.loadMore}>
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  onClick={this.loadMoreWalletStatement.bind(this)}
                  startIcon={<ExpandMoreIcon />}
                >
                  <Translate
                    value="labels.more"
                    style={{ cursor: "pointer" }}
                  />
                </Button>
              </div>
            ) : null}
          </>
        ) : (
          <div className={classes.infoNotification}>
            <i className="material-icons done">error</i>
            <Translate value="labels.statement_no_entries" />
          </div>
        )}
      </>
    );
  }

  menuTopChange = (event, newValue) => {
    this.setState({ type: newValue }, () => {
      this.reloadStatements();
    });
  };

  menuBottomChange = (event, newValue) => {
    this.setState({ period: newValue }, () => {
      const { period } = this.state;

      if (period === "custom") {
        return;
      } else {
        /** period filter for 24h **/

        const today = addDays(new Date(), 1);
        const prevDay = subDays(new Date(), period);

        const startDate = format(prevDay, "yyyy-MM-dd");
        const endDate = format(today, "yyyy-MM-dd");

        this.setState({ startDate, endDate }, () => {
          this.reloadStatements();
        });
      }
    });
  };

  reloadStatements = () => {
    /**
     * Reload current statements records
     */
    const { auth, locale } = this.props;
    const { type, startDate, endDate } = this.state;
    this.props.resetWalletStatement();

    auth.isSignedIn
      ? this.props.getWalletStatement(
          type,
          startDate,
          endDate,
          1,
          this.constructor.name
        )
      : this.props.navigate(`/${locale}/sign-in`);
  };

  loadMoreWalletStatement = () => {
    /**
     * Load more statements
     */
    const { auth, locale } = this.props;
    const { type, startDate, endDate } = this.state;
    let { currentPage } = this.state;

    currentPage += 1;

    this.setState({ currentPage }, () => {
      auth.isSignedIn
        ? this.props.loadMoreWalletStatement(
            type,
            startDate,
            endDate,
            currentPage,
            this.constructor.name
          )
        : this.props.navigate(`/${locale}/sign-in`);
    });
  };

  render() {
    log(this.constructor.name, "render");
    const { classes, layout } = this.props;
    const menuTopItems = [
      {
        // label: 'Tickets',
        label: <Translate value="labels.stmt_tickets" />,
        value: "games",
      },
      {
        label: <Translate value="labels.stmt_payin" />,
        value: "deposits",
      },
      {
        label: <Translate value="labels.stmt_payout" />,
        value: "withdrawals",
      },
    ];

    const menuBottomItems = [
      {
        label: <Translate value="labels.stmt_24" />,
        value: 1,
      },
      {
        label: <Translate value="labels.stmt_7" />,
        value: 7,
      },
      {
        label: <Translate value="labels.stmt_30" />,
        value: 30,
      },
      {
        label: <Translate value="labels.stmt_other" />,
        value: "custom",
      },
    ];

    return (
      <div style={{ backgroundColor: "var(--color-main)" }}>
        <div className={classes.root}>
          <AppBar position="static" elevation={0}>
            <Tabs
              value={this.state.type}
              onChange={this.menuTopChange.bind(this)}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
              classes={{
                indicator: classes.indicatorRemove,
              }}
            >
              {menuTopItems.map((item, key) => (
                <Tab
                  key={key}
                  value={item.value}
                  label={item.label}
                  {...a11yProps(key)}
                />
              ))}
            </Tabs>
          </AppBar>
        </div>
        {/* {console.log(this.props.statementData.header)} */}
        {/* <AccountTickets header={this.props.statementData.header} items={this.props.statementData.items}/> */}

        {/* <div className={classes.menuBottom}> */}
        {this.state.type !== "withdrawals" ? (
          <div className={classes.subTabs}>
            <AppBar position="static" elevation={0}>
              <Tabs
                value={this.state.period}
                onChange={this.menuBottomChange.bind(this)}
                // variant="fullWidth"
                variant={layout === "mouse" ? "scrollable" : "fullWidth"}
                indicatorColor="primary"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
              >
                {menuBottomItems.map((item, key) => (
                  <Tab
                    key={key}
                    className={classes.tabSelection}
                    value={item.value}
                    label={item.label}
                    {...a11yProps(key)}
                  />
                ))}
              </Tabs>
            </AppBar>
          </div>
        ) : null}
        {this.state.type !== "withdrawals" ? this.renderDatePicker() : null}
        {this.renderList()}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    auth: state.user.auth,
    statement: state.user.statement,
    locale: state.i18n.locale,
    translate: state.i18n.translations[state.i18n.locale],
    layout: state.settings.layout,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    getWalletStatement,
    resetWalletStatement,
    loadMoreWalletStatement,
  })(withStyles(styles)(Statement))
);
