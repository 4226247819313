/**
 * Reducer for labels
 */

import {
    GET_SPORTS_LABELS,
    GET_MARKET_LABELS,
    GET_MARKET_SPECIFIERS,
    GET_SELECTION_LABELS,
    GET_SELECTION_VIEWER_LABELS,
    GET_SELECTION_PRINT_LABELS,
    GET_HC_LABELS,
    GET_USER_BONUSES,
    GET_CASHOUT_AVAILABILITY,
} from '../actions/types';

const fn = (state = {}, action) => {

    let newState = {};
    switch (action.type) {
        case GET_SPORTS_LABELS:
            newState = { ...state.sports, default: action.payload };
            return { ...state, sports: newState };

        case GET_MARKET_LABELS:
            newState = { ...state.market, viewer: action.payload };
            return { ...state, market: newState };

        case GET_MARKET_SPECIFIERS:
            newState = { ...state.market, specifiers: action.payload };
            return { ...state, market: newState };

        case GET_SELECTION_LABELS:
            newState = { ...state.selection, default: action.payload };
            return { ...state, selection: newState };

        case GET_SELECTION_VIEWER_LABELS:
            newState = { ...state.selection, viewer: action.payload };
            return { ...state, selection: newState };

        case GET_SELECTION_PRINT_LABELS:
            newState = { ...state.selection, print: action.payload };
            return { ...state, selection: newState };

        case GET_HC_LABELS:
            newState = { ...state.hc, default: action.payload };
            return { ...state, hc: newState };

        case GET_USER_BONUSES:
            return { ...state, userBonuses: action.payload };

        case GET_CASHOUT_AVAILABILITY:
            return { ...state, cashoutAvailability: action.payload };

        default:
            return state;
    }
};

export default fn;