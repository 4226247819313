import { Box } from "@mui/material";
import GameBoxDetailedAvatarShort from "./GameBoxDetailedAvatarShort";
import GameBoxDetailedAvatarLong from "./GameBoxDetailedAvatarLongBB";
import GameBoxDetailedAvatarLongPS from "./GameBoxDetailedAvatarLongPS";
import GameBoxDetailedAvatarBX from "./GameBoxDetailedAvatarBX";
import GameBoxDetailedAvatarBB from "./GameBoxDetailedAvatarBB";
import GameBoxDetailedAvatarSN from "./GameBoxDetailedAvatarSN";
import GameBoxDetailedAvatarFB from "./GameBoxDetailedAvatarFBBB";
import GameBoxDetailedAvatarTE from "./GameBoxDetailedAvatarTE";
import GameBoxDetailedAvatarNo from "./GameBoxDetailedAvatarNoBB";
import PropTypes from "prop-types";

const TeamJersey = ({ game, teamNumber }) => {
  const createJersey = (
    teamColor,
    teamHorStripes,
    teamNumber,
    teamSleeve,
    teamSplit,
    teamSquares,
    teamStripes,
    teamSleeveDetail,
    isTeam1
  ) => {
    const defaultColor = isTeam1 ? "bdbdbd" : "636363";
    const defaultNumberColor = isTeam1 ? "f5f5f5" : "030303";
    const teamSleeveDetailColor =
      teamSleeveDetail != "" ? teamSleeveDetail : teamSleeve;
    const teamSplitColor =
      teamSplit != "false" ? teamSleeveDetailColor : "ffffff00";
    const teamHorStripesColor =
      teamHorStripes != "false" ? teamSleeveDetailColor : "ffffff00";
    const teamStripesColor =
      teamStripes != "false" ? teamSleeveDetailColor : "ffffff00";
    const teamSquaresColor =
      teamSquares != "false" ? teamSleeveDetailColor : "ffffff00";
    const teamSleeveColor = teamSleeve != "" ? teamSleeve : "ffffff00";

    return (
      <div className="featured-game-team">
        {game?.sport_code === "FB" && (
          <GameBoxDetailedAvatarFB
            pattern="1"
            color1={`#${teamColor || defaultColor}`}
            color2={`#${teamNumber || defaultNumberColor}`}
            color3={`#${teamHorStripesColor}`}
            color4={`#${teamSleeveColor}`}
            color5={`#${teamSplitColor}`}
            color6={`#${teamSquaresColor}`}
            color7={`#${teamStripesColor}`}
          />
        )}
        {game?.sport_code === "BA" && (
          <GameBoxDetailedAvatarNo
            pattern="1"
            color1={`#${teamColor || defaultColor}`}
            color2={`#${teamNumber || defaultNumberColor}`}
          />
        )}
        {(game?.sport_code === "AF" ||
          game?.sport_code === "FL" ||
          game?.sport_code === "VB" ||
          game?.sport_code === "HB" ||
          game?.sport_code === "FS") && (
          <GameBoxDetailedAvatarShort
            pattern="1"
            color1={`#${teamColor || defaultColor}`}
            color2={`#${teamNumber || defaultNumberColor}`}
          />
        )}
        {(game?.sport_code === "IH" || game?.sport_code === "BD") && (
          <GameBoxDetailedAvatarLong
            pattern="1"
            color1={`#${teamColor || defaultColor}`}
            color2={`#${teamNumber || defaultNumberColor}`}
          />
        )}
        {(game?.sport_code === "TE" || game?.sport_code === "DA") && (
          <GameBoxDetailedAvatarTE
            pattern="1"
            color1={`#${teamColor || defaultColor}`}
            color2={`#${teamNumber || defaultNumberColor}`}
          />
        )}
        {(game?.sport_code === "BX" || game?.sport_code === "MM") && (
          <GameBoxDetailedAvatarBX
            pattern="1"
            color1={`#${teamColor || defaultColor}`}
            color2={`#${teamNumber || defaultNumberColor}`}
          />
        )}
        {game?.sport_code === "PS" && (
          <GameBoxDetailedAvatarLongPS
            pattern="1"
            color1={`#${teamColor || defaultColor}`}
            color2={`#${teamNumber || defaultNumberColor}`}
          />
        )}
        {game?.sport_code === "SN" && (
          <GameBoxDetailedAvatarSN
            pattern="1"
            color1={`#${teamColor || defaultColor}`}
            color2={`#${teamNumber || defaultNumberColor}`}
          />
        )}
        {game?.sport_code === "BB" && (
          <GameBoxDetailedAvatarBB
            pattern="1"
            color1={`#${teamColor || defaultColor}`}
            color2={`#${teamNumber || defaultNumberColor}`}
          />
        )}
        {!game?.sport_code && (
          <GameBoxDetailedAvatarLong
            pattern="1"
            color1={`#${teamColor || defaultColor}`}
            color2={`#${teamNumber || defaultNumberColor}`}
          />
        )}
      </div>
    );
  };
  return (
    <Box>
      {teamNumber === 2
        ? createJersey(
            game?.team2_color,
            game?.team2_horizontal_stripes,
            game?.team2_number,
            game?.team2_sleeve,
            game?.team2_split,
            game?.team2_squares,
            game?.team2_stripes,
            game?.team2_sleeve_detail,
            game?.sport_code,
            false
          )
        : createJersey(
            game?.team1_color,
            game?.team1_horizontal_stripes,
            game?.team1_number,
            game?.team1_sleeve,
            game?.team1_split,
            game?.team1_squares,
            game?.team1_stripes,
            game?.team1_sleeve_detail,
            game?.sport_code,
            true
          )}
    </Box>
  );
};

TeamJersey.propTypes = {
  game: PropTypes.object,
  teamNumber: PropTypes.number,
};

TeamJersey.defaultProps = {
  game: {},
  teamNumber: 1,
};

export default TeamJersey;
