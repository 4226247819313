/**
 * Game Tabs component
 * E.g, Main bets, Over/under, Handicap, Asian Bets, Config Bets, , , , etc
 */

import { log } from "../../config/log";
import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import withStyles from "@mui/styles/withStyles";
import SwipeableViews from "react-swipeable-views";
import { Translate } from "react-redux-i18n";
import Badge from "@mui/material/Badge";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { AppBar } from "@mui/material";
import Odds from "./Odds";
import { menuConfig } from "../../config/menuConfig";
import CheckedIcon from "@mui/icons-material/CheckBox";
import StandingsIcon from "@mui/icons-material/FormatListNumberedRtl";
import LineupsIcon from "@mui/icons-material/Group";
import HeadToHeadIcon from "@mui/icons-material/AlignVerticalCenter";
import SummaryIcon from "@mui/icons-material/Timer";
import MarketIcon from "@mui/icons-material/ViewList";
import StatisticsIcon from "@mui/icons-material/Equalizer";
import VerticalTimeline from "./widgets/betradar/VerticalTimeline";
import LiveTable from "./widgets/betradar/LiveTable";
import GeneralStatistics from "./widgets/betradar/GeneralStatistics";
import Lineups from "./widgets/betradar/Lineups";
import HeadToHead from "./widgets/betradar/HeadToHead";
import MatchStatistics from "./widgets/betradar/MatchStatistics";
import GoalScorerMarket from "./GoalScorerMarket";
import PlayerProps from "./PlayerPropsOdds";
import BetbuilderWidget from "./BetbuilderWidget";

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const styles = (theme) => ({
  root: {
    "& .MuiTabs-root": {
      minHeight: "1em",
    },
    "& .MuiTab-root": {
      // minHeight: "1em",
      fontSize: "1.2em",
      minWidth: "6em",
    },
    "& .MuiTabScrollButton-root": {
      fontSize: "2em",
      color: "var(--color-contrast)",
    },
    "& .MuiTabScrollButton-root.Mui-disabled": {
      display: "none",
    },
    flexGrow: 1,
    width: "100%",
    // position: 'fixed',
    // bottom: '0px',
    // zIndex: 1,
    backgroundColor: theme.palette.background.paper,
  },
  rootTop: {
    "& .MuiTab-wrapper": {
      display: "inline-flex !important",
    },
    "& .MuiSvgIcon-root": {
      fontSize: "1.1em !important",
    },
    "& .MuiTab-wrapper > *:first-child": {
      marginBottom: 0,
    },
    "& .MuiBadge-anchorOriginTopRightRectangle": {
      top: "0.7em !important",
      left: "0.4em",
      "@media (hover: none) and (min-width: 768px)": {
        top: "0.6em !important",
        left: "0.8em",
      },
    },
    "& .MuiBadge-badge": {
      height: "1em",
      minWidth: "1em",
      fontSize: "0.8em",
      "@media (hover: none) and (min-width: 768px)": {
        // height: '17px',
        // minWidth: '11px',
      },
    },
    // flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.default,
    // position: "sticky",
    // top: "0",
    // zIndex: "999"
  },
  rootConference: {
    flexGrow: 1,
    width: "100%",
    position: "fixed",
    bottom: "66px",
    zIndex: 1,
    backgroundColor: theme.palette.background.paper,
  },
  tabLabel: {},
});

class GameTabs extends React.PureComponent {
  constructor(props) {
    super(props);

    this.icons = {};
    this.labels = {};

    this.state = {
      menuIndex: 0,
    };
  }

  setIcons() {
    const { selectedGame, marketSetConfig } = this.props;

    this.icons = {
      h2h: <HeadToHeadIcon style={{ transform: "rotate(90deg)" }} />,
      summary: <SummaryIcon />,
      statistics: <StatisticsIcon />,
      standings: <StandingsIcon />,
      lineups: <LineupsIcon />,
      market: <MarketIcon />,
      favorites: (
        <Badge
          badgeContent={
            marketSetConfig[selectedGame.sport_code].prematch["favorites"]
              ?.length
          }
          color="error"
        >
          <CheckedIcon />
        </Badge>
      ),
      // stream: <GameStreamIcon />,
    };
  }

  setLabels() {
    this.labels = {
      h2h: <Translate value={`menu.h2h`} />,
      summary: <Translate value={`menu.summary`} />,
      results: <Translate value={`menu.results`} />,
      statistics: <Translate value={`menu.statistics`} />,
      matchstatistics: <Translate value={`menu.matchstatistics`} />,
      standings: <Translate value={`menu.standings`} />,
      lineups: <Translate value={`menu.lineups`} />,
      // stream: <Translate value={`menu.stream`} />,
    };
  }

  // componentDidMount() {
  //   const { selectedGame, marketSetConfig } = this.props;
  //   this.setState({ menuIndex: _.findIndex(menuConfig[selectedGame.sport_code].sidebets, item => { return item === !marketSetConfig[selectedGame.sport_code].prematch['favorites']?.length ? 'mainbets' : 'mainbets'; }) });
  //   console.log('mount');
  // }

  menuChange = (event, newMenuIndex) => {
    this.setState({ menuIndex: newMenuIndex });
  };

  menuChangeIndex(newMenuIndex) {
    this.setState({ menuIndex: newMenuIndex });
  }

  renderMenuContent(menuValue) {
    const { selectedGame, marketSetConfig, isSideBetOpen, filteredTabs } =
      this.props;
    let menuIndex = _.findIndex(filteredTabs, (item) => {
      return item === menuValue;
    });

    if (menuIndex !== this.state.menuIndex) return <div key={menuIndex}></div>;

    switch (menuValue) {
      case "h2h":
        return (
          <div key="h2h">
            <HeadToHead gameId={selectedGame.gameid} />
          </div>
        );
      case "betbuilder":
        return (
          <div key="betbuilder">
            <BetbuilderWidget game={selectedGame} />
          </div>
        );

      case "summary":
        return (
          <div key="summary">
            <VerticalTimeline gameId={selectedGame.gameid} />
          </div>
        );

      case "statistics":
        return (
          <div key="statistics">
            <GeneralStatistics gameId={selectedGame.gameid} />
          </div>
        );

      case "matchstatistics":
        return (
          <div key="statistics">
            <MatchStatistics gameId={selectedGame.gameid} />
          </div>
        );

      case "standings":
        return (
          <div key="standings">
            <LiveTable gameId={selectedGame.gameid} />
          </div>
        );

      case "lineups":
        return (
          <div key="lineups">
            <Lineups gameId={selectedGame.gameid} />
          </div>
        );
      case "goalscorer":
        return (
          <div key="goalscorer">
            <GoalScorerMarket
              gameId={selectedGame.gameid}
              leagueId={selectedGame.leagueID}
              currentMarketSetKey="goalscorer"
            />
          </div>
        );
      case "player":
        return (
          <div key="player">
            <PlayerProps
              game={selectedGame}
              gameId={selectedGame.gameid}
              leagueId={selectedGame.leagueID}
              currentMarketSetKey="player"
            />
          </div>
        );

      // case 'stream':
      //   return <div key='stream'><Stream gameId={selectedGame.gameid} /></div>

      default:
        // console.log(menuValue);
        let currentMarketSetValue =
          marketSetConfig[selectedGame.sport_code][
            menuValue === "favorites" ? "prematch" : "sidebets"
          ][menuValue]; //<<<---Getting favorities data from  prematch and rest from sidebets
        const marketSetValue = currentMarketSetValue?.includes(",")
          ? currentMarketSetValue?.split(",")
          : currentMarketSetValue;
        const selectedOdds = selectedGame.odds?.filter(function (odd) {
          return marketSetValue?.includes(odd.m);
        });
        // console.log(selectedOdds);
        return (
          <div key="odds">
            <Odds
              isSideBetOpen={isSideBetOpen}
              game={selectedGame}
              odds={selectedOdds}
              marketSetKey={menuValue}
              mode="detailed"
              from="gameModalViewer"
            />
          </div>
        );
    }
  }

  renderMenu() {
    log(this.constructor.name, "render");
    const { selectedGame, marketSetConfig, classes, filteredTabs } = this.props;
    // console.log(filteredTabs);

    return (
      <div className={classes.rootTop}>
        <AppBar
          elevation={0}
          position="static"
          style={{ backgroundColor: "var(--bg-tabs-2)" }}
        >
          <Tabs
            value={this.state.menuIndex}
            variant="scrollable"
            textColor="primary"
            indicatorColor="primary"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
            onChange={this.menuChange}
          >
            {filteredTabs.map((key, index) => {
              return (
                <Tab
                  key={index}
                  value={index}
                  style={{
                    display:
                      key === "summary" && !selectedGame.isLive
                        ? "none"
                        : key === "matchstatistics" && !selectedGame.isLive
                        ? "none"
                        : null,
                  }}
                  label={
                    <span className={classes.tabLabel}>
                      {this.labels[key] ? (
                        this.labels[key]
                      ) : (
                        <Translate value={`marketSets.${key}`} />
                      )}
                    </span>
                  }
                  // style={{ padding: '0 0.5em', minWidth: '4em' }}
                  // icon={this.icons[key] ? this.icons[key] : this.icons.market}
                  // icon={mode === 'standard' ? (this.icons[key] ? this.icons[key] : this.icons.market) : null}

                  {...a11yProps(index)}
                />
              );
            })}
          </Tabs>
        </AppBar>
      </div>
    );
  }

  render() {
    log(this.constructor.name, "render");
    const { selectedGame, classes, filteredTabs } = this.props;

    this.setIcons();
    this.setLabels();

    return (
      <div className={classes.root}>
        <div>{this.renderMenu()}</div>
        <SwipeableViews
          axis={"x"}
          index={this.state.menuIndex}
          onChangeIndex={this.menuChangeIndex.bind(this)}
        >
          {filteredTabs.map((key) => {
            return this.renderMenuContent(key);
          })}
        </SwipeableViews>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const selectedGame = ownProps.game[0];
  const marketSetConfig = state.settings.marketSets;
  // console.log(selectedGame);

  const filteredTabs = menuConfig[selectedGame.sport_code].sidebets.filter(
    (config, key) => {
      const marketSets =
        marketSetConfig[selectedGame.sport_code].sidebets[config];
      const odds = selectedGame?.odds?.filter(
        (oddD) =>
          (marketSets?.includes(oddD?.m) && oddD?.iA === 1) ||
          (marketSets?.includes(oddD.m) && oddD?.iA?.toString()?.includes("1"))
      );
      if (odds?.length === 0 && marketSets?.length && config !== "favorites") {
        return false;
      }
      if (odds?.length === 0 && config === "player" && config !== "favorites") {
        return false;
      }
      if (
        config === "favorites" &&
        !marketSetConfig[selectedGame.sport_code].prematch["favorites"]?.length
      ) {
        return false;
      }
      if (config === "betbuilder" && !selectedGame?.isBetBuilderOn) {
        return false;
      }
      return true;
    }
  );

  return {
    content: ownProps.content,
    selectedGame: selectedGame,
    marketSetConfig: marketSetConfig,
    mode: state.settings.mode,
    isSideBetOpen: ownProps?.isSideBetOpen,
    filteredTabs: filteredTabs,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(GameTabs));
