// Player props catalog  Multi odds list
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import Iconify from "../../iconify";
import { useDispatch, useSelector } from "react-redux";
import {
  checkSelectedOdd,
  formatPlayerPropsOdds,
  getSelectionLabel,
} from "../../../helpers/odds-factory";
import OddsFormat from "../../OddsFormat";
import { saveBet, toggleMyTicketsPanel } from "../../../actions";
import { useEffect, useState } from "react";

const StyledContainer = styled(Box)(() => ({
  "&:hover": {
    background: "var(--bg-marketline-hover)",
  },
}));

const CatalogMultiOdds = ({ marketKey, odds, game, autoOpenFirst }) => {
  const [isSelected, setSelected] = useState(false);
  const [expanded, setExpanded] = useState(false);
  // redux dispatcher
  const dispatch = useDispatch();
  // get market and selection labels
  const marketViewerLabels = useSelector(
    (state) => state.labels?.market?.viewer
  );
  const selectionPrintLabels = useSelector(
    (state) => state.labels?.selection?.print
  );
  // get slip template
  const selectedSlipTemplate = useSelector(
    (state) => state.selected.slipTemplate
  );
  const bets = useSelector(
    (state) => state.slips[selectedSlipTemplate].settings.bets
  );
  // format market labels
  const formatMarketViewerLabels = (marketLabel) => {
    marketLabel = marketLabel?.replace("{%player}", "")?.trim();
    marketLabel = marketLabel?.replace(/^./, marketLabel[0].toUpperCase());
    return marketLabel;
  };
  // format the odds for single player odds
  const formattedOdds = formatPlayerPropsOdds(odds);

  /**
   * Select odd for bet
   */

  function addBet(odd, index) {
    if (deleteBet(odd, index) === true) return false;
    let payload = {
      gameId: game.gameid,
      isLive: game.isLive,
    };

    const marketId = odd.m;
    const selectionIds = odd.s.split(",");
    const prices = odd.p.split(",");
    const hc = odd.hc;
    const shc = odd?.shc;
    const isActives = odd.iA.split(",");

    if (selectionIds.length < 3 && selectionIds.length > 1) {
      selectionIds.unshift(null);
      prices.unshift(null);
      isActives.unshift(null);
    } else if (selectionIds.length === 1) {
      selectionIds.push(null);
      prices.push(null);
      isActives.push(null);
      selectionIds.push(null);
      prices.push(null);
      isActives.push(null);
    }

    if (Number(prices[index]) < 1) return false;
    if (Number(prices[index]) === 1) return false;
    if (Number(isActives[index]) === 0) return false;

    payload.marketId = Number(marketId);
    payload.selectionId = Number(selectionIds[index]);
    payload.price = Number(prices[index]);
    payload.hc = hc;
    payload.sn = odd?.sn;
    payload.isPlayerProps = true;
    payload.isSinglePlayerProps = true;
    payload.isActive = Number(isActives[index]);
    payload.game = { ...game, isOutright: true };
    if (shc) {
      payload.shc = shc;
    }
    dispatch(toggleMyTicketsPanel("Catalog Odds", true));
    dispatch(saveBet(payload, selectedSlipTemplate, "Catalog Odds"));
    setSelected(!isSelected);
    return Object.keys(payload).length === 0 ? false : true;
  }

  /**
   * Deselect odd from bet
   */

  function deleteBet(odd, index) {
    const marketId = odd.m;
    const selectionIds = odd.s.split(",");

    if (selectionIds.length < 3) {
      selectionIds.unshift(null);
    }

    let payload = {};
    payload = Object.values(bets).filter(function (bet) {
      return (
        bet.gameId === game.gameid &&
        bet.marketId === Number(marketId) &&
        bet.selectionId === Number(selectionIds[index]) &&
        bet.hc === odd.hc &&
        bet.shc === odd.shc
      );
    });

    if (payload.length) {
      dispatch(deleteBet(payload[0], "Catalog Odds"));
      setSelected(!isSelected);
      return Object.keys(payload[0]).length === 0 ? false : true;
    } else {
      return false;
    }
  }

  const handleExpanded = () => {
    setExpanded(!expanded);
  };



  return (
    <Accordion expanded={expanded} onChange={handleExpanded}>
      <AccordionSummary
        expandIcon={<Iconify icon="ic:outline-expand-more" />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <Typography>
          {marketViewerLabels &&
            marketViewerLabels[marketKey] &&
            formatMarketViewerLabels(marketViewerLabels[marketKey])}
        </Typography>
      </AccordionSummary>

      {expanded && (
        <AccordionDetails>
          {formattedOdds.map((marketOdds, key) => {
            return (
              <StyledContainer key={key} my={0.5}>
                <Grid container>
                  <Grid item xs={3} md={5}>
                    <Box display="flex" alignItems="center" height="100%">
                      <Typography pr={1}>
                        {marketOdds && marketOdds[0]
                          ? marketOdds[0]["sn"]
                          : null}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid xs={9} md={7} item container>
                    {marketOdds?.map((odd, indx) => {
                      const hc = odd.hc ? odd.hc?.split("=") : [""];
                      const isSelected = checkSelectedOdd(
                        Object.values(bets),
                        game,
                        odd,
                        0
                      );

                      return (
                        <Grid item xs={6} key={indx}>
                          <Stack direction="row" alignItems="center">
                            {indx === 0 && (
                              <span>
                                {hc && odd?.m !== 1234
                                  ? hc[hc?.length - 1]
                                  : null}
                              </span>
                            )}
                            <div
                              onClick={() => addBet(odd, 0)}
                              className={`${
                                isSelected ? "selected-odds" : ""
                              }  content-center vertical-center cursor-pointer font-xl font-bold-2 odds-btn-outright border-odds-right border-odds-left `}
                              style={{
                                margin: "0.15em 0.3em",
                                padding: "8px 10px",
                                width: "100%",
                                color:
                                  odd.iA != "1" ? "var(--grey-5)" : "inherit",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  width: "100%",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                }}
                              >
                                <div
                                  className="flex-end spacer-right font-s"
                                  style={{ fontSize: "0.7em", fontWeight: 400 }}
                                >
                                  {getSelectionLabel(
                                    selectionPrintLabels,
                                    odd?.s
                                  )}
                                </div>
                                <div
                                  className="price-border spacer-right"
                                  style={{
                                    color: isSelected
                                      ? "inherit"
                                      : "var(--color-odds)",
                                  }}
                                >
                                  <OddsFormat
                                    price={odd.p}
                                    isActive={odd.iA == "1"}
                                  />
                                </div>
                              </div>
                            </div>
                          </Stack>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Grid>
              </StyledContainer>
            );
          })}
        </AccordionDetails>
      )}
    </Accordion>
  );
};

export default CatalogMultiOdds;
