import { makeStyles } from "@mui/styles";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  deleteSearchResults,
  deleteSelectedLeagues,
  setEventSearchResult,
} from "../../../actions";
import { useEffect, useRef } from "react";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import InputBase from "@mui/material/InputBase";
import {
  Box,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
} from "@mui/material";
import Iconify from "../../iconify";

const useStyles = makeStyles(() => ({
  search: {
    borderRadius: 0,
    marginLeft: 0,
    width: "100%",
  },
  iconSearch: {
    padding: 0,
    color: "var(--color-contrast)",
    fontSize: "0.8em",
  },
  buttonSearch: {
    padding: "0",
  },
  input: {
    borderRadius: "0.2em",
    flex: 1,
    backgroundColor: "var(--bg-search-input)",
    padding: "0.5em 0 0.5em 1em",
    width: "100%",
    fontSize: "1em",
    "@media (hover:none)": {
      borderRadius: "0.25em",
      flex: 1,
      padding: "0.5em 1em",
      width: "100%",
      fontSize: "1em",
    },
    "@media (hover:none) and (orientation:landscape)": {
      fontSize: "1.5em",
      height: "3em",
    },
  },
}));

const SearchBox = () => {
  const classes = useStyles();
  // Redux action dispatcher
  const dispatch = useDispatch();
  // router props
  const location = useLocation();
  const navigate = useNavigate();
  // data selectors
  const locale = useSelector((state) => state.i18n.locale);
  const layout = useSelector((state) => state.settings.layout);
  const translate = useSelector(
    (state) => state.i18n.translations[state.i18n.locale]
  );
  const query = new URLSearchParams(location.search);
  const searchValue = query.get("searchQuery");
  const actionType = query.get("action");

  const handleSearch = () => {
    if (searchValue && searchValue?.length >= 2) {
      dispatch(setEventSearchResult(searchValue, locale, "SearchEvents"));
    }
  };

  const handleActions = () => {
    if (actionType === "reset") {
      dispatch(deleteSelectedLeagues(null, false, "SearchEvents"));
      dispatch(deleteSearchResults(searchValue, locale, "SearchEvents"));
    }
  };

  useEffect(handleSearch, [location.search]);
  useEffect(handleActions, [location.pathname, location.search]);

  const hanldeChange = (e) => {
    navigate(`/${locale}/main/search?searchQuery=${e.target.value}`);
  };

  const handleClear = () => {
    navigate(`/${locale}`);
  };

  return (
    <Container maxWidth="xl">
      <Grid container>
        <Grid item xs={layout === "mouse" ? 8 : 12}>
          <Stack direction="row" pb={{ xs: 1, md: 0 }}>
            <Link to={"/"}>
              <IconButton>
                <Iconify icon="ic:outline-arrow-back" />
              </IconButton>
            </Link>
          </Stack>
        </Grid>
        <Grid item xs={layout === "mouse" ? 4 : 12}>
          <InputBase
            className={classes.input}
            placeholder={translate.labels.search}
            color="primary"
            onFocus={(e) => (e.target.placeholder = "")}
            value={searchValue}
            inputProps={{ "aria-label": "suche" }}
            onChange={hanldeChange}
            autoFocus
            onBlur={(e) => (e.target.placeholder = "Search")}
            endAdornment={
              <InputAdornment position="start">
                {searchValue && (
                  <IconButton
                    component={"span"}
                    className={classes.buttonSearch}
                    type="submit"
                    aria-label="search"
                    onClick={handleClear}
                  >
                    <CloseIcon className={classes.iconSearch} />
                  </IconButton>
                )}
              </InputAdornment>
            }
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon className={classes.iconSearch} />
              </InputAdornment>
            }
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default SearchBox;
