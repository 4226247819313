import "./style.css";
import React from "react";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import {
  toggleMyTicketsPanel,
  saveBet,
  deleteBet,
  emptySlipTemplate,
} from "../../../actions";
import { connect } from "react-redux";
import { icons } from "../../events/SportsTabs";
import OddsFormat from "../../OddsFormat";
import { createEmptyTemplate } from "../../../reducers/slipsReducer";
import { createUuid } from "../../../helpers/utilities";
import { calculateSlip } from "../../../helpers/slipCalculatorService";
import withRouter from "../../../helpers/withRouter";

const createBet = (odd, game) => {
  let payload = {
    gameId: game.gameid,
    isLive: game.isLive,
  };
  const marketId = Number(odd.m);
  const selectionId = Number(odd.s);
  const price = Number(odd.p);
  const hc = odd.hc;
  const shc = odd.shc;
  const isActive = Number(odd.iA);

  payload.marketId = marketId;
  payload.selectionId = selectionId;
  payload.price = price;
  payload.isPlayerProps = hc.includes("player");
  payload.isSinglePlayerProps =
    hc.includes("player") && !hc.includes("playerprops");
  payload.hc = hc;
  payload.shc = shc;
  payload.hn = odd.hn;
  payload.sn = odd.sn;
  payload.nr = odd.nr;
  payload.isActive = isActive;
  payload.isBank = 0;
  payload.isLive = 0;
  payload.game = {
    ...game,
    score_set_calculated: {
      away: [],
      home: [],
    },
  };
  return payload;
};

class FeaturedSliptCard extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      betAdded: false,
    };
  }

  getHCLabel = (hc, odd) => {
    let hcstr = [];
    if (!hc) return "";
    if (hc.includes(":")) {
      hcstr = hc.split(":");
    }
    if (hc.includes("=") && hc.includes(":")) {
      hcstr = hc.split(":");
    }
    if (hc.includes("=") && hc.includes(":") && hc.includes("|")) {
      hcstr = hc.split("=");
    }

    if (odd.hc.includes("playerprops")) {
      return odd.sn + " " + hcstr[hcstr?.length - 1] + "+";
    }

    const { goalscorerMarkets } = this.props;
    if (goalscorerMarkets?.includes(odd?.m)) {
      return odd?.sn;
    }
    return hcstr[hcstr?.length - 1];
  };

  getSelectionLabel = (id, game, odd) => {
    let selectionLabel = "";
    const { selectionPrintLabels } = this.props;
    if (selectionPrintLabels && selectionPrintLabels[id]) {
      if (selectionPrintLabels[id].indexOf("{$competitor1}") > -1) {
        selectionLabel = selectionPrintLabels[id].replace(
          "{$competitor1}",
          game.team1
        );
      } else if (selectionPrintLabels[id].indexOf("{$competitor2}") > -1) {
        selectionLabel = selectionPrintLabels[id].replace(
          "{$competitor2}",
          game.team2
        );
      } else {
        selectionLabel = selectionPrintLabels[id];
      }
    }
    if (odd?.hc?.includes("player")) {
      selectionLabel = selectionLabel + this.getHCLabel(odd.hc, odd);
    }
    if (odd?.hc?.includes("playerprops")) {
      selectionLabel = this.getHCLabel(odd.hc, odd);
    }
    if (
      game?.game_title ||
      odd.shc === "prematch" ||
      odd?.hc?.includes("goalnr")
    ) {
      selectionLabel = odd?.sn;
    }
    if (selectionLabel.toString().trim() === "X") {
      selectionLabel = "Draw";
    }
    return selectionLabel;
  };

  getCombiTitle = (indx) => {
    const { slipKey, translation } = this.props;
    let title = translation.labels.daily_slip + " ";
    let keyParts = slipKey.split(" ");
    switch (slipKey) {
      case "Kombinatsioon 2-st":
      case "Combi of 2":
      case "Combi-2 Tupla":
        title = title + translation.labels.daily_slip_double;
        break;
      case "Kombinatsioon 3-st":
      case "Combi of 3":
      case "Combi-3 Tripla":
        title = title + translation.labels.daily_slip_treble;
        break;
      default:
        title =
          title +
          keyParts[keyParts?.length - 1]?.split("-")[0] +
          " " +
          translation.labels.daily_slip_fold;
        break;
    }

    title = title + " " + `#${indx}`;
    return title;
  };

  addBet(payload) {
    const { selectedSlipTemplate } = this.props;

    this.props.toggleMyTicketsPanel(this.constructor.name, true);
    this.props.saveBet(payload, selectedSlipTemplate, this.constructor.name);

    return Object.keys(payload)?.length === 0 ? false : true;
  }

  addBetsToBetslip = () => {
    const { navigate, lang, isMobile, createdBets, selectedSlipTemplate } =
      this.props;
    this.props.emptySlipTemplate(selectedSlipTemplate, this.constructor.name);
    createdBets?.forEach((each) => {
      this.addBet(each, 0);
    });
    if (isMobile) {
      navigate(`/${lang}/slip`);
    }
  };

  checkSelectedOdd(odd, game) {
    const { bets } = this.props;
    let payload = {
      gameId: game.gameid,
    };

    payload.marketId = odd.m;
    payload.selectionId = odd.s;
    payload.price = odd.p;
    payload.hc = odd.hc;

    let flag = false;

    if (!bets) return flag;

    for (let i = 0; i < bets?.length; i++) {
      let bet = bets[i];
      if (
        bet.gameId === payload.gameId &&
        bet.marketId === payload.marketId &&
        bet.selectionId === payload.selectionId &&
        bet.hc === payload.hc
      ) {
        flag = true;
        break;
      }
    }

    return flag;
  }

  // get market labels

  getMarketLabels = (marketViewerLabels, odd) => {
    let label =
      marketViewerLabels && odd && odd?.m && marketViewerLabels[odd.m]
        ? marketViewerLabels[odd.m]
        : null;
    if (label?.includes("{%player}")) {
      label = label?.replace("{%player}", odd?.sn);
    }
    // if (label.includes("1X2")) return "";

    if (label?.includes("{!goalnr}")) {
      const lbl = odd?.shc?.split("|")[0];
      label = label?.replace("{!goalnr}", `${lbl}.`);
    }

    return label;
  };

  render() {
    const { allGames, marketViewerLabels, slectedSlip, oddsFormat, booster } =
      this.props;
    // console.log(slectedSlip);
    const maxOdds = slectedSlip.settings.maxOdds;
    const boostedPrice =
      (Number(booster?.percent_bonus) * Number(slectedSlip.settings.winMax-slectedSlip?.stake) + Number(slectedSlip.settings.winMax))/slectedSlip?.stake;

    return (
      <div className="featured-slip-container">
        <div className="featured-slip-selections-container">
          {/* selection card start */}
          <div className="featured-banner-title slip-card-title">
            {this.getCombiTitle(this.props.order)}
          </div>
          {allGames?.map((game, indx) => {
            const odd = game && game?.odds ? game?.odds[0] : null;
            const marketLabel = this.getMarketLabels(marketViewerLabels, odd);
            return (
              <div key={indx}>
                <div className="featured-slip-selection">
                  <div>{icons[game?.sport_code]}</div>

                  <div>
                    <img
                      style={{ margin: "0 0.5em 0 0" }}
                      src={`https://api.nubet.com/media/flags/${game?.countryCode}.png`}
                      alt="flag"
                    />
                  </div>
                  <div>
                    {!game?.isOutright ? (
                      <p className="featured-slip-selection-game-name">
                        {game?.team1} - {game.team2}
                      </p>
                    ) : (
                      <p className="featured-slip-selection-game-name">
                        {game?.game_title}
                      </p>
                    )}
                  </div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div>
                    <span className="font-bold-2">
                      {marketLabel}
                    </span>
                    <span className="spacer-left color-odds font-bold-2">
                      {this.getSelectionLabel(
                        odd?.s && odd?.s?.toString()?.split(",")[0]
                          ? odd.s?.toString()?.split(",")[0]
                          : null,
                        game,
                        odd
                      )}
                    </span>
                  </div>
                  <div>
                    {game?.isOutright ? (
                      <div
                        className={`${
                          odd?.priceChange === ">"
                            ? "odds-raise"
                            : odd?.priceChange === "<"
                            ? "odds-drop"
                            : odd?.priceChange === "="
                            ? ""
                            : "color-odds"
                        }   featured-slip-outright-odd`}
                      >
                        {odd?.p}
                      </div>
                    ) : (
                      <div
                        className={`${
                          odd?.priceChanges?.includes(">")
                            ? "odds-raise"
                            : odd?.priceChanges?.includes("<")
                            ? "odds-drop"
                            : odd?.priceChanges?.includes("=")
                            ? ""
                            : "color-odds"
                        }   featured-slip-odd`}
                      >
                        <span className="color-odds font-xl font-bold-2">
                          <OddsFormat
                            price={
                              odd?.p && odd?.p?.split(",")[0]
                                ? odd.p?.split(",")[0]
                                : null
                            }
                            isActive={
                              odd?.iA && odd?.iA?.split(",")[0]
                                ? odd?.iA?.split(",")[0]
                                : null
                            }
                          />
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="total-odds-container">
          <div
            className="total-odds-sub-container cursor-pointer"
            onClick={this.addBetsToBetslip}
          >
            <div className="featured-slip-total-odds-container">
              <div className={`color-odds total-odds-odd prev-odd ${booster?.percent_bonus&&"line-through"}`}>
                {oddsFormat === "decimal" ? (
                  maxOdds?.toFixed(2)
                ) : (
                  <OddsFormat price={maxOdds} isActive="1" />
                )}
              </div>
            </div>
            {booster?.percent_bonus ? (
              <div style={{ margin: "0 8px" }}>
                <DoubleArrowIcon
                  fontSize="small"
                  style={{ fontSize: "1.4em", color: "var(--color-odds)" }}
                />
              </div>
            ) : null}
            <div className="featured-slip-total-odds-container">
              {booster?.percent_bonus ? (
                <div className="color-odds total-odds-odd">
                  {oddsFormat === "decimal" ? (
                    boostedPrice?.toFixed(2)
                  ) : (
                    <OddsFormat price={boostedPrice} isActive="1" />
                  )}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  // Get games
  const games = ownProps?.data?.games;
  const outrights = ownProps?.data?.outrights;
  const slipKey = ownProps.data?.slipKey;
  // create slip template for odds calculations
  let slipTemplate = {
    [state.selected.slipTemplate]: { ...createEmptyTemplate(0) },
  };
  slipTemplate[state.selected.slipTemplate]["betType"] = "combi";
  // collect all games in the list
  let allGames = [];
  // make bets list from games and odds
  let betsList = [];

  if (games) {
    Object.values(games)?.forEach((gameObj) => {
      Object.values(gameObj)?.forEach((gameSelected) => {
        allGames.push(gameSelected);
      });
    });
  }
  if (outrights) {
    Object.values(outrights)?.forEach((gameObj) => {
      Object.values(gameObj)?.forEach((gameSelected) => {
        allGames.push({ isOutright: true, ...gameSelected });
      });
    });
  }
  // create bet from each game and save it as slip template
  if (allGames?.length) {
    allGames?.forEach((game) => {
      const odd = game && game?.odds ? game?.odds[0] : null;
      let bet = createBet(odd, game);
      const uid = createUuid();
      if (Boolean(game?.game_title)) {
        bet = {
          ...bet,
          isOutright: true,
          game: { ...bet.game, isOutright: true },
        };
      }
      if (odd?.hc?.includes("goalnr") || (odd?.sn && odd?.shc === "prematch")) {
        bet = {
          ...bet,
          game: { ...bet.game, isOutright: true },
        };
      }
      slipTemplate[state.selected.slipTemplate].settings.bets[uid] = bet;
      betsList.push(bet);
    });
  }
  //calculate the slip using slip calculator service to get the odds
  const calculatedSlip = calculateSlip(slipTemplate);

  const slip_key_nr = slipKey?.replace(/\D/g, '');
  const boosters = state.comboboost?.bonuses ? state.comboboost?.bonuses : [];
  let selectedBooster = {};
  let shouldBoost = true;
  betsList.forEach((bt) => {
    if (!state.comboboost?.includedMarkets?.includes(String(bt?.marketId))||!Number(bt?.price)>=Number(state.comboboost?.minOdds)) {
      shouldBoost = false;
    }
  });


  if (shouldBoost) {
    selectedBooster = boosters.find(
      (bt) => bt?.bonus_type === Number(slip_key_nr)
    );
  }

  return {
    allGames: allGames,
    oddsFormat: state.settings.oddsFormat,
    slectedSlip: calculatedSlip[state.selected.slipTemplate],
    createdBets: betsList,
    slipType: ownProps?.data?.slipKey,
    lang: state.i18n.locale,
    marketViewerLabels: state.labels.market ? state.labels.market.viewer : null,
    selectionPrintLabels: state.labels.selection
      ? state.labels.selection.print
      : null,
    selectedSlipTemplate: state.selected.slipTemplate,
    bets:
      state.slips && state.slips[state.selected.slipTemplate].settings?.bets
        ? Object.values(state.slips[state.selected.slipTemplate].settings.bets)
        : [],
    goalscorerMarkets:
      state.settings.marketSets["FB"]["sidebets"]["goalscorer"],
    translation: state.i18n.translations[state.i18n.locale],
    isMobile: state.settings.isMobile,
    booster: selectedBooster,
    slipKey,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    saveBet,
    toggleMyTicketsPanel,
    deleteBet,
    emptySlipTemplate,
  })(FeaturedSliptCard)
);
