/**
 * Show all games list
 * For Odds and Columns view modes
 */

import { log } from "../../config/log";
import React from "react";
import { connect } from "react-redux";
import GameBox from "./GameBox";
import Odds from "./Odds";
import sortGamesByTime from "../../helpers/gameChronologicalsort";

import "./GamesList.css";

class GamesList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  UNSAFE_componentWillMount() {
    this.domReferences = {};
  }

  componentWillUnmount() {
    this.domReferences = {};
  }

  // shouldComponentUpdate(nextProps, nextState) {
  //   const { leagueId, leagues, currentMarketSetKey, currentMarketSetValue } = this.props;
  //   var flag = true;

  //   if ((leagues[leagueId]['games']) && (leagues[leagueId]['games']?.length ===< nextProps.leagues[leagueId]['games']?.length)) {
  //     flag = false;
  //   }

  //   if (currentMarketSetValue !== nextProps.currentMarketSetValue) {
  //     flag = true;
  //   }

  //   if (currentMarketSetKey !== nextProps.currentMarketSetKey) {
  //     flag = true;
  //   }

  //   return flag;
  // }

  // isInViewport(element) {
  //   const rect = element.getBoundingClientRect();
  //   return (
  //       rect.top >= 0 &&
  //       rect.left >= 0 &&
  //       rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
  //       rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  //   );
  // }

  isInViewport(element) {
    const rect = element.getBoundingClientRect();
    return (
      (rect.top >= 150 &&
        rect.left >= 0 &&
        rect.top <=
          (window.innerHeight - 66 ||
            document.documentElement.clientHeight - 66) &&
        rect.left <=
          (window.innerWidth || document.documentElement.clientWidth)) ||
      (rect.bottom >= 150 &&
        rect.right >= 0 &&
        rect.bottom <=
          (window.innerHeight - 66 ||
            document.documentElement.clientHeight - 66) &&
        rect.right <=
          (window.innerWidth || document.documentElement.clientWidth))
    );
  }

  saveRefs(key, ref) {
    this.domReferences[key] = ref;
  }

  renderGameList() {
    const {
      leagues,
      searchQuery,
      leagueId,
      currentMarketSetKey,
      leagueFilter,
      currentMarketSetValue,
      marketsLayout,
    } = this.props;
    const games = Object.values(leagues[leagueId]["games"]);
    const marketSetValue = currentMarketSetValue.includes(",")
      ? currentMarketSetValue?.split(",")
      : currentMarketSetValue;
    let availableGames = [];
    if (leagueFilter === "live") {
      games?.forEach((game) => {
        if (game.isLive === 1 && game.state != "FT") {
          availableGames.push(game);
        }
      });
    } else {
      availableGames = games;
    }
    // availableGames = games;
    if (availableGames?.length === 0) return null;

    return sortGamesByTime(availableGames, leagueFilter).map((game, key) => {
      const selectedOdds = game.odds?.filter(function (odd) {
        return marketSetValue.includes(odd.m);
      });

      let isInViewport = true;
      if (this.domReferences[key])
        isInViewport = this.isInViewport(this.domReferences[key]);
      return (
        <div
          key={key}
          ref={(ref) => {
            this.saveRefs(key, ref);
          }}
          className="games-list-box"
        >
          <GameBox
            searchQuery={searchQuery}
            game={[game]}
            gameStaticId={this.props.gameStaticId}
            disableTab="favorites"
            content="selectedGames"
            isInViewport={isInViewport}
            odds={selectedOdds}
            marketSetKey={currentMarketSetKey}
            gameIndex={key}
          />
          {marketsLayout === "vertical" ||
          currentMarketSetKey === "favorites" ? (
            <Odds
              searchQuery={searchQuery}
              gameAllOdds={game?.odds}
              game={game}
              odds={selectedOdds}
              marketSetKey={currentMarketSetKey}
              isInViewport={isInViewport}
            />
          ) : null}
        </div>
      );
    });
  }

  render() {
    log(this.constructor.name, "render");
    const { leagueId, leagues } = this.props;
    if (!leagues[leagueId]) return <div></div>;
    return <div>{this.renderGameList()}</div>;
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    leagueId: ownProps.leagueId,
    leagues: state.selected.leagues ? state.selected.leagues : {},
    selected: state.selected,
    leagueFilter: state.leagues._leagueFilter,
    currentMarketSetValue: ownProps.currentMarketSetValue,
    currentMarketSetKey: ownProps.currentMarketSetKey,
    marketsLayout: state.settings.marketsLayout,
    gameStaticId: ownProps.gameStaticId,
    searchQuery: ownProps?.searchQuery,
  };
};

export default connect(mapStateToProps, {})(GamesList);
