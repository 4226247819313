/**
 * SVG component for own icon 
 */

import React from 'react';
import './IconOwn.css';

const IconTT = (props) => {

	return (
		<div style={{ display: "flex" }}>
			<svg className="icon-own" viewBox="0 0 2000 2000">
				<linearGradient gradientTransform="rotate(90)" id="icongradient">
					<stop className="icon-color-1" offset="0%" />
					<stop className="icon-color-2" offset="50%" />
					<stop className="icon-color-1" offset="100%" />
				</linearGradient>
				<g><path fill="url(#icongradient)" d="M1859.2,484.2C1796,325.1,1670,199.1,1510.9,135.8c-76.5-30.1-158.1-45-240.3-44c-82.9,1-164.8,17.9-241.2,49.9
		c-49.9,20.9-97.1,48-140.4,80.5c-52-14.2-105.6-21.8-159.6-22.7c-82.2-1.2-163.8,13.7-240.3,43.9
		c-159.2,63.1-285.3,189.2-348.4,348.4c-30.2,76.5-45.1,158.1-43.9,240.3c1,82.9,17.9,164.7,49.8,241.2
		c33.1,79.7,81.7,152.1,142.8,213.1c34.8,34.6,73.3,65.1,114.8,91.2l-173.4,173.6c-17,16.9-26.5,39.9-26.5,63.9
		c0,24,9.5,47,26.5,63.9l85.2,85.2c16.9,17,39.9,26.5,63.9,26.5s47-9.5,63.9-26.5l285.4-285.4h2.8c6.8-0.1,13.6-1.4,20.1-3.7
		c2.2-0.2,4.5-0.6,6.6-1.3c130.9-54,236.5-66.2,332.4-38.5c30.3,8.8,59.3,21.6,86.2,38c1.3,13.8,7.3,26.7,17,36.5l361.9,361.9
		c35.3,35.3,92.4,35.3,127.7,0l85.1-85.1c16.9-16.9,26.4-39.9,26.4-63.9c0-24-9.5-46.9-26.4-63.9l-127.8-127.6l-191.9-191.9
		c15.8-5.1,31.8-10.9,47.7-17.5c160.8-67.4,288.7-195.3,356.1-356.2c32-76.5,49-158.4,50-241.3
		C1904.4,642.2,1889.5,560.6,1859.2,484.2z M732.2,1418.5l-0.2,0.2c-70.9-2.4-140.8-17.6-206.3-44.9
		c-146.2-61.2-262.4-177.5-323.6-323.7c-28.9-69.4-44.2-143.7-45.1-218.8c-1.1-74.4,12.4-148.3,39.7-217.4
		c56.9-143.7,170.8-257.5,314.5-314.4c66.9-26.4,138.1-39.9,210.1-39.7h7.4c75.2,0.9,149.4,16.2,218.8,45.1
		c146.1,61.2,262.2,177.4,323.4,323.5c27.4,65.5,42.5,135.5,44.7,206.4L869.7,1281l-0.3,0.2l-0.2,0.3L732.2,1418.5z M1107.7,1377.4
		c-82-21.8-168.3-21.5-250.2,0.9l418.1-418.1c-22.3,81.8-22.6,168.1-0.8,250.1c9.5,32.8,23,64.3,40.1,93.8l-113.5,113.5
		C1172,1400.4,1140.5,1386.9,1107.7,1377.4z M1843,723.7c-0.9,75.2-16.2,149.5-45.2,218.8c-61.3,146.1-177.6,262.3-323.7,323.6
		c-24.3,9.9-49.2,18.1-74.6,24.6c-8.5-6.1-18.5-9.8-28.9-10.8c-16.4-26.9-29.2-55.9-38-86.2c-27.7-95.8-15.4-201.4,38.5-332.3
		c0.6-2,1-4.1,1.2-6.2c2.7-7.2,4-14.8,3.8-22.5c-2.6-78.2-19.4-155.2-49.5-227.4c-67.3-160.9-195.2-288.8-356-356.1
		c-3.3-1.4-6.7-2.3-10-3.6c29.2-18.8,59.9-35,91.9-48.5c69.4-29,143.7-44.3,218.8-45.2c74.4-1.2,148.2,12.3,217.4,39.7
		c143.6,57.2,257.4,170.9,314.6,314.6C1830.6,575.5,1844.1,649.3,1843,723.7z"/>
					<path d="M1000,1536.9c-99.7,0.1-180.5,80.9-180.7,180.7c0,99.8,80.9,180.6,180.7,180.6c99.8,0,180.6-80.9,180.6-180.6
		C1180.6,1617.8,1099.8,1536.9,1000,1536.9z"/></g>
			</svg>
		</div>
	);
};

export default IconTT;
