import React from "react";
import { connect } from "react-redux";
import { getSingleGame } from "../../../actions";
import withStyles from "@mui/styles/withStyles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import Avatar from "@mui/material/Avatar";
import GamesList from "./GamesList";

const styles = (theme) => ({
  root: {
    displa: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  tabBars: {
    // position: "sticky",
    // top: "0",leagueFilter
    // zIndex: 998,
    position: "static",
    // "@media (hover:hover)": {
    //   position: "static",
    // },
  },
  paper: {
    margin: theme.spacing(1),
  },
  tabBarFavorites: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  avatarAll: {
    backgroundColor: "transparent",
    // color: "var(--grey-3)",
    color: "var(--color-contrast)",
    // border: "0.17em solid var(--grey-3)",
    borderRadius: "1em",
    width: "1.6em",
    height: "1.6em",
    fontSize: "0.94em",
    lineHeight: "inherit",
    fontWeight: "500",
  },
  container: {
    display: "flex",
  },
});

class LeagueRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
    };
  }

  handleCollapse = () => {
    this.setState({ checked: !this.state.checked });
  };

  componentDidMount() {
    if (this.props.indx === 0) {
      this.setState({ checked: true });
    }
  }

  render() {
    const { classes, league } = this.props;
    let gameLiveCount = league?.gameLiveCount;

    let leagueText;
    if (league.labelCategory !== "") {
      leagueText = (
        <span>
          <span style={{ marginRight: "2px" }}>
            <b>{league.labelCategory} </b>
          </span>
          {league.label}
        </span>
      );
    } else {
      leagueText = <b>{league.label}</b>;
    }

    return (
      <div>
        <div
          onClick={this.handleCollapse}
          className={
            this.state.checked
              ? "league-box-selected-betbuilder"
              : "league-box-betbuilder"
          }
          data-ripple="var(--color-ripple)"
        >
          <div
            id="league-country-flag"
            className="vertical-center content-center"
          >
            <img
              src={`https://api.nubet.com/media/flags/${(league.countryCode = ""
                ? "INT"
                : league.countryCode)}.png`}
              alt="flag"
            />
          </div>
          <div
            className="vertical-center font-xl font-bold"
            style={{ width: "max-content" }}
          >
            {leagueText}
          </div>
          <div className="vertical-center content-right">
            {gameLiveCount > 0 ? (
              <span>
                <Avatar className={classes.avatarLive}>{gameLiveCount}</Avatar>
              </span>
            ) : null}
            {/* <span className="spacer-left"><Avatar className={this.props.classes.avatarAll}>{league.gameCount}</Avatar></span> */}
            <span className={`spacer-left`}>
              <Avatar
                sx={{
                  backgroundColor: "transparent",
                  color: "var(--color-contrast)",
                  width: "2.1em",
                  height: "2.1em",
                  fontSize: "0.94em",
                  lineHeight: "inherit",
                  fontWeight: "500",
                  // backgroundImage: `url(${NowLiveIcon})`,
                  // backgroundRepeat: "no-repeat",
                  justifySelf: "center",
                  backgroundPosition: "center",
                  backgroundSize: "contain",
                }}
              >
                {league.gameCount}
              </Avatar>
            </span>
          </div>
          <div className="vertical-center content-center">
            {this.state.checked ? (
              <ExpandLessIcon style={{ color: "var(--grey-3)" }} />
            ) : (
              <ExpandMoreIcon style={{ color: "var(--grey-3)" }} />
            )}
          </div>
        </div>
        <div className={classes.container}>
          {this.state.checked && <GamesList league={league} />}
        </div>
        {/* <Divider /> */}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const sportCode = params.get("betBuilder&selectedBetBuilderSportCode");

  return {
    sportCode: sportCode,
    url: state.settings.url,
    league: ownProps.selectedLeague,
  };
};

export default connect(mapStateToProps, { getSingleGame })(
  withStyles(styles)(LeagueRow)
);
