import { Box, IconButton, styled } from "@mui/material";
import React from "react";
import Slider from "react-slick";
import Iconify from "../iconify";

const NextButton = ({ onClick }) => {
  return (
    <div className="slick-custom-next">
      <IconButton onClick={onClick}>
        <Iconify icon="jam:chevron-right" />
      </IconButton>
    </div>
  );
};

const PrevButton = ({ onClick }) => {
  return (
    <div className="slick-custom-prev">
      <IconButton onClick={onClick}>
        <Iconify icon="jam:chevron-left" />
      </IconButton>
    </div>
  );
};

const StyledContainer = styled(Box)(() => ({
  "& .slick-custom-next": {
    position: "absolute",
    top: "40%",
    right: -45,
  },
  "& .slick-custom-prev": {
    position: "absolute",
    top: "40%",
    left: -45,
  },
  "@media only screen and (max-width: 603px)": {
    width: "100%",
    "& .slick-custom-next": {
      right: -30,
    },
    "& .slick-custom-prev": {
      left: -30,
    },
  },
}));

const defaultSettings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: <NextButton />,
  prevArrow: <PrevButton />,
};

const SlickSlider = ({ children, customSettings = {} }) => {
  const settings = {
    ...defaultSettings,
    ...customSettings,
  };
  return (
    <StyledContainer>
      <Slider {...settings}>{children}</Slider>
    </StyledContainer>
  );
};

export default SlickSlider;
