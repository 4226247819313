import { useSelector } from "react-redux";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

import Leagues from "../components/events/Leagues";
import LeaguesTreeViewer from "../components/events/LeaguesTreeViewer";
import League from "../components/events/League";
import SignIn from "../components/account/SignIn";
import EmailConfirm from "../components/account/EmailConfirm";
import ResendConfirm from "../components/account/ResendConfirm";
import Statement from "../components/account/Statement";
import Settings from "../components/account/Settings";
import Payments from "../components/account/Payments";
import UserRegister from "../components/account/UserRegister";
import RegisterSwitch from "../components/account/RegisterSwitch";
import PasswordForgotten from "../components/account/PasswordForgotten";
import PasswordResetForm from "../components/account/PasswordResetForm";
import TrustlyRegister from "../components/account/TrustlyRegister";
import Help_DE from "../components/info/de/Help";
import AboutUs_EN from "../components/info/en/AboutUs";
import BettingRules_EN from "../components/info/en/BettingRules";
import BonusTerms_EN from "../components/info/en/BonusTerms";
import BonusTermsSlots_EN from "../components/info/en/BonusTermsSlots";
import Help_EN from "../components/info/en/Help";
import OutdatedBrowser_EN from "../components/info/en/OutdatedBrowser";
import PrivacyPolicy_EN from "../components/info/en/PrivacyPolicy";
import ResponsibleGame_EN from "../components/info/en/ResponsibleGame";
import SlotsRules_EN from "../components/info/en/SlotsRules";
import TermsConditions_EN from "../components/info/en/TermsConditions";
import Faq_EN from "../components/info/en/Faq";
import AboutUs_ET from "../components/info/et/AboutUs";
import BettingRules_ET from "../components/info/et/BettingRules";
import BonusTerms_ET from "../components/info/et/BonusTerms";
import BonusTermsSlots_ET from "../components/info/et/BonusTermsSlots";
import Help_ET from "../components/info/et/Help";
import PrivacyPolicy_ET from "../components/info/et/PrivacyPolicy";
import ResponsibleGame_ET from "../components/info/et/ResponsibleGame";
import SlotsRules_ET from "../components/info/et/SlotsRules";
import TermsConditions_ET from "../components/info/et/TermsConditions";
import OutdatedBrowser_ET from "../components/info/et/OutdatedBrowser";
import Faq_ET from "../components/info/et/Faq";
import AboutUs_FI from "../components/info/fi/AboutUs";
import BettingRules_FI from "../components/info/fi/BettingRules";
import BonusTerms_FI from "../components/info/fi/BonusTerms";
import BonusTermsSlots_FI from "../components/info/fi/BonusTermsSlots";
import Help_FI from "../components/info/fi/Help";
import PrivacyPolicy_FI from "../components/info/fi/PrivacyPolicy";
import ResponsibleGame_FI from "../components/info/fi/ResponsibleGame";
import SlotsRules_FI from "../components/info/fi/SlotsRules";
import TermsConditions_FI from "../components/info/fi/TermsConditions";
import OutdatedBrowser_FI from "../components/info/fi/OutdatedBrowser";
import Faq_FI from "../components/info/fi/Faq";
import StaticRoutes from "../components/events/StaticRoutes";
import TrustlyCases from "../components/TrustlyCases";
import TrustlyDepositWaiting from "../components/account/TrustlyDepositWaiting";
import SportsRegisterSwitch from "../components/account/SportsRegistration";
import CasinoRegisterSwitch from "../components/account/CasinoRegistration";
import CustomNotFoundComponent from "../components/404";
import WPCampaigns from "../components/wp-content/Campaigns";
import WPNews from "../components/wp-content/News";
// Main promotions
import Promotions_FI from "../components/info/fi/Promotions";
import Promotions_ET from "../components/info/et/Promotions";
import Promotions_EN from "../components/info/en/Promotions";
// free speens promottions
import PromotionsFreespins_FI from "../components/info/fi/PromotionsFreespins";
import PromotionsFreespins_ET from "../components/info/et/PromotionsFreespins";
import PromotionsFreespins_EN from "../components/info/en/PromotionsFreespins";
// betbuilder promotions
import PromotionsBetbuilder_FI from "../components/info/fi/PromotionsBetbuilder";
import PromotionsBetbuilder_ET from "../components/info/et/PromotionsBetbuilder";
import PromotionsBetbuilder_EN from "../components/info/en/PromotionsBetbuilder";
// Search events
import SearchEvents from "../components/events/events-search";
import CasinoMainPage from "../components/casino";
import CasinoMainView from "../components/casino/views/CasinoMainView";
import CasinoFilterView from "../components/casino/views/TabFilterView";
import CasinoProviderFilterView from "../components/casino/views/ProviderFilterView";
import FreeSpinsView from "../components/casino/views/FreeSpinsView";
import FavoriteGamesView from "../components/casino/views/FavoriteGamesView";
import { useEffect } from "react";
import BetbuilderPage from "../components/events/betbuilder-screen";
import BetBuilderLoaderPage from "../components/events/betbuilder-screen/BetBuilder";
import PlayerPropsCatalog from "../components/events/playerprops-catalog";

const DesktopRoutes = () => {
  const location = useLocation();
  const host = useSelector((state) => state.settings.host);
  const lang = useSelector((state) => state.i18n.locale);
  const navMode = useSelector((state) => state.settings.navMode);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <Routes>
      <Route path="/" element={<Navigate to={`/${lang}`} />} />
      <Route path="/:lang/main/search" element={<SearchEvents />} />
      <Route path="/:lang" exact element={host.bet ? navMode ? navMode === "list" ? <Leagues /> : <LeaguesTreeViewer /> : null : host.gaming ? <CasinoMainPage /> : null} />
      {host.promoPage && <Route path="/:lang/promotions" exact element={lang === "en" ? <Promotions_EN /> : lang === "et" ? <Promotions_ET /> : <Promotions_FI />} />}
      {host.promoPage && <Route path="/:lang/promotions-freespins" exact element={lang === "en" ? <PromotionsFreespins_EN /> : lang === "et" ? <PromotionsFreespins_ET /> : <PromotionsFreespins_FI />} />}
      {/* Betbuilder promotions start */}
      {host.promoPage && <Route path="/:lang/promotions-betbuilder" exact element={lang === "en" ? <PromotionsBetbuilder_EN /> : lang === "et" ? <PromotionsBetbuilder_ET /> : <PromotionsBetbuilder_FI />} />}
      {/* Betbuilder promotions end */}
      <Route path="/:lang/main/:leagueFilter/:sportCode" exact element={host.bet ? navMode ? navMode === "list" ? <Leagues /> : <LeaguesTreeViewer /> : null : host.gaming ? <CasinoMainPage /> : null} />
      <Route path="/:lang/horseracing" exact element={host.bet ? navMode ? navMode === "list" ? <Leagues /> : <LeaguesTreeViewer /> : null : host.gaming ? <CasinoMainPage /> : null} />
      <Route path="/:lang/outrights" exact element={host.bet ? navMode ? navMode === "list" ? <Leagues /> : <LeaguesTreeViewer /> : null : host.gaming ? <CasinoMainPage /> : null} />
      <Route path="/:lang/platform/news" exact element={<WPNews />} />
      <Route path="/:lang/platform/campaigns" exact element={<WPCampaigns />} />
      {host.bet ? <Route path="/:lang/results" exact element={navMode ? navMode === "list" ? <Leagues /> : <LeaguesTreeViewer /> : null} /> : null}
      {host.bet ? <Route path="/:lang/all" exact element={<League />} /> : null}
      {host.bet ? <Route path="/:lang/live" exact element={navMode ? navMode === "list" ? <Leagues /> : <LeaguesTreeViewer /> : null} /> : null}
      {host.bet ? <Route path="/:lang/calendar" exact element={navMode ? navMode === "list" ? <Leagues /> : <LeaguesTreeViewer /> : null} /> : null}
      {host.bet ? <Route path="/:lang/search" exact element={navMode ? navMode === "list" ? <Leagues /> : <LeaguesTreeViewer /> : null} /> : null}
      {host.bet ? <Route path="/:lang/live/:sportCode" exact element={<League />} /> : null}
      {host.bet ? <Route path="/:lang/leagues/:leagueFilter/:sportCode/:leagueId" exact element={<StaticRoutes />} /> : null}
      {host.bet ? <Route path="/:lang/sports/:leagueFilter/:sportCode/:countryCode" exact element={<StaticRoutes />} /> : null}
      {host.bet ? <Route path="/:lang/leagues/:leagueFilter/:sportCode" exact element={<StaticRoutes />} /> : null}
      {host.bet ? <Route path="/:lang/leagues/:leagueFilter/:sportCode/:leagueId/:gameId" exact element={<StaticRoutes />} /> : null}
      <Route path="/:lang/casino" exact element={<CasinoMainPage />}>
        <Route path="dashboard" element={<CasinoMainView />} />
        <Route path="featured-games" element={<CasinoFilterView />} />
        <Route path="provider-games" element={<CasinoProviderFilterView />} />
        <Route path="free-spins" element={<FreeSpinsView />} />
        <Route path="my-games" element={<FavoriteGamesView />} />
      </Route>
      <Route path="/:lang/sign-in" exact element={<SignIn />} />
      <Route path="/:lang/betbuilder" exact element={<BetbuilderPage />} />
      <Route path="/:lang/betbuilder/:gameId" exact element={<BetBuilderLoaderPage />} />
      <Route path="/:lang/player-props" exact element={<PlayerPropsCatalog />} />
      <Route path="/:lang/confirm" exact element={<EmailConfirm />} />
      <Route path="/:lang/resend" exact element={<ResendConfirm />} />
      <Route path="/:lang/register" exact element={<UserRegister />} />
      <Route path="/:lang/register/trustly" exact element={<TrustlyRegister />} />
      <Route path="/:lang/register-switch" exact element={<RegisterSwitch />} />
      <Route path="/:lang/all/casino/register-switch" exact element={<CasinoRegisterSwitch />} />
      <Route path="/:lang/sports/register-switch" exact element={<SportsRegisterSwitch />} />
      <Route path="/:lang/forgot-password" exact element={<PasswordForgotten />} />
      <Route path="/:lang/statement" exact element={<Statement />} />
      <Route path="/:lang/payments" exact element={<Payments />} />
      <Route path="/:lang/settings" exact element={<Settings />} />
      <Route path="/:lang/login/reset" exact element={<PasswordResetForm />} />
      <Route path="/:lang/interfaces/message" exact element={<TrustlyCases />} />
      <Route path="/:lang/trustly/deposit/waiting" exact element={<TrustlyDepositWaiting />} />
      <Route path="/:lang/info/terms-conditions" exact element={lang === "en" ? <TermsConditions_EN /> : lang === "et" ? <TermsConditions_ET /> : lang === "fi" ? <TermsConditions_FI /> : <TermsConditions_EN />} />
      <Route path="/:lang/info/outdated_browser" exact element={lang === "en" ? <OutdatedBrowser_EN /> : lang === "et" ? <OutdatedBrowser_ET /> : lang === "fi" ? <OutdatedBrowser_FI /> : <OutdatedBrowser_EN />} />
      {host.bet ? <Route path="/:lang/info/bonus-terms" exact element={lang === "en" ? <BonusTerms_EN /> : lang === "et" ? <BonusTerms_ET /> : lang === "fi" ? <BonusTerms_FI /> : <BonusTerms_EN />} /> : null}
      {host.bet ? <Route path="/:lang/info/betting-rules" exact element={lang === "en" ? <BettingRules_EN /> : lang === "et" ? <BettingRules_ET /> : lang === "fi" ? <BettingRules_FI /> : <BettingRules_EN />} /> : null}
      {host.gaming ? (
        <Route path="/:lang/info/bonus-terms-slots" exact element={lang === "en" ? <BonusTermsSlots_EN /> : lang === "et" ? <BonusTermsSlots_ET /> : lang === "fi" ? <BonusTermsSlots_FI /> : <BonusTermsSlots_EN />} />
      ) : null}
      {host.gaming ? <Route path="/:lang/info/slots-rules" exact element={lang === "en" ? <SlotsRules_EN /> : lang === "et" ? <SlotsRules_ET /> : lang === "fi" ? <SlotsRules_FI /> : <SlotsRules_EN />} /> : null}
      <Route path="/:lang/info/privacy-policy" exact element={lang === "en" ? <PrivacyPolicy_EN /> : lang === "et" ? <PrivacyPolicy_ET /> : lang === "fi" ? <PrivacyPolicy_FI /> : <PrivacyPolicy_EN />} />
      <Route path="/:lang/info/help" exact element={lang === "en" ? <Help_EN /> : lang === "et" ? <Help_ET /> : lang === "fi" ? <Help_FI /> : <Help_DE />} />
      <Route path="/:lang/info/responsible-game" exact element={lang === "en" ? <ResponsibleGame_EN /> : lang === "et" ? <ResponsibleGame_ET /> : lang === "fi" ? <ResponsibleGame_FI /> : <ResponsibleGame_EN />} />
      <Route path="/:lang/info/about-us" exact element={lang === "en" ? <AboutUs_EN /> : lang === "et" ? <AboutUs_ET /> : lang === "fi" ? <AboutUs_FI /> : <AboutUs_EN />} />
      <Route path="/:lang/info/faq" exact element={lang === "en" ? <Faq_EN /> : lang === "et" ? <Faq_ET /> : lang === "fi" ? <Faq_FI /> : <Faq_EN />} />
      <Route path="*" exact element={CustomNotFoundComponent} />
    </Routes>
  );
};

export default DesktopRoutes;
