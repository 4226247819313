/**
 * Odds component key= first-element
 */

import { log } from "../../config/log";
import React from "react";
import { connect } from "react-redux";
import { Translate } from "react-redux-i18n";
import {
  saveBet,
  deleteBet,
  saveMarketSetConfig,
  updateOddsInLeague,
  getOddsHistory,
  setLeagueMenuValue,
  toggleMyTicketsPanel,
} from "../../actions";
import OddsFormat from "../OddsFormat";
import UncheckedIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckedIcon from "@mui/icons-material/CheckBox";
import OddsChart from "./OddsChart";
import OddsHistory from "./OddsHistory";
import "./Odds.css";

class Odds extends React.Component {
  constructor(props) {
    super(props);
    log(this.constructor.name + "Constructor", "render");

    this.state = {
      oddsHistoryMarketId: "",
      oddsChartsMarketId: "",
      betAdded: false,
      openDetail: false,
    };

    this.lastRenderedMarketId = null;
    this.showSpecificLine = false;
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.marketSetKey !== this.props.marketSetKey) {
      this.setState({ shouldPriceChangeUpdate: false });
    } else {
      this.setState({ shouldPriceChangeUpdate: true });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { mode } = this.props;
    if (mode === "detailed") return true;

    var flag = false;
    if (nextProps.isInViewport === true) {
      flag = true;
    }
    if (this.state.openDetail !== nextState.openDetail) {
      flag = true;
    }

    return flag;
  }

  /**
   * Currently unused function
   */

  toggleMultiLineMarket(marketId) {
    const { odds } = this.props;
    let payload = { ...odds };

    for (let key in payload) {
      if (marketId === payload[key].m) {
        payload[key].expanded = payload[key].expanded ? false : true;
      }
    }
    this.props.updateOddsInLeague(payload, this.constructor.name);
  }

  /**
   * Check if odd / bet is selected
   */

  checkSelectedOdd(odd, index) {
    const { game, bets } = this.props;
    // console.log(odd);

    let payload = {
      gameId: game.gameid,
      // isLive: game.isLive
    };

    const marketId = odd.m;
    const selectionIds = odd.s?.split(",");
    const prices = odd.p?.split(",");
    const hc = odd.hc;
    const shc = odd.shc;
    const isActives = odd.iA?.split(",");

    if (selectionIds?.length < 3 && selectionIds?.length > 1 && odd.m !== 910) {
      selectionIds.unshift(null);
      prices.unshift(null);
      isActives.unshift(null);
    } else {
      selectionIds.push(null);
      prices.push(null);
      isActives.push(null);
      selectionIds.push(null);
      prices.push(null);
      isActives.push(null);
    }

    if (Number(prices[index]) < 1) return false;

    // payload.isMultiBet = 0;
    // payload.isBank = 0;
    payload.marketId = Number(marketId);
    payload.selectionId = Number(selectionIds[index]);
    payload.price = Number(prices[index]);
    payload.hc = hc;
    if (shc) {
      payload.shc = shc;
    }

    // payload.isActive = Number(isActives[index]);
    // console.log(payload);

    let flag = false;

    if (!bets) return flag;

    for (let i = 0; i < bets?.length; i++) {
      let bet = bets[i];
      // if ((bet.gameId === payload.gameId) && (bet.hc === payload.hc) && (bet.isActive === payload.isActive) && (bet.isBank === payload.isBank) && (bet.isLive === payload.isLive) && (bet.isMultiBet === payload.isMultiBet) && (bet.marketId === payload.marketId) && (bet.selectionId === payload.selectionId)) {
      if (
        bet.gameId === payload.gameId &&
        bet.marketId === payload.marketId &&
        bet.selectionId === payload.selectionId &&
        bet.hc === payload.hc &&
        bet.shc === payload.shc
      ) {
        flag = true;
        break;
      }
    }

    // for (let key in slipTemplates) {
    //   if (slipTemplates[key].slipTemplate === selectedSlipTemplate) {

    //     let bets = slipTemplates[key].settings.bets;

    //     for (let nextKey in bets) {
    //       if ((bets[nextKey].gameId === payload.gameId) && (bets[nextKey].hc === payload.hc) && (bets[nextKey].isActive === payload.isActive) && (bets[nextKey].isBank === payload.isBank) && (bets[nextKey].isLive === payload.isLive) && (bets[nextKey].isMultiBet === payload.isMultiBet) && (bets[nextKey].marketId === payload.marketId) && (bets[nextKey].selectionId === payload.selectionId)) {
    //         flag = true;
    //         break;
    //       }
    //     }
    //   }
    // }

    return flag;
  }

  /**
   * Select odd for bet
   */

  addBet(odd, index) {
    const { game, selectedSlipTemplate, maxSelections, bets } = this.props;
    this.setState({ betAdded: !this.state.betAdded });

    if (this.deleteBet(odd, index) === true) return false;
    // if (maxSelections === bets?.length) {
    //   return;
    // }
    let payload = {
      gameId: game.gameid,
      isLive: game.isLive,
    };

    const marketId = odd.m;
    const selectionIds = odd.s?.split(",");
    const prices = odd.p?.split(",");
    const hc = odd.hc;
    const shc = odd?.shc;
    const isActives = odd.iA?.split(",");

    if (selectionIds?.length < 3 && selectionIds?.length > 1 && odd.m !== 910) {
      selectionIds.unshift(null);
      prices.unshift(null);
      isActives.unshift(null);
    } else if (selectionIds?.length === 1) {
      selectionIds.push(null);
      prices.push(null);
      isActives.push(null);
      selectionIds.push(null);
      prices.push(null);
      isActives.push(null);
    } else if (odd.m === 910 && selectionIds?.length === 2) {
      selectionIds.push(null);
      prices.push(null);
      isActives.push(null);
    }

    if (Number(prices[index]) < 1) return false;
    if (Number(prices[index]) === 1) return false;
    if (Number(isActives[index]) === 0) return false;

    payload.marketId = Number(marketId);
    payload.selectionId = Number(selectionIds[index]);
    payload.price = Number(prices[index]);
    payload.hc = hc;
    payload.isActive = Number(isActives[index]);
    payload.game = game;
    if (shc) {
      payload.shc = shc;
    }
    this.props.toggleMyTicketsPanel(this.constructor.name, true);
    this.props.saveBet(payload, selectedSlipTemplate, this.constructor.name);
    return Object.keys(payload)?.length === 0 ? false : true;
  }

  /**
   * Deselect odd from bet
   */

  deleteBet(odd, index) {
    const self = this;
    const marketId = odd.m;
    const selectionIds = odd.s?.split(",");

    if (selectionIds?.length < 3 && odd.m !== 910) {
      selectionIds.unshift(null);
    } else {
      selectionIds.push(null);
    }

    let payload = {};
    payload = self.props.bets.filter(function (bet) {
      return (
        bet.gameId === self.props.game.gameid &&
        bet.marketId === Number(marketId) &&
        bet.selectionId === Number(selectionIds[index]) &&
        bet.hc === odd.hc &&
        bet.shc === odd.shc
      );
    });
    // console.log(payload);

    if (payload?.length) {
      self.props.deleteBet(payload[0], self.props.selectedSlipTemplate);
      return Object.keys(payload[0])?.length === 0 ? false : true;
    } else {
      return false;
    }
  }

  /**
   * Replace selection label with correct value
   */

  getSelectionLabel = (id, game) => {
    const { selectionPrintLabels } = this.props;
    if (selectionPrintLabels && selectionPrintLabels[id]) {
      if (selectionPrintLabels[id].indexOf("{$competitor1}") > -1) {
        return selectionPrintLabels[id].replace("{$competitor1}", "1");
      } else if (selectionPrintLabels[id].indexOf("{$competitor2}") > -1) {
        return selectionPrintLabels[id].replace("{$competitor2}", "2");
      } else {
        return selectionPrintLabels[id];
      }
    }
    return "";
  };

  /**
   * Update favorite markets
   */

  updateFavoriteMarket(sportCode, marketId) {
    const { leagueFilter, game } = this.props;

    let marketSetConfig = { ...this.props.marketSetConfig };
    let favoriteMarkets =
      marketSetConfig[sportCode][leagueFilter === "live" ? "live" : "prematch"]
        .favorites;

    if (favoriteMarkets.includes(marketId)) {
      let filtered = favoriteMarkets.filter((value) => {
        return value !== marketId;
      });

      favoriteMarkets = filtered;
      marketSetConfig[sportCode][
        leagueFilter === "live" ? "live" : "prematch"
      ].favorites = favoriteMarkets;
    } else {
      favoriteMarkets.push(marketId);
      marketSetConfig[sportCode][
        leagueFilter === "live" ? "live" : "prematch"
      ].favorites = favoriteMarkets;
    }

    this.props.setLeagueMenuValue(
      game?.sport_code ? game.sport_code : "FB",
      game?.leagueID,
      "mainbets",
      this.constructor.name
    );

    this.props.saveMarketSetConfig(marketSetConfig, this.constructor.name);
    let favoriteMarketsCookie = {};

    for (let spCode in marketSetConfig) {
      if (
        marketSetConfig[spCode][leagueFilter === "live" ? "live" : "prematch"]
      ) {
        favoriteMarketsCookie[spCode] = [];
        favoriteMarketsCookie[spCode] =
          marketSetConfig[spCode][
            leagueFilter === "live" ? "live" : "prematch"
          ].favorites;
      }
    }

    localStorage.setItem(
      "favoriteMarkets",
      JSON.stringify(favoriteMarketsCookie)
    );
  }

  saveQueryBet() {
    const { game, searchQuery } = this.props;
    const selectionID = new URLSearchParams(searchQuery)?.get("selectionId");
    const selectionIndex = new URLSearchParams(searchQuery)?.get(
      "selectionIndex"
    );
    const oddFullPrice = new URLSearchParams(searchQuery)?.get("oddFullPrice");
    const oddFullSelectionId = new URLSearchParams(searchQuery)?.get(
      "oddFullSelectionId"
    );
    const marketID = new URLSearchParams(searchQuery)?.get("marketId");
    const gameId = new URLSearchParams(searchQuery)?.get("gameId");
    const tabType = new URLSearchParams(searchQuery)?.get("type");
    const indx = selectionIndex ? parseInt(selectionIndex) : 0;
    if (!tabType && game?.gameid === parseInt(gameId)) {
      game?.odds?.forEach((odd) => {
        if (
          selectionID &&
          parseInt(gameId) === game?.gameid &&
          (odd?.p === oddFullPrice) & marketID &&
          odd?.s === oddFullSelectionId &&
          odd?.m === parseInt(marketID) &&
          !this.checkSelectedOdd(odd, indx)
        ) {
          this.addBet(odd, indx);
        }
      });
    }
  }

  /**
   * Open odds price changing history
   */

  openOddsHistory(marketId) {
    // const { game } = this.props;
    this.setState({ oddsChartsMarketId: marketId });
  }

  componentDidMount() {
    this.saveQueryBet();
  }

  /**
   * Format market viewer label beautifully
   */

  formatMarketViewerLabels(sportCode, gameState, marketLabel) {
    if (sportCode !== "FB") {
      let labels = marketLabel.split("}");
      if (labels?.length > 1) {
        if (gameState !== "not_started") {
          return gameState + "" + labels[1];
        } else {
          return labels[1].trim();
        }
      } else {
        return marketLabel;
      }
      // let formattedMarketLabel = marketLabel.split("}")
    } else {
      return marketLabel;
    }
  }

  /**
   * Show odds in vertical mode
   */

  renderVerticalList() {
    // const { game, odds, marketSetConfig, marketSetKey, marketViewerLabels, selected, from } = this.props;
    const {
      game,
      odds,
      marketSetConfig,
      layout,
      selectionPrintLabels,
      selectedLeague,
      isSideBetOpen,
      marketSetKey,
      marketViewerLabels,
      from,
      leagueFilter,
      showMyTicketsPanel,
      mode,
    } = this.props;
    this.lastRenderedMarketId = null;
    let sportCode = game.sport_code;
    let duplicatedOdds = [...odds];
    let marketSet = [];

    if (
      marketSetConfig[game.sport_code][
        isSideBetOpen
          ? "sidebets"
          : leagueFilter === "live"
          ? "live"
          : "prematch"
      ][marketSetKey]
    ) {
      marketSet =
        marketSetConfig[game.sport_code][
          marketSetKey === "favorites"
            ? "prematch"
            : isSideBetOpen
            ? "sidebets"
            : leagueFilter === "live"
            ? "live"
            : "prematch"
        ][marketSetKey];
    }

    let formattedOdds = [];

    if (from === "gameModalViewer") {
      // this means the user opens a game detail popover
      // we need to check if there is an odd which contains more information
      duplicatedOdds?.forEach((odd) => {
        // const marketId = odd.m;
        let selectionIds = odd.s?.split(",");
        const prices = odd.p?.split(",");
        // const hc = odd.hc.split('=');
        const isActives = odd.iA?.split(",");
        const selections = odd.sn?.split(",");
        const isFavorite = odd.iF;

        if (selectionIds?.length < 3) {
          selectionIds.unshift(null);
          prices.unshift(null);
          isActives.unshift(0);
        }

        if (selectionIds?.length > 3) {
          // this is the target odd
          var splittedSelectionIds = selectionIds.reduce(
            (resultArray, item, index) => {
              const chunkIndex = Math.floor(index / 3);

              if (!resultArray[chunkIndex]) {
                resultArray[chunkIndex] = []; // start a new chunk
              }

              resultArray[chunkIndex].push(item);

              return resultArray;
            },
            []
          );

          var splittedPrices = prices.reduce((resultArray, item, index) => {
            const chunkIndex = Math.floor(index / 3);

            if (!resultArray[chunkIndex]) {
              resultArray[chunkIndex] = []; // start a new chunk
            }

            resultArray[chunkIndex].push(item);

            return resultArray;
          }, []);

          var splittedActives = isActives.reduce((resultArray, item, index) => {
            const chunkIndex = Math.floor(index / 3);

            if (!resultArray[chunkIndex]) {
              resultArray[chunkIndex] = []; // start a new chunk
            }

            resultArray[chunkIndex].push(item);

            return resultArray;
          }, []);

          var splittedSelectionNames = selections
            ? selections?.reduce((resultArray, item, index) => {
                const chunkIndex = Math.floor(index / 3);

                if (!resultArray[chunkIndex]) {
                  resultArray[chunkIndex] = []; // start a new chunk
                }

                resultArray[chunkIndex].push(item);

                return resultArray;
              }, [])
            : [];

          splittedSelectionIds?.forEach((item, index) => {
            let obj = {};
            // obj.grp = odd.grp;
            obj.hc = index === 0 ? odd.hc : "";
            obj.iF = odd.iF;
            obj.m = odd.m;
            obj.sn = splittedSelectionNames[index]?.join(",");
            obj.iA = splittedActives[index].join(",");
            obj.p = splittedPrices[index].join(",");
            obj.s = item.join(",");
            if (odd?.shc) {
              obj.shc = odd.shc;
            }

            if (odd.m === 45 || odd.m === 199) {
              obj.isFormattedManually = false;
            } else {
              obj.isFormattedManually = true;
            }

            formattedOdds.push(obj);
          });
        } else {
          formattedOdds.push(odd);
        }
      });
    } else {
      formattedOdds = duplicatedOdds;
    }

    let formattedValues = [];

    marketSet?.forEach((mv) => {
      const filteredOdds = formattedOdds.filter((item) => item.m === mv);
      const findFavorite = filteredOdds?.find((oddF) => oddF?.iF == 1);
      const findActive = filteredOdds?.find(
        (oddA) => oddA?.iA == 1 || oddA?.iA?.toString().includes("1")
      );
      filteredOdds?.forEach((dd) => {
        const findIfExist = formattedValues.find(
          (tt) =>
            tt.m === dd.m && tt.s === dd.s && dd.hc === tt.hc && dd.p === tt.p
        );
        if (
          (!findIfExist && dd.iF === 1 && !isSideBetOpen) ||
          (!findIfExist && !isSideBetOpen && filteredOdds?.length === 1) ||
          (!findIfExist &&
            selectedLeague?.menuValue !== "mainbets" &&
            selectedLeague?.menuValue !== "") ||
          (!findIfExist &&
            !findFavorite &&
            findActive?.s === dd?.s &&
            !isSideBetOpen)
        ) {
          formattedValues.push(dd);
        } else if (isSideBetOpen && !findIfExist) {
          formattedValues.push(dd);
        }
      });
    });

    return formattedValues.map((comingOdd, key) => {
      const odd = { ...comingOdd };
      const initialSelections = comingOdd.s?.split(",");

      if (odd.m === 199 && initialSelections?.length === 1) {
        odd.s = `0,${odd.s}`;
        odd.p = `0,${odd.p}`;
        odd.iA = `0,${odd.iA}`;
      }

      const marketId = odd.m;
      const selectionIds = odd.s?.split(",");
      const prices = odd.p?.split(",");
      const hc = odd.hc.split("=");
      const isActives = odd.iA?.split(",");
      const selectioNames = odd?.sn?.split(",");

      // const currentTime = new Date().getTime();
      // const priceChanges = odd.priceChanges && odd.priceChanges?.length ? currentTime > odd.priceChangesLifeTime ? ['=', '=', '='] : odd.priceChanges : ['=', '=', '='];

      const priceChanges =
        odd.priceChanges && odd.priceChanges?.length
          ? odd.priceChanges
          : ["=", "=", "="];

      // const isFavorite = odd.iF;

      if (!odd.isFormattedManually && selectionIds?.length < 3) {
        selectionIds.unshift(null);
        prices.unshift(null);
        isActives.unshift(0);
      }

      //if all iA = 0 we do not show the line
      // if (!odd.iA.includes('1')) return null;
      let shouldRowRender = true;

      if (!selectionIds[0]) {
        shouldRowRender =
          ((Number(prices[1]) <= 1 && prices[1] !== null) ||
            Number(isActives[1]) === 0) &&
          ((Number(prices[2]) <= 1 && prices[2] !== null) ||
            Number(isActives[2]) === 0)
            ? false
            : true;
      } else {
        shouldRowRender =
          ((Number(prices[0]) <= 1 && prices[0] !== null) ||
            Number(isActives[0]) === 0) &&
          ((Number(prices[1]) <= 1 && prices[1] !== null) ||
            Number(isActives[1]) === 0) &&
          ((Number(prices[2]) <= 1 && prices[2] !== null) ||
            Number(isActives[2]) === 0)
            ? false
            : true;
      }

      // shouldRowRender = true;
      // if (from === 'gameModalViewer') {
      //   if (!shouldRowRender)
      //     return null;
      // }

      let renderMarketName =
        marketId === this.lastRenderedMarketId ? false : true;

      if (
        marketSetKey === "mainbets" &&
        !renderMarketName &&
        from !== "gameModalViewer"
      )
        return "";

      this.lastRenderedMarketId = marketId;
      let marketHasHc = hc[hc?.length - 1] !== "" ? true : false;

      let setnr = null;
      if (odd.hc && odd.hc?.includes("setnr")) {
        const nrVals = odd.hc.split("|");
        setnr = nrVals[0][nrVals[0]?.length - 1];
      } else if (odd.hc && odd.hc?.includes("periodnr")) {
        const nrVals = odd.hc.split("|");
        setnr = nrVals[0][nrVals[0]?.length - 1];
      }

      return (
        <div
          key={key}
          className={`${
            mode === "detailed" && renderMarketName
              ? "market-box-sidebets-separation"
              : ""
          }`}
        >
          {/* // <div key={key}> */}
          <div
            className={`${
              marketId === 910 && layout === "touch"
                ? "market-box-vertical-910"
                : "market-box-vertical"
            }`}
          >
            <div
              className={`flex-start font-m ${
                mode === "detailed" ? "" : "markets-intend"
              }`}
            >
              <div className="vertical-center grey-3 spacer-left-list"></div>
              <div
                className="vertical-center grey-3 spacer-right"
                onClick={() => {
                  this.updateFavoriteMarket(sportCode, marketId);
                }}
              >
                {renderMarketName ? (
                  marketSetConfig &&
                  marketSetConfig[sportCode][
                    leagueFilter === "live" ? "live" : "prematch"
                  ].favorites.includes(marketId) ? (
                    <CheckedIcon
                      style={{ width: "0.7em", color: "var(--color-contrast)" }}
                    />
                  ) : (
                    <UncheckedIcon style={{ width: "0.7em" }} />
                  )
                ) : null}
              </div>
              {setnr && renderMarketName ? `${setnr}. ` : ""}
              {renderMarketName
                ? [
                    marketViewerLabels &&
                      marketViewerLabels[marketId] &&
                      this.formatMarketViewerLabels(
                        game.sport_code,
                        game.state,
                        marketViewerLabels[marketId]
                      ),
                    // <OddsHistoryIcon key={'icon-' + key} style={{ width: '0.6em', padding: '0.15em', color: 'var(--grey-10)' }} onClick={() => { this.openOddsHistory(marketId) }} />
                  ]
                : null}
            </div>

            <div className="flex-end spacer-right font-m">
              {/* {marketHasHc && selectionIds[0] ? hc[hc?.length - 1] : ''}  */}
              {marketHasHc &&
              selectionIds[0] &&
              marketId != [7] &&
              marketId != [8] &&
              marketId != [910] &&
              marketId != [62]
                ? hc[hc?.length - 1]
                : ""}
            </div>

            <div
              id="first-element"
              className={`${
                this.checkSelectedOdd(odd, 0) ? "selected-odds-left" : ""
              } selection-label flex-start font-selection-label cursor-pointer ${
                selectionIds[0] ? "markets-btn border-odds-left" : ""
              } ${
                priceChanges[0] === "="
                  ? ""
                  : priceChanges[0] === ">"
                  ? "odds-raise"
                  : priceChanges[0] === "<"
                  ? "odds-drop"
                  : ""
              }`}
              onClick={selectionIds[0] ? this.addBet.bind(this, odd, 0) : null}
            >
              {Number(prices[0]) <= 1 && prices[0] !== null ? (
                <span style={{ color: "var(--grey-5)" }}>
                  {this.getSelectionLabel(selectionIds[0], game)}
                </span>
              ) : Number(isActives[0]) === 0 ? (
                <span style={{ color: "var(--grey-5)" }}>
                  {this.getSelectionLabel(selectionIds[0], game)}
                </span>
              ) : (
                this.getSelectionLabel(selectionIds[0], game)
              )}
              {marketId === 910 && selectioNames && selectioNames[0]
                ? selectioNames[0]
                : undefined}
            </div>

            {marketHasHc && !selectionIds[0] ? (
              // <div className='selection-label flex-start font-selection-label cursor-pointer'></div>
              <div className="flex-end spacer-right font-m">
                {hc[hc?.length - 1]}
              </div>
            ) : (
              // <div className={`${this.checkSelectedOdd(odd, 0) ? 'selected-odds-right' : ''} price-border font-bold flex-end cursor-pointer font-xl font-bold-2 spacer-right ${selectionIds[0] ? 'odds-btn border-odds-right' : ''} ${priceChanges[0] === '=' ? '' : priceChanges[0] === '>' ? 'odds-raise odds-raise-arrow' : priceChanges[0] === '<' ? 'odds-drop odds-drop-arrow' : ''}`} onClick={selectionIds[0] ? this.addBet.bind(this, odd, 0) : null}>
              // <div className={`${this.checkSelectedOdd(odd, 0) ? 'selected-odds-right' : ''} price-border font-bold flex-end cursor-pointer ${showMyTicketsPanel === true ? 'font-xs' : 'font-xl'} font-bold-2 spacer-right ${selectionIds[0] ? 'odds-btn border-odds-right' : ''} ${priceChanges[0] === '=' ? '' : priceChanges[0] === '>' ? 'odds-raise odds-raise-arrow' : priceChanges[0] === '<' ? 'odds-drop odds-drop-arrow' : ''}`} onClick={selectionIds[0] ? this.addBet.bind(this, odd, 0) : null}>
              <div
                className={`${
                  this.checkSelectedOdd(odd, 0) ? "selected-odds-right" : ""
                } price-border font-bold flex-end cursor-pointer font-xl font-bold-2 spacer-right ${
                  selectionIds[0]
                    ? "odds-btn border-odds-right odds_test_selector"
                    : ""
                } ${
                  priceChanges[0] === "="
                    ? ""
                    : priceChanges[0] === ">"
                    ? "odds-raise odds-raise-arrow"
                    : priceChanges[0] === "<"
                    ? "odds-drop odds-drop-arrow"
                    : ""
                }`}
                onClick={
                  selectionIds[0] ? this.addBet.bind(this, odd, 0) : null
                }
              >
                {from === "gameModalViewer" ? (
                  prices[0] && (
                    <OddsFormat price={prices[0]} isActive={isActives[0]} />
                  )
                ) : (
                  <OddsFormat price={prices[0]} isActive={isActives[0]} />
                )}
              </div>
            )}

            {selectionIds[1] && (
              <div
                className={`${
                  this.checkSelectedOdd(odd, 1) ? "selected-odds-left" : ""
                } selection-label border-odds-left flex-start ${
                  marketId === 199 && initialSelections?.length === 1
                    ? ""
                    : "markets-btn"
                } font-selection-label cursor-pointer ${
                  priceChanges[1] === "="
                    ? ""
                    : priceChanges[1] === ">"
                    ? "odds-raise"
                    : priceChanges[1] === "<"
                    ? "odds-drop"
                    : ""
                }`}
                onClick={this.addBet.bind(this, odd, 1)}
              >
                {Number(prices[1]) <= 1 && prices[1] !== null ? (
                  <span style={{ color: "var(--grey-5)" }}>
                    {this.getSelectionLabel(selectionIds[1], game)}
                  </span>
                ) : Number(isActives[1]) === 0 ? (
                  <span style={{ color: "var(--grey-5)" }}>
                    {this.getSelectionLabel(selectionIds[1])}
                  </span>
                ) : (
                  this.getSelectionLabel(selectionIds[1], game)
                )}
                {marketId === 910 && selectioNames && selectioNames[1]
                  ? selectioNames[1]
                  : undefined}
              </div>
            )}

            {selectionIds[1] && (
              // <div className={`${this.checkSelectedOdd(odd, 1) ? 'selected-odds-right' : ''} odds-btn price-border border-odds-right flex-end cursor-pointer font-xl font-bold-2 spacer-right ${priceChanges[1] === '=' ? '' : priceChanges[1] === '>' ? 'odds-raise odds-raise-arrow' : priceChanges[1] === '<' ? 'odds-drop odds-drop-arrow' : ''}`} onClick={this.addBet.bind(this, odd, 1)}><OddsFormat price={prices[1]} isActive={isActives[1]} /></div>
              // <div className={`${this.checkSelectedOdd(odd, 1) ? 'selected-odds-right' : ''} odds-btn price-border border-odds-right flex-end cursor-pointer ${showMyTicketsPanel === true ? 'font-xs' : 'font-xl'} font-bold-2 spacer-right ${priceChanges[1] === '=' ? '' : priceChanges[1] === '>' ? 'odds-raise odds-raise-arrow' : priceChanges[1] === '<' ? 'odds-drop odds-drop-arrow' : ''}`} onClick={this.addBet.bind(this, odd, 1)}><OddsFormat price={prices[1]} isActive={isActives[1]} /></div>
              <div
                className={`${
                  this.checkSelectedOdd(odd, 1) ? "selected-odds-right" : ""
                } ${
                  marketId === 199 && initialSelections?.length === 1
                    ? ""
                    : "odds-btn"
                } price-border border-odds-right flex-end cursor-pointer font-xl font-bold-2 spacer-right ${
                  priceChanges[1] === "="
                    ? ""
                    : priceChanges[1] === ">"
                    ? "odds-raise odds-raise-arrow"
                    : priceChanges[1] === "<"
                    ? "odds-drop odds-drop-arrow"
                    : ""
                } odds_test_selector`}
                onClick={this.addBet.bind(this, odd, 1)}
              >
                {marketId === 199 && initialSelections?.length === 1 ? (
                  ""
                ) : (
                  <OddsFormat price={prices[1]} isActive={isActives[1]} />
                )}
              </div>
            )}

            {marketId === 910 && layout === "touch" ? (
              <>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </>
            ) : null}

            {selectionIds[2] && (
              <div
                className={`${
                  this.checkSelectedOdd(odd, 2) ? "selected-odds-left" : ""
                } selection-label border-odds-left flex-start markets-btn font-selection-label cursor-pointer ${
                  priceChanges[2] === "="
                    ? ""
                    : priceChanges[2] === ">"
                    ? "odds-raise"
                    : priceChanges[2] === "<"
                    ? "odds-drop"
                    : ""
                } odds_test_selector`}
                onClick={this.addBet.bind(this, odd, 2)}
              >
                {Number(prices[2]) <= 1 && prices[2] !== null ? (
                  <span style={{ color: "var(--grey-5)" }}>
                    {this.getSelectionLabel(selectionIds[2], game)}
                  </span>
                ) : Number(isActives[2]) === 0 ? (
                  <span style={{ color: "var(--grey-5)" }}>
                    {this.getSelectionLabel(selectionIds[2], game)}
                  </span>
                ) : (
                  this.getSelectionLabel(selectionIds[2], game)
                )}
                {marketId === 910 && selectioNames && selectioNames[2]
                  ? selectioNames[2]
                  : undefined}
              </div>
            )}

            {selectionIds[2] && (
              // <div className={`${this.checkSelectedOdd(odd, 2) ? 'selected-odds-right' : ''} odds-btn border-odds-right flex-end cursor-pointer font-xl font-bold-2 spacer-right ${priceChanges[2] === '=' ? '' : priceChanges[2] === '>' ? 'odds-raise odds-raise-arrow' : priceChanges[2] === '<' ? 'odds-drop odds-drop-arrow' : ''}`} onClick={this.addBet.bind(this, odd, 2)}><OddsFormat price={prices[2]} isActive={isActives[2]} /></div>
              // <div className={`${this.checkSelectedOdd(odd, 2) ? 'selected-odds-right' : ''} odds-btn border-odds-right flex-end cursor-pointer ${showMyTicketsPanel === true ? 'font-xs' : 'font-xl'} font-bold-2 spacer-right ${priceChanges[2] === '=' ? '' : priceChanges[2] === '>' ? 'odds-raise odds-raise-arrow' : priceChanges[2] === '<' ? 'odds-drop odds-drop-arrow' : ''}`} onClick={this.addBet.bind(this, odd, 2)}><OddsFormat price={prices[2]} isActive={isActives[2]} /></div>
              <div
                className={`${
                  this.checkSelectedOdd(odd, 2) ? "selected-odds-right" : ""
                } odds-btn border-odds-right flex-end cursor-pointer font-xl font-bold-2 spacer-right ${
                  priceChanges[2] === "="
                    ? ""
                    : priceChanges[2] === ">"
                    ? "odds-raise odds-raise-arrow"
                    : priceChanges[2] === "<"
                    ? "odds-drop odds-drop-arrow"
                    : ""
                } odds_test_selector`}
                onClick={this.addBet.bind(this, odd, 2)}
              >
                <OddsFormat price={prices[2]} isActive={isActives[2]} />
              </div>
            )}

            {
              //ODDS HISTORY BUTTON
              // <div className={`${renderMarketName && this.state.oddsChartsMarketId === marketId ? 'odds-charts-selected' : 'odds-charts'} vertical-center content-center`}>
              //   {
              //     renderMarketName ?
              //       [
              //         <OddsHistoryIcon key={'icon-' + key} style={{ width: '0.6em', padding: '0.15em' }} onClick={() => { this.state.oddsChartsMarketId !== marketId ? this.openOddsHistory(marketId) : this.setState({ oddsChartsMarketId: null }) }} />
              //       ]
              //       : null
              //   }
              // </div>
              <div className="hidden"></div>
            }
          </div>

          {renderMarketName && this.state.oddsChartsMarketId === marketId ? (
            <div>
              <OddsChart
                leagueId={game.leagueID}
                gameId={game.gameid}
                marketId={marketId}
                hc={hc}
                selectionIds={selectionIds}
              />
            </div>
          ) : null}
        </div>
      );
    });
  }

  /**
   * Show odds in horizontal mode
   */

  renderHorizontalSets() {
    const {
      game,
      leagueFilter,
      selectedLeague,
      marketSetKey,
      marketSetConfig,
      odds,
      isSideBetOpen,
    } = this.props;

    let marketSet = [
      ...marketSetConfig[game.sport_code][
        leagueFilter === "live" ? "live" : "prematch"
      ][marketSetKey],
    ];

    let showSpecificLine = false; //for multiliners, rest show first and only line (0)
    let marketSetAsString = marketSet.join(",");

    let formattedValues = [];

    marketSet?.forEach((mv) => {
      const filteredOdds = odds.filter((item) => item.m === mv);
      const findFavorite = filteredOdds?.find((oddF) => oddF?.iF == 1);
      const findActive = filteredOdds?.find(
        (oddA) => oddA?.iA == 1 || oddA?.iA?.toString().includes("1")
      );
      filteredOdds?.forEach((dd) => {
        const findIfExist = formattedValues.find(
          (tt) =>
            tt.m === dd.m && tt.s === dd.s && dd.hc === tt.hc && dd.p === tt.p
        );
        if (
          (!findIfExist && dd.iF === 1 && !isSideBetOpen) ||
          (!findIfExist && !isSideBetOpen && filteredOdds?.length === 1) ||
          (!findIfExist &&
            selectedLeague?.menuValue !== "mainbets" &&
            selectedLeague?.menuValue !== "") ||
          (!findIfExist &&
            !findFavorite &&
            findActive?.s === dd?.s &&
            !isSideBetOpen)
        ) {
          formattedValues.push(dd);
        } else if (isSideBetOpen && !findIfExist) {
          formattedValues.push(dd);
        }
      });
    });

    switch (marketSetAsString) {
      case "18,18,18":
      case "16,16,16":
      case "14,14":
        showSpecificLine = true;
        break;

      default:
        break;
    }
    return (
      <div className={`marketset-grid-${marketSet?.length}-cols`}>
        {marketSet.map((marketId, key) => {
          // return this.renderMarketLines(odds.filter(obj => obj.m === marketId && obj.iA.includes('1')), showSpecificLine ? key : 0)
          const filteredOdds = formattedValues.filter(
            (obj) => obj.m === marketId
          );
          if (filteredOdds?.length === 1) {
            filteredOdds.push({
              iA: "0,0",
              m: "",
              s: "0,0",
              p: "",
              hc: "total=0",
            });
            filteredOdds.push({
              iA: "0,0",
              m: "",
              s: "0,0",
              p: "",
              hc: "total=0",
            });
          }
          if (filteredOdds?.length === 2) {
            filteredOdds.push({
              iA: "0,0",
              m: "",
              s: "0,0",
              p: "",
              hc: "total=0",
            });
          }
          return this.renderMarketLines(
            filteredOdds,
            showSpecificLine ? key : 0
          );
        })}
      </div>
    );
  }

  /**
   * Show market lines and render odds ( price, price changing icons )
   */

  renderMarketLines(marketOdds, specificLine) {
    const { game, marketSetKey, showMyTicketsPanel, oddsFormat } = this.props;
    this.lastRenderedMarketId = null;

    if (marketOdds?.length > 0) {
      return marketOdds.map((odd, key) => {
        if (key !== specificLine) return null;

        const marketId = odd.m;
        const selectionIds = odd.s?.split(",");
        const prices = odd.p?.split(",");
        const hc = odd.hc.split("=");
        const isActives = odd.iA?.split(",");

        // const currentTime = new Date().getTime();
        // const priceChanges = odd.priceChanges && odd.priceChanges?.length ? currentTime > odd.priceChangesLifeTime ? ['=', '=', '='] : odd.priceChanges : ['=', '=', '='];

        const priceChanges =
          odd.priceChanges && odd.priceChanges?.length
            ? odd.priceChanges
            : ["=", "=", "="];
        // const isFavorite = odd.iF;

        let renderMarketName =
          marketId === this.lastRenderedMarketId ? false : true;

        if (marketSetKey === "mainbets" && !renderMarketName) return "";

        this.lastRenderedMarketId = marketId;

        let className =
          selectionIds?.length > 3 ? "3" : String(selectionIds?.length);
        // let marketHasHc = hc[hc?.length - 1] !== "" ? true : false;
        let marketHasHc =
          hc[hc?.length - 1] !== "" &&
          marketId != [7] &&
          marketId != [8] &&
          marketId != [62]
            ? true
            : false;
        // let hideHc = marketId != [7] && marketId != [8] && marketId != [62] ? true : false;
        if (marketHasHc) className += "-hc";

        if (!odd.iA || odd.iA[0] === "0" || key !== specificLine) {
          return (
            //   <div key={key}>
            //   <div className={`market-box-${className}-cols`}>
            //     <div className="flex-end spacer-right font-s"></div>
            //       {className != 3 ? <div className="flex-end spacer-right font-s"></div> : null}

            //     <div className={`selection-label flex-start font-selection-label cursor-pointer markets-btn border-odds-left`}></div>

            //     <div className="price-border font-bold flex-end cursor-pointer font-bold-2 spacer-right odds-btn border-odds-right font-xl">
            //       <OddsFormat price={0} isActive={false} />
            //     </div>
            //     <div className={`selection-label flex-start font-selection-label cursor-pointer markets-btn border-odds-left`}></div>

            //     <div className="price-border font-bold flex-end cursor-pointer font-bold-2 spacer-right odds-btn border-odds-right font-xl">
            //       <OddsFormat price={0} isActive={false} />
            //     </div>

            //       {
            //         className == 3 ?
            //           <>
            //             <div className={`selection-label flex-start font-selection-label cursor-pointer markets-btn border-odds-left`}></div>
            //               <div  className="odds-btn price-border border-odds-right flex-end cursor-pointer font-bold-2 spacer-right font-xl">
            //               <OddsFormat price={0} isActive={false} />
            //             </div>
            //           </>:null
            //      }

            //     <div className={'hidden'} />
            //   </div>
            // </div>

            <div key={key}>
              <div
                className={`vertical-center content-center font-xl`}
                style={{ height: "100%" }}
              >
                <OddsFormat price={0} isActive={false} />
              </div>
            </div>
          );
        }

        return (
          <div key={key}>
            {/* <div className={`market-box-${className}-cols`}> */}
            {/* <div className={`market-box-${className}-cols ${showMyTicketsPanel === true ? 'font-odds-4-cols grid-template-row-4-cols' : ''}`}> */}
            <div className={`market-box-${className}-cols`}>
              <div className="flex-end spacer-right font-s"></div>

              {marketHasHc ? (
                // marketHasHc && marketId != [8]  ?
                <div className="flex-end spacer-right font-hc">
                  {hc[hc?.length - 1]}
                </div>
              ) : // <div className="flex-end spacer-right font-hc">{marketId}{hc[hc?.length - 1]}</div>

              null}

              {selectionIds?.length === 2 ? (
                <div
                  className={`${
                    this.checkSelectedOdd(odd, 1) ? "selected-odds-left" : ""
                  } selection-label flex-start font-selection-label cursor-pointer ${
                    selectionIds[0]
                      ? "markets-btn border-odds-left odds_test_selector"
                      : ""
                  } ${
                    priceChanges[0] === "="
                      ? ""
                      : priceChanges[0] === ">"
                      ? "odds-raise"
                      : priceChanges[0] === "<"
                      ? "odds-drop"
                      : ""
                  }`}
                  onClick={
                    selectionIds[0] ? this.addBet.bind(this, odd, 1) : null
                  }
                >
                  {Number(prices[0]) <= 1 && prices[0] !== null ? (
                    <span style={{ color: "var(--grey-5)" }}>
                      {this.getSelectionLabel(selectionIds[0], game)}
                    </span>
                  ) : Number(isActives[0]) === 0 ? (
                    <span style={{ color: "var(--grey-5)" }}>
                      {this.getSelectionLabel(selectionIds[0], game)}
                    </span>
                  ) : (
                    this.getSelectionLabel(selectionIds[0], game)
                  )}
                </div>
              ) : (
                <div
                  className={`${
                    this.checkSelectedOdd(odd, 0) ? "selected-odds-left" : ""
                  } selection-label flex-start font-selection-label cursor-pointer ${
                    selectionIds[0]
                      ? "markets-btn border-odds-left odds_test_selector"
                      : ""
                  } ${
                    priceChanges[0] === "="
                      ? ""
                      : priceChanges[0] === ">"
                      ? "odds-raise"
                      : priceChanges[0] === "<"
                      ? "odds-drop"
                      : ""
                  }`}
                  onClick={
                    selectionIds[0] ? this.addBet.bind(this, odd, 0) : null
                  }
                >
                  {Number(prices[0]) <= 1 && prices[0] !== null ? (
                    <span style={{ color: "var(--grey-5)" }}>
                      {this.getSelectionLabel(selectionIds[0], game)}
                    </span>
                  ) : Number(isActives[0]) === 0 ? (
                    <span style={{ color: "var(--grey-5)" }}>
                      {this.getSelectionLabel(selectionIds[0], game)}
                    </span>
                  ) : (
                    this.getSelectionLabel(selectionIds[0], game)
                  )}
                </div>
              )}

              {selectionIds?.length === 2 ? (
                // <div className={`${this.checkSelectedOdd(odd, 1) ? 'selected-odds-right' : ''} price-border font-bold flex-end cursor-pointer font-xl font-bold-2 spacer-right ${selectionIds[0] ? 'odds-btn border-odds-right' : ''} ${priceChanges[0] === '=' ? '' : priceChanges[0] === '>' ? 'odds-raise odds-raise-arrow' : priceChanges[0] === '<' ? 'odds-drop odds-drop-arrow' : ''}`} onClick={selectionIds[0] ? this.addBet.bind(this, odd, 1) : null}><OddsFormat price={prices[0]} isActive={isActives[0]} /></div>
                // <div className={`${this.checkSelectedOdd(odd, 1) ? 'selected-odds-right' : ''} price-border font-bold flex-end cursor-pointer ${showMyTicketsPanel === true ? 'font-xs' : 'font-xl'} font-bold-2 spacer-right ${selectionIds[0] ? 'odds-btn border-odds-right' : ''} ${priceChanges[0] === '=' ? '' : priceChanges[0] === '>' ? 'odds-raise odds-raise-arrow' : priceChanges[0] === '<' ? 'odds-drop odds-drop-arrow' : ''}`} onClick={selectionIds[0] ? this.addBet.bind(this, odd, 1) : null}><OddsFormat price={prices[0]} isActive={isActives[0]} /></div>
                <div
                  className={`${
                    this.checkSelectedOdd(odd, 1) ? "selected-odds-right" : ""
                  } price-border font-bold flex-end cursor-pointer font-xl font-bold-2 spacer-right ${
                    selectionIds[0]
                      ? "odds-btn border-odds-right odds_test_selector"
                      : ""
                  } ${
                    priceChanges[0] === "="
                      ? ""
                      : priceChanges[0] === ">"
                      ? "odds-raise odds-raise-arrow"
                      : priceChanges[0] === "<"
                      ? "odds-drop odds-drop-arrow"
                      : ""
                  }`}
                  onClick={
                    selectionIds[0] ? this.addBet.bind(this, odd, 1) : null
                  }
                >
                  <OddsFormat price={prices[0]} isActive={isActives[0]} />
                </div>
              ) : (
                // <div className={`${this.checkSelectedOdd(odd, 0) ? 'selected-odds-right' : ''} price-border font-bold flex-end cursor-pointer font-xl font-bold-2 spacer-right ${selectionIds[0] ? 'odds-btn border-odds-right' : ''} ${priceChanges[0] === '=' ? '' : priceChanges[0] === '>' ? 'odds-raise odds-raise-arrow' : priceChanges[0] === '<' ? 'odds-drop odds-drop-arrow' : ''}`} onClick={selectionIds[0] ? this.addBet.bind(this, odd, 0) : null}><OddsFormat price={prices[0]} isActive={isActives[0]} /></div>
                // <div className={`${this.checkSelectedOdd(odd, 0) ? 'selected-odds-right' : ''} price-border font-bold flex-end cursor-pointer ${showMyTicketsPanel === true ? 'font-xs' : 'font-xl'} font-bold-2 spacer-right ${selectionIds[0] ? 'odds-btn border-odds-right' : ''} ${priceChanges[0] === '=' ? '' : priceChanges[0] === '>' ? 'odds-raise odds-raise-arrow' : priceChanges[0] === '<' ? 'odds-drop odds-drop-arrow' : ''}`} onClick={selectionIds[0] ? this.addBet.bind(this, odd, 0) : null}><OddsFormat price={prices[0]} isActive={isActives[0]} /></div>
                <div
                  className={`${
                    this.checkSelectedOdd(odd, 0) ? "selected-odds-right" : ""
                  } price-border font-bold flex-end cursor-pointer font-xl font-bold-2 spacer-right ${
                    selectionIds[0]
                      ? "odds-btn border-odds-right odds_test_selector"
                      : ""
                  } ${
                    priceChanges[0] === "="
                      ? ""
                      : priceChanges[0] === ">"
                      ? "odds-raise odds-raise-arrow"
                      : priceChanges[0] === "<"
                      ? "odds-drop odds-drop-arrow"
                      : ""
                  }`}
                  onClick={
                    selectionIds[0] ? this.addBet.bind(this, odd, 0) : null
                  }
                >
                  <OddsFormat price={prices[0]} isActive={isActives[0]} />
                </div>
              )}

              {selectionIds?.length === 2 ? (
                <div
                  className={`${
                    this.checkSelectedOdd(odd, 2) ? "selected-odds-left" : ""
                  } selection-label border-odds-left flex-start markets-btn font-selection-label cursor-pointer ${
                    priceChanges[1] === "="
                      ? ""
                      : priceChanges[1] === ">"
                      ? "odds-raise"
                      : priceChanges[1] === "<"
                      ? "odds-drop"
                      : ""
                  }`}
                  onClick={this.addBet.bind(this, odd, 2)}
                >
                  {Number(prices[1]) <= 1 && prices[1] !== null ? (
                    <span style={{ color: "var(--grey-5)" }}>
                      {this.getSelectionLabel(selectionIds[1], game)}
                    </span>
                  ) : Number(isActives[1]) === 0 ? (
                    <span style={{ color: "var(--grey-5)" }}>
                      {this.getSelectionLabel(selectionIds[1], game)}
                    </span>
                  ) : (
                    this.getSelectionLabel(selectionIds[1], game)
                  )}
                </div>
              ) : (
                <div
                  className={`${
                    this.checkSelectedOdd(odd, 1) ? "selected-odds-left" : ""
                  } selection-label border-odds-left flex-start markets-btn font-selection-label cursor-pointer ${
                    priceChanges[1] === "="
                      ? ""
                      : priceChanges[1] === ">"
                      ? "odds-raise"
                      : priceChanges[1] === "<"
                      ? "odds-drop"
                      : ""
                  } odds_test_selector`}
                  onClick={this.addBet.bind(this, odd, 1)}
                >
                  {Number(prices[1]) <= 1 && prices[1] !== null ? (
                    <span style={{ color: "var(--grey-5)" }}>
                      {this.getSelectionLabel(selectionIds[1], game)}
                    </span>
                  ) : Number(isActives[1]) === 0 ? (
                    <span style={{ color: "var(--grey-5)" }}>
                      {this.getSelectionLabel(selectionIds[1], game)}
                    </span>
                  ) : (
                    this.getSelectionLabel(selectionIds[1], game)
                  )}
                </div>
              )}

              {selectionIds?.length === 2 ? (
                // <div className={`${this.checkSelectedOdd(odd, 2) ? 'selected-odds-right' : ''} odds-btn price-border border-odds-right flex-end cursor-pointer font-xl font-bold-2 spacer-right ${priceChanges[1] === '=' ? '' : priceChanges[1] === '>' ? 'odds-raise odds-raise-arrow' : priceChanges[1] === '<' ? 'odds-drop odds-drop-arrow' : ''}`} onClick={this.addBet.bind(this, odd, 2)}><OddsFormat price={prices[1]} isActive={isActives[1]} /></div>
                // <div className={`${this.checkSelectedOdd(odd, 2) ? 'selected-odds-right' : ''} odds-btn price-border border-odds-right flex-end cursor-pointer ${showMyTicketsPanel === true ? 'font-xs' : 'font-xl'} font-bold-2 spacer-right ${priceChanges[1] === '=' ? '' : priceChanges[1] === '>' ? 'odds-raise odds-raise-arrow' : priceChanges[1] === '<' ? 'odds-drop odds-drop-arrow' : ''}`} onClick={this.addBet.bind(this, odd, 2)}><OddsFormat price={prices[1]} isActive={isActives[1]} /></div>
                <div
                  className={`${
                    this.checkSelectedOdd(odd, 2) ? "selected-odds-right" : ""
                  } odds-btn price-border border-odds-right flex-end cursor-pointer font-xl font-bold-2 spacer-right ${
                    priceChanges[1] === "="
                      ? ""
                      : priceChanges[1] === ">"
                      ? "odds-raise odds-raise-arrow"
                      : priceChanges[1] === "<"
                      ? "odds-drop odds-drop-arrow"
                      : ""
                  } odds_test_selector`}
                  onClick={this.addBet.bind(this, odd, 2)}
                >
                  <OddsFormat price={prices[1]} isActive={isActives[1]} />
                </div>
              ) : (
                // <div className={`${this.checkSelectedOdd(odd, 1) ? 'selected-odds-right' : ''} odds-btn price-border border-odds-right flex-end cursor-pointer font-xl font-bold-2 spacer-right ${priceChanges[1] === '=' ? '' : priceChanges[1] === '>' ? 'odds-raise odds-raise-arrow' : priceChanges[1] === '<' ? 'odds-drop odds-drop-arrow' : ''}`} onClick={this.addBet.bind(this, odd, 1)}><OddsFormat price={prices[1]} isActive={isActives[1]} /></div>
                // <div className={`${this.checkSelectedOdd(odd, 1) ? 'selected-odds-right' : ''} odds-btn price-border border-odds-right flex-end cursor-pointer ${showMyTicketsPanel === true ? 'font-xs' : 'font-xl'} font-bold-2 spacer-right ${priceChanges[1] === '=' ? '' : priceChanges[1] === '>' ? 'odds-raise odds-raise-arrow' : priceChanges[1] === '<' ? 'odds-drop odds-drop-arrow' : ''}`} onClick={this.addBet.bind(this, odd, 1)}><OddsFormat price={prices[1]} isActive={isActives[1]} /></div>
                <div
                  className={`${
                    this.checkSelectedOdd(odd, 1) ? "selected-odds-right" : ""
                  } odds-btn price-border border-odds-right flex-end cursor-pointer font-xl font-bold-2 spacer-right ${
                    priceChanges[1] === "="
                      ? ""
                      : priceChanges[1] === ">"
                      ? "odds-raise odds-raise-arrow"
                      : priceChanges[1] === "<"
                      ? "odds-drop odds-drop-arrow"
                      : ""
                  } odds_test_selector`}
                  onClick={this.addBet.bind(this, odd, 1)}
                >
                  <OddsFormat price={prices[1]} isActive={isActives[1]} />
                </div>
              )}

              {selectionIds?.length > 2 ? (
                <div
                  className={`${
                    this.checkSelectedOdd(odd, 2) ? "selected-odds-left" : ""
                  } selection-label border-odds-left flex-start markets-btn font-selection-label cursor-pointer ${
                    priceChanges[2] === "="
                      ? ""
                      : priceChanges[2] === ">"
                      ? "odds-raise"
                      : priceChanges[2] === "<"
                      ? "odds-drop"
                      : ""
                  } odds_test_selector`}
                  onClick={this.addBet.bind(this, odd, 2)}
                >
                  {Number(prices[2]) <= 1 && prices[2] !== null ? (
                    <span style={{ color: "var(--grey-5)" }}>
                      {this.getSelectionLabel(selectionIds[2], game)}
                    </span>
                  ) : Number(isActives[2]) === 0 ? (
                    <span style={{ color: "var(--grey-5)" }}>
                      {this.getSelectionLabel(selectionIds[2], game)}
                    </span>
                  ) : (
                    this.getSelectionLabel(selectionIds[2], game)
                  )}
                </div>
              ) : null}

              {selectionIds?.length > 2 ? (
                // <div className={`${this.checkSelectedOdd(odd, 2) ? 'selected-odds-right' : ''} odds-btn border-odds-right flex-end cursor-pointer font-xl font-bold-2 spacer-right ${priceChanges[2] === '=' ? '' : priceChanges[2] === '>' ? 'odds-raise odds-raise-arrow' : priceChanges[2] === '<' ? 'odds-drop odds-drop-arrow' : ''}`} onClick={this.addBet.bind(this, odd, 2)}><OddsFormat price={prices[2]} isActive={isActives[2]} /></div>
                // <div className={`${this.checkSelectedOdd(odd, 2) ? 'selected-odds-right' : ''} odds-btn border-odds-right flex-end cursor-pointer ${showMyTicketsPanel === true ? 'font-xs' : 'font-xl'} font-bold-2 spacer-right ${priceChanges[2] === '=' ? '' : priceChanges[2] === '>' ? 'odds-raise odds-raise-arrow' : priceChanges[2] === '<' ? 'odds-drop odds-drop-arrow' : ''}`} onClick={this.addBet.bind(this, odd, 2)}><OddsFormat price={prices[2]} isActive={isActives[2]} /></div>
                <div
                  className={`${
                    this.checkSelectedOdd(odd, 2) ? "selected-odds-right" : ""
                  } odds-btn border-odds-right flex-end cursor-pointer font-xl font-bold-2 spacer-right ${
                    priceChanges[2] === "="
                      ? ""
                      : priceChanges[2] === ">"
                      ? "odds-raise odds-raise-arrow"
                      : priceChanges[2] === "<"
                      ? "odds-drop odds-drop-arrow"
                      : ""
                  } odds_test_selector`}
                  onClick={this.addBet.bind(this, odd, 2)}
                >
                  <OddsFormat price={prices[2]} isActive={isActives[2]} />
                </div>
              ) : null}

              {<div className="hidden"></div>}
            </div>

            {renderMarketName && this.state.oddsChartsMarketId === marketId ? (
              <div>
                <OddsChart
                  leagueId={game.leagueID}
                  gameId={game.gameid}
                  marketId={marketId}
                  hc={hc}
                  selectionIds={selectionIds}
                />
              </div>
            ) : null}
          </div>
        );
      });
    } else {
      return (
        <div
          key={parseInt(Math.random() * 100) + parseInt(Math.random() * 1000)}
        >
          {/* <div className={`market-box-2-hc-cols`}>
            <div className="flex-end spacer-right font-s"></div>
            <div className="flex-end spacer-right font-s"></div>

            <div className={`selection-label flex-start font-selection-label cursor-pointer markets-btn border-odds-left`}></div>

            <div className="price-border font-bold flex-end cursor-pointer font-bold-2 spacer-right odds-btn border-odds-right font-xl">
              <OddsFormat price={0} isActive={false} />
            </div>

            <div className={`selection-label flex-start font-selection-label cursor-pointer markets-btn border-odds-left`}></div>

            <div  className="odds-btn price-border border-odds-right flex-end cursor-pointer font-bold-2 spacer-right font-xl">
              <OddsFormat price={0} isActive={false} />
            </div>

            <div className={'hidden'} />
          </div> */}

          <div
            className={`vertical-center content-center font-xl`}
            style={{ height: "100%" }}
          >
            <OddsFormat price={0} isActive={false} />
          </div>
        </div>
      );
    }
  }

  render() {
    log(this.constructor.name, "render");
    const { game, odds, marketsLayout, tabMenuValue } = this.props;

    if (!odds)
      return (
        <div
          className={"vertical-center font-xl content-center"}
          style={{ height: "15em" }}
        >
          <Translate value="labels.no_odds" />
        </div>
      );

    if (game.state === "FT" && new Date().getTime() > game.endTime) {
      return null;
    }

    return (
      <div>
        {marketsLayout === "vertical"
          ? this.renderVerticalList()
          : this.renderHorizontalSets()}
        {this.state.oddsHistoryMarketId ? (
          <OddsHistory
            leagueId={game.leagueID}
            gameId={game.gameid}
            forwardRef={(c) => {
              this.openDrawer = c;
            }}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let game = ownProps.game;
  let marketSetConfig, marketSetKey, marketsLayout;

  marketSetConfig = state.settings ? state.settings.marketSets : null;
  marketSetKey = ownProps.marketSetKey ? ownProps.marketSetKey : "mainbets";
  marketsLayout =
    ownProps.mode === "detailed" || marketSetKey === "favorites"
      ? "vertical"
      : state.settings.marketsLayout;

  return {
    gameAllOdds: ownProps?.gameAllOdds,
    searchQuery: ownProps?.searchQuery,
    game: game,
    selectedLeague: state.selected.leagues[game["leagueID"]],
    odds: ownProps.odds,
    marketViewerLabels: state.labels.market ? state.labels.market.viewer : null,
    selectionPrintLabels: state.labels.selection
      ? state.labels.selection.print
      : null,
    selectedSlipTemplate: state.selected.slipTemplate,
    leagueFilter: state.leagues._leagueFilter,
    // slipTemplates: state.slips,
    // bets: Object.values(state.selectedBets),
    bets:
      state.slips && state.slips[state.selected.slipTemplate].settings?.bets
        ? Object.values(state.slips[state.selected.slipTemplate].settings.bets)
        : null,
    marketSetConfig: marketSetConfig,
    marketSetKey: marketSetKey,
    marketsLayout: marketsLayout,
    mode: ownProps.mode ? ownProps.mode : null,
    layout: state.settings.layout,
    showMyTicketsPanel: state.settings.showMyTicketsPanel,
    from: ownProps.from ? ownProps.from : "default",
    isSideBetOpen: ownProps?.isSideBetOpen,
    maxSelections:
      state.slips && state.slips[state.selected.slipTemplate].settings
        ? state.slips &&
          state.slips[state.selected.slipTemplate].settings?.maxSelections
        : 15,
  };
};

export default connect(mapStateToProps, {
  saveBet,
  deleteBet,
  saveMarketSetConfig,
  toggleMyTicketsPanel,
  updateOddsInLeague,
  getOddsHistory,
  setLeagueMenuValue,
})(Odds);
