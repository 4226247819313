/**
 * Component for game box detail avatar svg
 */

import { log } from '../../config/log';
import React from 'react';
import { connect } from 'react-redux';

import './GameBoxDetailedAvatar.css';

class GameBoxDetailedAvatarTE extends React.PureComponent {

  renderShirt() {
    const { pattern, color1, color2 } = this.props;

    switch (pattern) {

      case "1":
        return (
          <div>
            <div className="competitor-avatar-bg-tennis"></div>
            <svg className="competitor-avatar-img" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="3em" height="3em"
              viewBox="0 0 520.7 565.9" style={{ enableBackground: "0 0 520.7 565.9" }} xmlSpace="preserve">
              <g>
                <g>
                  <g>
                    <path fill={color1} d="M488.6,270.4c-16.5-116.2-39.9-172.6-45.2-180C436,79.8,430.4,77,429.1,76.5c0-0.1,0-0.1,0-0.1
                      L317.8,31.7c0,0-18.8,14.5-55.8,13.2C225.1,46.2,206,31.7,206,31.7L94.7,76.4c0,0-6.1,1.8-14.7,14c-4.9,7-25.5,56.2-41.6,156.6
                      c-1.3,7.9-2.5,16.1-3.7,24.6c-3.5,24.5,75.2,3.8,75.2,3.8l2.9-19.6c0.9,15.1,1.4,29.1,1.4,41.3c0,74.4-19.4,238.5-19.4,238.5
                      c79.4,19.6,167.2,20,167.2,20s87.8-0.3,167.2-20c0,0-19.4-164.1-19.4-238.5c0-11.8,0.5-25.4,1.3-39.9l3.4,23
                      C414.3,280.1,492.2,295.2,488.6,270.4z"/>
                  </g>
                </g>
              </g>
            </svg>
          </div>
        );

      default:
        return (
          <div>no pattern</div>
        );
    }


  }

  render() {
    log(this.constructor.name, 'render');
    return <div>{this.renderShirt()}</div>
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    pattern: ownProps.pattern,
    color1: ownProps.color1,
    color2: ownProps.color2
  };
};

export default connect(mapStateToProps)(GameBoxDetailedAvatarTE);
