// Precanned banner card
import "./style.css";
import { icons } from "../../events/SportsTabs";
import locales from "../../../helpers/datefns-locales";
import format from "date-fns/format";
import { useSelector, useDispatch } from "react-redux";
import Stack from "@mui/material/Stack";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import GameBoxDetailedAvatarShort from "../../events/GameBoxDetailedAvatarShortBB";
import GameBoxDetailedAvatarLong from "../../events/GameBoxDetailedAvatarLongBB";
import GameBoxDetailedAvatarLongPS from "../../events/GameBoxDetailedAvatarLongPS";
import GameBoxDetailedAvatarBX from "../../events/GameBoxDetailedAvatarBX";
import GameBoxDetailedAvatarBB from "../../events/GameBoxDetailedAvatarBB";
import GameBoxDetailedAvatarSN from "../../events/GameBoxDetailedAvatarSN";
import GameBoxDetailedAvatarFB from "../../events/GameBoxDetailedAvatarFBBB";
import GameBoxDetailedAvatarTE from "../../events/GameBoxDetailedAvatarTE";
import GameBoxDetailedAvatarNo from "../../events/GameBoxDetailedAvatarNoBB";
import { calcGame, checkGameSession } from "../../../helpers/gameFunctions";
import CommitIcon from "@mui/icons-material/Commit";
import OddsFormat from "../../OddsFormat";
import { useNavigate } from "react-router-dom";
import {
  toggleMyTicketsPanel,
  saveBet,
  emptySlipTemplate,
} from "../../../actions";

const constructorName = "PrecannedCard";

const PrecannedCard = ({ game, bet }) => {
  //  action dispatcher
  const dispatch = useDispatch();
  // navigate
  const navigate = useNavigate();
  // select data
  const selectedSlipTemplate = useSelector(
    (state) => state.selected.slipTemplate
  );
  const lang = useSelector((state) => state.i18n.locale);
  const layout = useSelector((state) => state.settings.layout);

  const createJersey = (
    teamColor,
    teamHorStripes,
    teamNumber,
    teamSleeve,
    teamSplit,
    teamSquares,
    teamStripes,
    teamSleeveDetail,
    sportCode,
    isTeam1
  ) => {
    const defaultColor = isTeam1 ? "bdbdbd" : "636363";
    const defaultNumberColor = isTeam1 ? "f5f5f5" : "030303";
    const teamSleeveDetailColor =
      teamSleeveDetail != "" ? teamSleeveDetail : teamSleeve;
    const teamSplitColor =
      teamSplit != "false" ? teamSleeveDetailColor : "ffffff00";
    const teamHorStripesColor =
      teamHorStripes != "false" ? teamSleeveDetailColor : "ffffff00";
    const teamStripesColor =
      teamStripes != "false" ? teamSleeveDetailColor : "ffffff00";
    const teamSquaresColor =
      teamSquares != "false" ? teamSleeveDetailColor : "ffffff00";
    const teamSleeveColor = teamSleeve != "" ? teamSleeve : "ffffff00";

    return (
      <div className="precanned-game-team">
        {game?.sport_code === "FB" && (
          <GameBoxDetailedAvatarFB
            pattern="1"
            color1={`#${teamColor || defaultColor}`}
            color2={`#${teamNumber || defaultNumberColor}`}
            color3={`#${teamHorStripesColor}`}
            color4={`#${teamSleeveColor}`}
            color5={`#${teamSplitColor}`}
            color6={`#${teamSquaresColor}`}
            color7={`#${teamStripesColor}`}
          />
        )}
        {game?.sport_code === "BA" && (
          <GameBoxDetailedAvatarNo
            pattern="1"
            color1={`#${teamColor || defaultColor}`}
            color2={`#${teamNumber || defaultNumberColor}`}
          />
        )}
        {(game?.sport_code === "AF" ||
          game?.sport_code === "FL" ||
          game?.sport_code === "VB" ||
          game?.sport_code === "HB") && (
          <GameBoxDetailedAvatarShort
            pattern="1"
            color1={`#${teamColor || defaultColor}`}
            color2={`#${teamNumber || defaultNumberColor}`}
          />
        )}
        {(game?.sport_code === "IH" || game?.sport_code === "BD") && (
          <GameBoxDetailedAvatarLong
            pattern="1"
            color1={`#${teamColor || defaultColor}`}
            color2={`#${teamNumber || defaultNumberColor}`}
          />
        )}
        {(game?.sport_code === "TE" || game?.sport_code === "DA") && (
          <GameBoxDetailedAvatarTE
            pattern="1"
            color1={`#${teamColor || defaultColor}`}
            color2={`#${teamNumber || defaultNumberColor}`}
          />
        )}
        {(game?.sport_code === "BX" || game?.sport_code === "MM") && (
          <GameBoxDetailedAvatarBX
            pattern="1"
            color1={`#${teamColor || defaultColor}`}
            color2={`#${teamNumber || defaultNumberColor}`}
          />
        )}
        {game?.sport_code === "PS" && (
          <GameBoxDetailedAvatarLongPS
            pattern="1"
            color1={`#${teamColor || defaultColor}`}
            color2={`#${teamNumber || defaultNumberColor}`}
          />
        )}
        {game?.sport_code === "SN" && (
          <GameBoxDetailedAvatarSN
            pattern="1"
            color1={`#${teamColor || defaultColor}`}
            color2={`#${teamNumber || defaultNumberColor}`}
          />
        )}
        {game?.sport_code === "BB" && (
          <GameBoxDetailedAvatarBB
            pattern="1"
            color1={`#${teamColor || defaultColor}`}
            color2={`#${teamNumber || defaultNumberColor}`}
          />
        )}
        {!game?.sport_code && (
          <GameBoxDetailedAvatarLong
            pattern="1"
            color1={`#${teamColor || defaultColor}`}
            color2={`#${teamNumber || defaultNumberColor}`}
          />
        )}
      </div>
    );
  };

  const handleAddToBetslip = () => {
    let gameCopy = { ...game };
    const returnedGame = checkGameSession(gameCopy, "all");
    if (returnedGame) {
      gameCopy = calcGame(returnedGame);
    }
    let payload = {
      game: gameCopy,
      gameId: game?.gameid,
      consumerFixtureId: `sr:match:${game?.gameid}`,
      isLive: false,
      isBetBuilder: true,
      isPrecanned: true,
      marketId: undefined,
      betslipUid: bet.betslipUid,
      price: bet.price,
      isActive: true,
      isPrecannedBet: true,
      hc: "",
      selectionId: bet.betslipUid,
      description: bet.description,
      translations: [],
      identifier: bet.identifier,
      marketDescription: bet.marketDescription,
      isMultiBet: 0,
      sn: bet.description?.toString(),
    };
    dispatch(toggleMyTicketsPanel(constructorName, true));
    dispatch(emptySlipTemplate(selectedSlipTemplate, constructorName));
    dispatch(saveBet(payload, selectedSlipTemplate, constructorName));
    if (layout === "touch") {
      navigate(`/${lang}/slip`);
    }
  };

  const getOldPrice = () => {
    const currentPrice = parseFloat(bet.price);
    let currentFloor = currentPrice * 0.95;
    if (currentFloor) {
      currentFloor = currentFloor?.toFixed(1);
      // currentFloor = currentFloor?.toFixed(2);
    }

    return currentFloor;
  };

  let selectionsArray =
    bet && bet?.description&& typeof bet?.description === 'object' && bet?.description?.length ? bet.description : [];

  let selections = [];

  selectionsArray?.forEach((element) => {
    const elements = element?.split(",");
    elements?.forEach((el) => {
      selections.push(el);
    });
  });

  return (
    <div className="featured-game-container">
      <div className="featured-game-header">
        <div style={{ margin: "0 1em 0 0", justifySelf: "right" }}>
          {icons[game?.sport_code]}
        </div>
        <div className="featured-outright-logo">
          <span className="featured-banner-title">
            {game?.leagueName ? game?.leagueName : "League name not available"}
          </span>
          <p
            className="text-align-center font-bold font-xs"
            style={{ margin: "0" }}
          >
            {game?.startTime
              ? format(new Date(game?.startTime), "dd.MM. HH:mm", {
                  locale: locales[lang],
                })
              : null}
          </p>
        </div>
        <div id="league-country-flag" style={{ margin: "0 0 0 1em" }}>
          <img
            src={`https://api.nubet.com/media/flags/${game?.countryCode}.png`}
            alt="flag"
          />
        </div>
      </div>
      <div className="precanned-info-container">
        <div className="precanned-game-teams-container">
          {createJersey(
            game?.team1_color,
            game?.team1_horizontal_stripes,
            game?.team1_number,
            game?.team1_sleeve,
            game?.team1_split,
            game?.team1_squares,
            game?.team1_stripes,
            game?.team1_sleeve_detail,
            game?.sport_code,
            true
          )}
          <div className="precanned-game-team-separator">vs</div>
          {createJersey(
            game?.team2_color,
            game?.team2_horizontal_stripes,
            game?.team2_number,
            game?.team2_sleeve,
            game?.team2_split,
            game?.team2_squares,
            game?.team2_stripes,
            game?.team2_sleeve_detail,
            game?.sport_code,
            false
          )}
          <div className="precanned-game-team">
            <span className="featured-game-team-name">{game?.team1}</span>
          </div>
          <div></div>
          <div className="precanned-game-team">
            <span className="featured-game-team-name">{game?.team2}</span>
          </div>
        </div>
        <div className="precanned-timeline-container">
          {selections?.map((des, indx) => (
            <Stack
              key={indx}
              direction="row"
              alignItems="center"
              gap={1}
              sx={{ margin: "0 0 -0.3em 0" }}
            >
              <CommitIcon sx={{ fontSize: "1.5em" }} className="rotated" />
              <span className="font-xm">{des}</span>
            </Stack>
          ))}
        </div>
      </div>
      <div className="precanned-card-footer ">
        <div
          className="precanned-total-odds-container cursor-pointer"
          onClick={handleAddToBetslip}
        >
          <Stack direction="row" alignItems="center" gap={1}>
            <del className="color-odds font-xl font-bold-2 prev-odd">
              <OddsFormat isActive={true} price={getOldPrice()} />
            </del>
            <DoubleArrowIcon
              fontSize="small"
              style={{ fontSize: "1.4em", color: "var(--color-odds)" }}
            />
            <span className="color-odds font-xl font-bold-2">
              <OddsFormat isActive={true} price={bet.price} />
            </span>
          </Stack>
        </div>
      </div>
    </div>
  );
};

export default PrecannedCard;
