/**
 *  Component for showing game verticalTimeline widget from widgets.sir.sportradar.com service
 */
 
import React from 'react';
import './theme.css';

const VerticalTimeline = (props) => {
   React.useEffect(() => {
    setTimeout(() => {
      window.SIR("addWidget", ".sr-widget-1", "match.verticalTimeline", {
        matchId: props.gameId,
        disableMatchHeader: true,
        filter: 'goals, cards, penalties, substitutions',
        eventsLayoutOption: "toSides"
      });
    }, 500);
  }, [props.gameId]);

  return (
    <div className="widgets">
      <div className="sr-widget sr-widget-1"><div style={{padding: "10px 5px"}}>Loading...</div></div>
    </div>
  );
}

export default VerticalTimeline;