/**
 * SVG component for own icon 
 */

import React from 'react';
import './IconOwn.css';

const IconCAS = (props) => {

	return (
		<div style={{ display: "flex" }}>
			<svg className="icon-own-drawer" viewBox="0 0 2000 2000">
				<linearGradient gradientTransform="rotate(90)" id="icongradient">
					<stop className="icon-color-1" offset="0%" />
					<stop className="icon-color-2" offset="50%" />
					<stop className="icon-color-1" offset="100%" />
				</linearGradient>
				<g><path fill="url(#icongradient)" d="M1000,83.8c-505.2,0-916.2,411-916.2,916.2s411,916.2,916.2,916.2c505.2,0,916.2-411,916.2-916.2S1505.2,83.8,1000,83.8z
		 M926.3,155.1h142.1v142.1H926.3V155.1z M1639.9,1000c0,352.8-287,639.9-639.9,639.9c-352.8,0-639.9-287.1-639.9-639.9
		s287-639.9,639.9-639.9C1352.8,360.1,1639.9,647.2,1639.9,1000z M452.8,352.3l100.5,100.5L452.8,553.3L352.3,452.8L452.8,352.3z
		 M294.6,1071.1H152.5V928.9h142.1V1071.1z M452.8,1647.7l-100.5-100.5l100.5-100.5l100.5,100.5L452.8,1647.7z M1068.4,1844.9H926.3
		v-142.1h142.1V1844.9z M1547.2,1647.7l-100.5-100.5l100.5-100.5l100.5,100.5L1547.2,1647.7z M1547.2,553.3l-100.5-100.5
		l100.5-100.5l100.5,100.5L1547.2,553.3z M1700.1,1071.1V928.9h142.1v142.1H1700.1z"/>
					<path fill="url(#icongradient)" d="M478.6,1000c0,287.5,233.9,521.4,521.4,521.4c287.5,0,521.4-233.9,521.4-521.4S1287.5,478.6,1000,478.6
		C712.5,478.6,478.6,712.5,478.6,1000z M1249.3,955.8c24.6,40,32.5,75.6,32.5,117.9c0,104.5-73.7,160.6-157.5,160.6
		c-57.2,0-89.7-27-107.5-49.5l30.3,107.4h-95.3l29.6-104.9c-18.3,21.8-50.4,49.1-105.5,49.1c-83.8,0-157.9-57.2-157.9-161.7
		c0-42.3,7.7-78.9,32.3-118.9c38.3-62.4,250.1-242,250.1-242S1211,893.4,1249.3,955.8z"/></g>
			</svg>
		</div>
	);
};

export default IconCAS;
