/**
 * Reducer for slip
 ** Creating , deleting, saving, duplicating, importing, exporting slip template, saving bet, updating odds
 ** Updating stake value, placing a bet, emptying slip template
 */
import {
  SAVE_SLIP_TEMPLATE,
  CREATE_SLIP_TEMPLATE,
  DELETE_SLIP_TEMPLATE,
  COPY_SLIP_TEMPLATE,
  IMPORT_SLIP_TEMPLATE,
  EXPORT_SLIP_TEMPLATE,
  SAVE_BET,
  UPDATE_ODDS_IN_SLIP,
  DELETE_BET,
  UPDATE_BET_WITH_LOWER_PRICE,
  UPDATE_STAKE,
  EMPTY_SLIP_TEMPLATE,
  DELETE_ENDED_SLIP_GAMES,
} from "../actions/types";
import { v4 as uuid } from "uuid";

import { calculateSlip } from "../helpers/slipCalculatorService";

export const createEmptyTemplate = (slipTemplate) => {
  return {
    slipTemplate: slipTemplate,
    stake: 10,
    betType: "single",
    hiddenTab: "combi",
    disabledActions: [],
    outrightExceeded: false,
    multipleOutright: false,
    settings: {
      outrightLeaguesCount: 0,
      maxSelections: 15,
      maxCombinations: 1024,
      combiCounts: 0,
      bets: {},
      totalBetsPerBetType: 0,
      stakePerBet: 10,
      stakePerBetReal: 10,
      combiStakes: [],
      combiTypes: [],
      odds: 0,
      maxOdds: 0,
      winMin: 0,
      winMax: 0,
      cntBanks: 0,
      bankIds: [],
      cntMultiBets: 0,
      hasMulti: false,
      multis: [],
      multiOdds: [],
      eventsInSlip: [],
      activeCombinations: [],
      realCombiStakes: [],
      availableCombiTypes: [],
      availableCombiTexts: [],
      availableCombiCounts: [],
      availableCombiWinMin: [],
      availableCombiWinMax: [],
      keepSystemTypeSettings: false,
      stakeCalculationType: "total",
    },
  };
};

const INITIAL_STATE = {
  0: createEmptyTemplate(0),
};

let stateCopy, bet, bets, totalBets;

function jsonCopy(src) {
  return JSON.parse(JSON.stringify(src));
}

const updateBetOdds = (bets, newOdds) => {
  for (let key in bets) {
    let bet = bets[key];
    const filteredOdds = newOdds.filter((item) => item.gId === bet.gameId && item.m === bet.marketId && item.s === bet.selectionId && item.hc === bet.hc);

    if (filteredOdds?.length) {
      let newOdd = filteredOdds[0];

      if (parseFloat(bet.price) === parseFloat(newOdd.p)) {
        bet["priceChanged"] = "=";
        bet.isPriceUppered = false;
        delete bet.tempPrice;
      }

      if (parseFloat(bet.price) < parseFloat(newOdd.p)) {
        bet["priceChanged"] = ">";

        bet.tempPrice = jsonCopy(bet.price);
        bet.price = jsonCopy(newOdd.p);
        bet.isPriceUppered = true;
        // delete bet.tempPrice;
      }

      if (parseFloat(bet.price) > parseFloat(newOdd.p)) {
        bet["priceChanged"] = "<";
        bet.isPriceUppered = false;
        bet.tempPrice = newOdd.p;
      }

      bet.isActive = newOdd.iA;
      if (bet?.nr === 0 && newOdd?.isNonRunner === 1) {
        bet.nr = newOdd?.isNonRunner;
      } else if (bet?.nr === 1 && newOdd.isNonRunner === 0) {
        bet.nr = newOdd?.isNonRunner;
      }
    }
  }
};

const fn = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SAVE_SLIP_TEMPLATE:
      stateCopy = { ...state };
      stateCopy[action.payload.slipTemplate] = { ...action.payload };
      stateCopy = calculateSlip(stateCopy);
      return stateCopy;

    case UPDATE_STAKE:
      stateCopy = { ...state };

      stateCopy[action.slipTemplate].stake = action.payload;

      stateCopy = calculateSlip(stateCopy);
      return stateCopy;

    case CREATE_SLIP_TEMPLATE:
      stateCopy = {
        ...state,
        [action.slipTemplate]: createEmptyTemplate(action.slipTemplate),
      };
      stateCopy = calculateSlip(stateCopy);
      return stateCopy;

    case COPY_SLIP_TEMPLATE:
      stateCopy = { ...state };
      let copyTemplate = JSON.parse(JSON.stringify(stateCopy[action.slipTemplate]));
      copyTemplate.slipTemplate = Object.keys(stateCopy)?.length;
      stateCopy = {
        ...stateCopy,
        [Object.keys(stateCopy)?.length]: { ...copyTemplate },
      };

      stateCopy = calculateSlip(stateCopy);
      return stateCopy;

    case IMPORT_SLIP_TEMPLATE:
      stateCopy = {
        ...state,
        [Object.keys(state)?.length]: action.slipTemplateData,
      };

      stateCopy = calculateSlip(stateCopy);
      return stateCopy;

    case EXPORT_SLIP_TEMPLATE:
      let exportTemplate = JSON.parse(JSON.stringify(state[action.slipTemplate]));
      exportTemplate.slipTemplate = Object.keys(state)?.length;

      // let objJsonStr = JSON.stringify(exportTemplate);
      // let exportData = Buffer.from(objJsonStr).toString("base64");

      return { ...state };

    case DELETE_SLIP_TEMPLATE:
      stateCopy = { ...state };
      delete stateCopy[action.slipTemplate];

      stateCopy = calculateSlip(stateCopy);
      return stateCopy;

    case SAVE_BET:
      
      stateCopy = { ...state };
      
      bet = action.payload;
      bet.isMultiBet = 0;
      bet.isBank = 0;
      let singleGrouped = false;

      bets = stateCopy[action.slipTemplate].settings.bets;
      let duplicate = false;
      const unique_id = uuid();

      for (let key in bets) {
        if (bets[key].gameId === bet.gameId && bets[key].marketId === bet.marketId && bets[key].selectionId === bet.selectionId && bets[key].hc === bet.hc && bets[key].shc === bet.shc) {
          delete bets[key];
          duplicate = true;
        }
      }

      if (duplicate === false) stateCopy[action.slipTemplate].settings.bets[unique_id] = action.payload;

      totalBets = Object.keys(stateCopy[action.slipTemplate].settings.bets)?.length;

      let totalBetsValues = Object.values(stateCopy[action.slipTemplate].settings.bets);
      const grouppedGames = {};
      const leagueFilterAll = {};

      totalBetsValues?.forEach((betGame) => {
        if (!grouppedGames[betGame.gameId]) {
          grouppedGames[betGame.gameId] = totalBetsValues.filter((each) => each.gameId === betGame.gameId);
        }

        if (!leagueFilterAll[betGame?.game?.leagueID]) {
          leagueFilterAll[betGame?.game?.leagueID] = totalBetsValues.filter((item) => item.game.leagueID === betGame?.game?.leagueID);
        }
      });

      Object.values(grouppedGames)?.forEach((betsArray) => {
        if (betsArray?.length > 1) {
          singleGrouped = true;
        }
      });
      stateCopy[action.slipTemplate].multipleOutright = false;

      const filteredOutrights = totalBetsValues.filter((item) => item?.isOutright && item?.game?.sport_code !== "TR");
      let multipleFromSameLeague = false;

      Object.values(leagueFilterAll)?.forEach((leagueArray) => {
        const outrightsOfLeague = leagueArray.filter((item) => item?.isOutright);
        if (outrightsOfLeague?.length > 0 && outrightsOfLeague?.length < leagueArray?.length) {
          stateCopy[action.slipTemplate].multipleOutright = true;
        }
        if (totalBetsValues?.length === filteredOutrights?.length && leagueArray?.length > 1) {
          multipleFromSameLeague = true;
        }
      });

      let outrightLeagues = [];

      filteredOutrights?.forEach((outright) => {
        const findIfExist = outrightLeagues.find((league) => league?.leagueId === outright?.game?.leagueID && league?.sport_code === outright?.game?.sport_code);
        if (!findIfExist) {
          outrightLeagues.push({
            sport_code: outright.game?.sport_code,
            leagueId: outright?.game?.leagueID,
          });
        }
      });

      stateCopy[action.slipTemplate]["settings"].outrightLeaguesCount = outrightLeagues?.length;
      if (totalBets < 2) {
        stateCopy[action.slipTemplate].outrightExceeded = false;
        stateCopy[action.slipTemplate].betType = "single";
        stateCopy[action.slipTemplate].disabledActions = ["combi", "system"];
        stateCopy[action.slipTemplate].hiddenTab = "combi";
      } else if (filteredOutrights?.length > 1 && singleGrouped) {
        stateCopy[action.slipTemplate].outrightExceeded = true;
        stateCopy[action.slipTemplate].hiddenTab = "single";
        stateCopy[action.slipTemplate].betType = stateCopy[action.slipTemplate].betType === "single" || stateCopy[action.slipTemplate].betType === "combi" ? "system" : stateCopy[action.slipTemplate].betType;
        stateCopy[action.slipTemplate].disabledActions = ["combi"];
      } else if (filteredOutrights?.length > 1 && !singleGrouped && multipleFromSameLeague) {
        stateCopy[action.slipTemplate].outrightExceeded = true;
        stateCopy[action.slipTemplate].hiddenTab = "single";
        stateCopy[action.slipTemplate].betType = stateCopy[action.slipTemplate].betType === "single" ? "combi" : stateCopy[action.slipTemplate].betType;
        stateCopy[action.slipTemplate].disabledActions = [];
      } else if (filteredOutrights?.length > 1 && !singleGrouped && !multipleFromSameLeague) {
        stateCopy[action.slipTemplate].outrightExceeded = false;
        stateCopy[action.slipTemplate].hiddenTab = "single";
        stateCopy[action.slipTemplate].betType = stateCopy[action.slipTemplate].betType === "single" ? "combi" : stateCopy[action.slipTemplate].betType;
        stateCopy[action.slipTemplate].disabledActions = [];
      } else if (totalBets > 1 && !singleGrouped && filteredOutrights?.length <= 1) {
        stateCopy[action.slipTemplate].outrightExceeded = false;
        stateCopy[action.slipTemplate].hiddenTab = "single";
        stateCopy[action.slipTemplate].betType = stateCopy[action.slipTemplate].betType === "single" ? "combi" : stateCopy[action.slipTemplate].betType;
        stateCopy[action.slipTemplate].disabledActions = [];
      } else if (totalBets > 1 && singleGrouped) {
        stateCopy[action.slipTemplate].outrightExceeded = false;
        stateCopy[action.slipTemplate].hiddenTab = "single";
        stateCopy[action.slipTemplate].betType = stateCopy[action.slipTemplate].betType === "single" || stateCopy[action.slipTemplate].betType === "combi" ? "system" : stateCopy[action.slipTemplate].betType;
        stateCopy[action.slipTemplate].disabledActions = ["combi"];
      }
      stateCopy = calculateSlip(stateCopy);
      return stateCopy;
    case DELETE_BET:
      stateCopy = { ...state };
      bet = action.payload;
      bets = stateCopy[action.slipTemplate].settings.bets;
      let clearedSingleGrouped = false;

      for (let key in bets) {
        if (bets[key].gameId === bet.gameId && bets[key].marketId === bet.marketId && bets[key].selectionId === bet.selectionId && bets[key].hc === bet.hc && bets[key].shc === bet.shc) {
          delete bets[key];
        }
      }

      totalBets = Object.keys(stateCopy[action.slipTemplate].settings.bets)?.length;
      let totalBetsValuesToClear = Object.values(stateCopy[action.slipTemplate].settings.bets);
      const clearGrouppedGames = {};
      const leagueFilterAllClear = {};

      totalBetsValuesToClear?.forEach((betGame) => {
        if (!clearGrouppedGames[betGame.gameId]) {
          clearGrouppedGames[betGame.gameId] = totalBetsValuesToClear.filter((each) => each.gameId === betGame.gameId);
        }
        if (!leagueFilterAllClear[betGame?.game?.leagueID]) {
          leagueFilterAllClear[betGame?.game?.leagueID] = totalBetsValuesToClear.filter((item) => item.game.leagueID === betGame?.game?.leagueID);
        }
      });

      Object.values(clearGrouppedGames)?.forEach((betsArray) => {
        if (betsArray?.length > 1) {
          clearedSingleGrouped = true;
        }
      });

      stateCopy[action.slipTemplate].multipleOutright = false;

      const filteredOutrightsClear = totalBetsValuesToClear.filter((item) => item?.isOutright && item?.game?.sport_code !== "TR");

      let multipleFromSameLeagueClear = false;

      Object.values(leagueFilterAllClear)?.forEach((leagueArray) => {
        const outrightsOfLeague = leagueArray.filter((item) => item?.isOutright);
        if (outrightsOfLeague?.length > 0 && outrightsOfLeague?.length < leagueArray?.length) {
          stateCopy[action.slipTemplate].multipleOutright = true;
        }
        if (leagueFilterAllClear?.length === filteredOutrightsClear?.length && leagueArray?.length > 1) {
          multipleFromSameLeagueClear = true;
        }
      });

      let outrightLeaguesAll = [];

      filteredOutrightsClear?.forEach((outright) => {
        const findIfExist = outrightLeaguesAll.find((league) => league?.leagueId === outright?.game?.leagueID && league?.sport_code === outright?.game?.sport_code);
        if (!findIfExist) {
          outrightLeaguesAll.push({
            sport_code: outright.game?.sport_code,
            leagueId: outright?.game?.leagueID,
          });
        }
      });

      stateCopy[action.slipTemplate]["settings"].outrightLeaguesCount = outrightLeaguesAll?.length;

      if (totalBetsValuesToClear?.length < 2) {
        stateCopy[action.slipTemplate].outrightExceeded = false;
        stateCopy[action.slipTemplate].betType = "single";
        stateCopy[action.slipTemplate].disabledActions = ["combi", "system"];
        stateCopy[action.slipTemplate].hiddenTab = "combi";
      } else if (totalBetsValuesToClear?.length > 1 && filteredOutrightsClear?.length > 1 && clearedSingleGrouped) {
        stateCopy[action.slipTemplate].outrightExceeded = true;
        stateCopy[action.slipTemplate].disabledActions = ["single", "combi"];
        stateCopy[action.slipTemplate].hiddenTab = "single";
        stateCopy[action.slipTemplate].betType = stateCopy[action.slipTemplate].betType === "single" || stateCopy[action.slipTemplate].betType === "combi" ? "system" : stateCopy[action.slipTemplate].betType;
      } else if (totalBetsValuesToClear?.length > 1 && filteredOutrightsClear?.length > 1 && !clearedSingleGrouped && multipleFromSameLeagueClear) {
        stateCopy[action.slipTemplate].outrightExceeded = true;
        stateCopy[action.slipTemplate].disabledActions = ["single"];
        stateCopy[action.slipTemplate].hiddenTab = "single";
        stateCopy[action.slipTemplate].betType = stateCopy[action.slipTemplate].betType === "single" ? "combi" : stateCopy[action.slipTemplate].betType;
      } else if (totalBetsValuesToClear?.length > 1 && filteredOutrightsClear?.length > 1 && !clearedSingleGrouped && !multipleFromSameLeagueClear) {
        stateCopy[action.slipTemplate].outrightExceeded = false;
        stateCopy[action.slipTemplate].disabledActions = ["single"];
        stateCopy[action.slipTemplate].hiddenTab = "single";
        stateCopy[action.slipTemplate].betType = stateCopy[action.slipTemplate].betType === "single" ? "combi" : stateCopy[action.slipTemplate].betType;
      } else if (totalBetsValuesToClear?.length > 1 && clearedSingleGrouped) {
        stateCopy[action.slipTemplate].outrightExceeded = false;
        stateCopy[action.slipTemplate].betType = "system";
        stateCopy[action.slipTemplate].disabledActions = ["combi"];
        stateCopy[action.slipTemplate].hiddenTab = "single";
      } else if (totalBetsValuesToClear?.length > 1 && !clearedSingleGrouped) {
        stateCopy[action.slipTemplate].outrightExceeded = false;
        stateCopy[action.slipTemplate].disabledActions = ["single"];
        stateCopy[action.slipTemplate].hiddenTab = "single";
        stateCopy[action.slipTemplate].betType = stateCopy[action.slipTemplate].betType === "single" ? "combi" : stateCopy[action.slipTemplate].betType;
      }

      stateCopy = calculateSlip(stateCopy);
      return stateCopy;

    case UPDATE_ODDS_IN_SLIP:
      stateCopy = { ...state };
      let odds = action.payload;

      if (odds?.length) {
        for (let key in stateCopy) {
          if (stateCopy[key].settings?.bets && Object.values(stateCopy[key].settings.bets)?.length) {
            updateBetOdds(stateCopy[key].settings.bets, odds);
          }
        }
      }

      for (let key in stateCopy) {
        bets = stateCopy[key].settings.bets;
        for (let betKey in bets) {
          let bet = bets[betKey];
          let game = bet.game;
          if (game.state === "FT" && new Date().getTime() >= game.endTime) {
            delete bets[betKey];
          }
        }
      }

      stateCopy = calculateSlip(stateCopy);
      return stateCopy;

    case UPDATE_BET_WITH_LOWER_PRICE:
      stateCopy = { ...state };
      bet = action.payload;
      bets = stateCopy[action.slipTemplate].settings.bets;

      for (let key in bets) {
        if (bets[key].gameId === bet.gameId && bets[key].marketId === bet.marketId && bets[key].selectionId === bet.selectionId && bets[key].hc === bet.hc) {
          bets[key].price = Number(bet.tempPrice);
          delete bet.tempPrice;
          delete stateCopy[action.slipTemplate].isPlacingBet;
        }
      }

      stateCopy = calculateSlip(stateCopy);
      return stateCopy;

    case EMPTY_SLIP_TEMPLATE:
      stateCopy = { ...state };
      stateCopy[action.payload].settings.bets = {};
      delete stateCopy[action.payload];
      if (!Object.keys(stateCopy)?.length) {
        stateCopy = { ...stateCopy, [0]: createEmptyTemplate(0) };
        stateCopy = calculateSlip(stateCopy);
        return stateCopy;
      }

      break;
    case DELETE_ENDED_SLIP_GAMES:
      stateCopy = { ...state };
      Object.values(stateCopy)?.forEach((bet, index) => {
        const slipBets = bet?.settings?.bets;
        const filteredBets = {};
        if (slipBets && Object.keys(slipBets)?.length) {
          Object.entries(slipBets)?.forEach((bet) => {
            const betValue = bet[1];
            const betKey = bet[0];
            if (betValue?.game?.time !== "end" && betValue?.game?.time !== "End") {
              filteredBets[betKey] = betValue;
            }
          });
        }
        stateCopy[bet["slipTemplate"]]["settings"]["bets"] = filteredBets;
      });
      return stateCopy;
    default:
      return state;
  }
};

export default fn;
