/**
 * League Tree Viewer Component
 */

import { log } from "../../config/log";
import React from "react";
import $ from "jquery";
import { connect } from "react-redux";
import { Translate } from "react-redux-i18n";
import withStyles from "@mui/styles/withStyles";
import {
  setLeagues,
  saveTreeViewLeagues,
  saveFavoriteLeagues,
  updateLeageuFilter,
} from "../../actions";

import Avatar from "@mui/material/Avatar";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";

import UncheckedIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckedIcon from "@mui/icons-material/CheckBox";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import NowLiveIcon from "../../media/img/now_live_icon.svg";

import TreeViewerLeaguesFilterTabs from "./TreeViewerLeaguesFilterTabs";

import "./Leagues.css";
import "./LeaguesTreeViewer.css";

const styles = (theme) => ({
  root: {
    fontSize: "var(--small)",
    "& .MuiSvgIcon-root": {
      fontSize: "1.7em",
    },
    "& .MuiTabs-root": {
      minHeight: "1em",
    },
    "& .MuiTab-root": {
      minHeight: "2.5em",
      // padding: "0 0.5em",
      minWidth: 0,
      fontSize: "1.3em",
      "@media (hover:hover)": {
        // backgroundColor: "var(--color-contrast)",
      },
    },
    "& .MuiPaper-root": {
      // boxShadow: "none",
    },
    "& .MuiTab-wrapper": {
      display: "inline-flex !important",
    },
    "& .MuiTabScrollButton-root.Mui-disabled": {
      display: "none",
    },
  },
  tabBars: {
    position: "sticky",
    top: "0",
    zIndex: 998,
    "@media (hover:hover)": {
      // borderRadius: "0.5em",
      position: "static",
    },
  },
  tabBarFavorites: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  heading: {
    // fontSize: theme.typography.pxToRem(15),
    fontSize: "1.2em",
    color: theme.palette.text.secondary,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  flexCenter: {
    display: "flex",
    alignItems: "center",
    "& div": {
      marginLeft: "5px",
    },
  },
  avatarAll: {
    backgroundColor: "transparent",
    color: "var(--grey-3)",
    border: "0.17em solid var(--grey-3)",
    borderRadius: "1rem",
    width: "1.1rem",
    height: "1.1rem",
    fontSize: "0.63rem",
    lineHeight: "inherit",
    fontWeight: "500",
    visibility: "visible",
    "@media (hover: hover) and (max-width: 1200px)": {
      fontSize: "0.473rem",
      width: "0.76rem",
      height: "0.76rem",
    },
  },
  avatarAllHidden: {
    backgroundColor: "transparent",
    color: "var(--grey-3)",
    border: "0.17em solid var(--grey-3)",
    borderRadius: "1rem",
    width: "1.1rem",
    height: "1.1rem",
    fontSize: "0.63rem",
    lineHeight: "inherit",
    fontWeight: "500",
    visibility: "hidden",
    "@media (hover: hover) and (max-width: 1200px)": {
      fontSize: "0.473rem",
      width: "0.76rem",
      height: "0.76rem",
    },
  },
  avatarLive: {
    backgroundColor: "transparent",
    color: "var(--color-contrast)",
    width: "1.4rem",
    height: "1.4rem",
    fontSize: "0.63rem",
    lineHeight: "inherit",
    fontWeight: "500",
    visibility: "visible",
    backgroundImage: `url(${NowLiveIcon})`,
    backgroundRepeat: "no-repeat",
    justifySelf: "center",
    backgroundPosition: "center",
    backgroundSize: "contain",
    "@media (hover: hover) and (max-width: 1200px)": {
      fontSize: "0.473rem",
      width: "1rem",
      height: "1rem",
    },
  },
  avatarLiveHidden: {
    backgroundColor: "transparent",
    color: "var(--color-contrast)",
    width: "1.4rem",
    height: "1.4rem",
    fontSize: "0.63rem",
    lineHeight: "inherit",
    fontWeight: "500",
    visibility: "hidden",
    backgroundImage: `url(${NowLiveIcon})`,
    backgroundRepeat: "no-repeat",
    justifySelf: "center",
    backgroundPosition: "center",
    backgroundSize: "contain",
    "@media (hover: hover) and (max-width: 1200px)": {
      fontSize: "0.473rem",
      width: "1rem",
      height: "1rem",
    },
  },
});

function isEmpty(obj) {
  for (var prop in obj) {
    if (obj.hasOwnProperty(prop)) return false;
  }

  return true;
}

class LeaguesTreeViewer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sportCode: "",
      leagueFilter: this.props.leagueFilter ? this.props.leagueFilter : "live",
    };
  }

  /**
   * Get live game and open it first, otherwise show Football leagues first
   * Implement Ripple effect
   */

  componentDidMount() {
    if (
      !this.props.leagues ||
      !this.props.leagues.treeViewLeagues ||
      isEmpty(this.props.leagues.treeViewLeagues)
    ) {
      // this.props.setLeagues(null, 'all', 'FB', this.constructor.name);
      this.props.setLeagues(
        null,
        this.state.leagueFilter,
        this.props.sportCode ? this.props.sportCode : "FB",
        this.props.locale,
        this.constructor.name
      );
    }

    $(document).on("mousedown", "[data-ripple]", function (e) {
      var $self = $(this);
      if (e.target && e.target.tagName) {
        if (e.target.tagName === "path" || e.target.tagName === "svg") return;
      }

      if ($self.hasClass("league-box-selected")) return;

      if ($self.is(".btn-disabled")) {
        return;
      }

      if ($self.closest("[data-ripple]")) {
        e.stopPropagation();
      }

      var initPos = $self.css("position"),
        offs = $self.offset(),
        x = e.pageX - offs.left,
        y = e.pageY - offs.top,
        dia = Math.min(this.offsetHeight, this.offsetWidth, 100), // start diameter
        $ripple = $("<div/>", { class: "ripple", appendTo: $self });

      if (!initPos || initPos === "static") {
        $self.css({ position: "relative" });
      }

      $("<div/>", {
        class: "rippleWave",
        css: {
          background: $self.data("ripple"),
          width: dia,
          height: dia,
          left: x - dia / 2,
          top: y - dia / 2,
        },
        appendTo: $ripple,
        one: {
          animationend: function () {
            $ripple.remove();
          },
        },
      });
    });
  }

  /**
   * Set Favorite leagues
   */

  saveFavoriteLeagues(sportCode, leagueId) {
    const { leagues } = this.props;

    let favoriteLeagues = { ...this.props.favoriteLeagues };

    if (!favoriteLeagues[sportCode]) favoriteLeagues[sportCode] = {};

    if (!favoriteLeagues[sportCode][leagueId]) {
      favoriteLeagues[sportCode][leagueId] =
        leagues[this.state.leagueFilter][sportCode][leagueId];
    } else {
      delete favoriteLeagues[sportCode][leagueId];
    }

    localStorage.setItem("favoriteLeagues", JSON.stringify(favoriteLeagues));
    // Cookies.set('favoriteLeagues', favoriteLeagues, { path: '/' });
    this.props.saveFavoriteLeagues(this.constructor.name);
    this.setState({ favoriteAdded: leagueId });
  }

  /**
   * Select and open the league
   */

  selectLeague(league) {
    const { leagues } = this.props;

    const { sportCode, leagueId } = league;

    // let treeViewLeagues = { ...this.props.treeViewLeagues };
    let treeViewLeagues = this.props.treeViewLeagues;

    if (!treeViewLeagues[sportCode]) treeViewLeagues[sportCode] = {};

    if (!treeViewLeagues[sportCode][leagueId]) {
      treeViewLeagues[sportCode][leagueId] = leagues[this.state.leagueFilter][
        sportCode
      ][leagueId]
        ? leagues[this.state.leagueFilter][sportCode][leagueId]
        : leagues["all"][sportCode][leagueId];
    } else {
      delete treeViewLeagues[sportCode][leagueId];
    }

    this.props.saveTreeViewLeagues(treeViewLeagues, this.constructor.name);
  }

  getSportName = (sportCode) => {
    const { sportLabels } = this.props;
    return sportLabels ? sportLabels.default[sportCode] : null;
  };

  /**
   * Show league based on type
   */

  renderLeagues(leagues, type) {
    const { sportCode } = this.state;
    // console.log(type);
    const { favoriteLeagues, selectedLeagues, classes } = this.props;

    if (type === "favorites") {
      return Object.values(leagues).map((league, key) => {
        if (!league.leagueId) return;

        let leagueText;
        if (league.labelCategory !== "") {
          leagueText = (
            <span>
              <span style={{ marginRight: "2px" }}>
                <b>{league.labelCategory}:</b>
              </span>
              {league.label}
            </span>
          );
        } else {
          leagueText = <b>{league.label}</b>;
        }

        let isSelected =
          selectedLeagues[league.leagueId] &&
          selectedLeagues[league.leagueId]["games"] &&
          selectedLeagues[league.leagueId].isOpen
            ? true
            : false;
        // let className = isSelected ? 'league-box-selected' : 'league-box';
        let className = isSelected
          ? "league-box-selected-left-nav"
          : "league-box";

        return (
          <div key={key} className={classes.root}>
            {/* <div className={className} data-ripple="#84b2ee"> */}
            <div className={className} data-ripple="var(--color-ripple)">
              <div
                className="vertical-center grey-3 spacer-left"
                onClick={() => {
                  this.saveFavoriteLeagues(league.sportCode, league.leagueId);
                }}
              >
                {favoriteLeagues &&
                favoriteLeagues[league.sportCode] &&
                favoriteLeagues[league.sportCode][league.leagueId] ? (
                  <CheckedIcon style={{ color: "var(--color-contrast)" }} />
                ) : (
                  <UncheckedIcon />
                )}
              </div>
              <div
                id="league-country-flag"
                className="vertical-center content-center spacer-left"
              >
                <img
                  src={`https://api.nubet.com/media/flags/${league.countryCode}.png`}
                  alt="flag"
                />
              </div>
              <div
                className="text-wrap font-xl font-bold vertical-center spacer-left"
                onClick={() => this.selectLeague(league)}
              >
                <span className="text-wrap">{leagueText}</span>
              </div>
              <div
                className="vertical-center content-right"
                onClick={() => this.selectLeague(league)}
              >
                {league.gameLiveCount > 0 ? (
                  <span>
                    <Avatar className={this.props.classes.avatarLive}>
                      {league.gameLiveCount}
                    </Avatar>
                  </span>
                ) : null}
                <span className="spacer-left">
                  <Avatar className={this.props.classes.avatarAll}>
                    {league.gameCount}
                  </Avatar>
                </span>
              </div>
            </div>
          </div>
        );
      });
    } else {
      if (Object.values(leagues)?.length === 0) {
        return;
      } else {
        let firstLeague = Object.values(leagues)[0];
        if (firstLeague.sportCode !== sportCode) {
          return;
        } else {
          return Object.values(leagues).map((league, key) => {
            if (!league.leagueId) return;

            let leagueText;
            if (league.labelCategory !== "") {
              leagueText = (
                <span>
                  <span style={{ marginRight: "2px" }}>
                    <b>{league.labelCategory}:</b>
                  </span>
                  {league.label}
                </span>
              );
            } else {
              leagueText = <b>{league.label}</b>;
            }

            let isSelected =
              selectedLeagues[league.leagueId] &&
              selectedLeagues[league.leagueId]["games"] &&
              selectedLeagues[league.leagueId].isOpen
                ? true
                : false;
            // let className = isSelected ? 'league-box-selected' : 'league-box';
            let className = isSelected
              ? "league-box-selected-left-nav"
              : "league-box-touch-left-nav";

            return (
              <div key={key} className={classes.root}>
                {/* <div className={className} data-ripple="#84b2ee"> */}
                <div className={className} data-ripple="var(--color-ripple)">
                  <div
                    className="vertical-center grey-3 spacer-left"
                    onClick={() => {
                      this.saveFavoriteLeagues(
                        league.sportCode,
                        league.leagueId
                      );
                    }}
                  >
                    {favoriteLeagues &&
                    favoriteLeagues[league.sportCode] &&
                    favoriteLeagues[league.sportCode][league.leagueId] ? (
                      <CheckedIcon style={{ color: "var(--color-contrast)" }} />
                    ) : (
                      <UncheckedIcon />
                    )}
                  </div>
                  <div
                    id="league-country-flag"
                    className="vertical-center content-center spacer-left"
                  >
                    <img
                      src={`https://api.nubet.com/media/flags/${league.countryCode}.png`}
                      alt="flag"
                    />
                  </div>
                  <div
                    className="text-wrap font-xl font-bold vertical-center spacer-left"
                    onClick={() => this.selectLeague(league)}
                  >
                    <span className="text-wrap">{leagueText}</span>
                  </div>
                  <div
                    className="vertical-center content-right"
                    onClick={() => this.selectLeague(league)}
                  >
                    {league.gameLiveCount > 0 ? (
                      <span>
                        <Avatar className={this.props.classes.avatarLive}>
                          {league.gameLiveCount}
                        </Avatar>
                      </span>
                    ) : null}
                    <span className="spacer-left">
                      <Avatar className={this.props.classes.avatarAll}>
                        {league.gameCount}
                      </Avatar>
                    </span>
                  </div>
                </div>
              </div>
            );
          });
        }
      }
    }
  }

  /**
   * Accordian Action function
   */

  openAccordian(sportCode, event, isExpanded) {
    if (isExpanded) {
      this.setState({ sportCode });
    } else {
      this.setState({ sportCode: false });
    }
  }

  /**
   * Show total games number for the league
   */

  renderGamesCounters(leagues, fromFavorites) {
    if (fromFavorites) {
      let concatedLeagues = [];
      if (leagues && Object.values(leagues)) {
        Object.values(leagues)?.forEach((league) => {
          concatedLeagues = concatedLeagues.concat(Object.values(league));
        });
      }

      leagues = concatedLeagues;
    }

    if (!leagues) return <div key="no-leagues"></div>;

    let totalAllCount = 0,
      totalLiveCount = 0;

    leagues &&
      Object.values(leagues)?.forEach((league, key) => {
        if (league.leagueId) {
          totalAllCount += Number(league.gameCount);
          totalLiveCount += Number(league.gameLiveCount);
        }
      });

    totalAllCount = totalAllCount <= 99 ? totalAllCount : "99+";
    totalLiveCount = totalLiveCount <= 99 ? totalLiveCount : "99+";

    return (
      <div className={this.props.classes.flexCenter}>
        <Avatar
          className={`${
            totalLiveCount === 0
              ? this.props.classes.avatarLiveHidden
              : this.props.classes.avatarLive
          }`}
        >
          {totalLiveCount}
        </Avatar>

        <Avatar
          className={`${
            totalAllCount === 0
              ? this.props.classes.avatarAllHidden
              : this.props.classes.avatarAll
          }`}
        >
          {totalAllCount}
        </Avatar>
      </div>
    );
  }

  /**
   * Show total games number for the league
   */

  renderTotalGamesCounter() {
    const allLeagues = this.props.leagues;
    let totalAllCount = 0,
      totalLiveCount = 0;

    let selectedLeagues = allLeagues[this.state.leagueFilter];

    if (!selectedLeagues) return <div key="no-leagues"></div>;

    for (let sportCode in selectedLeagues) {
      let leagues = selectedLeagues[sportCode];

      leagues &&
        Object.values(leagues)?.forEach((league, key) => {
          if (league.leagueId) {
            totalAllCount += Number(league.gameCount);
            totalLiveCount += Number(league.gameLiveCount);
          }
        });
    }

    totalAllCount = totalAllCount <= 99 ? totalAllCount : "99+";
    totalLiveCount = totalLiveCount <= 99 ? totalLiveCount : "99+";

    return (
      <div
        className="league-selector-legend-touch league-selector-legend-touch-left-nav"
        key="league-selector-legend-touch"
      >
        <div className="spacer-left vertical-center grey-3"></div>
        <div className="font-xs vertical-center content-right">
          <Translate value={`labels.avatar_now_live`} />
        </div>
        <div className="vertical-center spacer-left">
          <Avatar className={this.props.classes.avatarLive}>
            {totalLiveCount}
          </Avatar>
        </div>
        <div className="font-xs vertical-center content-right">
          <Translate value={`labels.avatar_all`} />
        </div>
        <div className="font-xs vertical-center content-right">
          <Avatar
            style={{ margin: "0 0.25rem 0 0" }}
            className={this.props.classes.avatarAll}
          >
            {totalAllCount}
          </Avatar>
        </div>
      </div>
    );
  }

  updateLeagueFilter(newVal) {
    this.setState({ leagueFilter: newVal }, () => {
      this.props.updateLeageuFilter(newVal);
    });
  }

  render() {
    log(this.constructor.name, "render");

    const { leagues, favoriteLeagues, classes } = this.props;
    const { leagueFilter, sportCode } = this.state;

    return (
      <div>
        <TreeViewerLeaguesFilterTabs
          leagueFilter={leagueFilter}
          updateLeagueFilter={this.updateLeagueFilter.bind(this)}
        />

        {this.renderTotalGamesCounter()}

        <Accordion
          expanded={sportCode === "favorites"}
          onChange={this.openAccordian.bind(this, "favorites")}
        >
          <AccordionSummary
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography className={classes.heading}>
              <span className="vertical-center">
                {sportCode !== "favorites" ? (
                  <ExpandMoreIcon />
                ) : (
                  <ExpandLessIcon />
                )}
                Favorites
              </span>

              {this.renderGamesCounters(favoriteLeagues, true)}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              {favoriteLeagues &&
              Object.values(favoriteLeagues) &&
              Object.values(favoriteLeagues)?.length
                ? Object.values(favoriteLeagues).map((leagues, key) =>
                    this.renderLeagues(leagues, "favorites")
                  )
                : null}
            </Typography>
          </AccordionDetails>
        </Accordion>

        {leagues && leagues[leagueFilter] ? (
          Object.values(leagues[leagueFilter]).map((leagues, key) => (
            <Accordion
              key={key}
              expanded={sportCode === Object.values(leagues)[0].sportCode}
              onChange={this.openAccordian.bind(
                this,
                Object.values(leagues)[0].sportCode
              )}
            >
              <AccordionSummary
                aria-controls="panel1bh-content1"
                id="panel1bh-header1"
              >
                <Typography className={classes.heading}>
                  <span className="vertical-center">
                    {sportCode !== Object.values(leagues)[0].sportCode ? (
                      <ExpandMoreIcon />
                    ) : (
                      <ExpandLessIcon />
                    )}
                    {this.getSportName(Object.values(leagues)[0].sportCode)}
                  </span>

                  {this.renderGamesCounters(leagues, false)}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>{this.renderLeagues(leagues, "other")}</Typography>
              </AccordionDetails>
            </Accordion>
          ))
        ) : (
          <div
            className="color-contrast vertical-center content-center font-xl"
            style={{
              padding: "3.3em 0px",
              backgroundColor: "var(--grey-7)",
            }}
          >
            No Leagues
          </div>
        )}
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  let treeViewLeagues = state.leagues.treeViewLeagues;
  let favoriteLeagues = state.leagues.favorites;
  let leagueFilter = state.leagues._leagueFilter;
  let sportCode = state.leagues._sportCode;

  return {
    leagues: state.leagues,
    leagueFilter,
    sportCode,
    treeViewLeagues: treeViewLeagues,
    favoriteLeagues: favoriteLeagues,
    selectedLeagues: state.selected.leagues,
    sportLabels: state.labels.sports,
    locale: state.i18n.locale,
  };
};

export default connect(mapStateToProps, {
  setLeagues,
  saveTreeViewLeagues,
  saveFavoriteLeagues,
  updateLeageuFilter,
})(withStyles(styles)(LeaguesTreeViewer));
