import { useDispatch, useSelector } from "react-redux";
import { getPlayerPropsGames } from "../../../actions";
import { useEffect } from "react";
import sortGamesByTime from "../../../helpers/gameChronologicalsort";
import PropTypes from "prop-types";
import CatalogGameBox from "./CatalogGameBox";


const PlayerPropsGamesList = ({ league }) => {
  // action dispatcher
  const dispatch = useDispatch();

  // selectors
  const marketSetConfig = useSelector((state) => state.settings.marketSets);
  const allLeagues = useSelector((state) => state.leagues);

  const loadGames = () => {
    let goalScorers =
      marketSetConfig[league.sportCode]["sidebets"]["goalscorer"];
    goalScorers = goalScorers ? goalScorers : [];
    const currentMarketSet = [
      ...marketSetConfig[league.sportCode]["sidebets"]["player"],
      ...goalScorers,
    ];
    if (!league?.games) {
      dispatch(
        getPlayerPropsGames(
          league.sportCode,
          true,
          "PlayerPropsGamesList",
          league.leagueId,
          currentMarketSet,
          goalScorers
        )
      );
    }
  };

  useEffect(loadGames, [league.leagueId]);
  const updatedLeague =
    allLeagues &&
    allLeagues?.playerprops &&
    allLeagues?.playerprops[league?.sportCode] &&
    allLeagues?.playerprops[league?.sportCode]?.length
      ? allLeagues?.playerprops[league.sportCode].find(
          (itm) => itm?.leagueId === league?.leagueId
        )
      : null;
  let games = updatedLeague && updatedLeague?.games ? updatedLeague.games : [];
  games = sortGamesByTime(games);

  return (
    <div>
      {games.map((game, key) => (
        <CatalogGameBox key={game.gameid} game={game} />
      ))}
    </div>
  );
};

PlayerPropsGamesList.propTypes = {
  league: PropTypes.object,
};

PlayerPropsGamesList.defaultProps = {
  league: {},
};

export default PlayerPropsGamesList;
