/**
 * BettingRules - Estonian version
 */

import { log } from "../../../config/log";
import React from "react";
import { connect } from "react-redux";
import Typography from "@mui/material/Typography";
import withStyles from "@mui/styles/withStyles";
import Container from "@mui/material/Container";
import ListItem from "@mui/material/ListItem";
import Obfuscate from "react-obfuscate";

const styles = (theme) => ({
  root: {
    padding: "1em 0",
    fontSize: "var(--content)",
  },
});

class BettingRules extends React.PureComponent {
  goTop = () => {};

  render() {
    log(this.constructor.name, "render");
    const { host, classes } = this.props;

    if (!host) return null;

    return (
      <Container style={{ backgroundColor: "var(--color-main)" }} maxWidth={false}>
        <Typography component="span">
          <div id="legalcontent" className={classes.root}>
            <h1>NUBET.COM-i KIHLVEOREEGLID</h1>
            Eestikeelne versioon 1.3, viimati uuendatud 7.2.2024.<br></br>
            <br></br>
            <h3 id="General">1. ÜLDTEAVE</h3>
            1.1. Kihlvedude korraldajat Nubet ja veebilehte nubet.com haldab Eestis registreeritud ettevõte Vana Lauri OÜ, registrikood 16427120, registreeritud aadress Roseni 10-135, 10111 Tallinn, Estonia (edaspidi:
            Nubet või ettevõte). Vana Lauri OÜ tegutseb Eesti Maksu- ja Tolliameti järelevalve all ja järgmiste tegevuslubade alusel: HKT000066 (välja antud 10.07.2022) ja HKT000067 (välja antud 10.07.2022).<br></br>
            <br></br>
            1.2. Siinsed Nubeti kihlveoreeglid kehtivad Nubeti spordiennustuse kohta veebisaidil nubet.com ja reguleerivad pärisrahaga sõlmitud kihlvedusid, mida Nubet pakub oma veebisaidil nubet.com (edaspidi: Nubeti
            kihlveod). Kui mängija ei nõustu kihlveoreeglite ja Nubeti tingimustega, peab ta Nubeti kihlvedude sõlmimise kohe lõpetama.<br></br>
            <br></br>
            1.3. Nubeti kihlveoreeglid on osa Nubeti tingimustest, millega klient nõustub Nubeti veebisaidil registreerudes.<br></br>
            <br></br>
            1.4. Mängija nõustub, et ta on enne panustamist lugenud ja mõistnud Nubeti kihlveoreegleid.<br></br>
            <br></br>
            1.5. Ettevõte võib Nubeti kihlveoreegleid aeg-ajalt muuta. Kui kihlveoreegleid muudetakse, teavitatakse muudatustest alati veebisaidil Nubet.com. Nubet jätab endale õiguse kihlveoreegleid igal ajal uuendada.
            <br></br>
            <br></br>
            1.6. Kui Nubeti tingimuste ja Nubeti kihlveoreeglite vahel esineb vastuolusid, kohaldatakse Nubeti kihlveoreegleid. Kui vastuolu esineb Nubeti kihlveoreeglite eri osade vahel, kohaldatakse sätteid selles
            järjekorras: vastuolude või puuduvate spordialareeglite korral kohaldatakse kõigepealt erireegleid, siis kihlvedude reegleid ja lõpuks üldreegleid.<br></br>
            <br></br>
            1.7. Nubetis kihlvedusid sõlmides peab mängija tunnistama, et tehtud panuseid peetakse toodeteks, mida tarbitakse kohe, ja ettevõte ei vastuta kliendi tehtud panuste tagastamise, tühistamise ega
            tagasimaksmise eest. See kehtib enne asjaomase kihlveosündmuse algust, selle ajal või pärast sündmust, välja arvatud kihlveo tühistamine või raha väljavõtmine, mis tehakse lubatud viisil kooskõlas ettevõtte
            kihlveoreeglitega. Pange tähele, et Nubeti kihlvedusid sõlmides on kliendil teatavates olukordades ja tingimustel, mida selgitatakse reeglite eraldi jaotises, võimalik tehtud panus välja võtta.<br></br>
            <br></br>
            1.8. Nubet pakub kihlvedusid, kus kaotuse risk on täielikult mängija kanda, kui ta otsustab sõlmida Nubeti pakutud ja korraldatud kihlvedusid.<br></br>
            <br></br>
            1.9. Nubet jätab endale õiguse piirata või blokeerida mängija juurdepääsu Nubeti kihlvedude toodetele, pakkumistele või teenustele ilma selgitusteta ja ettevõtte enda äranägemisel.<br></br>
            <br></br>
            1.10. Tarkvara, mis on seotud mängijale pakutavate Nubeti kihlvedude ja muude teenuste kasutamisega või võimaldab neid kasutada, võib osaliselt või täielikult pakkuda kolmandast isikust teenuseosutaja, kes on
            sõlminud Nubetiga lepingu. Tarkvara on kaitstud autoriõiguse ja intellektuaalomandiga. Mängija võib kasutada tarkvara ainult isiklikel ja meelelahutuslikel eesmärkidel kooskõlas kõigi kohaldatavate seaduste,
            eeskirjade ja määrustega.<br></br>
            <br></br>
            1.11. Nubeti kihlvedusid sõlmides on keelatud kasutada digitaalseid abivahendeid, näiteks roboteid või mehaanilisi, elektroonilisi või muid seadmeid, mille eesmärk on manipuleerida või juhtida Nubetis
            kasutatavat tarkvara. Sellise abivahendi või seadme kasutamise mõistliku kahtluse korral on ettevõttel õigus blokeerida mängijakonto, peatada sisse- ja väljamaksed ning konfiskeerida kõik seotud võidud.
            <br></br>
            <br></br>
            1.12. Mängija ei tohi mingil juhul kasutada Nubeti kihlvedude sõlmimise tarkvaras olevat mis tahes tüüpi viga, tõrget, viirust, puudust, defekti või vastuolusid enda kasuks või teiste mängijate kahjuks.
            Mängija peab kohe pärast vea avastamist teatama veast klienditeenindusele.<br></br>
            <br></br>
            1.13. Nubeti kihlveoreeglite või Nubeti kihlvedude suhtes kohaldatavate Nubeti tingimuste rikkumise korral võidakse mängija diskvalifitseerida kihlvedude sõlmimisest, kampaaniatest, boonus- või
            eripakkumistest ilma ette teatamata.<br></br>
            <br></br>
            1.14. Kui Nubet tuvastab, et mängija võidetud raha on saadud pettuse teel ja/või Nubeti kihlveoreegleid või tingimusi rikkudes, jätab Nubet endale õiguse võidu või krediidijäägi kinni pidada või
            konfiskeerida. Kõigist pettusekahtlusega seotud mängijatest võidakse teatada asjaomastele asutustele.<br></br>
            <br></br>
            1.15. Nubeti kihlvedude jaoks raha ülekandmiseks tuleb kasutada üht Nubeti pakutavatest maksemeetoditest, mille leiate menüüst „Deposit“ („Sissemaksed“).<br></br>
            <br></br>
            1.16. Nubetist raha välja võtmiseks tuleb taotleda väljamakset ja valida mõni saadaval väljamaksemeetod, mille leiate menüüst „Withdrawal“ („Väljamaksed“). Saadaval väljamaksemeetodid võivad oleneda kasutatud
            sissemaksemeetodist.<br></br>
            <br></br>
            1.17. Nubeti kihlveoreeglite klauslid on üksteisest eraldatavad. Kui mõni klausel osutub tühiseks, kehtetuks või mingil põhjusel jõustamatuks, jäävad teised klauslid täies ulatuses kehtima.<br></br>
            <br></br>
            1.18. Nubetil on õigus nõuda kliendilt raha tagasi, kui mängijakonto jääk on negatiivne, ja kliendil on kohustus tagastada ettevõttele ekslikult saadud raha.<br></br>
            <br></br>
            1.19. Kui teil on Nubeti kihlvedude kohta küsimusi, võtke meiega ühendust aadressil <Obfuscate email={"support@nubet.com"}>support@nubet.com</Obfuscate>.<br></br>
            <br></br>
            <h3 id="Rules">2. ÜLDREEGLID</h3>
            <h4>2.1. Panuse tegemine</h4>
            2.1.1. Panuseid saab teha üksik-, mitmik-, süsteem- või Betmikseri panustena.<br></br>
            <br></br>
            2.1.2. Üksikpanus tähendab panustamist ainult ühele tulemusele, samas kui mitmikpanuse korral ühendatakse üheks panuseks mitu tulemust.<br></br>
            <br></br>
            2.1.3. Peale üksik- ja mitmikpanuste saab teha ka süsteempanuse. Süsteempanuse puhul ühendatakse mitu üksik-/mitmikpanust üheks tehinguks.<br></br>
            <br></br>
            2.1.4. „Pankur“ on spetsiaalne kihlveosüsteemi toode. See tähendab kihlveosildil panuste kombineerimist. Et pankur oleks edukas ja mängija võidaks pankuri, peab võitma iga kaasatud panus. Mängija saab märkida
            panused pankuriks, klõpsates panusesildil selleks ettenähtud nuppu. Süsteempanuste summa arvutatakse ainult nende mängude kohta, mis ei ole märgitud pankuriks.<br></br>
            <br></br>
            2.1.5. Betmikser võimaldab teha panuse, mis hõlmab sama sündmuse, näiteks jalgpallimatši, mitu tulemust. Näiteks saate ennustada, et kindel mängija lööb värava ja mängus lüüakse rohkem kui 2,5 väravat.
            Üldiselt käsitletakse Betmikseri panust ühe panusena.<br></br>
            <br></br>
            <h4>2.2. Kihlveokviitung</h4>
            2.2.1. Kihlveokviitung on mängija ja Nubeti vahel sõlmitud leping, mis luuakse automaatselt, kui mängija teeb panuse ja Nubet võtab selle vastu.<br></br>
            <br></br>
            2.2.2. Mängija ja Nubeti vahelise kihlveokviitungi ja lepingu kehtivus oleneb sellest, kas sündmus on kihlveokontori keskarvutis nõuetekohaselt registreeritud. Seda saab vaadata ja kinnitada kontoteabes.
            <br></br>
            <br></br>
            2.2.3. Kui tarkvara esitab kihlveokviitungi automaatselt, kuid tegelik panus ei ole kihlveokontori keskarvutis nõuetekohaselt registreeritud, võidakse tehtud panus kehtetuks tunnistada.<br></br>
            <br></br>
            2.2.4. Klient võib enne virtuaalse kihlveosedeli saatmist seda igal ajal Nubetis muuta või selle kustutada. Klient vastutab ainuisikuliselt tehtud panuste valiku eest ning ei saa väita, et Nubeti
            kättesaadavaks tehtud ja/või antud teave ja soovitused olid ebaõiged või ebatäielikud.<br></br>
            <br></br>
            2.2.5. Mängija nõustub koefitsientidega, mis on toodud kihlveosedelil panuse tegemise hetkel. Mängija on samuti teadlik ja nõustub sellega, et need koefitsiendid võivad erineda koefitsientidest, mis on
            aktiivsed kihlveosedeli ärasaatmise ajal, eriti reaalajas panustamise korral, mille puhul võetakse panus vastu viivitusega.<br></br>
            <br></br>
            <h4>2.3. Kohustused</h4>
            2.3.1. Nubet ei ole kohustatud avalikustama lisateavet, nagu võistluse tüüp (nt liiga- või karikamäng), lühendatud/pikendatud mänguaeg (nt sõprusmängude, miniturniiride, noortemängude, siseturniiride jne
            puhul), mängu toimumispaik (nt neutraalne toimumispaik), võistlusdistantsid jne.<br></br>
            <br></br>
            2.3.2. Kui eespool nimetatud teavet esitatakse, siis ei tagata selle täpsust ja teave ei mõjuta kuidagi panuse arveldamist. Sama kehtib igat liiki statistika, tabelite ja tulemuste kohta.<br></br>
            <br></br>
            <h4>2.4. Panuste arveldamine</h4>
            2.4.1. Panused arveldatakse pärast ametlike tulemuste väljakuulutamist, kui kihlveoreeglites ei ole sätestatud teisiti.<br></br>
            <br></br>
            2.4.2. Igasugune kaebus, mis on seotud arvelduse või kihlveovõidu krediteerimisega, tuleb esitada kirjalikult ja saata Nubetile tava- või e-posti teel 14 tööpäeva jooksul pärast arveldust.<br></br>
            <br></br>
            <h4>2.5. Ümberpööratud panus</h4>
            2.5.1. Nubet jätab endale õiguse panus ümber pöörata, mis tähendab, et panus ei kehti enam ja see tagastatakse mängijale, olenemata sellest, kas sündmus on arveldatud või mitte. See kehtib ka väljavõetud
            panuste kohta.<br></br>
            <br></br>
            2.5.2. Kui Nubet pöörab panuse ümber, määratakse koefitsient samaväärseks panuse tagasisaamisega. See tähendab, et väljamakse summa on võrdne koefitsiendiga 1,00.<br></br>
            <br></br>
            2.5.3. Mitmik- või süsteempanusena tehtud panus jääb võimaluse korral kehtima, välja arvatud mitmik- või süsteempanuse ümberpööratud osa. See tähendab, et ümberpööratud panus jäetakse mitmik- või
            süsteempanuse lõplikust koefitsiendist välja ja panus ei pea olema edukas, et mitmik- või süsteempanus võidaks.<br></br>
            <br></br>
            2.5.4. Kui kihlveosündmus ei toimu nii, nagu on kirjeldatud kihlveosedelis, näiteks kui osalejad on valed või toimumiskohta muudetakse, võidakse panus lugeda kehtetuks ja Nubet määrab koefitsiendiks 1,00.
            <br></br>
            <br></br>
            2.5.5. Kui sündmus tühistatakse ja/või ei alga ettenähtud ajal ja punktis 2.5 toodud erandina nimetatud ajavahemiku jooksul, võidakse ümberpööratud panus lugeda kehtetuks ja Nubet määrab koefitsiendiks 1,00.
            Kui mäng või sündmus lükatakse edasi ja lõpetatakse 48 tunni jooksul pärast esialgu plaanitud algusaega, arveldatakse kõik avatud panused tulemuse põhjal.<br></br>
            <br></br>
            2.5.6. Kui mäng või sündmus ei lõppe 48 tunni jooksul, võidakse kõik sellega seotud panused lugeda kehtetuks. Mängude ja sündmuste puhul, mille algusaja muutmine on pikalt ette teada, et teha otseülekandeid,
            jätta mängude vahele piisavalt aega või muudel arusaadavatel ja etteteatatud põhjustel, võib seda reeglit mitte kohaldada, misjuhul võib selliseid mänge ja sündmusi pidada varem teadaoleval arusaadaval
            põhjusel edasilükatud mängudeks ja sündmusteks.<br></br>
            <br></br>
            2.5.7. Panused võivad kehtida ka kauem kui 48 tundi, kui kihlveosündmus on osa turniirist (tenniseturniir, olümpiamängud, maailmameistrivõistlused, Euroopa meistrivõistlused jne) ning see saab tulemuse
            turniiri ajal ja kooskõlas turniiri reeglitega, kuid erineval ajal.<br></br>
            <br></br>
            2.5.8. Panuseid, mille tulemus on otsustatud juba enne mängu katkestamist (nt sellised panusetüübid nagu üle/alla, seti peale panustamine, esimene värav jne) ning mis on arveldatud pärast kihlveosündmuse
            algust, ei pöörata tagasi ja need arveldatakse igal juhul.<br></br>
            <br></br>
            2.5.9. Siinsed reeglid ei kehti üldjuhul selliste panuste kohta, mida Nubet pakub pidevalt või mis sõltuvad suuresti panuse tegemise ajast ja mida pakutakse ka pärast sündmuse algust, näiteks pikaajalised
            panused või reaalajas panused, mis võivad saada tulemuse pärast sündmuse algust.<br></br>
            <br></br>
            2.5.10. Kui sündmus lõpeb lahtise tulemuse või viigiga olukorras, kus lahtisele tulemusele või viigile ei pakutud koefitsiente (nt pesapalli sõprusmängud, Ameerika jalgpall jne), võidakse panus lugeda
            kehtetuks ja Nubet määrab koefitsiendiks 1,00.<br></br>
            <br></br>
            2.5.11. Kui näidatud koefitsiendid on ilmselgelt vigased (nt jagatud kvoodid, valed kümnendkohad jne), võidakse panus lugeda kehtetuks ja Nubet määrab koefitsiendiks 1,00.<br></br>
            <br></br>
            2.5.12. Panus võidakse kuulutada kehtetuks ja Nubet määrab panuse koefitsiendiks 1,00, kui panuse vastuvõtmise ajal sõlmitakse kihlveoleping valede koefitsientidega tehnilise defekti tõttu, mis ilmneb
            veebisaidil, kihlveokontori süsteemides või kolmanda isiku süsteemis, mis on vajalik selleks, et kihlveokontor või Nubet saaks täpseid koefitsiente näidata, või tehnilise vea tõttu, mis takistab
            kihlveokontoril või Nubetil osutada teenuseid nõuetekohaselt ja täita muid kihlveotoodete pakkumisega seotud funktsioone. See hõlmab kõiki selgeid kõrvalekaldeid, mis võisid olla tolleaegse keskmise
            turuhinnaga võrreldes ekslikud.<br></br>
            <br></br>
            2.5.13. Keelatud on teha mitmik- või süsteempanust samal kviitungil, mille tulemused on omavahel seotud. Kuigi Nubet võtab kõiki vajalikke meetmeid, et selliseid kihlveovõimalusi vältida, jätab ettevõte
            endale siiski õiguse oma äranägemise järgi tühistada kõik mitmikpanuse osad, mis sisaldavad omavahel seotud tulemusi. Sellised panused võidakse lugeda kehtetuks ka pärast sündmuse tulemuse selgumist.<br></br>
            <br></br>
            2.5.14. Selleks et Betmikseri panus võidaks, peavad võitma kõik valikud (ei tohi olla viike). Valik, mis ei võida, arveldatakse kaotusena. Kui üks valik ei kehti või panuses märgitud mängija ei võta matšist
            osa, tühistatakse terve panus.<br></br>
            <br></br>
            <h4>2.6. Kodueelise muutmine</h4>
            2.6.1. Mängudes, kus üks meeskondadest mängib kodus (liigamäng, karikamäng, rahvusvaheline võistlus jne), on kodumeeskond alati see meeskond, kes on kihlveosedelil märgitud esimesena. See reegel ei kehti
            tingimata turniiride puhul, kuna turniiri reeglitest olenevalt ei peeta võõrustavat meeskonda alati kodumeeskonnaks.<br></br>
            <br></br>
            2.6.2. Kui mäng toimub mingil täpsustatud või täpsustamata põhjusel mujal kui kodumeeskonna tavapärases mängupaigas, jäävad kõik tehtud panused kehtima, välja arvatud juhul, kui kodumeeskonda muudetakse, see
            tähendab, kui vastutav ühing teatab, et endine kodumeeskond on nüüd külalismeeskond.<br></br>
            <br></br>
            <h4>2.7. Panuse tulemus</h4>
            2.7.1. Panuse arveldamiseks kohe pärast kihlveosündmuse lõppu kasutatakse ainult vastutava ühingu väljakuulutatud ametlikke tulemusi.<br></br>
            <br></br>
            2.7.2. Panuse arveldamist ei muuda tulemuste/järjestuse hilisemad muudatused.<br></br>
            <br></br>
            2.7.3. Nubet võib saada ametlikke tulemusi kolmandatest isikutest partneritelt.<br></br>
            <br></br>
            2.7.4. Nubet püüab turgusid arveldada ja saada lõpliku panusetulemuse võimalikult kiiresti pärast kihlveosündmuse lõppemist, kuid ei saa mõnel erandjuhul tagada ega määrata, kui kiiresti panus arveldatakse.
            See on tingitud Nubetist sõltumatutest teguritest ja Nubet on võtnud endale kohustuse arveldada panuseid alati võimalikult kiiresti.<br></br>
            <br></br>
            2.7.5. Kui panuse tulemus on kindel enne sündmuse lõppu, võidakse panust arveldada Nubeti kihlveoreeglite järgi isegi siis, kui sündmus jäetakse ära või kui sündmuse kestus lüheneb.<br></br>
            <br></br>
            2.7.6. Knock-out-süsteemiga individuaalspordi turniiridel (tennis, noolemäng, snuuker jne) on mängu ärajätmise korral (nt vigastuse vms tõttu) võitja see osaline, kes pääseb järgmisesse raundi (viimase
            play-off-mängu puhul võitjaks kuulutatud osaline). Ei ole oluline, kas ta läheb järgmisesse raundi või mitte. Kõik panused täpse tulemuse peale (skoori, seti, händikäpi panused jne) võidakse lugeda kehtetuks
            ja Nubet määrab koefitsiendiks 1,00. Reegel ei kehti panuste puhul, mille tulemus on otsustatud juba enne mängu ärajätmist (üle/alla, esimene sett jne), ja need arveldatakse igal juhul.<br></br>
            <br></br>
            2.7.7. Sündmuste puhul, mis lõppevad poodiumi- või medalitseremooniaga, lõpetatakse arveldamine enne tseremoonia toimumist. Hilisemaid diskvalifitseerimisi ja pärast poodiumitseremooniat tehtud muudatusi ei
            võeta kihlvedudes arvesse. See hõlmab ka kaebusi, kohtumenetlust, dopingukontrolli tulemust või muid põhjuseid.<br></br>
            <br></br>
            2.7.8. Nubet jätab endale õiguse vajaduse korral korrigeerida turgude ebakorrektset arveldamist.<br></br>
            <br></br>
            <h4>2.8. Viigiline tulemus (ingl dead heat)</h4>
            2.8.1. Kahe või enama võitja korral (nt parim väravalööja), mida nimetatakse ka viigiliseks tulemuseks, jagatakse võidusumma vastavalt: näiteks 100-eurone panus koefitsiendiga 1,80 annab panuse tulemuseks 180
            eurot ja seega moodustab 80 eurot kasumi, mis jagatakse kahe võitja vahel (80 jagatakse kahega ehk mõlemad võitjad saavad 40 eurot). Välja makstakse seega tehtud panus, millele lisatakse 40 eurot kasumit ja
            seega on lõpliku väljamakse summa 140 eurot. Rohkemate ühtivate tulemuste korral jagatakse võidusumma sama meetodiga ja lõplikult makstakse välja algne panus, millele lisatakse jagatud kasum.<br></br>
            <br></br>
            2.8.2. Viigilise tulemuse esimene erand: duelli (võitja vs. kaotaja) panused, mida on kirjeldatud Nubeti kihlveoreeglites eraldi jaotises.<br></br>
            <br></br>
            2.8.3. Viigilise tulemuse teine erand: on turutüüpe, mis sisaldavad rohkem kui üht võiduvalikut, näiteks topeltvõimalus, väravalööja ja top-X. Nende kihlveotüüpide ja seotud kihlveoturgude puhul viigilise
            tulemuse reeglid ei kehti. Kui aga top-X-i turutüübis on rohkem valikuid kui alguses turu kirjelduses, siis kohaldatakse viigi korral viigilise tulemuse reegleid.<br></br>
            <br></br>
            <h4>2.9. Otsesed turud</h4>
            2.9.1. Otseseid turge peetakse kõik-või-mitte-midagi-kihlvedudeks ja seetõttu arveldatakse need kaotusena, kui tehtud valik sündmusel ei osale. Kohaldatakse viigilise tulemuse reegleid.<br></br>
            <br></br>
            2.9.2. Kui sündmust korraldav ühing lisab pärast nn tavahooaja lõppu järjestuse, liiga võitjate, tõusmise/langemise määratlemiseks vajalikke raunde, mänge või mänguseeriaid (nt play-off’id, play-out’id,
            järelhooaeg), siis kasutab Nubet nende mängude tulemusi, et arveldada panuseid, mis on seotud viimase liigajärjestuse, tõusmise, langemise jms. Näiteks hooajalised panused NHL-i võitnud meeskonnale lahendab
            Stanley Cupi finaal.<br></br>
            <br></br>
            2.9.3. Kõik panused arveldatakse auhinnatseremoonia või võistluse ametliku tulemustabeli alusel, võtmata arvesse hilisemate uurimiste või diskvalifitseerimiste tulemusi.<br></br>
            <br></br>
            <h4>2.10. Duell (ingl head-to-head)</h4>
            2.10.1. Võitja on see osaleja, kes saab ametlikus edetabelis kõrgema koha. Kui osaleja langeb võistlusest, mängust või sündmusest vara välja, on sellele osalejale tehtud panused kaotatud ja võitjaks loetakse
            tema vastane või mõni teine osaleja.<br></br>
            <br></br>
            2.10.2. Kui võistlusel, mängus, sündmusel või turniiril ei osale üks osaleja või mõlemad osalejad, määratakse kõigile seotud panustele koefitsient 1,00, mille tulemusel saab mängija oma tehtud panuse tagasi.
            <br></br>
            <br></br>
            2.10.3. Kui mõlemal osalejal on ametlikus edetabelis sama koht, siis laheneb olukord viigiga. See kehtib ka mõlema osaleja varajase loobumise korral. Kui viiki ei pakuta ja seda ei ole võimalik lahendada,
            määratakse kõikidele nende duellide panustele koefitsient 1,00, mille tulemusel saab mängija algse panuse tagasi.<br></br>
            <br></br>
            2.10.4. Turniiridel, kus kasutatakse knock-out-süsteemi (tenniseturniirid jne), loetakse võitjaks see osaleja, kes pääseb järgmisesse võistlusetappi (teine voor pärast esimest, veerandfinaal pärast
            kuueteistkümnendikfinaali jne) või saavutab lõppkokkuvõttes parema koha (finaali võitja vs. kaotaja). Kui kvalifitseeritud osaleja ei ole järgmises etapis kohal, loetakse ta ikkagi etappi saanuks.<br></br>
            <br></br>
            <h4>2.11. Mängijaturud</h4>
            2.11.1. Kui ükskõik millise panusetüübi jaoks valitud mängija ei osale mängus, tühistatakse kõik selle mängijaga seotud panused.<br></br>
            <br></br>
            2.11.2. Kõikide mängijaturgude tulemustes võetakse arvesse lisaaega. Karistuslööke või samaväärseid elemente ei arvestata.<br></br>
            <br></br>
            2.11.3. Kõik panused, mis luuakse enne sündmuse toimumise koha muutumist, tühistatakse.<br></br>
            <br></br>
            2.11.4. Kui matš algab, aga see hüljatakse või peatatakse enne selle loomulikku lõppu ja matš ei jätku viie tunni jooksul, tühistatakse kõik selle mängijaga seotud panused.<br></br>
            <br></br>
            <h4>2.12. Vastutus</h4>
            2.12.1. Nubet ei võta vastutust sisestamis-, ülekande- ja/või hindamisvigade eest.<br></br>
            <br></br>
            2.12.2. Nubet jätab endale õiguse parandada ilmseid vigu, mis on tehtud koefitsientide sisestamisel ja/või kihlvedude tulemuste hindamisel (nt kirjaviga, vale mängijate paar, koefitsientide ilmselge
            ümberpaigutamine, ebaõiged händikäpinõuded, ilmselgelt ekslikult määratud väravate arv üle/alla panuste puhul vms). Seda reeglit võidakse kohaldada ka pärast sündmuse toimumist kohe, kui viga on tuvastatud,
            mille järel kuulutatakse tehtud panused kehtetuks. See hõlmab kõiki selgeid kõrvalekaldeid, mis võisid olla tolleaegse keskmise turuhinnaga võrreldes ekslikud.<br></br>
            <br></br>
            2.12.3. Nubet jätab endale õiguse igal ajal muuta kihlveo koefitsiente ning peatada või lõpetada sündmustele panustamine enne plaanitud algusaega. Ettevõttel on õigus otsustada, kas ta soovib pakkuda oma
            klientidele võimalust panustada teatavatele sündmustele teataval ajal.<br></br>
            <br></br>
            2.12.4. Kihlveoreeglites on ka erireegleid sisaldavad jaotised teatavat tüüpi spordialade ja turgude jaoks. Erireegleid kohaldatakse enne üldreegleid, mistõttu peab mängija Nubeti kihlvedusid sõlmides olema
            eelkõige tutvunud erireeglitega.<br></br>
            <br></br>
            <h4>2.13. Raha väljavõtmine (ingl cash out)</h4>
            2.13.1. Raha väljavõtmise valik võimaldab mängijal kindlal ajal lunastada panus väljavõtmise jaotises toodud väärtuses. Mängija peab ise otsustama, kas ta soovib raha välja võtta, ja võib sama hästi oodata,
            kuni panus arveldatakse tavapärase tähtaja jooksul. Kui taotlus on kinnitatud, ei saa mängija raha väljavõtmise valikut tühistada. Kui väljamakse taotlus on vastu võetud, lõpetatakse esialgne kihlvedu ja
            mängija panus arveldatakse tulemusest olenemata summas, mis on näidatud raha väljavõtmise võimaluse kasutamisel.<br></br>
            <br></br>
            2.13.2. Nubet jätab endale õiguse lõpetada või peatada turul kasutajatele raha väljamaksmise võimaluse pakkumine ilma etteteatamiseta. Ettevõttel on õigus otsustada, kas ta soovib pakkuda oma klientidele raha
            väljamaksmise võimalust teatavatele sündmustele teataval ajal.<br></br>
            <br></br>
            2.13.3. Kui Nubet kahtlustab põhjendatult pettusekatset, nagu on määratletud Nubeti tingimustes, näiteks kui panuse või väljavõtmise taotluse on pettuse või sõnaselgelt keelatud kasumi saamise eesmärgil
            teinud üksikisik või koos tegutsevate isikute rühmitus (sh sugulased, organisatsioonid või kihlveokontori töötajad) või kui esialgne panus või väljavõtmise taotlus on tehtud pärast asjaomase sündmuse lõppu,
            võib Nubet tühistada esialgse väljavõetud panuse, keelduda panuse väljamaksmisest või nõuda, et ettevõttele tagastataks kõik algse panusega seotud summad, sealhulgas väljavõetud summa.<br></br>
            <br></br>
            2.13.4. Kui esialgne panus oleks kuulutatud kehtetuks, jätab Nubet endale õiguse kuulutada raha väljavõtmine ja selle summa kehtetuks nagu esialgse panusegi, ning konfiskeerida mis tahes summa, mis ületab
            esialgset panust. Esialgne panus loetakse arveldatuks, misjärel ei ole Nubetil sellega seoses ühtegi kohustust.<br></br>
            <br></br>
            2.13.5. Kui raha väljavõtmise taotlus võetakse vastu ekslikult või väljavõetava summa märkimisel või maksmisel on ilmnenud viga, mis on viinud vale pakkumise või makse tegemiseni, või kui mängija kontole on
            väljamakstud panusega seoses krediteeritud vale summa, jätab Nubet endale õiguse kontojääki vastavalt muuta ja konfiskeerida mis tahes vea tõttu mängijale krediteeritud summa. Kui kontojäägi muutmise tõttu on
            mängijakonto jääk negatiivne, on Nubetil õigus nõuda kliendilt raha tagasi ja kliendil on kohustus tagastada ettevõttele ekslikult saadud raha.<br></br>
            <br></br>
            <h3 id="Betrules">3. PANUSTAMISREEGLID</h3>
            <h4>3.1.</h4>
            Ainult normaalaeg: kui ei ole sätestatud teisiti, loetakse võistluse tulemuseks normaalaja jooksul saadud tulemus. Normaalaeg hõlmab algset täisaega ja lisaaega vigastuste, vahetuste jms tavapäraste ja
            ebatavaliste sündmuste jaoks, mis lisatakse mängu või sündmuse normaalajale. Normaalaja hulka ei kuulu lisaaeg, sudden death jms. Normaalaeg on määratletud asjakohase juhtiva ühingu avaldatud ametlikes
            eeskirjades. Nubet ei võta vastutust klientide teavitamise eest, kui mängu või sündmuse kestus on tavapärasest erinev või kui see erineb täisajast, mida rakendatakse enamasti sarnaste sündmuste puhul.
            <br></br>
            <br></br>
            <h4>3.2. Kõige levinumad kihlveotüübid</h4>
            3.2.1. Allpool loetletud kõige levinumad kihlveotüübid, mida nimetatakse ka turgudeks või turutüüpideks, hõlmavad mitmeid või enamikku spordisündmuste või -võistlustega seotud tooteid. Rohkem panusetüüpe või
            erieeskirju, mida mängija peab arvestama ja millega ta peab tutvuma ning mis kehtivad teatavate kihlveotoodete puhul, leiate erireeglite jaotisest. 3.2.2. Võitja (1X2)<br></br>
            Milline meeskond võidab mängu (sh viik)? 1X2 kihlveos kehtib järgmine: 1 = kodumeeskonna võit, X = viik, 2 = külalismeeskonna võit.<br></br>
            <br></br>
            3.2.3. Võitja (Moneyline)<br></br>
            Moneyline on kahesuunaline kihlveotüüp, kus viik ei ole võimalik ja milles panustatakse sellele, milline meeskond või osaleja võidab või kaotab, olenemata võidumarginaalist. Kui normaalaja lõpus ollakse
            viigis, siis arvestatakse moneyline’i kihlvedu arveldades lisaaega, sudden death’i ja karistuslööke. Moneyline’i kihlveos kehtib järgmine: 1 = kodumeeskonna võit, 2 = külalismeeskonna võit.<br></br>
            <br></br>
            3.2.4. Ülejäänud mängu 1X2<br></br>
            Milline meeskond võidab ülejäänud mängu? Ülejäänud mängu 1X2 kihlveos kehtib järgmine: 1 = kodumeeskonna võit, X = viik, 2 = külalismeeskonna võit. Sellele saab panustada ainult reaalajas. Arvesse lähevad
            ainult väravad, mis lüüakse pärast panuse esitamist. Järelikult algab mäng panuse esitamisel tulemusega 0 : 0.<br></br>
            <br></br>
            3.2.5. Topeltvõimalus<br></br>
            Topeltvõimaluse puhul kehtib järgmine: 1X = kodumeeskonna võit või viik, 12 = kodu- või külalismeeskonna võit, X2 = külalismeeskonna võit või viik.<br></br>
            <br></br>
            3.2.6. Ilma viigita panus<br></br>
            Ilma viigita panuse puhul kehtib järgmine: 1 = kodumeeskonna võit, 2 = külalismeeskonna võit. Viigi korral on ilma viigita panus kehtetu.<br></br>
            <br></br>
            3.2.7. Händikäpid (punktivahe)<br></br>
            Erijuhtum: kui kodu-/külalismeeskonnaturul kasutatakse täpset määratlejat ja tulemus on see määratleja, on panused kehtetud.<br></br>
            <br></br>
            3.2.8. Händikäp<br></br>
            Meeskond saavutab ühe või mitme värava suuruse eduseisu. Need lisatakse tavalisele tulemusele (mängu tulemus + händikäp). Tulemust hinnatakse händikäppi arvestades.<br></br>
            <br></br>
            3.2.9. Aasia händikäp<br></br>
            Aasia händikäppe on kolm.<br></br>
            <br></br>
            Täisarvuline händikäp [0], [–1], [–2], ...<br></br>
            <br></br>
            <ListItem>
              Täisarvuline händikäp lisatakse tavalisele tulemusele (mängu tulemus + händikäp), mida hinnatakse koos händikäpiga. Kui pärast händikäpi rakendamist (mängu tulemus + händikäp) saavutatakse viik, makstakse
              summa tagasi (panust ei tehta).
            </ListItem>
            <br></br>
            Poolik händikäp [–0,5], [–1,5], [–2,5], ...<br></br>
            <br></br>
            <ListItem>
              Poolik händikäp lisatakse tavalisele tulemusele (mängu tulemus + händikäp), mida hinnatakse koos händikäpiga. Poolikute numbrite lisamise tõttu välistatakse viik. See tähendab, et selles variandis on panus
              alati kas võidetud või kaotatud. Kui panusel ei ole tulemust, ei saa seda tüüpi panuse raha tagasi maksta.
            </ListItem>
            <br></br>
            Jagatud händikäp [–0,25], [–0,75], [–1,25], ...<br></br>
            <br></br>
            <ListItem>Jagatud händikäp lisatakse tavalisele tulemusele (mängu tulemus + händikäp), mida hinnatakse koos händikäpiga. Jagatud händikäp jääb täisarvulise ja pooliku händikäpi vahele.</ListItem>
            <br></br>
            Mängu tulemusest olenevalt on võimalikud järgmised olukorrad.<br></br>
            <br></br>
            <ListItem>Panus on võidetud või kaotatud.</ListItem>
            <ListItem>Pool summast makstakse tagasi ja teine pool kaotab.</ListItem>
            <ListItem>Pool summast makstakse tagasi ja teine pool võidab.</ListItem>
            <br></br>
            Kõik esimese poolega seotud händikäpid arveldatakse poolaja tulemuse põhjal. Reaalajas Aasia händikäpi turul lähevad arvesse ainult väravad, mis lüüakse pärast panuse esitamist. Järelikult algab mäng panuse
            esitamisel tulemusega 0 : 0.<br></br>
            <br></br>
            3.2.10. Koguarv (üle/alla)<br></br>
            Koguarvu kihlveo puhul tehakse panus sellele, kas mängus löödud väravate koguarv on üle või alla kindlaksmääratud väravate arvu.<br></br>
            <br></br>
            3.2.11. Aasia koguarv<br></br>
            Aasia koguarvu kihlveo puhul tehakse panus sellele, kas mängus löödud väravate koguarv on üle või alla kindlaksmääratud väravate arvu. Loogika toimib sarnaselt Aasia händikäpiga. Allpool on toodud Aasia
            händikäpi näited.<br></br>
            <br></br>
            <ListItem>
              Üle/alla 2<br></br>
              Üle: panus võidab, kui mängus lüüakse kolm või rohkem väravat. Kui lüüakse täpselt kaks väravat, makstakse summa tagasi. Kui ei lööda ühtegi väravat või lüüakse üks värav, on panus kaotanud.<br></br>
              Alla: panus võidab, kui mängus lüüakse üks värav või ei lööda ühtegi väravat. Kui lüüakse täpselt kaks väravat, makstakse summa tagasi. Kui lüüakse kolm või rohkem väravat, on panus kaotanud.
            </ListItem>
            <ListItem>
              Üle/alla 2,25<br></br>
              Üle: panus võidab, kui lüüakse kolm või rohkem väravat. Kui lüüakse täpselt kaks väravat, makstakse pool summast tagasi ja teine pool võidab. Kui väravaid ei lööda või lüüakse üks värav, on panus kaotanud.
              <br></br>
              Alla: panus võidab, kui mängus lüüakse üks värav või ei lööda ühtegi väravat. Kui lüüakse täpselt kaks väravat, makstakse pool summast tagasi ja teine pool kaotab. Panus kaotab, kui lüüakse kolm või rohkem
              väravat.
            </ListItem>
            <ListItem>
              Üle/alla 2,5<br></br>
              Üle: panus võidab, kui mängus lüüakse kolm või rohkem väravat. Kui lüüakse kaks väravat või vähem, on panus kaotanud.<br></br>
              Alla: panus võidab, kui mängus lüüakse kaks väravat või vähem. Kolme või enama värava korral on panus kaotanud.
            </ListItem>
            <ListItem>
              Üle/alla 2,75<br></br>
              Üle: panus võidab, kui lüüakse neli või rohkem väravat. Kui lüüakse täpselt kolm väravat, makstakse pool summast tagasi ja teine pool võidab. Kui ei lööda ühtegi või lüüakse üks või kaks väravat, on panus
              kaotanud.<br></br>
              Alla: panus võidab, kui mängus ei lööda ühtegi väravat või lüüakse üks või kaks väravat. Kui lüüakse täpselt kolm väravat, makstakse pool summast tagasi ja teine pool kaotab. Panus kaotab, kui lüüakse kolm
              või rohkem väravat.
            </ListItem>
            <ListItem>
              Üle/alla 3<br></br>
              Üle: panus võidab, kui mängus lüüakse neli või rohkem väravat. Kui lüüakse täpselt kolm väravat, makstakse summa tagasi. Kui ei lööda ühtegi või lüüakse üks või kaks väravat, on panus kaotanud.<br></br>
              Alla: panus võidab, kui mängus ei lööda ühtegi väravat või lüüakse üks või kaks väravat. Kui lüüakse täpselt kolm väravat, makstakse summa tagasi. Nelja või enama värava korral on panus kaotanud.
            </ListItem>
            <br></br>
            Kõik muud Aasia koguarvud teiste määratlejatega (x, x,25, x,5, x,75, x+1) töötavad samamoodi.<br></br>
            <br></br>
            3.2.12. Mõlemad meeskonnad skoorivad<br></br>
            Mõlema meeskonna skoorimise peale panustades saate valida kahe võimaluse vahel:<br></br>
            <br></br>
            <ListItem>jah: mõlemad meeskonnad löövad vähemalt ühe värava,</ListItem>
            <ListItem>ei: mõlemad meeskonnad ei löö vähemalt ühte väravat.</ListItem>
            <br></br>
            3.2.13. Paaris/paaritu<br></br>
            Panustatakse sellele, kas mängu väravate/punktide koguarv on paaris või paaritu. Null loetakse paarisarvuks.<br></br>
            <br></br>
            3.2.14. Täpne skoor<br></br>
            Panustatakse sellele, milline on mängu täpne tulemus normaalaja lõpus. Õige tulemuse ennustamisel panus võidab ja kui mängu tegelik tulemus ei ühti valitud variandiga, on panus kaotanud.<br></br>
            <br></br>
            3.2.15. Poolaeg/täisaeg<br></br>
            Panustatakse sellele, milline on mängu tulemus esimese poolaja ja normaalaja lõpus. Esimene väärtus tähistab poolaja tulemust ja teine väärtus lõplikku tulemust. Poolajale/täisajale panustades on saadaval
            järgmised võimalused:<br></br>
            <br></br>
            <ListItem>1/1 = kodumeeskonna võit / kodumeeskonna võit,</ListItem>
            <ListItem>1/X = kodumeeskonna võit / viik,</ListItem>
            <ListItem>1/2 = kodumeeskonna võit / külalismeeskonna võit,</ListItem>
            <ListItem>X/1 = viik / kodumeeskonna võit,</ListItem>
            <ListItem>X/X = viik/viik,</ListItem>
            <ListItem>X/2 = viik / külalismeeskonna võit,</ListItem>
            <ListItem>2/1 = külalismeeskonna võit / kodumeeskonna võit,</ListItem>
            <ListItem>2/X = külalismeeskonna võit / viik,</ListItem>
            <ListItem>2/2 = külalismeeskonna võit / külalismeeskonna võit.</ListItem>
            <br></br>
            3.2.16. Intervalli panus<br></br>
            Panustatakse selle peale, mis on esimese või teise poolaja või sarnase mänguintervalli (periood, veerandajad, inningud jms) tulemus.<br></br>
            <br></br>
            3.2.17. Esimene skooriv meeskond<br></br>
            Esimesele skoorivale meeskonnale panustades on saadaval järgmised võimalused:<br></br>
            <br></br>
            <ListItem>1 = kodumeeskond lööb esimese värava,</ListItem>
            <ListItem>X = väravaid ei lööda (0 : 0),</ListItem>
            <ListItem>2 = külalismeeskond lööb esimese värava.</ListItem>
            <br></br>
            3.2.18. Duell (ingl head-to-head)<br></br>
            Duell on kahe osaleja vaheline matš, mille puhul klient panustab, kumb osalistest saavutab sündmusel parima lõpp-positsiooni või -tulemuse. Kui üks osaleja või mõlemad osalejad ei alusta sündmust või kui
            kumbki neist ei saavuta tulemust, makstakse panus tagasi. Kui mõlemad alustavad sündmust, kuid ainult üks neist saab tulemuse, jääb panus kehtima. Sündmuse alustamine tähendab, et osaleja alustab oma esimest
            hüpet, viskamist jne.<br></br>
            <br></br>
            3.2.19. Suurima skooriga poolaeg/periood/veerandaeg<br></br>
            Panustatakse sellele, millisel poolajal/perioodil/veerandajal kogutakse kõige rohkem väravaid/punkte.<br></br>
            <br></br>
            3.2.20. Meeskonna kogutulemus<br></br>
            Panustatakse sellele, mitu väravat lööb kodumeeskond üle või alla kindlaksmääratud väravate arvu.<br></br>
            <br></br>
            3.2.21. Kõik mängijad<br></br>
            Panuste kehtimiseks peavad asjakohasel turul osalema kõik mängijad (ametlikult mängima).<br></br>
            <br></br>
            3.2.22. Esimese värava aeg<br></br>
            Panustatakse sellele, millal lüüakse mängu esimene värav. Mängu esimene minut on 0.00–0.59, teine minut on 1.00–1.59, kolmas minut on 2.00–2.59 jne.<br></br>
            <br></br>
            3.2.23. Võitja<br></br>
            Panustatakse sellele, milline meeskond/osaleja võidab sündmuse.<br></br>
            <br></br>
            3.2.24. Händikäpi tabel<br></br>
            Meeskondadele antakse punktiline eelis. Panustatakse sellele, milline on seis pärast selle punktilise eelise arvestamist. Kui punktiline eelis välja arvata, arvutatakse tabel korraldaja reeglite järgi.
            <br></br>
            <br></br>
            3.2.25. Top-X<br></br>
            Panustatakse sellele, millise koha (nt 1–3) meeskond/mängija konkreetsel võistlusel saab.<br></br>
            <br></br>
            3.2.26. Triplehead (H3H)<br></br>
            Triplehead’is võistlevad üksteise vastu kolm osalejat. Panustatakse sellele, milline osaleja saab lõpuks parima koha. Panuse kehtimiseks peavad kõik osalejad sündmust alustama ja vähemalt üks peab saama
            lõpliku tulemuse/koha.<br></br>
            <br></br>
            3.2.27. Reaalajas panustamine<br></br>
            Tehtud reaalajas panust ei saa tühistada. Kui andmeedastus hilineb ja/või ülekanne on ajaliselt nihkunud ning see mõjutab märkimisväärselt mängu kulgu ja seda ei ole koefitsientide arvutamisel arvesse võetud,
            jätab Nubet endale õiguse kuulutada panused kehtetuks. Kui reaalajas panustamise puhul on kihlveosildil näidatud vale poolaja- või vaheseis, on panus tühine või sellele määratakse kombineeritud panuste puhul
            koefitsient 1,00. See kehtib ka ilmselgelt vahetatud või ebaõigete koefitsientide kohta.<br></br>
            <br></br>
            Kui pärast mängu algust ja panuse tegemist ei ole kihlveoturg enam kättesaadav (nt ülekandeprobleemide vms tõttu), siis hinnatakse kihlveosedelit alles pärast mängu lõppu ametlike tulemuste alusel.<br></br>
            <br></br>
            <h3 id="Specificrules">4. ERIREEGLID</h3>
            <h4>4.1. Ameerika jalgpall</h4>
            4.1.1. Tulemus ei sisalda lisaaega, kui turu kirjelduses ei ole märgitud teisiti. Kui mäng lõpeb pärast lisaaega viigiga, loetakse panused kehtetuks ja koefitsiendiks määratakse 1,00.<br></br>
            <br></br>
            4.1.2. Ärajäetud või edasilükatud mängude korral loetakse kõik turud kehtetuks, välja arvatud juhul, kui mäng jätkub sama nädala kavas (neljapäev–kolmapäev kohaliku staadioni aja järgi).<br></br>
            <br></br>
            4.1.3. Betmikseris hõlmavad kõik teise poolaja ja neljanda veerandaja turu valikud lisaaega, kui mäng peaks selleni jõudma. Näiteks sisaldab teise poolaja punktide valik nii teisel poolajal kui ka lisaajal
            saadud punkte.<br></br>
            <br></br>
            <h4>4.2. Kergejõustik</h4>
            4.2.1. Kui konkreetne sündmus lükatakse edasi või jäetakse ära, jäävad panused kehtima tingimusel, et sündmus toimub 72 tunni jooksul pärast esialgu väljakuulutatud algusaega.<br></br>
            <br></br>
            <h4>4.3. Baseball</h4>
            4.3.1. Tulemus ei sisalda lisainninguid, kui turu kirjelduses ei ole märgitud teisiti.<br></br>
            <br></br>
            4.3.2. Kõik topeltmängudele tehtud panused on tavalise tulemusega.<br></br>
            <br></br>
            4.3.3. Edasilükatud mängu korral loetakse kõik turud kehtetuks, kui mäng ei saa plaanitud päeval ametlikku tulemust.<br></br>
            <br></br>
            4.3.4. Ärajäetud mängu korral, mida ei peeta ametlikuks, loetakse kõik tulemuseta turud kehtetuks, kui mäng ei saa plaanitud päeval ametlikku tulemust. Kõik täielikult otsustatud turud arveldatakse.<br></br>
            <br></br>
            4.3.5. Kõik panused kehtivad, olenemata alustavatest söötjatest või alustava söötja vahetusest.<br></br>
            <br></br>
            <h4>4.4. Korvpall</h4>
            4.4.1. Tulemus ei sisalda lisaaega, kui turu kirjelduses ei ole märgitud teisiti.<br></br>
            <br></br>
            4.4.2. Kui mäng ei lõppe viigiga, kuid kvalifikatsiooni saamiseks mängitakse lisaaeg, arveldatakse turud normaalaja lõpu tulemuse järgi.<br></br>
            <br></br>
            4.4.3. Betmikseris hõlmavad kõik teise poolaja ja neljanda veerandaja turu valikud lisaaega, kui mäng peaks selleni jõudma. Näiteks sisaldab teise poolaja punktide valik nii teisel poolajal kui ka lisaajal
            saadud punkte.<br></br>
            <br></br>
            <h4>4.5. Poks</h4>
            4.5.1. Kõik turud arveldatakse tulemuse järgi, mis on saadaval kohe pärast võitluse lõppu. Hilisemaid kaebusi või tulemuse muudatusi ei võeta võitude väljamaksmisel arvesse.<br></br>
            <br></br>
            4.5.2. Kui võitleja ei vasta järgmise raundi kellale, loetakse tema vastane eelmise raundi võitjaks.<br></br>
            <br></br>
            4.5.3. Kui üks asjaomastest võitlejatest loobub või vahetatakse välja, on panused kehtetud.<br></br>
            <br></br>
            4.5.4. Kui kuulutatakse, et võistlust ei toimunud (ingl No Contest), on kõik panused kehtetud.<br></br>
            <br></br>
            4.5.5. Kui ettenähtud raundide arvu muudetakse enne võitlust, muutuvad kõik raundide koguarvu, võitja ja täpsete raundide ning võidumeetodiga seotud panused kehtetuks.<br></br>
            <br></br>
            4.5.6. Arvelduste jaoks peab mööduma vähemalt pool raundi.<br></br>
            <br></br>
            4.5.7. Kui arveldamiseks on märgitud pool raundi, siis loetakse selleks 1 minut ja 30 sekundit asjaomase raundi algusest. Seega peetakse 9,5. raundiks olukorda, mil 10. raundis on möödunud 1 minut ja 30
            sekundit. Kui võitlus lõpeb täpselt 1 minut ja 30 sekundit pärast 10. raundi algust, siis on tulemus üle 9,5. vooru.<br></br>
            <br></br>
            4.5.8. Kui poksija ei vasta järgmise raundi kellale, loetakse tema vastane eelmise raundi võitjaks. Tehnilise otsuse langetamise korral enne võitluse lõppu loetakse kõik panused otsusega võidetuks ja raundi
            panused kaotatuks.<br></br>
            <br></br>
            <h4>4.6. Kriket</h4>
            4.6.1. Kõik turud ei arvesta superpallimisvoorudega, kui ei ole öeldud teisiti.<br></br>
            <br></br>
            <h4>4.7. Jalgpall</h4>
            4.7.1. Vigastuse aega / lisaaega arvestatakse ajaraamistikus ja intervalli panustes.<br></br>
            <br></br>
            4.7.2. Väravalööjate turud<br></br>
            <br></br>
            4.7.2.1. Omaväravad ei lähe panuste arveldamisel arvesse (välja arvatud juhul, kui pakkumises on saadaval väravateta mängud, muud ja omaväravad).<br></br>
            <br></br>
            4.7.2.2. Kui mis tahes põhjusel lööb värava mõni nimekirjas mitteolev mängija, kehtivad kõik nimekirjas olevatele mängijatele tehtud panused.<br></br>
            <br></br>
            4.7.2.3. Kõiki mängijad, kes osalesid võistlusel pärast avalööki või eelmist väravat, loetakse jooksjateks.<br></br>
            <br></br>
            4.7.2.4. Loetletud on kõik osalevad mängijad.<br></br>
            <br></br>
            4.7.2.5. Kui mis tahes põhjusel lööb värava mõni nimekirjas mitteolev mängija, kehtivad kõik nimekirjas olevatele mängijatele tehtud panused.<br></br>
            <br></br>
            4.7.2.6. Turu arveldamine toimub teleülekande ja pressiliidu esitatud statistika põhjal, kui ei ole selgeid tõendeid selle kohta, et see statistika ei ole õige. Jalgpalli Betmikseri panuste arveldamise
            ametlik allikas on Statsbomb ja see võib mõnikord tavalisest turuarveldusest erineda.<br></br>
            <br></br>
            4.7.3. Märkimine ja punktide märkimise turud<br></br>
            <br></br>
            4.7.3.1. Märkimisturgudel loetakse kollast kaarti üheks kaardiks ja punast või kollasele kaardile järgnenud punast kaarti kaheks. Kui üks mängija saab teise kollase kaardi, mistõttu saab ta ka punase kaardi,
            siis teist kollast kaarti ei arvestata. Järelikult ei saa üks mängija teenida rohkem kui kolm kaarti.<br></br>
            <br></br>
            4.7.3.2. Kollane kaart on väärt 10 punkti ja punane kaart või kollane ja punane kaart 25 punkti. Kui üks mängija saab teise kollase kaardi, mistõttu saab ta ka punase kaardi, siis teist kollast kaarti ei
            arvestata. Järelikult ei saa üks mängija teenida üle 35 punkti.<br></br>
            <br></br>
            4.7.3.3. Arveldamine toimub kooskõlas kõigi olemasolevate tõenditega kaartide kohta, mida näidatakse tavalise 90-minutilise mängu ajal.<br></br>
            <br></br>
            4.7.3.4. Pärast mängu näidatud kaarte ei arvestata.<br></br>
            <br></br>
            4.7.3.5. Mängus mitteosalevate mängijate (juba asendatud mängijad, mänedžerid, varumängijad) kaarte ei arvestata.<br></br>
            <br></br>
            4.7.4. Määratud, kuid realiseerimata nurgalööke ei arvestata.<br></br>
            <br></br>
            4.7.5. Olukordades, kus pall põrkab väravaposti vastu ja läheb seejärel otse väravasse, ei arvestata, et pall põrkas väravaposti vastu.<br></br>
            <br></br>
            <h4>4.8. Golf</h4>
            4.8.1. 2-Balls: panustatakse sellele, milline osaleja saab nimetatud mänguringis väikseima punktisumma. Kui mänguringi ei ole määratletud, on selleks järgmine mänguring Kui mõlemad mängijad loobuvad sama augu
            juures, on turg kehtetu. Samuti on turg kehtetu, kui mõlemad osalejad saavutavad asjaomases mänguringis sama punktisumma. Mängijat võib pidada jooksjaks, kui ta teeb esimese planeeritud augu juures avalöögi.
            <br></br>
            <br></br>
            4.8.2. 3-Balls: panustatakse sellele, milline osaleja saab nimetatud mänguringis väikseima punktisumma. Kui mänguringi ei ole määratletud, on selleks järgmine mänguring.Kohaldatakse viigilise tulemuse
            reegleid.<br></br>
            <br></br>
            4.8.3. Otsene võitja: kõik otsesed panused arveldatakse karika võitnud mängija põhjal. Arvesse võetakse play-off'ide tulemusi. Kui turniiril vähendatakse mis tahes põhjusel (nt halbade ilmastikuolude tõttu)
            plaanitud aukude arvu, siis arveldatakse enne viimast raundi tehtud otsesed panused (v.a esimese raundi liidri panused) osaleja põhjal, kellele antakse karikas.<br></br>
            <br></br>
            4.8.4. Grupipanus: võitja on mängija, kes saavutab turniiri lõpus kõrgeima koha. Kohaldatakse viigilise tulemuse reegleid, välja arvatud juhul, kui võitja selgitatakse välja play-off'i teel. Kui mängu alustab
            ainult üks osaleja, on grupipanused kehtetud.<br></br>
            <br></br>
            4.8.5. Nimetatud mängija finišikoht: kui finišikoht on viigiline, võetakse seda arvesse. Seitsmendaks finišikohaks loetakse näiteks ka olukorda, kus seitsmenda koha said neli mängijat.<br></br>
            <br></br>
            4.8.6. Raundi lõpu liider (nt esimene voor – võitja): panus arveldatakse turniiriskoori põhjal pärast nimetatud raundi lõppu. Kohaldatakse viigilise tulemuse reegleid.<br></br>
            <br></br>
            4.8.7. Turniiri top-4/top-5/top-6/top-10/top-20: kohaldatakse viigilise tulemuse reegleid.<br></br>
            <br></br>
            4.8.8. Mängija loetakse turniiril või konkreetses mänguringis mänginuks, kui ta on teinud avalöögi. Kui mängija loobub, katkestab mängu või diskvalifitseeritakse pärast avalööki, jäävad panused kehtima.
            <br></br>
            <br></br>
            4.8.9. Turniiridel, mida mõjutab halb ilm või muud sarnased põhjused, arveldatakse panused ametliku tulemuse alusel, olenemata mängitud mänguringide arvust.<br></br>
            <br></br>
            4.8.10. Kui turniir katkestatakse, on kõik pärast viimast lõpetatud mänguringi tehtud panused kehtetud.<br></br>
            <br></br>
            4.8.11. Arveldamise aluseks on ametlikud tulemused, mis kehtivad karika üleandmise ajal (hilisem diskvalifitseerimine pärast seda aega ei loe).<br></br>
            <br></br>
            4.8.12. Grupid ei mängi nii, nagu kirjas: kui mõni osalejatest ei mängi, on 2-ball’i ja 3-ball’i panused kehtetud.<br></br>
            <br></br>
            <h4>4.9. Jäähoki</h4>
            4.9.1. Kõikidel turgudel (v.a perioodi-, lisaaja ja karistuslöögiturgudel) arvestatakse ainult normaalaega, kui ei ole öeldud teisiti.<br></br>
            <br></br>
            4.9.2. Kui mäng otsustatakse karistuslöögi abil, lisatakse võitnud meeskonna tulemusele ja mängu lõpptulemusele arveldamise eesmärgil üks värav. See kehtib kõigil turgudel, sealhulgas lisaaeg ja
            karistuslöögid.<br></br>
            <br></br>
            4.9.3. Kui väravavaht ei alusta mängu, kuulutatakse selle väravavahiga seotud panused kehtetuks.<br></br>
            <br></br>
            4.9.4. Betmikseri panuse korral hõlmavad kõik kolmanda perioodi turu valikud lisaaega ja karistuslöögina löödud väravaid, kui mäng neid sisaldab. Näiteks hõlmab kolmanda perioodi väravate valik nii kolmandas
            perioodis löödud väravaid kui ka võitvat väravat, mis lüüakse lisaajal või karistuslöögina.<br></br>
            <br></br>
            <h4>4.10. Motosport</h4>
            4.10.1. Kui konkreetne sündmus lükatakse edasi või jäetakse ära, jäävad panused kehtima tingimusel, et sündmus toimub 72 tunni jooksul.<br></br>
            <br></br>
            <h4>4.11. Sportlik vabavõitlus</h4>
            4.11.1. Kõik turud arveldatakse tulemuse järgi, mis on saadaval kohe pärast võitluse lõppu. Hilisemaid kaebusi või tulemuse muudatusi ei võeta võitude väljamaksmisel arvesse.<br></br>
            <br></br>
            4.11.2. Kui võitleja ei vasta järgmise raundi kellale, loetakse tema vastane eelmise raundi võitjaks.<br></br>
            <br></br>
            4.11.3. Kui üks asjaomastest võitlejatest loobub või vahetatakse välja, on panused kehtetud.<br></br>
            <br></br>
            4.11.4. Kui kuulutatakse, et võistlust ei toimunud (ingl No Contest), on kõik panused kehtetud.<br></br>
            <br></br>
            4.11.5. Kui ettenähtud raundide arvu muudetakse enne võitlust, muutuvad kõik raundide koguarvu, võitja ja täpsete raundide ning võidumeetodiga seotud panused kehtetuks.<br></br>
            <br></br>
            4.11.6. Arvelduste jaoks peab mööduma vähemalt pool raundi.<br></br>
            <br></br>
            4.11.7. Kõik panused arveldatakse ametliku tulemuse väljakuulutamisel. Võit diskvalifitseerimise teel loetakse nokaudiks / tehniliseks nokaudiks.<br></br>
            <br></br>
            4.11.8. Võidumeetodituru puhul on nokaut üks järgmistest juhtudest:<br></br>
            <br></br>
            <ListItem>kohtunik peatab võitluse löökide tõttu, kui üks või mõlemad võitlejad on seismas;</ListItem>
            <ListItem>kohtunik peatab võitluse löökide tõttu, kui üks või mõlemad võitlejad on matil;</ListItem>
            <ListItem>võitluse peatab arst;</ListItem>
            <ListItem>võitluse peatab võitleja nurk/meeskond;</ListItem>
            <ListItem>võitleja loobub vigastuse tõttu;</ListItem>
            <ListItem>võit diskvalifitseerimise teel.</ListItem>
            <br></br>
            4.11.9. Võidumeetodituru jaoks hõlmab alistumine järgmist:<br></br>
            <br></br>
            <ListItem>kohtunik peatab võitluse pärast tap-out’i;</ListItem>
            <ListItem>kohtunik peatab võitluse pärast tehnilist alistumist;</ListItem>
            <ListItem>võitleja annab verbaalselt märku alistumisest (sh löökide tõttu);</ListItem>
            <ListItem>mängu ajakava ja/või mängupäeva muudetakse.</ListItem>
            <br></br>
            <h4>4.12. Tennis</h4>
            4.12.1. Kõigi mängitud geimide arvule viitavate kihlvedude puhul loetakse kiire lõppmäng üheks geimiks.<br></br>
            <br></br>
            4.12.2. Turu arveldamine toimub loobumise korral järgmiselt.<br></br>
            <br></br>
            4.12.2.1. Võitja: mängija, kes pääseb järgmisesse raundi (kui mõjutatud geimis saadi punkt).<br></br>
            <br></br>
            4.12.2.2. X. sett – võitja: mängija, kes pääseb järgmisesse raundi (kui mõjutatud setis saadi punkt).<br></br>
            <br></br>
            4.12.2.3. X. seti geim X – võitja: mängija, kes pääseb järgmisesse raundi (kui mõjutatud geimis saadi punkt).<br></br>
            <br></br>
            4.12.3. Kui võistlejate/võistkondade nimesid näidatakse valesti, jätame endale õiguse panustamine tühistada.<br></br>
            <br></br>
            4.12.4. Kõik panused jäävad kehtima järgmistes olukordades:<br></br>
            <br></br>
            <ListItem>mängu ajakava ja/või mängupäeva muudetakse.</ListItem>
            <ListItem>toimumiskohta muudetakse;</ListItem>
            <ListItem>sisetenniseväljaku asemel mängitakse välistenniseväljakul või vastupidi;</ListItem>
            <ListItem>pinnavahetus (kas enne mängu või selle ajal).</ListItem>
            <br></br>
            <h4>4.13 Hobuste võidusõit</h4>
            4.13.1 Kui hobune ei osale võidusõidul, makstakse kõik selle hobuse peale tehtud panused tagasi (tingimusel, et panust ei tehtud enne lõpliku võistlusväljaku ametlikku väljakuulutamist
            kõik-või-mitte-midagi-kihlvedude puhul). Kõigile teistele võistlejatele tehtud panused arvutatakse ümber. Ümberarvutamisel korrutatakse asjaomase panuse koefitsient marginaaliga korrigeeritud
            mahaarvamisteguriga (1 – mitteosalemise tõenäosus) ajal, mil saadakse andmed mitteosalemise kohta. Kui hiljem eemaldatakse võidusõidult rohkem hobuseid, korratakse sama protsessi.<br></br>
            <br></br>
            <h4>4.14 Talispordialad</h4>
            4.14.1 Kui konkreetne sündmus lükatakse edasi või jäetakse ära, jäävad panused kehtima tingimusel, et sündmus toimub 72 tunni jooksul.<br></br>
            <br></br>
          </div>
        </Typography>
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    host: state.settings.host,
  };
};

export default connect(mapStateToProps, {})(withStyles(styles)(BettingRules));
