/**
 * SVG component for own icon 
 */

import React from 'react';
import './IconOwn.css';

const IconAF = (props) => {

	return (
		<div style={{ display: "flex" }}>
			<svg className="icon-own" viewBox="0 0 2000 2000">
				<linearGradient gradientTransform="rotate(90)" id="icongradient">
					<stop className="icon-color-1" offset="0%" />
					<stop className="icon-color-2" offset="50%" />
					<stop className="icon-color-1" offset="100%" />
				</linearGradient>
				<g><path fill="url(#icongradient)" d="M1855.6,259.3c-12.1-59.1-58.1-103.7-115.4-115.4c-446.8-91.1-905.7,46.7-1227.7,368.6S52.7,1293.4,143.9,1740.2
	c11.7,57.4,56.4,103.4,115.4,115.4C1210.9,2049.7,2049.4,1208.9,1855.6,259.3z M1644.8,180.1c-81.6,12.5-163,31.8-243.5,57.7
	l-63.3-63.3C1441.3,164.2,1544,166.3,1644.8,180.1z M1273.2,182.9l73.5,73.5c-71,25.6-138.6,55.3-203.7,88.8L1038.7,241
	C1114.7,214.5,1193.4,195,1273.2,182.9z M549.1,549.1c126.7-126.7,275.4-223.7,436.3-288.1v0l109.8,109.8
	c-43.8,24.2-86.6,50.3-128.4,78.2l-34.5-34.5c-10.1-10.1-26.5-10.1-36.6,0c-10.1,10.1-10.1,26.5,0,36.6l27.8,27.8
	c-15.6,11.1-31.1,22.4-46.4,34.1l-29.9-29.9c-10.1-10.1-26.5-10.1-36.6,0c-10.1,10.1-10.1,26.5,0,36.6L836,545
	c-14.8,11.9-29.5,24.1-43.9,36.5l-30-30c-10.1-10.1-26.5-10.1-36.6,0c-10.1,10.1-10.1,26.5,0,36.6l27.7,27.7
	c-14,12.7-27.9,25.6-41.7,38.8l-29-29c-10.1-10.1-26.5-10.1-36.6,0c-10.1,10.1-10.1,26.5,0,36.6l28.8,28.8
	c-13.4,13.5-26.5,27.2-39.4,41L608.4,705c-10.1-10.1-26.5-10.1-36.6,0c-10.1,10.1-10.1,26.5,0,36.6l28.6,28.6
	c-12.6,14.3-25,28.7-37.1,43.3l-23.4-23.4c-10.1-10.1-26.5-10.1-36.6,0c-10.1,10.1-10.1,26.5,0,36.6l27.3,27.3
	c-11.8,15.1-23.4,30.3-34.7,45.7l-24.5-24.5c-10.1-10.1-26.5-10.1-36.6,0c-10.1,10.1-10.1,26.5,0,36.6l30.6,30.6
	c-34.2,49.5-65.8,100.4-94.7,152.6L261,985.4C325.1,825,422.7,675.4,549.1,549.1z M241.1,1038.7l104.2,104.2
	c-33.2,64.6-63,132.1-88.8,203.7l-73.5-73.5C195,1193.3,214.5,1114.7,241.1,1038.7z M180.1,1644.7L180.1,1644.7
	c-13.9-102.1-15.8-205-5.5-306.7l63.3,63.3C211.9,1481.8,192.6,1563.1,180.1,1644.7z M269.6,1804.9L269.6,1804.9
	c-20.4-4.2-38.4-14.7-51.8-29.4c7.4-111.5,28-222.9,61.4-332.9l111.2,111.2c1.1,1.9,2.4,3.6,4,5.2l267.1,265.9
	C532.5,1837.8,400.5,1831.6,269.6,1804.9z M726.3,1816.5l-271.4-270.3c-1.1-1.8-2.3-3.5-3.9-5l-154-154
	c23.9-69.6,52.9-138.2,86.7-205.8l577,577C884.8,1785,806.1,1804.5,726.3,1816.5z M1014.1,1738.5l-605.2-605.2
	c28.5-52.6,59.8-103.8,93.8-153.6l23.6,23.6c10.1,10.1,26.5,10.1,36.6,0c10.1-10.1,10.1-26.5,0-36.6l-29.9-29.9
	c11.2-15.4,22.8-30.7,34.5-45.9l27.2,27.2c10.1,10.1,26.5,10.1,36.6,0c10.1-10.1,10.1-26.5,0-36.6L600,850.3
	c12.1-14.6,24.4-29.1,37-43.4l26.2,26.2c10.1,10.1,26.5,10.1,36.6,0c10.1-10.1,10.1-26.5,0-36.6l-28-28c12.9-13.8,26-27.5,39.4-41.1
	l26.1,26.1c10.1,10.1,26.5,10.1,36.6,0c10.1-10.1,10.1-26.5,0-36.6L748,691.1c13.7-13.2,27.6-26.1,41.7-38.7l27.1,27.1
	c10.1,10.1,26.5,10.1,36.6,0c10.1-10.1,10.1-26.5,0-36.6l-24.7-24.7c14.5-12.4,29.2-24.5,44.1-36.4L902,611
	c10.1,10.1,26.5,10.1,36.6,0c10.1-10.1,10.1-26.5,0-36.6L914,549.8c15.4-11.6,30.9-22.9,46.6-33.9l26.5,26.5
	c5.1,5.1,11.7,7.6,18.3,7.6c6.6,0,13.2-2.5,18.3-7.6c10.1-10.1,10.1-26.5,0-36.6l-19.6-19.6c42.1-27.7,85.1-53.6,129.2-77.4
	l262.8,262.8c0.8,0.8,1.6,1.4,2.4,2.1l340.1,340.3C1608.8,1338.1,1349.2,1604.3,1014.1,1738.5z M1758.4,960.8l-348.2-348.5
	c-0.8-0.8-1.6-1.5-2.5-2.1l-226.4-226.4c67.5-33.9,136.1-62.8,205.8-86.7l429.3,429.3C1804.5,806.1,1785,884.8,1758.4,960.8z
	 M1824.9,661.5l-382.4-382.4c110.1-33.4,221.4-53.9,332.9-61.4c14.7,13.4,25.3,31.4,29.4,51.8
	C1831.6,400.5,1837.9,532.5,1824.9,661.5z"/>
				</g>
			</svg>
		</div>
	);
};

export default IconAF;
