/**
 * Stake Pad component
 */

import React from 'react';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import Stack from '@mui/material/Stack';
import BackspaceOutlinedIcon from '@mui/icons-material/BackspaceOutlined';
import './StakePad.css';
import { Box, Typography } from '@mui/material';
import { useSelector } from 'react-redux';



export default function StakePad(props) {
  const stake = useSelector(state => state.slips[props?.slipTemplate ? props.slipTemplate : "0"].stake);

  const confirmTheAmount = () => {
    props.parentCallBack(props.stakePadValueReceiver, 'set');
  }
  

  const numPad = () => {
    return (
      <div className="stake-pad-grid font-bold font-xxl">
        <div className="bg-grey-2 stake-pad-button font-xl" onClick={e => { props.parentCallBack(props.stakePadValueReceiver, 10) }}>10</div>
        <div className="bg-grey-2 stake-pad-button  font-xl" onClick={e => { props.parentCallBack(props.stakePadValueReceiver, 25) }}>25</div>
        <div className="bg-grey-2 stake-pad-button  font-xl" onClick={e => { props.parentCallBack(props.stakePadValueReceiver, 50) }}>50</div>
        <div className="bg-grey-14 stake-pad-button  font-xl" onClick={e => { props.parentCallBack(props.stakePadValueReceiver, 1) }}>1</div>
        <div className="bg-grey-14 stake-pad-button  font-xl" onClick={e => { props.parentCallBack(props.stakePadValueReceiver, 2) }}>2</div>
        <div className="bg-grey-14 stake-pad-button  font-xl" onClick={e => { props.parentCallBack(props.stakePadValueReceiver, 3) }}>3</div>
        <div className="bg-grey-14 stake-pad-button  font-xl" onClick={e => { props.parentCallBack(props.stakePadValueReceiver, 4) }}>4</div>
        <div className="bg-grey-14 stake-pad-button  font-xl" onClick={e => { props.parentCallBack(props.stakePadValueReceiver, 5) }}>5</div>
        <div className="bg-grey-14 stake-pad-button  font-xl" onClick={e => { props.parentCallBack(props.stakePadValueReceiver, 6) }}>6</div>
        <div className="bg-grey-14 stake-pad-button  font-xl" onClick={e => { props.parentCallBack(props.stakePadValueReceiver, 7) }}>7</div>
        <div className="bg-grey-14 stake-pad-button  font-xl" onClick={e => { props.parentCallBack(props.stakePadValueReceiver, 8) }}>8</div>
        <div className="bg-grey-14 stake-pad-button  font-xl" onClick={e => { props.parentCallBack(props.stakePadValueReceiver, 9) }}>9</div>
        <div className="bg-grey-14 stake-pad-button  font-xl" onClick={e => { props.parentCallBack(props.stakePadValueReceiver, 'decimal') }}>,</div>
        <div className="bg-grey-14 stake-pad-button  font-xl" onClick={e => { props.parentCallBack(props.stakePadValueReceiver, 0) }}>0</div>
        <div className="bg-grey-14 stake-pad-button  font-xl" onClick={e => { props.parentCallBack(props.stakePadValueReceiver, 'delete') }}><BackspaceOutlinedIcon/></div>
        <div className="bg-light stake-pad-place-button ">
          <Stack direction="row" width="100%">
          <Button fullWidth variant="contained" size="small" color="primary"  onClick={confirmTheAmount}>
             <Typography sx={{marginRight: "6px", fontSize: "2em" }} align='center'>{Number(stake)} OK </Typography>
          </Button>
          </Stack>
        </div>
      </div>
    );
  }

  return (
    <div>
      <Drawer sx={{zIndex: 2000}} anchor="bottom" open={props.stakePadOpen}>
        {numPad()}
      </Drawer>
    </div>
  );
}

