/**
 * SVG component for own icon 
 */

import React from 'react';
import './IconOwn.css';

const IconSQ = (props) => {

	return (
		<div style={{ display: "flex" }}>
			<svg className="icon-own" viewBox="0 0 2000 2000">
				<linearGradient gradientTransform="rotate(90)" id="icongradient">
					<stop className="icon-color-1" offset="0%" />
					<stop className="icon-color-2" offset="50%" />
					<stop className="icon-color-1" offset="100%" />
				</linearGradient>
				<g><polygon points="1873.1,624.4 1873.1,624.4 1873.1,624.4 	" fill="url(#icongradient)" />
					<path d="M1016.8,270.7c-95.6,94.7-202.7,280.4-266.3,462c-39.4,112.5-58,212.2-56.1,295.6c-44.3,102.1-103,194.4-159.2,250.7
		c-34.6-24.4-68.6-17.4-86.9,0.6c-17,16.6-27.4,49.7-1,88.1c0.5,0.7,1,1.4,1.6,2l10.4,10.4l-314,301.8c-11.5,10.1-17.9,22.2-18.5,35
		c-0.6,13.8,5.3,27.4,17.1,39.8l82.7,96.5c9.1,11.6,21.5,18.3,34.7,19.1c0.8,0,1.6,0.1,2.4,0.1c13.7,0,27.4-6.3,38.9-17.9
		L618.2,1539l11,11c0.5,0.5,1.1,1,1.7,1.5c16.2,11.6,32.2,16.4,46.6,16.4c16.9,0,31.6-6.6,41.6-16.4c16.8-16.5,26.9-49.2,1-87.5
		c56.3-56.3,148.8-115.1,251-159.4c3.8,0.1,7.4,0.4,11.3,0.4c81,0,176.8-18.7,284-56.3c179.6-62.9,365.2-169.9,462-266.2
		c92.2-91.8,145-222.3,144.7-358.1c-0.2-136-51.7-261.6-144.8-353.7C1535.2,79.9,1209.4,79.9,1016.8,270.7z M699.2,1084L699.2,1084
		c4.6,30.3,12.5,57.9,23.8,82.3c-36.1,67.3-80.1,128.5-125.4,174.6l-42.6-42.6C605.1,1248.1,656.8,1171,699.2,1084z M738.7,1195.3
		c8.5,13.2,18,25.4,28.9,36.3c10.9,10.9,23.2,20.4,36.4,28.8c-63.5,35.7-121,78-165.3,121.5l-21.4-21.4
		C660.7,1316.3,703,1258.8,738.7,1195.3z M283.1,1835c-6.5,6.6-13.9,9.9-20.1,9.7c-5.4-0.3-10.2-3.2-14.9-9l-83.5-97.4
		c-4.7-5-10.3-12.6-10-20c0.2-5.1,3.3-10.3,9.5-15.8l314.8-302.6l119.7,119.7L283.1,1835z M699.7,1531.8c-10.1,10-30,13.1-51.8-2.1
		l-178.5-178.6c-13.1-19.7-13.8-39.9-1.8-51.7c10.1-9.9,29.7-13,51.3,2l178.6,178.6C713.6,1503.9,709.3,1522.4,699.7,1531.8z
		 M700.8,1444.2l-42.6-42.6c46-45.2,107.1-89.1,174.4-125.2c24.5,11.3,52,19.2,82.3,23.8C828.1,1342.5,751.1,1394.1,700.8,1444.2z
		 M1708.9,962.9c-92.6,92.2-278.3,199-451.6,259.7c-216.2,75.8-387.6,72-470.1-10.6c-82.5-82.5-86.4-253.8-10.6-470.1
		c62.4-178,166.8-359.5,259.7-451.5c91-90.2,213.5-135.3,336.1-135.3s245.2,45.1,336.5,135.3c87.8,86.8,136.3,205.4,136.6,334
		C1845.7,752.9,1795.9,876.2,1708.9,962.9z"/>
					<path fill="url(#icongradient)" d="M1672.8,325.9c-161.5-153.5-436.4-153.5-600.3-0.1C944.6,445.5,787.3,768.5,783.6,990c-1.3,77.8,16.6,135.8,53.2,172.4
		c39,39,97.6,52.8,160.9,52.8c89,0,187.6-27.3,254.7-50.3c166.2-56.8,335.2-152.4,420.6-237.9c153.5-153.7,153.8-428.6,0.5-600.3
		C1673.3,326.4,1673.1,326.2,1672.8,325.9z M1643.2,336.5l-56,56l-77.3-77.3l39.4-39.4C1583,291.3,1614.8,311.5,1643.2,336.5z
		 M1087.6,737.3l91.2-91.2l77.3,77.3l-91.2,91.2L1087.6,737.3z M1145.4,834.2l-91.2,91.2l-77.3-77.3l91.2-91.2L1145.4,834.2z
		 M1198.4,626.6l84.2-84.2l77.3,77.3l-84.2,84.2L1198.4,626.6z M1379.5,639.3l77.3,77.3l-84.2,84.2l-77.3-77.3L1379.5,639.3z
		 M1399.1,619.7l84.2-84.2l77.3,77.3l-84.2,84.2L1399.1,619.7z M1406,419l84.2-84.2l77.3,77.3l-84.2,84.2L1406,419z M1463.7,515.9
		l-84.2,84.2l-77.3-77.3l84.2-84.2L1463.7,515.9z M1282.6,503.2l-77.3-77.3l84.2-84.2l77.3,77.3L1282.6,503.2z M1263,522.8
		l-84.2,84.2l-77.3-77.3l84.2-84.2L1263,522.8z M1159.2,626.6l-91.2,91.2l-77.3-77.3l91.2-91.2L1159.2,626.6z M1048.5,737.3
		l-91.2,91.2L880,751.2l91.2-91.2L1048.5,737.3z M1034.6,945l-84.2,84.2l-77.3-77.3l84.2-84.2L1034.6,945z M1054.2,964.5l77.3,77.3
		l-84.2,84.2l-77.3-77.3L1054.2,964.5z M1073.8,945l91.2-91.2l77.3,77.3l-91.2,91.2L1073.8,945z M1184.5,834.2l91.2-91.2l77.3,77.3
		l-91.2,91.2L1184.5,834.2z M1372.6,840l77.3,77.3l-91.2,91.2l-77.3-77.3L1372.6,840z M1392.2,820.4l84.2-84.2l77.3,77.3l-84.2,84.2
		L1392.2,820.4z M1496,716.6l84.2-84.2l77.3,77.3l-84.2,84.2L1496,716.6z M1502.9,515.9l84.2-84.2l77.3,77.3l-84.2,84.2
		L1502.9,515.9z M1521.5,264.3l-31.2,31.3l-52.6-52.6C1466.2,247.3,1494.4,254.3,1521.5,264.3z M1392.6,238.6c0.5,0,1.1,0.1,1.6,0.1
		l76.5,76.5l-84.2,84.2l-77.3-77.3L1392.6,238.6z M1353.4,238.6l-63.9,63.9l-44.8-44.8C1280,246.7,1316.6,240.3,1353.4,238.6z
		 M1215.8,267.9L1215.8,267.9l54.2,54.2l-84.2,84.2l-76.2-76.2C1141.7,303.7,1177.7,283,1215.8,267.9z M1088.8,348.6l77.3,77.3
		l-84.2,84.2l-69.4-69.4C1038.1,403.8,1064,372.3,1088.8,348.6z M996.7,464.1l65.6,65.6l-91.1,91.1l-40.7-40.7
		C951.4,538.6,973.8,499.3,996.7,464.1z M917.6,606.5l34,34l-83.2,83.2C882.9,683.8,899.6,644.4,917.6,606.5z M848.8,782.2
		c0.2-0.2,0.5-0.2,0.7-0.4l11-11l77.3,77.3l-84.2,84.2l-33.1-33.1C826.9,861.3,836.6,821.9,848.8,782.2z M815.4,933.4l18.5,18.5
		l-22,22C812.6,960.7,813.7,947.2,815.4,933.4z M811.6,1013.4l41.9-41.9l77.3,77.3l-83.5,83.5
		C825.6,1104.7,813.6,1064.9,811.6,1013.4z M986.4,1187c-51-1.6-91.6-13.3-119.5-35.1l83.5-83.5l77.3,77.3L986.4,1187z
		 M1026.1,1186.5l21.2-21.2l17.4,17.4C1051.4,1184.4,1038.5,1185.7,1026.1,1186.5z M1221.8,1145.4c-0.2,0.2-0.2,0.4-0.4,0.6
		c-43.8,14.1-84.9,24.5-122.8,31.4l-31.7-31.7l84.2-84.2l77.3,77.3L1221.8,1145.4z M1248,1119.2l-77.3-77.3l91.2-91.2l77.3,77.3
		L1248,1119.2z M1281.2,1125.1l77.5-77.5l31.2,31.2C1354.4,1095.7,1317.8,1111.3,1281.2,1125.1z M1416.2,1065.9l-37.9-37.9
		l91.2-91.2l63.4,63.4C1497.1,1023,1457.6,1045.2,1416.2,1065.9z M1556.6,984.8l-67.5-67.5l84.2-84.2l77.3,77.3
		C1625.9,934.5,1593.8,959.8,1556.6,984.8z M1669.5,890.1l-76.7-76.7l84.2-84.2l55.7,55.7C1717.8,823.3,1696.7,858.9,1669.5,890.1z
		 M1742.9,755.9l-46.2-46.2l64-64C1759.6,683.3,1753.7,720.4,1742.9,755.9z M1760.1,607.1l-83,83l-77.3-77.3l84.2-84.2l75.9,75.9
		C1760,605.3,1760,606.2,1760.1,607.1z M1754.8,560.2l-51.2-51.2l29.7-29.7C1743,505.6,1750.2,532.7,1754.8,560.2z M1684,489.4
		l-77.3-77.3l55.7-55.7c24.3,29.3,44.1,61.4,59.4,95.2L1684,489.4z"/>
					<path fill="url(#icongradient)" d="M1594,1401.8c-106.9,0-193.8,86.9-193.8,193.8s86.9,193.8,193.8,193.8s193.8-86.9,193.8-193.8S1700.9,1401.8,1594,1401.8z
		 M1594,1761.6c-91.6,0-166.1-74.5-166.1-166.1c0-91.6,74.5-166.1,166.1-166.1c91.6,0,166.1,74.5,166.1,166.1
		C1760.1,1687.1,1685.6,1761.6,1594,1761.6z"/>
					<circle cx="1566.4" cy="1498.6" r="13.8" fill="url(#icongradient)" />
					<circle cx="1635.6" cy="1498.6" r="13.8" fill="url(#icongradient)" /></g>
			</svg>
		</div>
	);
};

export default IconSQ;
