// Goal scorer market implemented
import React from "react";
import { connect } from "react-redux";
import {
  saveBet,
  deleteBet,
  saveMarketSetConfig,
  updateOddsInLeague,
  getOddsHistory,
} from "../../actions";
import "./OutrightGameBox.css";
import "./Odds.css";

class GoalScorerMarket extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  UNSAFE_componentWillMount() {
    this.domReferences = {};
  }

  componentWillUnmount() {
    this.domReferences = {};
  }

  /**
   * Check if odd / bet is selected
   */

  checkSelectedOdd(odd, game, index) {
    const { bets } = this.props;
    if (!odd || !odd?.s) return false;

    if (!odd) return false;

    let payload = {
      gameId: game.gameid,
      // isLive: game.isLive
    };

    const marketId = odd.m;
    const selectionIds = odd?.s?.split(",");
    const prices = odd?.p?.split(",");
    const hc = odd?.hc;
    const isActives = odd?.iA?.split(",");

    if (selectionIds?.length < 3 && selectionIds?.length > 1) {
      selectionIds.unshift(null);
      prices.unshift(null);
      isActives.unshift(null);
    } else {
      selectionIds.push(null);
      prices.push(null);
      isActives.push(null);
      selectionIds.push(null);
      prices.push(null);
      isActives.push(null);
    }

    if (Number(prices[index]) < 1) return false;

    // payload.isMultiBet = 0;
    // payload.isBank = 0;
    payload.marketId = Number(marketId);
    payload.selectionId = Number(selectionIds[index]);
    payload.price = Number(prices[index]);
    payload.hc = hc;
    // payload.isActive = Number(isActives[index]);

    let flag = false;

    if (!bets || !odd) return flag;

    for (let i = 0; i < bets?.length; i++) {
      let bet = bets[i];
      // if ((bet.gameId === payload.gameId) && (bet.hc === payload.hc) && (bet.isActive === payload.isActive) && (bet.isBank === payload.isBank) && (bet.isLive === payload.isLive) && (bet.isMultiBet === payload.isMultiBet) && (bet.marketId === payload.marketId) && (bet.selectionId === payload.selectionId)) {
      if (
        bet.gameId === payload.gameId &&
        bet.marketId === payload.marketId &&
        bet.selectionId === payload.selectionId &&
        bet.hc === payload.hc
      ) {
        flag = true;
        break;
      }
    }

    // for (let key in slipTemplates) {
    //   if (slipTemplates[key].slipTemplate === selectedSlipTemplate) {

    //     let bets = slipTemplates[key].settings.bets;

    //     for (let nextKey in bets) {
    //       if ((bets[nextKey].gameId === payload.gameId) && (bets[nextKey].hc === payload.hc) && (bets[nextKey].isActive === payload.isActive) && (bets[nextKey].isBank === payload.isBank) && (bets[nextKey].isLive === payload.isLive) && (bets[nextKey].isMultiBet === payload.isMultiBet) && (bets[nextKey].marketId === payload.marketId) && (bets[nextKey].selectionId === payload.selectionId)) {
    //         flag = true;
    //         break;
    //       }
    //     }
    //   }
    // }

    return flag;
  }

  /**
   * Select odd for bet
   */

  addBet(odd, game, index) {
    const { selectedSlipTemplate } = this.props;
    this.setState({ betAdded: !this.state.betAdded });
    if (this.deleteBet(odd, game, index) === true || !odd) return false;

    let payload = {
      gameId: game.gameid,
      isLive: game.isLive,
    };

    const marketId = odd.m;
    const selectionIds = odd.s?.split(",");
    const prices = odd.p?.split(",");
    const hc = odd.hc;
    const isActives = odd.iA?.split(",");

    if (selectionIds?.length < 3 && selectionIds?.length > 1) {
      selectionIds.unshift(null);
      prices.unshift(null);
      isActives.unshift(null);
    } else if (selectionIds?.length === 1) {
      selectionIds.push(null);
      prices.push(null);
      isActives.push(null);
      selectionIds.push(null);
      prices.push(null);
      isActives.push(null);
    }

    if (Number(prices[index]) < 1) return false;
    if (Number(prices[index]) === 1) return false;
    if (Number(isActives[index]) === 0) return false;

    payload.marketId = Number(marketId);
    payload.selectionId = Number(selectionIds[index]);
    payload.price = Number(prices[index]);
    payload.hc = hc;
    payload.isActive = Number(isActives[index]);
    payload.game = { ...game, isOutright: true };
    payload.sn = odd?.sn;

    this.props.saveBet(payload, selectedSlipTemplate, this.constructor.name);
    return Object.keys(payload)?.length === 0 ? false : true;
  }

  /**
   * Deselect odd from bet
   */

  deleteBet(odd, game, index) {
    const self = this;
    const marketId = odd.m;
    const selectionIds = odd.s?.split(",");

    if (selectionIds?.length < 3) {
      selectionIds.unshift(null);
    }

    let payload = {};
    payload = self.props.bets.filter(function (bet) {
      return (
        bet.gameId === game.gameid &&
        bet.marketId === Number(marketId) &&
        bet.selectionId === Number(selectionIds[index]) &&
        bet.hc === odd.hc
      );
    });

    if (payload?.length) {
      self.props.deleteBet(payload[0], self.props.selectedSlipTemplate);
      return Object.keys(payload[0])?.length === 0 ? false : true;
    } else {
      return false;
    }
  }

  isInViewport(element) {
    const rect = element.getBoundingClientRect();
    return (
      (rect.top >= 150 &&
        rect.left >= 0 &&
        rect.top <=
          (window.innerHeight - 66 ||
            document.documentElement.clientHeight - 66) &&
        rect.left <=
          (window.innerWidth || document.documentElement.clientWidth)) ||
      (rect.bottom >= 150 &&
        rect.right >= 0 &&
        rect.bottom <=
          (window.innerHeight - 66 ||
            document.documentElement.clientHeight - 66) &&
        rect.right <=
          (window.innerWidth || document.documentElement.clientWidth))
    );
  }

  saveRefs(key, ref) {
    this.domReferences[key] = ref;
  }

  checkOneWeekLater(timeStamp) {
    let today = new Date();
    let time = new Date(timeStamp);
    let difference = Math.floor(
      (Date.UTC(time.getFullYear(), time.getMonth(), time.getDate()) -
        Date.UTC(today.getFullYear(), today.getMonth(), today.getDate())) /
        (1000 * 60 * 60 * 24)
    );

    if (difference > 7) return true;

    return false;
  }

  formatMarketViewerLabels(sportCode, gameState, marketLabel) {
    if (sportCode !== "FB") {
      let labels = marketLabel.split("}");
      if (labels?.length > 1) {
        if (gameState !== "not_started") {
          return gameState + "" + labels[1];
        } else {
          return labels[1].trim();
        }
      } else {
        return marketLabel;
      }
      // let formattedMarketLabel = marketLabel.split("}")
    } else {
      return marketLabel;
    }
  }

  renderGoalScorerOdds(selectedOdds, game) {
    const { marketSetValue, marketViewerLabels } = this.props;
    const selectedOdd = selectedOdds[0];
    const allSelections = selectedOdd.s?.split(",");
    const market38Label = selectedOdds.find((item) => item.m === 38);
    const grouppedOddsByMarket = {};
    let label = null;
    if (market38Label?.hc && market38Label?.hc?.includes("goalnr")) {
      label =
        market38Label.hc.split("|")[0][
          market38Label.hc.split("|")[0]?.length - 1
        ];
    }

    const oddGenerator = (odd, key) => {
      if (!odd) return {};
      return {
        p: odd?.p?.split(",")[key],
        s: odd?.s?.split(",")[key],
        m: odd?.m,
        iF: odd?.iF,
        iA: odd?.iA?.split(",")[key],
        // grp: odd.grp,
        hc: odd?.hc,
        sn: odd?.sn?.split(",")[key],
      };
    };

    marketSetValue?.forEach((marketID) => {
      const marketOdd = selectedOdds?.find((item) => item.m === marketID);
      const generatedOdds = [];
      const selectionIds = marketOdd?.s?.split(",");

      selectionIds?.forEach((sOddId, indx) => {
        const generatedOdd = oddGenerator(marketOdd, indx);
        generatedOdds.push(generatedOdd);
      });

      grouppedOddsByMarket[marketID] = generatedOdds;
    });

    return (
      <div>
        <div className={`marketset-grid-4-cols`}>
          <div></div>
          {marketSetValue.map((marketId, key) => (
            <div key={key} className={`content-center vertical-center`}>
              <div className="vertical-center grey-3 spacer-left-list"></div>
              {marketViewerLabels &&
                marketViewerLabels[marketId] &&
                this.formatMarketViewerLabels(
                  game.sport_code,
                  game.state,
                  marketViewerLabels[marketId]?.includes("goalnr")
                    ? marketViewerLabels[marketId].replace(
                        "{!goalnr}",
                        `${label}. `
                      )
                    : marketViewerLabels[marketId]
                )}
            </div>
          ))}
        </div>
        {allSelections?.map((selectionId, key) => {
          const market38 = grouppedOddsByMarket[marketSetValue[0]]?.find(
            (item) => item.s === selectionId
          );
          const market39 = grouppedOddsByMarket[marketSetValue[1]]?.find(
            (item) => item.s === selectionId
          );
          const market40 = grouppedOddsByMarket[marketSetValue[2]]?.find(
            (item) => item.s === selectionId
          );

          return (
            <div key={key}>
              <div className={`marketset-grid-4-cols-goalscorer`}>
                <div className="color-team font-team vertical-center">
                  {market38?.sn}
                </div>
                {market38 ? (
                  <div
                    onClick={this.addBet.bind(this, market38, game, 0)}
                    className={`${
                      this.checkSelectedOdd(market38, game, 0)
                        ? "selected-odds"
                        : ""
                    } content-center vertical-center cursor-pointer font-xl font-bold-2 odds-btn-outright border-odds-right border-odds-left`}
                    style={{
                      margin: "0.15em 0.3em",
                      color:
                        market38.iA != "1"
                          ? "var(--grey-5)"
                          : "var(--color-odds)",
                    }}
                  >
                    {parseFloat(market38.p).toFixed(2)}
                  </div>
                ) : null}
                {market39 ? (
                  <div
                    onClick={this.addBet.bind(this, market39, game, 0)}
                    className={`${
                      this.checkSelectedOdd(market39, game, 0)
                        ? "selected-odds"
                        : ""
                    } content-center vertical-center cursor-pointer font-xl font-bold-2 odds-btn-outright border-odds-right border-odds-left`}
                    style={{
                      margin: "0.15em 0.3em",
                      color:
                        market39.iA != "1"
                          ? "var(--grey-5)"
                          : "var(--color-odds)",
                    }}
                  >
                    {parseFloat(market39.p).toFixed(2)}
                  </div>
                ) : null}
                {market40 ? (
                  <div
                    onClick={this.addBet.bind(this, market40, game, 0)}
                    className={`${
                      this.checkSelectedOdd(market40, game, 0)
                        ? "selected-odds"
                        : ""
                    } content-center vertical-center cursor-pointer font-xl font-bold-2 odds-btn-outright border-odds-right border-odds-left`}
                    style={{
                      margin: "0.15em 0.3em",
                      color:
                        market40.iA != "1"
                          ? "var(--grey-5)"
                          : "var(--color-odds)",
                    }}
                  >
                    {parseFloat(market40.p).toFixed(2)}
                  </div>
                ) : null}
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  render() {
    const { game, marketSetValue, isInCatalog } = this.props;
    if (!game) return null;
    const selectedOdds = game.odds?.filter(function (odd) {
      return marketSetValue?.includes(odd.m);
    });
    return (
      <div>
        <div className={!isInCatalog&&"games-list-box"}>
          <div className="spacer-left-list" style={{ marginTop: "10px" }}>
            {selectedOdds?.length > 0
              ? this.renderGoalScorerOdds(selectedOdds, game)
              : null}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let marketSetConfig;

  marketSetConfig = state.settings ? state.settings.marketSets : null;
  let games = state.selected.leagues[ownProps.leagueId] ? state.selected.leagues[ownProps.leagueId]["games"] : {};

  return {
    marketSetValue:
      marketSetConfig["FB"]["sidebets"][ownProps.currentMarketSetKey],
    marketViewerLabels: state.labels.market ? state.labels.market.viewer : null,
    selectionPrintLabels: state.labels.selection
      ? state.labels.selection.print
      : null,
    leagueFilter: state.leagues._leagueFilter,
    bets:
      state.slips && state.slips[state.selected.slipTemplate].settings?.bets
        ? Object.values(state.slips[state.selected.slipTemplate].settings.bets)
        : null,
    marketSetConfig: marketSetConfig,
    currentMarketSetKey: ownProps.currentMarketSetKey,
    game: ownProps?.game ? ownProps.game : games[ownProps.gameId],
    selectedSlipTemplate: state.selected.slipTemplate,
  };
};

export default connect(mapStateToProps, {
  saveBet,
  deleteBet,
  saveMarketSetConfig,
  updateOddsInLeague,
  getOddsHistory,
})(GoalScorerMarket);
