/**
  Betbuilder
 */
import "./BetBuilderWidget.css";
import { log } from "../../config/log";
import React from "react";
import { connect } from "react-redux";
import { Translate } from "react-redux-i18n";
import withStyles from "@mui/styles/withStyles";
import { Button, IconButton, Modal, Stack } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import Close from "@mui/icons-material/Close";
import {
  saveBet,
  deleteBet,
  toggleMyTicketsPanel,
  getBetBuilderSlipBet,
  emptySlipTemplate,
} from "../../actions";
import withRouter from "../../helpers/withRouter";
import PrecannedBetsCarousel from "../dynamic-banners/precanned-bets";

function isJson(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

const styles = (theme) => ({
  root: {
    "& .MuiTabs-root": {
      minHeight: "1em",
    },
    "& .MuiTab-root": {
      minHeight: "1em",
    },
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: "20px",
  },
  contentContainer: {
    width: "100%",
  },
  content_body: {
    width: "50%",
    paddingTop: "30px",
    margin: "0 auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    "@media (hover:none)": {
      width: "80%",
      paddingTop: "20px"
    },
  },
  carousel_container: {
    "@media (hover:none)": {
      width: "95%",
      margin: "0 auto",
    },
  },
  contentHeadline: {
    fontSize: "1.3em",
    margin: "1em 0 0",
  },
  contentText: {
    fontSize: "1.1em",
    margin: "0 0 2em 0",
    textAlign: "center",
  },
  betbuilderButton: {
    color: "var(--btn-text)",
    justifyContent: "center",
    background: "var(--bg-btn)",
    width: "50%",
    "@media (hover:none)": {
      width: "80%",
    },
  },
  paper: {
    width: "50%",
    backgroundColor: theme.palette.background.paper,
    border: "none",
    outline: "none",
    boxShadow: theme.shadows[5],
    "@media only screen and (max-width: 603px)": {
      width: "100%",
      height: "100dvh",
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  builder_closer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px",
    backgroundColor: "var(--bg-app)",
  },
  headline: {
    color: "var(--color-contrast)",
    fontWeight: "800",
    fontSize: "1.2em",
  },
  promo: {
    height: "auto",
    width: "50%",
    borderRadius: "0.4em",
    margin: "1em 0",
    "@media (hover:none)": {
      width: "80%",
    },
  },
});

class BetbuilderWidget extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isBuilderOpen: false,
    };
    this.gameData = this.props.game;
  }

  handleBuilderModal = () => {
    this.setState({ isBuilderOpen: !this.state.isBuilderOpen });
  };

  handleAddBet = (res) => {
    const { layout, locale } = this.props;
    const data = res?.payload ? res.payload : {};
    let consumerFixtureId = data?.ConsumerFixtureId
      ? data?.ConsumerFixtureId?.split(":")
      : [];
    let fixtureId = consumerFixtureId[2];
    if (this.props.game?.gameid !== parseInt(fixtureId)) return;
    let payload = {
      game: this.gameData,
      gameId: this.gameData?.gameid,
      consumerFixtureId: data?.ConsumerFixtureId,
      isLive: false,
      isBetBuilder: true,
      marketId: undefined,
      betslipUid: data?.BetSlipUid,
      price: data?.PriceDetails?.DecimalPriceRounded,
      isActive: true,
      hc: "",
      selectionId: data?.BetSlipUid,
      description: data?.Description,
      translations: data?.Translations,
      identifier: data?.Identifier,
      marketDescription: data?.LegDetails,
      isMultiBet: 0,
      sn: data?.Description?.toString(),
    };
    this.props.toggleMyTicketsPanel(this.constructor.name, true);
    this.props.emptySlipTemplate(0, this.constructor.name);
    this.props.saveBet(
      payload,
      this.props.selectedSlipTemplate,
      this.constructor.name
    );
    if (layout === "touch") {
      this.props.navigate(`/${locale}/slip`);
    }
    return Object.keys(payload)?.length === 0 ? false : true;
  };

  handleBuilderResponse(e) {
    if (!isJson(e?.data)) return;
    const betData = e?.data ? JSON.parse(e.data) : {};
    const { url, bets } = this.props;
    const betValues = bets ? Object.values(bets) : [];
    const isBetExist = betValues.find(
      (bet) => bet.betslipUid === betData?.betslipUid
    );
    if (betData?.command === "addToBetslip" && !isBetExist) {
      if (this.props.isInGameBox) {
        this.props.navigate(url);
      } else {
        this.handleBuilderModal();
      }

      this.props.getBetBuilderSlipBet(
        betData?.betslipUid,
        this.handleAddBet,
        this.constructor.name
      );
    }
  }

  componentDidMount() {
    window.addEventListener("message", this.handleBuilderResponse.bind(this));
  }

  render() {
    log(this.constructor.name, "render");
    const {
      classes,
      consumerFixtureId,
      isInGameBox,
      widgetKey,
      locale,
      brand,
      sportcast_url,
      editable,
      siteTheme,
      game,
    } = this.props;
    return (
      <div>
        {!isInGameBox && !editable && (
          <div className={classes.root}>
            <div className={classes.contentContainer}>
              {/* <img
                className={classes.promo}
                src={`https://storage.googleapis.com/nubet-prod-content-news/nbt_banner_betbuilder_${locale}_${siteTheme}.gif`}
                alt="banner-casino-promo"
              /> */}
              <Stack width={{xs: "84%", md: "93%"}} margin="auto">
                <PrecannedBetsCarousel gameId={consumerFixtureId} leagueId={game?.leagueID} />
              </Stack>
              <div className={classes.content_body}>
              <Button
                className={classes.betbuilderButton}
                onClick={this.handleBuilderModal}
                variant="contained"
                color="primary"
                style={{ color: "var(--btn-text)" }}
              >
                <Translate value="labels.betbuilder_button" />
              </Button>
            <div className={classes.contentHeadline}>
                <h1 style={{ fontSize: "1.3em", alignContent: "center" }}>
                  <Translate value="labels.betbuilder_headline" />
                </h1>
              </div>
              <div className={classes.contentText}>
                <p>
                  <Translate value="labels.betbuilder_content" />
                </p>
              </div>
              </div>
            </div>
          </div>
        )}
        {editable && (
          <div>
            <IconButton onClick={this.handleBuilderModal} size="medium">
              <EditIcon style={{ fontSize: "var(--large)" }} />
            </IconButton>
          </div>
        )}
        <Modal
          onClose={
            isInGameBox
              ? this.props.handleBetBuilderModal
              : this.handleBuilderModal
          }
          open={
            isInGameBox ? this.props.isBuilderOpen : this.state.isBuilderOpen
          }
          aria-labelledby="nubet-bet-builder-modal"
          aria-describedby="nubet-bet-builder-widget-modal"
          className={classes.modal}
          closeAfterTransition
        >
          <div className={classes.paper}>
            <div className={classes.builder_closer}>
              <IconButton
                onClick={
                  isInGameBox
                    ? this.props.handleBetBuilderModal
                    : this.handleBuilderModal
                }
              >
                <Close />
              </IconButton>
              <Translate
                className={classes.headline}
                value={`marketSets.betbuilder`}
              />
              <div />
            </div>
            {/* <Divider /> */}
            <iframe
              id="bb_modalframe"
              src={`${sportcast_url}/markets?key=${widgetKey}&consumerfixtureid=sr:match:${consumerFixtureId}&brand=nubet-${brand}${
                locale !== "en"
                  ? `&culture=${locale}-${locale === "et" ? "EE" : "FI"}`
                  : ""
              }`}
              title="modalframe"
            ></iframe>
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let content = ownProps.content;
  const slipTemplate = state.selected.slipTemplate;
  const bets = state.slips[slipTemplate]["settings"]["bets"];
  return {
    content: content,
    consumerFixtureId: ownProps?.game?.gameid,
    brand: state.settings.theme,
    widgetKey: "4148833c-e969-4b6f-9299-b693dc74340e",
    locale: state.i18n.locale,
    game: ownProps?.game,
    selectedSlipTemplate: state.selected.slipTemplate,
    isInGameBox: ownProps.isInGameBox,
    url: state.settings.url,
    layout: state.settings.layout,
    bets,
    sportcast_url: state.settings.host.sportcast_url,
    siteTheme: state.settings.theme,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    saveBet,
    deleteBet,
    toggleMyTicketsPanel,
    getBetBuilderSlipBet,
    emptySlipTemplate,
  })(withStyles(styles)(BetbuilderWidget))
);
