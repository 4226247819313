import { log } from "../../config/log";
import React from "react";
import { connect } from "react-redux";
import withStyles from "@mui/styles/withStyles";
import { Translate } from "react-redux-i18n";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import { Close } from "@mui/icons-material";

import { setTermsStatus, signIn } from "../../actions";
// import { isNull } from 'lodash';

const styles = (theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    zIndex: 100,
    border: "none",
    outline: "none",
    boxShadow: theme.shadows[5],
    padding: "5px 15px",
    width: "500px",
    "@media only screen and (max-width: 603px)": {
      width: "90% !important",
    },
  },
  headline: {
    color: "var(--color-contrast)",
    fontWeight: "800",
    fontSize: "1.5em",
    textAlign: "center",
  },
  close_btn_container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
  },
  content: {
    color: "var(--yellow-1)",
    textAlign: "center",
  },
  btns_container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    padding: "20px 0",
  },
  url_list_container: {
    marginTop: "10px",
  },
  url_list: {
    listStyle: "none",
    padding: 0,
  },
  url_list_item: {
    fontSize: "14px",
    margin: 5,
    width: "max-content",
  },
});

class TermsAndConditionsManager extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  handleClose = () => {
    this.props.setTermsStatus(false);
  };

  handleAccept = () => {
    const { formValues } = this.props;
    if (formValues && formValues?.userPass && formValues?.email) {
      this.props.setTermsStatus(false);
      this.props.signIn(
        formValues?.email,
        formValues?.userPass,
        this.constructor.name,
        true
      );
    }
  };

  render() {
    log(this.constructor.name, "render");
    const { classes, modalOpen, locale } = this.props;

    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={modalOpen}
        onClose={this.handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modalOpen}>
          <div className={classes.paper}>
            <div className={classes.close_btn_container}>
              <IconButton onClick={this.handleClose}>
                <Close />
              </IconButton>
            </div>
            <div>
              <h2 className={classes.headline}>
                <Translate value="legal.terms_conditions_link" />
              </h2>
              {locale == "en" ? (
                <p className={classes.content}>
                  Nubet General Terms & Conditions have been updated. By
                  clicking <strong>"Accept"</strong> you will accept the new
                  versions of the T&C and/or other related documents. Accepting
                  new document versions is mandatory in order to use the
                  services.
                </p>
              ) : locale === "fi" ? (
                <p className={classes.content}>
                  Nubetin yleiset käyttöehdot on päivitetty. Klikkaamalla
                  <strong>"Hyväksy"</strong> hyväksyt uudet versiot
                  käyttöehdoista ja/tai muista niihin liittyvistä asiakirjoista.
                  Uusien versioiden hyväksyminen on pakollista palvelujen
                  käyttämiseksi.
                </p>
              ) : (
                <p className={classes.content}>
                  Nubeti üldtingimused on uuendatud. Vajutades{" "}
                  <strong>"Nõustun"</strong>
                  nõustute uute T&C ja/või muude seotud dokumentide
                  versioonidega. Uue dokumendiversiooni aktsepteerimine on
                  teenuste kasutamiseks kohustuslik.
                </p>
              )}
            </div>
            <div className={classes.url_list_container}>
              <ul className={classes.url_list}>
                <li>
                  <a href={`/${locale}/info/terms-conditions`} style={{textDecoration: "underline"}}>
                    <h4 className={classes.url_list_item}>
                      <Translate value="legal.tAndc_click" />
                    </h4>
                  </a>
                </li>
                <li>
                  <a href={`/${locale}/info/betting-rules`} style={{textDecoration: "underline"}}>
                    <h4 className={classes.url_list_item}>
                      <Translate value="legal.rules_click" />
                    </h4>
                  </a>
                </li>
              </ul>
            </div>
            <div className={classes.btns_container}>
              <Button onClick={this.handleClose}>
                <Translate value="labels.button_decline" />
              </Button>
              <Button
                onClick={this.handleAccept}
                variant="contained"
                color="primary"
              >
                <Translate value="labels.button_accept" />
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.user.auth,
  translate: state.i18n.translations[state.i18n.locale],
  locale: state.i18n.locale,
  modalOpen: state.settings.shouldConfirmTerms,
  formValues: state.form?.Login?.values,
});

export default connect(mapStateToProps, {
  setTermsStatus,
  signIn,
})(withStyles(styles)(TermsAndConditionsManager));
