/**

 */

import { log } from "../../../config/log";
import React from "react";
import { connect } from "react-redux";

import "./GameBox.css";
import { format } from "date-fns";
import GameBoxDetailedAvatarShort from "../GameBoxDetailedAvatarShortBB";
import GameBoxDetailedAvatarLong from "../GameBoxDetailedAvatarLongBB";
import GameBoxDetailedAvatarBX from "../GameBoxDetailedAvatarBX";
import GameBoxDetailedAvatarBB from "../GameBoxDetailedAvatarBB";
import GameBoxDetailedAvatarSN from "../GameBoxDetailedAvatarSN";
import GameBoxDetailedAvatarFB from "../GameBoxDetailedAvatarFBBB";
import GameBoxDetailedAvatarTE from "../GameBoxDetailedAvatarTEBB";
import GameBoxDetailedAvatarNo from "../GameBoxDetailedAvatarNoBB";
import withRouter from "../../../helpers/withRouter";

class BetBuilderGameBox extends React.Component {
  constructor(props) {
    super(props);
  }

  handleBetBuilderModal = () => {
    const { navigate, game, locale } = this.props;
    navigate(`/${locale}/betbuilder/${game.gameid}?league=${game.leagueID}&sport=${game.sport_code}`);
  };

  render() {
    log(this.constructor.name, "render");
    const { game } = this.props;

    const createJersey = (teamColor, teamHorStripes, teamNumber, teamSleeve, teamSplit, teamSquares, teamStripes, teamSleeveDetail, sportCode, isTeam1) => {
      const defaultColor = isTeam1 ? "bdbdbd" : "636363";
      const defaultColorTe = isTeam1 ? "afcff1" : "3da3fd";
      const defaultNumberColor = isTeam1 ? "f5f5f5" : "030303";
      const teamSleeveDetailColor = teamSleeveDetail != "" ? teamSleeveDetail : teamSleeve;
      const teamSplitColor = teamSplit != "false" ? teamSleeveDetailColor : "ffffff00";
      const teamHorStripesColor = teamHorStripes != "false" ? teamSleeveDetailColor : "ffffff00";
      const teamStripesColor = teamStripes != "false" ? teamSleeveDetailColor : "ffffff00";
      const teamSquaresColor = teamSquares != "false" ? teamSleeveDetailColor : "ffffff00";
      const teamSleeveColor = teamSleeve != "" ? teamSleeve : "ffffff00";

      return (
        <div className="featured-game-team">
          {game?.sport_code === "FB" && (
            <GameBoxDetailedAvatarFB
              pattern="1"
              color1={`#${teamColor || defaultColor}`}
              color2={`#${teamNumber || defaultNumberColor}`}
              color3={`#${teamHorStripesColor}`}
              color4={`#${teamSleeveColor}`}
              color5={`#${teamSplitColor}`}
              color6={`#${teamSquaresColor}`}
              color7={`#${teamStripesColor}`}
            />
          )}
          {game?.sport_code === "BA" && <GameBoxDetailedAvatarNo pattern="1" color1={`#${teamColor || defaultColor}`} color2={`#${teamNumber || defaultNumberColor}`} />}
          {(game?.sport_code === "AF" || game?.sport_code === "FL" || game?.sport_code === "VB" || game?.sport_code === "HB") && (
            <GameBoxDetailedAvatarShort pattern="1" color1={`#${teamColor || defaultColor}`} color2={`#${teamNumber || defaultNumberColor}`} />
          )}
          {(game?.sport_code === "IH" || game?.sport_code === "BD") && <GameBoxDetailedAvatarLong pattern="1" color1={`#${teamColor || defaultColor}`} color2={`#${teamNumber || defaultNumberColor}`} />}
          {game?.sport_code === "DA" && <GameBoxDetailedAvatarTE pattern="1" color1={`#${teamColor || defaultColor}`} color2={`#${teamNumber || defaultNumberColor}`} />}
          {game?.sport_code === "TE" && <GameBoxDetailedAvatarTE pattern="1" color1={`#${teamColor || defaultColorTe}`} color2={`#${teamNumber || defaultNumberColor}`} />}
          {(game?.sport_code === "BX" || game?.sport_code === "MM") && <GameBoxDetailedAvatarBX pattern="1" color1={`#${teamColor || defaultColor}`} color2={`#${teamNumber || defaultNumberColor}`} />}
          {game?.sport_code === "SN" && <GameBoxDetailedAvatarSN pattern="1" color1={`#${teamColor || defaultColor}`} color2={`#${teamNumber || defaultNumberColor}`} />}
          {game?.sport_code === "BB" && <GameBoxDetailedAvatarBB pattern="1" color1={`#${teamColor || defaultColor}`} color2={`#${teamNumber || defaultNumberColor}`} />}
          {!game?.sport_code && <GameBoxDetailedAvatarLong pattern="1" color1={`#${teamColor || defaultColor}`} color2={`#${teamNumber || defaultNumberColor}`} />}
        </div>
      );
    };

    return (
      <div className="betbuilder-gamebox-container" onClick={this.handleBetBuilderModal}>
        <div className="text-align-center" style={{ color: "var(--color-time)" }}>
          {format(new Date(game.startTime), "dd.MM. HH:mm")}
        </div>
        <div className="betbuilder-gamebox-games">
          <div className="">
            {createJersey(
              game?.team1_color,
              game?.team1_horizontal_stripes,
              game?.team1_number,
              game?.team1_sleeve,
              game?.team1_split,
              game?.team1_squares,
              game?.team1_stripes,
              game?.team1_sleeve_detail,
              game?.sport_code,
              true
            )}
            <div className="color-team text-last-center betbuilder-team">{game.team1}</div>
          </div>
          <p className="vs text-last-center">vs</p>
          <div>
            {createJersey(
              game?.team2_color,
              game?.team2_horizontal_stripes,
              game?.team2_number,
              game?.team2_sleeve,
              game?.team2_split,
              game?.team2_squares,
              game?.team2_stripes,
              game?.team2_sleeve_detail,
              game?.sport_code,
              false
            )}
            <div className="color-team text-last-center betbuilder-team">{game.team2}</div>
          </div>
        </div>
        {/* <p className="text-align-center" style={{ color: "var(--color-time)" }}>
          {format(new Date(game.startTime), "dd.MM. HH:mm")}
        </p> */}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    game: ownProps.game,
    locale: state.i18n.locale,
  };
};

export default withRouter(connect(mapStateToProps, {})(BetBuilderGameBox));
