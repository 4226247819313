// MUI TABS component overrides
import { tabClasses } from "@mui/material/Tab";

export function tabs(theme) {
  return {
    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: 0,
          minWidth: "100%",
        },
        scrollButtons: {
          width: 48,
          borderRadius: "50%",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: ({ ownerState }) => {
          return {
            ...(ownerState.orientation === "vertical" && {
              [`&.${tabClasses.root}`]: {
                textTransform: "none !important",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
                minHeight: 35,
                fontSize: "var(--normal)"
              },
            }),
            ...(ownerState.orientation === "horizontal" && {
              [`&.${tabClasses.root}`]: {
                textTransform: "uppercase !important",
                minHeight: 35,
                fontSize: "1.1em",
                opacity: 1,
                fontWeight: 500,
                padding: 0,
              },
            }),
            [`&.${tabClasses.selected}`]: {
              fontWeight: "bold",
              color: theme.palette.primary.main,
            },
          };
        },
      },
    },
  };
}
