/**
 * SVG component for own icon 
 */

import React from 'react';
import './IconOwn.css';

const IconChecked = (props) => {

	return (
		<div style={{ display: "flex" }}>
			<svg className="icon-own" viewBox="0 0 2000 2000">
				<linearGradient gradientTransform="rotate(90)" id="icongradient">
					<stop className="icon-color-1" offset="0%" />
					<stop className="icon-color-2" offset="50%" />
					<stop className="icon-color-1" offset="100%" />
				</linearGradient>
				<g>
					<path fill="url(#icongradient)" d="M1692.4,161.7H307.6c-80.6,0-145.9,65.3-145.9,145.9v1384.8c0,80.6,65.3,145.9,145.9,145.9h1384.8
	c80.6,0,145.9-65.3,145.9-145.9V307.6C1838.3,227,1773,161.7,1692.4,161.7z M879.2,1433.8L431.9,986.6L590,828.5l289.1,289.1
	l551.4-551.4l158.1,158.1L879.2,1433.8z" />
				</g>
			</svg>
		</div>
	);
};

export default IconChecked;
