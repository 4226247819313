/**
 * SVG component for own icon 
 */

import React from 'react';
import './IconOwn.css';

const IconCR = (props) => {

	return (
		<div style={{ display: "flex" }}>
			<svg className="icon-own" viewBox="0 0 2000 2000">
				<linearGradient gradientTransform="rotate(90)" id="icongradient">
					<stop className="icon-color-1" offset="0%" />
					<stop className="icon-color-2" offset="50%" />
					<stop className="icon-color-1" offset="100%" />
				</linearGradient>
				<g><path fill="url(#icongradient)" d="M999.9,1526.9c-96.8,0-175.3,78.5-175.3,175.3c0,96.8,78.5,175.3,175.3,175.3c96.8,0,175.3-78.5,175.3-175.3
		C1175.2,1605.4,1096.7,1526.9,999.9,1526.9z M964.1,1556.3c5.6,0,10.1,4.5,10.1,10.1c0,5.6-4.5,10.1-10.1,10.1
		c-5.6,0-10.1-4.5-10.1-10.1C954,1560.8,958.5,1556.3,964.1,1556.3z M964.1,1600.9c5.9,0,10.7,4.8,10.7,10.7s-4.8,10.7-10.7,10.7
		c-5.9,0-10.7-4.8-10.7-10.7S958.1,1600.9,964.1,1600.9z M964.1,1645.9c6.1,0,11,4.9,11,11c0,6.1-4.9,11-11,11c-6.1,0-11-4.9-11-11
		C953,1650.8,957.9,1645.9,964.1,1645.9z M964.1,1848.2c-5.6,0-10.1-4.5-10.1-10.1c0-5.6,4.5-10.1,10.1-10.1
		c5.6,0,10.1,4.5,10.1,10.1C974.1,1843.7,969.6,1848.2,964.1,1848.2z M964.1,1803.5c-5.9,0-10.7-4.8-10.7-10.7s4.8-10.7,10.7-10.7
		c5.9,0,10.7,4.8,10.7,10.7S970,1803.5,964.1,1803.5z M964.1,1758.6c-6.1,0-11-4.9-11-11c0-6.1,4.9-11,11-11c6.1,0,11,4.9,11,11
		C975.1,1753.6,970.1,1758.6,964.1,1758.6z M964.1,1713.9c-6.5,0-11.8-5.3-11.8-11.7s5.3-11.7,11.8-11.7c6.5,0,11.7,5.3,11.7,11.7
		S970.5,1713.9,964.1,1713.9z M1035.7,1556.3c5.5,0,10.1,4.5,10.1,10.1c0,5.6-4.5,10.1-10.1,10.1c-5.6,0-10.1-4.5-10.1-10.1
		C1025.6,1560.8,1030.2,1556.3,1035.7,1556.3z M1035.7,1600.9c5.9,0,10.7,4.8,10.7,10.7s-4.8,10.7-10.7,10.7
		c-5.9,0-10.7-4.8-10.7-10.7S1029.8,1600.9,1035.7,1600.9z M1035.7,1645.9c6.1,0,11,4.9,11,11c0,6.1-5,11-11,11c-6.1,0-11-4.9-11-11
		C1024.7,1650.8,1029.6,1645.9,1035.7,1645.9z M1035.7,1848.2c-5.6,0-10.1-4.5-10.1-10.1c0-5.6,4.5-10.1,10.1-10.1
		c5.5,0,10.1,4.5,10.1,10.1C1045.8,1843.7,1041.3,1848.2,1035.7,1848.2z M1035.7,1803.5c-5.9,0-10.7-4.8-10.7-10.7
		s4.8-10.7,10.7-10.7c5.9,0,10.7,4.8,10.7,10.7S1041.6,1803.5,1035.7,1803.5z M1035.7,1758.6c-6.1,0-11-4.9-11-11c0-6.1,5-11,11-11
		c6.1,0,11,4.9,11,11C1046.8,1753.6,1041.8,1758.6,1035.7,1758.6z M1035.7,1713.9c-6.5,0-11.7-5.3-11.7-11.7s5.3-11.7,11.7-11.7
		c6.5,0,11.7,5.3,11.7,11.7S1042.2,1713.9,1035.7,1713.9z"/>
					<path fill="url(#icongradient)" d="M1807.5,438.7c13.5-13.5,15.6-34.9,4.4-50.3c-34-46.7-74-93.8-119.5-139.3c-45.5-45.5-92.6-85.5-139.3-119.5
		c-15.4-11.2-36.8-9.1-50.3,4.4L1016,620.8l304.7,304.7L1807.5,438.7z"/>
					<path fill="url(#icongradient)" d="M193.9,1646c-17.2,16.7-17.3,44.3-0.4,61.3l40.8,40.8c17,17,44.5,16.8,61.3-0.4l385.1-395l-91.8-91.8
		L193.9,1646z"/>
					<path fill="url(#icongradient)" d="M857.3,1388.9l126.6-126.6L849.6,1128l-122.5,141.3l-54.8-54.8L813.6,1092L679.3,957.6l-126.6,126.6
		c-14.2,14.3-15,36.7-2.5,52.5c25.5,32.3,43.1,68.7,53.5,106.7l94.5,94.5c38,10.3,74.4,28,106.7,53.5
		C820.6,1403.9,843.1,1403.2,857.3,1388.9z"/>
					<path fill="url(#icongradient)" d="M1449.8,1136.8c12.5-15.8,11.8-38.3-2.5-52.5L497.2,134.1c-13.5-13.5-34.9-15.6-50.3-4.4
		c-46.8,33.9-93.8,74-139.3,119.5c-45.5,45.5-85.5,92.6-119.4,139.3c-11.2,15.4-9.1,36.8,4.4,50.3l950.2,950.2
		c14.2,14.3,36.7,15,52.5,2.5c32.3-25.5,68.7-43.1,106.7-53.5l94.5-94.5C1406.7,1205.5,1424.3,1169.1,1449.8,1136.8z M1272.9,1269.3
		l-222.4-256.6c-5-5.8-4.7-14.5,0.7-20c5.4-5.4,14.2-5.7,20-0.7l256.6,222.4L1272.9,1269.3z"/>
					<path fill="url(#icongradient)" d="M1411.1,1260.9l-91.8,91.8l385.1,395c16.8,17.2,44.3,17.4,61.3,0.4l40.8-40.8c17-17,16.8-44.5-0.4-61.3
		L1411.1,1260.9z"/></g>
			</svg>
		</div>
	);
};

export default IconCR;
