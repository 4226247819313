/**
 * SVG component for own icon 
 */

import React from 'react';
import './IconOwn.css';

const IconGH = (props) => {

	return (
		<div style={{ display: "flex" }}>
			<svg className="icon-own" viewBox="0 0 2000 2000">
				<linearGradient gradientTransform="rotate(90)" id="icongradient">
					<stop className="icon-color-1" offset="0%" />
					<stop className="icon-color-2" offset="50%" />
					<stop className="icon-color-1" offset="100%" />
				</linearGradient>
				<g>
					<path fill="url(#icongradient)" d="M1743.2,1767.8c0.1-0.1,0.2-0.2,0.2-0.3c17.4-19.7-20.4-40.2-52-45.9c0,0-0.1,0-0.1,0
		c-0.5-0.1-0.9-0.2-1.4-0.2c-0.1,0-0.3,0-0.4-0.1c-0.4-0.1-0.7-0.1-1.1-0.2c-0.2,0-0.5-0.1-0.7-0.1c-0.3,0-0.6-0.1-0.9-0.1
		c-0.3,0-0.6-0.1-0.9-0.1c-0.2,0-0.4-0.1-0.6-0.1c-0.5-0.1-1-0.1-1.6-0.2c-34.1-3.5-54.5-3-71.2-26.5c-19.2-27-46-64.9-78.4-110.5
		c-5.4-7.6-10.9-15.3-16.5-23.2l0,0c-32.6-46-70-98.6-110.1-155.2c-104.4-147.2-228-321.4-337.3-475.5
		c125.6-169,223.5-300.9,237.4-320.5c41.4-58.4,285.2-110.5,320.4-184c0.5-1.1,1-2.1,1.5-3.2c0.2-0.4,0.4-0.8,0.5-1.3
		c0.3-0.7,0.6-1.3,0.8-2c0.2-0.5,0.4-1.1,0.7-1.6c0.2-0.5,0.4-1,0.6-1.6c0.3-0.7,0.5-1.3,0.7-2c0.1-0.4,0.3-0.7,0.4-1.1
		c0.3-0.8,0.6-1.6,0.8-2.4c0-0.1,0-0.2,0.1-0.2c18.4-55.5,3-129.3-52-194.4c0,0-0.1-0.1-0.1-0.1c-4-4.7-8.2-9.4-12.6-14
		c-0.3-0.3-0.5-0.5-0.8-0.8c-0.8-0.8-1.6-1.7-2.5-2.5c-0.5-0.5-0.9-1-1.4-1.4c-0.8-0.8-1.5-1.5-2.3-2.3c-0.6-0.6-1.1-1.1-1.7-1.7
		c-0.7-0.7-1.5-1.4-2.2-2.1c-0.6-0.6-1.3-1.2-1.9-1.8c-0.7-0.7-1.4-1.3-2.1-2c-0.8-0.7-1.5-1.4-2.3-2.1c-0.6-0.6-1.3-1.1-1.9-1.7
		c-0.3-0.3-0.6-0.5-0.9-0.8c-0.2-0.1-0.3-0.3-0.5-0.4c-0.9-0.8-1.8-1.6-2.8-2.4c-0.4-0.4-0.9-0.7-1.3-1.1c-0.5-0.4-0.9-0.8-1.4-1.2
		c-0.2-0.2-0.5-0.4-0.7-0.6c-0.4-0.3-0.7-0.6-1.1-0.9c-0.6-0.5-1.2-1-1.8-1.5c-0.9-0.8-1.9-1.5-2.8-2.3c-0.6-0.5-1.3-1-1.9-1.5
		c-0.9-0.7-1.9-1.5-2.8-2.2c-0.6-0.5-1.3-1-1.9-1.5c-0.1-0.1-0.1-0.1-0.2-0.2c-0.2-0.2-0.4-0.3-0.6-0.5c-0.7-0.5-1.4-1.1-2.1-1.6
		c-0.6-0.5-1.2-0.9-1.9-1.4c-0.7-0.5-1.4-1.1-2.1-1.6c-0.5-0.3-0.9-0.7-1.4-1c-0.5-0.3-0.9-0.7-1.4-1c-1.7-1.2-3.3-2.4-5-3.6
		c-3.6-2.5-7.2-4.9-10.9-7.1c-36.5-22.4-74.5-31.4-110.6-31.3c-75.8,0.2-142.7,40.4-167.2,80.6c-0.4,0.6-0.7,1.2-1,1.8
		c-0.1,0.2-0.2,0.3-0.2,0.5c-0.1,0.1-0.1,0.2-0.2,0.4c-0.2,0.4-0.4,0.7-0.6,1.1c-0.1,0.1-0.1,0.3-0.2,0.4c-0.1,0.2-0.2,0.4-0.3,0.7
		c-0.1,0.1-0.1,0.2-0.2,0.4c-0.4,1-0.9,2-1.3,3c0,0,0,0.1-0.1,0.1c-0.2,0.6-0.4,1.2-0.6,1.8c0,0.1-0.1,0.2-0.1,0.3
		c-0.3,0.9-0.6,1.7-0.8,2.6c0,0,0,0.1,0,0.1c-0.5,1.9-1,3.8-1.4,5.8c0,0,0,0,0,0c-2.9,15.1-1.7,33.6,1.1,54.2c0,0.1,0,0.1,0,0.2
		c0.4,2.5,0.7,5.1,1.1,7.7c0.1,0.4,0.1,0.8,0.2,1.1c0.2,1,0.3,2.1,0.5,3.1c0.1,0.7,0.2,1.3,0.3,2c0.1,0.4,0.1,0.7,0.2,1.1
		c1.5,9.3,3.3,19,5,28.9c0,0,0,0,0,0c2.5,14.2,5.1,28.9,7.2,44.1c-0.2-1.6-0.5-3.1-0.8-4.7c3.4,23.6,5.9,48.1,5.2,72.7
		c0-0.2-0.1-0.5-0.1-0.7c-0.9,35.8-8.2,71.7-28.5,104.9c-0.1,0.2-0.2,0.4-0.4,0.6c-0.5,0.9-1.1,1.7-1.6,2.6
		c-0.1,0.2-0.2,0.4-0.4,0.6c-0.1,0.1-0.2,0.3-0.3,0.4c-0.5,0.8-1,1.5-1.5,2.3c-0.1,0.1-0.2,0.2-0.2,0.3c-0.1,0.2-0.3,0.4-0.4,0.6
		c-0.7,1-1.4,2.1-2.2,3.1c-25.2,35.5-104.3,147.1-204.8,288.8C899.5,688.9,820.3,577.3,795.2,541.8c-0.7-1-1.5-2.1-2.2-3.1
		c-0.1-0.2-0.3-0.4-0.4-0.6c-0.1-0.1-0.2-0.2-0.2-0.3c-0.5-0.8-1-1.5-1.5-2.3c-0.1-0.1-0.2-0.3-0.3-0.4c-0.1-0.2-0.2-0.4-0.4-0.6
		c-0.5-0.9-1.1-1.7-1.6-2.6c-0.1-0.2-0.2-0.4-0.4-0.6c-20.3-33.2-27.6-69.1-28.5-104.9c0,0.2-0.1,0.5-0.1,0.7
		c-0.7-24.6,1.8-49.1,5.2-72.7c-0.3,1.5-0.5,3.1-0.8,4.7c2.1-15.1,4.7-29.9,7.2-44.1c0,0,0,0,0,0c1.8-10,3.5-19.6,5-28.9
		c0.1-0.4,0.1-0.7,0.2-1.1c0.1-0.7,0.2-1.3,0.3-2c0.2-1,0.3-2.1,0.5-3.1c0.1-0.4,0.1-0.8,0.2-1.1c0.4-2.6,0.8-5.1,1.1-7.7
		c0-0.1,0-0.1,0-0.2c2.9-20.7,4-39.1,1.1-54.2c0,0,0,0,0,0c-0.4-2-0.8-3.9-1.4-5.8c0,0,0-0.1,0-0.1c-0.3-0.9-0.5-1.8-0.8-2.6
		c0-0.1-0.1-0.2-0.1-0.3c-0.2-0.6-0.4-1.2-0.6-1.8c0,0,0-0.1-0.1-0.1c-0.4-1-0.8-2-1.3-3c-0.1-0.1-0.1-0.2-0.2-0.4
		c-0.1-0.2-0.2-0.4-0.3-0.7c-0.1-0.1-0.1-0.3-0.2-0.4c-0.2-0.4-0.4-0.8-0.6-1.1c-0.1-0.1-0.1-0.2-0.2-0.4c-0.1-0.2-0.2-0.3-0.2-0.5
		c-0.3-0.6-0.7-1.2-1-1.8c-24.5-40.2-91.4-80.5-167.2-80.6c-36.1-0.1-74.2,8.9-110.6,31.3c-3.6,2.2-7.3,4.6-10.9,7.1
		c-1.7,1.2-3.4,2.4-5,3.6c-0.5,0.3-0.9,0.7-1.4,1c-0.5,0.3-0.9,0.7-1.4,1c-0.7,0.5-1.4,1.1-2.1,1.6c-0.6,0.5-1.2,0.9-1.8,1.4
		c-0.7,0.5-1.4,1.1-2.1,1.6c-0.2,0.2-0.4,0.3-0.6,0.5c-0.1,0.1-0.2,0.1-0.2,0.2c-0.6,0.5-1.3,1-1.9,1.5c-1,0.8-1.9,1.5-2.9,2.3
		c-0.6,0.5-1.2,1-1.8,1.5c-1,0.8-1.9,1.6-2.9,2.4c-0.6,0.5-1.1,0.9-1.7,1.4c-0.4,0.3-0.7,0.6-1.1,0.9c-0.2,0.2-0.5,0.4-0.7,0.6
		c-0.5,0.4-1,0.9-1.6,1.3c-0.4,0.3-0.7,0.6-1.1,0.9c-0.9,0.8-1.9,1.6-2.8,2.4c-0.2,0.1-0.3,0.3-0.5,0.4c-0.3,0.3-0.6,0.5-0.9,0.8
		c-0.6,0.5-1.2,1.1-1.7,1.6c-0.8,0.7-1.6,1.5-2.4,2.2c-0.7,0.6-1.3,1.2-2,1.9c-0.7,0.7-1.4,1.3-2.1,2c-0.7,0.7-1.4,1.3-2.1,2
		c-0.6,0.6-1.2,1.2-1.8,1.8c-0.7,0.7-1.5,1.4-2.2,2.2c-0.5,0.5-1,1.1-1.6,1.6c-0.8,0.8-1.6,1.6-2.3,2.4c-0.3,0.4-0.7,0.7-1,1.1
		c-4.3,4.5-8.4,9.1-12.3,13.7c-0.1,0.1-0.2,0.2-0.3,0.3c-1,1.2-2,2.4-3,3.6c0,0-0.1,0.1-0.1,0.1c-52.4,64.1-66.9,136.2-48.9,190.6
		c0,0.1,0.1,0.2,0.1,0.2c0.3,0.8,0.6,1.6,0.8,2.4c0.1,0.4,0.3,0.7,0.4,1.1c0.2,0.7,0.5,1.3,0.7,2c0.2,0.5,0.4,1,0.6,1.6
		c0.2,0.5,0.4,1.1,0.7,1.6c0.3,0.7,0.6,1.3,0.8,2c0.2,0.4,0.4,0.8,0.5,1.3c0.5,1.1,1,2.2,1.5,3.2c35.3,73.6,279,125.6,320.4,184
		c13.9,19.6,111.8,151.5,237.4,320.5c-2.1,3-4.2,6-6.4,9c-10.7,15.1-21.5,30.3-32.4,45.7c-0.5,0.7-1,1.4-1.5,2.2l0,0
		c-100.1,141.1-208.2,293.6-300.9,424.2c-38.6,54.5-74.6,105.1-106.1,149.6l0,0c-5.7,8-11.2,15.8-16.6,23.5
		c-32.3,45.5-59.1,83.3-78.2,110.3c-16.7,23.6-37.1,23-71.2,26.5c-0.5,0.1-1,0.1-1.6,0.2c-0.2,0-0.4,0.1-0.6,0.1
		c-0.3,0-0.6,0.1-0.9,0.1c-0.3,0-0.6,0.1-0.9,0.1c-0.2,0-0.5,0.1-0.7,0.1c-0.4,0.1-0.7,0.1-1.1,0.2c-0.1,0-0.3,0-0.4,0.1
		c-0.5,0.1-0.9,0.2-1.4,0.2c0,0-0.1,0-0.1,0c-31.6,5.7-69.5,26.1-52,45.9c0.1,0.1,0.1,0.2,0.2,0.2c0.2,0.2,0.4,0.4,0.6,0.7
		c0.2,0.2,0.3,0.3,0.5,0.5c0.2,0.1,0.3,0.3,0.5,0.4c0.3,0.3,0.7,0.6,1.1,1c0.4,0.4,0.9,0.7,1.3,1.1c0.1,0,0.1,0.1,0.2,0.1
		c0.3,0.3,0.7,0.6,1.1,0.9c27.3,22.9,88.2,66.9,120.9,69.2c0.4,0,0.7,0,1.1,0c11,0.2,27.1-8,40.5-18.1c0.9-0.7,1.8-1.4,2.7-2.1
		c14.3-11.4,16.3-47.7,27.6-63.6c13.2-18,32-43.3,55-74.3l0.4,0.3l14.6-20.6c0.2-0.3,0.4-0.6,0.6-0.8l0,0c1.6-2.1,3.2-4.3,4.8-6.4
		c9.5-12.8,19.6-26.4,30.1-40.5l0,0c77.6-104.5,181.8-244.7,287.9-387.3c37.9-50.9,76-102.2,113.2-152.2
		c5.4-7.3,10.8-14.6,16.2-21.8c7.5-10.1,15-20.2,22.4-30.2c48.3,64.9,98.9,132.9,149.1,200.4c107,143.9,212.4,285.6,290.7,391l0,0
		c8,10.8,15.9,21.4,23.4,31.6c3.9,5.2,7.7,10.4,11.4,15.4l0,0c0.2,0.3,0.4,0.6,0.6,0.8l14.6,20.6l0.4-0.3c23,31,41.7,56.4,55,74.3
		c11.3,15.9,13.3,52.2,27.6,63.6c0.9,0.7,1.8,1.4,2.7,2.1c13.4,10.1,29.5,18.3,40.5,18.1c0.4,0,0.8,0,1.1,0
		c32.2-2.3,91.7-44.9,119.6-68.1c0.8-0.7,1.6-1.3,2.4-2c0.1-0.1,0.2-0.1,0.2-0.2c0.4-0.4,0.9-0.7,1.3-1.1c0.4-0.3,0.7-0.6,1.1-1
		c0.2-0.1,0.3-0.3,0.4-0.4c0.2-0.2,0.4-0.3,0.5-0.5C1742.8,1768.3,1743,1768,1743.2,1767.8z"/>
					<path d="M1055.5,1570.1c-8.6-3.2-17.6-5.6-26.9-7.3c-2.6-0.5-5.3-0.9-8-1.2c-6.7-0.9-13.6-1.3-20.5-1.3
		c-1.3,0-2.6,0-3.9,0c-0.4,0-0.9,0-1.3,0c-0.9,0-1.7,0.1-2.6,0.1c-0.5,0-1,0.1-1.5,0.1c-0.8,0-1.6,0.1-2.3,0.1
		c-0.5,0-1.1,0.1-1.6,0.1c-0.7,0.1-1.5,0.1-2.2,0.2c-0.6,0.1-1.1,0.1-1.7,0.2c-0.7,0.1-1.5,0.2-2.2,0.2c-0.6,0.1-1.1,0.1-1.7,0.2
		c-0.7,0.1-1.4,0.2-2.2,0.3c-0.5,0.1-1.1,0.2-1.6,0.2c-0.9,0.1-1.7,0.3-2.6,0.4c-1,0.2-2,0.4-3,0.5c-0.5,0.1-1.1,0.2-1.6,0.3
		c-0.7,0.1-1.5,0.3-2.2,0.5c-0.5,0.1-0.9,0.2-1.4,0.3c-0.8,0.2-1.5,0.4-2.3,0.5c-0.4,0.1-0.8,0.2-1.2,0.3c-0.8,0.2-1.6,0.4-2.4,0.6
		c-0.3,0.1-0.6,0.2-1,0.3c-0.9,0.2-1.8,0.5-2.7,0.7c-0.2,0-0.3,0.1-0.5,0.1c-62.4,18.4-109.1,73.5-115,140.3c0,0,0,0.1,0,0.1
		c-0.1,1.4-0.2,2.8-0.3,4.2c0,0.3,0,0.6-0.1,0.9c0,0.7-0.1,1.3-0.1,2c0,0.5,0,0.9-0.1,1.4c0,0.1,0,0.2,0,0.3c0,0.4,0,0.7,0,1.1
		c0,0.3,0,0.5,0,0.8c0,1.3,0,2.5,0,3.8c0,0,0,0,0,0c0,0,0,0,0,0c0,69.6,44.1,128.9,105.8,151.5c8.6,3.2,17.6,5.6,26.9,7.3
		c2.6,0.5,5.3,0.9,8,1.2c6.7,0.9,13.6,1.3,20.5,1.3c1.3,0,2.5,0,3.8,0c0.4,0,0.9,0,1.3,0c0.1,0,0.2,0,0.3,0c0.2,0,0.5,0,0.7,0
		c0.5,0,0.9,0,1.4-0.1c0.5,0,1.1-0.1,1.6-0.1c0.7,0,1.4-0.1,2.1-0.1c0.1,0,0.1,0,0.2,0c0.1,0,0.3,0,0.4,0c0.4,0,0.8-0.1,1.2-0.1
		c0.5,0,1-0.1,1.5-0.1c80.4-7.2,143.9-73.4,146.6-155.1c0-0.1,0-0.3,0-0.4c0.1-1.7,0.1-3.4,0.1-5.2c0,0,0,0,0,0c0,0,0,0,0,0s0,0,0,0
		C1161.3,1652,1117.2,1592.7,1055.5,1570.1z"/>
				</g>
			</svg>
		</div>
	);
};

export default IconGH;
