import React from "react";
import { connect } from 'react-redux';
import { getGames } from '../../actions';
import Leagues from './Leagues';
import withRouter from "../../helpers/withRouter";


class StaticRoutes extends React.Component{
  
  constructor(props) {
      super(props);
  }

  componentDidMount(){
    if(!this.props.countryCode&&this.props.leagueId){
      this.props.getGames(this.props.leagueFilter, this.props.sportCode, this.props.leagues, true, this.props.locale, this.constructor.name, false,  this.props.leagueId);
    }
  }
  
  render(){
     let { leagueFilter, countryCode, sportCode, gameStaticId, leagueId, searchQuery } = this.props;
     return (<Leagues searchQuery={searchQuery} leagueFilter={leagueFilter} filterMethod={countryCode} sportCode={sportCode} gameStaticId={gameStaticId} selectedLeagueId={leagueId}/>);
  }
}

const mapStateToProps = (state, ownProps) => {
  let leagueFilter = ownProps.params.leagueFilter;
  let sportCode = ownProps.params.sportCode;
  const leagueId = ownProps ? ownProps.params.leagueId : ownProps.leagueId;
  const gameId = ownProps.params?.gameId;

  return {
    leagueId: leagueId,
    leagueFilter: leagueFilter,
    sportCode: sportCode,
    locale: state.i18n.locale,
    searchQuery: ownProps?.location?.search ? ownProps?.location?.search : "",
    gameStaticId: gameId,
    countryCode: ownProps?.params?.countryCode,
    leagues: state.selected.leagues,
  };
};

export default withRouter(connect(mapStateToProps, { getGames })(StaticRoutes));
