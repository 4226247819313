/**
 * Game information component
 * Show game score, hasStream information, game tabs
 */

import { log } from "../../config/log";
import React from "react";
import clsx from "clsx";
import { connect } from "react-redux";
import { getGameAllOdds } from "../../actions";
import withStyles from "@mui/styles/withStyles";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import GameBox from "./GameBox";
import GameTabs from "./GameTabs";
import withRouter from "../../helpers/withRouter";

const styles = (theme) => ({
  fullList: {
    width: "auto",
  },
  drawerCustomStyleDesktop: {
    "& .MuiDrawer-paperAnchorLeft": {
      top: "64px!important",
      height: "calc(100% - 64px)!important",
      "@media (hover:hover)": {
        overflowY: "auto",
        margin: 0,
        padding: 0,
        listStyle: "none",
        height: "100%",
        fontSize: "0.8em",
      },
    },
    "& .MuiBackdrop-root": {
      backgroundColor: "rgba(0, 0, 0, 0.2)",
    },
  },
  drawerCustomStyleMobile: {
    "& .MuiDrawer-paperAnchorRight": {
      left: "initial",
      right: "initial",
      height: "calc(100% - 66px)",
      fontSize: "0.8em",
      "@media (orientation: landscape)": {
        height: "calc(100% - 45px)",
      },
    },
  },
  backIcon: {
    zIndex: 1,
    paddingTop: "0",
  },
  root: {
    "& .MuiTabs-root": {
      minHeight: "1em",
    },
    "& .MuiTab-root": {
      minHeight: "1em",
    },

    "&. MuiPaper-root": {
      // '@media (hover:hover)': {
      top: "54px !important",
      // }
    },
  },
  stream: {
    color: "#ff0000",
    marginTop: "-64px",
    minHeight: "100px",
  },
  livelogo: {
    width: "70vW",
    textAlign: "right",
    "@media (hover:none)": {
      width: "90vW",
    },
  },
});

class Game extends React.Component {
  constructor(props) {
    super(props);
    const { layout } = this.props;

    props.forwardRef(() => this.openDrawer());

    this.direction = layout === "touch" ? "right" : "left";

    this.state = {
      top: false,
      left: false,
      bottom: false,
      right: false,
      value: 2,
    };
  }

  openDrawer = () => {
    this.setState({ [this.direction]: true, value: 0 });
    setTimeout(() => {
      this.setState({ [this.direction]: true, value: 0 });
    }, 10);
  };

  toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    const { url, locale } = this.props;
    const splitPath = url?.split("/");

    if (!open) {
      if (splitPath?.length === 7) {
        this.props.navigate(`/${locale}`);
      }
    }

    this.setState({ ...this.state, [anchor]: open });
  };

  list = (anchor) => {
    const {
      classes,
      game,
      content,
      layout,
      showMyTicketsPanel,
      selectedGame,
      sportCode,
    } = this.props;

    return (
      <div
        className={clsx(classes.list, {
          [classes.fullList]: anchor === "top" || anchor === "bottom",
        })}
        style={{ width: layout === "touch" ? "100vW" : "73.7vW" }}
        role="presentation"
      >
        <List
          onClick={this.toggleDrawer(anchor, false)}
          className={classes.backIcon}
        >
          <ListItem key="back">
            <ListItemIcon>
              <ArrowBackIosIcon color="primary" />
            </ListItemIcon>
          </ListItem>
        </List>
        <Divider />
        {/* <GameBox game={game} content={content} mode="detailed" isInViewport={true} /> */}

        {/* STREAM - SWITCH BETWEEN HAS STREAM REMOVE "!"  */}
        {/* {selectedGame.hasStream ? <div key='stream'><StreamWidget gameId={selectedGame.game_id_bg} /></div> : <GameBox game={game} content={content} mode="detailed" isInViewport={true} />} */}
        <GameBox
          game={game}
          content={content}
          mode="detailed"
          isInViewport={true}
        />

        <div className={classes.root}>
          {/* Uncomment below to show BR Timeline widget */}
          {/* <div className={`${(sportCode === 'FB' || sportCode === 'BA') ? '' : 'hidden'}`}>
            <Momentum gameId={game[0].gameid} />
          </div> */}
          {/* <Momentum className={`${sportCode === 'FB' ? 'hidden' : ''}`} gameId={game[0].gameid} /> */}
          <GameTabs
            game={game}
            content={content}
            isSideBetOpen={this.state[this.direction]}
          />
        </div>
      </div>
    );
  };

  render() {
    log(this.constructor.name, "render");
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Drawer
          anchor={this.direction}
          open={this.state[this.direction]}
          onClose={this.toggleDrawer(this.direction, false)}
          className={`${
            this.props.layout === "mouse"
              ? classes.drawerCustomStyleDesktop
              : classes.drawerCustomStyleMobile
          }`}
          elevation={0}
        >
          {this.list(this.direction)}
        </Drawer>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    gameId: ownProps.game[0].gameid,
    game: ownProps.game,
    sportCode: ownProps.game[0].sport_code,
    content: ownProps.content,
    layout: state.settings.layout,
    isInViewport: ownProps.isInViewport,
    showMyTicketsPanel: state.settings.showMyTicketsPanel,
    selectedGame: ownProps.game[0],
    url: state.settings.url,
    locale: state.i18n.locale,
  };
};

export default withRouter(
  connect(mapStateToProps, { getGameAllOdds })(withStyles(styles)(Game))
);
