import "./style.css";
import { log } from "../../../config/log";
import React from "react";
import { connect } from "react-redux";
import FeaturedOutrightCard from "../components/outright-card";
import { withStyles } from "@mui/styles";
import Slider from "react-slick";
import FeaturedGameCard from "../components/game-card-v1";
import { getFeaturedGames, getFeaturedOutrights } from "../../../actions";
import withRouter from "../../../helpers/withRouter";
import FeaturedSliptCard from "../components/slip-card";
import PrecannedCard from "../components/precanned-card";

const settings = {
  dots: true,
  infinite: true,
  speed: 2000,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  autoplay: true,
  pauseOnHover: true,
  autoplaySpeed: 15000,
  swipeToSlide: true,
  // fade: true,
  // vertical: true,
  lazyLoad: true,
  rtl: false,
  responsive: [
    {
      breakpoint: 600,
      settings: {
        autoplaySpeed: 5000,
        dots: false,
      },
    },
  ],
};

const styles = (theme) => ({
  root: {
    "@media (hover:hover)": {
      backgroundColor: "var(--color-main)",
    },
    // "@media (hover:none)": {
    //   height: "16em",
    // },
    "& .MuiTypography-root": {
      fontSize: "1.1em !important",
    },
    "& p": {
      margin: "1em 0 1em",
    },
    "& li": {
      margin: "0.3em 0",
    },
    "& h3": {
      textAlign: "center",
      fontSize: "1.1em !important",
    },
    "& .MuiDivider-root": {
      backgroundColor: "var(--color-contrast)",
    },
    flexGrow: 1,
    width: "100%",
    backgroundColor: "var(--color-main)",
  },
});

class FeaturedGameAndOutrightCombi extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { games, outrights } = this.props;
    if (games.length < 1) {
      this.props.getFeaturedGames(this.constructor.name);
    }
    if (outrights.length < 1) {
      this.props.getFeaturedOutrights(this.constructor.name);
    }
  }

  goToRiskitonVeto = () => {
    this.props.navigate(`/${this.props.lang}/promotions`);
  };
  goToBetbuilder = () => {
    this.props.navigate(`/${this.props.lang}?dialogue=betBuilder&selectedBetBuilderSportCode=FB`);
  };
  goToKaeteiskierrosta = () => {
    this.props.navigate(`/${this.props.lang}/info/kaeteiskierrosta`);
  };
  goToAF = () => {
    this.props.navigate(`/${this.props.lang}/leagues/all/IH?leagueId=3&gameId=43072243`);
  };
  goToBA = () => {
    this.props.navigate(`/${this.props.lang}/leagues/all/BA?leagueId=648&gameId=188857&type=outrights`);
  };
  goToTE = () => {
    this.props.navigate(`/${this.props.lang}/leagues/all/TE`);
  };
  goToCY = () => {
    this.props.navigate(`/${this.props.lang}/leagues/all/CY`);
  };
  goToTR = () => {
    this.props.navigate(`/${this.props.lang}/leagues/all/TR`);
  };
  goToOL = () => {
    this.props.navigate(`/${this.props.lang}/leagues/all/OL`);
  };

  render() {
    log(this.constructor.name, "render");
    const { classes, games, outrights, allSlips, host, locale, auth, siteTheme, isMobile, precannedGames } = this.props;
    return (
      <div className={classes.root}>
        {/* <h3 style={{ textTransform: "uppercase", margin: "0.6em" }}>
          <Translate value="labels.featured_matches" />
        </h3> */}
        <div className="featured-matches-container">
          <Slider {...settings}>
          {host.promoPage ? (
              <div style={{ height: "100%" }}>
                <img
                  key="link-promo"
                  className="promo-banner"
                  style={{ width: "100%", height: "16em" }}
                  src={`https://storage.googleapis.com/nubet-prod-content-news/banner_casino_sm_dropswins_${siteTheme}.jpg`}
                  alt="banner dropswins"
                  onClick={this.goToRiskitonVeto}
                />
              </div>
            ) : null}
            {/* {host.promoPage ? (
              <div style={{ height: "100%" }}>
                <img
                  key="link-promo"
                  className="promo-banner"
                  style={{ width: "100%", height: "16em" }}
                  src={`https://storage.googleapis.com/nubet-prod-content-news/banner_casino_sm_gemix.jpg`}
                  alt="banner gemix"
                  onClick={this.goToRiskitonVeto}
                />
              </div>
            ) : null} */}
            {/* {host.promoPage ? (
              <div style={{ height: "100%" }}>
                <img
                  key="link-promo"
                  className="promo-banner"
                  style={{ width: "100%", height: "16em" }}
                  src={`https://storage.googleapis.com/nubet-prod-content-news/nbt_banner_elitloppet_${siteTheme}.jpg`}
                  alt="banner slots"
                  onClick={this.goToTR}
                />
              </div>
            ) : null} */}
            {/* {isMobile && host.promoPage ? (
              <div style={{ height: "100%" }}>
                <img
                  key="link-promo"
                  className="promo-banner"
                  style={{ width: "100%", height: "16em" }}
                  src={`https://storage.googleapis.com/nubet-prod-content-news/nbt_banner_superbowl_all.gif`}
                  alt="banner slots"
                  onClick={this.goToAF}
                />
              </div>
            ) : null} */}
            {isMobile &&
              precannedGames?.map((precannedGame) => {
                const filteredBets = precannedGame?.bets ? precannedGame?.bets.filter((bt) => bt.sideBet === "0") : [];
                return filteredBets.map((bet) => <PrecannedCard key={bet.betslipUid} game={precannedGame} bet={bet} />);
              })}
            {isMobile &&
              allSlips?.map((slipGroup) => {
                return slipGroup?.map((slipData, indx) => <FeaturedSliptCard key={indx} data={slipData} order={indx + 1} />);
              })}
            {host.promoPage ? (
              <div style={{ height: "100%" }}>
                <img
                  key="link-promo"
                  className="promo-banner"
                  style={{ width: "100%", height: "16em" }}
                  src={`https://storage.googleapis.com/nubet-prod-content-news/nbt_banner_betbuilder_${locale}_${siteTheme}.gif`}
                  alt="banner slots"
                  onClick={this.goToBetbuilder}
                />
              </div>
            ) : null}
            {isMobile &&
              games?.map((game, indx) => {
                return game?.odds?.map((gameOdd, index) => {
                  const gameCopy = { ...game };
                  gameCopy["odds"] = [gameOdd];
                  return React.createElement(FeaturedGameCard, {
                    key: index,
                    game: gameCopy,
                  });
                });
              })}
            {host.promoPage ? (
              <div style={{ height: "100%" }}>
                <img
                  key="link-promo"
                  className="promo-banner"
                  style={{ width: "100%", height: "16em" }}
                  src={`https://storage.googleapis.com/nubet-prod-content-news/nbt_banner_comboboost_trotting_anim_${locale}_${siteTheme}.gif`}
                  alt="banner racingboost"
                  onClick={this.goToRiskitonVeto}
                />
              </div>
            ) : null}
            {/* {host.promoPage && !isMobile ? (
              <div style={{ height: "100%" }}>
                <img
                  key="link-promo"
                  className="promo-banner"
                  style={{ width: "100%", height: "16em" }}
                  src={`https://storage.googleapis.com/nubet-prod-content-news/nbt_banner_comboboost_${locale}_${siteTheme}.gif`}
                  alt="banner comboboost"
                  onClick={this.goToRiskitonVeto}
                />
              </div>
            ) : null} */}
            {isMobile && outrights?.map((game, indx) => <FeaturedOutrightCard key={indx} game={game} />)}
            {host.promoPage && !auth.isSignedIn ? (
              <div style={{ height: "100%" }}>
                <img
                  key="link-promo"
                  className="promo-banner"
                  style={{ width: "100%", height: "16em" }}
                  src={`https://storage.googleapis.com/nubet-prod-content-news/nbt_banner_cta_bookofpower_${siteTheme}.jpg`}
                  alt="banner euro 2024"
                  onClick={this.goToRiskitonVeto}
                />
              </div>
            ) : null}

            {/* {host.promoPage && !auth.isSignedIn ? (
              <div style={{ height: "100%" }}>
                <img
                  key="link-promo"
                  className="promo-banner"
                  style={{ width: "100%", height: "16em" }}
                  src={`https://storage.googleapis.com/nubet-prod-content-news/nbt_banner_cta_25_gif_478_230_${locale}_${siteTheme}.gif`}
                  alt="banner slots"
                  onClick={this.goToRiskitonVeto}
                />
              </div>
            ) : null} */}
            {/* {host.promoPage ? (
              <div style={{ height: "100%" }}>
                <img
                  key="link-promo"
                  className="promo-banner"
                  style={{ width: "100%", height: "16em" }}
                  src={`https://storage.googleapis.com/nubet-prod-content-news/nbt_banner_garros_${siteTheme}.jpg`}
                  alt="banner slots"
                  onClick={this.goToTE}
                />
              </div>
            ) : null} */}
          </Slider>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const sportCode = state.leagues._sportCode;
  const favList = state.leagues?.favorites ? Object.keys(state.leagues.favorites) : [];
  let gamesList = [];
  const gamesGroups = state.banner.games && Object.values(state.banner.games) ? Object.values(state.banner.games) : [];
  gamesGroups?.forEach((grItem) => {
    const grItemValues = Object.values(grItem);
    grItemValues?.forEach((item) => {
      gamesList.push(item);
    });
  });

  let outrightsList = [];
  const outrightsGroups = state.banner.outrights && Object.values(state.banner.outrights) ? Object.values(state.banner.outrights) : [];
  outrightsGroups?.forEach((grItem) => {
    const grItemValues = Object.values(grItem);
    grItemValues?.forEach((item) => {
      outrightsList.push(item);
    });
  });

  const filteredOutrights = outrightsList?.filter((outright) => outright?.sport_code === sportCode);
  const filteredGames = gamesList?.filter((game) => game.sport_code === sportCode);

  const filteredOutrights2 = outrightsList?.filter((outright) => outright?.sport_code === "IH" || outright?.sport_code === "FB");
  const filteredGames2 = gamesList?.filter((game) => game.sport_code === "IH" || game.sport_code === "FB");

  if (filteredGames?.length || filteredOutrights?.length) {
    outrightsList = filteredOutrights;
    gamesList = filteredGames;
  } else if (filteredGames2?.length || filteredOutrights2?.length) {
    outrightsList = filteredOutrights2;
    gamesList = filteredGames2;
  }

  const favGamesList = gamesList.filter((item) => favList.includes(item.sport_code));
  const favOutrightsList = outrightsList.filter((item) => favList.includes(item.sport_code));

  if ((sportCode === "favorites" && favGamesList?.length) || (sportCode === "favorites" && favOutrightsList?.length)) {
    gamesList = gamesList.filter((item) => favList.includes(item.sport_code));
    outrightsList = outrightsList.filter((item) => favList.includes(item.sport_code));
  }

  const slip = state.banner.slip;
  const slipKeysArray = Object.keys(slip);
  const allSlips = [];

  if (slip) {
    slipKeysArray?.forEach((arrKey) => {
      Object.values(slip[arrKey])?.forEach((slipData) => {
        if ((slipData?.games && Object.keys(slipData?.games)?.length) || (slipData?.outrights && Object.keys(slipData?.outrights)?.length)) {
          allSlips.push({ ...slipData, slipKey: arrKey });
        }
      });
    });
  }

  const slipsGroup = {};

  allSlips?.forEach((slp) => {
    if (!slipsGroup[slp.slipKey]) {
      slipsGroup[slp.slipKey] = allSlips.filter((itm) => itm.slipKey === slp.slipKey);
    }
  });

  const precannedGames = [];
  const precannedList = Object.values(state.banner.precanned);

  precannedList?.forEach((prGroup) => {
    Object.values(prGroup)?.forEach((prGame) => {
      precannedGames.push(prGame);
    });
  });

  return {
    games: gamesList,
    outrights: outrightsList,
    allSlips: Object.values(slipsGroup),
    lang: state.i18n.locale,
    isMobile: state.settings.isMobile,
    host: state.settings.host,
    locale: state.i18n.locale,
    auth: state.user.auth,
    siteTheme: state.settings.theme,
    precannedGames: precannedGames,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    getFeaturedGames,
    getFeaturedOutrights,
  })(withStyles(styles)(FeaturedGameAndOutrightCombi))
);
