/**
 * Register form
 *
 */
import { log } from "../../config/log";
import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm, getFormValues } from "redux-form";
import { withStyles } from "@mui/styles";
import ReCAPTCHA from "react-google-recaptcha";
import { Link } from "react-router-dom";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CheckIcon from "@mui/icons-material/Check";
import { Translate } from "react-redux-i18n";
import PasswordField from "../Inputs/PasswordInput";

import countryConfig from "../../config/countryConfig";
import hostConfig from "../../config/hostConfig";
import api from "../../apis/nubet";
import authHeader from "../../apis/auth-header";

const styles = (theme) => ({
  root: {
    "& .MuiContainer-root": {
      padding: theme.spacing(2),
      paddingBottom: 0,
      width: "100%",
    },
    "& .MuiFormControl-root": {
      margin: "0.3em 0",
      width: "100%",
    },
    "& .MuiTypography-body1": {
      fontSize: "1.3em",
    },
    "@media (hover:hover)": {
      "& .MuiFormControl-root": {
        margin: "1em 1em 0 0",
        width: "60% !important",
      },
      "& .MuiContainer-root": {
        padding: theme.spacing(3),
      },
      backgroundColor: "var(--color-main)",
    },
    "& .MuiFormLabel-root": {
      fontSize: "1.2em",
    },
    "& .MuiInput-root": {
      fontSize: "1.2em",
    },
  },
  hidden: {
    width: "0 !important",
    height: "0 !important",
    visibility: "hidden",
  },
  legal: {
    fontSize: "10em",
  },
  greenColor: {
    color: "var(--color-contrast)",
  },
  smallFont: {
    fontSize: "1.2em",
    "& .MuiFormControlLabel-label": {
      fontSize: "1.2em",
    },
  },
});

class UserForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      recaptchaVerified: false,
      errMsgs: [],
      step: this.props.step,
    };
  }

  componentDidMount() {
    if (typeof this.props.onRef === "function") {
      this.props.onRef(this);
    }

    this.props.initialize({
      countryCode: "EN",
      nationality: "EN",
      birthCountry: "EN",
    });
  }
  componentWillUnmount() {
    if (typeof this.props.onRef === "function") {
      this.props.onRef(undefined);
    }
  }

  /**
   * CountryCode = DE, nationality = DE and birthCountry = DE in DEFAULT for new user
   */

  shouldComponentUpdate(nextProps, nextState) {
    // var flag = false;

    if (this.state.recaptchaVerified !== nextState.recaptchaVerified) {
      // flag = true;
    }

    if (this.state.errMsgs !== nextState.errMsgs) {
      // flag = true;
    }

    if (this.props.lang !== nextProps.lang) {
      // flag = true;
    }

    if (this.state.step !== nextState.step) {
      this.props.initialize({
        countryCode: "EN",
        nationality: "EN",
        birthCountry: "EN",
      });
      // flag = true;
    }

    // return flag;
    return true;
  }

  radioButton = ({ input, ...rest }) => {
    const { translate, content } = this.props;

    return (
      <FormControl>
        <RadioGroup row {...input} {...rest}>
          <FormControlLabel
            value="F"
            control={<Radio color="primary" />}
            label={translate.labels.female}
            disabled={content === "update" ? true : false}
          />
          <FormControlLabel
            value="M"
            control={<Radio color="primary" />}
            label={translate.labels.male}
            disabled={content === "update" ? true : false}
          />
        </RadioGroup>
      </FormControl>
    );
  };

  renderTextField = ({
    label,
    input,
    classes,
    meta: { touched, invalid, error },
    ...custom
  }) => (
    <TextField
      label={label}
      placeholder={label}
      error={touched && invalid}
      helperText={touched && error}
      {...input}
      {...custom}
      InputProps={{
        endAdornment: !invalid && !error && (
          <CheckIcon className={classes.greenColor} />
        ),
      }}
    />
  );

  renderCheckbox = ({ input, label, classes }) => (
    <div>
      <FormControlLabel
        control={
          <Checkbox
            checked={input.value ? true : false}
            onChange={input.onChange}
            color="primary"
          />
        }
        label={label}
        className={classes}
      />
    </div>
  );

  renderFromHelper = ({ touched, error }) => {
    if (!(touched && error)) {
      return;
    } else {
      return <FormHelperText>{touched && error}</FormHelperText>;
    }
  };

  renderSelectField = ({
    input,
    label,
    meta: { touched, error },
    children,
    ...custom
  }) => (
    <FormControl error={touched && error}>
      <InputLabel htmlFor="color-native-simple">{label}</InputLabel>
      <Select
        native
        {...input}
        {...custom}
        inputProps={{
          name: input.name,
          id: "color-native-simple",
        }}
      >
        {children}
      </Select>
      {this.renderFromHelper({ touched, error })}
    </FormControl>
  );

  renderDatePicker = ({
    label,
    input,
    meta: { touched, invalid, error },
    ...custom
  }) => (
    <TextField
      label={label}
      type="date"
      placeholder={label}
      error={touched && invalid}
      helperText={touched && error}
      {...input}
      {...custom}
      // colorIcon={"var(--color-main)"}
      // style={{color: "var(--color-main)"}}
      // id="date"
      // label="Birthday"
      // type="date"
      // defaultValue="2017-05-24"
      // className={classes.textField}
      InputLabelProps={{
        shrink: true,
      }}
    />
  );

  /**
   * Validate all information
   * Name, Phone, address, TOS, Privacy Policy, DOB
   */

  onSubmit = (formValues) => {
    this.props.handleRegisterState(true);
    let flag = true;
    let { errMsgs } = this.state;
    errMsgs = [];

    if (this.validatePhoneNUmber(formValues.phoneNumber) === false) {
      flag = false;
      errMsgs.push("invalid_phone");
    }

    if (!formValues.birthCity) {
      flag = false;
      errMsgs.push("missing_birthCity");
    }

    if (!formValues.ageChecked || !formValues.termsAndConditions) {
      flag = false;
      errMsgs.push("invalid_tos_privacy");
    }

    this.setState({ errMsgs }, () => {
      if (flag) {
        this.props.onSubmit(formValues);
        this.props.handleRegisterState(false);
      }
    });
  };

  onVerificationCompleted(value) {
    this.setState({ recaptchaVerified: true, errMsgs: [] });
  }

  onVerificationFailed(value) {
    this.setState({ recaptchaVerified: false, errMsgs: [] });
  }

  /**
   * Validate password
   */
  validatePassword(str) {
    let pattern = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[-+_!@#$%^&*.,?]).+$"
    );

    return pattern.test(str);
  }

  /**
   * Validate email address
   */
  validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  /**
   * Validate Age
   * Age should be 18+
   */
  validateAge(birthday) {
    // it will accept two types of format yyyy-mm-dd and yyyy/mm/dd
    var optimizedBirthday = birthday.replace(/-/g, "/");

    //set date based on birthday at 01:00:00 hours GMT+0100 (CET)
    var myBirthday = new Date(optimizedBirthday);

    // set current day on 01:00:00 hours GMT+0100 (CET)
    var currentDate = new Date().toJSON().slice(0, 10) + " 01:00:00";

    // calculate age comparing current date and borthday
    var myAge = ~~((Date.now(currentDate) - myBirthday) / 31557600000);

    if (myAge < 18) {
      return false;
    } else {
      return true;
    }
  }

  /**
   * Validate phone number
   */
  validatePhoneNUmber(number) {
    return /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(
      number
    );
  }

  /**
   * Validate missing fields in selected steps
   */
  onValidateCurrentStepForm() {
    const { formValues } = this.props;
    let { errMsgs } = this.state;

    let flag = true;
    if (this.props.step === 0) {
      flag = true;
      errMsgs = [];

      if (!formValues) {
        errMsgs.push("missing_username");
        errMsgs.push("missing_password");
        errMsgs.push("missing_confirm_pass");
        errMsgs.push("missing_email");
        errMsgs.push("missing_confirm_email");
        flag = false;
      } else {
        if (!formValues.screenName) {
          flag = false;
          errMsgs.push("missing_username");
        } else {
          if (
            formValues.screenName?.length < 6 ||
            formValues.screenName?.length > 12 ||
            /[a-zA-Z]/.test(formValues.screenName[0]) === false ||
            /\d/.test(formValues.screenName) === false
          ) {
            flag = false;
            errMsgs.push("invalid_user_name");
          }
        }
        if (!formValues.password) {
          flag = false;
          errMsgs.push("missing_password");
        } else {
          if (
            formValues.password?.length < 8 ||
            this.validatePassword(formValues.password) === false
          ) {
            flag = false;
            errMsgs.push("invalid_pass");
          }
        }

        if (!formValues.passwordConfirm) {
          flag = false;
          errMsgs.push("missing_confirm_pass");
        }

        if (!formValues.email) {
          flag = false;
          errMsgs.push("missing_email");
        } else {
          if (this.validateEmail(formValues.email) === false) {
            flag = false;
            errMsgs.push("invalid_email");
          }
        }

        if (!formValues.emailConfirm) {
          flag = false;
          errMsgs.push("missing_confirm_email");
        }

        if (formValues.password !== formValues.passwordConfirm) {
          flag = false;
          errMsgs.push("invalid_pass_confirm");
        }

        if (formValues.email !== formValues.emailConfirm) {
          flag = false;
          errMsgs.push("invalid_email_confirm");
        }
      }

      if (!this.state.recaptchaVerified) {
        flag = false;
        errMsgs.push("invalid_recaptcha");
      }

      this.setState({ errMsgs });
    }

    if (this.props.step === 1) {
      flag = true;
      errMsgs = [];

      if (!formValues) {
        errMsgs.push("missing_gender");
        errMsgs.push("missing_first_name");
        errMsgs.push("missing_last_name");
        errMsgs.push("missing_dob");
        errMsgs.push("missing_address");
        errMsgs.push("missing_postal_code");
        errMsgs.push("missing_city");
        errMsgs.push("missing_country");
        flag = false;
      } else {
        if (!formValues.sex) {
          flag = false;
          errMsgs.push("missing_gender");
        }

        if (!formValues.firstName) {
          flag = false;
          errMsgs.push("missing_first_name");
        }

        if (!formValues.lastName) {
          flag = false;
          errMsgs.push("missing_last_name");
        }

        if (!formValues.dob) {
          flag = false;
          errMsgs.push("missing_dob");
        } else {
          if (this.validateAge(formValues.dob) === false) {
            flag = false;
            errMsgs.push("min18Y");
          }
        }

        if (!formValues.address1) {
          flag = false;
          errMsgs.push("missing_address");
        }

        if (!formValues.zip) {
          flag = false;
          errMsgs.push("missing_postal_code");
        }

        if (!formValues.city) {
          flag = false;
          errMsgs.push("missing_city");
        }

        if (!formValues.countryCode) {
          flag = false;
          errMsgs.push("missing_country");
        }
      }

      this.setState({ errMsgs });
    }

    if (flag) {
      this.props.goNext();
    }
  }

  /**
   * Validate user name is available before clicking Register button
   * Pre-check username existance
   */
  async validateUserNameExistance() {
    const { formValues } = this.props;
    let { errMsgs } = this.state;

    let host = window.location.hostname
      ? window.location.hostname.replace(/\./g, "_").replace(/\-/g, "_")
      : "betv3_frontend_herokuapp_com";
    let source;
    let baseURL;

    switch (hostConfig[host].api) {
      default:
      case "nubet_test":
        source = "https://test-api.nubet.com";
        break;

      case "nubet_prod":
        source = "https://api.nubet.com";
        break;
    }

    if (formValues.screenName) {
      baseURL += `/account/services/ajax?action=/ecr/ups/screenNameExist&screenName=${formValues.screenName}`;

      const reply = await api.get(baseURL, {
        headers: authHeader(),
      });

      if (reply && reply.data && reply.data.exists) {
        errMsgs.push("user_exists");
        this.setState({ errMsgs });
      } else {
        errMsgs.splice(errMsgs.indexOf("user_exists"), 1);
        this.setState({ errMsgs });
      }
    }
  }

  /**
   * Validate email is available before clicking Register button
   * Pre-check email existance
   */
  async validateEmailExistance() {
    const { formValues } = this.props;
    let { errMsgs } = this.state;

    let host = window.location.hostname
      ? window.location.hostname.replace(/\./g, "_").replace(/\-/g, "_")
      : "betv3_frontend_herokuapp_com";
    let source;
    let baseURL;

    switch (hostConfig[host].api) {
      default:
      case "nubet_test":
        source = "https://test-api.nubet.com";
        break;

      case "nubet_prod":
        source = "https://api.nubet.com";
        break;
    }

    if (formValues.email) {
      baseURL += `/account/services/ajax?action=/ecr/registration/emailExist&email=${formValues.email}`;

      const reply = await api.get(baseURL, {
        headers: authHeader(),
      });

      if (reply && reply.data && reply.data.exists) {
        errMsgs.push("email_exists");
        this.setState({ errMsgs });
      } else {
        errMsgs.splice(errMsgs.indexOf("email_exists"), 1);
        this.setState({ errMsgs });
      }
    }
  }

  renderProfileData() {
    const { step, classes, translate, content, lang } = this.props;

    switch (step) {
      case 0:
        return (
          <Container maxWidth="xl">
            {content === "update" ? (
              <Field
                name="userName"
                component={this.renderTextField}
                classes={classes}
                label={translate.labels.username}
                disabled
              />
            ) : (
              <Field
                name="screenName"
                component={this.renderTextField}
                classes={classes}
                label={translate.labels.username}
                onBlur={this.validateUserNameExistance.bind(this)}
              />
            )}

            <Field
              name="password"
              component={PasswordField}
              classes={classes}
              label={translate.labels.password}
              className={content === "update" ? classes.hidden : null}
            />
            <Field
              name="passwordConfirm"
              component={PasswordField}
              classes={classes}
              label={translate.labels.confirm_pass}
              className={content === "update" ? classes.hidden : null}
            />
            <Field
              name="email"
              component={this.renderTextField}
              classes={classes}
              label={translate.labels.email}
              disabled={content === "update" ? true : false}
              onBlur={this.validateEmailExistance.bind(this)}
              type="email"
            />
            <Field
              name="emailConfirm"
              component={this.renderTextField}
              classes={classes}
              label={translate.labels.confirm_mail}
              className={content === "update" ? classes.hidden : null}
            />

            <ReCAPTCHA
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "3em 0 0 0",
              }}
              sitekey="6LfyOHQfAAAAAJbo2njI_ZNRj-OVEEkXFcFG4T5p"
              onChange={this.onVerificationCompleted.bind(this)}
              onExpired={this.onVerificationFailed.bind(this)}
              onErrored={this.onVerificationFailed.bind(this)}
              theme="dark"
              hl={lang}
              key={lang}
            />
            <br></br>
          </Container>
        );

      case 1:
        return (
          <Container maxWidth="xl">
            {/* <FormLabel component="legend" style={{ textAlignLast: "left" }}><Typography variant="body2">{translate.labels.register_title}</Typography></FormLabel> */}
            <Field
              name="sex"
              component={this.radioButton}
              style={{ textAlignLast: "left" }}
            >
              <Radio value="M" label={translate.labels.male} />
              <Radio value="F" label={translate.labels.female} />
            </Field>
            <Field
              name="firstName"
              component={this.renderTextField}
              classes={classes}
              label={translate.labels.form_fname}
              disabled={content === "update" ? true : false}
            />
            <Field
              name="lastName"
              component={this.renderTextField}
              classes={classes}
              label={translate.labels.form_lname}
              disabled={content === "update" ? true : false}
            />
            <Field
              name="dob"
              component={this.renderDatePicker}
              label={translate.labels.form_}
              disabled={content === "update" ? true : false}
            />
            <Field
              name="address1"
              component={this.renderTextField}
              classes={classes}
              label={translate.labels.street}
            />
            <Field
              name="address2"
              component={this.renderTextField}
              classes={classes}
              label={translate.labels.street2}
            />
            <Field
              name="zip"
              component={this.renderTextField}
              classes={classes}
              label={translate.labels.postal}
            />
            <Field
              name="city"
              component={this.renderTextField}
              classes={classes}
              label={translate.labels.city}
            />
            <Field
              name="countryCode"
              component={this.renderSelectField}
              label={translate.labels.country}
            >
              {countryConfig.allowedResidenceCountries.map((key) => {
                return (
                  <option
                    key={key[0]}
                    value={countryConfig.getTripple(key[0])}
                    disabled={key[0] === "---" ? true : false}
                  >
                    {key[1]}
                  </option>
                );
              })}
            </Field>
          </Container>
        );

      case 2:
        return (
          <Container maxWidth="xl">
            <Field
              name="nationality"
              component={this.renderSelectField}
              label={translate.labels.nationality}
              disabled={content === "update" ? true : false}
            >
              {countryConfig.allowedNationalities.map((key) => {
                return (
                  <option
                    key={key[0]}
                    value={countryConfig.getTripple(key[0])}
                    disabled={key[0] === "---" ? true : false}
                  >
                    {key[1]}
                  </option>
                );
              })}
            </Field>
            <Field
              name="birthCity"
              component={this.renderTextField}
              classes={classes}
              label={translate.labels.cib}
              disabled={content === "update" ? true : false}
            />

            <Field
              name="birthCountry"
              component={this.renderSelectField}
              label={translate.labels.cob}
              disabled={content === "update" ? true : false}
            >
              {countryConfig.all.map((key) => {
                return (
                  <option
                    key={key[0]}
                    value={countryConfig.getTripple(key[0])}
                    disabled={key[0] === "---" ? true : false}
                  >
                    {key[1]}
                  </option>
                );
              })}
            </Field>
            <Field
              name="phoneNumber"
              component={this.renderTextField}
              classes={classes}
              label={translate.labels.mobile}
            />
            <Field
              name="refCode"
              component={this.renderTextField}
              classes={classes}
              label={translate.labels.ref_code}
              className={content === "update" ? classes.hidden : null}
            />
            <br></br>
            <br></br>
            <br></br>
            {/* <Field name="securityAnswer" component={this.renderTextField} classes={classes} label={translate.labels.security_question} className={content === 'update' ? classes.hidden : null} /> */}
            {/* <Field name="ageChecked" component={this.renderCheckbox} label="TOS Text" className={content === 'update' ? classes.hidden : null} /> */}

            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Field
                  name="ageChecked"
                  component={this.renderCheckbox}
                  label={translate.legal.terms_conditions}
                  className={content === "update" ? classes.hidden : ""}
                  classes={classes.smallFont}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  name="termsAndConditions"
                  component={this.renderCheckbox}
                  label={translate.legal.privacy_policy}
                  className={content === "update" ? classes.hidden : null}
                  classes={classes.smallFont}
                />
              </Grid>
              <Grid item xs={"auto"} sm={3}></Grid>
              <Grid item xs={12} sm={6}>
                <Typography
                  style={{ margin: "1.5em 0 0.5em" }}
                  className={classes.smallFont}
                >
                  {translate.legal.welcome_bonus}
                </Typography>
              </Grid>
              <Grid item xs={"auto"} sm={3}></Grid>
              <Grid item xs={3} sm={4}></Grid>
              <Grid item xs={3} sm={2} style={{ margin: "0 0 2em" }}>
                <Field
                  name="infoSms"
                  component={this.renderCheckbox}
                  label={translate.labels.user_sms}
                  className={content === "update" ? classes.hidden : null}
                />
              </Grid>
              <Grid item xs={3} sm={2} style={{ margin: "0 0 2em" }}>
                <Field
                  name="infoEmail"
                  component={this.renderCheckbox}
                  label={translate.labels.user_email}
                  className={content === "update" ? classes.hidden : null}
                />
              </Grid>
            </Grid>

            <Typography
              style={{ fontSize: "1.3em", color: "var(--color-contrast)" }}
              component="span"
            >
              <Link to={`/info/terms-conditions`}>
                <Translate value="legal.terms_conditions_link" />
              </Link>{" "}
              |{" "}
              <Link to={`/info/privacy-policy`}>
                <Translate value="legal.privacy_policy_link" />
              </Link>{" "}
            </Typography>
          </Container>
        );

      default:
    }
  }

  submitForm = () => {
    document.getElementById("submitButton").click();
  };

  render() {
    log(this.constructor.name, "render");
    const { classes } = this.props;

    return (
      <form
        onSubmit={this.props.handleSubmit(this.onSubmit)}
        className={classes.root}
        autoComplete="off"
        id="userForm"
      >
        <div>{this.renderProfileData(this.state.step)}</div>

        {this.state.errMsgs && this.state.errMsgs?.length
          ? this.state.errMsgs.map((msg, key) => (
              <div
                style={{
                  fontSize: "1rem",
                  color: "var(--red)",
                  marginBottom: 3,
                }}
                key={key}
              >
                <Translate value={`labels.${msg}`} />
              </div>
            ))
          : null}

        <Button
          type="submit"
          variant="contained"
          size="small"
          color="primary"
          className={classes.hidden}
          id="submitButton"
        >
          <Translate value="labels.btn_save" />
        </Button>
      </form>
    );
  }
}

const validate = (values, props) => {
  const errors = {};

  const requiredFields = [
    "countryCode",
    "address1",
    "zip",
    "city",
    "firstName",
    "lastName",
    "dob",
    "phoneNumber",
    "screenName",
    "password",
    "passwordConfirm",
    "email",
    "emailConfirm",
    "birthCity",
  ];

  //maks: some fields need to sent to server uppercase
  // const toUpper = [
  //   'address1',
  //   'address2',
  //   'zip',
  //   'city'
  // ];

  // toUpper?.forEach(field => {
  //   values[field] = values[field].toUpper;
  // })

  const checkSpecialCharacters = (field, error) => {
    const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    if (values[field] && specialChars.test(values[field])) {
      errors[field] = error;
    }
  };

  const getMinMax = (max, min, field, error) => {
    if (values[field] && values[field]?.length > max) {
      errors[field] = error;
    } else if (values[field] && values[field]?.length < min) {
      errors[field] = error;
    }
  };

  const checkEmail = (field, error) => {
    if (
      values[field] &&
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values[field])
    ) {
      errors[field] = error;
    }
  };

  const checkPassword = (field, error) => {
    if (
      values[field] &&
      !/^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}/.test(
        values[field]
      )
    ) {
      errors[field] = error;
    }
  };

  const checkFirstLetter = (field, error) => {
    if (values[field] && !/^[a-zA-Z]+$/.test(values[field][0])) {
      errors[field] = error;
    } else if (values[field] && !/\d/.test(values[field])) {
      errors[field] = error;
    }
  };

  const confirmCreds = (initial, confirming, field, error) => {
    if (initial !== confirming) {
      errors[field] = error;
    }
  };
  const checkPhone = (field, error) => {
    if (
      !/^[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(
        values[field]
      )
    ) {
      errors[field] = error;
    }
  };

  requiredFields?.forEach((field) => {
    if (!values[field]) {
      errors[field] = props.translate.labels.field_required;
    }
  });
  // checks if password and confirm password are equal.
  confirmCreds(
    values["password"],
    values["passwordConfirm"],
    "passwordConfirm",
    props.translate.labels.invalid_pass_confirm
  );
  // checks if email and confirm email are equal.
  confirmCreds(
    values["email"],
    values["emailConfirm"],
    "emailConfirm",
    props.translate.labels.invalid_email_confirm
  );
  // Checks the username with max and min values
  getMinMax(12, 6, "screenName", props.translate.labels.invalid_user_name);
  // checks if email is valid
  checkEmail("email", props.translate.labels.invalid_email);
  checkEmail("emailConfirm", props.translate.labels.invalid_email);
  // checks is password is valid
  checkPassword("password", props.translate.labels.invalid_pass);
  checkPassword("passwordConfirm", props.translate.labels.invalid_pass);
  // checks if username starts with a letter
  checkFirstLetter("screenName", props.translate.labels.invalid_user_name);
  // checks if username contains special characters
  checkSpecialCharacters(
    "screenName",
    props.translate.labels.invalid_user_name
  );
  //checks phone number
  checkPhone("phoneNumber", props.translate.labels.invalid_phone);
  // checks if form is dirty
  if (props.step === 0) {
    if (
      values.screenName &&
      values.email &&
      values.emailConfirm &&
      values.email === values.emailConfirm &&
      values.password &&
      values.password === values.passwordConfirm &&
      !errors["screenName"] &&
      !errors["email"] &&
      !errors["emailConfirm"] &&
      !errors["password"] &&
      !errors["passwordConfirm"]
    ) {
      props.enableSubmit();
    } else {
      props.disableSubmit();
    }
  } else if (props.step === 1) {
    if (
      values.address1 &&
      values.sex &&
      values.zip &&
      values.city &&
      values.firstName &&
      values.lastName &&
      values.countryCode &&
      values.dob &&
      !errors["address1"] &&
      !errors["email"] &&
      !errors["sex"] &&
      !errors["firstName"] &&
      !errors["lastName"] &&
      !errors["countryCode"] &&
      !errors["dob"]
    ) {
      props.enableSubmit();
    } else {
      props.disableSubmit();
    }
  } else if (props.step == 2) {
    if (
      values.nationality &&
      values.birthCity &&
      values.birthCountry &&
      values.phoneNumber &&
      values.countryCode &&
      values.termsAndConditions &&
      values.ageChecked &&
      !errors["nationality"] &&
      !errors["birthCity"] &&
      !errors["birthCountry"] &&
      !errors["phoneNumber"] &&
      !errors["countryCode"] &&
      !errors["termsAndConditions"] &&
      !errors["ageChecked"]
    ) {
      props.enableSubmit();
    } else {
      props.disableSubmit();
    }
  }

  return errors;
};

const mapStateToProps = (state, ownProps) => {
  return {
    translate: state.i18n.translations[state.i18n.locale],
    content: ownProps.content,
    step: ownProps.step,
    lang: ownProps.lang,
    formValues: getFormValues("UserForm")(state),
  };
};

export default connect(mapStateToProps)(
  reduxForm({
    form: "UserForm",
    validate,
  })(withStyles(styles)(UserForm))
);
