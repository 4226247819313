/**
 * Show all leagues
 */

import { log } from "../../config/log";
import React from "react";
import $ from "jquery";
import { connect } from "react-redux";
import { Translate } from "react-redux-i18n";
import withStyles from "@mui/styles/withStyles";
import {
  setLeagues,
  getFeaturedSlip,
  getGames,
  getOutrightGames,
  setOutrightLeagues,
  saveFavoriteLeagues,
  deleteSelectedLeagues,
  setFavoritesLayout,
  setLeagueGrouping,
} from "../../actions";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Avatar from "@mui/material/Avatar";
import SportsTabs from "./SportsTabs";
import LeaguesFilterTabs from "./LeaguesFilterTabs";
import MenuTabs from "./MenuTabs";
import Conference from "./Conference";
import IconFB from "@mui/icons-material/SportsSoccer";
import IconTE from "@mui/icons-material/SportsTennis";
import IconBA from "@mui/icons-material/SportsBasketball";
import IconHB from "@mui/icons-material/SportsHandball";
import IconIH from "@mui/icons-material/SportsHockey";
import StarIcon from "@mui/icons-material/StarBorder";
import UncheckedIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckedIcon from "@mui/icons-material/CheckBox";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ViewTabsIcon from "@mui/icons-material/ViewColumn";
import ViewListIcon from "@mui/icons-material/ViewStream";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";

import League from "./League";
import OutrightLeague from "./OutrightLeague";
import FirstLeagueLoader from "./FirstLeagueLoader";
import "./Leagues.css";
import withRouter from "../../helpers/withRouter";

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const styles = (theme) => ({
  root: {
    "& .MuiSvgIcon-root": {
      fontSize: "1.7em",
    },
    "& .MuiTabs-root": {
      minHeight: "1em",
    },
    "& .MuiTab-root": {
      "@media (hover:hover)": {
        fontSize: "1.2em",
      },
    },
    "& .MuiTab-wrapper": {
      display: "inline-flex !important",
    },
    "& .MuiTabScrollButton-root.Mui-disabled": {
      display: "none",
    },
  },
  tabBars: {
    // position: "sticky",
    // top: "0",
    // zIndex: 998,
    position: "static",
    // "@media (hover:hover)": {
    //   position: "static",
    // },
  },
  tabBarFavorites: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  avatarAll: {
    backgroundColor: "transparent !important",
    // color: "var(--grey-3)",
    color: "var(--color-contrast) !important",
    // border: "0.17em solid var(--grey-3)",
    borderRadius: "1em !important",
    width: "1.6em !important",
    height: "1.6em !important",
    fontSize: "0.94em !important",
    lineHeight: "inherit !important",
    fontWeight: "500 !important",
  },
  // avatarLive: {
  //   backgroundColor: "transparent",
  //   color: "var(--color-contrast)",
  //   width: "2.1em",
  //   height: "2.1em",
  //   fontSize: "0.94em",
  //   lineHeight: "inherit",
  //   fontWeight: "500",
  //   backgroundImage: `url(${NowLiveIcon})`,
  //   backgroundRepeat: "no-repeat",
  //   justifySelf: "center",
  //   backgroundPosition: "center",
  //   backgroundSize: "contain",
  // },
  avatarLive: {
    backgroundColor: "transparent",
    color: "var(--color-contrast)",
    width: "2.1em",
    height: "2.1em",
    fontSize: "0.94em",
    lineHeight: "inherit",
    fontWeight: "500",
    // backgroundImage: `url(${NowLiveIcon})`,
    // backgroundRepeat: "no-repeat",
    justifySelf: "center",
    backgroundPosition: "center",
    backgroundSize: "contain",
  },
  // leagueSticky: {
  //   position: 'sticky',
  //   zIndex: '999',
  //   top: '0',
  // },
});

class Leagues extends React.Component {
  constructor(props) {
    super(props);

    const { favoritesLayout } = this.props;

    this.totalFeaturedLeagues = 0;

    this.state = {
      favoriteAdded: false,
      favoritesLayout: favoritesLayout,
      favoriteTabsSelectedLeagueIndex: 0,
      leagueDeleted: false,
      groupedMenuTabsChanged: false,
    };
  }

  /**
   * Get live game and open it first, otherwise show Football leagues first
   * Implement Ripple effect
   */

  loadLegauesOnLoad = (reload) => {
    const {
      leagues,
      leagueFilter,
      sportCode,
      staticLeagueFilter,
      staticSportCode,
      url,
      locale,
    } = this.props;
    // if (navMode) {
    //   if (navMode === 'tab' && layout === 'mouse') {
    //     if (!leagues || !Object.keys(leagues)?.length) this.props.setLeagues(leagues, 'all', 'FB', this.constructor.name);
    //   } else {
    //     if (!leagues || !Object.keys(leagues)?.length) this.props.setLeagues(leagues, leagueFilter ? leagueFilter : 'live', sportCode ? sportCode : 'FB', this.constructor.name);
    //   }
    // }
    if (!leagues || !Object.keys(leagues) || leagueFilter === "favorites") {
      let hasFavs = false;
      let favoriteLeagues = localStorage.getItem("favoriteLeagues")
        ? JSON.parse(localStorage.getItem("favoriteLeagues"))
        : {};

      for (let sportCodeIndex in favoriteLeagues) {
        const favleagues =
          favoriteLeagues[sportCodeIndex] &&
          typeof favoriteLeagues[sportCodeIndex] === "object"
            ? favoriteLeagues[sportCodeIndex]
            : {};
        if (Object.values(favleagues)?.length > 0) {
          hasFavs = true;
        }
      }
      if (url === "/outrights") {
        this.props.setOutrightLeagues(
          leagues,
          staticLeagueFilter
            ? staticLeagueFilter
            : leagueFilter
            ? leagueFilter
            : "all",
          staticSportCode
            ? staticSportCode
            : url === "/horseracing"
            ? "TR"
            : sportCode
            ? sportCode
            : hasFavs
            ? "favorites"
            : "FB",
          "",
          this.constructor.name
        );
      } else {
        this.props.setLeagues(
          reload ? null : leagues,
          staticLeagueFilter
            ? staticLeagueFilter
            : leagueFilter
            ? leagueFilter
            : "all",
          staticSportCode
            ? staticSportCode
            : url === "/horseracing"
            ? "TR"
            : sportCode
            ? sportCode
            : hasFavs
            ? "favorites"
            : "FB",
          locale,
          this.constructor.name
        );
      }
    }
  };

  componentDidMount() {
    const { searchQuery, bets } = this.props;
    const slipType = new URLSearchParams(searchQuery)?.get("slipType");
    // if (slipActionParam === "add-to-featured-slip" && slipType) {
    this.props.getFeaturedSlip(this.constructor.name, slipType, true, bets);
    // }
    this.loadLegauesOnLoad();
    window.addEventListener("popstate", () => {
      this.loadLegauesOnLoad(true);
    });

    $(document).on("mousedown", "[data-ripple]", function (e) {
      var $self = $(this);
      if (e.target && e.target.tagName) {
        if (e.target.tagName === "path" || e.target.tagName === "svg") return;
      }

      if ($self.hasClass("league-box-selected")) return;

      if ($self.is(".btn-disabled")) {
        return;
      }

      if ($self.closest("[data-ripple]")) {
        e.stopPropagation();
      }

      var initPos = $self.css("position"),
        offs = $self.offset(),
        x = e.pageX - offs.left,
        y = e.pageY - offs.top,
        dia = Math.min(this.offsetHeight, this.offsetWidth, 100), // start diameter
        $ripple = $("<div/>", { class: "ripple", appendTo: $self });

      if (!initPos || initPos === "static") {
        $self.css({ position: "relative" });
      }

      $("<div/>", {
        class: "rippleWave",
        css: {
          background: $self.data("ripple"),
          width: dia,
          height: dia,
          left: x - dia / 2,
          top: y - dia / 2,
        },
        appendTo: $ripple,
        one: {
          animationend: function () {
            $ripple.remove();
          },
        },
      });
    });
  }

  componentWillUnmount() {
    window.removeEventListener("popstate", this.loadLegauesOnLoad);
  }

  reloadLeaguesView = () => {
    this.setState({
      groupedMenuTabsChanged: !this.state.groupedMenuTabsChanged,
    });
  };

  /**
   * Set Favorite leagues
   */

  saveFavoriteLeagues(sportCode, leagueId) {
    const { leagues, url, outrightOnly } = this.props;
    const allselecteLeagues =
      leagues && typeof leagues === "object" ? leagues : {};
    const leagueIndex = Object.values(allselecteLeagues).findIndex(
      (item) => item.leagueId === leagueId
    );
    let favoriteLeagues = { ...this.props.favoriteLeagues };
    if (!favoriteLeagues[sportCode]) favoriteLeagues[sportCode] = {};

    if (!favoriteLeagues[sportCode][leagueId]) {
      if (url === "/outrights" || outrightOnly) {
        favoriteLeagues[sportCode][leagueId] = {
          ...leagues[leagueIndex],
          isOutright: true,
        };
      } else {
        favoriteLeagues[sportCode][leagueId] = {
          ...leagues[leagueIndex],
          isOutright: false,
        };
      }
    } else {
      delete favoriteLeagues[sportCode][leagueId];
    }

    localStorage.setItem("favoriteLeagues", JSON.stringify(favoriteLeagues));
    // Cookies.set('favoriteLeagues', favoriteLeagues, { path: '/' });
    this.props.saveFavoriteLeagues(this.constructor.name);
    this.setState({ favoriteAdded: leagueId });
  }

  /**
   * Select and open the league
   */

  selectLeague(league, isSearch) {
    const {
      selectedLeagues,
      leagueFilter,
      sportCode,
      layout,
      navMode,
      url,
      locale,
      outrightOnly,
    } = this.props;
    if (layout === "mouse" && navMode === "tab") {
      this.saveFavoriteLeagues(league.sportCode, league.leagueId);
    } else if (league) {
      let leagues = {
        leagueFilter,
        [league.leagueId]: league,
      };

      if (
        selectedLeagues[league.leagueId] &&
        selectedLeagues[league.leagueId]["games"] &&
        selectedLeagues[league.leagueId]["isOpen"] &&
        !isSearch
      ) {
        this.props.deleteSelectedLeagues(
          league.leagueId,
          leagueFilter === "live" ? true : false,
          this.constructor.name
        );
        this.setState({ leagueDeleted: true });
      } else if (
        isSearch &&
        selectedLeagues[league.leagueId] &&
        selectedLeagues[league.leagueId]["games"]
      ) {
        this.props.deleteSelectedLeagues(
          league.leagueId,
          leagueFilter === "live" ? true : false,
          this.constructor.name
        );
        this.setState({ leagueDeleted: true });
      } else {
        this.props.getGames(
          leagueFilter,
          sportCode === "favorites" || sportCode === "ES"
            ? league.sportCode
            : sportCode,
          leagues,
          true,
          locale,
          this.constructor.name
        );
      }
    }
  }

  getSportName = (sportCode) => {
    const { sportLabels } = this.props;
    return sportLabels ? sportLabels.default[sportCode] : null;
  };

  /**
   * Show total games number for the league
   */

  renderTotalGamesCounter() {
    // console.log('render Counter');
    const { leagues, leagueFilter, sportCode, leagueGrouping } = this.props;
    if (!leagues) return <div key="no-leagues"></div>;

    let totalAllCount = 0,
      totalLiveCount = 0;
    leagues &&
      Object.values(leagues)?.forEach((league, key) => {
        if (league.leagueId) {
          totalAllCount += Number(league.gameCount);
          totalLiveCount += Number(league.gameLiveCount);
        }
      });

    totalAllCount = totalAllCount <= 99 ? totalAllCount : "99+";
    totalLiveCount = totalLiveCount <= 99 ? totalLiveCount : "99+";

    return (
      <div
        className="league-selector-legend-touch"
        key="league-selector-legend-touch"
      >
        <div className="spacer-left vertical-center grey-3">
          {sportCode === "favorites"
            ? this.state.favoritesLayout === "list"
              ? [
                  <ViewListIcon
                    style={{
                      fontSize: "2.2em",
                      color: "var(--color-contrast)",
                    }}
                    onClick={() => this.setFavoritesLayout()}
                    key={0}
                  />,
                  <ViewTabsIcon
                    style={{ fontSize: "2.2em" }}
                    onClick={() => this.setFavoritesLayout()}
                    key={1}
                  />,
                ]
              : [
                  <ViewListIcon
                    style={{ fontSize: "2.2em" }}
                    onClick={() => this.setFavoritesLayout()}
                    key={0}
                  />,
                  <ViewTabsIcon
                    style={{
                      fontSize: "2.2em",
                      color: "var(--color-contrast)",
                    }}
                    onClick={() => this.setFavoritesLayout()}
                    key={1}
                  />,
                ]
            : // leagueFilter === 'live' && Object.keys(leagues)?.length > 1 ?
            leagueFilter === "live"
            ? [
                leagueGrouping ? (
                  <ToggleOnIcon
                    style={{ color: "var(--color-contrast)" }}
                    onClick={() => this.toggleLeagueGrouping()}
                    key="league-grouping-on"
                  />
                ) : (
                  <ToggleOffIcon
                    onClick={() => this.toggleLeagueGrouping()}
                    key="league-grouping-off"
                  />
                ),
                <span
                  className="font-xs spacer-left"
                  key="league-grouping-label"
                >
                  <Translate value="labels.group_events" />
                  {"  "}
                </span>,
              ]
            : null}
        </div>
        <div className="font-xs vertical-center content-right">
          <Translate value={`labels.avatar_now_live`} />
        </div>
        <div className="vertical-center spacer-left">
          <Avatar className={this.props.classes.avatarLive}>
            {totalLiveCount}
          </Avatar>
        </div>
        <div className="font-xs vertical-center content-right">
          <Translate value={`labels.avatar_all`} />
        </div>
        <div className="vertical-center content-right spacer-right">
          <Avatar className={this.props.classes.avatarAll}>
            {totalAllCount}
          </Avatar>
        </div>
      </div>
    );
  }

  favoriteTabsSelectedLeagueChangeIndex = (event, newValue) => {
    this.setState({ favoriteTabsSelectedLeagueIndex: newValue });
  };

  setFavoritesLayout() {
    let newLayout = this.state.favoritesLayout === "list" ? "tabs" : "list";
    this.props.setFavoritesLayout(newLayout, this.constructor.name);
    this.setState({ favoritesLayout: newLayout });
  }

  toggleLeagueGrouping() {
    const { leagueGrouping } = this.props;
    // let newLayout = this.state.favoritesLayout === 'list' ? 'tabs' : 'list';
    this.props.setLeagueGrouping(!leagueGrouping, this.constructor.name);
    // this.setState({ favoritesLayout: newLayout });
  }

  /**
   * Show favorite leagues
   */

  renderFavorites() {
    const {
      leagues,
      leagueFilter,
      url,
      gameStaticId,
      locale,
      sportCode,
      selectedLeagues,
      classes,
      outrightOnly,
    } = this.props;
    /***** get games for favorite leagues when it's selected in default *****/
    switch (this.state.favoritesLayout) {
      case "list":
      default:
        leagues &&
          Object.keys(leagues)?.forEach((leagueId, index) => {
            let league = Object.values(leagues)[index]
              ? Object.values(leagues)[index]
              : null;
            // console.log(league);
            if (league) {
              if (!selectedLeagues[leagueId])
                this.props.getGames(
                  leagueFilter,
                  sportCode === "favorites" || sportCode === "ES"
                    ? league.sportCode
                    : sportCode,
                  { [leagueId]: league },
                  true,
                  this.constructor.name,
                  league?.isOutright
                );
            }
          });

        return [
          // this.renderTotalGamesCounter(),
          this.renderLeagues("favorites"),
        ];

      case "tabs":
        let leagueId;
        let isSelected = false;
        let league = Object.keys(leagues)[
          this.state.favoriteTabsSelectedLeagueIndex
        ]
          ? Object.values(leagues)[this.state.favoriteTabsSelectedLeagueIndex]
          : null;
        if (league) {
          leagueId = league.leagueId;
          if (
            league.sportCode === "TR" ||
            url === "/outrights" ||
            outrightOnly ||
            league?.onlyOtright ||
            (league.league_game_cnt === 0 &&
              parseInt(league.league_outright_cnt) > 0)
          ) {
            this.props.getOutrightGames(
              "all",
              league.leagueId,
              league.sportCode,
              locale,
              this.constructor.name
            );
          } else {
            if (!selectedLeagues[leagueId])
              this.props.getGames(
                leagueFilter,
                sportCode === "favorites" || sportCode === "ES"
                  ? league.sportCode
                  : sportCode,
                { [leagueId]: league },
                true,
                this.constructor.name
              );
          }
          if (selectedLeagues[leagueId] && selectedLeagues[leagueId]["games"])
            isSelected = true;
        }

        let icons = {
          FB: <IconFB style={{ fontSize: "1.15em" }} />,
          TE: <IconTE style={{ fontSize: "1.15em" }} />,
          BA: <IconBA style={{ fontSize: "1.15em" }} />,
          HB: <IconHB style={{ fontSize: "1.15em" }} />,
          IH: <IconIH style={{ fontSize: "1.15em" }} />,
          default: <StarIcon style={{ fontSize: "1.15em" }} />,
        };

        return (
          <div key={0}>
            <div className={classes.root}>
              <AppBar position="static" color="primary">
                <Tabs
                  value={this.state.favoriteTabsSelectedLeagueIndex}
                  onChange={this.favoriteTabsSelectedLeagueChangeIndex.bind(
                    this
                  )}
                  variant="scrollable"
                  scrollButtons="auto"
                  indicatorColor="primary"
                >
                  {Object.values(leagues).map((league, key) => (
                    <Tab
                      key={key}
                      value={key}
                      label={
                        <div className="vertical-center text-wrap">
                          {icons[league.sportCode]
                            ? icons[league.sportCode]
                            : icons.default}
                          <img
                            src={`https://api.nubet.com/media/flags/${league.countryCode}.png`}
                            style={{ margin: "0 0.1em" }}
                            alt="flag"
                          />
                          <span className="text-wrap">{league.label}</span>
                        </div>
                      }
                      {...a11yProps(key)}
                    />
                  ))}
                </Tabs>
              </AppBar>
              {this.renderTotalGamesCounter()}
            </div>
            {(isSelected && league.sportCode === "TR") ||
            (isSelected && league.isOutright) ||
            (isSelected &&
              league.league_game_cnt === 0 &&
              parseInt(league.league_outright_cnt) > 0) ? (
              <OutrightLeague
                outrightOnly={outrightOnly}
                leagueId={league.leagueId}
                singleOnlyOutright={
                  isSelected &&
                  league.league_game_cnt === 0 &&
                  parseInt(league.league_outright_cnt) > 0
                }
              />
            ) : (isSelected && league.sportCode !== "TR" && !outrightOnly) ||
              (isSelected && !league.isOutright && !outrightOnly) ? (
              <League leagueId={league.leagueId} gameStaticId={gameStaticId} />
            ) : null}
          </div>
        );
    }
  }

  /**
   * Render leagues based on type ( favorite / normal )
   * remove the league which has no live games ( when LIVE view mode / leagueFilter is selected )
   */

  renderLeagues(type) {
    const {
      sportCode,
      favoriteLeagues,
      outrightOnly,
      url,
      searchQuery,
      selectedLeagueId,
      countryCode,
      selectedLeagues,
      classes,
      gameStaticId,
      layout,
      navMode,
      leagueFilter,
      visibleInFavorites,
    } = this.props;
    let { leagues } = this.props;
    if (!leagues || !Object.keys(leagues)?.length) {
      if (type === "favorites") {
        return (
          <div
            key={`loading-${type}-${sportCode}`}
            className="color-contrast vertical-center content-center font-xl"
            style={{ padding: "6em 0", backgroundColor: "var(--grey-7)" }}
          >
            <Translate value="labels.no_favorite" />
          </div>
        );
      } else {
        // return <div key={`loading-${type}-${sportCode}`}>No Leagues</div>;
        return <div key={`loading-${type}-${sportCode}`}></div>;
      }
    }

    switch (type) {
      case "featured":
        leagues = Object.values(leagues).filter((league) => {
          return league?.isTopLeague === 1;
        });

        this.totalFeaturedLeagues = Object.keys(leagues)?.length;

        break;

      case "leagues":
        leagues = Object.values(leagues).filter((league) => {
          return league?.isTopLeague === 0 || league?.isTopLeague === 1;
        });
        break;

      default:
        break;
    }

    let sportCodes = [];
    // let showSportsHeader = false;

    let availableLeagues = [];
    availableLeagues = Object.values(leagues);

    if (leagueFilter === "live" && visibleInFavorites) {
      availableLeagues = availableLeagues.filter(
        (obj) => obj.visibleInFavorites
      );
    }

    const selectedLeague = availableLeagues.find(
      (lg) => lg.leagueId === parseInt(selectedLeagueId)
    );

    if (selectedLeagueId && selectedLeague) {
      availableLeagues = availableLeagues.filter(
        (lg) => lg.leagueId !== parseInt(selectedLeagueId)
      );
      availableLeagues = [selectedLeague, ...availableLeagues];
    }
    if (countryCode) {
      availableLeagues = availableLeagues.filter(
        (lg) => lg.countryCode === countryCode
      );
    }

    return availableLeagues.map((league, key) => {
      // showSportsHeader = false;
      if (type === "favorites" && !sportCodes.includes(league.sportCode)) {
        sportCodes.push(league.sportCode);
        // showSportsHeader = true;
      }

      let gameLiveCount = league.gameLiveCount ? league.gameLiveCount : 0;

      let leagueText;
      if (league.labelCategory !== "") {
        leagueText = (
          <span>
            <span style={{ marginRight: "2px" }}>
              <b>{league.labelCategory} </b>
            </span>
            {league.label}
          </span>
        );
      } else {
        leagueText = <b>{league.label}</b>;
      }

      let isSelected =
        ((selectedLeagues[league.leagueId] &&
          selectedLeagues[league.leagueId]["games"]) ||
          (selectedLeagues[league.leagueId] &&
            selectedLeagues[league.leagueId]["outrightGames"])) &&
        selectedLeagues[league.leagueId].isOpen
          ? true
          : false;
      let className = isSelected ? `league-box-selected` : "league-box";
      return (
        <div key={key} className={`${classes.root}`}>
          {/* {
            key === 0 || showSportsHeader ?
              (<div key={key + '-' + type} className={`font-s content-center font-bold league-box-title vertical-center`}>
                <h6>
                  <span>
                    {type === 'favorites' ? this.getSportName(league.sportCode) : type}
                  </span>
                </h6>
              </div>)
              : null
          } */}
          <div></div>
          {/* <div className={className} data-ripple="var(--color-ripple)"> */}
          <div className={className} data-ripple="var(--color-ripple)">
            <div
              className="vertical-center grey-3 spacer-left-list"
              onClick={() => {
                this.saveFavoriteLeagues(league.sportCode, league.leagueId);
              }}
            >
              {favoriteLeagues &&
              favoriteLeagues[league.sportCode] &&
              favoriteLeagues[league.sportCode][league.leagueId] ? (
                <CheckedIcon style={{ color: "var(--color-contrast)" }} />
              ) : (
                <UncheckedIcon />
              )}
            </div>
            <div
              id="league-country-flag"
              className="vertical-center content-center"
            >
              <img
                src={`https://api.nubet.com/media/flags/${(league.countryCode =
                  "" ? "INT" : league.countryCode)}.png`}
                alt="flag"
              />
            </div>
            {/* <div className="text-wrap font-xl font-bold vertical-center spacer-left" onClick={() => this.selectLeague(league)}>{leagueText}</div> */}
            <div
              className="text-wrap font-xl font-bold vertical-center league-select-row"
              onClick={() => this.selectLeague(league)}
            >
              {leagueText}
            </div>
            <div
              className="vertical-center content-right"
              onClick={() => this.selectLeague(league)}
            >
              {gameLiveCount > 0 ? (
                <span>
                  <Avatar className={this.props.classes.avatarLive}>
                    {gameLiveCount}
                  </Avatar>
                </span>
              ) : null}
              {/* <span className="spacer-left"><Avatar className={this.props.classes.avatarAll}>{league.gameCount}</Avatar></span> */}
              <span
                className={`spacer-left ${
                  leagueFilter === "live" ? "hidden" : null
                }`}
              >
                <Avatar className={this.props.classes.avatarAll}>
                  {league.gameCount}
                </Avatar>
              </span>
            </div>

            {navMode === "tab" && layout === "mouse" ? null : (
              <div
                className="vertical-center content-center"
                onClick={() => this.selectLeague(league)}
              >
                {isSelected ? (
                  <ExpandLessIcon style={{ color: "var(--grey-3)" }} />
                ) : (
                  <ExpandMoreIcon style={{ color: "var(--grey-3)" }} />
                )}
              </div>
            )}
          </div>
          {(isSelected && league.sportCode === "TR") ||
          (isSelected && url === "/outrights" && type !== "favorites") ||
          (isSelected && league?.isOutright) ||
          (isSelected &&
            league.league_game_cnt === 0 &&
            parseInt(league.league_outright_cnt) > 0) ? (
            <OutrightLeague
              searchQuery={searchQuery}
              leagueId={league.leagueId}
              outrightOnly={outrightOnly}
              singleOnlyOutright={
                isSelected &&
                league.league_game_cnt === 0 &&
                parseInt(league.league_outright_cnt) > 0
              }
            />
          ) : (isSelected && league.sportCode !== "TR" && !outrightOnly) ||
            (isSelected && url !== "/outrights" && !outrightOnly) ||
            (isSelected &&
              url === "/outrights" &&
              type === "favorites" &&
              !outrightOnly) ||
            (isSelected && !league?.isOutright && !outrightOnly) ? (
            <League
              searchQuery={searchQuery}
              leagueId={league.leagueId}
              gameStaticId={gameStaticId}
            />
          ) : null}
        </div>
      );
    });
  }

  selectFirstLeague() {
    const { leagues, sportCode, leagueFilter, searchQuery } = this.props;
    const leagueID = new URLSearchParams(searchQuery)?.get("leagueId");
    const gameID = new URLSearchParams(searchQuery)?.get("gameId");

    if (leagueID && gameID && leagues && leagues?.length) {
      const selectedQueryLeague = leagues?.find(
        (lg) => lg?.leagueId === parseInt(leagueID)
      );

      if (selectedQueryLeague) {
        this.selectLeague(selectedQueryLeague, false);

        const selectedGameIndex = document.getElementById(
          `scroll_tab_index_${leagueID}_${gameID}`
        );

        if (selectedGameIndex) {
          selectedGameIndex.scrollIntoView();
        }
      }
    } else if (
      leagues &&
      sportCode !== "PS" &&
      sportCode !== "TR" &&
      sportCode !== "favorites" &&
      leagueFilter !== "search" &&
      !leagueID
    ) {
      this.selectLeague(
        leagues && Object.values(leagues)[0] ? Object.values(leagues)[0] : {},
        false
      );
    } else if (
      (leagues && Object.values(leagues) && sportCode === "PS") ||
      (leagues && Object.values(leagues) && sportCode === "TR" && !leagueID)
    ) {
      Object.values(leagues)?.forEach((legaueSelectable) => {
        this.selectLeague(legaueSelectable ? legaueSelectable : {}, false);
      });
    }
  }

  render() {
    log(this.constructor.name, "render");
    const {
      selectedLeagues,
      leagueFilter,
      layout,
      leagues,
      sportCode,
      leagueGrouping,
      classes,
      selectedLeagueId,
    } = this.props;
    // console.log(leagueFilter, sportCode);
    if (!leagueFilter) return <div></div>;
    return (
      <div>
        <FirstLeagueLoader
          callBack={this.selectFirstLeague.bind(this)}
          availableLeagues={leagues}
          league={
            leagues && Object.values(leagues)[0]
              ? Object.values(leagues)[0]
              : {}
          }
        />
        <div className={`${classes.tabBars}`} key="tab-bar-sports">
          {layout !== "mouse" ? [] : null}

          {/* {
            sportCode !== 'favorites' ? <LeaguesFilterTabs leagueFilter={leagueFilter} key="0" /> : null
          } */}
          <LeaguesFilterTabs
            leagueFilter={leagueFilter}
            sportCode={sportCode}
            key="0"
          />
          <SportsTabs
            sportCode={sportCode}
            leagueFilter={leagueFilter}
            key="1"
          />
        </div>
        <div>
          {leagueFilter === "favorites"
            ? this.renderFavorites()
            : [
                // this.renderTotalGamesCounter(),
                leagueFilter === "live" ? (
                  <div key="menu-tabs">
                    <MenuTabs
                      content="leagues"
                      leagueFilter={leagueFilter}
                      sportCode={sportCode}
                      parentCallBack={this.reloadLeaguesView}
                    />
                  </div>
                ) : null,
                leagueGrouping &&
                selectedLeagues &&
                Object.keys(selectedLeagues)?.length &&
                Object.values(selectedLeagues)[0].menuValue === "conference" ? (
                  <div key="conference">
                    <Conference content="leagues" />
                  </div>
                ) : selectedLeagueId ? (
                  this.renderLeagues("leagues")
                ) : (
                  [
                    // this.renderLeagues('featured'),
                    this.renderLeagues("leagues"),
                  ]
                ),
              ]}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let leagueFilter = ownProps?.leagueFilter
    ? ownProps?.leagueFilter
    : state.leagues._leagueFilter;

  let sportCode = ownProps?.sportCode
    ? ownProps?.sportCode
    : state.leagues._sportCode;
  if (sportCode === "favorites") {
    leagueFilter = sportCode;
  }
  let favoriteLeagues = state.leagues.favorites;
  let outrightOnly =
    state.leagues[leagueFilter] &&
    state.leagues[leagueFilter][sportCode] &&
    state.leagues[leagueFilter][sportCode]["outrightGames"] &&
    state.leagues[leagueFilter][sportCode]["games"] &&
    Object.keys(state.leagues[leagueFilter][sportCode]["games"])?.length === 0;
  let leagues;

  switch (leagueFilter) {
    case "live":
      leagues =
        state.leagues[leagueFilter] && state.leagues[leagueFilter][sportCode]
          ? state.leagues[leagueFilter][sportCode]
          : [];
      leagues = leagues.filter((league) => {
        if (
          league?.games &&
          Object.values(league?.games)?.length === 1 &&
          Object.values(league?.games)[0]?.time === "End"
        ) {
          return false;
        }
        return true;
      });
      break;
    case "favorites":
      const selectedLeaguesAll = {};
      for (let sportCode in favoriteLeagues) {
        for (let leagueId in favoriteLeagues[sportCode]) {
          selectedLeaguesAll[leagueId] = favoriteLeagues[sportCode][leagueId];
        }
      }
      leagues = selectedLeaguesAll;
      break;

    default:
      leagues =
        state.leagues[leagueFilter] && state.leagues[leagueFilter][sportCode]
          ? state.leagues[leagueFilter][sportCode]
          : null;
      break;
  }
  return {
    bets:
      state.slips && state.slips[state.selected.slipTemplate].settings?.bets
        ? Object.values(state.slips[state.selected.slipTemplate].settings.bets)
        : null,
    outrightOnly: outrightOnly,
    leagues: leagues,
    favoriteLeagues: favoriteLeagues,
    visibleInFavorites: state.leagues.visibleInFavorites,
    selectedLeagues: state.selected.leagues,
    leagueFilter: leagueFilter,
    sportCode: sportCode,
    sportLabels: state.labels.sports,
    layout: state.settings.layout,
    favoritesLayout: state.settings.favoritesLayout,
    leagueGrouping: state.settings.leagueGrouping,
    navMode: state.settings.navMode,
    selectedLeagueId: ownProps.selectedLeagueId,
    gameStaticId: ownProps.gameStaticId,
    countryCode: ownProps.filterMethod,
    staticSportCode: ownProps.sportCode,
    staticLeagueFilter: ownProps.leagueFilter,
    url: state.settings.url,
    locale: state.i18n.locale,
    searchQuery: ownProps?.location?.search
      ? ownProps?.location?.search
      : ownProps?.searchQuery,
    searchParams: ownProps?.location?.search,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    setLeagues,
    getFeaturedSlip,
    setOutrightLeagues,
    getGames,
    getOutrightGames,
    saveFavoriteLeagues,
    deleteSelectedLeagues,
    setFavoritesLayout,
    setLeagueGrouping,
  })(withStyles(styles)(Leagues))
);
