import { icons, styles } from "../betbuilder-screen/SportsTabs";
import React from "react";
import { connect, useSelector } from "react-redux";
import { Translate } from "react-redux-i18n";
import withStyles from "@mui/styles/withStyles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Box } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const SportsFilterTabs = ({ classes, selectedSportCode }) => {
  // router hooks
  const navigate = useNavigate();
  const location = useLocation();
  // state
  const sportLabels = useSelector((state) => state.labels.sports);
  const mode = useSelector((state) => state.settings.mode);
  const playerProps = useSelector((state) => state.leagues?.playerprops);

  // sports codes list
  const sportCodes = playerProps && typeof playerProps === "object" ? Object.keys(playerProps) : [];
  // handle tab change
  const handleChange = (e, newSportCode) => {
    navigate(`${location.pathname}?selected-sport-code=${newSportCode}`);
  };

  const getSportName = (sportCode) => {
    if (sportCode === "favorites") return <Translate value={`menu.mySports`} />;
    if (sportLabels) return sportLabels.default[sportCode];
  };

  //  make tabs
  const tabs = [];

  if (sportLabels?.default) {
    sportCodes.forEach((item) => {
      tabs.push({
        sportCode: item,
        icon: icons[item] ? icons[item] : <CheckedIcon />,
        active: item === selectedSportCode ? true : false,
      });
    });
  }

  return (
    <Box className={classes.root}>
      <AppBar position="static" color="transparent" elevation={0}>
        <Tabs
          value={selectedSportCode}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          // indicatorColor="none"
          TabIndicatorProps={{
            style: {
              display: "none",
            },
          }}
        >
          {tabs.map((tab, key) => (
            <Tab
              key={tab.sportCode}
              value={tab.sportCode}
              icon={mode === "standard" ? tab.icon : null}
              label={<span className={classes.tabLabel}>{getSportName(tab.sportCode)}</span>}
              content={tab.sportCode}
              aria-label={tab.sportCode}
              className="sports_tab_test_selector"
              {...a11yProps(key)}
              iconPosition="top"
            />
          ))}
        </Tabs>
      </AppBar>
    </Box>
  );
};

SportsFilterTabs.propTypes = {
  selectedSportCode: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
};

SportsFilterTabs.defaultProps = {
  selectedSportCode: "FB",
};

export default withStyles(styles)(SportsFilterTabs);
