import { log } from "../../config/log";
import React from "react";
import { connect } from "react-redux";
import withStyles from "@mui/styles/withStyles";
import { Localize } from "react-redux-i18n";
import {
  saveBet,
  deleteBet,
  updateOddsInLeague,
  getOddsHistory,
  toggleMyTicketsPanel,
} from "../../actions";
import "./OutrightGameBox.css";
import "./Odds.css";
import OddsFormat from "../OddsFormat";
import { format } from "date-fns";

const styles = (theme) => ({
  root: {
    "& .MuiTabs-root": {
      minHeight: "1em",
    },
    "& .MuiTab-root": {
      minHeight: "1em",
    },
    "& .MuiSvgIcon-root": {
      fontSize: "1.2em",
    },
    "& .MuiTabScrollButton-root": {
      fontSize: "1.3em",
      width: "1.3em",
      opacity: "0.5",
    },
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  rootTop: {
    "& .MuiTab-wrapper": {
      display: "inline-flex !important",
    },
    "& .MuiSvgIcon-root": {
      fontSize: "1.3em !important",
      "@media (hover: none) and (min-width: 768px)": {
        fontSize: "1.5em !important",
      },
    },
    "& .MuiTab-wrapper > *:first-child": {
      marginBottom: 0,
    },
    "& .MuiTabScrollButton-root.Mui-disabled": {
      display: "none",
    },
    "& .MuiTab-root": {
      minHeight: "1em",
      minWidth: 0,
      fontSize: "1.3em",
    },
    // flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.default,
    // position: 'sticky',
    // top: '0',
    // zIndex: '999'
  },
  tabLabel: {
    fontSize: "0.7em",
  },
  tabsticky: {
    position: "sticky",
    top: "3em",
    zIndex: "997",
  },
});

class OutrightGameBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      betAdded: false,
    };
  }

  addBet(odd, index) {
    const { game, selectedSlipTemplate, maxSelections, bets } = this.props;
    this.setState({ betAdded: !this.state.betAdded });
    if (this.deleteBet(odd, index) === true) return false;
    // if (maxSelections === bets?.length) {
    //   return;
    // }

    let payload = {
      gameId: game.gameid,
      isLive: game.isLive,
    };

    const marketId = odd.m;
    const selectionId = odd.s;
    const price = odd.p;
    const hc = odd.hc;
    const isActive = odd.iA;

    payload.marketId = marketId;
    payload.selectionId = selectionId;
    payload.price = price;
    payload.hc = hc;
    payload.hn = odd.hn;
    payload.sn = odd.sn;
    payload.nr = odd?.nr;
    payload.isActive = isActive;
    payload.game = {
      ...game,
      score_set_calculated: {
        away: [],
        home: [],
      },
    };
    payload.isOutright = true;
    this.props.toggleMyTicketsPanel(this.constructor.name, true);
    this.props.saveBet(payload, selectedSlipTemplate, this.constructor.name);

    return Object.keys(payload)?.length === 0 ? false : true;
  }

  /**
   * Deselect odd from bet
   */

  deleteBet(odd, index) {
    const self = this;
    const marketId = odd.m;
    const selectionIds = odd.s;

    if (selectionIds?.length < 3) {
      selectionIds.unshift(null);
    }

    let payload = {};
    payload = self.props.bets.filter(function (bet) {
      return (
        bet.gameId === self.props.game.gameid &&
        bet.marketId === Number(marketId) &&
        bet.selectionId === Number(selectionIds[index]) &&
        bet.hc === odd.hc
      );
    });

    if (payload?.length) {
      self.props.deleteBet(payload[0], self.props.selectedSlipTemplate);
      return Object.keys(payload[0])?.length === 0 ? false : true;
    } else {
      return false;
    }
  }

  saveQueryBet() {
    const { game, searchQuery } = this.props;
    const selectionID = new URLSearchParams(searchQuery)?.get("selectionId");
    const marketID = new URLSearchParams(searchQuery)?.get("marketId");
    const gameId = new URLSearchParams(searchQuery)?.get("gameId");

    game?.odds?.forEach((odd, indx) => {
      if (
        selectionID &&
        parseInt(gameId) === game?.gameid &&
        marketID &&
        odd?.s === parseInt(selectionID) &&
        odd?.m === parseInt(marketID) &&
        !this.checkSelectedOdd(odd, indx)
      ) {
        this.addBet(odd, indx);
      }
    });
  }

  componentDidMount() {
    this.saveQueryBet();
  }

  checkSelectedOdd(odd, index) {
    const { game, bets } = this.props;

    let payload = {
      gameId: game.gameid,
      // isLive: game.isLive
    };

    // payload.isMultiBet = 0;
    // payload.isBank = 0;
    payload.marketId = odd.m;
    payload.selectionId = odd.s;
    payload.price = odd.p;
    payload.hc = odd.hc;
    // payload.isActive = Number(isActives[index]);

    let flag = false;

    if (!bets) return flag;

    for (let i = 0; i < bets?.length; i++) {
      let bet = bets[i];
      // if ((bet.gameId === payload.gameId) && (bet.hc === payload.hc) && (bet.isActive === payload.isActive) && (bet.isBank === payload.isBank) && (bet.isLive === payload.isLive) && (bet.isMultiBet === payload.isMultiBet) && (bet.marketId === payload.marketId) && (bet.selectionId === payload.selectionId)) {
      if (
        bet.gameId === payload.gameId &&
        bet.marketId === payload.marketId &&
        bet.selectionId === payload.selectionId &&
        bet.hc === payload.hc
      ) {
        flag = true;
        break;
      }
    }

    // for (let key in slipTemplates) {
    //   if (slipTemplates[key].slipTemplate === selectedSlipTemplate) {

    //     let bets = slipTemplates[key].settings.bets;

    //     for (let nextKey in bets) {
    //       if ((bets[nextKey].gameId === payload.gameId) && (bets[nextKey].hc === payload.hc) && (bets[nextKey].isActive === payload.isActive) && (bets[nextKey].isBank === payload.isBank) && (bets[nextKey].isLive === payload.isLive) && (bets[nextKey].isMultiBet === payload.isMultiBet) && (bets[nextKey].marketId === payload.marketId) && (bets[nextKey].selectionId === payload.selectionId)) {
    //         flag = true;
    //         break;
    //       }
    //     }
    //   }
    // }

    return flag;
  }

  getGameTime = (game) => {
    const end_markets = [534];
    const next_markets = [535, 537, 538];
    let hasEndMarkets = false;
    let hasNextMarkets = false;

    game?.odds?.forEach((odd) => {
      if (end_markets.includes(odd.m)) {
        hasEndMarkets = true;
      }
      if (next_markets.includes(odd.m)) {
        hasNextMarkets = true;
      }
    })

    let gameEndTime = new Date(game?.endTime);
    let gameStartTime = new Date(game?.startTime);
    let gameTime = new Date(game?.startTime);
    const timeDiff = Math.abs(gameEndTime - gameStartTime);
    const dayDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    
    if (hasEndMarkets) {
      if (dayDiff > 7) {
        gameTime = gameEndTime;
      } else {
        gameTime = new Date(game?.nextBetStop);
      }
    }

    if (hasNextMarkets) {
      gameTime = new Date(game?.nextBetStop);
    }

    return gameTime;
  };

  renderTrOutrights = () => {
    const { game } = this.props;
    let sortedOdds = game.odds.sort((a, b) => a.ord - b.ord);
    if (game?.gameid===96585) {
      sortedOdds = game.odds.sort((a, b) => Number(a.p) - Number(b.p));
    }
    return (
      <div id={`scroll_tab_index_${game?.leagueID}_${game?.gameid}`}>
        <div className="outright-grid-title">
          <div className="color-team font-title-outright text-wrap font-bold-2 spacer-left-list">
            {game.game_title}
          </div>
          <div
            className="color-time font-time vertical-center font-bold spacer-left-list"
            style={{ paddingTop: "13px" }}
          >
            <span>
              <Localize
                value={this.getGameTime(game)}
                dateFormat="date.game1"
              />
            </span>
          </div>
        </div>
        <div className="outright-grid">
          {sortedOdds.map((odd, indx) => {
            return (
              // <div className={`outright-grid-btn ${this.checkSelectedOdd(odd, indx)?"selected-odds-left":""}`} onClick={()=>this.addBet(odd, indx)}>
              <div className="outright-grid-btn" key={indx}>
                <div
                  className={`outright-grid-icon ${
                    this.checkSelectedOdd(odd, indx) && !odd.nr
                      ? "outright-icon-bg-selected color-main"
                      : "outright-icon-bg color-contrast"
                  }  vertical-center content-center`}
                >
                  <span
                    style={{ marginTop: "1em", marginRight: "0.6em" }}
                    className={` ${!odd.nr ? "" : "red"}`}
                  >
                    {odd.ord}
                  </span>
                </div>
                {/* <div className="outright-grid-detail"> */}
                <div
                  className={`${
                    this.checkSelectedOdd(odd, indx) && !odd.nr
                      ? "outright-grid-detail-selected"
                      : "outright-grid-detail"
                  }`}
                  onClick={!odd.nr ? () => this.addBet(odd, indx) : () => {}}
                >
                  <div
                    className={`vertical-center bold spacer-left content-uppercase ${
                      this.checkSelectedOdd(odd, indx) && !odd.nr
                        ? "color-tr"
                        : "color-contrast"
                    } ${!odd.nr ? "" : "nonrunner"}`}
                  >
                    {odd.hn}
                  </div>
                  <div
                    className={`outright-divider-odd font-xl font-bold-2 vertical-center content-right spacer-right ${
                      this.checkSelectedOdd(odd, indx) ? "" : ""
                    } ${!odd.nr ? "" : "nonrunner"} ${
                      odd?.priceChange === ">"
                        ? "odds-raise"
                        : odd?.priceChange === "<"
                        ? "odds-drop"
                        : odd?.priceChange === "="
                        ? ""
                        : ""
                    }`}
                  >
                    {odd.p}
                  </div>
                  <div className={`vertical-center spacer-left`}>
                    <span className={`${!odd.nr ? "" : "nonrunner"}`}>
                      {odd.dn}
                    </span>
                  </div>
                  <div></div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  renderBrOutrigts = () => {
    const { game, lable } = this.props;
    let sortedOdds = game.odds.sort((a, b) => a.p - b.p);
    let title = game.game_title;

    return (
      <div id={`scroll_tab_index_${game?.leagueID}_${game?.gameid}`}>
        <div className="outright-grid-title">
          {/* <div className='color-team font-title-outright text-wrap font-bold-2 spacer-left-list'>{title}</div> */}
          <div className="color-team font-title-outright font-bold-2 spacer-left-list">
            {title}
          </div>
          <div
            className="color-time font-time vertical-center font-bold spacer-left-list"
            style={{ paddingTop: "13px" }}
          >
            <span>
              <Localize
                value={this.getGameTime(game)}
                dateFormat="date.game1"
              />
            </span>
          </div>
        </div>
        <div className="outright-grid">
          {sortedOdds.map((odd, indx) => {
            return (
              <div key={indx}>
                {odd.iA == 1 ? (
                  <div
                    className={`${
                      this.checkSelectedOdd(odd, indx) ? "selected-odds" : ""
                    } price-border font-bold flex-end cursor-pointer font-xl font-bold-2 spacer-right odds-btn-outright border-odds-right border-odds-left ${
                      odd?.priceChange === ">"
                        ? "odds-raise"
                        : odd?.priceChange === "<"
                        ? "odds-drop"
                        : odd?.priceChange === "="
                        ? ""
                        : ""
                    }`}
                    onClick={() => this.addBet(odd, indx)}
                  >
                    <h6
                      style={{
                        textAlign: "left",
                        padding: "0.8em",
                        color: "var(--grey)",
                      }}
                    >
                      {odd.sn}
                    </h6>
                    <OddsFormat price={odd.p} isActive={odd.iA} />
                  </div>
                ) : (
                  <div
                    className={`price-border font-bold flex-end cursor-pointer font-xl font-bold-2 spacer-right odds-btn-outright border-odds-right border-odds-left ${
                      odd?.priceChange === ">"
                        ? "odds-raise"
                        : odd?.priceChange === "<"
                        ? "odds-drop"
                        : odd?.priceChange === "="
                        ? ""
                        : ""
                    }`}
                  >
                    <h6
                      style={{
                        textAlign: "left",
                        padding: "0.8em",
                        color: "var(--grey-5)",
                      }}
                    >
                      {odd.sn}
                    </h6>
                    <OddsFormat price={odd.p} isActive={odd.iA} />
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  render() {
    log(this.constructor.name, "render");
    const { game, sportCode } = this.props;
    if (!game) return <div>no game</div>;
    return (
      <div>
        {sportCode === "TR"
          ? this.renderTrOutrights()
          : this.renderBrOutrigts()}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    searchQuery: ownProps?.searchQuery,
    game: ownProps.game,
    bets:
      state.slips && state.slips[state.selected.slipTemplate].settings?.bets
        ? Object.values(state.slips[state.selected.slipTemplate].settings.bets)
        : null,
    selectedSlipTemplate: state.selected.slipTemplate,
    marketSetKey: ownProps.marketSetKey,
    url: state.settings.url,
    sportCode: ownProps.sportCode,
    lable: ownProps.lable,
    maxSelections:
      state.slips && state.slips[state.selected.slipTemplate].settings
        ? state.slips &&
          state.slips[state.selected.slipTemplate].settings?.maxSelections
        : 15,
  };
};

export default connect(mapStateToProps, {
  saveBet,
  deleteBet,
  toggleMyTicketsPanel,
  updateOddsInLeague,
  getOddsHistory,
})(withStyles(styles)(OutrightGameBox));
