/**
 * Component for game box detail avatar svg
 */

import { log } from '../../config/log';
import React from 'react';
import { connect } from 'react-redux';



class GameBoxDetailedAvatarLongBB extends React.PureComponent {

  renderShirt() {
    const { pattern, color1, color2 } = this.props;

    switch (pattern) {

      case "1":
        return (
          <div>
            <svg className="team-avatar" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="3em" height="3em"
              viewBox="0 0 520.7 565.9" style={{ enableBackground: "0 0 520.7 565.9" }} xmlSpace="preserve">
              <path fill={color1} d="M498,348.1c-15.1-176.8-48.2-260-54.6-269.3C436,68,430.4,65.2,429.1,64.6c0-0.1,0-0.1,0-0.1
                L317.8,18.8c0,0-18.8,14.8-55.8,13.5C225.1,33.6,206,18.8,206,18.8L94.7,64.5c0,0-6.1,1.9-14.7,14.3c-6.4,9.3-39.5,92.4-54.6,269.3
                c0,0-13.6,182.4-13.6,182.4c28.9,18.2,59.2,6.8,59.2,6.8L101,330.8l11.8-82.6c0.9,15.4,1.4,29.9,1.4,42.3
                c0,76.3-19.4,244.4-19.4,244.4c79.4,20.1,167.2,20.5,167.2,20.5s87.8-0.3,167.2-20.5c0,0-19.4-168.1-19.4-244.4
                c0-12.1,0.5-26,1.3-40.8l11.6,81.1l29.9,206.5c0,0,30.3,11.4,59.2-6.8C511.6,530.5,498,348.1,498,348.1z"/>
              <path fill={color2} d="M111.5,165.3l3.7,67.1c0,0-3.2,18.9-1.6,35.3c2.8,28.3-4.1,113.8-4.1,113.8s69.8,12.4,151.6,12.4
                c75.2,0,153.2-11.9,153.2-11.9s-6.1-83.8-4.3-111.8c0.4-5.4,0.8-13.1,1.2-21.9c0.2-5.2-2.5-10-2.3-15.7c1.4-31.2,3.4-67.3,3.4-67.3
                S300.4,163,263.2,163C225.2,163,111.5,165.3,111.5,165.3z M315.9,339.8h-29.7l-38.2-75v75h-29.6V203.2h29.4l38.4,75.5v-75.5h29.7
                V339.8z"/>
              <path fill={'#00000060'} d="M429.1,64.5l-93-38.3c-5.8,17.6-17.3,33.5-10.9,27.5c8.9-8.4,18-12.8,30.3-10.5
                c4.7,0.9,9.8,2.5,14.7,4.6c7.8,3,15.3,6.9,22.6,11c7.5,4.2,16,9.2,20.1,17.2c5.6,11,5.1,24.9,4.6,36.8c-0.6,13.1-2.4,25.9-4.2,38.9
                c-0.8,5.6-2.1,17.3-10.6,16.7c-6.9-0.5-10.5-7.7-13-13.3c-8.3-18.5-9.1-21.1-16-40.1c6.4,23.6,8.6,30.2,14.9,53.8
                c2.4,9.1,5.1,18.3,6,27.8c0.5,5.1,1.3,12.1-2.8,16c-3.8,3.6-10.6-0.6-13.5-3.4c-7.4-7-12-16.5-16.4-25.5
                c-8.2-16.8-18.4-32.5-27.6-48.7c16.9,29.9,29.9,66.2,42.2,98.4c14.7,38.6,21.5,79.3,26.6,120.1c5.4,42.7,21,113.2,0.2,153.9
                c-10.3,20.2-11.4,17.7-39.5,32.2c-12.7,6.6-50.2,12.3-68,14.5c0.2,0.2,18.7-1.6,18.8-1.3c32-2.6,74.3-7.7,114.4-17.8
                c0,0-19.4-168.1-19.4-244.4C409.7,214.3,429.1,64.5,429.1,64.5z"/>
              <path fill={'#00000060'} d="M143.7,527.8c-10.7-8.6-18.1-20.6-20.7-34.1
                c-6.6-35.2,7.6-69.9,16-103.6c5.2-20.7,9.8-41.6,16.3-62c6.1-19.2,24.1-62.8,26.4-82.9c-1.4,11.8-21.5,49.4-27.7,59.7
                c-4.5,7.3-14.8,26.1-26,21.1c-7.6-3.3-2.6-20.7-1.8-26.3c4.7-34.2,15.6-65.9,27.3-98.4c13.7-38.3,24.3-53.9,33.4-82
                c-10.4,14.8-19.6,30.7-28.2,46.6c-7.1,13-13.9,28.7-27.8,35.7c-0.9,0.5-2,0.1-2.3-0.9c-4.3-15.4,3.2-32.5,6.9-47.4
                c4.7-18.6,10.2-37,13.6-55.9c-2.7,15.1-12.2,29.3-17.9,43.4c-4.1,10.1-12.7,10.7-14.7,0.4c-14.9-72.8-11.4-74.5,15-85.9
                c16.6-7.2,40.9-23.8,58.6-12c8.3,5.5,4.1-3.6,0-18.1L94.7,64.5c0,0,19.4,149.8,19.4,226c0,76.3-19.4,244.4-19.4,244.4
                c45.3,11.5,93.4,16.5,126.3,18.7c-9.7-1.7-19.3-3.9-28.8-6.5C175.9,542.9,157.1,538.6,143.7,527.8z"/>
              <path fill={'#00000050'} d="M330.9,30.6l-135.6-0.5c-3,2.3-7,13.4,12.2,37.4c12.3,15.4,34.8,30.8,54.9,34.9
                c20.1-4.2,45.7-20.7,57.7-36.3C335,46.7,333.9,32.9,330.9,30.6z"/>
              <path fill={'#b3b3b3'} d="M338.7,24.7c-9-4.3-17.2-7.1-26.1-6.1c-0.5,0.1-34,4.4-49.5,4.4c-15.7,0-49-4.3-49.5-4.4
                c-8.9-1-17,1.7-26.1,6.1c-0.3,0.2-15.6,8.6,5.8,42.9c8.7,14,31.9,39.2,69.7,47.5c37.9-8.3,61-33.5,69.7-47.5
                C354.3,33.3,339,24.9,338.7,24.7z M315.6,63.2c-12,15.6-32.3,30.2-52.5,34.3c-20.1-4.2-40.5-18.8-52.5-34.3
                c-9.2-12-13.4-23.1-14.6-30.3c10.8,2.3,32.2,7.1,67.1,7.1c34.4,0,56.1-4.6,67-7C329,40.3,324.8,51.3,315.6,63.2z"/>
            </svg>
          </div>
        );

      default:
        return (
          <div>no pattern</div>
        );
    }


  }

  render() {
    log(this.constructor.name, 'render');
    return <div>{this.renderShirt()}</div>
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    pattern: ownProps.pattern,
    color1: ownProps.color1,
    color2: ownProps.color2
  };
};

export default connect(mapStateToProps)(GameBoxDetailedAvatarLongBB);
