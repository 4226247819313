// Tickets paging component
import * as React from "react";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { useSelector, useDispatch } from "react-redux";
import { getTickets } from "../../actions";
import PropTypes from "prop-types";

const TicketsPagination = ({ startDate, endDate }) => {
  const [page, setPage] = React.useState(1);
  const dispatch = useDispatch();

  const count = useSelector((state) => state.tickets.count);
  const ticketsLimit = useSelector((state) => state.tickets.limit);
  const ticketListStatus = useSelector(
    (state) => state.settings.ticketListStatus
  );

  const pagesCount = Math.ceil(count / ticketsLimit);

  const handleChange = (event, value) => {
    const start = (value - 1) * ticketsLimit;
    setPage(value);
    dispatch(
      getTickets(
        startDate,
        endDate,
        ticketListStatus,
        "TicketsPagination",
        start,
        ticketsLimit
      )
    );
  };

  React.useEffect(() => {
    setPage(1);
  }, [ticketListStatus])

  return (
    <Stack
      alignItems="center"
      sx={{
        display: pagesCount && pagesCount > 1 ? "flex" : "none",
      }}
      py={3}
    >
      <Pagination
        page={page}
        onChange={handleChange}
        count={pagesCount}
        variant="outlined"
        color="primary"
      />
    </Stack>
  );
};

TicketsPagination.propTypes = {
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
};

export default TicketsPagination;
