/**
 * Reducer for Tickets
 */

import { Translate } from "react-redux-i18n";

import {
  SAVE_TICKETS,
  SAVE_TICKET,
  UPDATE_ODDS_IN_TICKET,
  UPDATE_SCORES_IN_TICKET,
  POST_TICKET_CASHOUT,
  IS_TICKETS_LOADING,
  GET_TICKET_GAMES,
  IS_TICKET_GAMES_LOADING,
  POST_TICKET_LOADING,
  POST_TICKET,
  REMOVE_POSTED_TICKET_STATE,
} from "../actions/types";

import {
  calcTennisStateInfo,
  calcGameTime,
  calcScoreSet,
  randomHexCode,
  updateScore,
} from "../helpers/gameFunctions";

let INITIAL_STATE = {
  isTicketsLoading: false,
  limit: 20,
  selectedTicketGames: [],
  isSelectedGamesLoading: false,
  isPostTicketLoading: false,
  isTicketPosted: false,
  postedTicket: {},
};

function jsonCopy(src) {
  return JSON.parse(JSON.stringify(src));
}

const updateCashOutPrice = (bet, odds) => {
  let targets = odds.filter(
    (obj) =>
      obj.gId === bet.gameId &&
      obj.m === bet.marketId &&
      obj.hc === bet.hc &&
      obj.s === bet.selectionId
  );
  if (targets?.length) {
    const target = targets[0];
    // bet.currentPrice = target.price;
    // bet.price = target.price;

    bet.currentPrice = jsonCopy(bet.price);
    bet.price = jsonCopy(target.p);
  }

  return bet;
};

const fn = (state = INITIAL_STATE, action) => {
  // let newState = {};
  let tickets = [];
  let stateCopy;
  switch (action.type) {
    case GET_TICKET_GAMES:
      stateCopy = { ...state };
      stateCopy["selectedTicketGames"] = action.payload;
      return stateCopy;
    case IS_TICKET_GAMES_LOADING:
      return { ...state, isSelectedGamesLoading: action.payload };
    case SAVE_TICKETS:
      tickets = action.payload;
      let winLabel = "";
      let winValue = "";
      let chipColor = "";
      let chipLabel = "";
      let betTypeLabel = "";
      let betsCount = null;
      let combiTypes = "";
      let arrCombiTypes = [];
      let arrCombiTypesAfterBanks = [];

      tickets?.forEach((ticket) => {
        switch (Number(ticket.gameEngineID)) {
          case 1:
            betTypeLabel = <Translate value="labels.bett_single" />;
            break;

          case 2:
            betTypeLabel = (
              <span>
                <Translate value="labels.bett_comb" /> {ticket.betsCount}
              </span>
            );
            break;

          case 3:
            betTypeLabel = "";

            if (ticket.totalBanks > 0)
              betTypeLabel += ticket.totalBanks + "B + ";

            betsCount = ticket.betsCount - ticket.totalBanks;
            arrCombiTypes = ticket.combiTypes?.split(",");
            arrCombiTypesAfterBanks = [];
            arrCombiTypes?.forEach((item, index) => {
              let key = Number(item) - ticket.totalBanks;
              if (key > 0) arrCombiTypesAfterBanks.push(key);
            });
            combiTypes = arrCombiTypesAfterBanks.join();

            betTypeLabel = (
              <span>
                {betTypeLabel}
                {combiTypes} <Translate value="labels.bett_of" /> {betsCount} ={" "}
                {ticket.combiCount} <Translate value="labels.combinations" />
              </span>
            );
            // betTypeLabel = ticket.specialLabel === 'multibet' ? <span><Translate value="labels.bett_comb_row" /> ({ticket.combiCount} <Translate value="labels.bett_row" />)</span> : betTypeLabel;
            // betTypeLabel = ticket.specialLabel === 'multibet' ? <span><Translate value="labels.bett_comb_row" /></span> : betTypeLabel;

            break;

          default:
            break;
        }

        ticket["betTypeLabel"] = betTypeLabel;

        switch (ticket.status) {
          case "pending":
            winLabel = <Translate value="labels.ticket_win_chance" />;
            winValue = ticket.potentialWin;
            chipColor = "";
            chipLabel = "";
            break;

          case "paid":
            if (ticket.cashedOut === 1) {
              winLabel = <Translate value="tickets.win_cashout" />;
              winValue = ticket.won;
              chipColor = "var(--green)";
              chipLabel = <Translate value="tickets.paid_cashout" />;
            } else {
              winLabel = <Translate value="tickets.paid" />;
              winValue = ticket.won;
              chipColor = "var(--green)";
              chipLabel = <Translate value="tickets.paid" />;
            }
            break;

          case "lost":
            winLabel = "";
            winValue = "";
            chipColor = "var(--red)";
            chipLabel = <Translate value="tickets.lost" />;
            break;

          case "cancelled":
            winLabel = "";
            winValue = "";
            chipColor = "var(--yellow-1)";
            chipLabel = <Translate value="tickets.cancelled" />;
            break;

          default:
            winLabel = "";
            winValue = "";
            chipColor = "";
            chipLabel = "";
            break;
        }

        ticket["winLabel"] = winLabel;
        ticket["winValue"] = winValue;
        ticket["chipColor"] = chipColor;
        ticket["chipLabel"] = chipLabel;
      });

      if (action.status === "pending") {
        return {
          ...state,
          list: action.payload,
          pendingList: tickets,
          count: action.count,
        };
      } else {
        return { ...state, list: action.payload, count: action.count };
      }
    // return action.payload;

    case SAVE_TICKET:
      tickets = action.payload.tickets;
      let type = action.payload.type;
      const ts = new Date().getTime();

      let stateInfo = null;

      // console.log(cashout)

      tickets?.forEach((ticket) => {
        ticket.bets?.forEach((bet) => {
          // game['ts'] = ts;
          // console.log(bet.game.gameid);
          bet.game["ts"] = ts;
          bet.game["score_set_calculated"] = calcScoreSet(
            bet.result,
            bet.game.state,
            bet.game.score_set,
            bet.game.sport_code
          );
          bet.game["time"] =
            bet.game.isLive === 1
              ? calcGameTime(
                  bet.game.state,
                  bet.game.eventTime,
                  bet.game.sport_code
                )
              : bet.game.startTime;
          bet.game["team1Color1"] = randomHexCode();
          bet.game["team1Color2"] = randomHexCode();
          bet.game["team2Color1"] = randomHexCode();
          bet.game["team2Color2"] = randomHexCode();

          switch (bet.game.sport_code) {
            case "FB":
              // stateInfo = calcFootballStateInfo(bet.game.danger_state, bet.game.var_state, false);
              // bet.game['stateIcon'] = stateInfo ? stateInfo.stateIcon : '';
              // bet.game['stateBackground'] = stateInfo ? stateInfo.stateBackground : '';
              // bet.game['stateText'] = stateInfo ? stateInfo.stateText : '';
              // bet.game['stateTeam'] = stateInfo ? stateInfo.stateTeam : '';
              break;
            case "TE":
              stateInfo = calcTennisStateInfo(bet.game.current_server);
              bet.game["stateIcon"] = stateInfo ? stateInfo.stateIcon : "";
              bet.game["stateBackground"] = "";
              bet.game["stateText"] = "";
              bet.game["stateTeam"] = "";
              break;
            default:
              bet.game["stateIcon"] = "";
              bet.game["stateBackground"] = "";
              bet.game["stateText"] = "";
              bet.game["stateTeam"] = "";
              break;
          }

          if (bet.game.sport_code === "TE") {
            let scoreSetsd = bet.game.score_set;
            scoreSetsd = scoreSetsd.split("|");
            const scoreData = scoreSetsd[scoreSetsd?.length - 1];
            const scoreHome = scoreData.split(":")[0];
            const scoreAway = scoreData.split(":")[1];

            bet.game["score_home"] =
              parseInt(scoreHome) === 50 ? "A" : scoreHome;
            bet.game["score_away"] =
              parseInt(scoreAway) === 50 ? "A" : scoreAway;
          }

          if (bet.scoreSet === "" && bet.game.state === "FT") {
            // need to update game score away and home from result;
            var resultArray = bet.result?.split(",");
            var target = resultArray[0];
            if (target) {
              var scoreSets = target.split("|");
              const scoreData = scoreSets[scoreSets?.length - 1];
              const scoreHome = scoreData.split(":")[0];
              const scoreAway = scoreData.split(":")[1];
              bet.game["score_home"] = scoreHome;
              bet.game["score_away"] = scoreAway;
            }
          }

          bet["basicPrice"] = bet.price;
        });
      });

      if (type === "calcCashout") {
        return { ...state, calcCashout: tickets };
      } else {
        return { ...state, selected: tickets };
      }

    case UPDATE_ODDS_IN_TICKET:
      let newState = { ...state };
      const odds = action.payload;
      if (newState.calcCashout) {
        newState.calcCashout?.forEach((ticket) => {
          ticket.bets?.forEach((bet) => {
            bet = updateCashOutPrice(bet, odds);
          });
        });
      }

      return newState;

    case IS_TICKETS_LOADING:
      return { ...state, isTicketsLoading: action.payload };

    case UPDATE_SCORES_IN_TICKET:
      let newState1 = { ...state };
      const newScores = action.payload;

      if (newState1.selected) {
        newState1.selected?.forEach((ticket) => {
          ticket.bets?.forEach((bet) => {
            updateScore(bet.game, newScores);
          });
        });
      }

      // console.log(newState1);
      return newState1;

    case POST_TICKET_CASHOUT:
      let newState2 = { ...state };

      const { payload, ticketID } = action;
      if (
        payload.type === "success" &&
        payload.payload === "cashout_successful"
      ) {
        let { calcCashout, list } = newState2;

        //remove from calcCashout
        for (let key1 = calcCashout?.length - 1; key1 > -1; key1--) {
          const item = calcCashout[key1];
          const meta = item.meta;
          if (meta.ticketID === ticketID) {
            calcCashout.splice(key1, 1);
            item["isConfirming"] = false;
          }
        }

        //remove from list

        for (let key2 = list?.length - 1; key2 > -1; key2--) {
          const item = list[key2];
          if (item.ticketID === ticketID) {
            list.splice(key2, 1);
            item["isConfirming"] = false;
          }
        }
      } else {
        if (payload.payload === "initialState") {
          let { list } = newState2;

          //add false flag to target calcCashout
          for (let key3 = list?.length - 1; key3 > -1; key3--) {
            let item = list[key3];
            if (item.ticketID === ticketID) {
              delete item["isConfirming"];
            }
          }
        } else {
          let { list } = newState2;

          //add false flag to target calcCashout
          for (let key3 = list?.length - 1; key3 > -1; key3--) {
            let item = list[key3];
            if (item.ticketID === ticketID) {
              item["isConfirming"] = false;
            }
          }
        }
      }

      return newState2;
    case POST_TICKET_LOADING:
      stateCopy = { ...state };
      stateCopy.isPostTicketLoading = action.payload;
      return stateCopy;
    case POST_TICKET:
      stateCopy = { ...state };
      stateCopy.isTicketPosted = true;
      stateCopy.postedTicket = action.payload;
      return stateCopy;
    case REMOVE_POSTED_TICKET_STATE:
      stateCopy = { ...state };
      stateCopy.isTicketPosted = false;
      stateCopy.postedTicket = {};
      return stateCopy;
    default:
      return state;
  }
};

export default fn;
