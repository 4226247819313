/**
 * Action type values for Actions -> Reducers
 */

export const SET_LEAGUES = "SET_LEAGUES";
export const GET_SPORTS_LABELS = "GET_SPORTS_LABELS";

export const SELECT_LEAGUES = "SELECT_LEAGUES";
export const UPDATE_SELECTED_LEAGUE = "UPDATE_SELECTED_LEAGUE";
export const SAVE_LEAGUE_RESULTS = "SAVE_LEAGUE_RESULTS";
export const DELETE_SELECTED_LEAGUES = "DELETE_SELECTED_LEAGUES";
export const SAVE_FAVORITE_LEAGUES = "SAVE_FAVORITE_LEAGUES";
export const SAVE_TREEVIEW_LEAGUES = "SAVE_TREEVIEW_LEAGUES";
export const SET_LEAGUE_MENU_VALUE = "SET_LEAGUE_MENU_VALUE";

export const SAVE_GAMES = "SAVE_GAMES";
export const SAVE_OUTRIGHT_GAMES = "SAVE_OUTRIGHT_GAMES";
export const SAVE_GAME_ALL_ODDS = "SAVE_GAME_ALL_ODDS";

export const GET_MARKET_LABELS = "GET_MARKET_LABELS";
export const GET_MARKET_SPECIFIERS = "GET_MARKET_SPECIFIERS";
export const SAVE_MARKET_SET_CONFIG = "SAVE_MARKET_SET_CONFIG";

export const GET_SELECTION_LABELS = "GET_SELECTION_LABELS";
export const GET_SELECTION_VIEWER_LABELS = "GET_SELECTION_VIEWER_LABELS";
export const GET_SELECTION_PRINT_LABELS = "GET_SELECTION_PRINT_LABELS";
export const GET_HC_LABELS = "GET_HC_LABELS";

export const UPDATE_SCORES_IN_LEAGUE = "UPDATE_SCORES_IN_LEAGUE";
export const UPDATE_ODDS_IN_LEAGUE = "UPDATE_ODDS_IN_LEAGUE";
export const UPDATE_ODDS_IN_SLIP = "UPDATE_ODDS_IN_SLIP";
export const UPDATE_ODDS_IN_TICKET = "UPDATE_ODDS_IN_TICKET";
export const UPDATE_SCORES_IN_TICKET = "UPDATE_SCORES_IN_TICKET";
export const UPDATE_BET_WITH_LOWER_PRICE = "UPDATE_BET_WITH_LOWER_PRICE";
export const SET_ODDS_FORMAT = "SET_ODDS_FORMAT";
export const EXPAND_MULTI_LINE_ODDS = "EXPAND_MULTI_LINE_ODDS";
export const COLLAPSE_MULTI_LINE_ODDS = "COLLAPSE_MULTI_LINE_ODDS";
export const SAVE_ODDS_HISTORY = "SAVE_ODDS_HISTORY";

export const SELECT_SLIP_TEMPLATE = "SELECT_SLIP_TEMPLATE";
export const SAVE_SLIP_TEMPLATE = "SAVE_SLIP_TEMPLATE";
export const CREATE_SLIP_TEMPLATE = "CREATE_SLIP_TEMPLATE";
export const DELETE_SLIP_TEMPLATE = "DELETE_SLIP_TEMPLATE";
export const COPY_SLIP_TEMPLATE = "COPY_SLIP_TEMPLATE";
export const IMPORT_SLIP_TEMPLATE = "IMPORT_SLIP_TEMPLATE";
export const EXPORT_SLIP_TEMPLATE = "EXPORT_SLIP_TEMPLATE";
export const SAVE_BET = "SAVE_BET";
export const DELETE_BET = "DELETE_BET";
export const GET_SAVED_SLIP = "GET_SAVED_SLIP";

export const SAVE_TICKETS = "SAVE_TICKETS";
export const SAVE_TICKET = "SAVE_TICKET";
export const POST_TICKET = "POST_TICKET";
export const REMOVE_POSTED_TICKET_STATE = "REMOVE_POSTED_TICKET_STATE";
export const POST_TICKET_LOADING = "POST_TICKET_LOADING";
export const POST_TICKET_CASHOUT = "POST_TICKET_CASHOUT";
export const SAVE_TICKET_FILTER = "SAVE_TICKET_FILTER";
export const TOGGLE_MY_TICKETS_PANEL = "TOGGLE_MY_TICKETS_PANEL";
export const GET_CASHOUT_AVAILABILITY = "GET_CASHOUT_AVAILABILITY";

export const SET_THEME = "SET_THEME";
export const SET_LAYOUT = "SET_LAYOUT";
export const SET_MODE = "SET_MODE";
export const SET_NAV_MODE = "SET_NAV_MODE";
export const SET_MARKETS_LAYOUT = "SET_MARKETS_LAYOUT";
export const SET_FAVORITES_LAYOUT = "SET_FAVORITES_LAYOUT";
export const SET_URL = "SET_URL";
export const SET_HOST = "SET_HOST";
export const SET_IS_MOBILE = "SET_IS_MOBILE";
export const SET_SSE = "SET_SSE";
export const SET_LEAGUE_GROUPING = "SET_LEAGUE_GROUPING";
export const SHOW_RESULTS_FIRST = "SHOW_RESULTS_FIRST";

export const SAVE_CASINO_FILTER = "SAVE_CASINO_FILTER";
export const SAVE_VENDORS = "SAVE_VENDORS";

export const GET_USER_DETAILS = "GET_USER_DETAILS";
export const GET_USER_BONUSES = "GET_USER_BONUSES";
export const GET_BALANCE = "GET_BALANCE";
export const GET_BALANCE_DETAILS = "GET_BALANCE_DETAILS";
export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_USER_WITH_TRUSTLY = "REGISTER_USER_WITH_TRUSTLY";
export const CHECK_TRUSTLY_DEPOSIT_STATUS = "CHECK_TRUSTLY_DEPOSIT_STATUS";
export const SIGN_IN_USER_WITH_TRUSTLY_TOKEN =
  "SIGN_IN_USER_WITH_TRUSTLY_TOKEN";
export const RESET_REGISTER_USER = "RESET_REGISTER_USER";
export const UPDATE_USER = "UPDATE_USER";
export const BLOCK_USER = "BLOCK_USER";
export const UPDATE_PASSWORD = "UPDATE_PASSWORD";
export const GET_FORGOTTEN_PASSWORD = "GET_FORGOTTEN_PASSWORD";
export const GET_SUBSCRIPTIONS = "GET_SUBSCRIPTIONS";
export const UPDATE_SUBSCRIPTIONS = "UPDATE_SUBSCRIPTIONS";
export const SET_KYC_STATUS = "SET_KYC_STATUS";
export const SET_VALID_INSTRUMENT = "SET_VALID_INSTRUMENT";
export const SET_PENDING_CASHOUTS = "SET_PENDING_CASHOUTS";
export const GET_KYC_DETAILS = "GET_KYC_DETAILS";
export const UPLOAD_KYC_DOCUMENTS = "UPLOAD_KYC_DOCUMENTS";
export const GET_LIMITS = "GET_LIMITS";
export const POST_WALLET_DEPOSIT = "POST_WALLET_DEPOSIT";
export const RESET_WALLET_STATEMENT = "RESET_WALLET_STATEMENT";
export const GET_WALLET_STATEMENT = "GET_WALLET_STATEMENT";
export const LOAD_MORE_WALLET_STATEMENT = "LOAD_MORE_WALLET_STATEMENT";

export const POST_WITHDRAW = "POST_WITHDRAW";

export const SIGN_IN_SUCCESS = "SIGN_IN_SUCCESS";
export const SIGN_IN_FAIL = "SIGN_IN_FAIL";
export const SIGN_IN_FAIL_BLOCKED_USER = "SIGN_IN_FAIL_BLOCKED_USER";
export const SIGN_OUT = "SIGN_OUT";
export const SIGN_IN_NOT_CONFIRMED_EMAIL = "SIGN_IN_NOT_CONFIRMED_EMAIL";
export const CONFIRM_EMAIL_ADDRESS = "CONFIRM_EMAIL_ADDRESS";
export const RESEND_CONFIRM_EMAIL_ADDRESS = "RESEND_CONFIRM_EMAIL_ADDRESS";
export const REFRESH_USER_SESSION = "REFRESH_USER_SESSION";

export const SET_ALERT = "SET_ALERT";
export const CLEAR_ALERT = "CLEAR_ALERT";

export const SAVE_CASINO_GAMES = "SAVE_CASINO_GAMES";
export const FILTER_CASINO_GAMES = "FILTER_CASINO_GAMES";
export const SELECT_CASINO_GAME = "SELECT_CASINO_GAME";
export const DELETE_CASINO_GAME = "DELETE_CASINO_GAME";
export const SAVE_FAVORITE_CASINO_GAMES = "SAVE_FAVORITE_CASINO_GAMES";
export const GET_CASINO_GAME_GROUP_NAMES = "GET_CASINO_GAME_GROUP_NAMES";
export const SEARCH_CASINO_GAMES = "SEARCH_CASINO_GAMES";
export const CLEAR_CASINO_SEARCH_RESULTS = "CLEAR_CASINO_SEARCH_RESULTS";
export const GET_CASINO_GAMES_BY_GROUP_NAME = "GET_CASINO_GAMES_BY_GROUP_NAME";
export const GET_USER_FAVORITE_CASINO_GAMES = "GET_USER_FAVORITE_CASINO_GAMES";
export const GET_USER_DEPOSIT_ALLOWED = "GET_USER_DEPOSIT_ALLOWED";
export const CASINO_FREE_SPINS_LOADING = "CASINO_FREE_SPINS_LOADING";

export const UPDATE_STAKE = "UPDATE_STAKE";
export const UPDATE_LEAGUE_FILTER = "UPDATE_LEAGUE_FILTER";

export const EMPTY_SLIP_TEMPLATE = "EMPTY_SLIP_TEMPLATE";

export const GET_ACCOUNT_LIVECHECK = "GET_ACCOUNT_LIVECHECK";

export const SAVE_AUTO_SLIP_UPDATE_STATUS = "SAVE_AUTO_SLIP_UPDATE_STATUS";

export const SET_MAINTENANCE_MODE = "SET_MAINTENANCE_MODE";

export const UPDATE_OUTRIGHT_LEAGUE_ODDS = "UPDATE_OUTRIGHT_LEAGUE_ODDS";

export const DELETE_ENDED_SLIP_GAMES = "DELETE_ENDED_SLIP_GAMES";

export const GET_WP_CONTENT = "GET_WP_CONTENT";
export const GET_WP_NEWS_CONTENT = "GET_WP_NEWS_CONTENT";

export const GET_FEATURED_GAMES = "GET_FEATURED_GAMES";
export const GET_FEATURED_OUTRIGHTS = "GET_FEATURED_OUTRIGHTS";
export const GET_FEATURED_SLIP = "GET_FEATURED_SLIP";
export const ADD_FEATURED_TO_SLIP = "GET_FEATURED_SLIP";
export const GET_PRECANNED_BETS = "GET_PRECANNED_BETS";
export const FEATURED_GAMES_LOADING = "FEATURED_GAMES_LOADING";
export const FEATURED_OUTRIGHTS_LOADING = "FEATURED_OUTRIGHTS_LOADING";
export const FEATURED_SLIPS_LOADING = "FEATURED_SLIPS_LOADING";

export const UPDATE_FEATURED_GAMES = "UPDATE_FEATURED_GAMES";
export const UPDATE_FEATURED_OUTRIGHTS = "UPDATE_FEATURED_OUTRIGHTS";
export const UPDATE_FEATURED_SLIP = "UPDATE_FEATURED_SLIP";

export const REMOVE_ENDED_FEATURED_GAME = "REMOVE_ENDED_FEATURED_GAME";
export const REMOVE_ENDED_FEATURED_OUTRIGHT = "REMOVE_ENDED_FEATURED_OUTRIGHT";
export const REMOVE_ENDED_FEATURED_SLIP = "REMOVE_ENDED_FEATURED_SLIP";

export const REMOVE_ENDED_LEAGUE_GAMES = "REMOVE_ENDED_LEAGUE_GAMES";
export const REMOVE_ENDED_OUTRIGHT_GAMES = "REMOVE_ENDED_OUTRIGHT_GAMES";
export const SAVE_BET_BUILDER_RESPONSE = "SAVE_BET_BUILDER_RESPONSE";
export const GET_CUSTOMER_FREE_SPINS = "GET_CUSTOMER_FREE_SPINS";

export const SAVE_BETBUILDER_GAMES = "SAVE_BETBUILDER_GAMES";
export const SELECT_BETBUILDER_LEAGUES = "SELECT_BETBUILDER_LEAGUES";
export const SHOULD_CONFIRM_TERMS = "SHOULD_CONFIRM_TERMS";
export const CASINO_GAME_LOADING = "CASINO_GAME_LOADING";
export const CASINO_GAMES_LIST_LOADING = "CASINO_GAMES_LIST_LOADING";
export const RESET_CASINO_GAMES_CACHE = "RESET_CASINO_GAMES_CACHE";
export const SET_LIVECHAT_WIDGET = "SET_LIVECHAT_WIDGET";

export const UPDATE_LIMITS_STATUS = "UPDATE_LIMITS_STATUS";
export const UPDATE_BETBUILDER_GAMES = "UPDATE_BETBUILDER_GAMES";
export const SHOULD_ALERT_ODDS_UPDATE = "SHOULD_ALERT_ODDS_UPDATE";

export const IS_TICKETS_LOADING = "IS_TICKETS_LOADING";
export const GET_TICKET_GAMES = "GET_TICKET_GAMES";
export const IS_TICKET_GAMES_LOADING = "IS_TICKET_GAMES_LOADING";

export const HANDLE_LIMITS_ALERT = "HANDLE_LIMITS_ALERT";

export const GET_PLAYERPROPS_GATALOG_GAMES = "GET_PLAYERPROPS_GATALOG_GAMES";

export const SET_EVENTS_SEARCH_RESULT = "SET_EVENTS_SEARCH_RESULT";
export const SET_EVENT_SEARCH_LOADING = "SET_EVENT_SEARCH_LOADING";
export const DELETE_SEARCH_RESULTS = "DELETE_SEARCH_RESULTS";
export const SELECT_SEARCHED_LEAGUES = "SELECT_SEARCHED_LEAGUES";
export const GET_COMBO_BOOST = "GET_COMBO_BOOST";
export const SET_COMBO_BOOST = "SET_COMBO_BOOST";

export const GET_EXAMPLE_SEARCHES = "GET_EXAMPLE_SEARCHES";

// ---------- casino action types--------------//
export const GET_CASINO_SLIDER_GAMES = "GET_CASINO_SLIDER_GAMES";
export const GET_CASINO_GAMES_BY_FEATURE = "GET_CASINO_GAMES_BY_FEATURE";
export const RESET_FEATURE_GAMES = "RESET_FEATURE_GAMES";
export const GET_CASINO_GAMES_BY_PROVIDER = "GET_CASINO_GAMES_BY_PROVIDER";
export const RESET_CASINO_PROVIDER_GAMES = "RESET_CASINO_PROVIDER_GAMES";
export const GET_USER_FAV_GAMES_IDS = "GET_USER_FAV_GAMES_IDS";

// -------casino action types end--------------//
