import React, { Component } from "react";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";

class FooterExpand extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
    };
  }

  showButton = () => {
    this.setState({ expanded: !this.state.expanded });
  };

    render() {
        const { expanded } = this.state
        return (
            <Container>
                <div>
                    <h2 style={{ color: 'var(--color-contrast)' }}>NUBET - POHJOLAN TAIKAA</h2>
                    Oletko liian fiksu lottoamaan? <br></br><br></br>
                </div>
                {
                    expanded &&
                    <div>
                        Tämä on Nubet, meidän näkemyksemme mukaan yksi tyylikkäimmistä alan uusista vedonlyöntisivustoista. Täällä ei pelata lottoa, meille ja asiakkaillemme pelaaminen on ajanvietteen ohella myös älyllinen haaste. Tämä vaatii kuitenkin reiluja peliehtoja, ilman lottopelien kaltaista asiakkaiden aliarvioimista.<br></br><br></br>
                        Meidän ajatuksenamme on luoda tyylikäs vedonlyöntipalvelu, joka kestää vertailun yhtä hyvin pohjoismaiden kilpailluilla markkinoilla kuin muuallakin Euroopassa. Yritämme olla reiluja, luotettavia ja tyylikkäitä. Asiakkuutesi arvoinen pelipaikka.<br></br><br></br>
                        Nubet erottautuu markkinasta ottamalla vetoja oikeasti vastaan kunnollisilla panoksilla ja useilla omilla kertoimilla. On iso ero, toimiiko yritys whitelabelina huonoilla palautusprosenteilla, vai toimiiko se oikeasti kuten vedonvälittäjän kuuluisi toimia.<br></br><br></br>
                        Vuonna 2023 avattu Nubet ei varmastikaan ole heti paras versio itsestään, tarkoitus on kehittää palvelua jatkuvasti asiakkaiden tarpeet huomioiden. Kova kilpailu varmistaa, ettei tyytyväisyyteen ole varaa vaan tarjonnan ja palvelun käytettävyyden on parannuttava jatkuvasti. Tarjonta on silti ollut heti ensimmäisten kuukausien aikana laaja ja sitä onkin parannettu jatkuvasti. Tehtävää kuitenkin riittää. Työlistalla on mm. livevedonlyönnin tarjoaminen heti kun mahdollista.<br></br><br></br>
                        Kova kilpailu eurooppalaisilla lisenssimarkkinoilla vaatii palvelulta paljon. Pyrimme tarjoamaan kilpailukykyisiä kertoimia mahdollisimman paljon, eikä riskienhallinnan työkaluihimme kuulu se, että voittava asiakas savustetaan parin vedon jälkeen muualle pelaamaan. Reilu peli on kaiken lähtökohtana, sitä odotamme myös asiakkailta. Nubetin riskienhallinnan työkaluilla pyritään takaamaan asiallinen kohtelu kaikille asiakkaille.<br></br><br></br>
                        Mobiilipalveluun on satsattu kovasti. Tiedämme hyvin, että valtaosa peleistä kulkee nykyään tätä kautta. Pelaamisen helppous ja nopeus ovat kehitystyötämme ohjaavat tavoitteet. Sama koskee tietenkin myös rahansiirtoja. Sujuvan pelielämyksen lisäksi hyvä asiakaskokemus edellyttää toimivaa rahaliikennettä. Tässäkin Nubet luottaa markkinajohtajiin ja tekee yhteistyötä markkinajohtaja Trustlyn kanssa. Nubetilla asiakkaan rahat ovat turvassa, mutta myös nopeasti siirrettävissä omalle pankkitilille.<br></br><br></br>
                        Keskustelu pohjolan lisenssimarkkinoista käy kuumana. Toistaiseksi Nubet operoi Viron lisenssin alla, joka on meille Maltaa läheisempi ja vahvempi valinta. Tavoitteenamme on olla vuoteen 2028 mennessä vahvasti mukana pelimarkkinoilla Euroopasta Aasiaan ja Etelä-Amerikkaan. Nubetissa nimittäin on sitä jotain mitä pelurit sijainnistaan riippumatta ovat pitkään kaivanneet. Nubetissa on pohjolan taikaa ja se jos mikä toimii kaikkialla maailmassa.<br></br><br></br>
                    </div>
                }
                <Button color="primary" variant="outlined" style={{ padding: '0.2em 0.5em', fontSize: '0.8em' }} onClick={this.showButton}>{expanded ? 'Lue vähemmän' : 'Lue lisää'}</Button>

            </Container>
        )
    }
}

export default FooterExpand;
