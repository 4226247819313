/**
 * SettingsPanel component
 ** A drawer when clicking settings ( gear ) icon
 */

import { log } from "../config/log";
import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Translate } from "react-redux-i18n";
import withStyles from "@mui/styles/withStyles";
import clsx from "clsx";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import AdultIcon18 from "../media/img/18+.svg";
import AdultIcon21 from "../media/img/21+.svg";
import MenuIcon from "@mui/icons-material/Menu";
import ListItemIcon from "@mui/material/ListItemIcon";
import IconSPORT from "./events/icons/IconSPORT";
import IconCASINFO from "./events/icons/IconCASINFO";
import IconBETBUILDER from "./events/icons/IconBETBUILDERMENUBOTTOM";
import IconPLAYERPROPS from "./events/icons/IconPLAYERPROPS";
import IconABOUT from "./events/icons/IconABOUT";
import IconBONUS from "./events/icons/IconBONUS";
import LanguageSwitcher from "./LanguageSwitcher";
import ThemeSwitcher from "./ThemeSwitcher";

import { setLiveChatWidget } from "../actions";
import { fontSize } from "@mui/system";

const styles = (theme) => ({
  root: {
    "& .MuiListItemIcon-root": {
      minWidth: "30px",
    },
    "& .MuiListItem-root": {
      fontSize: "1rem",
      color: "var(--grey)",
    },
    list: {
      width: 250,
    },
    fullList: {
      width: "auto",
    },
  },
  avatar: {
    backgroundColor: "var(--color-contrast)",
    color: "white",
    width: theme.spacing(3),
    height: theme.spacing(3),
    fontSize: "0.6em",
    lineHeight: "3em",
  },
});

class SettingsPanel extends React.Component {
  constructor(props) {
    super(props);
    var today = new Date(),
      date = today.getDate() + "." + (today.getMonth() + 1) + "." + today.getFullYear();

    this.state = {
      top: false,
      left: false,
      bottom: false,
      right: false,
      currentDate: date,
    };
  }

  handleLeagueRoute = (anchor) => {
    const { leagues, lang } = this.props;
    this.toggleDrawer(anchor, false);
    this.props.setLeagues(leagues, "All", "FB", lang, this.constructor.name);
  };

  toggleDrawer = (anchor, open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }

    this.setState({ ...this.state, [anchor]: open });
  };

  renderList = (anchor) => {
    const { host, classes, locale, url } = this.props;
    if (!host) return null;

    return (
      <div
        className={clsx(classes.list, {
          [classes.fullList]: anchor === "top" || anchor === "bottom",
        })}
        role="presentation"
        onKeyDown={this.toggleDrawer(anchor, false)}
      >
        <div className={classes.root}>
          <List>
            <Link to={`/${locale}`} onClick={this.toggleDrawer(anchor, false)} key={1}>
              <ListItem button key="home" value="Meine Wetten">
                <ListItemIcon>
                  <IconSPORT />
                </ListItemIcon>
                <ListItemText primary={<Translate value="menu.betting" />} />
              </ListItem>
            </Link>

            <Link to={`/${locale}/betbuilder?selectedBetBuilderSportCode=FB`} onClick={this.toggleDrawer(anchor, false)} key={2}>
              <ListItem button key="betbuilder" value="Betbuilder">
                <ListItemIcon>
                  <IconBETBUILDER />
                </ListItemIcon>
                <ListItemText primary={<Translate value="marketSets.betbuilder" />} />
              </ListItem>
            </Link>
            <Link to={`/${locale}/player-props?selected-sport-code=FB`} onClick={this.toggleDrawer(anchor, false)} key={10}>
              <ListItem key="player-props" value="player-props">
                <ListItemIcon>
                  <IconPLAYERPROPS />
                </ListItemIcon>
                <ListItemText primary={<Translate value="marketSets.player" />} />
              </ListItem>
            </Link>

            <Link to={`/${locale}/casino/dashboard`} onClick={this.toggleDrawer(anchor, false)} key={3}>
              <ListItem button key="casino">
                <ListItemIcon>
                  <IconCASINFO />
                </ListItemIcon>
                <ListItemText primary={<Translate value="menu.casino" />} />
              </ListItem>
            </Link>
            {host.promoPage ? (
              <Link to={`/${locale}/promotions`} onClick={this.toggleDrawer(anchor, false)} key={4}>
                <ListItem button key="promotions">
                  <ListItemIcon>
                    <IconBONUS />
                  </ListItemIcon>
                  <ListItemText primary={<Translate value="menu.promotions" />} />
                </ListItem>
              </Link>
            ) : null}

            <Divider />
            <ListItem
              // component="a"
              // href="mailto:support@nubet.com"
              style={{ cursor: "pointer" }}
              key="customersupport"
              onClick={(e) => {
                this.props.setLiveChatWidget(this.constructor.name);
                const openDrawer = this.toggleDrawer(anchor, false);
                openDrawer(e);
              }}
            >
              <ListItemIcon>
                <IconABOUT />
              </ListItemIcon>
              <ListItemText primary={<Translate value="labels.customer_support" />} />
            </ListItem>
            <Divider />
            <ListItem>
              <LanguageSwitcher />
            </ListItem>
            <ListItem>
              <ThemeSwitcher />
            </ListItem>
            {/* <ListItem>
              <LayoutSwitcher />
            </ListItem> */}
            {/* {host.bet && this.props.layout === "mouse" ? (
              <ListItem>
                <MarketsLayoutSwitcher />
              </ListItem>
            ) : null} */}
            <Divider />
            <ListItem>
              <Grid container spacing={0}>
                <Grid item xs={12} style={{ textAlign: "center" }}>
                  {host.brand === "nubet_et" ? (
                    <img style={{ height: "3em" }} src={"https://api.nubet.com/media/icons/maksujatolliameti.png"} alt="licence-ee" />
                  ) : (
                    <img style={{ height: "3em" }} src={"https://api.nubet.com/media/icons/spelinspektionen.png"} alt="licence-se" />
                  )}
                </Grid>
                <Grid item xs={6} style={{ textAlign: "center" }}>
                  <Link to={`/${locale}/info/responsible-game`}>
                    <img style={{ height: "1.5em", margin: "0.5em 0 0 0.3em" }} src={AdultIcon18} alt="18+" />
                  </Link>
                </Grid>
                <Grid item xs={6} style={{ textAlign: "center" }}>
                  {host.brand === "nubet_et" ? (
                    <Link to={`/${locale}/info/responsible-game`}>
                      <img style={{ height: "1.5em", margin: "0.5em 0 0 0.3em" }} src={AdultIcon21} alt="21+" />
                    </Link>
                  ) : null}
                </Grid>
              </Grid>
            </ListItem>
            <Divider />
            <Grid
              item
              xs={12}
              sx={{
                width: "18em",
                margin: "1em",
                textAlign: "center",
                color: "var(--grey)",
                fontSize: "0.8rem",
                lineHeight: 1.1,
              }}
            >
              <Translate value="labels.attention_gambling" />
            </Grid>
            <Divider />
            <ListItem>
              <Grid container spacing={0}>
                <Grid item xs={6} className={"font-release-version"}>
                  Release v 4.084
                </Grid>
                <Grid item xs={6} className={"font-release-date"}>
                  04/10/24
                </Grid>
              </Grid>
            </ListItem>
          </List>
        </div>
      </div>
    );
  };

  render() {
    log(this.constructor.name, "render");
    const { classes, url } = this.props;

    return (
      <div className="grey-3 font-l vertical-center">
        <IconButton className={classes.menuButton} style={{ padding: "0 0 0 0" }} aria-label="open drawer" onClick={this.toggleDrawer("left", true)}>
          <MenuIcon color="primary" />
        </IconButton>
        <Drawer anchor="left" open={this.state["left"]} onClose={this.toggleDrawer("left", false)} elevation={0}>
          {this.renderList("left")}
        </Drawer>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    host: state.settings.host,
    layout: state.settings.layout,
    locale: state.i18n.locale,
    url: state.settings.url,
  };
};

export default connect(mapStateToProps, { setLiveChatWidget })(withStyles(styles)(SettingsPanel));
