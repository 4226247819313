/**
 * Component for game box detail avatar svg
 */

import { log } from '../../config/log';
import React from 'react';
import { connect } from 'react-redux';

import './GameBoxDetailedAvatar.css';

class GameBoxDetailedAvatarShort extends React.PureComponent {

  renderShirt() {
    const { pattern, color1, color2 } = this.props;

    switch (pattern) {

      case "1":
        return (
          <div>
            <div className="competitor-avatar-bg-short"></div>
            <svg className="competitor-avatar-img" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="3em" height="3em"
              viewBox="0 0 520.7 565.9" style={{ enableBackground: "0 0 520.7 565.9" }} xmlSpace="preserve">

              <path fill={color1} d="M488.6,263.4c-16.5-119-39.9-176.8-45.2-184.5C436,68,430.4,65.2,429.1,64.6c0-0.1,0-0.1,0-0.1
                L317.8,18.8c0,0-18.8,14.8-55.8,13.5C225.1,33.6,206,18.8,206,18.8L94.7,64.5c0,0-6.1,1.9-14.7,14.3c-4.9,7.1-25.5,57.6-41.6,160.5
                c-1.3,8.1-2.5,16.5-3.7,25.2c-3.5,25.1,75.2,3.9,75.2,3.9l2.9-20.1c0.9,15.4,1.4,29.9,1.4,42.3c0,76.3-19.4,244.4-19.4,244.4
                c79.4,20.1,167.2,20.5,167.2,20.5s87.8-0.3,167.2-20.5c0,0-19.4-168.1-19.4-244.4c0-12.1,0.5-26,1.3-40.8l3.4,23.5
                C414.3,273.3,492.2,288.8,488.6,263.4z"/>
              <path fill={color2} d="M112.2,165.2c0,0,0,76.8,1.4,102.5c1.5,28.4-4.1,113.8-4.1,113.8l304.8,0.5c0,0-6.1-83.8-4.3-111.8
                c1.7-26.2,4.6-104.9,4.6-104.9L112.2,165.2z M315.9,339.8h-29.7l-38.2-75v75h-29.6V203.2h29.4l38.4,75.5v-75.5h29.7V339.8z"/>

            </svg>
          </div>
        );

      default:
        return (
          <div>no pattern</div>
        );
    }


  }

  render() {
    log(this.constructor.name, 'render');
    return <div>{this.renderShirt()}</div>
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    pattern: ownProps.pattern,
    color1: ownProps.color1,
    color2: ownProps.color2
  };
};

export default connect(mapStateToProps)(GameBoxDetailedAvatarShort);
