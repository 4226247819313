/**
 *  Component for game conference
 *  Show game status and game information with conference type
 */

import { log } from "../../config/log";
import React from "react";
import { connect } from "react-redux";
import { Localize, Translate } from "react-redux-i18n";
import Countdown from "react-countdown";
import { addStateItem } from "../../helpers/gameFunctions";
import LiveConferenceIcon from "../../media/img/logo_live.png";
import GameBoxColumn from "./GameBoxColumn";
import "./GameBox.css";

class ConferenceBox extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      gameId: "",
      sportCode: "",
      openDetail: false,
      selectedGames: this.props.selectedGames,
    };

    this.blockDangerState = false;
    this.stateList = [];
  }

  /**
   *  Check if game start time is 1 week later or not
   */

  checkOneWeekLater(timeStamp) {
    let today = new Date();
    let time = new Date(timeStamp);
    let difference = Math.floor(
      (Date.UTC(time.getFullYear(), time.getMonth(), time.getDate()) -
        Date.UTC(today.getFullYear(), today.getMonth(), today.getDate())) /
        (1000 * 60 * 60 * 24)
    );

    if (difference > 7) return true;

    return false;
  }

  goNextGame(stateItem) {
    const gameId = stateItem.gameId;
    if (typeof this.props.updateGameId == "function")
      this.props.updateGameId(gameId);
  }

  /**
   *  Update live game status
   */

  calcUpdatedGames() {
    const { selectedGames } = this.props;

    Object.values(selectedGames)?.forEach((game) => {
      if (game.isLive === 1) {
        addStateItem(game, this.stateList);
      }
    });
  }

  renderStateItem(stateItem, key, content) {
    return (
      <div key={key} style={{ marginBottom: 5 }}>
        <GameBoxColumn
          game={[stateItem]}
          mode="compact"
          content="selectedGames"
          isInViewport={true}
          key={key}
          from="conference"
          goNextGame={this.goNextGame.bind(this)}
        />
      </div>
    );
  }

  renderHeader() {
    let selectedGameKey = 0;
    return (
      <div className="game-box-conference-title white-permanent font-xl delay fade-in">
        <div>{this.renderHeaderScore(selectedGameKey)}</div>
        <div className="content-right vertical-center spacer-right text-shadow">
          <img src={LiveConferenceIcon} alt="live" /> | LIVE
        </div>
      </div>
    );
  }

  renderHeaderScore(selectedGameKey) {
    const { selectedGames } = this.props;

    Object.values(selectedGames)?.forEach((game, key) => {
      if (key === selectedGameKey) {
        return (
          <div className="game-box-conference-score-info" key={selectedGameKey}>
            <div style={{ backgroundColor: game.team1Color1 }}></div>
            <div className="bg-light-trans grey-permanent font-bold-2 font-s vertical-center content-center">
              {game.team1.substring(0, 3).toUpperCase()}
            </div>
            <div className="bg-color-contrast white-permanent font-bold-2 text-shadow font-l vertical-center content-center">
              {game.score_home} - {game.score_away}
            </div>
            <div className="bg-light-trans font-bold-2  grey-permanent font-s vertical-center content-center">
              {game.team2.substring(0, 3).toUpperCase()}
            </div>
            <div style={{ backgroundColor: game.team2Color1 }}></div>
            <div className="vertical-center font-s spacer-left">
              {game.isLive === 0 || game.state === "FT" ? (
                this.checkOneWeekLater(game.startTime) ? (
                  <Localize value={game.startTime} dateFormat="date.game1" />
                ) : (
                  <Localize value={game.startTime} dateFormat="date.game2" />
                )
              ) : (
                game.startTime
              )}
            </div>
          </div>
        );
      }
    });
  }

  renderConferenceBox() {
    const { selectedGames } = this.props;
    let gamesAreLive = false;

    Object.values(selectedGames)?.forEach((game) => {
      if (game.isLive === 1) gamesAreLive = true;
    });

    this.calcUpdatedGames();
    return (
      <div className="game-box-conference-bg">
        {this.renderHeader()}
        <div className="game-box-conference-additional-info">
          {gamesAreLive ? (
            Object.values(this.stateList).map((stateItem, key) => {
              return this.renderStateItem(stateItem, key, "conference");
            })
          ) : (
            <div className="game-box-conference-countdown text-center">
              <div className="game-box-conference-countdown-info">
                <Translate value={`labels.conference_start_title`} />
              </div>
              <div className="game-box-conference-countdown-time white-permanent font-bold-2 font-xxxxxl bg-light-gradient-center">
                <Countdown date={selectedGames[0].startTime} />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

  render() {
    log(this.constructor.name, "render");
    return <div>{this.renderConferenceBox()}</div>;
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    selectedGames: Object.values(ownProps.games),
  };
};

export default connect(mapStateToProps)(ConferenceBox);
