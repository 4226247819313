/**
 * League menu component ( Result, standing , conference and others)
 */

import { log } from "../../config/log";
import React from "react";
import { connect } from "react-redux";
import withStyles from "@mui/styles/withStyles";
import { setLeagueMenuValue, setLeagues } from "../../actions";
import { menuConfig } from "../../config/menuConfig";
import MenuTabs from "./MenuTabs";
import GamesList from "./GamesList";
import LeagueResults from "./LeagueResults";
import Conference from "./Conference";
import LiveTable from "./widgets/betradar/LiveTable";
import OutrightLeague from "./OutrightLeague";
import GoalScorerMarket from "./GoalScorerMarket";

const styles = (theme) => ({
  root: {
    "& .MuiTabs-root": {
      minHeight: "1em",
    },
    "& .MuiTab-root": {
      minHeight: "1em",
    },
    "& .MuiSvgIcon-root": {
      fontSize: "1.2em",
    },
    "& .MuiTabScrollButton-root": {
      fontSize: "1.3em",
      width: "1.3em",
      opacity: "0.5",
    },
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  rootTop: {
    "& .MuiTab-wrapper": {
      display: "inline-flex !important",
    },
    "& .MuiSvgIcon-root": {
      fontSize: "1.3em !important",
      "@media (hover: none) and (min-width: 768px)": {
        fontSize: "1.5em !important",
      },
    },
    "& .MuiTab-wrapper > *:first-child": {
      marginBottom: 0,
    },
    "& .MuiTabScrollButton-root.Mui-disabled": {
      display: "none",
    },
    "& .MuiTab-root": {
      minHeight: "1em",
      minWidth: 0,
      fontSize: "1.3em",
    },
    // flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.default,
    // position: "sticky",
    // top: "0",
    // zIndex: "999"
  },
  tabLabel: {
    fontSize: "0.7em",
  },
  tabsticky: {
    position: "sticky",
    top: "3em",
    zIndex: "997",
  },
});

class League extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuValue: "mainbets",
    };
  }

  menuChangeIndex(newLeagueMenuValue) {
    const {
      sportCode,
      leagueId,
      leagueFilter,
      leagueGrouping,
      marketSetConfig,
    } = this.props;

    let menuItems = [];
    if (
      !marketSetConfig[sportCode][
        leagueFilter === "live" ? "live" : "prematch"
      ]["favorites"]?.length
    ) {
      menuItems = menuConfig[sportCode][
        leagueFilter === "live" ? "live" : "prematch"
      ].filter((value) => {
        return value !== "favorites";
      });
    } else {
      menuItems = [
        ...menuConfig[sportCode][leagueFilter === "live" ? "live" : "prematch"],
      ];
    }

    this.props.setLeagueMenuValue(
      leagueGrouping ? sportCode : null,
      leagueId,
      menuItems[newLeagueMenuValue],
      this.constructor.name
    );
  }

  renderMenuContent(menuValue) {
    const {
      leagueId,
      sportCode,
      leagueFilter,
      searchQuery,
      marketSetConfig,
      from,
    } = this.props;

    if (menuValue !== this.state.menuValue) return <div key={menuValue}></div>;

    switch (this.state.menuValue) {
      case "results":
        return (
          <div key="results">
            <LeagueResults leagueId={leagueId} />
          </div>
        );

      case "conference":
        return (
          <div key="conference">
            <Conference content="league" leagueId={leagueId} />
          </div>
        );

      case "standings":
        return (
          <div key="standings">
            <LiveTable leagueId={leagueId} />
          </div>
        );

      case "outrights":
        return (
          <div key="outrights">
            <OutrightLeague
              searchQuery={searchQuery}
              isInsportsBetting
              leagueId={leagueId}
              sportCode={sportCode}
            />
          </div>
        );

      case "goalscorer":
        return (
          <div key="goalscorer">
            <GoalScorerMarket
              currentMarketSetKey={this.state.menuValue}
              isInsportsBetting
              marketSetKey={this.state.menuValue}
              leagueId={leagueId}
              sportCode={sportCode}
            />
          </div>
        );

      default:
        return (
          <div key="odds">
            <GamesList
              searchQuery={searchQuery}
              gameStaticId={this.props.gameStaticId}
              leagueId={leagueId}
              currentMarketSetKey={this.state.menuValue}
              currentMarketSetValue={
                marketSetConfig[sportCode][
                  leagueFilter === "live" ? "live" : "prematch"
                ][this.state.menuValue]
              }
              key={this.state.menuValue}
              from={from}
            />
          </div>
        );
    }
  }

  handleMenuValueChange = (newValue) => {
    this.setState({ menuValue: newValue });
  };

  componentDidMount() {
    const { searchTabIndex } = this.props;
    if (searchTabIndex) {
      this.handleMenuValueChange(searchTabIndex);
    }
  }

  render() {
    log(this.constructor.name, "render");

    const {
      sportCode,
      leagueId,
      leagues,
      marketSetConfig,
      leagueFilter,
      classes,
    } = this.props;
    

    if (
      !leagues[leagueId] ||
      !leagues[leagueId]["games"] ||
      !menuConfig[sportCode] ||
      !marketSetConfig[sportCode] ||
      !menuConfig[sportCode][leagueFilter === "live" ? "live" : "prematch"] ||
      !marketSetConfig[sportCode][leagueFilter === "live" ? "live" : "prematch"]
    )
      return <div>no games</div>;

    let menuItems = [];
    if (
      !marketSetConfig[sportCode] ||
      !marketSetConfig[sportCode][
        leagueFilter === "live" ? "live" : "prematch"
      ]["favorites"] ||
      !marketSetConfig[sportCode][
        leagueFilter === "live" ? "live" : "prematch"
      ]["favorites"]?.length
    ) {
      menuItems = menuConfig[sportCode][
        leagueFilter === "live" ? "live" : "prematch"
      ].filter((value) => {
        return value !== "favorites";
      });
    } else {
      menuItems = [
        ...menuConfig[sportCode][leagueFilter === "live" ? "live" : "prematch"],
      ];
    }

    return (
      <div className={classes.root}>
        {/* STICKY LEAGUE LIST */}
        <div className={classes.tabsticky}>
          {leagueFilter !== "live" ? (
            <MenuTabs
              setLocalMenuValue={this.handleMenuValueChange}
              menuValue={this.state.menuValue}
              content="league"
              sportCode={sportCode}
              leagueId={leagueId}
            />
          ) : null}
        </div>
        {menuItems.map((key) => {
          return this.renderMenuContent(key);
        })}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let showResultsFirst = state.settings.showResultsFirst;
  const leagueId = ownProps.leagueId;
  const leagueFilter = state.leagues._leagueFilter;
  const sportCode = state.selected.leagues[leagueId]
    ? state.selected.leagues[leagueId].sportCode
    : state.leagues._sportCode;
  const hasOutright = state.selected.leagues[leagueId]
    ? state.selected.leagues[leagueId].hasOutright
    : false;

  const searchTabIndex = new URLSearchParams(ownProps?.searchQuery)?.get(
    "type"
  );
  return {
    leagueId: leagueId,
    leagues: state.selected.leagues,
    leagueMenuValue:
      state.selected.leagues[leagueId] &&
      state.selected.leagues[leagueId].menuValue
        ? state.selected.leagues[leagueId].menuValue
        : showResultsFirst
        ? "results"
        : "mainbets",
    leagueGrouping: state.settings.leagueGrouping,
    leagueFilter: leagueFilter,
    sportCode: sportCode,
    marketSetConfig: state.settings.marketSets,
    gameStaticId: ownProps.gameStaticId,
    hasOutright: hasOutright,
    searchTabIndex: searchTabIndex,
    searchQuery: ownProps?.searchQuery,
  };
};

export default connect(mapStateToProps, { setLeagues, setLeagueMenuValue })(
  withStyles(styles)(League)
);
