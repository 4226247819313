import { useEffect } from "react";
import PropTypes from "prop-types";

const SideBetStaticLoader = ({ url, shouldMount, callback }) => {
  useEffect(() => {
    if (shouldMount) {
      callback();
    }
  }, [url]);

  return null;
};

SideBetStaticLoader.propTypes = {
  url: PropTypes.string,
  shouldMount: PropTypes.bool,
  callback: PropTypes.func,
};

SideBetStaticLoader.defaultProps = {
  url: "/",
  shouldMount: false,
  callback: () => {},
};

export default SideBetStaticLoader;
