/**
 * SVG component for own icon 
 */

import React from 'react';
import './IconOwn.css';

const IconBB = (props) => {

	return (
		<div style={{ display: "flex" }}>
			<svg className="icon-own" viewBox="0 0 2000 2000">
				<linearGradient gradientTransform="rotate(90)" id="icongradient">
					<stop className="icon-color-1" offset="0%" />
					<stop className="icon-color-2" offset="50%" />
					<stop className="icon-color-1" offset="100%" />
				</linearGradient>
				<g><path fill="url(#icongradient)" d="M1014.3,586.4c126.2-6.8,226.1-108.1,233.2-233.2c-0.4,0-0.4,0-0.4,0c-62.1-1.1-122,22.5-166.2,66.7
		C1036.4,464.8,1012.5,523.6,1014.3,586.4z"/>
					<path fill="url(#icongradient)" d="M985.7,92.1c0-0.4,0-0.4,0-0.4c-58.5,3.2-116.3,27.5-161.2,72.4c-44.9,44.9-68.5,102.7-71.7,161.2
		c5.7-0.7,93.1,6.4,166.2-66.7C963.6,214.1,987.2,155.2,985.7,92.1z"/>
					<path fill="url(#icongradient)" d="M757.9,364.2h-3.9c5.7,55.3,29.2,108.8,71,150.5c42.1,42.1,95.6,66,150.5,71.3c-1.4-72,27.1-141.9,78.1-192.9
		c51-51,120.9-79.5,192.9-77.7c-5.3-54.9-29.2-108.4-71.3-150.5s-95.6-66-150.5-71.3c1.4,72-27.1,142.3-78.1,192.9
		C896.9,336.1,828.1,364.2,757.9,364.2z"/>
					<path fill="url(#icongradient)" d="M753.2,357.8c0,2.1,0.4,4.3,0.4,6.4C753.6,362.1,753.6,359.9,753.2,357.8z" />
					<path fill="url(#icongradient)" d="M862.7,1326.4c-15.3-10.7-30-22.1-44.6-33.9c-61,82.4-131.2,164-205.8,238.6l-204.7,204.7l-11.1-10.7
		c-16.8-16.4-43.5-16.4-59.9,0c-16.4,16.4-16.4,43.2,0,59.9l111.3,110.9c16.4,16.4,43.2,16.4,59.9,0c16.4-16.8,16.4-43.5-0.4-59.9
		c1.8,1.8-8.9-9.3-10.7-11.1l205.1-204.3c81.7-82,170.5-157.3,264.6-225c-16-12.5-103.1-66.7-104.8-68.1L862.7,1326.4z"/>
					<path fill="url(#icongradient)" d="M1905.8,482.3L1905.8,482.3c-28.9-74.9-81-127-156.2-155.8c-41.7-16-88.8-4.6-118.8,28.2
		c-231.4,251.4-595.9,648-604.1,656.2c25.3,29.2,36.4,41.4,67,83.8l0.7-0.7c14.6,22.5,45.3,73.8,94.1,142.3
		c0.4-0.4,688.6-634.1,689-634.4C1910.5,571.4,1921.9,524.4,1905.8,482.3z"/>
					<path fill="url(#icongradient)" d="M939.4,974.4L369.1,354.6c-30-32.8-77.4-44.2-118.8-28.2c-75.2,28.9-127.3,81-156.2,155.8c-16,41.7-4.6,88.8,27.8,119.1
		l619.8,570.2L939.4,974.4z"/>
					<path fill="url(#icongradient)" d="M1603.4,1725.5l-10.7,10.7v-0.4L1388,1531.5v-0.4c-104.5-104.5-197.2-218.6-278.5-342l-152.6,152.6
		c123.4,81,237.9,174,342,278.5l204.7,204.7l-10.7,11.1c-16.8,16.4-16.8,43.2-0.4,59.9c16.8,16.4,43.5,16.4,59.9,0l110.9-110.9v0
		c16.4-16.8,16.4-43.5,0-59.6C1646.6,1709.1,1619.8,1709.1,1603.4,1725.5z"/>
					<path fill="url(#icongradient)" d="M770.7,1198.4c46,46,97.4,87.4,152.6,122l164.4-164.4c-34.6-55.3-76-106.6-122-152.6L770.7,1198.4z" /></g>
			</svg>
		</div>
	);
};

export default IconBB;
