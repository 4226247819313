import React, { Component } from 'react'
import Container from "@mui/material/Container";
import Button from '@mui/material/Button';

class FooterExpand extends Component {
  constructor(props) {
    super(props)
    this.state = {
      expanded: false
    }
  }

  showButton = () => {
    this.setState({ expanded: !this.state.expanded })
  }

  render() {
    const { expanded } = this.state
    return (
      <Container>
        <div>
          <h2 style={{ color: 'var(--color-contrast)' }}>NUBET - PÕHJALA MAAGIA</h2>
          Kas sa oled liiga nutikas, et loteriid mängida?<br></br><br></br>
        </div>
        {
          expanded &&
          <div>
            See on Nubet, meie arvates üks lahedamaid uusi kihlvedude saite. See ei ole loterii, vaid meie ja meie klientide jaoks on see nii intellektuaalne väljakutse kui ka ajaviide. See aga eeldab õiglasi mängutingimusi, erinevalt loteriimängudest.<br></br><br></br>
            Meie idee on luua elegantne kihlveoteenus, mis peab vastu võrdlustele nii Põhjamaade kui ka mujal Euroopas konkurentsitihedatel turgudel. Püüame olla õiglane, usaldusväärne ja stiilne. Mängusait, mis on väärt teie usaldust.<br></br><br></br>
            Nubet eristub keskmisest turust selle poolest, et võtab vastu panuseid korralike panustega ja piisavalt kõrgete koefitsientidega. On suur vahe, kas tegemist on kehva tootlikkusega valgelaenuna tegutseva ettevõttega või ettevõttega, mis tegelikult tegutseb nagu kihlveokontor peaks tegutsema.<br></br><br></br>
            2023. aastal avatud Nubet ei ole kindlasti kohe parim versioon endast, kavatsetakse teenust pidevalt arendada, et see vastaks klientide vajadustele. Karm konkurents tagab, et rahulolule ei jää ruumi ning teenuse pakkumist ja kasutatavust tuleb pidevalt parandada. Sellele vaatamata on Nubeti pakkumine olnud juba esimestest kuudest alates ulatuslik ja seda on pidevalt täiustatud. Kuid teha on veel palju muudki. Üks päevakorrapunkt on pakkuda võimalikult kiiresti live-panuseid.<br></br><br></br>
            Euroopa litsentseerimisturul valitsev tihe konkurents nõuab teenusele palju. Püüame pakkuda võimalikult konkurentsivõimelisi koefitsiente ja meie riskijuhtimise vahendid ei sisalda võitnud kliendi välja suitsetamist pärast paari panust. Õiglane mäng on kõige meie tegevuse aluseks ja sama ootame ka oma klientidelt. Nubeti riskijuhtimisvahendite eesmärk on tagada, et kõiki kliente koheldakse õiglaselt.<br></br><br></br>
            Suurt tähelepanu pööratakse mobiilsusele. Oleme teadlikud, et valdav osa mängudest käib praegu selle kanali kaudu. Mängimise lihtsus ja kiirus on meie arengu juhtivad eesmärgid. Sama kehtib loomulikult ka rahaülekannete kohta. Lisaks sujuvale mängukogemusele eeldab hea kliendikogemus ka sujuvat rahaülekannet. Selles osas toetub Nubet sellistele turuliidritele nagu Trustly. Nubetiga ei ole teie raha mitte ainult turvaline, vaid ka kiiresti teie pangakontole üle kantud.<br></br><br></br>
            Põhjamaade litsentseerimisturgu käsitlev arutelu kuumeneb. Hetkel tegutseb Nubet Eesti litsentsi alusel, mis on meie jaoks loomulikum valik kui Malta. Meie eesmärk on olla 2028. aastaks tugev tegija mänguturul Euroopast Aasiasse ja Lõuna-Ameerikasse. Lõppude lõpuks on Nubetil midagi sellist, mida mängijad, kus iganes nad ka ei asuks, juba ammu otsivad. Nubetil on Magic of the North ja see, kui üldse, toimib kõikjal maailmas.<br></br><br></br>
          </div>
        }
        <Button color="primary" variant="outlined" style={{ padding: '0.2em 0.5em', fontSize: '0.8em' }} onClick={this.showButton}>{expanded ? 'Loe vähem' : 'Loe edasi'}</Button>

      </Container>
    )
  }
}

export default FooterExpand