import React, { useEffect, useState } from "react";
import withStyles from "@mui/styles/withStyles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import Avatar from "@mui/material/Avatar";
import PropTypes from "prop-types";
import PlayerPropsGamesList from "./GamesList";
import { useLocation } from "react-router-dom";

const styles = (theme) => ({
  root: {
    displa: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  tabBars: {
    position: "static",
  },
  paper: {
    margin: theme.spacing(1),
  },
  tabBarFavorites: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  avatarAll: {
    backgroundColor: "transparent",
    // color: "var(--grey-3)",
    color: "var(--color-contrast)",
    // border: "0.17em solid var(--grey-3)",
    borderRadius: "1em",
    width: "1.6em",
    height: "1.6em",
    fontSize: "0.94em",
    lineHeight: "inherit",
    fontWeight: "500",
  },
  container: {},
});

const PlayerPropsLeagueRow = ({ classes, league, indx }) => {
  const location = useLocation();
  const [checked, setChecked] = useState(false);
  let gameLiveCount = league?.gameLiveCount;

  const getLeagueText = () => {
    let leagueText;
    if (league.labelCategory !== "") {
      leagueText = (
        <span>
          <span style={{ marginRight: "2px" }}>
            <b>{league.labelCategory} </b>
          </span>
          {league.label}
        </span>
      );
    } else {
      leagueText = <b>{league.label}</b>;
    }
    return leagueText;
  };

  const leagueText = getLeagueText();

  const handleCollapse = () => {
    setChecked(!checked);
  };

  useEffect(() => {
    if (indx === 0) {
      setChecked(true);
    }
  }, [location.pathname]);

  return (
    <div>
      <div
        onClick={handleCollapse}
        className={
          checked ? "league-box-selected-betbuilder" : "league-box-betbuilder"
        }
        data-ripple="var(--color-ripple)"
      >
        <div
          id="league-country-flag"
          className="vertical-center content-center"
        >
          <img
            src={`https://api.nubet.com/media/flags/${(league.countryCode = ""
              ? "INT"
              : league.countryCode)}.png`}
            alt="flag"
          />
        </div>
        <div
          className="vertical-center font-xl font-bold"
          style={{ width: "max-content" }}
        >
          {leagueText}
        </div>
        <div className="vertical-center content-right">
          {gameLiveCount > 0 ? (
            <span>
              <Avatar className={classes.avatarLive}>{gameLiveCount}</Avatar>
            </span>
          ) : null}
          {/* <span className="spacer-left"><Avatar className={this.props.classes.avatarAll}>{league.gameCount}</Avatar></span> */}
          <span className={`spacer-left`}>
            <Avatar
              sx={{
                backgroundColor: "transparent",
                color: "var(--color-contrast)",
                width: "2.1em",
                height: "2.1em",
                fontSize: "0.94em",
                lineHeight: "inherit",
                fontWeight: "500",
                // backgroundImage: `url(${NowLiveIcon})`,
                // backgroundRepeat: "no-repeat",
                justifySelf: "center",
                backgroundPosition: "center",
                backgroundSize: "contain",
              }}
            >
              {league.gameCount}
            </Avatar>
          </span>
        </div>
        <div className="vertical-center content-center">
          {checked ? (
            <ExpandLessIcon style={{ color: "var(--grey-3)" }} />
          ) : (
            <ExpandMoreIcon style={{ color: "var(--grey-3)" }} />
          )}
        </div>
      </div>
      <div className={classes.container}>
        {checked && <PlayerPropsGamesList league={league} />}
      </div>
      {/* <Divider /> */}
    </div>
  );
};

PlayerPropsLeagueRow.propTypes = {
  league: PropTypes.object,
  classes: PropTypes.object,
  indx: PropTypes.number.isRequired,
};

PlayerPropsLeagueRow.defaultProps = {
  league: {},
};

export default withStyles(styles)(PlayerPropsLeagueRow);
