/**
 * User Register page
 */
import { log } from "../../config/log";
import React from "react";
import { connect } from "react-redux";
import { Translate } from "react-redux-i18n";
import withStyles from "@mui/styles/withStyles";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepConnector from "@mui/material/StepConnector";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import PersonIcon from "@mui/icons-material/Person";
import FolderSharedIcon from "@mui/icons-material/FolderShared";
import SubtitlesIcon from "@mui/icons-material/Subtitles";
import { registerUser, resetRegisterUserFlag } from "../../actions";
import UserForm from "./UserForm";
import withRouter from "../../helpers/withRouter";

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 5,
  },
  line: {
    height: 2,
    border: 0,
    backgroundColor: "var(--grey-4)",
    borderRadius: 1,
  },
})(StepConnector);

const styles = (theme) => ({
  root: {
    "@media (hover:hover)": {
      backgroundColor: "var(--color-main)",
    },
    width: "100%",
    "& .MuiStepper-root": {
      padding: "2em 0 0 0",
    },
    "& .MuiStepLabel-label": {
      color: "var(--grey-4)",
      fontSize: "1em",
    },
    "& .MuiStepLabel-active": {
      color: "var(--color-contrast)",
    },
  },
  button: {
    margin: theme.spacing(1),
    color: "var(--btn-text)",
    "&:hover": {
      backgroundColor: "var(--color-contrast)",
    },
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  stepper: {
    zIndex: 1,
    color: "var(--grey-4)",
    width: 10,
    height: 10,
    display: "flex",
    borderRadius: "20%",
    justifyContent: "center",
    alignItems: "center",
  },
  stepperActive: {
    zIndex: 1,
    color: "var(--color-contrast)",
    width: 10,
    height: 10,
    display: "flex",
    borderRadius: "20%",
    justifyContent: "center",
    alignItems: "center",
  },
  stepperCompleted: {
    color: "var(--color-contrast)",
  },
  hidden: {
    display: "none",
  },
  flexEnd: {
    alignItems: "center",
    display: "flex",
    justifyContent: "flex-end",
  },
});

class UserRegister extends React.Component {
  constructor(props) {
    super(props);

    this.steps = [
      <Translate value="labels.register_user_data" />,
      <Translate value="labels.register_person_data" />,
      <Translate value="labels.register_detail_data" />,
    ];
    this.icons = [<SubtitlesIcon />, <PersonIcon />, <FolderSharedIcon />];

    this.state = {
      activeStep: 0,
      previousStep: 0,
      stepCompleted: true,
      disabled: true,
      disbaleRegister: false,
    };
  }

  handleNext = () => {
    this.child.onValidateCurrentStepForm();
  };

  handleDisable = () => {
    this.setState({ disabled: true });
  };

  handleEnable = () => {
    this.setState({ disabled: false });
  };

  handleRegisterState = (val) => {
    this.setState({ disbaleRegister: val });
  };

  goNext = () => {
    this.setState({
      activeStep: this.state.activeStep + 1,
      previousStep: this.state.activeStep,
    });
    this.handleDisable();
  };

  handleBack = () => {
    this.setState({ activeStep: this.state.activeStep - 1 });
  };

  handleReset = () => {
    this.setState({ activeStep: 0, previousStep: this.state.activeStep });
  };

  submitForm = () => {
    this.child.submitForm();
  };

  goToLogin = () => {
    this.props.resetRegisterUserFlag();
    this.props.navigate(`/${this.props.locale}/sign-in`);
  };

  renderStepContent(step) {
    // const { classes } = this.props;
    // return;
    switch (step) {
      case 0:
      case 1:
      case 2:
        return (
          <UserForm
            onSubmit={this.onSubmit}
            content="register"
            step={step}
            lang={this.props.locale}
            goNext={this.goNext.bind(this)}
            onRef={(ref) => (this.child = ref)}
            disableSubmit={this.handleDisable.bind(this)}
            enableSubmit={this.handleEnable.bind(this)}
            handleRegisterState={this.handleRegisterState.bind(this)}
          />
        );
      default:
        return "Unknown step";
    }
  }

  onSubmit = (formValues) => {
    this.props.registerUser(formValues, this.constructor.name);
  };

  render() {
    log(this.constructor.name, "render");
    const { classes, registeredUser } = this.props;
    // console.log(this.state.activeStep);
    return (
      <div className="App">
        <div className={classes.root}>
          {registeredUser ? (
            <div style={{ padding: 20 }}>
              <Typography className={classes.instructions}>
                <Translate value="labels.account_created" dangerousHTML />
              </Typography>
              <div className={classes.flexEnd}>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  style={{ color: "var(--btn-text)" }}
                  onClick={this.goToLogin}
                  className={classes.button}
                >
                  <Translate value="labels.continue" />
                </Button>
              </div>
            </div>
          ) : (
            <div>
              <Stepper
                key="register-stepper"
                alternativeLabel
                activeStep={this.state.activeStep}
                connector={<ColorlibConnector />}
              >
                {this.steps.map((label, key) => (
                  <Step key={key}>
                    <StepLabel
                      icon={
                        <div
                          className={
                            key === this.state.activeStep
                              ? classes.stepperActive
                              : classes.stepper
                          }
                        >
                          {this.icons[key]}
                        </div>
                      }
                    >
                      {label}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>

              {this.state.activeStep === this.steps?.length ? (
                <div>
                  <Typography className={classes.instructions}>
                    Vielen Dank für Ihre Registrierung.
                  </Typography>
                </div>
              ) : (
                <div className="text-center">
                  {this.renderStepContent(this.state.activeStep)}
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    style={{ color: "var(--btn-text)" }}
                    onClick={this.handleBack}
                    className={
                      this.state.activeStep === 0
                        ? classes.hidden
                        : classes.button
                    }
                  >
                    <Translate value="labels.back" />
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    disabled={this.state.disabled}
                    onClick={this.handleNext}
                    className={
                      this.state.activeStep === 2
                        ? classes.hidden
                        : classes.button
                    }
                  >
                    <Translate value="labels.next" />
                  </Button>
                  <Button
                    disabled={this.state.disbaleRegister || this.state.disabled}
                    type="submit"
                    variant="contained"
                    size="small"
                    color="primary"
                    className={
                      this.state.activeStep === 2
                        ? classes.button
                        : classes.hidden
                    }
                    onClick={this.submitForm.bind(this)}
                  >
                    <Translate value="labels.btn_save" />
                  </Button>
                  <br></br>
                  <br></br>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  locale: state.i18n.locale,
  registeredUser: state.registeredUser,
});

export default withRouter(
  connect(mapStateToProps, {
    registerUser,
    resetRegisterUserFlag,
  })(withStyles(styles)(UserRegister))
);
