/**
 * Casino Banner component
 */
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Image from "../../image";
import SlickSlider from "../../react-slick";
import { Box, styled } from "@mui/material";
import useWindowSize from "../../../helpers/useWindowSize";

const StyledContainer = styled(Box)(() => ({
  padding: "0 1em",
  "& .MuiPaper-root": {
    backgroundColor: "#ffffff00",
    padding: "6em 0 0 0",
    textAlign: "right",
  },
  "& .MuiSvgIcon-root": {
    fontSize: "1em !important",
  },
}));

const CasinoBanner = () => {
  const locale = useSelector((state) => state.i18n.locale);
  const screenWidth = useWindowSize();
  const auth = useSelector((state) => state.user.auth);

  let items = [
    {
      name: "Drops and Wins",
      description: "Drops and Wins",
      image: "banner_dropswins.jpg",
      link: "promotions",
    },
    // {
    //   name: "Gemix",
    //   description: "Gemix",
    //   image: "banner_gemix.jpg",
    //   link: "promotions",
    // },
    {
      name: "Sugar Rush 1000",
      description: "Sugar Rush 1000",
      image: "banner_sugar_rush_1000.jpg",
      link: "casino/dashboard?dialog=play-game&game-id=690111",
    },
    // {
    //   name: "Gonzos Quest",
    //   description: "Gonzos Quest",
    //   image: "banner_gonzos_quest.jpg",
    //   link: "casino/dashboard?dialog=play-game&game-id=160250",
    // },
  ];
  if (!auth.isSignedIn) {
    items.splice(1, 0, {
      name: "Book of Power",
      description: "Book of Power",
      image: "banner_bookofpower_101.jpg",
      link: "promotions",
    });
  }

  return (
    <SlickSlider
      customSettings={{
        arrows: false,
        autoplay: true,
        autoplaySpeed: 5000,
        infinite: true,
        speed: 1000,
      }}
    >
      {items?.map((item, indx) => (
        <StyledContainer key={indx}>
          <Link key="link-promo" to={`/${locale}/${item.link}`}>
            <Image src={screenWidth <= 600 ? `https://storage.googleapis.com/nubet-prod-content-news/mobile_${item.image}` : `https://storage.googleapis.com/nubet-prod-content-news/${item.image}`} ratio={screenWidth <= 600 ? "21/9" : "21/6"} />
          </Link>
        </StyledContainer>
      ))}
    </SlickSlider>
  );
};



export default CasinoBanner;
