/**
 * League result component
 * Show result for all games of current league
 */

import { log } from '../../config/log';
import React from 'react';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import { getLeagueResults } from '../../actions';
import GameBox from './GameBox';
import Grid from "@mui/material/Grid";
import './LeagueResults.css';
import { sortDatesDesc } from '../../helpers/gameChronologicalsort';


const styles = (theme) => ({
});

class LeagueResults extends React.PureComponent {

  componentDidMount() {
    const { leagueId } = this.props;
    this.props.getLeagueResults(leagueId, this.constructor.name);
  }

  renderGameDay() {
    const { gameDays } = this.props;
    const datesList = sortDatesDesc(Object.keys(gameDays));
    return datesList.map((gameDay, key) => {
      return (
        <div key={key}  style={{ padding: "1em" }}>
          {/* <div className="spacer-left font-m font-bold-2 league-box-title color-contrast">{gameDay}</div> */}
          <div className="spacer-left font-m font-bold-2 color-contrast">{gameDay}</div>
          <Grid container style={{ width: "100%" }}>
            {this.renderGames(gameDay)}</Grid>
        </div>
      );
    });
  }

  renderGames(gameDay) {
    const { gameDays} = this.props;

    return Object.values(gameDays[gameDay]).map((game, indx) => {

  
      return (
        <div key={game.gameid}>
          {/* <GameBox game={[game]} content="selectedLeagueResults" /> */}
          {/* <Grid item xs={12}><p className='color-team font-team font-bold-2'>{game.team1} </p>{game.score_set} {game.team2}</Grid> */}
          <Grid item xs={12} style={{ margin: "1em 1em 0 0", pointerEvents: "none" }}><GameBox game={[game]} content="selectedLeagueResults" /></Grid>
        </div>
      );
    });
  }

  render() {
    log(this.constructor.name, 'render');
    const { gameDays } = this.props;

    if (!gameDays) return <div>Loading...</div>;

    return (
      <div>
        {this.renderGameDay()}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    leagueId: ownProps.leagueId,
    gameDays: state.selected.leagues[ownProps.leagueId]?.results
  };
};

export default connect(mapStateToProps, { getLeagueResults })(withStyles(styles)(LeagueResults));
