/**
 * Ticket Box component
 */
import "./TicketBox.css";
import { log } from "../../config/log";
import React from "react";
import { connect } from "react-redux";
import { Translate } from "react-redux-i18n";
import { Localize } from "react-redux-i18n";
import { withStyles } from "@mui/styles";
import Chip from "@mui/material/Chip";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

import { postTicketCashout, getWalletBalanceDetails } from "../../actions";
import { formatCommasAndDots } from "../../helpers/utilities";

const styles = (theme) => ({
  button: {
    "& .MuiButton-root": {
      width: "95%",
      padding: "0.2em 0.2em",
      fontSize: "1.1em",
      textTransform: "none",
      minWidth: "1em",
      margin: "0.2em 0 0 0",
    },
  },
  chipGreen: {
    "& .MuiChip-root": {
      backgroundColor: "green",
    },
  },
});

// import { makeStyles } from '@mui/material/styles';

// const useStyles = makeStyles((theme) => ({
//   root: {
//     display: 'flex',
//     flexDirection: 'column',
//     alignItems: 'center',
//     '& > *': {
//       margin: theme.spacing(1),
//     },
//   },
// }));

/**
 * Timer for Cashout button
 * Counting down from 5 to 0 and reback to 5 again until cashout successful
 */
let timer = null;

class TicketBox extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      // gameId: '',
      // sportCode: '',
      firstClick: false,
      isConfirming: false,
      remainingSeconds: 5,
    };
  }

  componentDidMount() {}

  // openGameDetails(game) {
  //   this.setState({ gameId: game.gameid, sportCode: game.sport_code }, () => {
  //     this.openDrawer()
  //   });
  // }

  /**
   * Trigger ticket cashout request
   */
  postTicketCashout = (ticket) => {
    const cashoutWin = this.calcCashout(ticket.ticketID).cashoutWin;
    let data = {
      ticketID: ticket.ticketID,
      cashoutWin,
    };

    this.props.postTicketCashout(data);
  };

  // Find if the ticket has TR outrights

  getScore24Market(ticketCode) {
    const { calcCashoutTickets } = this.props;
    const ticket = calcCashoutTickets.find(
      (item) => item.meta.ticketCode === ticketCode
    );
    const findTR = ticket.bets.find((item) => item.game.sport_code === "TR");
    return findTR;
  }

  /**
   * Format value to 2 decimal value
   * E.g, 1.2335 -> 1.23
   */
  dec(n) {
    return n.toFixed(2);
  }

  /**
   * Calculate cashoutWin price and  cashout status label from ticket
   */
  calcCashout(ticketID) {
    let result = {
      cashoutWin: 0,
      cashoutStatus: "enabled",
    };

    if (this.props.calcCashoutTickets) {
      let ticket = this.props.calcCashoutTickets.filter(
        (obj) => obj.meta.ticketID === ticketID
      );
      if (ticket?.length === 0) return;

      ticket = ticket[0];

      // if (cashingOut) {
      //   return;
      // }

      let voidRate = 0.95;
      let cashoutStatus = "enabled";

      // if (typeof pendingTicketDetails[ticketID] == 'undefined') {

      //   cashoutBtn.removeClass('account_ticket_cashout').addClass('account_ticket_cashout_disabled');
      //   cashoutSlipBtn.removeClass('slip_account_ticket_cashout').addClass('slip_account_ticket_cashout_disabled');
      //   return false;
      // }

      //first check if the cashout is eligible to void
      let isVoidable = true;
      let hasLiveBets = false;
      let hasPriceChanges = false;
      let openBetsLength = 0;
      let finishedBetsLength = 0;

      ticket.bets?.forEach((item, index) => {
        // $.each(pendingTicketDetails[ticketID], function (index, item) {

        if (item.game.isLive === 1 || item.isWon !== 0) {
          hasLiveBets = true;
        }

        if (Number(item.price) !== Number(item.currentPrice)) {
          hasPriceChanges = true;
        }

        if (item.isWon === 0) {
          openBetsLength++;
        }

        if (item.isWon === 1) {
          finishedBetsLength++;
        }
      });

      if (hasLiveBets || hasPriceChanges) {
        isVoidable = false;
      }

      //1. update prices with cashout factor
      ticket.bets?.forEach((item, index) => {
        // $.each(pendingTicketDetails[ticketID], function (index, item) {

        //only replace cashout factor for pending bets' prices, won remain unchanged.
        //is one bet lost cashout is eliminated for this ticket. (system too?)
        if (item.isWon === 0) {
          let currentMarketSelectionIds = item.currentMarketSelectionIds; //1,2,3
          let currentMarketPrices = item.currentMarketPrices; //2.2,3.5,1.2

          //calc current total market margin (=Wettmarkt, odd key)
          let totalMargin = 0;

          for (var i = 0; i < currentMarketSelectionIds?.length; i++) {
            //in case there is a odd of 0 in the same market
            if (Number(currentMarketPrices[i]) === 0) {
              cashoutStatus = "disabled";
              return false;
            }

            let currentMarketSelectionPrice = Number(currentMarketPrices[i]);

            if (currentMarketSelectionPrice < 1) {
              currentMarketSelectionPrice = 1;
            }

            totalMargin += 1 / currentMarketSelectionPrice;
          }

          //=MAX(0;placedPrice*(1/currentPrice-(totalMargin-1,01)))
          let cashoutFactor = 0;
          if (item.marketId === 10) {
            cashoutFactor =
              (1 / Number(item.currentPrice) + 0.99 - totalMargin / 1.9) * 1.1;
          } else {
            cashoutFactor = 1 / Number(item.currentPrice) + 1.01 - totalMargin;
          }

          item.cashoutFactor = cashoutFactor;
          item.cashoutPrice = Number(cashoutFactor) * Number(item.price);

          if (item.cashoutPrice < 0) {
            item.cashoutPrice = 0;
          }
        }
      });

      //2. check if cashout is possible & calculate cashout win of the ticket
      let cashoutWin = 0;
      let totalStake = 0;
      let totalOdd = 1;

      let gameEngineID = ticket.meta.gameEngineID;

      if (gameEngineID === 1 || gameEngineID === 2) {
        let cntWonBets = 0;
        let ticketPriceProduct = 1;
        let currentPriceProduct = 1;

        ticket.bets?.forEach((item, index) => {
          // $.each(pendingTicketDetails[ticketID], function (index, item) {

          //if there is any bet inactive we disable cashout
          //this does not affect the cashout if the game is over (isWon not 0)
          if (item.isActive === 0 && item.isWon === 0) {
            cashoutStatus = "disabled";
            return false;
          }

          let var1 = finishedBetsLength + 5;
          let var2 = var1 / 100;
          let var3 = Math.max(0, openBetsLength - var1);
          let var4 = ((var3 / 30) * var3) / 1000;
          let var5 = 0.98 - var2 / 20;
          let var6 = item.cashoutFactor * item.price;
          let var7 = var6 + var4;

          //cashout market exclusion
          if (!item.game) return;

          let isCashoutAvailable =
            item.game.isLive === 1
              ? Number(this.props.cashoutAvailability["live"][item.marketId])
              : Number(this.props.cashoutAvailability["pre"][item.marketId]);

          if (Number(item.isWon) !== 0) {
            isCashoutAvailable = 1;
          }

          switch (isCashoutAvailable) {
            case 0:
              cashoutStatus = "disabled";
              return false;

            case 0.5:
              var arrHC = item.hc.split("=");

              if (arrHC[1].indexOf(".5") === -1) {
                cashoutStatus = "disabled";
                return false;
              }

              break;

            case 1:
              break;

            default:
              break;
          }

          // var gameEngineID = item.ticketGameEngineID;
          totalStake = Number(ticket.meta.stake);

          switch (item.isWon) {
            case -1:
              //one lost bet means cashout not possible, deactivate permanently
              cashoutStatus = "expired";
              return false;

            case 0:
              let realCashoutPrice = Math.max(var6, Math.min(var5, var7));
              if (realCashoutPrice < 0) realCashoutPrice = 0;

              totalOdd *= realCashoutPrice;
              ticketPriceProduct *= Number(item.price);
              currentPriceProduct *= Number(item.currentPrice);
              break;

            case 1:
              if (gameEngineID === 1) {
                //if it is a single bet the ticket has already won, deactivate permanently
                cashoutStatus = "expired";
                return false;
              } else if (gameEngineID === 2) {
                cntWonBets++;

                if (cntWonBets === ticket.bets?.length) {
                  //all combi bets are won so the ticket is won and we deactivate permanently
                  cashoutStatus = "expired";
                  return false;
                }

                totalOdd *= Number(item.price);
              }

              break;

            default:
              break;
          }
        });

        if (!hasLiveBets && currentPriceProduct < ticketPriceProduct) {
          isVoidable = true;
        }

        if (isVoidable === false) {
          cashoutWin = this.dec(totalStake * totalOdd);
        } else {
          cashoutWin = this.dec(totalStake * voidRate);
        }
      } else if (gameEngineID === 3) {
        let oddPerCombi = [];
        let stakePerCombi = [];

        let systemCombis = ticket.systemCombis;
        systemCombis?.forEach((systemCombi, index) => {
          // $.each(systemCombis, function (index, systemCombi) {

          // let combiType = systemCombi.type;
          let thisCombiIsDisabled, thisCombiIsLost;

          //go through each combi of this combiType
          for (var i = 0; i < systemCombi.combis?.length; i++) {
            let odds = 1;
            let indexes = systemCombi.combis[i].indexes.split(";");

            thisCombiIsLost = false;
            thisCombiIsDisabled = false;

            for (var j = 0; j < indexes?.length; j++) {
              var v = indexes[j];
              // $.each(indexes, function (k, v) {

              let bet = ticket.bets[v];
              let isCashoutAvailable;
              //cashout market exclusion
              if (this.props.cashoutAvailability) {
                isCashoutAvailable =
                  bet.game.isLive === 1
                    ? Number(
                        this.props.cashoutAvailability["live"][bet.marketId]
                      )
                    : Number(
                        this.props.cashoutAvailability["pre"][bet.marketId]
                      );
              }

              if (Number(bet.isWon) !== 0) {
                isCashoutAvailable = 1;
              }

              switch (isCashoutAvailable) {
                case 0:
                  thisCombiIsDisabled = true;
                  return false;

                case 0.5:
                  let arrHC = bet.hc.split("=");

                  if (arrHC[1].indexOf(".5") === -1) {
                    thisCombiIsDisabled = true;
                    return false;
                  }

                  break;

                case 1:
                  break;

                default:
                  break;
              }

              switch (Number(bet.isWon)) {
                case -1:
                  thisCombiIsLost = true;
                  return false;

                case 0:
                  if (bet.isActive === 0) {
                    thisCombiIsDisabled = true;
                    return false;
                  }

                  odds *= Number(bet.cashoutPrice);
                  break;

                case 1:
                  odds *= Number(bet.price);
                  break;

                default:
                  break;
              }
            }

            if (thisCombiIsDisabled === true) {
              cashoutStatus = "disabled";
              break;
            }

            if (thisCombiIsLost === false) {
              oddPerCombi.push(odds);
              stakePerCombi.push(Number(systemCombi.item_stake_clean));
            }
          }

          if (thisCombiIsDisabled === true) {
            cashoutStatus = "disabled";
            return false;
          }
        });

        oddPerCombi?.forEach((item, index) => {
          // $.each(oddPerCombi, function (index, item) {

          cashoutWin += item * stakePerCombi[index];
        });

        if (isVoidable === false) {
          cashoutWin = this.dec(cashoutWin);
        } else {
          totalStake = Number(ticket.meta.stake);
          cashoutWin = this.dec(totalStake * voidRate);
        }
      }

      if (cashoutWin <= 0) {
        cashoutStatus = "disabled";
      }

      ticket.meta.cashoutWin = cashoutWin;
      ticket.meta.cashoutStatus = cashoutStatus;
      // switch (cashoutStatus) {

      //   case 'expired':

      //     cashoutBtn.css('visibility', 'hidden');
      //     cashoutBtn.css('pointer-events', 'none');
      //     cashoutSlipBtn.css('visibility', 'hidden');
      //     cashoutSlipBtn.css('pointer-events', 'none');
      //     cashoutHistory.css('visibility', 'hidden');
      //     cashoutSlipHistory.css('visibility', 'hidden');

      //     break;

      //   case 'disabled':

      //     cashoutBtn.removeClass('account_ticket_cashout').addClass('account_ticket_cashout_disabled').html(APP_LANG_NEW_CASHOUT_NOT_AVAILABLE);
      //     cashoutSlipBtn.removeClass('slip_account_ticket_cashout').addClass('slip_account_ticket_cashout_disabled').html(APP_LANG_NEW_CASHOUT_NOT_AVAILABLE);
      //     cashoutHistory.css('visibility', 'hidden');
      //     cashoutSlipHistory.css('visibility', 'hidden');

      //     break;

      //   case 'enabled':

      //     var cashoutWinOld = Number(cashoutBtn.attr('cashoutwin'));

      //     // var styleChange = '';
      //     if (cashoutWinOld > 0) {

      //       if (cashoutWinOld < cashoutWin) {

      //         $('#ticket_' + ticketID).find('.account_ticket_cashout_status').html('<i class="material-icons account_ticket_cashout_raise">fast_forward</i>' + cashoutWinOld + ' ' + APP_LANG_NEW_CURRENCY);
      //         $('#slip_account_ticket_cashout_status_' + ticketID).html('<i class="material-icons account_ticket_cashout_raise" style="margin-top:0px;margin-right:2px">fast_forward</i>' + cashoutWinOld + ' ' + APP_LANG_NEW_CURRENCY);

      //       } else if (cashoutWinOld > cashoutWin) {

      //         $('#ticket_' + ticketID).find('.account_ticket_cashout_status').html('<i class="material-icons account_ticket_cashout_drop">fast_rewind</i>' + cashoutWinOld + ' ' + APP_LANG_NEW_CURRENCY);
      //         $('#slip_account_ticket_cashout_status_' + ticketID).html('<i class="material-icons account_ticket_cashout_drop" style="margin-top:0px;margin-right:2px">fast_rewind</i>' + cashoutWinOld + ' ' + APP_LANG_NEW_CURRENCY);

      //       }

      //     }

      //     cashoutBtn.attr('cashoutwin', cashoutWin);
      //     cashoutBtn.find('.ticket_cashout').html(nf(cashoutWin) + ' ' + APP_LANG_NEW_CURRENCY);

      //     cashoutSlipBtn.attr('cashoutwin', cashoutWin);
      //     cashoutSlipBtn.find('.slip_ticket_cashout').html(nf(cashoutWin) + ' ' + APP_LANG_NEW_CURRENCY);

      //     cashoutHistory.css('visibility', 'visible');
      //     cashoutSlipHistory.css('visibility', 'visible');

      //     resetCashoutBtn(ticketID);

      //     break;

      //   default:

      //     break;

      // }

      result["cashoutWin"] = cashoutWin;
      result["cashoutStatus"] = cashoutStatus;

      return result;
    }

    return result;
  }

  /**
   * Show correct button ( with styles and label ) from ticketStatus value
   */
  renderButtons(ticketStatus) {
    let ticket = this.props.selectedTicket[0];
    //line below should be removed to enable cashout button
    if (ticketStatus == "pending") {
      return <div></div>;
      return (
        <div
          className={`${this.props.classes.button} vertical-center flex-end ticket-divider-chip spacer-right`}
        >
          {this.calcCashout(ticket.ticketID) ? (
            isNaN(this.calcCashout(ticket.ticketID).cashoutWin) ||
            this.calcCashout(ticket.ticketID).cashoutWin <= 0 ||
            this.getScore24Market(ticket.ticketCode) ? (
              <Chip
                disabled
                variant="outlined"
                size="small"
                style={{ fontSize: "0.75em", height: "45%" }}
                label={<Translate value="labels.cashout_no" />}
              />
            ) : (
              <Chip
                onClick={this.confirmCashout.bind(this, ticket)}
                variant={this.state.firstClick ? "contained" : "outlined"}
                size="small"
                style={{
                  backgroundColor: this.state.firstClick
                    ? "var(--color-contrast)"
                    : "",
                  color: this.state.firstClick
                    ? "var(--btn-text)"
                    : "var(--color-contrast)",
                  borderColor: this.state.firstClick
                    ? ""
                    : "var(--color-contrast)",
                  fontSize: "0.75em",
                  height: "45%",
                }}
                label={
                  this.state.isConfirming ? (
                    <span>
                      <Translate value="labels.cashout_in" />{" "}
                      {this.state.remainingSeconds}{" "}
                      <Translate value="labels.cashout_sec" />
                    </span>
                  ) : // this.state.firstClick ? <span><Translate value="labels.cashout_confirm" />  {formatCommasAndDots(this.calcCashout(ticket.ticketID).cashoutWin, this.props.locale)} {ticket.currency}</span> : <span><Translate value="tickets.cashedout" /> {formatCommasAndDots(this.calcCashout(ticket.ticketID).cashoutWin, this.props.locale)} {ticket.currency}</span>
                  this.state.firstClick ? (
                    <span>
                      <Translate value="labels.cashout_confirm" />{" "}
                      {formatCommasAndDots(
                        this.calcCashout(ticket.ticketID).cashoutWin,
                        this.props.locale
                      )}
                    </span>
                  ) : (
                    <span>
                      <Translate value="tickets.cashedout" />{" "}
                      {formatCommasAndDots(
                        this.calcCashout(ticket.ticketID).cashoutWin,
                        this.props.locale
                      )}
                    </span>
                  )
                }
              />
            )
          ) : null}
        </div>
      );
    } else {
      return (
        <div
          className={`${this.props.classes.button} vertical-center flex-end ticket-divider-chip  spacer-right`}
        >
          <Chip
            color="primary"
            variant="outlined"
            size="small"
            style={{
              borderColor: ticket.chipColor,
              color: ticket.chipColor,
              height: "45%",
              fontSize: "0.8em",
            }}
            label={ticket.chipLabel}
          />
        </div>
      );
    }
  }

  /**
   * Show information for current selected ticket
   * Ticket value, label, stake, ticketCode ,,,
   */

  renderTicket() {
    let ticket = this.props.selectedTicket[0];
    const { selectedTicketInfo, teamsDisplayOff, isSelectedGamesLoading } =
      this.props;
    const game = selectedTicketInfo?.bets[0]?.game;
    const selectedBet = selectedTicketInfo?.bets[0];

    if (!ticket) return null;

    return (
      <div>
        <div className="my-bets-title" style={{ paddingBottom: "2px" }}>
          <div
            className="color-contrast text-wrap vertical-center font-detail-1 font-bold-2 spacer-left cursor-pointer"
            onClick={(e) => this.props.parentCallBack(ticket)}
          >
            {selectedBet?.isBetBuilder ? (
              <Translate value="marketSets.betbuilder" />
            ) : (
              ticket.betTypeLabel
            )}
          </div>
          <div className="text-wrap vertical-center font-detail">
            <Translate value="labels.ticket_stake" />
          </div>
          <div className="text-wrap vertical-center font-detail flex-end spacer-right">
            {ticket.stake}
          </div>
          <div/>
          <div
            className="text-wrap vertical-center font-detail spacer-left cursor-pointer"
            onClick={(e) => this.props.parentCallBack(ticket)}
          >
            <Localize value={ticket.isoDate} dateFormat="date.extra" />
            &nbsp;- {ticket.ticketCode}
          </div>
          <div className="text-wrap vertical-center font-detail color-odds">
            <Translate value="labels.bett_odd" />:
          </div>
          <div className="text-wrap vertical-center font-detail flex-end spacer-right color-odds">
            {formatCommasAndDots(ticket?.odds, this.props.locale)}
          </div>
          {this.renderButtons(ticket.status)}
          {/* onClick={e => this.props.parentCallBack(ticket)} */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              width: "95%",
            }}
            className="ellipsis-text"
          >
            {!teamsDisplayOff &&
            !isSelectedGamesLoading &&
            game &&
            game?.isOutright ? (
              <h6 style={{ textAlign: "left", paddingLeft: "5px" }}>
                {game && game?.sport_code === "TR"
                  ? selectedTicketInfo?.bets[0]?.selectionName?.replace(
                      ".",
                      " "
                    )
                  : selectedTicketInfo?.bets[0]?.selectionName}
              </h6>
            ) : game && !isSelectedGamesLoading && !teamsDisplayOff ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  paddingLeft: "5px",
                }}
              >
                <h6 style={{ textAlign: "left", width: "max-content" }}>
                  {game?.team1}
                </h6>
                <span style={{ margin: "0 5px" }}>-</span>
                <h6 style={{ width: "max-content" }}>{game?.team2}</h6>
              </div>
            ) : isSelectedGamesLoading ? (
              <span
                style={{
                  display: "block",
                  paddingLeft: "5px",
                  fontSize: "var(--small)",
                }}
              >
                Loading...
              </span>
            ) : null}
          </div>
          {/* <div></div> */}
          <div className="color-contrast text-wrap vertical-center font-detail font-bold-2">
            {ticket.winLabel}
          </div>
          {/* <div className="text-wrap vertical-center font-detail flex-end spacer-right">{ticket.winValue}</div> */}
          <div className="color-contrast text-wrap vertical-center font-detail font-bold-2 flex-end spacer-right">
            {ticket.winValue && typeof ticket.winValue === "number"
              ? parseFloat(ticket.winValue)?.toFixed(2)
              : ticket.winValue}
          </div>
          {ticket.status == "pending" ? (
            <div key={"ticket-spacer"}></div>
          ) : null}
        </div>
      </div>
    );
  }

  /**
   * Confirm cashout request
   * Confirming cashout needs clicking cahsout button 2 times
   */
  confirmCashout(ticket) {
    let self = this;
    let { firstClick, remainingSeconds, isConfirming } = self.state;

    if (isConfirming) return;

    if (firstClick) {
      self.setState({ isConfirming: true }, () => {
        timer = setInterval(() => {
          let flag = true;

          if (ticket.hasOwnProperty("isConfirming")) {
            flag = ticket.isConfirming;
          }

          if (flag === false) {
            clearInterval(timer);
            self.setState({ remainingSeconds: 5, isConfirming: flag });

            setTimeout(() => {
              self.props.getWalletBalanceDetails(self.name);
            }, 100);
          } else {
            if (remainingSeconds > 0) {
              remainingSeconds -= 1;
              self.setState({ remainingSeconds, isConfirming: flag });
            } else {
              remainingSeconds = 5;
              self.setState({ remainingSeconds, isConfirming: flag });
            }
          }
        }, 1000);

        self.postTicketCashout(ticket);
      });
    }

    self.setState({ firstClick: !firstClick });
  }

  render() {
    log(this.constructor.name, "render");
    return <div>{this.renderTicket()}</div>;
  }
}

const mapStateToProps = (state, ownProps) => {
  const selectedTicket = state.tickets.list.filter(function (ticket) {
    return ticket.ticketID === Number(ownProps.ticketId);
  });

  const selectedTicketInfo = state.tickets.selectedTicketGames?.find(function (
    ticket
  ) {
    return ticket?.meta?.ticketID === Number(ownProps.ticketId);
  });

  return {
    selectedTicketInfo,
    selectedTicket: Object.values(selectedTicket),
    content: ownProps.content,
    calcCashoutTickets: state.tickets.calcCashout
      ? state.tickets.calcCashout
      : null,
    cashoutAvailability: state.labels ? state.labels.cashoutAvailability : null,
    locale: state.i18n.locale,
    teamsDisplayOff: ownProps.teamsDisplayOff,
    isSelectedGamesLoading: state.tickets.isSelectedGamesLoading,
  };
};

export default connect(mapStateToProps, {
  postTicketCashout,
  getWalletBalanceDetails,
})(withStyles(styles)(TicketBox));
