/**
 * Component for game box detail avatar svg
 */

import { log } from '../../config/log';
import React from 'react';
import { connect } from 'react-redux';

import './GameBoxDetailedAvatar.css';

class GameBoxDetailedAvatarLongPS extends React.PureComponent {

  renderShirt() {
    const { pattern, color1, color2 } = this.props;

    switch (pattern) {

      case "1":
        return (
          <div>
            <div className="competitor-avatar-bg"></div>
            <svg className="competitor-avatar-img" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="3em" height="3em"
              viewBox="0 0 520.7 565.9" style={{ enableBackground: "0 0 520.7 565.9" }} xmlSpace="preserve">
<path fill={color2} d="M418.8,264.3L457,535.8
		c0,0,25.7,12.9,54.6-5.3c0,0-30.2-268-30.2-268L418.8,264.3z"/>
              <path fill={color2} d="M41.5,256.7c0,0-29.6,273.8-29.6,273.8
		c28.9,18.2,54,6.7,54,6.7l38.2-280.4H41.5z"/>
              <path fill={color1} d="M488.6,263.4c-16.5-119-39.9-176.8-45.2-184.5
		C436,68,430.4,65.2,429.1,64.6c0-0.1,0-0.1,0-0.1L317.8,18.8c0,0-18.8,14.8-55.8,13.5C225.1,33.6,206,18.8,206,18.8L94.7,64.5
		c0,0-6.1,1.9-14.7,14.3c-4.9,7.1-25.5,57.6-41.6,160.5c-1.3,8.1-2.5,16.5-3.7,25.2c-3.5,25.1,75.2,3.9,75.2,3.9l2.9-20.1
		c0.9,15.4,1.4,29.9,1.4,42.3c0,76.3-19.4,244.4-19.4,244.4c79.4,20.1,167.2,20.5,167.2,20.5s87.8-0.3,167.2-20.5
		c0,0-19.4-168.1-19.4-244.4c0-12.1,0.5-26,1.3-40.8l3.4,23.5C414.3,273.3,492.2,288.8,488.6,263.4z"/>
              <path fill={color2} d="M111.5,165.3l3.7,67.1c0,0-3.2,18.9-1.6,35.3c2.8,28.3-4.1,113.8-4.1,113.8s69.8,12.4,151.6,12.4
		c75.2,0,153.2-11.9,153.2-11.9s-6.1-83.8-4.3-111.8c0.4-5.4,0.8-13.1,1.2-21.9c0.2-5.2-2.5-10-2.3-15.7c1.4-31.2,3.4-67.3,3.4-67.3
		S300.4,163,263.2,163C225.2,163,111.5,165.3,111.5,165.3z M315.9,339.8h-29.7l-38.2-75v75h-29.6V203.2h29.4l38.4,75.5v-75.5h29.7
		V339.8z"/>

            </svg>
          </div>
        );

      default:
        return (
          <div>no pattern</div>
        );
    }


  }

  render() {
    log(this.constructor.name, 'render');
    return <div>{this.renderShirt()}</div>
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    pattern: ownProps.pattern,
    color1: ownProps.color1,
    color2: ownProps.color2
  };
};

export default connect(mapStateToProps)(GameBoxDetailedAvatarLongPS);
