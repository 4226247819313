import { Box, Grid, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import CasinoGameBonusCard from "../components/CasinoGameBonusCard";
import { I18n } from "react-redux-i18n";

const FreeSpinsView = () => {
  const bonuses = useSelector((state) => state.casino.bonuses);

  return (
    <Box>
      <Typography mb={1} variant="h2" ml={1.5}>
        {I18n.t("menu.free_spins")}
      </Typography>
      <Grid container>
        {bonuses?.transactions?.map((bonusData) => {
          return bonusData?.games.map((gameData) => (
            <Grid item xs={12} sm={6} key={gameData.id}>
              <Box p={1}>
                <CasinoGameBonusCard bonus={bonusData} game={gameData} />
              </Box>
            </Grid>
          ));
        })}
      </Grid>
    </Box>
  );
};

export default FreeSpinsView;
