/**
 *  Component for showing game liveTable widget from widgets.sir.sportradar.com service
 */
 
import React from 'react';
import './theme.css';

const LiveTable = (props) => {

  React.useEffect(() => {
    setTimeout(() => {
      if (!props.gameId) {
        window.SIR("addWidget", ".sr-widget-2", "season.liveTable", { uniqueTournamentId: props.leagueId });
      } else {
        window.SIR("addWidget", ".sr-widget-2", "season.liveTable", { matchId: props.gameId });
      }
    }, 500);
  }, [props.leagueId, props.gameId]);

  return (
    <div className="widgets">
      <div><div className="sr-widget sr-widget-2"><div style={{padding: "10px 5px"}}>Loading...</div></div></div>
    </div>
  );
}

export default LiveTable;