/**
 * Component for game box detail avatar svg
 */

import { log } from '../../config/log';
import React from 'react';
import { connect } from 'react-redux';

class GameBoxDetailedAvatarNoBB extends React.PureComponent {

  renderShirt() {
    const { pattern, color1, color2 } = this.props;

    switch (pattern) {

      case "1":
        return (
          <div>
            <svg className="team-avatar" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="3em" height="3em"
              viewBox="0 0 520.7 565.9" style={{ enableBackground: "0 0 520.7 565.9" }} xmlSpace="preserve">
              <path fill={color1} d="M408,192.1c0,0-24.7-20.8-26.6-71.4c-1.6-43.5,9.7-73.4,9.7-73.4l-13.5-4.5
                c-29.6-10.4-42.9-25.9-65-23.3c-19.5,2.3-33.3,4.4-49.5,4.4s-29.9-2.1-49.5-4.4c-21.9-2.6-35.4,12.8-65,23.3l-13.5,4.5
                c0,0,11.5,29.6,9.9,73.1c-1.9,50.5-26.8,71.5-26.8,71.5l1.3,19.6c0,29.6,6,152.7,5.2,213.6c-0.6,52.2-4.4,122.7-4.4,122.7
                c35.7,4.2,115.7,7,142.8,7c27.1,0,107.1-2.8,142.8-7c0,0-4.4-70.5-4.4-122.7s5.2-184,5.2-213.6L408,192.1z"/>
              <path fill={color2} d="M118.8,204.1l5.9,219.5l276.9,0.5l5.6-217.3L118.8,204.1z M317,382h-29.7l-38.2-75v75h-29.6V245.5
                h29.4l38.4,75.5v-75.5H317V382z"/>
              <path fill={'#00000050'} d="M330.9,30.6l-135.6-0.5c-3,2.3-7,13.4,12.2,37.4c12.3,15.4,34.8,30.8,54.9,34.9
                c20.1-4.2,45.7-20.7,57.7-36.3C335,46.7,333.9,32.9,330.9,30.6z"/>
              <path fill={'#b3b3b3'} d="M367,115.2c0.1-43.5,10.6-72.3,10.6-72.3
                l13.5,4.5c0,0-10.3,29.6-8.7,73.1c1.9,50.5,26.1,71.4,26.1,71.4l-1.8,19.7C406.8,211.6,366.9,181.4,367,115.2z"/>
              <path fill={'#b3b3b3'} d="M159.2,115.2c-0.1-43.5-10.6-72.3-10.6-72.3l-13.5,4.5c0,0,10.3,29.6,8.7,73.1
                c-1.9,50.5-26.1,71.4-26.1,71.4l1.8,19.7C119.5,211.6,159.3,181.4,159.2,115.2z"/>
              <path fill={'#b3b3b3'} d="M338.7,24.7c-9-4.3-17.2-7.1-26.1-6.1c-0.5,0.1-34,4.4-49.5,4.4c-15.7,0-49-4.3-49.5-4.4
                c-8.9-1-17,1.7-26.1,6.1c-0.3,0.2-15.6,8.6,5.8,42.9c8.7,14,31.9,39.2,69.7,47.5c37.9-8.3,61-33.5,69.7-47.5
                C354.3,33.3,339,24.9,338.7,24.7z M315.6,63.2c-12,15.6-32.3,30.2-52.5,34.3c-20.1-4.2-40.5-18.8-52.5-34.3
                c-9.2-12-13.4-23.1-14.6-30.3c10.8,2.3,32.2,7.1,67.1,7.1c34.4,0,56.1-4.6,67-7C329,40.3,324.8,51.3,315.6,63.2z"/>
              <path fill={'#00000040'} d="M383.4,542.7c6.3-9.6,8.1-21,7.9-32.7
                c-0.3-17.1,1.4-30.3-1.6-47c-11.3-63.3-22.1-140.6-16-125.9c6.3,14.9,19,26.2,20.2,1.3c0-0.7-39.3-140.1-33.1-132.7
                c49.4,58.9,45.7,6.5,45.9,6c-2.7,100.8-5.1,171.7-4.9,225.2c0.2,74.3,3.7,110.9,3.7,110.9c-8.6,2.4-20.5,0.7-29.1,2.6
                C379.1,548.2,381.4,545.7,383.4,542.7z"/>
              <path fill={'#00000040'} d="M176.7,529c-10.7-7.1-23.7-22.8-26-36.4
                c-5.9-35.2-3.1-59.1,4.2-92.3c4.6-21.2,6.2-55.8,12-76.2c5.5-19.2,20.8-51.9,16.1-60.9c-1.5-2.9-11.7,27.5-17.3,37.7
                c-4,7.3-13.2,26.1-23.3,21.1c-6.8-3.3-2.3-20.7-1.6-26.3c4.2-34.2,14.9-65.1,25.4-97.6c12.3-38.3,15.4-50.5,7.7-34.6
                c-6.3,13-16.4,27.1-28.9,34c-0.8,0.5-1.2-0.4-2.1-0.9c-6.4-4.3-23.3,15-23.3,15c8.2,180.4,1,336.1,1,336.1c46.1,5.3,96,5,125.4,7.2
                c-8.7-1.7-17.3-3.9-25.8-6.5C205.5,544,191,538.4,176.7,529z"/>
            </svg>
          </div>
        );

      default:
        return (
          <div>no pattern</div>
        );
    }


  }

  render() {
    log(this.constructor.name, 'render');
    return <div>{this.renderShirt()}</div>
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    pattern: ownProps.pattern,
    color1: ownProps.color1,
    color2: ownProps.color2
  };
};

export default connect(mapStateToProps)(GameBoxDetailedAvatarNoBB);
