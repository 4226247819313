/**
 * Reducer for settings
 ** Theme, layout, view mode, nav mode, market layout, see, odds format, ticket filter, maintenance, favorites layout, etc
 */

import hostConfig from '../config/hostConfig';

import {
    SET_THEME,
    SET_LAYOUT,
    SET_MODE,
    SET_NAV_MODE,
    SET_MARKETS_LAYOUT,
    SET_URL,
    SET_HOST,
    SET_IS_MOBILE,
    SET_SSE,
    SET_ODDS_FORMAT,
    SET_FAVORITES_LAYOUT,
    SET_LEAGUE_GROUPING,
    SHOW_RESULTS_FIRST,
    SAVE_MARKET_SET_CONFIG,
    SAVE_TICKET_FILTER,
    TOGGLE_MY_TICKETS_PANEL,
    SET_MAINTENANCE_MODE,
    SHOULD_CONFIRM_TERMS,
    SET_LIVECHAT_WIDGET,
} from '../actions/types';
import playerPropsConfig from '../config/playerPropsConfig';

const canHover = window.matchMedia('(hover: hover)').matches;
let defaultLayout = canHover ? 'mouse' : 'touch';

const INITIAL_STATE = {
    theme: 'dark',
    layout: defaultLayout,
    mode: 'compact',
    oddsFormat: 'decimal',
    ticketListStatus: 'pending',
    marketSets: {},
    marketsLayout: 'horizontal',
    favoritesLayout: 'list',
    leagueGrouping: false,
    showMyTicketsPanel: false,
    showResultsFirst: false,
    showCompetitorAvatar: false,
    tvMode: false,
    sse: false,
    maintenanceMode: false,
    notificationEnabled: false,
    notificationContent: "",
    shouldConfirmTerms: false,
    playerProps: playerPropsConfig,
    isLiveChatOpen: false,
}

const fn = (state = INITIAL_STATE, action) => {

    switch (action.type) {
        case SHOULD_CONFIRM_TERMS:
            return { ...state, shouldConfirmTerms: action.payload };
        case SET_THEME:
            return { ...state, theme: action.payload };
        case SET_LAYOUT:
            return { ...state, layout: action.payload };
        case SET_MODE:
            return { ...state, mode: action.payload };
        case SET_NAV_MODE:
            return { ...state, navMode: action.payload };
        case SET_MARKETS_LAYOUT:
            return { ...state, marketsLayout: action.payload };
        case SET_URL:
            return { ...state, url: action.payload };
        case SET_HOST:
            let host = localStorage.getItem('host') ? hostConfig[localStorage.getItem('host').replace(/\./g,'_')] : action.payload;
            return { ...state, host: host };
        case SET_IS_MOBILE:
            return { ...state, isMobile: action.payload };
        case SET_SSE:
            return { ...state, sse: action.payload };
        case SET_ODDS_FORMAT:
            return { ...state, oddsFormat: action.payload };
        case SET_FAVORITES_LAYOUT:
            return { ...state, favoritesLayout: action.payload };
        case SET_LEAGUE_GROUPING:
            return { ...state, leagueGrouping: action.payload };
        case SHOW_RESULTS_FIRST:
            return { ...state, showResultsFirst: action.payload };
        case SAVE_MARKET_SET_CONFIG:
            return { ...state, marketSets: action.payload };
        case TOGGLE_MY_TICKETS_PANEL:
            const { flag } = action;
            if (flag) {
                return { ...state, showMyTicketsPanel: false };
            }

            return { ...state, showMyTicketsPanel: state.showMyTicketsPanel ? false : true };
        case SAVE_TICKET_FILTER:
            return { ...state, ticketListStatus: action.payload };

        case SET_MAINTENANCE_MODE:
            let hasNotification = Boolean(action.payload?.notification);
            return { ...state, maintenanceMode: action.payload?.maintenance, notificationEnabled: hasNotification, notificationContent: action.payload?.notification };
        case SET_LIVECHAT_WIDGET:
            return {...state, isLiveChatOpen: true}
        default:
            return state;
    }
};

export default fn;