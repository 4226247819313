/**
 * SVG component for own icon 
 */

import React from 'react';
import './IconOwn.css';

const IconBETBUILDERMENUBOTTOM = (props) => {

	return (
		<div style={{ display: "flex" }}>
			<svg className="icon-own" viewBox="0 0 2000 2000">

				<path fill={'var(--color-icon-1)'} d="M1373.3,935.2l-21.5-144.9c-14.2-97.6-89.5-175.1-186.6-192.1l-65.6-11.5c1.4-34.7,9-135.6,7.9-169.5H220.6
		c-14.5,0.2-26.1,12.1-25.9,26.6c0.1,6.7,2.8,13.2,7.5,18l153.5,153.8l36,644.6c1.7,31.2,14.9,60.6,37,82.7
		c-80.3,31.6-133,109-133.1,195.3v170.6c0,14.5,11.8,26.2,26.2,26.2h809.9c14.5,0,26.2-11.8,26.2-26.2V1538
		c0-86.2-52.8-163.7-133.1-195.3c22.1-22,35.3-51.5,37-82.7l2.6-46.2c0.4-0.2,0.9-0.3,1.3-0.3l117.6-18.9
		C1306.8,1174.1,1391,1058.9,1373.3,935.2L1373.3,935.2z M726.9,1633.6c-134.7-3.4-134.6-200.8,0-204.2
		C861.5,1432.7,861.5,1630.2,726.9,1633.6L726.9,1633.6z M1175.1,1142.8l-117.1,19.2c-6.2,1.2-16.3,0.2-23.6,1.3
		c-12.3,2.4-21.1,13.2-21,25.7l-3.7,68.2c-2.3,39.6-35.1,70.7-74.8,70.9H518.8c-39.7-0.2-72.5-31.2-74.8-70.9l-36.2-654.8
		c-0.5-6.4-3.3-12.5-7.9-17.1L284.1,469.6h769.2l-7.6,138.3c-0.9,19.4,15.9,28.6,33.3,28.3l76.9,13.6c74.9,13.1,133,72.8,144.1,148
		l21.3,144.9C1335,1038.1,1270.1,1126.9,1175.1,1142.8L1175.1,1142.8z"/>
				<path fill={'var(--color-icon-1)'} d="M978.8,815.8l5.2-94.5c-69.1,0.9-137.1,17.1-199.2,47.2C845.5,797.7,911.6,813.8,978.8,815.8z" />
				<path fill={'var(--color-icon-1)'} d="M714.5,791.6c-74.3-44-158.6-68.2-244.9-70.3l31,558h452.5L975.9,868C883.8,864.6,794,838.4,714.5,791.6L714.5,791.6z" />
				<path fill={'var(--color-icon-1)'} d="M1251.8,805c-7.9-54.5-50-97.7-104.2-107.1l-76.1-13.4c-1.7-0.4-3.5-0.6-5.2-0.5c-14.5,0.1-26.1,11.9-26,26.3
		c0,0.3,0,0.6,0,1l-21.3,375c-0.2,7.2,2.4,14.2,7.3,19.4c5.9,6.5,14.7,9.5,23.4,7.9l117.6-18.9c68.7-11.5,115.8-75.6,106-144.6
		L1251.8,805z M1159.1,1043l-85.8,13.6l17.8-315.5l47.5,8.4c31.9,5.5,56.6,31,61.1,63l21.5,145.1
		C1227.1,998.3,1199.5,1036.1,1159.1,1043L1159.1,1043z"/>
				<path fill={'var(--color-icon-1)'} d="M726.9,1481.9c-27.4,0-49.6,22.2-49.6,49.6s22.2,49.6,49.6,49.6c27.4,0,49.6-22.2,49.6-49.6
		C776.5,1504.1,754.3,1481.9,726.9,1481.9z"/>
				<path fill={'var(--color-icon-1)'} d="M979.9,265.1h-506c-59.6,0.1-111.2,41.4-124.4,99.5h754.8C1091.1,306.5,1039.5,265.2,979.9,265.1L979.9,265.1z" />




			</svg>
		</div>
	);
};

export default IconBETBUILDERMENUBOTTOM;
