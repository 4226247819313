/**
 * Menu configurations
 */

export const menuConfig = {
    FB: {
        prematch: ['favorites', 'mainbets', 'outrights', 'asianhc', 'ou', 'handicap', 'bothscore', 'firsthalf', 'standings', 'results'],
        // live: ['favorites', 'mainbets', 'rest', 'asianhc', 'ou', 'handicap', 'firsthalf'],
        live: ['favorites', 'mainbets'],
        sidebets: ['favorites', 'mainbets', 'betbuilder', 'goalscorer', 'player', 'configbets', 'firsthalf', 'corners', 'bookings', 'h2h', 'standings', 'matchstatistics', 'summary'],
    },
    TE: {
        prematch: ['favorites', 'mainbets',  'outrights', 'firstset', 'results'],
        live: ['favorites', 'mainbets'],
        sidebets: ['favorites', 'mainbets','betbuilder', 'player', 'firstset', 'h2h']
    },
    BA: {
        prematch: ['favorites', 'mainbets', 'outrights', 'firsthalf', 'standings', 'results'],
        live: ['favorites', 'mainbets'],
        sidebets: ['favorites', 'mainbets','betbuilder', 'player', 'firsthalf', 'h2h', 'standings', 'matchstatistics', 'summary']
    },
    IH: {
        prematch: ['favorites', 'mainbets', 'outrights', 'firstperiod', 'standings', 'results'],
        live: ['favorites', 'mainbets'],
        sidebets: ['favorites', 'mainbets', 'betbuilder', 'player', 'firstperiod', 'h2h', 'standings']
    },
    HB: {
        prematch: ['favorites', 'mainbets', 'outrights', 'firsthalf', 'standings', 'results'],
        live: ['favorites', 'mainbets'],
        sidebets: ['favorites', 'mainbets', 'betbuilder', 'player', 'firsthalf', 'h2h', 'standings'],
    },
    DA: {
        prematch: ['favorites', 'mainbets', 'outrights', 'results'],
        live: ['favorites', 'mainbets'],
        sidebets: ['favorites', 'mainbets']
    },
    BX: {
        prematch: ['favorites', 'mainbets', 'outrights', 'results'],
        live: ['favorites', 'mainbets'],
        sidebets: ['favorites', 'mainbets']
    },
    AF: {
        prematch: ['favorites', 'mainbets', 'outrights', 'firsthalf', 'standings', 'results'],
        live: ['favorites', 'mainbets'],
        sidebets: ['favorites', 'mainbets', 'betbuilder', 'player', 'firsthalf', 'h2h', 'standings']
    },
    RG: {
        prematch: ['favorites', 'mainbets', 'outrights', 'results'],
        live: ['favorites', 'mainbets'],
        sidebets: ['favorites', 'mainbets', 'betbuilder',]
    },
    BB: {
        prematch: ['favorites', 'mainbets', 'outrights', 'results'],
        live: ['favorites', 'mainbets'],
        sidebets: ['favorites', 'mainbets', 'betbuilder', 'player']
    },
    SN: {
        prematch: ['favorites', 'mainbets', 'outrights', 'results'],
        live: ['favorites', 'mainbets'],
        sidebets: ['favorites', 'mainbets']
    },
    TT: {
        prematch: ['favorites', 'mainbets', 'outrights', 'results'],
        live: ['favorites', 'mainbets'],
        sidebets: ['favorites', 'mainbets', 'h2h']
    },
    CR: {
        prematch: ['favorites', 'mainbets', 'outrights', 'results'],
        live: ['favorites', 'mainbets'],
        sidebets: ['favorites', 'mainbets',]
    },
    CS: {
        prematch: ['favorites', 'mainbets', 'outrights', 'results'],
        live: ['favorites', 'mainbets'],
        sidebets: ['favorites', 'mainbets']
    },
    DT: {
        prematch: ['favorites', 'mainbets', 'outrights', 'results'],
        live: ['favorites', 'mainbets'],
        sidebets: ['favorites', 'mainbets']
    },
    LL: {
        prematch: ['favorites', 'mainbets', 'outrights', 'results'],
        live: ['favorites', 'mainbets'],
        sidebets: ['favorites', 'mainbets']
    },
    VB: {
        prematch: ['favorites', 'mainbets', 'outrights', 'standings', 'results'],
        live: ['favorites', 'mainbets'],
        sidebets: ['favorites', 'mainbets', 'h2h', 'standings']
    },
    BV: {
        prematch: ['favorites', 'mainbets', 'outrights', 'results'],
        live: ['favorites', 'mainbets'],
        sidebets: ['favorites', 'mainbets', 'h2h']
    },
    BM: {
        prematch: ['favorites', 'mainbets', 'outrights', 'results'],
        live: ['favorites', 'mainbets'],
        sidebets: ['favorites', 'mainbets', 'h2h']
    },
    SQ: {
        prematch: ['favorites', 'mainbets', 'outrights', 'results'],
        live: ['favorites', 'mainbets'],
        sidebets: ['favorites', 'mainbets', 'h2h']
    },
    WP: {
        prematch: ['favorites', 'mainbets', 'outrights', 'results'],
        live: ['favorites', 'mainbets'],
        sidebets: ['favorites', 'mainbets', 'h2h']
    },
    FL: {
        prematch: ['favorites', 'mainbets', 'outrights', 'results'],
        live: ['favorites', 'mainbets'],
        sidebets: ['favorites', 'mainbets']
    },
    AR: {
        prematch: ['favorites', 'mainbets', 'outrights', 'results'],
        live: ['favorites', 'mainbets'],
        sidebets: ['favorites', 'mainbets', 'betbuilder',]
    },
    BD: {
        prematch: ['favorites', 'mainbets', 'outrights', 'results'],
        live: ['favorites', 'mainbets'],
        sidebets: ['favorites', 'mainbets', 'h2h']
    },
    FS: {
        prematch: ['favorites', 'mainbets', 'outrights', 'results'],
        live: ['favorites', 'mainbets'],
        sidebets: ['favorites', 'mainbets', 'h2h']
    },
    CU: {
        prematch: ['favorites', 'mainbets', 'outrights', 'results'],
        live: ['favorites', 'mainbets'],
        sidebets: ['favorites', 'mainbets']
    },
    MM: {
        prematch: ['favorites', 'mainbets', 'outrights', 'results'],
        live: ['favorites', 'mainbets'],
        sidebets: ['favorites', 'mainbets']
    },
    PS: {
        prematch: ['favorites', 'mainbets', 'outrights', 'results'],
        live: ['favorites', 'mainbets'],
        sidebets: ['favorites', 'mainbets']
    },
    CH: {
        prematch: ['favorites', 'mainbets', 'outrights', 'results'],
        live: ['favorites', 'mainbets'],
        sidebets: ['favorites', 'mainbets']
    },
    B3: {
        prematch: ['favorites', 'mainbets', 'outrights', 'results'],
        live: ['favorites', 'mainbets'],
        sidebets: ['favorites', 'mainbets']
    },
    BV: {
        prematch: ['favorites', 'mainbets', 'outrights', 'results'],
        live: ['favorites', 'mainbets'],
        sidebets: ['favorites', 'mainbets']
    },
    FH:{
        prematch: ['favorites', 'mainbets', 'outrights', 'results'],
        live: ['favorites', 'mainbets'],
        sidebets: ['favorites', 'mainbets']
    }

};



