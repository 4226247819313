/**
 * BettingRules - English version
 */

import { log } from "../../../config/log";
import React from "react";
import { connect } from "react-redux";
import Typography from "@mui/material/Typography";
import withStyles from "@mui/styles/withStyles";
import Container from "@mui/material/Container";
import ListItem from "@mui/material/ListItem";
import Obfuscate from "react-obfuscate";

const styles = (theme) => ({
  root: {
    padding: "1em 0",
    fontSize: "var(--content)",
  },
});

class BettingRules extends React.PureComponent {
  goTop = () => {};

  render() {
    log(this.constructor.name, "render");
    const { host, classes } = this.props;

    if (!host) return null;

    return (
      <Container style={{ backgroundColor: "var(--color-main)" }} maxWidth={false}>
        <Typography component="span">
          <div id="legalcontent" className={classes.root}>
            <h1>NUBET.COM BETTING RULES</h1>
            English version 1.3 Last update 7.2.2024.<br></br>
            <br></br>
            <h3 id="General">1. GENERAL INFORMATION</h3>
            1.1. Betting organizer Nubet and the website Nubet.com is operated by Vana Lauri OÜ, a company registered in Estonia under company registration number 16427120 having its registered address at Roseni 10-135,
            10111 Tallinn, Estonia (hereinafter referred to as “Nubet” or “The Company”). Vana Lauri OÜ is licensed and supervised by the Estonian Tax and Customs Board and is holding the following licenses: HKT000066
            (date of issue: 10.07.2022) and HKT000067 (date of issue: 10.07.2022).<br></br>
            <br></br>
            1.2. These Nubet Betting Rules apply to Nubet’s Sports Betting on the website Nubet.com and govern the provision of betting played with real money provided by Nubet on its website Nubet.com (hereinafter
            referred also as “Nubet Betting”). If the player does not agree with these Betting Rules and Nubet’s Terms and Conditions, they should immediately stop using Nubet Betting.<br></br>
            <br></br>
            1.3. Nubet Betting Rules are part of the Nubet Terms and Conditions accepted during the Customer registration process at the Nubet’s website.<br></br>
            <br></br>
            1.4. The player agrees that he or she has read and understood the Nubet Betting Rules when placing a bet.<br></br>
            <br></br>
            1.5. The Company may from time to time need to amend these Nubet Betting Rules. If amendments are made to these Nubet Betting Rules, the amendments will always be informed separately on the Nubet website
            Nubet.com. Nubet reserves the right to update Betting Rules at any time.<br></br>
            <br></br>
            1.6. In the event of any discrepancy between the Nubet Terms and Conditions and Nubet Betting Rules, the Nubet Betting Rules will apply. In the event of discrepancy between different areas of the Nubet
            Betting Rules, following order will apply: In case of any contradictions or missing sport specific rules, Specific Rules takes precedence over Bet Rules, and Bet Rules takes precedence over General Rules.
            <br></br>
            <br></br>
            1.7. When placing a bet at Nubet Betting the player must acknowledge that placed bets are considered as products that are consumed immediately and by default the Company cannot be considered liable to return,
            cancel or refund bets and stakes, which the customer has placed. This applies before the relevant betting event starts, during the relevant betting event, or after the relevant betting event with exception of
            cancellation of the bet or cash-out that has been carried out in a separately permitted way in accordance with the Company's Betting Rules. Please note, that Nubet Betting does have an option to cash-out
            placed bets under certain circumstances and conditions which are explained in its own section in Nubet Betting Rules.<br></br>
            <br></br>
            1.8. Nubet Betting offers betting products where the risk of loss falls entirely for the player when he or she decides to place the stake or bet provided on and organized by the Nubet Betting.<br></br>
            <br></br>
            1.9. Nubet Betting reserves the right to restrict or block a player's access to Nubet Betting products, offers, or services without explanation at the Company's sole discretion.<br></br>
            <br></br>
            1.10. The software related to, or which enables use of Nubet Betting products, and other services, offered to the player by Nubet Betting, may be provided partially or completely through a third-party
            provider that has a contractual relationship with Nubet. Software is protected by copyright laws and intellectual property protection. The player may use the software only for personal and recreational
            purposes in accordance with all applicable laws, rules, and regulations.<br></br>
            <br></br>
            1.11. At Nubet Betting, the use of digital aids such as robots or mechanical, electronic, or other devices to manipulate or lead on the software used in Nubet Betting is prohibited. The company has the right
            to suspend the game account, suspend deposits and withdrawals, and to confiscate all related winnings, if there is reasonable suspicion that such an aid or device has been used.<br></br>
            <br></br>
            1.12. Under no circumstances may the player use any type of error, malfunction, virus, bug, defect, or inconsistencies in the software used at Nubet Betting for his/her own benefit or to the detriment of
            other players. The player must report such an incident to customer service immediately after the discovery.<br></br>
            <br></br>
            1.13. Breach of Nubet Betting Rules or Nubet Terms and Conditions applicable to the Nubet Betting may lead to disqualification of the player from placing bets, participation to promotions, bonus offers or
            special offers without further notice.<br></br>
            <br></br>
            1.14. If Nubet determines that funds won by the player came from an account that has obtained these winnings fraudulently and/or in breach of these Nubet Betting Rules or Nubet Terms and Conditions, Nubet
            reserves the right to withhold or confiscate those winnings or credit balance. All players involved in any suspected fraudulent activity may be reported to the relevant authorities.<br></br>
            <br></br>
            1.15. Funds can be deposited at Nubet Betting by using payment methods offered by Nubet Betting, which can be found under “Deposit” in the menu.<br></br>
            <br></br>
            1.16. Funds can be withdrawn at Nubet Betting by requesting a pay-out and choosing available withdrawal method, which can be found under “Withdrawal” in the menu. The available withdrawal method may depend on
            the used deposit method.<br></br>
            <br></br>
            1.17. Each clause of these Nubet Betting Rules is separate and severable from all others. If any clause is found to be void, invalid, or unenforceable for any reason, the other clauses will remain in full
            force and effect.<br></br>
            <br></br>
            1.18. If the player account goes into negative balance, Nubet has the right to recover these funds from the customer, and the customer has the obligation to correct the funds he or she received in error to
            the Company.<br></br>
            <br></br>
            1.19. If you have any questions about Nubet Betting, please contact <Obfuscate email={"support@nubet.com"}>support@nubet.com</Obfuscate>.<br></br>
            <br></br>
            <h3 id="Rules">2. GENERAL RULES</h3>
            <br></br>
            <br></br>
            For each sport, there are specific betting offers and evaluation criteria. Below is a list of the most popular and the mostly offered types of sports. Test company 123 will also offer odds for other types of
            sports, depending on what is available and the sports calendar. The general rules apply for sports that are not listed.<br></br>
            <br></br>
            <h4>2.1. Placing a bet</h4>
            2.1.1. Bets can be placed as single, multiple, system or Betbuilder bets.<br></br>
            <br></br>
            2.1.2. Single bet means betting one selection only whereas multiple bet means combining multiple selections into one bet.<br></br>
            <br></br>
            2.1.3. In addition to single and multiple bets, a bet can also be placed as a system bet. In a system bet, you combine several single/multiple bets into one bet transaction.<br></br>
            <br></br>
            2.1.4. So-called banker is a special betting system product. The banker choice is a combination of bets in a betting slip. For a banker to be successful and to lead the player to win the banker, every bet
            included must be a winning bet. The player can choose bets on the betting slip as banker by clicking the dedicated button on the bet slip. The amount of system bets will only be calculated on the matches that
            are not chosen as a banker.<br></br>
            <br></br>
            2.1.5. Betbuilder allows to create a bet by selecting multiple selections for the same event, i.e. the same football match. For example, you predict that a specific player will score a goal and that the game
            will have more than 2.5 goals. In general, Betbuilder bet is treated as a single bet.<br></br>
            <br></br>
            <h4>2.2. Bet Receipt</h4>
            2.2.1. The bet receipt is a contract between the player and Nubet Betting which is automatically created when a bet is placed by the player and accepted by the Nubet Betting.<br></br>
            <br></br>
            2.2.2. The validity of the bet receipt and contract between the player and Nubet Betting depends on whether the event has been properly recorded on the central computer of the bookmaker. This can be viewed
            and confirmed in the account information.<br></br>
            <br></br>
            2.2.3. In the event when bet receipt is provided automatically by the software but the actual bet is not recorded properly on the central computer of the bookmaker, placed bet may be declared null and void.
            <br></br>
            <br></br>
            2.2.4. The customer may correct or delete the completed virtual betting slip at any time in Nubet Betting before placing it. The customer is solely responsible for the selection of the bets he or she makes
            and cannot claim that the information and recommendations made available and/or provided by Nubet Betting were possibly incorrect or incomplete.<br></br>
            <br></br>
            2.2.5. The player approves the odds indicated on the betting slip at the time of placing a bet. The player is also aware and approves that the odds can deviate from the odds at the time the betting slip is
            sent off, particularly in live betting with the built-in delay of the acceptance of the bet.<br></br>
            <br></br>
            <h4>2.3. Obligation</h4>
            2.3.1 There is no obligation for Nubet Betting to disclose additional information, such as the type of competition (e.g., league game or cup game), shortened/extended playing time (e.g., in the case of
            friendly matches, mini- tournaments, youth matches, indoor tournaments, etc.), match venues (e.g., neutral venue), race distances, etc.<br></br>
            <br></br>
            2.3.2. If aforementioned information is provided it is presented without a guarantee of accuracy and has no influence on the settlement of the bet. The same applies for all types of statistics, tables, and
            scores.<br></br>
            <br></br>
            <h4>2.4. Settlement of bets</h4>
            2.4.1 The bets are settled after the announcement of the official results, unless otherwise specified in there Betting Rules.<br></br>
            <br></br>
            2.4.2. Any type of complaint related to the settlement, or the crediting of betting winning must be provided in writing and sent to Nubet via e-mail or post within 14 weekdays after the settlement.<br></br>
            <br></br>
            <h4>2.5. Reversed bet</h4>
            2.5.1 Nubet Betting reserves a right to reverse a bet, which means that the bet is no longer valid, and the stake amount is returned to the player, regardless of whether an event has been settled or not. This
            also applies to cashed out bets.<br></br>
            <br></br>
            2.5.2 In case a bet is reversed by Nubet Betting, odds will be set to the equivalent of getting the stake back, meaning that pay-out amount will be equal to 1.00 odds.<br></br>
            <br></br>
            2.5.3 A bet placed as a Multiple or System bet will remain valid excluding the reverse bet part of a Multiple or System bet, whenever possible. This means that the reverse bet is excluded from the final odds
            of the Multiple or System bet and does not need to succeed for a Multiple or System bet to be a winning bet.<br></br>
            <br></br>
            2.5.4 If the betting event does not take place as described in the bet slip e.g., wrong participants or the venue for an event is changed a bet may be considered void and odds are set to 1.00 by Nubet
            Betting.<br></br>
            <br></br>
            2.5.5 If the event is cancelled and/or does not start according to the intended time and within the period included as exception to this rule explained in section 2.5. Reversed bet may be considered void and
            odds are set to 1.00 by Nubet Betting. If a match or event is postponed and completed within 48 hours of the original scheduled start time, then all open bets will be settled with the result.<br></br>
            <br></br>
            2.5.6 If the match or event is not completed within 48 hours all related bets may be considered void. Matches and events which have their start time altered well in advance to accommodate live TV, or to ease
            fixture congestion, or another understandable and well-announced reason as described, may be excluded from this rule, and considered to be postponed for an understandable reason known in advance.<br></br>
            <br></br>
            2.5.7 The bets may also remain valid for longer period than 48 hours if the betting event is a part of a tournament (tennis tournament, Olympic Games, World Championships, European Championships, etc.) and is
            completed during this tournament and in accordance with the rules of the tournament but at a different time.<br></br>
            <br></br>
            2.5.8 Bets whose outcome and result has already been decided before the discontinuation of the game (e.g., bet types, such as over/under, set betting, first goal, etc.) and bets which outcome is settled after
            the start of the event being bet on are not reversed and will be settled in any case.<br></br>
            <br></br>
            2.5.9 Bets that are offered continuously by Nubet Betting or are particularly dependent on its current time, offered even after the start of the event, e.g. long term bets or live bets that could be completed
            after the start of the event are mainly outside the scope of these rules.<br></br>
            <br></br>
            2.5.10 If an event ends undecided or in a draw in a situation where no odds were offered for an undecided result or a draw, (e.g. baseball friendlies, American football, etc.) a bet may be considered void and
            odds are set to 1.00 by Nubet Betting.<br></br>
            <br></br>
            2.5.11 If there is an obvious error in displayed odds (e.g. split quotas, wrong decimal places etc.) a bet may be considered void and odds are set to 1.00 by Nubet Betting.<br></br>
            <br></br>
            2.5.12 If at the time of the bet acceptance, due to a technical defect on the website, or in bookmaker’s systems, or a third-party system providing a necessary function for the bookmaker or for the Nubet
            Betting to give the correct odds, or technical error relevant to the bookmaker or Nubet Betting for correct functioning and in the fulfilment of neither functions related to supply of betting products, where
            incorrect odds become the basis of the betting contract, a bet may be considered void and odds are set to 1.00 by Nubet Betting. This includes any clear deviations that could have been assumed to be erroneous
            compared to the average market price at the time.<br></br>
            <br></br>
            2.5.13 It’s forbidden to place multiple, or system bet within the same coupon which has a correlation. Despite taking all necessary measures to prevent such betting possibilities, but in cases it should
            happen, Nubet reserves the right, at its sole discretion, to cancel all parts of multiple bets which include the correlated outcomes. Such bets could be deemed void even after the result of the event is
            known.<br></br>
            <br></br>
            2.5.14 In Betbuilder bet all selections must win (no ties, draws or pushes) for a bet to be deemed a winner. A selection that does not win, will be settled as a loser. If any individual selection is void, or
            a player in the bet does not take any part in the match, then the whole bet will be made void.<br></br>
            <br></br>
            <h4>2.6. Change of home advantage</h4>
            2.6.1 In matches where one of the teams is playing at home (league match, cup match, international competition, etc.), the team first mentioned on the betting slip is always the home team. This rule does not
            necessarily apply to tournaments, as the host team is not always regarded as the home team which might depend on certain specification on the tournament.<br></br>
            <br></br>
            2.6.2 If the match is played elsewhere than at the home team’s regular home venue due to any specified or unspecified reason, all placed bets retain their validity, unless the home team is changed meaning
            that the original home team will be declared and refined as the away team by the responsible association.<br></br>
            <br></br>
            <h4>2.7. Bet result</h4>
            2.7.1. Only the results announced officially by the responsible association are decisive for the settlement of a bet immediately after the end of the betting event.<br></br>
            <br></br>
            2.7.2. Subsequent changes of the result/ranking by the responsible association do not play a role in the settlement of the bet.<br></br>
            <br></br>
            2.7.3. Nubet may receive official results from the third-party partners.<br></br>
            <br></br>
            2.7.4. Nubet attempts to settle markets and obtain final bet result as soon as possible after the betting event completion but in some exceptional occasions cannot guarantee and define in advance the maximum
            duration or delay to settle the bet. This is due to factors beyond Nubet's control and Nubet is committed to settling bets as soon as possible every time.<br></br>
            <br></br>
            2.7.5. When the result of a bet has been determined before the event is complete, the bet may be settled according to Nubet Betting Rules even if the event is abandoned or the length of the event is
            shortened.<br></br>
            <br></br>
            2.7.6. In the case of tournaments with a knock-out system in individual sports (tennis, darts, snooker, etc.), if the game is aborted, (due to injury, etc.), the participant who goes onto the next round (in
            the case of a final of a play-off match, the participant who is declared the winner) is regarded as the winner. It does not matter whether he enters the next round or not. All bets on the exact result (score
            bets, set bets, handicap bets, etc.) may be considered void and odds are set to 1.00 by Nubet Betting. Bets whose content has already been decided before the game was aborted (under/over, 1 Set, etc.) are
            excluded from this rule and are settled in any case.<br></br>
            <br></br>
            2.7.7. For events where a podium presentation or medal ceremony is held then those events will be the cut-off time for settlements. Subsequent disqualifications and amendments after the podium presentation
            will not count for betting purposes. This also includes appeal, lawsuit, drug testing result, or any other reasons.<br></br>
            <br></br>
            2.7.8. Nubet reserves the right to correct incorrect settlement of markets when necessary.<br></br>
            <br></br>
            <h4>2.8. Dead heat</h4>
            2.8.1. In the case of two or more winners (e.g., Top Goal scorer), situation also referred to as dead heat, the bet winning amount is divided accordingly e.g. bet stake 100 EUR, odds 1.80 would result to bet
            outcome with a pay-out of 180 and thus 80 will be the amount of the profit share, with two winners the profit share is 40 (80 divided by two) and the pay-out is therefore the original stake to which is added
            the profit share of 40 leading to a final pay-out of amount 140 EUR. With more matching results the winning amount is divided with the same method and final pay-out will be the original stake added with the
            shared profit.<br></br>
            <br></br>
            2.8.2. Dead heat exception No 1: “Head-to-head " ("winner vs. loser") bets, which are described at their own section in these Nubet Betting Rules.<br></br>
            <br></br>
            2.8.3. Dead heat exception No 2: There are certain market types which contain more than one winning selections. Examples of such as Double Chance, Anytime Goal Scorer and Top X. In these bet types and related
            bet markets dead heat rules do not apply. However, if in Top X market type there are more selections than was originally defined in the market description, then dead heat rules apply to the tied selections.
            <br></br>
            <br></br>
            <h4>2.9. Outrights</h4>
            2.9.1. Outright markets (also known as Futures) are considered All In Run Or Not and therefore will be settled as a loss if the selection does not take part in the event. Dead Heat rules apply.<br></br>
            <br></br>
            2.9.2. Unless stated otherwise, whenever the organising association includes any necessary rounds, matches, or series of matches (e.g. Play-offs, Play-outs, Postseason) following the end of the so-called
            Regular Season in order to determine the classification, league winners, promotion/relegation, etc., Nubet will consider the results and outcomes deriving from these matches for settlement purposes of tickets
            referring to the final league classification, promotion, relegation, etc. For example, seasonal bets on the team winning the NHL will refer to the Stanley Cup Winners.<br></br>
            <br></br>
            2.9.3. All bets are settled on the basis of the award ceremony or the competition’s official score-sheet, without taking into consideration the results of any subsequent investigations or disqualifications.
            <br></br>
            <br></br>
            <h4>2.10. Head-to-Head</h4>
            2.10.1 The participant that finally ends up in the higher position in the official ranking is evaluated as the winner. If a participant falls out of the competition early, the bets on this participant are
            lost and the opponent or another participant in the competition, game or event will be deemed as the winner.<br></br>
            <br></br>
            2.10.2 If one or both participants do not participate in the competition, game, event or tournament, all related bets are given the odds of 1.00 which will result to outcome of the player having the
            originally set stake returned.<br></br>
            <br></br>
            2.10.3 If both participants have the same placing in the official ranking, then the duel is settled as a tie. This also applies to a premature withdrawal of both participants. If draw isn’t offered and it
            cannot be resolved, all bets on these duels are given the odds of 1.00 which will result to outcome of the player having the original set stake returned.<br></br>
            <br></br>
            2.10.4 In tournaments with a knock-out system (tennis tournaments, etc.), the participant that goes onto the next stage of the competition (second round ahead of the first, quarterfinals ahead of final
            sixteen, etc.) or achieve a better ranking in the final standings (winner of final ahead of loser of final, etc.) is considered as the winner. If a qualified participant is not present at the next round, they
            will still be considered as having been reached.<br></br>
            <br></br>
            <h4>2.11. Player markets:</h4>
            2.11.1 If any player selected for any bet type does not participate in game, then all bets related to that player shall be voided.<br></br>
            <br></br>
            2.11.2 All Player Markets include overtime for bet resulting. Penalty shootouts or equivalent are not included.<br></br>
            <br></br>
            2.11.3 All bets created prior to the venue change shall be voided.<br></br>
            <br></br>
            2.11.4 If the match starts but is abandoned or suspended at any time before the match reaches a natural end and the match does not resume within 5 hours, then all bets on that player shall be voided.<br></br>
            <br></br>
            <h4>2.12. Liability</h4>
            2.12.1 Nubet accepts no liability for entry, transfer and/or evaluation errors.<br></br>
            <br></br>
            2.12.2 Nubet reserves the right to correct obvious errors in the input of betting odds and/or in the evaluation of betting results (e.g., writing error, wrong player pairing, the obvious transposition of
            odds, incorrect handicap requirements, obviously mistakenly setting of the number of goals for over/under bets, or similar). This rule can also be applied after the event as soon as error has been detected,
            leading to placed bets being declared void afterwards. This includes any clear deviations that could have been assumed to be erroneous compared to the average market price at the time.<br></br>
            <br></br>
            2.12.3 Nubet reserves the right to change the odds on the offer at any time as well as suspend or close betting on events before the scheduled start time. The Company has the right to decide whether the
            Company wants to offer betting products for a certain event at a certain time to its customers.<br></br>
            <br></br>
            2.12.4 There are Specific Rules for certain types of sport betting and betting markets available at their own section in these Betting Rules. The Specific Rules takes precedence over the General Rules so the
            player should be aware of these rules first and foremost before playing any of the games offered at Nubet Betting.<br></br>
            <br></br>
            <h4>2.13. Cash Out</h4>
            2.13.1 Cash Out option allows the player to redeem a bet at the value displayed in the Cash Out selection at a certain moment. It is up to the Player to decide whether they want to use the Cash Out option,
            and the Player may as well decide to wait for the bet to be settled within the normal term. The player cannot cancel use of a Cash Out option once the request has been confirmed. When a Cash Out request has
            been accepted it will terminate the original wager and the player will have the bet settled regardless of the result and with pay-out amount stated at the moment when using of the Cash Out option.<br></br>
            <br></br>
            2.13.2 Nubet reserves the right to stop offering or suspend Cash Out option to users on a market without prior notice. The Company has the right to decide whether the Company wants to offer Cash Out option
            for a certain event at a certain time to its customers.<br></br>
            <br></br>
            2.13.3 In case of reasonable suspicion of attempted fraud as defined in Nubet Terms and Condition e.g., a wager or Cash Out request has been made by individual or group of people acting together such as, but
            not limited to, relatives, organisations, or employees of bookmaker either to defraud or to obtain benefits that are specifically prohibited, or if the original stake or if the Cash Out request was made after
            the relevant event has finished Nubet may void the original wager which was Cashed Out, refuse to Cash Out a wager, or require repayment of any amounts paid by Nubet in respect of the original wager,
            including the Cash Out amount.<br></br>
            <br></br>
            2.13.4 In the event where the original bet would have been declared void Nubet reserves the right to declare the Cash Out to be invalid and Cash Out amount void like the original bet and confiscate any amount
            exceeding the original wager. The original wager will be deemed to have been settled and Nubet does not have any further obligation to you in respect of the original wager.<br></br>
            <br></br>
            2.13.5 If a Cash Out request has been accepted mistakenly or there has been an error indicating or paying the Cash Out Price leading to incorrect offer or payment or an Cash Out amount has been incorrectly
            credited to a player account in connection with a wager that has been Cashed Out, Nubet reserves the right to correct the account balance accordingly and confiscate any amount credited to the player due to an
            error of any kind. If, because of any such adjustment, the player account goes into negative balance, Nubet has the right to recover these funds from the customer, and the customer has the obligation to
            correct the funds he or she received in error to the Company.<br></br>
            <br></br>
            <h3 id="Betrules">3. BET RULES</h3>
            <h4>3.1.</h4>
            Regular time only: Unless specified otherwise the result after regular time will be considered as an outcome of the event. Regular time includes the original full time and additional time for injuries,
            substitutions etc usual and unusual events that are included in the normal time of the match or event. Extra time and sudden death etc. are not included in a regular time. Regular time is defined by the
            official rules published by the respective governing association. Nubet does not take any liability to inform customers if a match or an event has different duration from normal or different duration than
            most implemented full-time duration in similar events.<br></br>
            <br></br>
            <h4>3.2. Most common bet types</h4>
            3.2.1. The most common bet types listed below, also called markets or market types, cover several or most products related to sport events, matches, or events. More bet types or special rules to be considered
            and familiarized by the player and which apply to certain betting products can be found under section Specific Rules.<br></br>
            <br></br>
            3.2.2. Winner (1X2)<br></br>
            Which team will win the match including draw? In 1X2 bet following applies: 1 = home team wins, X = draw, 2 = away team wins.<br></br>
            <br></br>
            3.2.3. Winner (Moneyline)<br></br>
            Moneyline is a 2-way bet type with no possible draws and is equal to betting on which team or participant will win or lose regardless of the winning margin. In case of a draw after regular time, then extra
            time, sudden death, penalty shots are included in a Moneyline bet. In Moneyline bet following applies: 1 = home team wins, 2 = away team wins.<br></br>
            <br></br>
            3.2.4. 1X2 Rest of The Match<br></br>
            Which team will win the rest of the match? In 1X2 Rest of The Match following applies: 1 = home team wins, X = draw, 2 = away team wins. This is live betting market only. Only the goals that are scored after
            the submission of the bet count. Consequently, the match starts with 0:0 when the bet is submitted.<br></br>
            <br></br>
            3.2.5. Double Chance<br></br>
            In Double Chance following applies: 1X = home team wins or draw, 12 = home team or away team wins, X2 = away team or draw.<br></br>
            <br></br>
            3.2.6. Draw No Bet<br></br>
            In Draw No Bet following applies: 1 = home team wins, 2 = away team wins. With a draw the Draw No Bet the placed bet is void.<br></br>
            <br></br>
            3.2.7. Handicaps (Point Spread)<br></br>
            Special case: When an exact number is quoted as the line for a home/away market, if the result will be the quoted line, then bets will be made void.<br></br>
            <br></br>
            3.2.8. Handicap<br></br>A team obtains a lead of one or more goals. These are added to the regular result (match outcome + handicap). The result is evaluated with the inclusion of the Handicap stipulation.
            <br></br>
            <br></br>
            3.2.9. Asian Handicap<br></br>
            There are three types of Asian handicap:<br></br>
            <br></br>
            Whole handicap [0], [-1], [-2], ...<br></br>
            <br></br>
            <ListItem>
              The handicap consists of whole numbers and is added to the regular result (match outcome + handicap) the result is evaluated with the inclusion of the handicap stipulation. If there is a draw after the
              handicap is applied (match outcome + handicap) the amount will be refunded (no bet).{" "}
            </ListItem>
            <br></br>
            Half handicap [-0.5], [-1.5], [-2.5], ...<br></br>
            <br></br>
            <ListItem>
              The handicap consists of half numbers and is added to the regular result (match outcome + handicap) The result is evaluated with the inclusion of the handicap stipulation. A draw is excluded as a result of
              the addition of half figures. This means that in this variant, the bet is always either won or lost. The payment cannot be refunded with this type of bet if there is no betting outcome.{" "}
            </ListItem>
            <br></br>
            Split handicap [-0.25], [-0.75], [-1.25] , ...<br></br>
            <br></br>
            <ListItem>
              The handicap consists of divided half numbers and is added to the regular result (match outcome + handicap) the result is evaluated with the inclusion of the handicap stipulation. In this variant, the
              amount is split in equal parts between the half and full handicap.{" "}
            </ListItem>
            <br></br>
            Depending on the outcome of the match, the following options are possible:<br></br>
            <br></br>
            <ListItem>The bet is won or lost </ListItem>
            <ListItem>One half of the amount is refunded, and the other half loses. </ListItem>
            <ListItem>One half of the amount is refunded, and the other half wins. </ListItem>
            <br></br>
            All handicaps related to the 1st half are evaluated based on the half-time result. In live Asian handicap market, only the goals that are scored after the submission of the bet count. Consequently, the game
            starts with 0:0 when the bet is submitted.<br></br>
            <br></br>
            3.2.10 Totals (Over/Under)<br></br>
            Totals are bets on whether the total number of goals scored in a match will be over or under the specified number of goals.<br></br>
            <br></br>
            3.2.11 Asian Totals<br></br>
            Asian Totals are bets on whether the total number of goals scored in a match will be over or under the specified number of goals. The logic works in a similar way than in Asian Handicap. Examples of Asian
            Total listed below.<br></br>
            <br></br>
            <ListItem>
              Over/Under 2<br></br>
              Over: The bet wins if three or more goals are scored in the match. If exactly two goals are scored, the amount will be refunded. If no goals or one goal are scored, the bet is lost.<br></br>
              Under: The bet wins if either one or no goals are scored in the match. If exactly two goals are scored, the amount will be refunded. If three or more goals are scored, the bet is lost.
            </ListItem>
            <ListItem>
              Over/Under 2.25<br></br>
              Over: The bet wins if three or more goals are scored. If exactly two goals are scored, half the amount is refunded and the other half wins. With no goals or one goal, the bet is lost.<br></br>
              Under: The bet wins if either one or no goals are scored in the match. If exactly two goals are scored, half the amount is refunded, and the other half loses. The bet is lost if three or more goals are
              scored.
            </ListItem>
            <ListItem>
              Over/Under 2.5<br></br>
              Over: The bet wins if three or more goals are scored in the match. If two or less goals are scored, the bet is lost.<br></br>
              Under: The bet wins if two or less goals are scored in the game. With three or more goals, the bet is lost.
            </ListItem>
            <ListItem>
              Over/Under 2.75<br></br>
              Over: The bet wins if four or more goals are scored. If exactly three goals are scored, half the amount is refunded and the other half wins. If no, one or two goals are scored, the bet is lost.<br></br>
              Under: The bet wins if no, one or two goals are scored in the match. If exactly three goals are scored, half the amount is refunded and the other half loses. The bet is lost if three or more goals are
              scored.
            </ListItem>
            <ListItem>
              Over/Under 3<br></br>
              Over: The bet wins if four or more goals are scored in the match. If exactly three goals are scored, the amount will be refunded. If no, one or two goals are scored, the bet is lost.<br></br>
              Under: The bet wins if no, one or two goals are scored in the match. If exactly three goals are scored, the amount will be refunded. With four or more goals, the bet is lost.
            </ListItem>
            <br></br>
            All other Asian Totals with different lines (x, x.25, x.5, x.75, x+1) for in a similar way.<br></br>
            <br></br>
            3.2.12 Both Team to Score<br></br>
            In Both Team to Score there are two options as listed below.
            <ListItem>Yes: Both teams will score at least one goal.</ListItem>
            <ListItem>No: Both teams will not score at least one goal.</ListItem>
            <br></br>
            3.2.13 Odd/Even<br></br>
            Will the total number of goals/points in a match be an odd or even number. Zero is considered as an even number.<br></br>
            <br></br>
            3.2.14 Correct Score<br></br>
            Betting on what is the exact result of the match at the end of the regular time. Predict the correct result to win the bet and if the result of the match is not the chosen option the bet is lost.<br></br>
            <br></br>
            3.2.15 Half Time/Full Time<br></br>
            What is the match outcome of the 1st half and at the end of the regular time? The first value is for the half-time standing and the second is for the final standing. In Half Time/Full Time bet the following
            options are available as explained below:<br></br>
            <br></br>
            <ListItem>1/1 = home win / home win</ListItem>
            <ListItem>1/X = home win / draw</ListItem>
            <ListItem>1/2 = home win / away win</ListItem>
            <ListItem>X/1 = draw / home win</ListItem>
            <ListItem>X/X = draw / draw</ListItem>
            <ListItem>X/2 = draw / away win</ListItem>
            <ListItem>2/1 = away win / home win</ListItem>
            <ListItem>2/X = away win / draw</ListItem>
            <ListItem>2/2 = away win / away win</ListItem>
            <br></br>
            3.2.16 Interval Betting<br></br>
            What is the 1st Half, 2nd Half result or the result of equivalent interval of a fixture (periods, quarters, innings…).<br></br>
            <br></br>
            3.2.17 First Team to Score<br></br>
            In First Team to Score bet the following options are available as explained below:<br></br>
            <br></br>
            <ListItem>1 = home team will score the first goal</ListItem>
            <ListItem>X = no goal (0:0)</ListItem>
            <ListItem>2 = away team will score the first goal</ListItem>
            <br></br>
            3.2.18 Head-to-Head (H2H)<br></br>
            Head-to-Head is a match between two participants where the customer will bet which one of these will get the best final position or final result in an event. If one or both do not start the event, or if none
            of them gets a result, the stake will be refunded. If both start the event, but only one of them gets a result, the bet will stand. To start an event means that a participant starts his first jump, throw,
            etc.<br></br>
            <br></br>
            3.2.19 Most Scoring Half / Period / Quarter<br></br>
            Which half / quarter / period will have the most goals / points.<br></br>
            <br></br>
            3.2.20 Team Totals<br></br>
            This is a bet on the number of goals scored by the home team over or under the specified number of goals.<br></br>
            <br></br>
            3.2.21 Player Specials<br></br>
            All players listed in the specific market must participate (get actual official playing time) for bets to stand.<br></br>
            <br></br>
            3.2.22 Time Of the First Goal<br></br>
            When the first goal of the match will be scored. For clarification purposes, the 1st minute of the match is 0:00 - 0:59, 2nd minute is 1:00 - 1:59, 3rd minute is 2:00 - 2:59, and so on.<br></br>
            <br></br>
            3.2.23 Winner<br></br>
            Which team/participant will win the event.<br></br>
            <br></br>
            3.2.24 Handicap Table<br></br>
            Teams are given a point advantage. What are the standings after this point advantage has been included. Except for the point advantage, the table is calculated according to the organiser’s rules.<br></br>
            <br></br>
            3.2.25 Top X<br></br>
            Which team / participant will end up in any of the positions (e.g. 1-3) in a certain competition.<br></br>
            <br></br>
            3.2.26 Triplehead (H3H)<br></br>
            Three objects are set up against each other in a triplehead. Predict which of the three will achieve the best final position. All participants must start the event and at least one must achieve a final
            result/position for the bet to stand.<br></br>
            <br></br>
            3.2.27 Live betting<br></br>A live bet that has been placed can no longer be cancelled. In the event of a delayed data transfer and/or time-shifted TV coverage with live betting and a resultant serious change
            in the progress of the game, which has not been considered in calculating the odds, Nubet reserves the right to declare the bets void. If an incorrect half-time or intermediate situation is shown on the
            betting slip with live betting, the bet is void, or in the case of combination bets the selection is given the odds 1.00. This also applies to obviously swapped or incorrect odds.<br></br>
            <br></br>
            If, after the match has started and the bet has been placed, the betting market is no longer available (e.g. transmission problems, etc.), then the betting slip is only evaluated after the end of the match on
            the basis of the official results.<br></br>
            <br></br>
            <h3 id="Specificrules">4. SPECIFIC RULES</h3>
            <h4>4.1. American football</h4>
            4.1.1. Overtime is not included in the result, unless stated otherwise in market description. If a match ends in a draw after extra time, bets are considered void, and the odds are set to 1.00.<br></br>
            <br></br>
            4.1.2. In case of abandoned or postponed matches all undecided markets are considered void unless the match continues in the same weekly schedule (Thursday – Wednesday local stadium time).<br></br>
            <br></br>
            4.1.3. In Betbuilder bet, all second half and fourth quarter market selections do include overtime if it occurs. For example, a total second half points selection would include the points scored in both the
            second half and overtime.<br></br>
            <br></br>
            <h4>4.2. Athletics</h4>
            4.2.1. If a specific event is postponed or abandoned, then bets remain valid provided that the event is started within 72 hours of the originally announced starting time.<br></br>
            <br></br>
            <h4>4.3. Baseball</h4>
            4.3.1. Extra innings are not included in the result unless otherwise stated.<br></br>
            <br></br>
            4.3.2. All bets placed on double headers will be resulted as normal.<br></br>
            <br></br>
            4.3.3. In the case of a postponed match all markets are considered void unless the match continues to an official result on the scheduled day.<br></br>
            <br></br>
            4.3.4. In the case of an abandoned match that is not considered official all undecided markets are considered void unless the match continues to an official result on the scheduled day. All fully decided
            markets will be settled.<br></br>
            <br></br>
            4.3.5. All bets stand irrespective of starting pitchers or starting pitcher changes.<br></br>
            <br></br>
            <h4>4.4. Basketball</h4>
            4.4.1. Overtime is not included in the result, unless stated otherwise in market description.<br></br>
            <br></br>
            4.4.2. If a match does not finish in a tie, but overtime is played for qualification purposes, the markets will be settled according to the result at the end of regular time.<br></br>
            <br></br>
            4.4.3. In Betbuilder bet, all second half and fourth quarter market selections do include overtime periods if they occur. For example, a total second half points selection would include the points scored in
            both the second half and any overtime periods played.<br></br>
            <br></br>
            <h4>4.5. Boxing</h4>
            4.5.1. All markets are settled according to the result available immediately after the end of the fight. Any subsequent appeals or amendments to the result are not taken into consideration for settlement
            purposes.<br></br>
            <br></br>
            4.5.2. If either fighter fails to answer the bell for the next round, then his opponent will be deemed to have won in the previous round.<br></br>
            <br></br>
            4.5.3. Should there be a withdrawal or a substitution of one of the fighters concerned, bets will be void.<br></br>
            <br></br>
            4.5.4. In the event of a fight being declared a No Contest all bets will be void.<br></br>
            <br></br>
            4.5.5. Should the scheduled number of rounds be changed before the fight then all “Total Rounds”, “Winner and exact rounds” and “Winning Method” bets will be made void.<br></br>
            <br></br>
            4.5.6. Half of round must pass to be counted for settlements<br></br>
            <br></br>
            4.5.7. For settlement purposes where a half round is stated then 1 minute 30 seconds of the respective round will define the half to determine under or over. Thus, 9.5 rounds would be one minute and thirty
            seconds of the 10th round. If the fight ends at exactly 1 minute 30 seconds of the 10th round, then the result would be over 9.5 rounds.<br></br>
            <br></br>
            4.5.8. Where a boxer fails to answer the bell for the next round, his opponent shall be deemed to have won the contest in the previous round. In the event of a Technical Decision before the end of the fight
            all bets will be settled as a win by Decision and round bets will be deemed losers.<br></br>
            <br></br>
            <h4>4.6. Cricket</h4>
            4.6.1. All markets do not consider super overs unless otherwise mentioned.<br></br>
            <br></br>
            <h4>4.7. Football</h4>
            4.7.1. Injury time/added time is considered in time frame and interval betting.<br></br>
            <br></br>
            4.7.2. Goal scorer markets:<br></br>
            <br></br>
            4.7.2.1 Own goals do not count in the settlement of bets (except when the no goal, other and own goal selections are available in the offer).<br></br>
            <br></br>
            4.7.2.2 If for any reason an unlisted player scores a goal all bets on listed players stand.<br></br>
            <br></br>
            4.7.2.3 All players who took part in the match since kick off or previous goal are considered as runners.<br></br>
            <br></br>
            4.7.2.4 All players who are currently taking part are listed.<br></br>
            <br></br>
            4.7.2.5 If for any reason an unlisted player scores a goal all bets on listed players stand.<br></br>
            <br></br>
            4.7.2.6 Market will be settled based on TV insert and statistics provided by Press Association unless there is clear evidence that these statistics are not correct. For Betbuilder Statsbomb is used as the
            official source for settling Football Bet Builder bets and can at times differ from regular sportsbook market settlement.<br></br>
            <br></br>
            4.7.3. Booking and booking points markets:<br></br>
            <br></br>
            4.7.3.1. In booking markets yellow card counts as 1 card and red or yellow-red card as 2. The 2nd yellow for one player which leads to a yellow-red card is not considered. Consequently, one player cannot
            cause more than 3 cards.<br></br>
            <br></br>
            4.7.3.2. Yellow card counts as 10 points and red or yellow-red cards as 25. The 2nd yellow for one player which leads to a yellow-red card is not considered. Consequently, one player cannot cause more than 35
            booking points.<br></br>
            <br></br>
            4.7.3.3. Settlement will be made according to all available evidence of cards shown during the regular 90 minutes play.<br></br>
            <br></br>
            4.7.3.4. Cards shown after the match are not considered.<br></br>
            <br></br>
            4.7.3.5. Cards for non-players (already substituted players, managers, players on bench) are not considered.<br></br>
            <br></br>
            4.7.4. Corners awarded but not taken corner kicks are not considered.<br></br>
            <br></br>
            4.7.5. Instances where the ball hits the woodwork which results directly into a goal, do not count as woodwork being hit.<br></br>
            <br></br>
            <h4>4.8. Golf</h4>
            4.8.1. 2-Balls: Which player will shoot the lowest score in the listed round. If the round is not specified, it is the next round. If both players retire at the same hole, the market will be void. Market will
            be void if both competitors achieve the same score for the affected round. A player can be considered as runner if he tees off on his first scheduled hole.<br></br>
            <br></br>
            4.8.2. 3 Balls: Predict which player will shoot the lowest score in the listed round. If the round is not specified, it is the next round. Dead heat rules apply.<br></br>
            <br></br>
            4.8.3. Outright winner: All outright bets are settled on the player awarded the trophy. The result of playoffs is taken into account. Where a tournament is reduced from the scheduled number of holes for any
            reason (e.g., bad weather conditions) outright bets (except “First round leader” bets) placed prior to the final completed round will be settled on the player awarded the trophy.<br></br>
            <br></br>
            4.8.4. Group betting: The winner will be the player achieving the highest placing at the end of the tournament. Dead-heat rules apply except where the winner is determined by a playoff. If we offer a group
            betting and only one competitor starts bets will be void.<br></br>
            <br></br>
            4.8.5. Finishing Position of a Named Player: In the event of a tie for a finishing position the tied position will count. For example, a tie with 4 other players for 7th place will count as a finishing
            position of 7th.<br></br>
            <br></br>
            4.8.6. End of Round Leader (e.g., First round – Winner): Settlement is based on the tournament score at the end of the specified round. Dead-heat rules apply.<br></br>
            <br></br>
            4.8.7. Tournament Top 4/Top 5/top 6/Top 10/Top 20 Finish: Dead-heat rules apply.<br></br>
            <br></br>
            4.8.8. A player is deemed to have played in a tournament or a specific round once they have teed off. If a player withdraws, retires, or is disqualified after having teed off, bets will stand.<br></br>
            <br></br>
            4.8.9. In tournaments affected by bad weather or other similar reasons, bets will be resulted on the official result regardless of the number of rounds played.<br></br>
            <br></br>
            4.8.10. If the tournament is abandoned, any bets placed after the last completed round will be void.<br></br>
            <br></br>
            4.8.11. Official tour site results at the time of trophy presentation are used for settlement purposes (subsequent disqualification after this time does not count). 4.8.12. Groups not played as listed: In the
            event of any non-runners, 2 ball and 3 ball bets will be void.<br></br>
            <br></br>
            <h4>4.9. Ice hockey</h4>
            4.9.1. All markets (except period, overtime and penalty shootout markets) are considered for regular time only unless stated otherwise.<br></br>
            <br></br>
            4.9.2. In the event of a game being decided by a penalty shootout, then one goal will be added to the winning team’s score and the game total for settlement purposes. This applies to all markets including
            overtime and penalty shootout.<br></br>
            <br></br>
            4.9.3. If a goalie does not start the game, the bets concerning this goalie will be voided.<br></br>
            <br></br>
            4.9.4. In Betbuilder bet, all third period market selections do include overtime and the resultant goal from shootouts if they occur. For example, a total third period goals selection would include the goals
            scored in both the third period and the winning goal from either overtime or resulting from the shootout.<br></br>
            <br></br>
            <h4>4.10. Motor sports</h4>
            4.10.1. If a specific event is postponed or abandoned, then bets remain valid provided that the event is completed within 72 hours.<br></br>
            <br></br>
            <h4>4.11. Mixed Martial Arts</h4>
            4.11.1. All markets are settled according to the result available immediately after the end of the fight. Any subsequent appeals or amendments to the result are not taken into consideration for settlement
            purposes.<br></br>
            <br></br>
            4.11.2. If either fighter fails to answer the bell for the next round, then his opponent will be deemed to have won in the previous round.<br></br>
            <br></br>
            4.11.3. Should there be a withdrawal or a substitution of one of the fighters concerned, bets will be void.<br></br>
            <br></br>
            4.11.4. In the event of a fight being declared a No Contest all bets will be void.<br></br>
            <br></br>
            4.11.5. Should the scheduled number of rounds be changed before the fight then all “Total Rounds”, “Winner and exact rounds” and “Winning Method” bets will be made void.<br></br>
            <br></br>
            4.11.6. Half of round must pass to be counted for settlements<br></br>
            <br></br>
            4.11.7. All bets will be settled on the official result declared. A win by disqualification is counted as Knockout/Technical Knockout.<br></br>
            <br></br>
            4.11.8. For the purposes of the Winning Method market, a KO includes the following:<br></br>
            <br></br>
            <ListItem>Referee stoppage due to strikes while either fighter is, or both fighters are, standing</ListItem>
            <ListItem>Referee stoppage due to strikes while either fighter is, or both fighters are, on the canvas</ListItem>
            <ListItem>Stoppage by doctor</ListItem>
            <ListItem>Stoppage by a fighter's corner/team</ListItem>
            <ListItem>A fighter retires due to injury</ListItem>
            <ListItem>A win by disqualification</ListItem>
            <br></br>
            4.11.9. For the purposes of the Winning Method market, a submission includes the following:<br></br>
            <br></br>
            <ListItem>Referee stoppage due to tap-out</ListItem>
            <ListItem>Referee stoppage due to technical submission</ListItem>
            <ListItem>A fighter's verbal submission (including a verbal submission which is made due to strikes)</ListItem>
            <ListItem>Change of schedule and/or day of match.</ListItem>
            <br></br>
            <h4>4.12. Tennis</h4>
            4.12.1 For all bets referring to the number of games played, a tie-break is counted as one game.<br></br>
            <br></br>
            4.12.2 Market settlements for retirements:<br></br>
            <br></br>
            4.12.2.1. Winner: Player who will advance to the next round (if a point was played in the affected game).<br></br>
            <br></br>
            4.12.2.2. Xth set – winner: Player who will advance to the next round (if a point was played in the affected set).<br></br>
            <br></br>
            4.12.2.3. Xth set game x – winner: Player who will advance to the next round (if a point was played in the affected game).<br></br>
            <br></br>
            4.12.3. If the players/teams are displayed incorrectly, we reserve the right to void betting.<br></br>
            <br></br>
            4.12.4. In the event of any of the following circumstances all bets will stand:<br></br>
            <br></br>
            <ListItem>Change of schedule and/or day of match.</ListItem>
            <ListItem>Change of venue.</ListItem>
            <ListItem>Change from indoor court to outdoor court or vice versa.</ListItem>
            <ListItem>Change of surface (either before or during a match).</ListItem>
            <br></br>
            <h4>4.13 Trotting</h4>
            4.13.1 If a horse is withdrawn from the race, all bets placed for that horse will be refunded (providing the bet was not placed prior to the final field being officially declared on a Futures betting basis).
            Bets on all other runners will be subject to a recalculation. Recalculation is done by multiplying the corresponding bet selection odds by the margin corrected deduction factor (1 – non-runner probability) at
            the time the withdrawal information is received. If more horses are withdrawn from the race later, the same process is repeated.<br></br>
            <br></br>
            <h4>4.14 Winter sports</h4>
            4.14.1 If a specific event is postponed or abandoned, then bets remain valid provided that the event is completed within 72 hours.<br></br>
            <br></br>
          </div>
        </Typography>
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    host: state.settings.host,
  };
};

export default connect(mapStateToProps, {})(withStyles(styles)(BettingRules));
