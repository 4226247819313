/**
 * CookieLoader component
 * Load all settings stored in cookie and apply them in app
 */

import { log } from "../config/log";
import React from "react";
import { connect } from "react-redux";
import {
  saveMarketSetConfig,
  setOddsFormat,
  setTheme,
  setLayout,
  setMode,
  setMarketsLayout,
  setNavMode,
} from "../actions/index";
import marketSetConfig from "../config/marketSetConfig";
import { getLabelsMarkets } from "../actions";
import { setLocaleWithFallback } from "../actions/i18n";
import withRouter from "../helpers/withRouter";

function hasTouchSupport() {
  return "ontouchstart" in window || navigator.maxTouchPoints > 0;
}

class CookieLoader extends React.PureComponent {
  /**
   * Load all settings stored in cookie and set them in app
   */

  componentDidMount() {
    let oddsFormat = localStorage.getItem("oddsFormat")
      ? localStorage.getItem("oddsFormat")
      : "decimal";
    this.props.setOddsFormat(oddsFormat, this.constructor.name);
    let themeMode = localStorage.getItem("theme")
      ? localStorage.getItem("theme")
      : this.props.host.themes[0];

    this.props.setTheme(themeMode, this.constructor.name);
    document.getElementsByTagName("html")[0].className = "";
    document.getElementsByTagName("html")[0].classList.add(themeMode);

    let compactMode = localStorage.getItem("mode")
      ? localStorage.getItem("mode")
      : "standard";
    this.props.setMode(compactMode, this.constructor.name);

    let navViewMode = localStorage.getItem("navMode")
      ? localStorage.getItem("navMode")
      : "list";
    this.props.setNavMode(navViewMode, this.constructor.name);

    let defaultLayout = hasTouchSupport() ? "touch" : "mouse";
    let layoutMode = defaultLayout;
    this.props.setLayout(layoutMode, this.constructor.name);

    let favoriteMarkets = localStorage.getItem("favoriteMarkets")
      ? JSON.parse(localStorage.getItem("favoriteMarkets"))
      : null;

    let marketSetConfigWithFavoritesFromCookie = { ...marketSetConfig };
    for (var sportCode in favoriteMarkets) {
      marketSetConfigWithFavoritesFromCookie[sportCode].prematch.favorites = [
        ...favoriteMarkets[sportCode],
      ];
    }
    this.props.saveMarketSetConfig(
      marketSetConfigWithFavoritesFromCookie,
      this.constructor.name
    );

    const getLanguage = () => {
      const activeRoute = window.location.pathname;
      const routeLang = activeRoute
        .split("/")
        ?.filter((bl) => bl === "en" || bl === "fi" || bl === "et")[0];
      const browserLangAgent = navigator.language || navigator.userLanguage;
      const savedLang = localStorage.getItem("lang");
      const searchParams = new URL(window.location.href)?.search;
      const queryString = searchParams ? searchParams : "";

      let browserLang = undefined;
      if (browserLangAgent?.includes("fi")) {
        browserLang = "fi";
      }
      if (browserLangAgent?.includes("et")) {
        browserLang = "et";
      }
      if (browserLangAgent?.includes("en")) {
        browserLang = "en";
      }
      const list = ["en", "fi", "et"];
      if (list.includes(routeLang)) {
        localStorage.setItem("lang", routeLang);
        return routeLang;
      } else if (savedLang && list.includes(savedLang)) {
        localStorage.setItem("lang", savedLang);
        if (activeRoute?.includes("sitemap.xml")) return;
        this.props.navigate(`/${savedLang}${activeRoute}`);
        return savedLang;
      } else if (browserLang) {
        localStorage.setItem("lang", browserLang);
        this.props.navigate(`/${browserLang}${activeRoute}`);
        return browserLang;
      } else {
        localStorage.setItem("lang", list[1]);
        this.props.navigate(`/${list[1]}${activeRoute}`);
        return list[1];
      }
    };
    let lang = getLanguage();
    window.document.querySelector("html").lang = lang;

    if (
      Object.keys(this.props.labels)?.length === 0 &&
      this.props.labels.constructor === Object
    ) {
      this.props.getLabelsMarkets(lang, this.constructor.name);
      this.props.setLocaleWithFallback(lang, this.constructor.name);
    }
  }

  render() {
    log(this.constructor.name, "render");
    return <div></div>;
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    labels: state.labels,
    host: state.settings.host,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    saveMarketSetConfig,
    setOddsFormat,
    setTheme,
    setLayout,
    setMode,
    setMarketsLayout,
    setNavMode,
    getLabelsMarkets,
    setLocaleWithFallback,
  })(CookieLoader)
);
