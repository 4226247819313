/**
 * Show all tickets
 */
import "./TicketsList.css";
import { log } from "../../config/log";
import React from "react";
import { connect } from "react-redux";
import { Translate } from "react-redux-i18n";
import withStyles from "@mui/styles/withStyles";
import { getTickets, saveTicketFilter, getTicket } from "../../actions";
import { AppBar } from "@mui/material";
import SwipeableViews from "react-swipeable-views";
import Grid from "@mui/material/Grid";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TicketBox from "./TicketBox";
import Ticket from "./Ticket";
import CustomDatePicker from "../Inputs/DatePicker";

import { toggleMyTicketsPanel } from "../../actions";
import withRouter from "../../helpers/withRouter";
import TicketsPagination from "./TicketsPagination";

const styles = (theme) => ({
  root: {
    "& .MuiTab-wrapper": {
      display: "inline-flex !important",
    },
    "& .MuiSvgIcon-root": {
      "@media (hover: none)": {
        fontSize: "0.9em !important",
      },
    },
    "& .MuiTab-textColorPrimary": {
      color: "var(--grey-11) !important",
    },
    "& .MuiTab-textColorPrimary.Mui-selected": {
      color: "var(--color-contrast)",
    },
    "& .MuiTab-wrapper > *:first-child": {
      marginBottom: 0,
    },
    "& .MuiTabs-root": {
      minHeight: "3.2em !important",
    },
    "& .MuiTab-root": {
      minHeight: "2em",
      minWidth: 0,
      fontSize: "1.3em",
      fontSize: "1.2em",
      minHeight: "1em",
      color: "var(--grey)",
      "@media (hover:hover)": {
        fontSize: "1.2em",
        minHeight: "2.6em",
      },
    },
    "& .MuiTab-textColorInherit.Mui-selected": {
      fontWeight: "bold",
      color: "var(--color-contrast)",
    },
    "& .MuiChip-root": {
      fontSize: "1em",
      "@media (hover:hover)": {
        fontSize: "1em",
      },
    },
    "& .MuiTabScrollButton-root.Mui-disabled": {
      display: "none",
    },
    "& .MuiTab-labelIcon": {
      minHeight: 0,
    },
    flexGrow: 1,
    width: "100%",
    backgroundColor: "var(--bg-menu-top)",
  },
  topTabs: {
    "& .MuiTab-root": {
      textTransform: "uppercase !important",
    },
    "& .MuiTabs-indicator": {
      display: "none",
    },
    "& .MuiTabs-flexContainer": {
      justifyContent: "center",
    },
    "& .MuiTab-textColorInherit.Mui-selected": {
      color: "var(--color-slip-top)",
    },
    "@media (hover:hover)": {
      background: "var(--bg-slip-top)",
    },
  },
  dateSelection: {
    flexGrow: 1,
    width: "100%",
    margin: "1em 0",
    zIndex: 1,
    borderBottom: "0.1em solid var(--grey-4)",
    "& .MuiInputBase-input": {
      fontSize: "0.8em",
      color: "var(--grey)",
      "@media (hover:hover)": {
        fontSize: "0.8em",
      },
    },
    "& .MuiFormLabel-root": {
      color: "var(--grey)",
    },
    "& .MuiIconButton-root": {
      padding: "0",
      color: "var(--grey)",
    },
    "& .MuiInput-underline:before": {
      border: "none",
    },
    "& .MuiInputBase-root": {
      fontSize: "1.2em",
    },
  },
  datePickerIcon: {
    "& .MuiSvgIcon-root": {
      fontSize: "var(--normal)",
    },
  },
  scroll: {
    "@media (hover:hover)": {
      maxHeight: "90vH !important",
      overflowY: "auto !important",
      overflowX: "hidden !important",
      margin: 0,
      padding: 0,
      listStyle: "none !important",
      height: "100% !important",
    },
  },
});

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

class TicketsList extends React.Component {
  constructor(props) {
    super(props);

    const { ticketListStatus } = this.props;

    let dateFrom = new Date();
    dateFrom = dateFrom.setDate(dateFrom.getDate() - 365);

    this.state = {
      topMenuIndex: 0,
      ticketStatus: ticketListStatus ? ticketListStatus : "pending",
      dateFrom: dateFrom,
      dateTo: Date.now(),
    };

    this.openedTicket = [];
    this.ticketStatusMenuItems = [
      "pending",
      "all",
      "paid",
      // "cashedout",
      "lost",
      // "cancelled",
    ];
  }

  /**
   * Format the date to YYYY-MM-DD string
   */
  formatDateToApi(date) {
    const ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(date);
    const mo = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(date);
    const da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(date);

    // return (`${da}-${mo}-${ye}`);
    return `${ye}-${mo}-${da}`;
  }

  /**
   * Get tickets lists when user is signed in
   * Otherwise, redirect to log in page
   */
  componentDidMount() {
    if (this.props.auth.isSignedIn) {
      if (!this.props.tickets) this.props.getTickets(this.formatDateToApi(this.state.dateFrom), this.formatDateToApi(this.state.dateTo), "pending", this.constructor.name, 0, this.props.ticketsLimit);
    } else {
      this.props.navigate(`/${this.props.locale}/sign-in`);
    }
    var wrapper = document.getElementById("betv3_wrapper");
    if (wrapper && this.props.layout === "touch") {
      wrapper.style.paddingBottom = "115px";
    }
  }

  componentDidUpdate() {
    if (!this.props.auth.isSignedIn) this.props.navigate(`/${this.props.locale}/sign-in`);
    var wrapper = document.getElementById("betv3_wrapper");
    if (wrapper) {
      if (wrapper && this.props.layout === "touch") {
        wrapper.style.paddingBottom = "115px";
      }
    }
  }

  componentWillUnmount() {
    var wrapper = document.getElementById("betv3_wrapper");
    if (wrapper && this.props.layout === "touch") {
      wrapper.style.paddingBottom = "115px";
    }
  }

  /**
   * Open / select ticket
   */
  addOpenedTicket = (ticket) => {
    let item = {
      ticketId: ticket.ticketID,
      ticketCode: ticket.ticketCode,
    };
    this.openedTicket = [];
    this.openedTicket.push(item);
    this.setState({ topMenuIndex: 1 });
    this.props.getTicket("selected", ticket.ticketID);
  };

  topMenuChange = (event, newValue) => {
    this.setState({ topMenuIndex: newValue }, () => {});
  };

  handleBackToTickets = () => {
    this.setState({ topMenuIndex: 0 });
  };

  topMenuChangeIndex = (newValue) => {
    this.setState({ topMenuIndex: newValue }, () => {});
  };

  /**
   * Get Tickets list based on selected status
   * Available status are
   * Pending, All, Won, Cashout, lost, Voided
   */
  ticketStatusChange = (event, newValue) => {
    this.setState({ ticketStatus: newValue }, () => {
      this.props.saveTicketFilter(newValue);
      this.props.getTickets(this.formatDateToApi(this.state.dateFrom), this.formatDateToApi(this.state.dateTo), this.state.ticketStatus, this.constructor.name, 0, this.props.ticketsLimit);
    });
  };

  dateFromChange = (newValue) => {
    this.setState({ dateFrom: newValue }, () => {
      this.props.getTickets(this.formatDateToApi(newValue), this.formatDateToApi(this.state.dateTo), this.state.ticketStatus, this.constructor.name, 0, this.props.ticketsLimit);
    });
  };

  dateToChange = (newValue) => {
    this.setState({ dateTo: newValue }, () => {
      this.props.getTickets(this.formatDateToApi(this.state.dateFrom), this.formatDateToApi(newValue), this.state.ticketStatus, this.constructor.name, 0, this.props.ticketsLimit);
    });
  };

  renderDateSelection() {
    const { translate, classes } = this.props;

    if (this.state.ticketStatus === "pending") return null;

    return (
      <div className={classes.dateSelection}>
        <AppBar position="static" style={{ backgroundColor: "var(--bg-tabs-2)" }} elevation={0}>
          <Grid container justifyContent="space-around">
            <CustomDatePicker
              disableToolbar
              autoOk={true}
              format={translate.date.ticketDateFilter}
              margin="normal"
              input={{
                value: this.state.dateFrom,
                onChange: this.dateFromChange,
              }}
              className={classes.datePickerIcon}
            />
            <CustomDatePicker
              disableToolbar
              autoOk={true}
              format={translate.date.ticketDateFilter}
              margin="normal"
              input={{
                value: this.state.dateTo,
                onChange: this.dateToChange,
              }}
              className={classes.datePickerIcon}
            />
          </Grid>
        </AppBar>
      </div>
    );
  }

  renderTicketStatusMenu() {
    const { classes } = this.props;
    return (
      <div className={classes.subTabs}>
        <AppBar position="static" style={{ backgroundColor: "var(--bg-tabs-2)" }} elevation={0}>
          <Tabs value={this.state.ticketStatus} onChange={this.ticketStatusChange.bind(this)} variant="scrollable" indicatorColor="primary" scrollButtons="auto" aria-label="scrollable auto tabs example">
            {this.ticketStatusMenuItems.map((item, key) => (
              <Tab key={key} value={item} label={<Translate value={`tickets.${item}`} />} {...a11yProps(key)} />
            ))}
          </Tabs>
        </AppBar>
      </div>
    );
  }

  renderTicketsList() {
    if ((!this.props.tickets && !this.props.isTicketsLoading) || (!this.props.isTicketsLoading && !this.props.tickets?.length))
      return (
        <div key={"no_open_tickets"} className={"vertical-center font-sm content-center"} style={{ height: "10em" }}>
          <Translate value="labels.no_open_tickets" />
        </div>
      );

    return (
      <>
        {this.props.isTicketsLoading && (
          <div key={"no_open_tickets"} className={"vertical-center font-sm content-center"} style={{ height: "10em" }}>
            <span>Loading...</span>
          </div>
        )}
        {!this.props.isTicketsLoading &&
          this.props.tickets.map((ticket) => {
            return (
              <div className="bg-light" key={ticket.ticketID}>
                <TicketBox ticketId={ticket.ticketID} parentCallBack={this.addOpenedTicket} content="ticketList" />
              </div>
            );
          })}
        <TicketsPagination startDate={this.formatDateToApi(this.state.dateFrom)} endDate={this.formatDateToApi(this.state.dateTo)} />
      </>
    );
  }

  renderTabContent = () => {
    switch (this.state.topMenuIndex) {
      default:
      case 0:
        return (
          <div>
            {this.renderTicketStatusMenu()}
            {this.renderDateSelection()}
            {this.renderTicketsList()}
          </div>
        );
      case 1:
        return <Ticket teamsDisplayOff backToTickets={this.handleBackToTickets} />;
    }
  };

  render() {
    log(this.constructor.name, "render");
    const { classes, translate, layout } = this.props;

    let topMenuItems = [<Translate value={`menu.myBets`} />];
    for (var key in this.openedTicket) {
      topMenuItems.push("");
    }

    if (!this.props.tickets) return <div>Loading...</div>;

    return (
      <div className="tickets-list-sticky">
        <div className={classes.root}>
          {layout === "mouse" ? (
            <AppBar position="static" color="transparent" elevation={0}>
              <Tabs
                value={this.state.topMenuIndex}
                onChange={this.topMenuChange.bind(this)}
                variant="scrollable"
                // textColor="primary"
                indicatorColor="primary"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
                className={classes.topTabs}
              >
                <Tab
                  key=""
                  value=""
                  className={classes.tabSelection}
                  label={translate.labels.hide_tickets}
                  onClick={() => {
                    this.props.toggleMyTicketsPanel(this.constructor.name);
                  }}
                />
                {topMenuItems.map((item, key) => (
                  <Tab key={key} value={key} label={item} {...a11yProps(key)} />
                ))}
              </Tabs>
            </AppBar>
          ) : null}
          <SwipeableViews axis={"x"} index={this.state.topMenuIndex} onChangeIndex={this.topMenuChangeIndex.bind(this)}>
            {topMenuItems.map((item, key) => (
              <div key={key} className={classes.scroll}>
                {this.renderTabContent()}
              </div>
            ))}
          </SwipeableViews>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.user.auth,
    layout: state.settings.layout,
    mode: state.settings.mode,
    tickets: state.tickets.list ? Object.values(state.tickets.list) : null,
    calcCashoutTickets: state.tickets.calcCashout ? Object.values(state.tickets.calcCashout) : null,
    cashoutAvailability: state.labels ? state.labels.cashoutAvailability : null,
    ticketListStatus: state.settings.ticketListStatus,
    translate: state.i18n.translations[state.i18n.locale],
    locale: state.i18n.locale,
    isTicketsLoading: state.tickets.isTicketsLoading,
    ticketsLimit: state.tickets.limit,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    getTickets,
    saveTicketFilter,
    getTicket,
    toggleMyTicketsPanel,
  })(withStyles(styles)(TicketsList))
);
