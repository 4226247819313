/**
 * League Filter tabs component
 * Live, All, lastMinute, today, tomorrow, result
 */

import { log } from "../../config/log";
import React from "react";
import { connect } from "react-redux";
import { setLeagues, setEventSearchResult, showResultsFirst, setLeagueGrouping, setOutrightLeagues } from "../../actions";
import { AppBar, Box, Grid, IconButton, InputAdornment } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import SearchIcon from "@mui/icons-material/Search";
import FeaturedGameAndOutrightCombi from "../dynamic-banners/featured-game-outright";
import withRouter from "../../helpers/withRouter";
import { Link } from "react-router-dom";

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const styles = (theme) => ({
  root: {
    "& .MuiTabs-root": {
      minHeight: "1em",
    },
    "& .MuiTab-root": {
      minHeight: "2em",
      fontSize: "1.2em",
      minWidth: "1em",
      lineHeight: "1em",
      textTransform: "uppercase !important",
      padding: "1em 0.1em",
      "@media (hover:hover)": {
        padding: "0 0 0 0.8em",
        margin: "0 0 0.5em 0",
      },
    },
    "& .MuiTab-textColorInherit.Mui-selected": {
      fontWeight: "bold",
      color: "var(--color-contrast)",
    },
    "& .MuiSvgIcon-fontSizeSmall": {
      fontSize: "1.3em",
      padding: "0.2em",
    },
    "& .MuiTab-fullWidth": {
      flexBasis: "unset",
    },
    "& .MuiInputBase-input": {
      textAlign: "center",
      padding: "0.3em 0",
      // color: 'var(--grey)',
      "@media (hover:none)": {
        textAlign: "left",
        padding: "1em 0",
      },
    },
    "& .MuiInputBase-input:focus": {
      textAlign: "left",
      padding: "0.3em 0",
      "@media (hover:none)": {
        textAlign: "left",
        padding: "1em 0",
      },
    },
    flexGrow: 1,
    width: "100%",
    "& .MuiAppBar-root": {
      "@media (hover:hover)": {
        width: "96.5%",
      },
    },
    // background: 'var(--bg-secondary)',
    background: "var(--bg-leagues-filter-tabs)",
  },
  featured_banner_close: {
    position: "absolute",
    top: 10,
    right: 10,
  },
  indicatorRemove: {
    display: "none",
  },
  slider: {
    "& .MuiSlider-markLabel": {
      fontSize: "0.65rem",
    },
  },
  search: {
    borderRadius: 0,
    marginLeft: 0,
    width: "100%",
  },
  iconSearch: {
    padding: 0,
    color: "var(--color-contrast)",
    fontSize: "0.8em",
  },
  buttonSearch: {
    padding: "0",
  },
  bannerMobile: {
    "@media (min-width: 450px)": {
      display: "none",
    },
  },
  input: {
    borderRadius: "0.2em",
    flex: 1,
    backgroundColor: "var(--bg-search-input)",
    padding: "0 0 0 1em",
    width: "100%",
    fontSize: "1em",
    "@media (hover:none)": {
      borderRadius: "0.25em",
      flex: 1,
      // backgroundColor: 'var(--bg-casino-search)',
      padding: "0 1em",
      width: "100%",
      fontSize: "1em",
    },
    "@media (hover:none) and (orientation:landscape)": {
      fontSize: "1.5em",
      height: "3em",
    },
  },
});

class LeaguesFilterTabs extends React.PureComponent {
  constructor(props) {
    super(props);

    const { leagueFilter } = this.props;
    this.searchRef = React.createRef();

    this.menuItems = [];
    this.state = {
      value: leagueFilter ? leagueFilter : "all",
      searchTerm: "",
      search: "",
      searchClicked: false,
      isBannerOpen: true,
    };
  }

  /**
   * Change league filter value and keep it
   * Show correct leagues based on league filter value
   */

  handleChange = (event, newValue) => {
    // const { leagues, sportCode, leagueGrouping } = this.props;
    const { leagues, sportCode, url, locale } = this.props;
    if (newValue !== "search") {
      this.setState({ search: "" });
    }
    if (url === `/${locale}/outrights`) {
      this.props.setOutrightLeagues(leagues, newValue, sportCode, locale, this.constructor.name);
    } else {
      this.props.setLeagues(leagues, newValue, sportCode, locale, this.constructor.name, true);
    }
    if (newValue !== "search") {
      this.props.navigate(`/${locale}/leagues/${newValue}/${sportCode}`);
    }
    // if (newValue !== 'live' && leagueGrouping) this.props.setLeagueGrouping(false);
    this.props.setLeagueGrouping(newValue === "live" ? true : false);
    this.props.showResultsFirst(newValue === "results" ? true : false);
  };

  handleCalendarChange = (event, newValue) => {
    // console.log('change' + newValue);
  };

  calcCalendarLabel(value) {
    //  return `${value}°C`;
    switch (value) {
      case 0:
        return "Bewerbe in der nächsten Stunde";
      case 25:
        return "Bewerbe in den nächsten 3 Stunden";
      case 50:
        return "Heutige Bewerbe";
      default:
        break;
    }
  }

  renderSearchButton() {
    const { classes, locale } = this.props;
    return (
      <Link to={`/${locale}/main/search?action=reset&cursor=ready`}>
        <IconButton component={"span"} className={classes.buttonSearch} type="submit" aria-label="search">
          <SearchIcon className={classes.iconSearch} />
        </IconButton>
      </Link>
    );
  }

  render() {
    log(this.constructor.name, "render");
    // const { leagues, translate, classes, layout } = this.props;
    const { leagues, translate, classes, layout, leagueFilter } = this.props;

    return (
      <div>
        <div key="app-bar" className={classes.root}>
          <AppBar position="static" color="transparent" elevation={0}>
            <Grid container>
              {layout !== "mouse" && this.state.isBannerOpen ? (
                <Grid item xs={12} className={classes.bannerMobile}>
                  {/* <PromoBannerSmallSports/> */}
                  <div style={{ position: "relative" }}>
                    <FeaturedGameAndOutrightCombi />
                  </div>
                </Grid>
              ) : null}
              <Grid item xs={12}>
                <Tabs
                  value={leagueFilter ? (leagueFilter === "favorites" ? "all" : leagueFilter) : "all"}
                  onChange={this.handleChange.bind(this)}
                  variant="fullWidth"
                  scrollButtons="auto"
                  classes={{
                    indicator: classes.indicatorRemove,
                  }}
                >
                  <Tab value="all" label={translate.menu.all} {...a11yProps(1)} key={1} className="filter_test_selector" />
                  <Tab value="lastMinute" label={translate.menu.threeHours} {...a11yProps(3)} key={3} className="filter_test_selector" />
                  <Tab value="today" label={translate.menu.today} {...a11yProps(4)} key={4} className="filter_test_selector" />
                  <Tab value="tomorrow" label={translate.menu.tomorrow} {...a11yProps(5)} key={5} className="filter_test_selector" />
                  <Tab value="search" icon={this.renderSearchButton()} {...a11yProps(9)} key={9} disableRipple />
                </Tabs>
              </Grid>
            </Grid>
          </AppBar>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const searchParam = new URLSearchParams(ownProps.location.search).get("searchQuery");
  return {
    leagues: state.leagues,
    leagueFilter: ownProps.leagueFilter,
    leagueGrouping: state.settings.leagueGrouping,
    sportCode: ownProps.sportCode,
    translate: state.i18n.translations[state.i18n.locale],
    locale: state.i18n.locale,
    layout: state.settings.layout,
    url: state.settings.url,
    searchParam: searchParam,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    setLeagues,
    setOutrightLeagues,
    setEventSearchResult,
    showResultsFirst,
    setLeagueGrouping,
  })(withStyles(styles)(LeaguesFilterTabs))
);
