// Casino search box component
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import { Grid, styled } from "@mui/material";
import InputBase from "@mui/material/InputBase";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Iconify from "../../iconify";

import SearchIcon from "@mui/icons-material/Search";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

import { searchCasinoGames } from "../../../actions";
import Image from "../../image";

const StyledInputBase = styled(InputBase)(({ layout }) => ({
  animationName: "mui-auto-fill-cancel",
  animationDuration: "10ms",
  ...(layout === "mouse" && {
    borderRadius: "0.5em",
    flex: 1,
    backgroundColor: "var(--bg-casino-search-2)",
    padding: "0.5em",
    width: "95%",
    fontSize: "1em",
    // "@media (hover:none)": {
    //   fontSize: "1.2em",
    //   borderRadius: "0.5em",
    //   backgroundColor: "var(--bg-search-input)",
    //   padding: "0 0 0 1em",
    // },
  }),
  ...(layout !== "mouse" && {
    borderRadius: "0.5em",
    flex: 1,
    backgroundColor: "var(--bg-casino-search-2)",
    padding: "0 1em",
    width: "100%",
    fontSize: "0.8em",
    padding: "0 0.5em",
  }),
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  position: "absolute",
  top: 50,
  zIndex: 10,
  left: 0,
  right: 0,
  height: "auto",
  boxShadow: "0px 1px 8px 6px rgba(34, 60, 80, 0.39)",
  padding: theme.spacing(0.5),
}));

const StyledResultsWrapper = styled("div")(({ theme }) => ({
  backgroundColor: "var(--bg-app)",
  padding: "0.5em 0",
  overflowY: "auto",
  height: "50vh",
  position: "relative",
  zIndex: 11111,
}));

const StyledSearchItemContainer = styled("div")(({}) => ({
  padding: "0.4em 0.8em",
  cursor: "pointer",
  color: "var(--grey-3)",
  "&:hover": {
    background: "var(--grey-5)",
    color: "var(--white)",
    transition: "background-color .3s ease,color .3s ease",
  },
  "&:last-child": {
    marginBottom: "10.5em",
  },
}));

const StyledGameImage = styled("img")(() => ({
  width: "15em",
  height: "auto",
  marginRight: "0.5em",
  borderRadius: "0.5em",
  "@media (hover:none)": {
    width: "3em",
  },
}));

const CasinoSearchBox = () => {
  // router hooks
  const [searchParams] = useSearchParams();
  const location = useLocation();
  // Selectors
  const auth = useSelector((state) => state.user.auth);
  const lang = useSelector((state) => state.i18n.locale);
  const layout = useSelector((state) => state.settings.layout);
  const translate = useSelector((state) => state.i18n.translations[lang]);
  const searchedGames = useSelector((state) => state.casino.searchedGames);
  //action dispatcher
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [collapseChecked, setCollapse] = useState();
  const [searchValue, setSearchValue] = useState();

  const handleOpenGameFromSearch = (game) => {
    if (auth.isSignedIn) {
      searchParams.set("dialog", "play-game");
      searchParams.set("game-id", game.gameId);
      navigate(`${location.pathname}?${searchParams.toString()}`);
    } else {
      navigate(`/${lang}/sign-in?redirect_to=casino/dashboard?dialog=play-game&game-id=${game.gameId}`);
    }
  };

  const getImgURL = (game) => {
    let casinoGameImgLink = `https://storage.googleapis.com/nubet-casino-gfx/${game.name}.jpg`;
    return casinoGameImgLink;
  };

  const handleImage404Error = (e) => {
    e.target.src = `https://storage.googleapis.com/nubet-casino-gfx/coming_soon.jpg`;
  };

  const searchGames = (event) => {
    const keyword = event.target.value;
    if (keyword && keyword?.length > 2) {
      setCollapse(true);
    } else if (!keyword || (keyword && keyword?.length < 2)) {
      setCollapse(false);
    }
    setSearchValue(keyword);
    dispatch(searchCasinoGames("CasinoSearch", keyword));
  };

  useEffect(() => {
    window.addEventListener("click", () => {
      if (collapseChecked) {
        setCollapse(false);
        setSearchValue("");
      }
    });
  }, [collapseChecked]);

  return (
    <Stack position="relative">
      <StyledInputBase
        value={searchValue}
        layout={layout}
        onChange={searchGames}
        placeholder={translate.slots.search}
        inputProps={{ style: { textAlign: "center", fontSize: "1.6em", margin: "0 0 0 2.5em" } }}
        endAdornment={
          <InputAdornment position="end">
            <IconButton type="submit" aria-label="search">
              <SearchIcon color="primary" />
            </IconButton>
          </InputAdornment>
        }
      />
      <Fade in={collapseChecked}>
        <StyledPaper elevation={1}>
          <StyledResultsWrapper hasResults={searchedGames?.length}>
            <Stack px={1} direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="h2">{translate.labels.games}</Typography>
              <IconButton aria-label="close-search-button">
                <Iconify icon={"ic:round-close"} />
              </IconButton>
            </Stack>
            {searchedGames?.map((game, key) => (
              <StyledSearchItemContainer key={key} onClick={(e) => handleOpenGameFromSearch(game)}>
                <Grid container>
                  <Grid item xs={4}>
                    <Image ratio="6/4" sx={{ borderRadius: "4px" }} alt={game.translation} src={getImgURL(game)} onError={handleImage404Error} />
                  </Grid>
                  <Grid item xs={8}>
                    <Typography color="primary" align="left" variant="h4" ml={2}>
                      {game.translation}
                    </Typography>
                  </Grid>
                </Grid>
              </StyledSearchItemContainer>
            ))}
          </StyledResultsWrapper>
        </StyledPaper>
      </Fade>
    </Stack>
  );
};

export default CasinoSearchBox;
