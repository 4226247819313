import { alpha, Box, Stack, styled, Typography } from "@mui/material";
import Iconify from "../../iconify";
import PropTypes from "prop-types";
import {  useLocation, useNavigate, useSearchParams } from "react-router-dom";

const StyledContainer = styled(Box)(({ theme }) => ({
  padding: "10px",
  background: "var(--bg-odds)",
  borderRadius: "6px",
  cursor: "pointer",
  // "&:hover": {
  //   background: alpha(theme.palette.background.default, 0.6),
  // },
}));

const ExampleSearchButton = ({ league }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const handleExampleSearch = () => {
    searchParams.set("searchQuery", league.label);
    navigate(`${location.pathname}?${searchParams.toString()}`);
  }
  
  return (
    <StyledContainer onClick={handleExampleSearch}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack direction="row" alignItems="center">
          <Typography>{league.label}</Typography>
        </Stack>
        <Iconify icon="line-md:chevron-right" />
      </Stack>
    </StyledContainer>
  );
};

ExampleSearchButton.propTypes = {
  league: PropTypes.object.isRequired,
};

export default ExampleSearchButton;
