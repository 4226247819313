import React, { useEffect } from "react";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";
import { useSelector, useDispatch } from "react-redux";
import { icons } from "../SportsTabs";
import SearchResultLeague from "./SearchResultLeague";
import SearchBox from "./search-box";
import { makeStyles } from "@mui/styles";
import { Container, Divider, Grid, Stack, Typography } from "@mui/material";
import ExampleSearchButton from "./example-search-btn";
import { getExampleSearchItems } from "../../../actions";
import { Translate } from "react-redux-i18n";

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiTab-wrapper": {
      display: "inline-flex !important",
      // color: 'var(--color-contrast)',
    },
    "& .MuiSvgIcon-root": {
      color: "var(--color-sportstabs)",
      fontSize: "1.3em !important",
      "@media (hover: hover)": {
        fontSize: "2.7em !important",
      },
    },
    "& .MuiTab-wrapper > *:first-child": {
      marginBottom: 0,
    },
    "& .MuiBadge-anchorOriginTopRightRectangle": {
      top: "0.6em",
      left: "0.2em",
      "@media (hover: none) and (min-width: 768px)": {
        top: "1.05em",
        left: "0.55em",
      },
    },
    "& .MuiBadge-badge": {
      height: "1.2em",
      minWidth: "1.2em",
      "@media (hover: none) and (min-width: 768px)": {
        height: "1.6em",
        minWidth: "1.6em",
      },
    },
    "& .MuiTabs-root": {
      minHeight: "2.6em",
    },
    "& .MuiTab-root": {
      // minHeight: "1em",
      minHeight: "3em",
      padding: "0.3em 0.4em",
      minWidth: 0,
      fontSize: "1.3em",
      "@media (hover:hover)": {
        fontSize: "1.2em",
        padding: "0.5em 1.2em 0.5em 1em",
      },
    },
    "& .MuiTab-labelIcon": {
      minHeight: 0,
    },
    "& .MuiTabScrollButton-root": {
      color: "var(--grey-11)",
    },
    "& .MuiTabScrollButton-root.Mui-disabled": {
      display: "none",
    },
    "& .Mui-selected": {
      color: "var(--color-sports-tabs-selected)",
      background: "var(--bg-sports-tabs-selected)",
    },
    flexGrow: 1,
    background: "var(--bg-tabs-2)",
  },
}));

const SearchEvents = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  // data selectors
  const results = useSelector((state) => state.search.result);
  const lang = useSelector((state) => state.i18n.locale);
  const sportsCodes = useSelector((state) => state.search.sports);
  const sportLabels = useSelector((state) => state.labels.sports);
  const isSearchLoading = useSelector((state) => state.search.isLoading);
  const layout = useSelector((state) => state.settings.layout);
  const exampleSearches = useSelector((state) => state.search.exampleSearches);
  // Sports tab value
  const [value, setValue] = React.useState(sportsCodes[0]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    dispatch(getExampleSearchItems(lang, "events-search/index.js"));
    if (!sportsCodes.includes(value)) {
      setValue(sportsCodes[0]);
    }
  }, [sportsCodes.length]);

  return (
    <div>
      <Box mb={2}>
        <SearchBox />
      </Box>
      {isSearchLoading && <div>Loading...</div>}
      {!isSearchLoading && !sportsCodes.length && <Container maxWidth="xl">
        <Stack mb={1}>
          <Typography variant="body1" color="primary">
            <Translate value="labels.example_searches"/>
          </Typography>
        </Stack>
        <Divider />
        <Stack pb={3}>
          <Grid container spacing={2}>
            {exampleSearches.map((exs) => (
              <Grid key={exs.leagueId} item xs={12} sm={6} md={4}>
                <ExampleSearchButton league={exs} />
              </Grid>
            ))}
          </Grid>
        </Stack>
      </Container>}
      {!isSearchLoading &&
      sportsCodes?.length &&
      sportsCodes?.includes(value) ? (
        <TabContext value={value}>
          <div className={classes.root}>
            <TabList
              onChange={handleChange}
              variant="scrollable"
              scrollButtons={layout === "mouse" ? "auto" : false}
              TabIndicatorProps={{
                style: {
                  display: "none",
                },
              }}
              aria-label="search-results-tabs"
            >
              {sportsCodes.map((sportCode) => {
                return (
                  <Tab
                    label={sportLabels?.default[sportCode]}
                    value={sportCode}
                    icon={icons[sportCode]}
                    iconPosition="top"
                    key={sportCode}
                  />
                );
              })}
            </TabList>
          </div>
          {sportsCodes.map((sportCode) => {
            let selectedLeagues = results[sportCode];
            let onlyOutrightLeagues = selectedLeagues.filter(
              (slg) => Object.values(slg.games).length === 0 || !slg?.games
            );
            let restLeagues = selectedLeagues.filter(
              (slg) => slg?.games && Object.values(slg.games).length > 0
            );
            return (
              <TabPanel sx={{ p: 0 }} value={sportCode} key={sportCode}>
                {restLeagues.map((league) => (
                  <SearchResultLeague key={league.leagueId} league={league} />
                ))}
                {onlyOutrightLeagues.map((league) => (
                  <SearchResultLeague key={league.leagueId} league={league} />
                ))}
              </TabPanel>
            );
          })}
        </TabContext>
      ) : null}
    </div>
  );
};

export default SearchEvents;
