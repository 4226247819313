import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import Slider from "react-slick";
import PrecannedCard from "../components/precanned-card";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { IconButton } from "@mui/material";

const NextButton = ({ onClick }) => {
  return (
    <div className="slick-custom-next">
      <IconButton onClick={onClick}>
        <KeyboardArrowRightIcon />
      </IconButton>
    </div>
  );
};

const PrevButton = ({ onClick }) => {
  return (
    <div className="slick-custom-prev">
      <IconButton onClick={onClick}>
        <KeyboardArrowLeftIcon />
      </IconButton>
    </div>
  );
};

const shuffleBets = (precannedBetsList) => {
  for (let i = precannedBetsList.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [precannedBetsList[i], precannedBetsList[j]] = [precannedBetsList[j], precannedBetsList[i]];
  }

  return precannedBetsList;
}

const settings = {
  dots: false,
  infinite: true,
  speed: 2000,
  slidesToShow: 3,
  slidesToScroll: 3,
  arrows: true,
  autoplay: true,
  pauseOnHover: true,
  autoplaySpeed: 7000,
  swipeToSlide: true,
  rtl: false,
  nextArrow: <NextButton />,
  prevArrow: <PrevButton />,
  lazyLoad: true,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        autoplaySpeed: 5000,
        dots: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        swipeToSlide: false,
      },
    },
  ],
};

const styles = (theme) => ({
  root: {
    "& .slick-custom-next": {
      position: "absolute",
      top: "40%",
      right: -45,
    },
    "& .slick-custom-prev": {
      position: "absolute",
      top: "40%",
      left: -45,
      zIndex: 1,
    },
    "@media only screen and (max-width: 603px)": {
      width: "100%",
      height: "100dvh",
      "& .slick-custom-next": {
        right: -30,
      },
      "& .slick-custom-prev": {
        left: -30,
      },
    },
    "@media (hover:hover)": {
      backgroundColor: "var(--color-main)",
    },
    "& .MuiTypography-root": {
      fontSize: "1.1em !important",
    },
    "& p": {
      margin: "1em 0 1em",
    },
    "& li": {
      margin: "1em 0",
    },
    "& .MuiDivider-root": {
      backgroundColor: "var(--color-contrast)",
    },
    padding: "10px 0",
    flexGrow: 1,
    height: "max-content !important",
    backgroundColor: theme.palette.background.paper,
  },
});

class PrecannedBetsCarousel extends React.Component {
  constructor(props) {
    super(props);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.precannedBetsList.length<nextProps.precannedBetsList.length) {
      return true;
    }
    return false;
  }

  render() {
    const { classes, precannedBetsList } = this.props;
    return (
      <div className={classes.root}>
        <div className="featured-matches-container">
          <Slider {...settings}>
            {shuffleBets(precannedBetsList)?.map((bet, indx) => (
              <PrecannedCard key={indx} game={bet.game} bet={bet} />
            ))}
          </Slider>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const precannedList =
    state.banner?.precanned && typeof state.banner.precanned === "object"
      ? state.banner.precanned
      : null;
  let precannedBetsList = [];
  if (ownProps?.isInBBModal && precannedList && ownProps?.topLeagueIds) {
    Object.keys(precannedList).forEach((lgkey) => {
      const league = precannedList[lgkey];
      Object.values(league).forEach((lgGame) => {
        lgGame.bets?.forEach((bet) => {
          if (bet?.sideBet === "1") {
            precannedBetsList.push({ ...bet, game: { ...lgGame, bets: [] } });
          }
        });
      });
    });
    let filteredBets = precannedBetsList.filter((bet) => ownProps.topLeagueIds?.includes(bet?.game?.leagueID));
    if (filteredBets.length >= 3) {
      precannedBetsList = filteredBets;
    }
  } else {
    const selectedGame =
      state.banner?.precanned &&
      typeof state.banner.precanned === "object" &&
      state.banner.precanned[ownProps.leagueId]
        ? state.banner.precanned[ownProps.leagueId][ownProps.gameId]
        : null;
    selectedGame?.bets?.forEach((bet) => {
      if (bet?.sideBet === "1") {
        precannedBetsList.push({ ...bet, game: { ...selectedGame, bets: [] } });
      }
    });
  }


  return {
    slip: state.banner.slip,
    lang: state.i18n.locale,
    precannedBetsList: precannedBetsList,
  };
};

export default connect(
  mapStateToProps,
  {}
)(withStyles(styles)(PrecannedBetsCarousel));
