/**
 * Game Score and states information component
 * Show game score, game state and additional information
 * For Odds view mode ende selectedLeagueResults this.state.gameId
 */

import { log } from "../../config/log";
import React from "react";
import { connect } from "react-redux";
import { Localize, Translate } from "react-redux-i18n";
import GameBoxCompactAvatar from "./GameBoxCompactAvatar";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import { addStateItem } from "../../helpers/gameFunctions";
import Game from "./Game";
import Odds from "./Odds";
import RedCardIcon from "../../media/img/card_red.svg";
import YellowCardIcon from "../../media/img/card_yellow.svg";
import CornerIcon from "../../media/img/corner.svg";
import { getGameStatus } from "../../helpers/gameFunctions";
import "./GameBox.css";
import SideBetStaticLoader from "./SideBetStaticLoader";
import TeamJersey from "./TeamJersey";
import { Stack } from "@mui/material";

class GameBox extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      gameId: "",
      sportCode: "",
      openDetail: false,

      currentTime: "",
      increasingTime: "",
    };

    this.stateList = [];
    this.liveTimer = null;
  }

  UNSAFE_componentWillMount() {
    this.runLiveGameTimer(this.props.game);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.runLiveGameTimer(nextProps.game);
  }

  shouldComponentUpdate(nextProps, nextState) {
    var flag = false;
    if (nextProps.isInViewport === true) {
      flag = true;
    }

    if (this.state.openDetail !== nextState.openDetail) {
      flag = true;
    }

    return flag;
  }

  /**
   * Open game details ( show game odds information )
   */

  openGameDetails(game) {
    this.setState(
      {
        gameId: game.gameid,
        sportCode: game.sport_code,
        openDetail: !this.state.openDetail,
      },
      () => {
        this.openDrawer();
      }
    );
  }

  // componentDidMount() {
  //   if (parseInt(this.props.gameStaticId) === this.props.game.gameid&&!this.state.openDetail) {
  //     this.openGameDetails(this.props.game);
  //   }
  // }
  /**
   * Show game state item ( home / away )
   */

  renderStateItem(stateInfoItem, key) {
    let className = "";

    switch (stateInfoItem.stateTeam) {
      default:
      case "home":
        className =
          "game-box-detailed-additional-state-info-item-left bg-light-gradient-detailed-left vertical-center delay fade-in content-left grey-permanent";
        break;
      case "away":
        className =
          "game-box-detailed-additional-state-info-item-right bg-light-gradient-detailed-right vertical-center delay fade-in content-right grey-permanent";
        break;
    }
    if (key === Object.keys(this.stateList)?.length) {
      className =
        "game-box-detailed-additional-state-info-item content-center vertical-center font-l bg-light-gradient-center delay fade-in";
    }
    return (
      <div className={className} key={key}>
        <div
          className={
            stateInfoItem.stateIcon.replace("home-", "").replace("away-", "") +
            "-white"
          }
          style={{ width: "1em", margin: "0.35em 0.4em 0.35em 0.2em" }}
        >
          &nbsp;
        </div>
        <div className="white-permanent spacer-right">
          <Translate value={`stateInfo.${stateInfoItem.stateText}`} />
        </div>
      </div>
    );
  }

  /**
   * Check if game stateTime is one week later or not
   */

  checkOneWeekLater(timeStamp) {
    let today = new Date();
    let time = new Date(timeStamp);
    let difference = Math.floor(
      (Date.UTC(time.getFullYear(), time.getMonth(), time.getDate()) -
        Date.UTC(today.getFullYear(), today.getMonth(), today.getDate())) /
        (1000 * 60 * 60 * 24)
    );

    if (difference > 7) return true;

    return false;
  }

  /**
   * Show game live time counter
   */

  runLiveGameTimer = (game) => {
    // let self = this;
    // let { time } = game;
    // if (time) {
    //   if (self.state.currentTime !== time) {
    //     if (time === 'HT' || time === 'End' || (time && time?.length !== 5)) {
    //       self.setState({ increasingTime: time }, () => {
    //         clearInterval(self.liveTimer);
    //         self.liveTimer = null;
    //         // localStorage.removeItem(`${game.gameid}_live_time`);
    //       });
    //     } else {
    //       self.setState({ currentTime: time, increasingTime: time }, () => {
    //         if (self.state.increasingTime === 'NaN:NaN' || !self.state.increasingTime) {
    //           self.setState({ increasingTime: time }, () => {
    //             if (!self.liveTimer && self.state.increasingTime) {
    //               self.liveTimer = setInterval(() => {
    //                 let minutes = parseInt(self.state.increasingTime.toString().split(":")[0], 10);
    //                 let seconds = parseInt(self.state.increasingTime.toString().split(":")[1], 10);
    //                 if (seconds >= 59) {
    //                   seconds = 0;
    //                   if (game.sport_code === "BA"||game.sport_code === "IH") {
    //                     minutes--;
    //                   } else {
    //                     minutes++;
    //                   }
    //                 } else {
    //                   if (game.sport_code === "BA"||game.sport_code === "IH") {
    //                     seconds--;
    //                   } else {
    //                     seconds++;
    //                   }
    //                 }
    //                 const formattedTime = `${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`;
    //                 // self.setState({ increasingTime: formattedTime }, () => {
    //                 //   localStorage.setItem(`${game.gameid}_live_time`, formattedTime);
    //                 // });
    //                 self.setState({ increasingTime: formattedTime });
    //               }, 1000);
    //             }
    //           });
    //         } else {
    //           if (!self.liveTimer && self.state.increasingTime) {
    //             self.setState({ increasingTime: time }, () => {
    //               self.liveTimer = setInterval(() => {
    //                 let minutes = parseInt(self.state.increasingTime.toString().split(":")[0], 10);
    //                 let seconds = parseInt(self.state.increasingTime.toString().split(":")[1], 10);
    //                 // if (game.sport_code === "BA" || game.sport_code === "IH") {
    //                 //   if (seconds === 0) {
    //                 //     minutes--;
    //                 //     seconds = 60;
    //                 //   } else {
    //                 //     seconds--;
    //                 //   }
    //                 // } else {
    //                   if (seconds >= 59) {
    //                       seconds = 0;
    //                       minutes++;
    //                   } else {
    //                       seconds++;
    //                   }
    //                 // }
    //                 const formattedTime = `${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`;
    //                 // self.setState({ increasingTime: formattedTime }, () => {
    //                 //   localStorage.setItem(`${game.gameid}_live_time`, formattedTime);
    //                 // });
    //                 self.setState({ increasingTime: formattedTime });
    //                 // console.log(this.state.increasingTime);
    //               }, 1000);
    //             });
    //           }
    //         }
    //       });
    //     }
    //   }
    // }
  };

  goNextGame = (game, e) => {
    e.stopPropagation();
    this.props.goNextGame(game);
  };

  // Create jerseys

  createJersey = (
    teamColor,
    teamHorStripes,
    teamNumber,
    teamSleeve,
    teamSplit,
    teamSquares,
    teamStripes,
    teamSleeveDetail,
    sportCode,
    isTeam1
  ) => {
    const { game } = this.props;
    const defaultColor = isTeam1 ? "bdbdbd" : "636363";
    const defaultNumberColor = isTeam1 ? "f5f5f5" : "030303";
    const teamSleeveDetailColor =
      teamSleeveDetail != "" ? teamSleeveDetail : teamSleeve;
    const teamSplitColor =
      teamSplit != "false" ? teamSleeveDetailColor : "ffffff00";
    const teamHorStripesColor =
      teamHorStripes != "false" ? teamSleeveDetailColor : "ffffff00";
    const teamStripesColor =
      teamStripes != "false" ? teamSleeveDetailColor : "ffffff00";
    const teamSquaresColor =
      teamSquares != "false" ? teamSleeveDetailColor : "ffffff00";
    const teamSleeveColor = teamSleeve != "" ? teamSleeve : "ffffff00";

    return (
      <div className="sidebets_jersey">
        {game?.sport_code === "FB" && (
          <GameBoxDetailedAvatarFB
            pattern="1"
            color1={`#${teamColor || defaultColor}`}
            color2={`#${teamNumber || defaultNumberColor}`}
            color3={`#${teamHorStripesColor}`}
            color4={`#${teamSleeveColor}`}
            color5={`#${teamSplitColor}`}
            color6={`#${teamSquaresColor}`}
            color7={`#${teamStripesColor}`}
          />
        )}
        {game?.sport_code === "BA" && (
          <GameBoxDetailedAvatarNo
            pattern="1"
            color1={`#${teamColor || defaultColor}`}
            color2={`#${teamNumber || defaultNumberColor}`}
          />
        )}
        {(game?.sport_code === "AF" ||
          game?.sport_code === "FL" ||
          game?.sport_code === "VB" ||
          game?.sport_code === "HB") && (
          <GameBoxDetailedAvatarShort
            pattern="1"
            color1={`#${teamColor || defaultColor}`}
            color2={`#${teamNumber || defaultNumberColor}`}
          />
        )}
        {(game?.sport_code === "IH" || game?.sport_code === "BD") && (
          <GameBoxDetailedAvatarLong
            pattern="1"
            color1={`#${teamColor || defaultColor}`}
            color2={`#${teamNumber || defaultNumberColor}`}
          />
        )}
        {(game?.sport_code === "TE" || game?.sport_code === "DA") && (
          <GameBoxDetailedAvatarTE
            pattern="1"
            color1={`#${teamColor || defaultColor}`}
            color2={`#${teamNumber || defaultNumberColor}`}
          />
        )}
        {(game?.sport_code === "BX" || game?.sport_code === "MM") && (
          <GameBoxDetailedAvatarBX
            pattern="1"
            color1={`#${teamColor || defaultColor}`}
            color2={`#${teamNumber || defaultNumberColor}`}
          />
        )}
        {game?.sport_code === "PS" && (
          <GameBoxDetailedAvatarLongPS
            pattern="1"
            color1={`#${teamColor || defaultColor}`}
            color2={`#${teamNumber || defaultNumberColor}`}
          />
        )}
        {game?.sport_code === "SN" && (
          <GameBoxDetailedAvatarSN
            pattern="1"
            color1={`#${teamColor || defaultColor}`}
            color2={`#${teamNumber || defaultNumberColor}`}
          />
        )}
        {game?.sport_code === "BB" && (
          <GameBoxDetailedAvatarBB
            pattern="1"
            color1={`#${teamColor || defaultColor}`}
            color2={`#${teamNumber || defaultNumberColor}`}
          />
        )}
        {!game?.sport_code && (
          <GameBoxDetailedAvatarLong
            pattern="1"
            color1={`#${teamColor || defaultColor}`}
            color2={`#${teamNumber || defaultNumberColor}`}
          />
        )}
      </div>
    );
  };

  /**
   * Show game time ( static and live time) and game odds information
   */

  renderGameBox() {
    const {
      game,
      translate,
      mode,
      content,
      searchQuery,
      showCompetitorAvatar,
      marketsLayout,
      isInViewport,
      odds,
      marketSetKey,
      showMyTicketsPanel,
      layout,
    } = this.props;
    if (game.isLive == 1 && game.gameid === 23032145) {
      // console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>UPDATE INFO FROM GAMEBOX START<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<");
      const startTimeLocal = localStorage.getItem("updateTimeStart");
      const startTime = new Date(startTimeLocal);
      const currentTime = new Date();
      const mills = currentTime - startTime;
      // console.log("Time taken in milli seconds", mills);
      // console.log("game >>>", game);
      // console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>UPDATE INFO FROM GAMEBOX END<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<");
    }
    switch (mode) {
      default:
        return null;

      case "compact":
        // let gridTemplateColumns = [(content === 'selectedLeagueResults' ? '0em' : '4.7em'), '1.5em'];
        // let gridTemplateColumns = [(content === 'selectedLeagueResults' ? '0em' : '4.3em'), '1em'];
        let gridTemplateColumns = [
          content === "selectedLeagueResults" ? "0em" : "0em",
        ];
        if (showCompetitorAvatar) gridTemplateColumns.push("2em");
        // window.innerWidth > 768 && content === 'selectedGames' ? gridTemplateColumns.push('11em', '1em', '1.5em') : gridTemplateColumns.push('12em', '1em', '1.8em');
        // showMyTicketsPanel === true && content === 'selectedGames' ? gridTemplateColumns.push('7em', '1em', '1.5em') : gridTemplateColumns.push('14em', '1em', '1.5em');
        //  gridTemplateColumns.push('14em', '1em', '1.5em');
        //  gridTemplateColumns.push('14em', '1em', '1em', '1.5em');
        // content === 'selectedLeagueResults' && game?.isOutright ? gridTemplateColumns.push('auto', '1em', '1em', '1.5em') : gridTemplateColumns.push('14em', '1em', '1em', '1.5em');
        // content === 'selectedLeagueResults' && game?.isOutright ? gridTemplateColumns.push('auto', '0', '0', '0') : gridTemplateColumns.push('14em', '1em', '1em', '1.5em');
        // Tablet FIX
        (content === "selectedLeagueResults" && game?.isOutright) ||
        layout === "touch"
          ? gridTemplateColumns.push("auto", "0", "0", "0")
          : gridTemplateColumns.push("20%", "1em", "1em", "1.5em");

        game.score_set_calculated &&
          Object.keys(game.score_set_calculated?.home)?.forEach((key) => {
            gridTemplateColumns.push("1.8em");
          });

        // gridTemplateColumns.push('auto');
        // content === 'selectedLeagueResults' && game?.isOutright ? gridTemplateColumns.push('1em') : gridTemplateColumns.push('auto');
        content === "selectedLeagueResults" && game?.isOutright
          ? gridTemplateColumns.push("0")
          : gridTemplateColumns.push("auto");
        // if (marketsLayout === 'horizontal') gridTemplateColumns.push('60%');
        // if (marketsLayout === 'horizontal') gridTemplateColumns.push(showMyTicketsPanel === true ? '65%' : '66%');
        // if (marketsLayout === 'horizontal') gridTemplateColumns.push('66%');
        // if (marketsLayout === 'horizontal') gridTemplateColumns.push(content === 'selectedLeagueResults' && game?.isOutright ? '1%' : '66%');
        if (marketsLayout === "horizontal")
          gridTemplateColumns.push(
            content === "selectedLeagueResults" && game?.isOutright
              ? "1em"
              : "66%"
          );
        gridTemplateColumns.push(
          content === "selectedLeagueResults" ? "0em" : "2em"
        );

        gridTemplateColumns = gridTemplateColumns.join(" ");

        let totalOddsAvatarStyle;

        if (game.total_odds > 99) {
          game.total_odds = "99+";
          totalOddsAvatarStyle = {
            backgroundColor: "var(--color-contrast)",
            color: "var(--color-main)",
            width: "1.8em",
            height: "1.5em",
            fontSize: "1em",
            lineHeight: "inherit",
            fontWeight: "500",
          };
        } else {
          totalOddsAvatarStyle = {
            backgroundColor: "var(--color-contrast)",
            color: "var(--color-main)",
            width: "1.5em",
            height: "1.5em",
            fontSize: "1em",
            lineHeight: "inherit",
            fontWeight: "500",
          };
        }

        return (
          // <div className={this.props.from === 'conference' ? 'game-compact-column-conference' : `game-compact-column ${game.stateBackground}`} style={{ gridTemplateColumns: gridTemplateColumns }} key={game.gameid} onClick={this.props.from === 'conference' ? this.goNextGame.bind(this, game) : marketsLayout === 'vertical' ? this.openGameDetails.bind(this, game) : null}>
          <div>
            <div
              className={
                content === "selectedLeagueResults"
                  ? "game-compact-column-outright-results"
                  : "game-compact-column"
              }
              style={{ gridTemplateColumns: gridTemplateColumns }}
              key={game.gameid}
            >
              {/* <div className={content === 'selectedLeagueResults' && game?.isOutright ? 'game-compact-column-result-outrights' : `game-compact-column ${game.stateBackground}`} style={{ gridTemplateColumns: gridTemplateColumns }} key={game.gameid}> */}
              {/* <div className={`${content === 'selectedLeagueResults' && game?.isOutright ? 'game-compact-column-result-outrights' : 'game-compact-column'}`} style={{ gridTemplateColumns: gridTemplateColumns }} key={game.gameid}> */}
              {/* {
              game.time ? (
                // <div className="game-divider-time color-time font-bold vertical-center spacer-left-list font-time">
                <div className={`game-divider-time ${game.isLive === 1 ? 'color-time-live' : 'color-time'} font-bold vertical-center spacer-left-list font-time-1 cursor-pointer`} onClick={this.openGameDetails.bind(this, game)}>
                  <span className={`word-wrap ${content === 'selectedLeagueResults' ? 'hidden' : ''}`}>
                    {
                      game.isLive === 0 || (game.state === 'FT' && content !== 'selectedGames') ?
                        this.checkOneWeekLater(game.time) ? (<Localize value={game.time} dateFormat="date.game1" />) : (<Localize value={game.time} dateFormat="date.game2" />) : <span>{this.state.increasingTime} <span style={{ color: 'var(--red)' }}>{game.stoppageTime ? `[${game.stoppageTime}]` : null}</span></span>
                    }</span>
                </div>
              ) : (
                game.isLive === 1 ? (
                  <div className="game-divider-time color-time font-bold vertical-center spacer-left-list font-time-1">
                    <span className="text-wrap">Pause</span>
                  </div>
                ) : (
                  <div className="game-divider-time color-time font-bold vertical-center spacer-left-list font-time-1">
                    <span className="text-wrap">{
                      game.isLive === 0 || (game.state === 'FT' && content !== 'selectedGames') ?
                        this.checkOneWeekLater(game.startTime) ? (<Localize value={game.startTime} dateFormat="date.game1" />) : (<Localize value={game.startTime} dateFormat="date.game2" />) : game.startTime
                    }</span>
                  </div>
                )
              )
            } */}
              <div></div>
              {showCompetitorAvatar ? (
                <div className="vertical-center content-center">
                  <GameBoxCompactAvatar
                    pattern="1"
                    color1={game.team1Color1}
                    color2={game.team1Color2}
                  />
                </div>
              ) : null}
              <div
                className="color-team font-team vertical-center font-bold-2 cursor-pointer spacer-left-list"
                onClick={this.openGameDetails.bind(this, game)}
              >
                <span
                  className={`${
                    content === "selectedLeagueResults" && game?.isOutright
                      ? "text-wrap-outright-result"
                      : "text-wrap"
                  }`}
                >
                  {content === "selectedLeagueResults" && game?.isOutright
                    ? game?.game_title
                    : game.team1}
                </span>
              </div>
              <div
                className={
                  "vertical-center content-center " +
                  (game.stateIcon?.includes("home")
                    ? game.stateIcon.replace("home-", "")
                    : "")
                }
                style={{ width: "1.1em", margin: "0 0.3em 0 0" }}
              ></div>
              <div className="vertical-center content-center">
                {game.red_home > 0 ? (
                  <img
                    src={RedCardIcon}
                    style={{ height: "0.8em" }}
                    alt="redCard"
                  />
                ) : null}
              </div>
              <div
                className={`color-contrast vertical-center font-bold-2 font-standing content-center ${
                  game.score_home_updated ? "animate-flicker" : ""
                }`}
              >
                {game.state !== "not_started" ? game.score_home : null}
              </div>

              {game.score_set_calculated &&
                Object.values(game.score_set_calculated?.home).map(
                  (scoreSet, key1) => {
                    return (
                      <div
                        key={key1}
                        className="grey-3 vertical-center font-standing content-center"
                      >
                        {game.state !== "1p" ? scoreSet : null}
                      </div>
                    );
                  }
                )}
              {/* Genius Danger State updates remove  */}
              {/* <div className="text-wrap vertical-center font-s">
              {
                game.time === 'HT' || game.time === 'End' ? null : (
                  game.infoIcon ? (
                    <span style={{ display: "flex", alignItems: "center" }} className={`${showMyTicketsPanel === true && marketsLayout === 'horizontal' ? 'div-hidden' : ''}`}>
                      <span className={game.infoIcon} style={{ width: '1.1em', height: '1.1em', margin: "0px 4px 0.2em 0" }}></span>
                      <Translate value={`stateInfo.${game.infoText}`} />
                    </span>
                  ) : (
                    <span style={{ display: "flex", alignItems: "center" }} className={`${showMyTicketsPanel === true && marketsLayout === 'horizontal' ? 'div-hidden' : ''}`}>
                      <Translate className="text-wrap" value={`stateInfo.${game.stateText}`} />
                    </span>
                  )
                )
              }TEST
            </div> */}
              <div></div>

              {content === "selectedGames" && marketsLayout === "horizontal" ? (
                <div>
                  <Odds
                    searchQuery={searchQuery}
                    game={game}
                    odds={odds}
                    marketSetKey={marketSetKey}
                    isInViewport={isInViewport}
                  />
                </div>
              ) : null}

              <div className="game-divider-badge font-m vertical-center content-right cursor-pointer spacer-right-2">
                {/* <div className="game-divider-badge color-contrast font-m vertical-center content-right cursor-pointer"> */}
                {/* <LiveTvIcon style={{ color: "var(--green)", fontSize: "0.8em" }} /><br/> */}

                {/* STREAM - SHOW LIVE TV ICON  */}
                <span className={`word-wrap text-last-center`}>
                  {game.hasStream ? (
                    <OndemandVideoIcon
                      style={{ color: "var(--green)", fontSize: "1.1em" }}
                      onClick={
                        this.props.from === "conference"
                          ? this.goNextGame.bind(this, game)
                          : marketsLayout === "horizontal"
                          ? this.openGameDetails.bind(this, game)
                          : null
                      }
                    />
                  ) : null}
                  {content === "selectedGames" && !game.hasStream ? (
                    <ArrowForwardIosIcon
                      className={
                        this.props.from === "conference"
                          ? "white-permanent"
                          : "grey-3"
                      }
                      onClick={this.openGameDetails.bind(this, game)}
                      style={{ fontSize: "0.9em" }}
                    />
                  ) : null}
                  {content === "selectedGames" && game.total_odds != 0 ? (
                    <div
                      style={{
                        fontSize: "0.9em",
                        color: "var(--color-contrast)",
                      }}
                      onClick={this.openGameDetails.bind(this, game)}
                    >
                      {game.total_odds}
                    </div>
                  ) : null}
                </span>
              </div>
              <div></div>
              {content === "selectedLeagueResults" &&
              marketsLayout === "horizontal" ? (
                <div></div>
              ) : null}

              {showCompetitorAvatar ? (
                <div className="vertical-center content-center">
                  <GameBoxCompactAvatar
                    pattern="1"
                    color1={game.team2Color1}
                    color2={game.team2Color2}
                  />
                </div>
              ) : null}
              <div
                className="color-team font-team vertical-center font-bold-2 cursor-pointer spacer-left-list"
                onClick={
                  content === "selectedLeagueResults" && game?.isOutright
                    ? null
                    : this.openGameDetails.bind(this, game)
                }
              >
                <span
                  className={`${
                    content === "selectedLeagueResults" && game?.isOutright
                      ? "color-contrast"
                      : "text-wrap"
                  }`}
                >
                  {content === "selectedLeagueResults" && game?.isOutright
                    ? translate?.markets?.label_1 + " >> " + game?.winner
                    : game.team2}
                </span>
              </div>
              <div
                className={
                  "vertical-center content-center " +
                  (game.stateIcon?.includes("away")
                    ? game.stateIcon.replace("away-", "")
                    : "")
                }
                style={{ width: "1.1em", margin: "0.1em 0.3em 0 0" }}
              ></div>
              <div className="vertical-center content-center">
                {game.red_away > 0 ? (
                  <img
                    src={RedCardIcon}
                    style={{ height: "0.8em" }}
                    alt="redCard"
                  />
                ) : null}
              </div>
              {/* {content === 'selectedLeagueResults' && game?.isOutright ?
                <div className='font-standing font-bold-2 color-contrast' style={{minWidth: "200px"}}>{game?.winner}</div>:
                <div className={`color-contrast font-bold-2 vertical-center font-standing content-center ${game.score_away_updated ? 'animate-flicker' : ''}`}>
                  {game.state !== 'not_started' ? game.score_away : null}
                </div>
              } */}

              <div
                className={`color-contrast font-bold-2 vertical-center font-standing content-center ${
                  game.score_away_updated ? "animate-flicker" : ""
                }`}
              >
                {game.state !== "not_started" ? game.score_away : null}
              </div>

              {game.score_set_calculated &&
                Object.values(game.score_set_calculated.away).map(
                  (scoreSet, key2) => {
                    return (
                      <div
                        key={key2}
                        className="grey-3 vertical-center font-standing content-center"
                      >
                        {game.state !== "1p" ? scoreSet : null}
                      </div>
                    );
                  }
                )}
              {/* Genius Danger State updates remove  */}
              {/* <div className="text-wrap vertical-center font-s">
              {
                game.time === 'HT' || game.time === 'End' ? null : (
                  game.infoIcon ? (
                    <span className={`${showMyTicketsPanel === true && marketsLayout === 'horizontal' ? 'div-hidden' : 'text-wrap vertical-center'}`}>{game?.infoTeam?.replace('home', game.team1).replace('away', game.team2)}</span>
                  ) : (
                    <span className={`${showMyTicketsPanel === true && marketsLayout === 'horizontal' ? 'div-hidden' : 'text-wrap vertical-center'}`}>{game?.stateTeam?.replace('home', game.team1).replace('away', game.team2)}</span>
                  )
                )
              }TEST
            </div> */}
              <div></div>
            </div>
            <div className="game-title-date">
              {game.time ? (
                // <div className="game-divider-time color-time font-bold vertical-center spacer-left-list font-time">
                <div
                  className={`game-divider-time ${
                    game.isLive === 1 ? "color-time-live" : "color-time"
                  } font-bold vertical-center spacer-left-list font-time-1 cursor-pointer`}
                  onClick={this.openGameDetails.bind(this, game)}
                >
                  <span
                    className={`word-wrap suomi_time_changer ${
                      content === "selectedLeagueResults" ? "hidden" : ""
                    }`}
                  >
                    {getGameStatus(game.state, game?.sport_code)}{" "}
                    {game.isLive === 0 ||
                    (game.state === "FT" && content !== "selectedGames") ? (
                      this.checkOneWeekLater(game.time) ? (
                        <Localize value={game.time} dateFormat="date.game1" />
                      ) : (
                        <Localize value={game.time} dateFormat="date.game2" />
                      )
                    ) : (
                      <span>
                        {game.time}{" "}
                        <span style={{ color: "var(--red)" }}>
                          {game.stoppageTime ? `[${game.stoppageTime}]` : null}
                        </span>
                      </span>
                    )}
                  </span>
                </div>
              ) : game.isLive === 1 ? (
                <div className="game-divider-time color-time font-bold vertical-center spacer-left-list font-time-1">
                  <span className="text-wrap">Pause</span>
                </div>
              ) : (
                <div className="game-divider-time color-time font-bold vertical-center spacer-left-list font-time-1">
                  <span className="text-wrap">
                    {game.isLive === 0 ||
                    (game.state === "FT" && content !== "selectedGames") ? (
                      this.checkOneWeekLater(game.startTime) ? (
                        <Localize
                          value={game.startTime}
                          dateFormat="date.game1"
                        />
                      ) : (
                        <Localize
                          value={game.startTime}
                          dateFormat="date.game2"
                        />
                      )
                    ) : (
                      game.startTime
                    )}
                  </span>
                </div>
              )}
            </div>
          </div>
        );

      case "detailed":
        addStateItem(game, this.stateList);
        // this.addStateItem(game);

        return (
          <div
            className={`game-box-detailed-bg bg-${game.sport_code}`}
            key={game.gameid}
          >
            {/* <div className={`game-box-detailed-bg bg-${game.sport_code} ${game.hasStream === true ? 'hidden' : '' } `} key={game.gameid}> */}
            <div className="game-box-detailed-title">
              <div
                className="game-box-detailed-team1 content-center vertical-center"
                style={{ margin: "0 0 0 -3em" }}
              ></div>
              <div></div>
              <div className="white-permanent font-m content-left vertical-center text-wrap spacer-left">
                {/* {game.sport_code === "FB"
                  ? [
                      <div key={0}>
                        {game.yellow_home}{" "}
                        <img
                          className="spacer-right"
                          src={YellowCardIcon}
                          alt="yellow"
                        />
                        {game.red_home}{" "}
                        <img
                          className="spacer-right"
                          src={RedCardIcon}
                          alt="red"
                        />
                        {game.corners_home}{" "}
                        <img
                          className="spacer-right"
                          src={CornerIcon}
                          alt="corner"
                        />
                      </div>,
                    ]
                  : null} */}
              </div>
              <div></div>
              <div className="white-permanent font-m content-right vertical-center text-wrap spacer-right">
                {/* {game.sport_code === "FB"
                  ? [
                      <div key={0}>
                        {game.yellow_away}{" "}
                        <img
                          className="spacer-right"
                          src={YellowCardIcon}
                          alt="yellow"
                        />
                        {game.red_away}{" "}
                        <img
                          className="spacer-right"
                          src={RedCardIcon}
                          alt="red"
                        />
                        {game.corners_away}{" "}
                        <img
                          className="spacer-right"
                          src={CornerIcon}
                          alt="corner"
                        />
                      </div>,
                    ]
                  : null} */}
              </div>
            </div>
            <div className="game-box-detailed-title1">
              <div className="white-permanent bg-color-contrast-gradient-left font-s spacer-left vertical-center content-left">
                <Stack pt={0.5}>
                  <TeamJersey game={game} teamNumber={1} />
                  <span
                    style={{ textShadow: "2px 2px #000000" }}
                    className="text-wrap"
                  >
                    {game.team1}
                  </span>
                </Stack>
              </div>
              <div className="white-permanent bg-color-contrast-gradient-right font-s spacer-right vertical-center content-right">
                <Stack pt={0.5}>
                  <TeamJersey game={game} teamNumber={2} />
                  <span
                    style={{
                      textShadow: "2px 2px #000000",
                      textAlignLast: "right",
                    }}
                    className="text-wrap"
                  >
                    {game.team2}
                  </span>
                </Stack>
              </div>
            </div>
            {/* Genius Danger State updates remove  */}
            {/* <div className="game-box-detailed-additional-info">
              {
                Object.values(this.stateList).map((stateInfoItem, key) => {
                  // if (key > 4) return;
                  // return this.renderStateItem(stateInfoItem, key, 'detailed');
                  return game.isLive === 1 && game.sport_code === 'FB' ? this.renderStateItem(stateInfoItem, key, 'detailed') : null;
                })
              }
            </div> */}
          </div>
        );
    }
    // });
  }

  render() {
    log(this.constructor.name + ":" + this.props.game.team1, "render");
    const { game, content, url } = this.props;

    if (
      game.state === "FT" &&
      content !== "selectedLeagueResults" &&
      new Date().getTime() > game.endTime
    ) {
      return null;
    }

    return (
      <div id={`scroll_tab_index_${game?.leagueID}_${game?.gameid}`}>
        <SideBetStaticLoader
          url={url}
          shouldMount={
            parseInt(this.props.gameStaticId) === this.props.game.gameid &&
            !this.state.openDetail
          }
          callback={() => this.openGameDetails(this.props.game)}
        />
        {this.renderGameBox()}
        {this.state.gameId ? (
          <Game
            game={[game]}
            content={content}
            forwardRef={(c) => {
              this.openDrawer = c;
            }}
            gameStaticId={this.props.gameStaticId}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    game: ownProps.game[0],
    content: ownProps.content,
    from: ownProps.from,
    mode: ownProps.mode ? ownProps.mode : "compact",
    showCompetitorAvatar: state.settings.showCompetitorAvatar,
    leagueFilter: state.leagues._leagueFilter,
    marketsLayout:
      ownProps.marketSetKey === "favorites"
        ? "vertical"
        : state.settings.marketsLayout,
    isInViewport: ownProps.isInViewport,
    odds: ownProps.odds,
    marketSetKey: ownProps.marketSetKey,
    gameIndex: ownProps.gameIndex,
    showMyTicketsPanel: state.settings.showMyTicketsPanel,
    gameStaticId: ownProps.gameStaticId,
    translate: state.i18n.translations[state.i18n.locale],
    searchQuery: ownProps?.searchQuery,
    url: state.settings.url,
    layout: state.settings.layout,
  };
};

export default connect(mapStateToProps)(GameBox);
